import {CompanyData} from '@/models/CompanyData';
import {LoanApplicationDTO} from '@/models/LoanApplication';
import {Currency} from '@/modules/multiForm/shared/MultiformDictionaries';
import Client from '@/models/Client';
import {CountryCode} from 'libphonenumber-js';
import {PersonalDetailsBorrowerCardViewModel} from '@/modules/client/models/BorrowerCardViewModel';
import {clone, Utils} from '@/services/utils/BasicUtils';
import {Person} from '@/models/Person';
import {RealEstate} from '@/models/RealEstate';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {IRaiffeisen, Raiffeisen} from '@/models/integrations/RaiffeisenLoanApplication';
import EnvironmentService from '@/env/EnvironmentService';
import {
  CivilStatus,
  EducationDegree,
  HomeStatus,
  IncomeType,
  LiabilityType,
  LimitType,
  PersonalSharesSourceType
} from '@/modules/multiForm/shared/Types';

export type MultiformModel = {
  creditDetails: CreditDetails;
  realEstate?: Array<RealEstate>;
  households: Household[];
  agent?: Person;
};

export class HouseholdDto {
  id?: number;
  clientsIds: Array<number> = [];
  anonymousBorrowersIds: Array<number> = [];
  numberOfAdults: Nullable<number> = null;
  numberOfKids: Nullable<number> = null;
  householdsMonthlyExpenses: Nullable<number> = null;
  householdsMonthlyCarsExpenses: Nullable<number> = null;
  householdsMonthlyLifeExpenses: Nullable<number> = null;
}

export class Household extends HouseholdDto {
  borrowers?: Client[];

  constructor(dto?: HouseholdDto) {
    super();
    if (dto) {
      Object.assign(this, dto);
    }
  }

  getClientsByIds(clients: Client[]): Client[] {
    return clients.filter(c => this.clientsIds.includes(c.id));
  }
}

export class PersonalDetails implements NullableDeep<PersonalDetailsBorrowerCardViewModel> {
  residentCountry?: CountryCode;
  birthCity: Nullable<string> = null;
  civilStatus: Nullable<CivilStatus> = null;
  homeStatusId: Nullable<HomeStatus> = null;
  educationId: Nullable<EducationDegree> = null;
  origin: CountryCode = EnvironmentService.Environment.getAppDomainConfig().region;
  mothersMaidenName: Nullable<string> = null;
  fathersName: Nullable<string> = null;
  mothersName: Nullable<string> = null;
  everHaveEconomicActivity: Nullable<boolean> = null;

  stayInPolandYears?: number;
  stayInPolandMonths?: number;
  economicActivityWasSuspended?: boolean;
  economicActivityWasSuspendedDateFrom?: string;
  economicActivityWasSuspendedDateTo?: boolean;
  economicActivityWasClosedDateFrom?: string;
  economicActivityWasClosed?: boolean;
  homeStatusOther?: string;
  originSurname?: string;
  militaryServiceStatus?: string;
  separationCapital?: boolean;

  resident: boolean = true;
  plannedRetirementAge?: number;
  birthDateTime!: string;
  estateFutureOwner: boolean = true;
  futureEstateResident: boolean = true;
  hasIncome?: boolean;
  haveEconomicShares?: boolean;
  hasLiabilities?: boolean;
  hasLimits?: boolean;
  hasAccount: boolean = true;
  hasAssets?: boolean;
  isBankWorker: Nullable<BankSymbol> = null;
  isWorkingInBank?: boolean;
  incomes: Income[] = [];
  assets: Asset[] = [];
  liabilities: Liability[] = [];
  limits: Limit[] = [];
  accounts: Account[] = [];
  percentComplete: number = 75;
  [key: string]: any;

  constructor(dto?: PersonalDetails) {
    if (dto) {
      if (dto.incomes) {
        dto.incomes = dto.incomes.map(i => new Income(i));
      }
      Utils.assign(this, dto);
    }
  }
}

export enum IncomeDeliveredOn {
  BANK_ACCOUNT = 'bankAccount',
  CASH = 'cash',
}

export class Income {
    incomeTypeId: Nullable<IncomeType> = null;
    incomeCurrency: Currency = EnvironmentService.Environment.currentCurrency();
    incomeDeliveredOn: IncomeDeliveredOn = IncomeDeliveredOn.BANK_ACCOUNT;
    professionId?: number;
    industryId?: number;
    companyPhoneNumber: Nullable<string> = null;
    numberOfEmployers?: Nullable<string> = null;
    netIncome?: number;
    position: Nullable<string> = null;
    paymentDay?: number;
    workingPeriodYears: number = 0;
    workingPeriodMonths?: number;
    incomeCountry: string = EnvironmentService.Environment.getAppDomainConfig().region;
    companyData: CompanyData = new CompanyData();
    workingStartDate?: string;
    contactInfo:any;
    constructor(dto?: Income) {
      if (dto) {
        Object.assign(this, dto);
      }
    }
}

export class Asset {
    type: Nullable<string> = null;
    isPlannedToFlow?: boolean;
    isCredited?: boolean;
    value?: number;
    description: Nullable<string> = null;
}

export class Liability {
    type: Nullable<LiabilityType> = null;
    currency: Currency = EnvironmentService.Environment.currentCurrency();
    loanAmount: Nullable<number> = null;
    startLoanAmount?: number;
    installment: Nullable<number> = null;
    institution: Nullable<string> = null;
    startLoanDate: Nullable<string> = null;
    endLoanDate: Nullable<string> = null;
    bank: Nullable<BankSymbol> = null;
    willBeRefinanced?: boolean;
}

export class Limit {
    type: Nullable<LimitType> = null;
    bank: Nullable<string> = null;
    startLoanAmount: Nullable<number> = null;
    loanAmount: Nullable<number> = null;
    startLoanDate: Nullable<string> = null;
}

export class AddressDTO {
  id?: number;
  country: string = EnvironmentService.Environment.getAppDomainConfig().region;
  street: Nullable<string> = null;
  city: Nullable<string> = null;
  cityDistrict: Nullable<string> = null;
  zip: Nullable<string> = null;
  landId: number | null = null;
  districtId: number | null = null;
  streetNum: Nullable<string> = null;
  streetLocal: Nullable<string> = null;
  post?: string;
  community?: string;
}

export class Address extends AddressDTO {
  constructor(address?: PartialDeep<Address>) {
    super();
    if (address) {
      Utils.assign(this, address);
    }
  }

  get isFilled(): boolean {
    return !!this.city &&
      !!this.country &&
      !!this.districtId &&
      !!this.landId &&
      !!this.street &&
      !!this.streetNum;
  }
}

export class Account {
    incomeBankAccountNumber: Nullable<string> = null;
    incomeToAccount: Nullable<boolean> = null;
    bank: Nullable<BankSymbol> = null;
}

export class EstateAddress extends Address {
  storey?: number;
  numberOfStoreys?: number;
  constructionYear?: number;
  buildingType: Nullable<string> = null;
  estateCondition: Nullable<number> = null;
}

export interface PersonalShareSources {
  personalSharePaidSource: Nullable<PersonalSharesSourceType>
  personalShareToPayBeforePaymentSource: Nullable<PersonalSharesSourceType>
  personalShareToPayAfterPaymentSource: Nullable<PersonalSharesSourceType>
}

export class Tranche implements PersonalShareSources {
  type: Nullable<string> = null;
  creditedAmount?: number;
  trancheDate: Nullable<string> = null;
  bankAccountOwner: Nullable<string> = null;
  bankAccount: Nullable<string> = null;
  personalSharePaid?: number;
  personalShareToPayBeforePayment?: number;
  personalShareToPayAfterPayment?: number;
  personalSharePaidSource: Nullable<PersonalSharesSourceType> = null;
  personalShareToPayBeforePaymentSource: Nullable<PersonalSharesSourceType> = null;
  personalShareToPayAfterPaymentSource: Nullable<PersonalSharesSourceType> = null;
}

export class CreditDetails {
  inTranche: boolean | null = null;
  tranches: Tranche[] = [];
  loanPeriod: number = 0;
  creditedAmountSum: number = 0;
  gracePeriod: number = 0;
  gracePeriodCapital: number = 0;
  ratePaymentDate?: number = 10;
  withdrawCreditDate: Nullable<string> = null;
  currency: Currency = EnvironmentService.Environment.currentCurrency();
  withdrawCreditType: string = 'ownAccount';
  sendingExtractType: string = 'extract';
  withdrawCreditAccountNumber: Nullable<string> = null;
  withdrawCreditAccountOwnerName: Nullable<string> = null;
  fixedInstallments: boolean = true;
  applicationCity: Nullable<string> = null;
  applicationDate: Nullable<string> = null;
  offerName: Nullable<string> = null;
  bankOperatorId: number = 18;
  rrsoCash: number = 0;
  provision: number = 0;
  chosenOfferMargin: number = 0;
  firstInstallmentDate: Nullable<string> = null;
  peselAgent: Nullable<string> = null;
  driveroadType: Nullable<string> = null;
  ror: Nullable<boolean> = null;
  [key: string]: any;
  raiffeisen: Nullable<IRaiffeisen> = new Raiffeisen();

  constructor(loanApp: LoanApplicationDTO) {
    if (loanApp.period) {
      this.loanPeriod = loanApp.period;
    }
    this.offerName = loanApp.name;
    this.creditedAmountSum = loanApp.amount;
  }
}

export function mapForValidation(data: PersonalDetails): PersonalDetails {
  if (data.isBankWorker as any === false) {
    data.isBankWorker = null;
  }
  data.incomes.forEach((x, index) => {
    if ((x.companyData?.type as string) === '') {
      data.incomes[index].companyData.type = null;
    }
  });
  return data;
}

export function mapMultiformModelForCheckingFilledPercentage(data: MultiformModel): MultiformModel {
  const clonedData: MultiformModel = clone(data);
  clonedData.households.forEach(household => {
    if (household.borrowers) {
      household.borrowers?.forEach((borrower, index) => {
        if (household.borrowers && household.borrowers[index]) {
          household.borrowers![index] = borrower.dataForCheckingFilledPercentage as any;
        }
      });
    }
  });
  return clonedData;
}
