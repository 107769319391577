import EnvironmentService from '@/env/EnvironmentService';
import {MeetingPlannerMode} from '@/models/widgets/MeetingPlannerMode';
import WidgetConfig from '@/models/widgets/WidgetConfig';
import { Utils } from '@/services/utils/BasicUtils';
import { VuetifyThemeItem } from 'vuetify/types/services/theme';

export class MeetingPlannerConfig extends WidgetConfig {
  agentId: number | undefined = EnvironmentService.Environment.isPreprodOrDev ? 2539 : 96;
  description: Nullable<string> = 'W razie pytań lub wątpliwości zachęcam do skorzystania z bezpłatnej konsultacji w moim ☕ biurze lub 👨‍💻 online.';
  mode: MeetingPlannerMode = MeetingPlannerMode.BASIC;
  districtId: number | undefined = undefined;
  landId: number | undefined = undefined;
  secondaryColor: VuetifyThemeItem = EnvironmentService.Theme.light.secondary;

  constructor(obj?: Partial<WidgetConfig>) {
    super(obj);
    if (obj) {
      Utils.assign(this, obj);
      this.secondaryColor = obj.secondaryColor;
    }
  }
}
