<template>
  <simulation-results
    :simulationResultConfig="simulationResultConfig">
    <template #toolbar-left>
      <v-col class="shrink pa-0 align-self-end pl-3">
        <mortgage-simulation-available-products-tabs />
      </v-col>
    </template>
    <template #user-panel-credit-parameters-extension>
      <div :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.BASIC_PARAMETERS">
        <v-col class="pa-0 text-center text-no-wrap">
          <fp-chip-radio
            v-model="store.userInput.fixedInstallments"
            :items="installmentTypeDictionary"
            item-name="name_pl"
            :mandatory="true"
          ></fp-chip-radio>
        </v-col>
        <fp-input
          dense
          :suffix="$env.currentCurrency()"
          prepend-icon="mdi-cash-multiple"
          type="number"
          int
          required
          :customRules="loanAmountRules"
          v-model="store.userInput.loanAmount"
          />
        <fp-input
          dense
          prepend-icon="mdi-home-city-outline"
          :label= "getLabel(useMortgageSimulationResultsEnum['HYPTOHEC_VALUE'])"
          :suffix="$env.currentCurrency()"
          int
          required
          :customRules="hypothecValueRules"
          v-model="store.userInput.hypothecValue"
          type="number"/>
      </div>
      <fp-select
        dense
        :items="currencyDictionary"
        v-model="store.userInput.currency"
        prepend-icon="mdi-currency-usd"
        :clearable="false"
      />
      <fp-input
        v-if="$env.isAppDomain().LENDI_PL"
        :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.BRIDGING_INSURANCE"
        dense
        prepend-icon="mdi-bridge"
        :label="getLabel(useMortgageSimulationResultsEnum['BRIDGING_PERIOD'])"
        type="number"
        int
        :suffix="monthsSuffix"
        required
        v-model="store.userInput.explicitBridgingPeriod"/>
      <div v-if="$env.isAppDomain().NESTO_RO" class="d-flex align-center pl-2 w-100">
        <span class="body-2 text--secondary font-weight-medium">DTI</span>
        <fp-input
            dense
            type="number"
            suffix="%"
            int
            required
            :custom-rules="debtToIncomeRules"
            v-model="store.userInput.acceptableDtiPercentage"
        />
      </div>
      <div :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.LOAN_PERIOD">
        <fp-input
          dense
          prepend-icon="mdi-clock-outline"
          :label="getLabel(useMortgageSimulationResultsEnum['MORTGAGE_PERIOD'])"
          :suffix="monthsSuffix"
          int
          @input="onLoanPeriodInMonthsChange"
          :value="$env.isBackendSimV2 ? store.userInput.loanPeriod : store.userInput.loanPeriodInMonths"
          type="number"/>
        <v-flex>
          <loan-period-slider
            @input="onSliderInput"
            v-model="store.userInput.loanPeriod"/>
        </v-flex>
        <ReferenceRatePanel />
      </div>
    </template>
  </simulation-results>
</template>
<script lang="ts" setup>
import SimulationResults from '@/components/calculator/results/SimulationResults.vue';
import {ProductType} from '@/commons/enums/ProductType';
import {SimulationResultConfig} from '@/components/calculator/results/SimulationResultConfig';
import {getCurrentInstance, onMounted, onUnmounted, ref, Ref} from 'vue';
import {UserInputPanelElementEnum} from '@/components/calculator/results/userInputPanel/UserInputPanelElementEnum';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import FpChipRadio from '@/components/inputs/FpChipRadio.vue';
import LoanPeriodSlider from '@/components/calculator/LoanPeriodSlider.vue';
import ReferenceRatePanel from '@/components/calculator/results/userInputPanel/ReferenceRatePanel.vue';
import {debouncedAtInput} from '@/services/utils/BasicUtils';
import EnvironmentService from '@/env/EnvironmentService';
import {currencyDictionary, installmentTypeDictionary} from '@/modules/multiForm/shared/MultiformDictionaries';
import useOfferSorting from '@/components/calculator/results/OfferSorting';
import MortgageSimulationAvailableProductsTabs
  from '@/components/calculator/mortgage/MortgageSimulationAvailableProductsTabs.vue';
import {useOnboarding} from '@/modules/onboarding/useOnboarding';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18N_Calculator_Results} from '@/services/enumTranslator/I18N_CALCULATOR/I18N_Calculator_Results';
import {useCalculatorFormRules} from '@/components/calculator/useCalculatorFormRules';
import {TourName} from '@/modules/onboarding/tour/models/TourOptions';

const instance = getCurrentInstance();
const onboarding = useOnboarding(TourName.MORTGAGE_CALCULATOR);
const {availableProductsItems,} = useOfferSorting();
const store = useSimulationStore();
const {loanAmountRules, hypothecValueRules,} = useCalculatorFormRules();
const simulationId = instance?.proxy.$route.params.simulationId;
const {debtToIncomeRules,} = useCalculatorFormRules();

store.init(ProductType.MORTGAGE, simulationId);

const simulationResultConfig: Ref<SimulationResultConfig> = ref({
  fullscreen: !!instance?.proxy.$vuetify.breakpoint.mdAndDown,
  userInputPanels: [] as UserInputPanelElementEnum[],
});

const getLabel = (I18NDictContent: keyof I18N_Calculator_Results['useMortgageSimulationResults']): string => {
  return I18NGetter().useMortgageSimulationResults[I18NDictContent];
};
const useMortgageSimulationResultsEnum = I18NGetter().useMortgageSimulationResults;
const monthsSuffix = I18NGetter().useMortgageSimulationResults.MONTHS_SUFFIX;
// To będzie do wykasowania po wprowadzeniu simV2
const onLoanPeriodInMonthsChange = debouncedAtInput(($event: number) => {
  if (EnvironmentService.Environment.isBackendSimV2) {
        store.userInput!.loanPeriod = $event;
  } else {
        store.userInput!.loanPeriod = Math.round($event / 12);
        store.userInput!.loanPeriodInMonths = $event;
  }
});
const onSliderInput = debouncedAtInput(($event: number) => {
  if (!EnvironmentService.Environment.isBackendSimV2) {
        store.userInput!.loanPeriodInMonths = $event ? $event * 12 : null;
  }
});

onMounted(() => onboarding.start());
onUnmounted(() => onboarding.stop());
</script>
<style scoped lang="scss">
</style>
