<template>
  <v-row class="no-gutters">
    <v-col>
      <data-card-element-row
          :loading="loading"
          :description="store.visibleOffersCountLabel"
          :title="getOffersTitle(store)"/>
      <data-card-element-row
          :loading="loading"
          :description="initialCosts"
          :title="getInitialCostsTitle()"/>
      <data-card-element-row
          :loading="loading"
          :description="firstInstallments"
          :title="getMortgageCostsTitle()"/>
      <data-card-element-row
          :loading="loading"
          :description="costSum"
          :title="getCostSumTitle()"/>
      <data-card-element-row
          :loading="loading"
          :description="rrso"
          :title="getRRSOTitle()"/>
      <data-card-element-row
          v-if="isForeignCurrency(store.userInput)"
          :loading="loading"
          :description="exchangeRate"
          :title="getExchangeRateTitle()"/>
      <data-card-element-row
        v-if="$env.isAppDomain().NESTO_RO"
        :loading="loading"
        :description="dti"
        :title="getDTITitle()"/>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Offer, Simulation } from '@/models/simulation/Simulation';
import { numeralFormat } from '@/filters/StringFilters';
import DataCardElementRow from '@/components/commons/DataCardElementRow.vue';
import { useSimulationStore } from '@/components/calculator/services/SimulationStore';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import { useSimulationCurrency } from '@/components/calculator/useSimulationCurrency';
import EnvironmentService from '@/env/EnvironmentService';
import { i18nFormatter } from '@/services/StringFormatter';

const props = withDefaults(defineProps<{
  simulation: Simulation;
  loading: boolean;
}>(), {
  simulation: () => ({} as Simulation),
  loading: false,
});

const store = useSimulationStore();
const { isForeignCurrency, } = useSimulationCurrency();

const getOffersTitle = (): string => {
  return store.isShownAvailableProducts
    ? I18NGetter().useCalculatorSummary.NEW_TRANSLATION_AVAILABLE
    : I18NGetter().useCalculatorSummary.NEW_TRANSLATION_EXCLUDED as string;
};

const getInitialCostsTitle = (): string => {
  return I18NGetter().useCalculatorSummary.INITIAL_COSTS;
};

const getMortgageCostsTitle = (): string => {
  return I18NGetter().useCalculatorSummary.MORTGAGE_COSTS;
};

const getCostSumTitle = (): string => {
  return I18NGetter().useCalculatorSummary.COST_SUM;
};

const getRRSOTitle = (): string => {
  return I18NGetter().useCalculatorSummary.RRSO;
};

const getDTITitle = (): string => {
  return I18NGetter().useCalculatorSummary.DTI;
};

const getExchangeRateTitle = (): string | void => {
  return i18nFormatter(
    I18NGetter().useCalculatorSummary.NEW_TRANSLATION_EXCHANGE_RATE,
    { currency: props.simulation?.userInput.currency!, }
  );
};

const currentCurrency = EnvironmentService.Environment.currentCurrency();

const maxCreditAmounts = computed(() => {
  const range = props.simulation?.summary.maxCreditAmountRange;
  return range?.from && range?.until
    ? `${numeralFormat(range?.from)} - ${numeralFormat(range?.until)} ${currentCurrency}`
    : null;
});

const initialCosts = computed(() => {
  const initialCostRange = props.simulation?.summary.initialCostRange;
  return `${numeralFormat(initialCostRange?.from)} - ${numeralFormat(initialCostRange?.until)} ${currentCurrency}`;
});

const costSum = computed(() => {
  const range = props.simulation?.summary.costsSumRange;
  return `${numeralFormat(range?.from)} ${currentCurrency}`;
});

const rrso = computed(() => {
  const range = props.simulation?.summary.rrsoRange;
  return range?.from && range?.until
    ? `${range?.from} - ${range?.until} %`
    : I18NGetter().useCalculatorSummary.NO_DATA;
});

const firstInstallments = computed(() => {
  const range = props.simulation?.summary.firstInstallmentRange;
  return range?.from && range?.until
    ? `${numeralFormat(range?.from)} - ${numeralFormat(range?.until)} ${currentCurrency}`
    : I18NGetter().useCalculatorSummary.NO_DATA;
});

const exchangeRate = computed<string>(() => {
  if (isForeignCurrency(store.userInput)) {
    const offer: Offer | undefined = props.simulation?.offers[0];
    const exchangeRate: number = offer?.fxValues?.averageNBPRate ?? 0;
    return exchangeRate
      ? `${exchangeRate} ${currentCurrency}`
      : I18NGetter().useCalculatorSummary.NO_DATA;
  } else {
    return I18NGetter().useCalculatorSummary.NO_DATA;
  }
});

const dti = computed<string>(() => {
  const lowerValue: number | undefined = props.simulation?.offers[0].debtToIncome?.householdDtiPercentage;
  const higherValue: number | undefined = props.simulation?.offers[props.simulation?.offers.length - 1].debtToIncome?.householdDtiPercentage;
  return lowerValue && higherValue ? `${lowerValue.toFixed(1)} - ${higherValue.toFixed(1)} %` : I18NGetter().useCalculatorSummary.NO_DATA;
});
</script>
