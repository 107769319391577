import MultiformDictionary from '@/modules/multiForm/shared/MultiformDictionaries';
import {ProductType} from '@/commons/enums/ProductType';
import EnvironmentProducts from '@/env/services/EnvironmentProducts';
import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {capitalize} from '@/filters/StringFilters';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import useGeoAreas from '@/composables/useGeoAreas';

export function landName(landId: number): string {
  const geoArea = useGeoAreas();
  return capitalize(geoArea.landDictionary().find(land => land.type === landId)?.name_pl) || '';
}

export function districtName(districtId: number): string {
  const geoArea = useGeoAreas();
  return capitalize(geoArea.districtDictionary().find(district => district.type === districtId)?.name_pl) || '';
}

export function productType(value: ProductType) {
  const noun = EnvironmentProducts().find(product => product.type === value)?.nominative?.female;
  return `Sprawa ${noun}`;
}

export function bankName(value: BankSymbol) {
  return banksDictionary().find(bank => bank.type === value)?.name_pl;
}
