import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const rangeMoment = extendMoment(Moment);
type DateTimeUnits = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';
type DateTimeStartUnits = 'year' | 'month' | 'week' | 'day';

export default class DateUtils {
  private static MONTH_JS_OFFSET = 1;
  static SECONDS_IN_MINUTE = 60;
  static SECONDS_IN_HOUR = 60 * 60;
  static SECONDS_IN_DAY = 60 * 60 * 24;
  static SECONDS_IN_WEEK = 60 * 60 * 24 * 7;
  static HOURS_IN_DAY = 24;

  public static isTodayInRange(startDate: Date, endDate: Date | null): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.isDayInRange(today, startDate, endDate);
  }

  public static isDayInRange(day: Date, startDate: Date, endDate: Date | null): boolean {
    return endDate
      ? day.getTime() <= endDate.getTime() && day.getTime() >= startDate.getTime()
      : this.isSameDayObjects(day, startDate);
  }

  public static isTomorrow(date: string | Date): boolean {
    return moment(date).format('DD-MM-YYYY') === moment(new Date()).add(1, 'days').format('DD-MM-YYYY');
  }

  public static isDayAfterTomorrow(date: string | Date): boolean {
    return moment(date).format('DD-MM-YYYY') === moment(new Date()).add(2, 'days').format('DD-MM-YYYY');
  }

  public static isToday(date: string | Date): boolean {
    return moment(date).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY');
  }

  public static isYesterday(date: string | Date): boolean {
    return moment(date).format('DD-MM-YYYY') === moment(new Date()).subtract(1, 'days').format('DD-MM-YYYY');
  }

  public static isDayBeforeYesterday(date: string | Date): boolean {
    return moment(date).format('DD-MM-YYYY') === moment(new Date()).subtract(2, 'days').format('DD-MM-YYYY');
  }

  public static isThisWeek(date: string | Date): boolean {
    return moment(new Date()).subtract(1, 'week').isBefore(moment(date));
  }

  public static isThisMonth(date: string | Date): boolean {
    return moment(new Date()).subtract(1, 'month').isBefore(moment(date));
  }

  public static isThisYear(date: string | Date): boolean {
    return moment(new Date()).subtract(1, 'year').isBefore(moment(date));
  }

  public static isOlderThanThisYear(date: string | Date): boolean {
    return moment(date).isBefore(moment(new Date()).subtract(1, 'year'));
  }

  public static isSameDay(date1: string, date2: string): boolean {
    return date1.slice(0, 10) === (new Date(date2)).toISOString().slice(0, 10);
  }

  public static isPastDate(date: string | Date): boolean {
    return moment().isAfter(moment(date));
  }

  public static isFutureDate(date: string | Date, compareUnit: moment.unitOfTime.StartOf = 'day'): boolean {
    return moment().isBefore(moment(date), compareUnit);
  }

  public static getFirstDayOfCurrentMonth(): Date {
    return new Date(moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'));
  }

  public static getLastDayOfCurrentMonth(): Date {
    return new Date(moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'));
  }

  public static getFormatedDate(date: string | Date, format: string = 'DD.MM.YYYY'): string {
    return moment(date).format(format);
  }

  public static isSameDayObjects(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  public static formatDateStringToObject(date: string = ''): Date | null {
    if (date.length === 8) {
      const year = parseInt(date.substr(4, 4));
      const month = parseInt(date.substr(2, 2));
      const day = parseInt(date.substr(0, 2));
      return new Date(year, month - this.MONTH_JS_OFFSET, day);
    } else return null;
  }

  public static formatDateObjectToString(date: Date): string {
    const month = date.getMonth() + 1;
    const monthPrefix = date.getMonth() + this.MONTH_JS_OFFSET < 10 ? '0' : '';
    const dayPrefix = date.getDate() < 10 ? '0' : '';
    const day = date.getDate();
    return `${dayPrefix + day}${monthPrefix + month}${date.getFullYear()}`;
  }

  public static getDatesRange(startDate: Date | string | Moment.Moment, endDate: Date | string | Moment.Moment) {
    return rangeMoment.range(moment(endDate).startOf('day'), moment(startDate).startOf('day'));
  }

  public static getPastDate(amount: number, unit: DateTimeUnits) {
    return moment().subtract(amount, unit);
  }

  public static getFuturetDate(amount: number, unit: DateTimeUnits) {
    return moment().add(amount, unit);
  }

  public static getStartOfDate(inputDate: Date | moment.Moment, unit: DateTimeStartUnits) {
    return moment(inputDate).startOf(unit);
  }

  public static isDateInRange(dateToCheck: Date | moment.Moment, startDate: Date | moment.Moment, endDate: Date | moment.Moment, inclusive: boolean = true) {
    return moment(dateToCheck).isBetween(moment(startDate), moment(endDate), null, inclusive ? '[]' : '()');
  }

  public static diff(start: Date | moment.Moment | string, end: Date | moment.Moment | string, unit: DateTimeUnits = 'seconds'): number {
    return moment(start).diff(moment(end), unit);
  }

  public static fromNow(date: Date | moment.Moment | string): string {
    return moment(date).fromNow();
  }
};
