export enum TableElementHeight {
  DEFAULT = 41,
  INPUT = 57,
  DOUBLE_INPUT = TableElementHeight.INPUT * 2,
  DOUBLE_DEFAULT = TableElementHeight.DEFAULT * 2,
  MEDIUM = 150,
  LARGE = 180,
  EXTRA_LARGE = 300,
  EXTRA_EXTRA_LARGE = 500,
}

export const EMPTY_VALUE: string = '-';
