<template>
  <v-row>
    <v-col class="pa-0" cols="7">
      <fp-input
          :id="$cy.nipInput"
          :label="appDomainConfig.taxIdentificationNumber.label"
          prepend-icon="mdi-domain"
          v-model="model"
          taxId
          :required="!partnerWithoutNip"
          @input="fetchCompanyDataByNip"
      />
    </v-col>
    <v-col class="pt-7 pl-1" v-if="appDomainConfig.taxIdentificationNumber.hasCompanyDataApiIntegration">
      <fp-btn :id="$cy.downloadNip" :disabled="!validated" @click="fetchCompanyDataByNip" primary>
        {{ $i18n.useFpNipForm.DOWNLOAD_DATA }}
      </fp-btn>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {ref, onMounted, computed} from 'vue';
import MultiformApi from '@/modules/multiForm/services/MultiformApi';
import {useInstance} from '@/composables/useInstance';

const {$i18n, $env, $errorHandler,} = useInstance();

const props = defineProps<{
  value?: string | null;
  partnerWithoutNip?: boolean;
}>();
const emit = defineEmits<{
  (event: 'input', value: string | null): void;
  (event: 'fetchCompanyDataByNip', response: any, nip: string | null): void;
  (event: 'update:nip', value: string | null): void;
}>();

const nip = ref<string | null>(null);

const appDomainConfig = $env?.getAppDomainConfig();

const model = computed({
  get: () => props.value,
  set: (value) => {
    nip.value = value;
    emit('input', value);
  },
});

const validated = computed(() => {
  const validator = appDomainConfig.taxIdentificationNumber.validator;
  if (validator?.length) {
    return nip.value ? nip.value.toString().length === validator.length : false;
  } else if (validator?.validationFunc) return validator.validationFunc(nip.value as string);
  else return true;
});

onMounted(() => {
  if (props.value && props.value.length !== 0) {
    nip.value = props.value;
  }
  fetchCompanyDataByNip();
});

const fetchCompanyDataByNip = async() => {
  if (validated.value) {
    try {
      emit('update:nip', nip.value);
      if (appDomainConfig.taxIdentificationNumber.hasCompanyDataApiIntegration) {
        const response = await MultiformApi.getCompanyDataByNip(nip.value!);
        if (response) {
          emit('fetchCompanyDataByNip', response, nip.value);
        }
      }
    } catch (e) {
      $errorHandler(e, $i18n.useFpNipForm.FETCH_NIP_DATA_ERROR_HANDLER);
    }
  }
};
</script>
