<template>
  <v-row :class="`alert-wrapper pa-3 alert-wrapper--${alertType}`" no-gutters>
    <v-col class="shrink d-flex justify-center">
      <v-icon :class="`icon icon--${alertType}`">{{ icon }}</v-icon>
    </v-col>
    <v-col class="d-flex align-center">
      <span>
        <slot></slot>
      </span>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {AlertType} from '@/models/enums/AlertType';
import {computed, PropType} from 'vue';

const props = defineProps({
  alertType: {
    type: String as PropType<AlertType>,
    default: AlertType.WARNING,
  },
});
const icon = computed<string>(() => {
  switch (props.alertType) {
  case AlertType.WARNING:
    return 'mdi-alert';
  case AlertType.INFO:
    return 'mdi-information';
  case AlertType.SUCCESS:
    return 'mdi-check';
  case AlertType.ERROR:
    return 'mdi-alert-octagon';
  default:
    return 'mdi-alert';
  }
});
</script>

<style scoped lang="scss">
.alert-wrapper {
  border-radius: 4px;
  border: 1px solid;
  line-height: 24px;
  &--warning {
    background-color: #FFCC1A0F;
    border-color: var(--v-warning-base);
  }

  &--info {
    background-color: #0A73EB0F;
    border-color: var(--v-info-base);
  }

  &--success {
    background-color: #23CD300F;
    border-color: var(--v-success-base);
  }

  &--error {
    background-color: #FA72680F;
    border-color: var(--v-error-base);
  }
}

.icon {
  margin-right: 12px;

  &--warning {
    color: var(--v-warning-base);
  }

  &--info {
    color: var(--v-info-base);
  }

  &--success {
    color: var(--v-success-base);
  }

  &--error {
    color: var(--v-error-base);
  }
}
</style>
