<template>
  <v-layout wrap>
    <v-card-text>
      <simulation-form-step3 :income="borrower.mainIncome" />
      <simulation-form-step3 v-if="borrower.hasAdditionalIncomeSource" :income="borrower.additionalIncome" :income-number="2" />
    </v-card-text>
  </v-layout>
</template>
<script setup lang="ts">
import {FFBorrower} from '@/models/ExpertState';
import SimulationFormStep3 from '@/components/calculator/form/SimulationFormStep3.vue';

defineProps<{borrower: FFBorrower}>()
</script>
<style scoped lang="scss">

</style>
