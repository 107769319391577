export enum CivilStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  WIDOW = 'widow',
  PARTNERSHIP = 'partnership',
  AFTER_MARRIGE = 'afterMarriage',
  SEPARATION = 'separation',
}

export enum FFCivilStatus {
  SINGLE = 'single',
  MARRIED_WITH_SEPARATION_CAPITAL = 'marriedWithSeparationCapital',
  MARRIED_WITHOUT_SEPARATION_CAPITAL = 'marriedWithoutSeparationCapital',
  WIDOW = 'widow',
  PARTNERSHIP = 'partnership',
  AFTER_MARRIGE = 'afterMarriage',
  SEPARATION = 'separation',
}

export enum Profession {
  CIVIL_SERVANT = 21,
  BROKER = 17,
  FINANCIAL_ADVISOR = 14,
  FINANCIAL_BROKER = 18,
  INSURANCE_SPECIALIST = 30,
  ANOTHER_FINANCIAL_SECTOR_EMPLOYEE = 25,
  LAWYER = 6,
  ARCHITECT = 11,
  AUDITOR = 10,
  APOTHECARY = 4,
  CIVIL_ENGINEER = 12,
  BAILIFF = 15,
  ACCOUNTANT = 13,
  DOCTOR = 2,
  NOTARY = 7,
  NURSE = 3,
  SCIENTIST = 19,
  PROSECUTOR = 9,
  LEGAL_COUNSEL = 24,
  PATENT_ATTORNEY = 28,
  REAL_ESTATE_APPRAISER = 27,
  JUDGE = 8,
  TRANSLATOR = 16,
  VET = 5,
  MANAGER = 20,
  UNIFORM_SERVICES = 26,
  SAILOR = 22,
  TEACHER = 29,
  OTHER = 23,
}

export enum FFIncomeType {
  FLEX_TIME_CONTRACT = 1,
  TIME_CONTRACT = 2,
  CONTRACT_FOR_WORK_OR_ORDER = 3,
  SEA_CONTRACT = 4,
  MANAGER_CONTRACT = 5,
  MILITARY_CONTRACT = 26,
  GENERAL_ACTIVITIES = 6,
  ACTIVITIES_3 = 7,
  ACTIVITIES_5 = 8,
  ACTIVITIES_8 = 9,
  ACTIVITIES_17_20 = 10,
  ACTIVITIES_BON_CARD = 11,
  ACTIVITIES_FULL_ACCOUNTING = 12,
  ACTIVITIES_FARMER = 13,
  INCOME_FROM_ABROAD_ACTIVITIES = 14,
  INCOME_FROM_ABROAD_RETIRING = 15,
  INCOME_FROM_ABROAD_PENSION = 16,
  INCOME_FROM_ABROAD_FLEX_TIME_CONTRACT = 27,
  INCOME_FROM_ABROAD_TIME_CONTRACT = 28,
  PERMAMENT_PENSION = 17,
  RETIRING = 18,
  PRIEST = 19,
  RENT_INCOME = 20,
  MATTERNITY_LEAVE = 21,
  DRIVERS_DIET = 22,
  INCOME_HOUSE_SUPLEMENT = 23,
  INCOME_DIVIDENDS = 24,
  APPOINTMENT = 29,
}

export enum IncomeFormType {
  DEFAULT_INCOME_TYPE_FORM = 'DEFAULT_INCOME_TYPE_FORM',
  BUSINESS_INCOME_TYPE_FORM = 'BUSINESS_INCOME_TYPE_FORM',
  BUSINESS_WITHOUT_COSTS_INCOME_TYPE_FORM = 'BUSINESS_WITHOUT_COSTS_INCOME_TYPE_FORM',
  BON_CARD_INCOME_TYPE_FORM = 'BON_CARD_INCOME_TYPE_FORM',
  PERMANENT_INCOME_TYPE_FORM = 'PERMANENT_INCOME_TYPE_FORM',
  RENT_INCOME_TYPE_FORM = 'RENT_INCOME_TYPE_FORM',
  GENERAL_ACTIVITIES_INCOME_TYPE_FORM = 'GENERAL_ACTIVITIES_INCOME_TYPE_FORM',
}

export enum TaxType {
  THRESHOLD_TAX = 'ThresholdTax',
  LINEAR_TAX = 'LinearTax'
}

export enum EducationDegree {
  BASIC = 'basic',
  BASIC_NON_GRADUATE = 'basicNonGraduate',
  GYMNASIUM = 'gymnasium',
  WORK_PREPARE = 'workPrepare',
  MEDIUM = 'medium',
  TECHNICAL = 'technical',
  MEDIUM_AFTER = 'mediumAfter',
  HIGHER = 'higher',
  HIGHER_NON_GRADUATE = 'higherNonGraduate', // Brak obrony
  LICENSE = 'license',
  ENGINEER = 'engineer',
  MASTER = 'master',
  DOCTOR = 'doctor',
  POST_GRADUATE = 'postGraduate',
  MBA = 'mba',
}

export enum HomeStatus {
  HOUSE_OWNER_WITHOUT_MORTGAGE = 'houseOwnerWithoutMortgage',
  HOUSE_OWNER_WITH_MORTGAGE = 'houseOwnerWithMortgage',
  FLAT_OWNER_WITHOUT_MORTGAGE = 'flatOwnerWithoutMortgage',
  FLAT_OWNER_WITH_MORTGAGE = 'flatOwnerWithMortgage',
  WITH_FAMILY = 'withFamily',
  TENEMENT = 'tenement',
  CONDOMINIUM_WITHOUT_MORTGAGE = 'condominiumWithoutMortgage',
  MASTER = 'master',
  POST_GRADUATE = 'postGraduate',
  CONDOMINIUM_WITH_MORTGAGE = 'condominiumWithMortgage',
  TBS = 'tbs',
  RENTAL_FLAT = 'rentalFlat',
  RENTAL_HOUSE = 'rentalHouse',
  ACCOMODATION = 'accomodation',
  WORK_FLAT = 'workFlat',
  OTHER = 'other',
}

export enum LiabilityType {
  mortgage = 'mortgage',
  mortgageLoan = 'mortgageLoan',
  cashLoan = 'cashLoan',
  carLoan = 'carLoan',
  shopLoan = 'shopLoan',
  investLoan = 'investLoan',
  financiaLeasing = 'financiaLeasing',
  leasing = 'leasing',
  businessLoan = 'businessLoan',
  guarantee = 'guarantee',
  businessGuarantee = 'businessGuarantee',
  alimony = 'alimony',
  insurance = 'insurance',
  rent = 'rent',
  billOfExchange = 'billOfExchange',
}

export enum LimitType {
  creditCard = 'creditCard',
  businessCreditCard = 'businessCreditCard',
  accountLimit = 'accountLimit',
  businessAccountLimit = 'businessAccountLimit',
}

export enum IncomeType {
  FLEX_TIME_CONTRACT = 1,
  TIME_CONTRACT = 2,
  CONTRACT_FOR_ORDER = 3,
  CONTRACT_FOR_WORK = 30,
  SEA_CONTRACT = 4,
  MANAGER_CONTRACT = 5,
  MILITARY_CONTRACT = 26,
  GENERAL_ACTIVITIES = 6,
  ACTIVITIES_3 = 7,
  ACTIVITIES_5 = 8,
  ACTIVITIES_8 = 9,
  ACTIVITIES_17_20 = 10,
  ACTIVITIES_BON_CARD = 11,
  ACTIVITIES_FULL_ACCOUNTING = 12,
  ACTIVITIES_FARMER = 13,
  INCOME_FROM_ABROAD_ACTIVITIES = 14,
  INCOME_FROM_ABROAD_RETIRING = 15,
  INCOME_FROM_ABROAD_PENSION = 16,
  INCOME_FROM_ABROAD_FLEX_TIME_CONTRACT = 27,
  INCOME_FROM_ABROAD_TIME_CONTRACT = 28,
  PERMAMENT_PENSION = 17,
  TERM_PENSION = 94,
  STRUCTURAL_PENSION = 32,
  PRE_PENSION = 33,
  RETIRING = 18,
  PRIEST = 19,
  RENT_INCOME = 20,
  MATTERNITY_LEAVE = 21,
  DRIVERS_DIET = 22,
  INCOME_HOUSE_SUPLEMENT = 23,
  INCOME_DIVIDENDS = 24,
  APPOINTMENT = 29,
  SOCIAL_500 = 100,
  NO_INCOME = 95,
}

export enum CompanyType {
  WORK_IN_PUBLIC_OR_PRIVATE_ADMIN = 'workInPublicOrPrivateAdmin',
  WORK_IN_PERSONAL_COMPANY = 'workInPersonalCompany',
  WORK_IN_PUBLIC_COMPANY = 'workInPublicCompany',
  WORK_IN_LIMITED_COMPANY = 'workInLimitedCompany',
  WORK_IN_CIVIL_PARTNERSHIP = 'workInCivilPartnership',
  WORK_IN_GENERAL_PARTNERSHIP = 'workInGeneralPartnership',
  WORK_IN_STATE_OWNED_COMPANY = 'workInStateOwnedCompany',
  WORK_IN_ZOO = 'workInZOO',
  WORK_IN_OTHER_COMPANY_TYPE = 'workInOtherCompanyType',
  WORK_IN_BUDGET_ZONE = 'workInBudgetZone',
  WORK_IN_COOPERATIVE = 'workInCooperative',
  WORK_IN_OTHER_WORKPLACE_TYPE = 'workInOtherWorkplaceType',
}

export enum RealEstateType {
  APARTMENT = 'Apartment',
  HOUSE = 'House',
  MULTI_HOUSE = 'MultiHouse',
  OFFICE = 'Office',
  HOLIDAY_HOME = 'HolidayHome',
  BUILDING_PLOT = 'BuildingPlot',
  RECREATION_PLOT = 'RecreationPlot',
  AGRICULTURAL_PLOT = 'AgriculturalPlot',
  RECREATION_PLOT_WITH_BUILDINGS = 'RecreationPlotWithBuildings',
  RECREATION_PLOT_WITHOUT_BUILDINGS = 'RecreationPlotWithoutBuildings',
  GARAGE = 'Garage',
  CAR_PARKING = 'CarParking',
  TENEMENT_HOUSE = 'TenementHouse',
  SEGMENT = 'Segment',
  HABITATION = 'Habitation',
  STORAGE_ROOM = 'StorageRoom',
}

export enum PersonalSharesSourceType {
  BANK_ACCOUNTS = 'bankAccounts',
  BANK_DEPOSITS = 'bankDeposits',
  HOUSING_BOOKLET = 'housingBooklet',
  INVESTMENT_FUND = 'investmentFund',
  PROPERTY_SALE = 'propertySale',
  OTHER_SHARE = 'otherShare',
}

export enum AssetType {
  REALESTATE_APARTMENT = 'realestate_apartment',
  REALESTATE_PLOT = 'realestate_plot',
  REALESTATE_HOUSE_ONE_FAMILY = 'realestate_house_oneFamily',
  REALESTATE_HOUSE_MULTI_FAMILY = 'realestate_house_multiFamily',
  REALESTATE_OFFICE = 'realestate_office',
  SHARES = 'shares',
  INVESTMENT = 'investment',
  FARMER_MACHINES = 'farmer_machines',
  CAR = 'car',
}

export enum RealEstateRights {
  OWNERSHIP_RIGHT = 'ownershipRight',
  TENEMENT_RIGHT = 'tenementRight',
  CONDOMINIUM_RIGHT = 'condominiumRight',
  PERPETUAL_USUFRUCT = 'perpetualUsufruct',
  ASSET = 'asset',
  OTHER_RIGHT = 'otherRight',
}
