import {ProductType} from '@/commons/enums/ProductType';
import {BikReportSummary} from '@/models/BikReport';
import Division from '@/models/Division';
import {DivisionGroupDTO} from '@/models/DivisionGroup';
import {FiltersResponse, UserFilter} from '@/models/Filter';
import {HistoryElement} from '@/models/HistoryElement';
import {LandOfWork, LandOfWorkDto} from '@/models/LandOfWork';
import {LoginData, PasswordForgot, PasswordReset} from '@/models/LoginData';
import Organisations from '@/models/Organisations';
import {userBusinessHours, UserBusinessHoursElement} from '@/models/UserBusinessHoursElement';
import {UserListElement, UserListElementDto} from '@/models/ppl/UserListElement';
import {IUserInfo} from '@/models/user/IUserInfo';
import {LoginDataResponseDTO} from '@/models/user/LoginDataResponseDTO';
import {RegisterUser} from '@/models/user/RegisterUser';
import User from '@/models/user/User';
import {UserDTO} from '@/models/user/UserDTO';
import FinCRM_User, {CommissionRates, Organisation} from '@/modules/adminPanel/services/FinCRM_User';
import {BranchDto} from '@/modules/branches/models/Branch';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import AuthService from '@/modules/user/AuthService';
import {AgentOpinion} from '@/modules/user/models/AgentOpinion';
import AgentBusinessCard from '@/modules/user/models/BusinessCardStats';
import {prepareUserFormToSend} from '@/modules/user/services/Utils';
import {I18N} from '@/services/enumTranslator/I18NDictionary';
import Logger from '@/services/log/Logger';
import {clone, Utils} from '@/services/utils/BasicUtils';
import {BikQuery} from '@/types/BikQuery';
import {BikTransaction} from '@/types/BikTransaction';
import {DelegationWithStats, DelegationWithStatsDTO} from '@/types/DelegationWithStats';
import Axios, {AxiosResponse} from 'axios';
import {FpAxios} from 'fp-components';
import {sortBy} from 'lodash-es';
import {AdminNote} from '../models/AdminNote';
import moment from 'moment';
import qs from 'qs';
import {DivisionKind} from '@/models/enums/Division';

export interface UsersForOrganisationQuery {
  is_active?: boolean;
  can_manage_prospects?: boolean;
  email?: string;
  name?: string;
  land_id?: number;
  district_id?: number;
  community_id?: number;
  organisation_id?: number | Array<number>;
}

export interface UsersForFiltersQuery extends UsersForOrganisationQuery {
  from_my_organisation?: boolean;
  with_delegation?: boolean;
  is_for_prospects?: boolean;
  with_address?: boolean;
  users?: Array<number>;
}

export interface DelegationShortlistQuery {
  name: string;
  users: Array<number>;
  kind: DelegationShortListKind;
}

export class DelegationShortlistDTO {
  id!: number;
  name!: string;
  users!: Array<User>;
  kind!: DelegationShortListKind;
}

export interface UserProspectListsClosedStatus {
  is_closed: boolean;
}

export enum DelegationShortListKind {
  Deals = 'deals',
  Prospects = 'prospects',
}

export class DelegationShortlist extends DelegationShortlistDTO {
  constructor(delegationShortlistDTO?: DelegationShortlistDTO) {
    super();
    if (delegationShortlistDTO) {
      if (delegationShortlistDTO.users) {
        delegationShortlistDTO.users = delegationShortlistDTO.users.map((user) => new User(user));
      }
      Utils.assign(this, delegationShortlistDTO);
    }
  }
}

export class DelegationProspectListDTO {
  public users?: User[];
  public isClosed?: boolean;
  public delegationListId?: number;
  public delegationListName?: string;
}

export class DelegationProspectList extends DelegationProspectListDTO {
  constructor(dto?: DelegationProspectListDTO) {
    super();
    if (dto) {
      dto.users = dto.users!.map((x) => new User(x));
    }
    Utils.assign(this, dto);
  }
}

type ScansUnlockResponse = {
  agentId: number;
  clientCreationAllowedFrom: string;
  clientCreationAllowedTo: string;
  createdBy: number;
  dealId: number;
  id: number;
  dealStage: Object;
  dealStatus: Object;
};

class UserApi {
  private userAxios = FpAxios.create();
  private customAxios = Axios.create({
    headers: { 'X-App-Hash': process.env.VUE_APP_COMMIT_HASH, },
    baseURL: process.env.VUE_APP_BASE_URL,
  });

  readonly addressBaseUrl = 'address';
  readonly userBaseUrl = 'users';
  readonly authenticateBaseUrl = 'auth';
  readonly accountsBaseUrl = 'accounts';

  private sortUsers(users: User[]): User[] {
    const lowerCaseSorter = (key: 'firstName' | 'lastName') => (user: User) => user[key].toLowerCase();
    return sortBy(users, [lowerCaseSorter('firstName'), lowerCaseSorter('lastName'),]);
  }

  constructor() {
    this.userAxios.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        Logger.endpointErrorHandler(error);
        return Promise.reject(error);
      },
    );

    this.customAxios.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        Logger.endpointErrorHandler(error);
        return Promise.reject(error);
      },
    );
  }

  async sendRegistrationSms(phone: string): Promise<void> {
    await this.customAxios.post(`sms/registration/${phone}`);
  }

  async register(user: RegisterUser): Promise<User> {
    const response = await this.customAxios.post<UserDTO>(`${this.userBaseUrl}/register`, user);
    return new User(response.data);
  }

  async login(loginData: LoginData): Promise<LoginDataResponseDTO> {
    const response = await this.customAxios.post<LoginDataResponseDTO>(`${this.authenticateBaseUrl}`, loginData);
    AuthService.setTokens(response);
    AuthService.User = new User(response.data.user, response.data.privileges, response.data.quiz);
    AuthService.revokeToken(response);
    return response.data;
  }

  async logout() {
    const logoutAxios = clone(this.customAxios);
    const response = await logoutAxios.post(
      `${this.authenticateBaseUrl}/logout`,
      {},
      {
        headers: {
          authorization: AuthService.token,
        },
      },
    );
    return response.data;
  }

  async passwordForgot(userName: string): Promise<void> {
    await this.customAxios.post(`${this.userBaseUrl}/password-forgot`, { username: userName, });
  }

  async passwordChange(secret: string, passwordsWrap: PasswordForgot): Promise<void> {
    await this.userAxios.post(`${this.userBaseUrl}/password-change/${secret}`, {
      password: passwordsWrap.password,
      passwordConfirmation: passwordsWrap.passwordConfirmation,
    });
  }

  async resetPassword(body: PasswordReset) {
    await this.userAxios.post(`${this.userBaseUrl}/password-change`, body);
  }

  async changeRole(id: number) {
    return this.userAxios.put(`${this.authenticateBaseUrl}/role/${id}`);
  }

  async dropRole() {
    return this.userAxios.delete(`${this.authenticateBaseUrl}/role`);
  }

  async userInfo(): Promise<User> {
    const response = await this.userAxios.get<LoginDataResponseDTO>(`${this.authenticateBaseUrl}/info`);
    const user = new User(response.data.user, response.data.privileges, response.data.quiz);
    if (user.language) {
      I18N.setSystemLanguage(user.language);
    }
    return user;
  }

  async getProvisionTable(productType: ProductType | null, ownerId?: number | null) {
    const response = await this.userAxios.get(`${this.userBaseUrl}/provisions/table`, {
      params: {
        product_type: productType,
        user_id: ownerId,
      },
    });
    return response.data;
  }

  async checkIfEmailExists(email: string): Promise<{ exists: boolean }> {
    const response = await this.userAxios.get(`${this.accountsBaseUrl}/check/email?email=${email}`);
    return response.data;
  }

  async checkIfPhoneExists(phone: string): Promise<{ exists: boolean }> {
    const response = await this.userAxios.get(`${this.accountsBaseUrl}/check/phone?phone=${phone}`);
    return response.data;
  }

  async checkNames(firstName: string, lastName: string): Promise<{ exists: boolean }> {
    const response = await this.userAxios.get(`${this.accountsBaseUrl}/check/names`, {
      params: {
        firstname: firstName,
        lastname: lastName,
      },
    });
    return response.data;
  }

  async organisations(): Promise<Array<Organisations>> {
    const response = await this.userAxios.get(`${this.userBaseUrl}/organisations`);
    return response.data;
  }

  async userDetails(id: number): Promise<User> {
    const response = await this.userAxios.get<User>(`${this.userBaseUrl}/${id}`);
    return response.data;
  }

  async userDetailsInfo(id: number): Promise<IUserInfo> {
    const response = await this.userAxios.get<IUserInfo>(`${this.userBaseUrl}/${id}/info`);
    return response.data;
  }

  async usersForFilters(query?: Partial<UsersForFiltersQuery>): Promise<Array<User>> {
    const response = await this.userAxios.get<Array<User>>(`${this.userBaseUrl}/users-for-filters`, {
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'comma', });
      },
    });

    return response.data.map((userDTO: UserDTO) => new User(userDTO));
  }

  async usersForOrganisation(query?: UsersForOrganisationQuery): Promise<Array<User>> {
    const response = await this.userAxios.get<Array<User>>(`${this.userBaseUrl}/users-for-organisation`, {
      params: query,
    });
    return response.data.map((userDTO: UserDTO) => new User(userDTO));
  }

  async divisionEmployees(): Promise<{ id: number; name: string; pesel: string }[]> {
    const response = await this.userAxios.get<{ id: number; name: string; pesel: string }[]>(
      `${this.userBaseUrl}/employees`,
    );
    return response.data;
  }

  async divisions(): Promise<Array<DivisionGroupDTO>> {
    const response = await this.userAxios.get<Array<DivisionGroupDTO>>(`${this.userBaseUrl}/divisions-for-filters`);
    return response.data;
  }

  async divisionsUsers(id: number): Promise<Array<any>> {
    const response = await this.userAxios.get<Array<any>>(`${this.userBaseUrl}/users-from-divison/${id}`);
    return response.data;
  }

  async changeUserDetails(userSettings: FormData): Promise<void> {
    const response = await this.userAxios.put(`${this.userBaseUrl}/settings`, userSettings);
    AuthService.revokeToken(response);
    return response.data;
  }

  async changeUserSetting(userBeforeChanges: User, userToSend: User): Promise<Nullable<User>> {
    const formToSend: FormData = prepareUserFormToSend(userBeforeChanges, userToSend);
    if (!formToSend.entries().next().done) {
      const response = await this.userAxios.put(`${this.userBaseUrl}/${userToSend.id}/settings`, formToSend);
      AuthService.revokeToken(response);
      return response.data;
    } else {
      console.warn('Cannot send FormData /settings');
      console.warn('If you want to change user details, add keys to prepareUserFormToSend method');
      return null;
    }
  }

  async changeBusinessCardVisibility(userId: number, businessCardVisible: boolean): Promise<Nullable<User>> {
    const formToSend = new FormData();
    formToSend.append('businessCardVisible', String(businessCardVisible));
    const response = await this.userAxios.put(`${this.userBaseUrl}/${userId}/settings`, formToSend);
    AuthService.revokeToken(response);
    return response.data.user;
  }

  async updateUserCustomLogoVisibility(userId: number, hasAccessToCustomLogo: boolean): Promise<Nullable<User>> {
    const formToSend = new FormData();
    formToSend.append('hasAccessToCustomLogo', String(hasAccessToCustomLogo));
    const response = await this.userAxios.put(`${this.userBaseUrl}/${userId}/settings`, formToSend);
    AuthService.revokeToken(response);
    return response.data.user;
  }

  async changeUserBranch(userId: number, branchId: Nullable<number> | undefined) {
    if (branchId) {
      const formToSend = new FormData();
      formToSend.append('branchId', String(branchId));
      const response = await this.userAxios.put(`${this.userBaseUrl}/${userId}/settings`, formToSend);
      AuthService.revokeToken(response);
    }
  }

  async getBikQueriesAmount(): Promise<BikQuery> {
    const response = await this.userAxios.get('payments/big/queries');
    return response.data;
  }

  async getBikTransactions(): Promise<Array<BikTransaction>> {
    const response = await this.userAxios.get('payments/big/list');
    return response.data;
  }

  async getLatestBikReports(): Promise<Array<BikReportSummary>> {
    const response = await this.userAxios.get('big/summary/list');
    return response.data.map((bikReportSummary: BikReportSummary) => new BikReportSummary(bikReportSummary));
  }

  async saveLandsOfWork(userId: number, lands: LandOfWork[]): Promise<void> {
    const data: LandOfWorkDto[] = lands.map((value) => ({
      id: value.id!,
      districts: value.districts.map((d) => ({
        id: d.type,
        name: d.name_pl,
      })),
    }));
    const landsTosave = data.filter((land) => land.id);
    await this.userAxios.put(`${this.userBaseUrl}/${userId}/location/range`, {
      userId,
      lands: landsTosave,
    });
  }

  async getLandsOfWork(): Promise<LandOfWork[]> {
    const response = await this.userAxios.get<{ lands: LandOfWorkDto[] }[]>(`${this.userBaseUrl}/location/range`);
    return response.data[0].lands.map((dto) => new LandOfWork(dto));
  }

  async getOfficeAddress(): Promise<Address> {
    try {
      const response = await this.userAxios.get<Address>(`${this.userBaseUrl}/${AuthService.User!.id}/office/address`);
      return new Address(response.data);
    } catch (e) {
      return new Address();
    }
  }

  async getUserHistory(userId: number): Promise<HistoryElement<any>[]> {
    const response = await this.userAxios.get<HistoryElement<any>[]>(`${this.userBaseUrl}/${userId}/history`);
    return response.data;
  }

  async getAddress(addressId: number): Promise<Address> {
    try {
      const response = await this.userAxios.get<Address>(`${this.addressBaseUrl}/${addressId}`);
      return response.data;
    } catch (e) {
      return new Address();
    }
  }

  async getBusinessHoursForUserOrDefault(userId: number): Promise<Array<UserBusinessHoursElement>> {
    const response = await this.userAxios.get<Array<UserBusinessHoursElement>>(
      `${this.userBaseUrl}/${userId}/office/hours`,
    );
    return response.data.length ? response.data.map((x) => new UserBusinessHoursElement(x)) : userBusinessHours;
  }

  async updateUserBusinessCard(userId: number, agentBusinessCard: AgentBusinessCard): Promise<AgentBusinessCard> {
    const response = await this.userAxios.put<AgentBusinessCard>(
      `${this.userBaseUrl}/${userId}/profile`,
      agentBusinessCard,
    );
    return response.data;
  }

  async addOpinion(userId: number, opinion: AgentOpinion): Promise<AgentOpinion> {
    const response = await this.userAxios.post<AgentOpinion>(`${this.userBaseUrl}/${userId}/opinion`, opinion);
    return response.data;
  }

  async editOpinion(userId: number, opinion: AgentOpinion): Promise<AgentOpinion> {
    const response = await this.userAxios.put<AgentOpinion>(
      `${this.userBaseUrl}/${userId}/opinion/${opinion.id}`,
      opinion,
    );
    return response.data;
  }

  async deleteOpinion(userId: number, opinionId: number): Promise<void> {
    await this.userAxios.delete(`${this.userBaseUrl}/${userId}/opinion/${opinionId}`);
  }

  async editBusinessHoursForUser(
    userId: number,
    userBusinessHours: UserBusinessHoursElement[],
  ): Promise<UserBusinessHoursElement[]> {
    const data = clone(userBusinessHours);
    data.map((x) => {
      x.open = x.openDateObject ? moment(x.openDateObject).format('HH:mm') : undefined;
      x.close = x.closeDateObject ? moment(x.closeDateObject).format('HH:mm') : undefined;
    });
    const response = await this.userAxios.put<Array<UserBusinessHoursElement>>(
      `${this.userBaseUrl}/${userId}/office/hours`,
      data,
    );
    return response.data;
  }

  async setPhotoUrl(photoData: FormData): Promise<string> {
    const response = await this.userAxios.put<string>(`${this.userBaseUrl}/photo-url`, photoData);
    return response.data;
  }

  async setLogoUrl(logoData: FormData): Promise<string> {
    const response = await this.userAxios.put<string>(`${this.userBaseUrl}/logo-url`, logoData);
    return response.data;
  }

  async deleteLogoUrl(): Promise<void> {
    const response = await this.userAxios.delete(`${this.userBaseUrl}/logo-url`);
  }

  async deletePhotoUrl(): Promise<void> {
    const response = await this.userAxios.delete(`${this.userBaseUrl}/photo-url`);
  }

  async setUserPhotoUrl(userId: number, photoData: FormData): Promise<string> {
    const response = await this.userAxios.put<string>(`${this.userBaseUrl}/${userId}/photo-url`, photoData);
    return response.data;
  }

  async setUserLogoUrl(userId: number, logoData: FormData): Promise<string> {
    const response = await this.userAxios.put<string>(`${this.userBaseUrl}/${userId}/logo-url`, logoData);
    return response.data;
  }

  async deleteUserPhotoUrl(userId: number): Promise<void> {
    await this.userAxios.delete(`${this.userBaseUrl}/${userId}/photo-url`);
  }

  async deleteUserLogoUrl(userId: number): Promise<void> {
    await this.userAxios.delete(`${this.userBaseUrl}/${userId}/logo-url`);
  }

  async getDelegationUserShortlists(kind: Nullable<DelegationShortListKind>): Promise<Array<DelegationShortlist>> {
    const queryKind = kind ?? null;
    const response = await this.userAxios.get<Array<DelegationShortlist>>(`${this.userBaseUrl}/delegation/list`, {
      params: { kind: queryKind, },
    });
    const delegationShortlist = response.data.map((x) => new DelegationShortlist(x));
    delegationShortlist.map((list) => {
      list.users = this.sortUsers(list.users).map((x) => new User(x));
    });
    return delegationShortlist;
  }

  async removeDelegationUserShortlist(id: number) {
    const response = await this.userAxios.delete(`${this.userBaseUrl}/delegation/list/${id}`);
    return response.data;
  }

  async addDelegationUserShortlist(delegationShortlist: DelegationShortlistQuery): Promise<DelegationShortlist> {
    const response = await this.userAxios.post<DelegationShortlistDTO>(
      `${this.userBaseUrl}/delegation/list`,
      delegationShortlist,
    );
    return new DelegationShortlist(response.data);
  }

  async getShortlist(id: number): Promise<DelegationShortlist> {
    const response = await this.userAxios.get<DelegationShortlistDTO>(`${this.userBaseUrl}/delegation/list/${id}`);
    return new DelegationShortlist(response.data);
  }

  async editDelegationUserShortlist(id: number, userList: Array<User>, listName: string): Promise<DelegationShortlist> {
    const response = await this.userAxios.put<DelegationShortlistDTO>(
      `${this.userBaseUrl}/delegation/list/${id}/users`,
      {
        listId: id,
        users: userList.map((x) => x.id),
        name: listName,
      },
    );
    return new DelegationShortlist(response.data);
  }

  async addDelegationListToUser(userId: number, listId: number): Promise<AxiosResponse> {
    const response = await this.userAxios.put(`${this.userBaseUrl}/delegation/${userId}/list/${listId}`);
    return response;
  }

  async addProspectDelegationListToUser(userId: number, listId: number): Promise<AxiosResponse> {
    const response = await this.userAxios.put(`${this.userBaseUrl}/delegation/prospects/${userId}/list/${listId}`);
    return response;
  }

  async removeDelegationListFromUser(userId: number, listId: number): Promise<AxiosResponse> {
    const response = await this.userAxios.delete(`${this.userBaseUrl}/delegation/${userId}/list/${listId}`);
    return response;
  }

  async addDelegationShortlistForUser(delegationShortlist: DelegationShortlist, userId: number) {
    const response = await this.userAxios.put(
      `${this.userBaseUrl}/delegation/list/${delegationShortlist.id}/user/${userId}`,
    );
    return response.data;
  }

  async getListOfUsersToDelegate(userId: number, query: Partial<UsersForFiltersQuery>): Promise<Array<User>> {
    const response = await this.userAxios.post<Array<User>>(
      `${this.userBaseUrl}/delegation/${userId}?is_active=true&organisation_id=${query.organisation_id}`,
    );
    return response.data.map((x) => new User(x));
  }

  async getDelegationWithStats(userId: number): Promise<DelegationWithStats> {
    const response = await this.userAxios.get<DelegationWithStatsDTO>(`${this.userBaseUrl}/delegation-with-stats`, {
      params: {
        user_id: userId,
      },
    });
    const delegationWithStats = new DelegationWithStats(response.data);
    delegationWithStats.bokLists.forEach((list) => {
      list.users = this.sortUsers(list.users);
    });
    delegationWithStats.bok = this.sortUsers(delegationWithStats.bok);
    return delegationWithStats;
  }

  async updateDelegationList(userId: number, userIds: Array<number>): Promise<DelegationWithStats> {
    const response = await this.userAxios.put(`${this.userBaseUrl}/delegation/${userId}/list`, userIds);
    return new DelegationWithStats(response.data);
  }

  async addUsersToDelegationList(userId: number, userIdsToAdd: Array<number>) {
    const response = await this.userAxios.put(`${this.userBaseUrl}/delegation/${userId}`, { data: userIdsToAdd, });
    return response.data;
  }

  async removeUsersFromDelegationList(userId: number, userIdsToRemove: Array<number>) {
    const response = await this.userAxios.delete(`${this.userBaseUrl}/delegation/${userId}`, { data: userIdsToRemove, });
    return response.data;
  }

  async getProspectDelegationList(userId: number): Promise<Array<DelegationProspectList>> {
    const response = await this.userAxios.get<Array<DelegationProspectList>>(
      `${this.userBaseUrl}/delegation/prospects/${userId}/list`,
    );
    const prospectLists = response.data.map((x) => new DelegationProspectList(x));
    prospectLists.forEach((list) => {
      list.users = this.sortUsers(list.users!);
    });
    return prospectLists;
  }

  async removeProspectListFromUser(userId: number, listId: number): Promise<AxiosResponse> {
    const response = await this.userAxios.delete(`${this.userBaseUrl}/delegation/prospects/${userId}/list/${listId}`);
    return response.data;
  }

  async updateProspectDelegationList(userId: number, userIds: Array<number>, isClosed: boolean) {
    await this.userAxios.post(`${this.userBaseUrl}/delegation/prospects/${userId}`, userIds, {
      params: { is_closed: isClosed, },
    });
  }

  async removeProspectDelegationList(userId: number): Promise<void> {
    await this.userAxios.delete(`${this.userBaseUrl}/delegation/prospects/${userId}`);
  }

  async updateUserProspectStatus(userId: number, isClosed: boolean): Promise<AxiosResponse> {
    const queryParams = isClosed;
    const response = await this.userAxios.put(`${this.userBaseUrl}/delegation/prospects/${userId}/list`, null, {
      params: { is_closed: isClosed, },
    });
    return response;
  }

  async getUserProspectListClosedStatus(userId: number): Promise<UserProspectListsClosedStatus> {
    const response = await this.userAxios.get(`${this.userBaseUrl}/delegation/prospects/${userId}/list-kind`);
    return response.data;
  }

  async filterUsers(filters?: UserFilter): Promise<FiltersResponse<UserListElement>> {
    const response = await this.userAxios.post<FiltersResponse<UserListElement>>(
      `${this.userBaseUrl}/search`,
      filters ? filters.apiData : {},
    );
    response.data.items = response.data.items.map((userDto: UserListElementDto) => new UserListElement(userDto));
    return response.data;
  }

  async addUser(user: FinCRM_User): Promise<FinCRM_User> {
    const response = await this.userAxios.post(
      `${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/organisations/users`,
      user,
    );
    return response.data;
  }

  async editUser(user: FinCRM_User): Promise<void> {
    const response = await this.userAxios.patch(
      `${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/users/${user.id}/fields`,
      user,
    );
  }

  async checkUserLogin(username: string): Promise<boolean> {
    const response = await this.userAxios.get(
      `${process.env.VUE_APP_OLD_BASE_URL}/konta/checkUsername?username=${username}`,
    );
    return response.data.exists;
  }

  async checkUserEmail(email: string): Promise<boolean> {
    const response = await this.userAxios.get(`${process.env.VUE_APP_OLD_BASE_URL}/konta/checkEmail?email=${email}`);
    return response.data.exists;
  }

  async getCommissionRates(organisationId: number): Promise<Array<CommissionRates>> {
    const response = await this.userAxios.get(
      `${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/organisations/${organisationId}/units`,
    );
    return response.data;
  }

  async getDivisions(organisationId: number, kind?: DivisionKind): Promise<Array<Division>> {
    const queryParams = kind ? `?kind=${kind}` : ''
    const response = await this.userAxios.get(`${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/organisations/${organisationId}/onlyDivisions${queryParams}`);
    return response.data;
  }

  async getOrganisations(): Promise<Array<Organisation>> {
    const response = await this.userAxios.get(`${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/organisations`);
    return response.data;
  }

  async changeUserActivityState(index: number, isActive: boolean): Promise<void> {
    if (isActive) {
      await this.userAxios.delete(`${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/users/${index}`);
    } else {
      await this.userAxios.post(`${process.env.VUE_APP_OLD_BASE_URL}/organisations/api/users/${index}`);
    }
  }

  async getUserBranch(id: number): Promise<BranchDto> {
    const response = await this.userAxios.get(`${this.userBaseUrl}/${id}/branch`);
    return response?.data;
  }

  async getUsersWithAssignedList(listId: number, kind: string): Promise<User[]> {
    const queryKind = kind;
    const response = await this.userAxios.get(`${this.userBaseUrl}/delegation/users-assigned/${listId}`, {
      params: { kind: queryKind, },
    });
    return response.data.map((userDto: UserDTO) => new User(userDto));
  }

  async unlockScanVerification(dealId: number): Promise<ScansUnlockResponse> {
    const response = await this.userAxios.post(`${this.userBaseUrl}/client-with-scan/${dealId}`);
    return response.data;
  }

  async fetchAdminNote(id: number): Promise<AdminNote> {
    const response = await this.userAxios.get(`${this.userBaseUrl}/${id}/note`);
    return response.data;
  }

  async saveAdminNote(id: number, note: string): Promise<AdminNote> {
    const response = await this.userAxios.put<AdminNote>(`${this.userBaseUrl}/${id}/note`, { note, });
    return response.data;
  }

  async updateHrLockValue(body: {
    value: boolean,
    userIds: number[],
  }): Promise<void> {
    return (await this.userAxios.put(`${this.userBaseUrl}/update/is-hr-locked `, body)).data;
  }
}

export default new UserApi();
