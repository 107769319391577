<template>
  <div>
    <fp-input
      v-if="isForeignCurrency(store.userInput) || $user.isAppDomain.NESTO_RO"
      dense
      prepend-icon="mdi-label-percent-outline"
      suffix="%"
      :label="$user.isAppDomain.NESTO_RO ? I18NGetter().useCommonTableElements.IRCC : ''"
      v-model="referenceRate"
      decimal="2"
      type="number"/>
    <template v-else>
      <div class="mx-2">
        <v-btn-toggle
          v-model="selectedReferenceRateType"
          mandatory dense rounded
          color="primary" class="mt-2"
          @change="onReferenceRateTypeToggle">
          <v-btn v-for="(value, key) in referenceRateTypes"
                 :value="key"
                 :key="key"
                 class="text-body-2 font-weight-medium"
                 small
          >
            {{ value }}
          </v-btn>
        </v-btn-toggle>
        <div class="body-3 pt-4 pb-2">
          <span v-html-safe="referenceRateInfo"></span>
        </div>
      </div>
      <fp-input
        v-if="selectedReferenceRateType === ReferenceRateMode.COMMON"
        :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.REFERENCE_RATE"
        key="referenceRate"
        dense
        prepend-icon="mdi-label-percent-outline"
        suffix="%"
        v-model="referenceRate"
        decimal="5"
        type="number"/>
      <template v-else>
        <span class="body-3 mx-2 font-weight-medium">WIBOR:</span>
        <fp-input
          xs12
          dense
          prepend-icon="mdi-numeric-3-box-outline"
          suffix="%"
          v-model="referenceRateWibor3m"
          key="referenceRateWibor3m"
          decimal="5"
          type="number"/>
        <fp-input
          xs12
          dense
          prepend-icon="mdi-numeric-6-box-outline"
          suffix="%"
          v-model="referenceRateWibor6m"
          decimal="5"
          key="referenceRateWibor6m"
          type="number"/>
        <span class="body-3 mx-2 font-weight-medium">WIRON:</span>
        <fp-input
          xs12
          dense
          prepend-icon="mdi-numeric-1-box"
          suffix="%"
          v-model="referenceRateWiron1m"
          decimal="5"
          key="referenceRateWiron1m"
          type="number"/>
      </template>
    </template>
    <v-divider class="mt-2 mb-4" />
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {useSimulationCurrency} from '@/components/calculator/useSimulationCurrency';
import {
  ReferenceRateMode,
  useReferenceRate
} from '@/components/calculator/results/userInputPanel/useReferenceRate';

const referenceRateTypes: Record<ReferenceRateMode, string> = {
  [ReferenceRateMode.COMMON]: I18NGetter().useMortgageSimulationResults.COMMON_REFERENCE_RATE,
  [ReferenceRateMode.SPLIT]: I18NGetter().useMortgageSimulationResults.SPLIT_REFERENCE_RATE,
};
const store = useSimulationStore();
const {isForeignCurrency,} = useSimulationCurrency();
const {
  selectedReferenceRateType,
  referenceRate,
  referenceRateWibor3m,
  referenceRateWibor6m,
  referenceRateWiron1m,
  setSplitReferenceRate,
  setCommonReferenceRate,
} = useReferenceRate();

const updateSimulation = store.debouncedUpdateSimulation();

const referenceRateInfo = computed<string>(() =>
  selectedReferenceRateType.value === ReferenceRateMode.COMMON
    ? I18NGetter().useMortgageSimulationResults.COMMON_REFERENCE_RATE_INFO
    : I18NGetter().useMortgageSimulationResults.SPLIT_REFERENCE_RATE_INFO);

const onReferenceRateTypeToggle = (value: ReferenceRateMode) => {
  if (value === ReferenceRateMode.COMMON) {
    setCommonReferenceRate();
  } else {
    setSplitReferenceRate();
  }
  const isSomeReferenceRateSet: boolean = Boolean(
    referenceRate.value || referenceRateWibor3m.value || referenceRateWibor6m.value || referenceRateWiron1m.value
  );
  if (isSomeReferenceRateSet) {
    updateSimulation();
  }
};
</script>
<style lang="scss" scoped>
</style>
