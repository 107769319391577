import {FpAxios} from 'fp-components';

export default async function getDevelopmentVerificationCode(phone: string): Promise<string> {
  try {
    const response = await FpAxios.create().get(`qa/sms/${phone}`);
    return String(response.data);
  } catch (e) {
    return '';
  }
}
