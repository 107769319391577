import googleLoader from 'google-maps';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import _ from 'underscore';
import GoogleAutocompletePrediction from '@/modules/googleAutocomplete/GoogleAutocompletePrediction';
import {AutocompletePlacesTypes} from '@/modules/googleAutocomplete/enums/AutocompletePlacesTypes';
import GooglePlacesMapper from '@/modules/googleAutocomplete/services/GooglePlacesMapper';
import {GooglePlacesTypes} from '@/modules/googleAutocomplete/enums/GooglePlacesTypes';
import {I18N} from '@/services/enumTranslator/I18NDictionary';
import EnvironmentService from '@/env/EnvironmentService';

googleLoader.KEY = 'AIzaSyDI1q9Nh2e3gVapDiAna1ibeF_equM50a8';
googleLoader.LIBRARIES = ['places',];

const appDomainConfig = EnvironmentService.Environment.getAppDomainConfig();
googleLoader.LANGUAGE = appDomainConfig.region.toUpperCase();
googleLoader.REGION = appDomainConfig.region.toUpperCase();

interface IGooglePromise{
  Autocomplete: google.maps.places.AutocompleteService
  Geocoder: google.maps.Geocoder
}

const GooglePromise = new Promise<IGooglePromise>(resolve => {
  googleLoader.load((googleInstance) => {
    resolve({
      Autocomplete: new googleInstance.maps.places.AutocompleteService(),
      Geocoder: new googleInstance.maps.Geocoder(),
    });
  });
});

class GooglePlacesApi {
  private googleApi!: IGooglePromise;

  constructor() {
    GooglePromise.then(x => {
      this.googleApi = x;
    });
  }

  private async getPlaceDetails(placeId: string): Promise<google.maps.GeocoderResult> {
    return new Promise<google.maps.GeocoderResult>((resolve) => {
      this.googleApi.Geocoder.geocode({placeId, }, results => resolve(results[0]));
    });
  };

  public async getAddress(placeId: string): Promise<Address> {
    const response = await this.getPlaceDetails(placeId);
    const foundPlace = GooglePlacesMapper.findPlace(response);
    return new Address({
      landId: foundPlace?.landId || null,
      districtId: foundPlace?.districtId || null,
      country: GooglePlacesMapper.findComponentShortName(response, GooglePlacesTypes.COUNTRY),
      street: GooglePlacesMapper.findComponentLongName(response, GooglePlacesTypes.ROUTE),
      city: GooglePlacesMapper.findComponentLongName(response, GooglePlacesTypes.LOCALITY),
      post: '',
      community: '',
      streetLocal: '',
      streetNum: '',
      zip: '',
    });
  };

  public async getAddressesByInput(input: string, type: AutocompletePlacesTypes): Promise<GoogleAutocompletePrediction[]> {
    const requestOptions: google.maps.places.AutocompletionRequest = {
      input,
      types: [type,],
    };
    return new Promise((resolve, reject) =>
      this.googleApi.Autocomplete.getPlacePredictions(requestOptions, response => {
        const typesFilter = GooglePlacesMapper.typesForFilter(type);
        if (response) {
          resolve(response
            .map(row => new GoogleAutocompletePrediction(row))
            .filter(row => typesFilter ? _.intersection(row.types, typesFilter).length > 0 : true));
        } else {
          reject(new Error('Address has not been found'));
        }
      }));
  };
}

export default new GooglePlacesApi();
