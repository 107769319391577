import {ComponentPublicInstance} from 'vue';

/* LENDA INFO
    to make lenda highlight more than 1 field You have to pass 1'st div with the step class as template ref to useTour.
    it's important that the useTour is used when referred component is mounted. If there is no need of multiple select,
    but You have problem with too long lasting highlights use useTour for the element that should clean previous highlights.
*/

const HIGHLIGHTED_TOUR_CLASS = 'v-tour__target--highlighted' as const
const RELATIVE_TOUR_CLASS = 'v-tour__target--relative' as const
export const useTour = (observedByLendaTemplate: ComponentPublicInstance, tourStepClass: string) => {
  const getHighlightedByVTourElementSelector = (tourStepClass: string) =>
    `.${tourStepClass}.${HIGHLIGHTED_TOUR_CLASS}.${RELATIVE_TOUR_CLASS}`
  const LendasObserverCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
    for (const mutation of mutationsList) {
      if (mutation.type !== 'attributes' || mutation.attributeName !== 'class') {
        continue;
      }
      const classes = (mutation.target as HTMLElement).className.split(' ')
      if (classes.includes(HIGHLIGHTED_TOUR_CLASS) && classes.includes(tourStepClass)) {
        addLendasHighlightClass(tourStepClass);
      }
    }
  }

  const addLendasHighlightClass = (tourStepClass: string) => {
    const isElementHighlightedByVTourPresent =
            !!document.querySelector(getHighlightedByVTourElementSelector(tourStepClass))
    const hasVTourHighlightingClasses = (div: Element) =>
      div.classList.contains(HIGHLIGHTED_TOUR_CLASS) && div.classList.contains(RELATIVE_TOUR_CLASS)

    cleanOthersStepsHighlights();
    if (isElementHighlightedByVTourPresent) {
      const divs = document.querySelectorAll(`.${tourStepClass}`);
      divs.forEach(div => {
        if (!hasVTourHighlightingClasses(div)) {
          div.classList.add(HIGHLIGHTED_TOUR_CLASS, RELATIVE_TOUR_CLASS);
        }
      });
    }
  }
  const cleanOthersStepsHighlights = () => {
    const divsToClean = document.querySelectorAll(`.${HIGHLIGHTED_TOUR_CLASS}.${RELATIVE_TOUR_CLASS}`);
    divsToClean.forEach(div => {
      if (!div.classList.contains(tourStepClass)) {
        div.classList.remove(HIGHLIGHTED_TOUR_CLASS, RELATIVE_TOUR_CLASS);
      }
    });
  }

  const observer: MutationObserver = new MutationObserver(LendasObserverCallback)

  if (observedByLendaTemplate) {
    observer.observe(observedByLendaTemplate.$el, {attributes: true, childList: true, subtree: true,})
  } else {
    console.warn('observedByLendaElement is not available or not properly referenced.')
  }
};
