<template>
  <v-layout>
    <v-flex>
      <v-autocomplete
        :id="id"
        :value="value"
        input-class="fp-outline-input"
        :items="partners"
        :multiple="multiple"
        :search-input.sync="search"
        @input="emitPartner"
        @click:clear="clear"
        color="primary"
        class="fp-input-outline"
        hide-no-data
        outlined
        hide-selected
        :ref="ELEMENT_REF_ID"
        clearable
        :rules="rules"
        no-filter
        :disabled="disabled"
        :required="required"
        :placeholder="placeholderPartner"
        :label="label"
        :return-object="returnObject"
        item-value="id"
        item-text="name"
        hide-details="auto"
        :prepend-icon="prependIcon ? prependIcon : undefined">
        <template slot="item" slot-scope="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-flex v-if="$user.canProcessExternals" shrink class="align-self-center">
      <v-tooltip top>
        <template v-slot:activator="{on}">
          <v-btn v-on="on"
                 :disabled="disabled"
                 @click="openNewPartnerModal"
                 class="mx-0"
                 fab small
                 depressed
                 :color="$vuetify.theme.currentTheme.primary +'33'">
            <v-icon large color="primary">add</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.useDealFromPartnerAutocomplete.ADD_NEW_PARTNER }}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Partner, {FinpackSource} from '@/models/Partner';
import FuzzySearch from '@/services/searchEngine/FuzzySearch';
import DictionaryStore from '@/commons/services/store/DictionaryStore';
import {PartnerType} from '@/modules/partner/enums/PartnerType';
import PartnerApi from '@/modules/partner/services/PartnerApi';
import AddPartnerModal from '@/modules/partner/modals/AddPartnerModal.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
type Model = Partner | null | number | number[]

@Component
export default class DealFromPartnerAutocomplete extends Vue {
  @Prop({required: true, })
  public readonly value!: Model;

  @Prop({default: I18NGetter().useDealFromPartnerAutocomplete.CLIENT_ORIGIN, })
  public readonly label!: string;

  @Prop({default: false, })
  public readonly required!: boolean;

  @Prop({default: false, })
  public readonly disabled!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly autoFocus!: boolean;

  @Prop({default: false, })
  public readonly multiple!: boolean;

  @Prop({default: false, })
  public readonly returnObject!: boolean;

  @Prop({default: 'search', })
  public readonly prependIcon!: string | false;

  @Prop({required: false,})
  public readonly id?: string;

  @Prop({required: false, default: () => [],})
  public readonly partnerTypeFilters?: PartnerType[];

  @Prop({required: false,})
  public readonly autoDefaultPartner?: boolean;

  public search = '';
  public readonly ELEMENT_REF_ID = 'partnerAutocomplete';
  public partnerList: Partner[] = [];
  public sources: FinpackSource[] = [];

  public rules = [
    (v: any) => this.required === false || !!v || this.$i18n.useDealFromPartnerAutocomplete.FIELD_REQUIRED,
  ];

  public async openNewPartnerModal() {
    const modalResponse = await this.$modalService.open<Partner>(AddPartnerModal,
      {maxWidth: 600, persistent: true, fullscreen: undefined, });
    if (modalResponse) {
      DictionaryStore.addToDictionary<Partner>('partnerDictionary', modalResponse);
      this.emitPartner(modalResponse.id);
    }
  }

  public get placeholderPartner() {
    return I18NGetter().useDealFromPartnerAutocomplete.CHOOSE_PARTNER;
  }

  public emitSources(value: Model) {
    if (value) {
      let sources: FinpackSource[] | null = null;
      if (typeof value === 'object' && (value as Partner).sources) {
        sources = (value as Partner).sources as FinpackSource[];
      } else if (typeof value === 'number') {
        sources = this.partnerList.find(x => x.id === value)?.sources || null;
      }
      if (sources) {
        this.$emit('update:sources', sources);
      }
    }
  }

  public async mounted() {
    this.partnerList = await DictionaryStore.getDictionary('partnerDictionary');
    if ((this.$user.privileges.simplePartnerView || this.autoDefaultPartner) && this.$user.defaultPartnerId) {
      const defaultPartner = await PartnerApi.getPartner(this.$user.defaultPartnerId);
      this.partnerList = [defaultPartner,];
      this.emitPartner(this.returnObject !== false ? this.partnerList[0] : this.partnerList[0].id);
    }
    this.emitSources(this.value);
    setTimeout(() => {
      if (this.autoFocus) {
        const ref: any = this.$refs[this.ELEMENT_REF_ID];
        ref.focus();
      }
    }, 50);
  }

  get partners(): Array<Partner> {
    const websiteTypePartnerList = this.partnerList.filter((item) => (
      this.partnerTypeFilters?.length || 0) > 0 && !this.autoDefaultPartner
      ? this.partnerTypeFilters?.includes(item.type!)
      : true
    );
    if (this.search && this.search !== '') {
      return FuzzySearch.sortAndFilter<Partner>(websiteTypePartnerList, this.search);
    }
    return websiteTypePartnerList;
  };

  public clear(): void {
    this.$emit('input', undefined);
  }

  public emitPartner(event: Model): void {
    this.search = '';
    this.emitSources(event);
    this.$emit('input', event);
  }
}
</script>
