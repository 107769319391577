import EnvironmentService from '@/env/EnvironmentService';
import User from '@/models/user/User';
import AuthService from '@/modules/user/AuthService';
import {IIntegrable} from '@/models/interfaces/IIntegrable';

interface IUserCom {
  apiKey: string,
  name?: string,
  email?: string,
  user_id?: string
}

interface IUserEngage {
  refresh(userComData: IUserCom): void,
  pageHit(userComData: IUserCom): void,
  init(userComData: IUserCom): void,
  destroy(): void,
  resetAuth(userComData: IUserCom): void,
}

const IS_USER_COM_ON = EnvironmentService.Environment.isUserComServiceOne;
const API_KEY = 'SJ2OXC';

class UserCom implements IIntegrable {
  private get UserEngage(): IUserEngage | undefined {
    return (<any>window).UE;
  }

  private get UserComData(): IUserCom | undefined {
    return (<any>window).civchat;
  }

  private set UserComData(newValue: IUserCom | undefined) {
    (<any>window).civchat = newValue;
  }

  public connect() {
    if (IS_USER_COM_ON) {
      this.UserComData = {
        apiKey: API_KEY,
      };
    } else {
      console.warn('User.com service is off');
    }
  }

  public setUserData(user: Nullable<User>) {
    if (this.UserComData && IS_USER_COM_ON) {
      if (user) {
        this.UserComData.name = user.displayName;
        this.UserComData.email = user.email!;
        this.UserComData.user_id = user.username;
      } else {
        this.UserComData.name = undefined;
        this.UserComData.email = undefined;
        this.UserComData.user_id = undefined;
      }
      if (this.UserEngage) {
        this.UserEngage.resetAuth(this.UserComData);
      }
    }
  }
}

export default new UserCom();
