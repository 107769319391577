import {RouteConfig} from 'vue-router';
import { Routes } from '@/router/Routes';
const DelegationListsPanel = () => import(/* webpackChunkName: "DelegationLists" */ '@/modules/adminPanel/views/DelegationListsPanel.vue');

const DelegationListsRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.ADMIN.DELEGATION_LISTS,
    component: DelegationListsPanel,
  },
];

export default DelegationListsRoutes;
