import {computed, getCurrentInstance} from 'vue';
import User from '@/models/user/User';
import VueRouter, {Route} from 'vue-router';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import GoToService from '@/router/GoToService';
import {isRefValid} from '@/services/utils/isRefValid';

export const useInstance = () => {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error('useInstance must be called within a component instance.');
  }
  const $modalService = instance?.proxy.$modalService!;
  const $snackbarService = instance?.proxy.$snackbarService!;
  const $vuetify = instance?.proxy.$vuetify!;
  const $log = instance?.proxy.$log!;
  const $errorHandler = instance?.proxy.errorHandler!;
  const $refs = instance?.proxy.$refs!;
  const $goto: typeof GoToService = instance?.proxy.$goto!;
  const $user = computed<User>(() => instance.proxy.$user);
  const $router = computed<VueRouter>(() => instance?.proxy.$router);
  const $route = computed<Route>(() => instance?.proxy.$route);
  const $i18n: I18NInterface = instance?.proxy.$i18n;
  const $onboarding = instance?.proxy.$onboarding;
  const $env = instance?.proxy.$env;
  const $clipboard = instance?.proxy.$clipboard;
  const $validate = (ref: string): boolean => {
    const formRef = instance.proxy.$refs[ref] as any;
    formRef.$el?.closest('.v-card__text');
    return isRefValid(formRef, ref, formRef.$el?.closest('.v-card__text'));
  };
  const $i18nFormatter = instance?.proxy.$i18nFormatter;
  const $set = instance?.proxy.$set!;
  const $breakpoint = instance?.proxy.$vuetify.breakpoint;
  const $currentTheme = instance?.proxy.$vuetify.theme.currentTheme;
  return {
    $modalService,
    $snackbarService,
    $errorHandler,
    $user,
    $router,
    $route,
    $i18n,
    $refs,
    $goto,
    $onboarding,
    $env,
    $i18nFormatter,
    $set,
    $clipboard,
    $log,
    $vuetify,
    $breakpoint,
    $validate,
    $currentTheme,
  };
};
