import moment from 'moment';
import DateUtils from '@/services/utils/DateUtils';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const getDateFormat = (monthsVerbally: boolean) => monthsVerbally ? 'DD MMMM YYYY' : 'DD.MM.YYYY';

type DateParam = string | Date;

export function noDataHandler(value: any, formattedValue: DateParam): string {
  return value ? `${formattedValue}` : I18NGetter().useDateFilters.NONE;
}

export function noDataDefault(value: any) {
  return noDataHandler(value, value);
}

export function dateAndTimeFormat(value: DateParam, monthsVerbally: boolean = false) {
  return noDataHandler(value, moment(String(value)).format(`${getDateFormat(monthsVerbally)} HH:mm`));
}

export function dateTimeFormat(value: DateParam, monthsVerbally: boolean = false) {
  return noDataHandler(value, moment(String(value)).format(`${getDateFormat(monthsVerbally)} HH:mm`));
}

export function dateFormat(value: DateParam, monthsVerbally: boolean = false) {
  return noDataHandler(value, moment(String(value)).format(getDateFormat(monthsVerbally)));
}

export function dayMonthFormat(value: DateParam) {
  return noDataHandler(value, moment(String(value)).format('DD MMMM'));
}

export function monthYearFormat(value: DateParam) {
  return noDataHandler(value, moment(String(value)).format('MMMM YYYY'));
}

export function dayFormat(value: DateParam) {
  return `${DateUtils.isToday(value) ? I18NGetter().useDateFilters.TODAY : (DateUtils.isTomorrow(value) ? I18NGetter().useDateFilters.TOMORROW : moment(String(value)).format('dddd'))}`;
}

export function dateTextFormat(value: DateParam, showTime: boolean = true) {
  return `${DateUtils.isToday(value) ? I18NGetter().useDateFilters.TODAY : (DateUtils.isTomorrow(value) ? I18NGetter().useDateFilters.TOMORROW : dateFormat(value, true))}` +
    (showTime ? `, ${I18NGetter().useDateFilters.AT} ${timeFormat(value)}` : '');
}

export function dayTime(value: DateParam) {
  return `${dayFormat(value)} ${timeFormat(value)}`;
}

export function timeFormat(value: DateParam) {
  return noDataHandler(value, moment(String(value)).format('HH:mm'));
}

export function yearFormat(year: number) {
  return noDataHandler(year, `${year} ${I18NGetter().useDateFilters.YEARS}`);
}

export function monthFormat(months: number) {
  return noDataHandler(months, `${months} ${I18NGetter().useDateFilters.MONTHS}`);
}

export function fileNameFormat(file: any) {
  return file.name || file.fileName;
}

export function decimalFormat(value: string, precision: number = 2) {
  return parseFloat(value).toFixed(precision)
}

export function numeralFormat(number, decimals = 2) {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};
