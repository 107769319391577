import {OrganisationId} from '@/models/enums/OrganisationId';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import User from '@/models/user/User';
import {Privileges} from '@/models/user/Privileges';
import {Role} from '@/models/user/Role';
import {Person} from '@/models/Person';
import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {BranchType} from '@/modules/branches/enums/BranchType';

export type UserDivisionStatus = { id: number, isMainChief: boolean };
export type UserOrganisationStatus = UserDivisionStatus;

export abstract class UserDTO extends Person {
  email!: string;
  firstName!: string;
  lastName!: string;
  username!: string;
  phone!: string;
  pesel!: string;
  nip?: string;
  password?: string;
  createdAt?: string;
  requirePasswordChange: boolean = false;
  requireBankSecrecy?: boolean;
  role: Role = new Role();
  landId: number | null = null;
  districtId: number | null = null;
  organisationId?: OrganisationId;
  organisationUnitId?: number;
  divisionsChief?: Array<UserDivisionStatus>;
  organisationsChief?: Array<UserOrganisationStatus>;
  officeAddress?: Address;
  isExpired?: boolean = false;
  isPaid?: boolean = true;
  isActive?: boolean = true;
  isChief?: boolean;
  forceLogout!: boolean;
  privileges: Privileges = new Privileges();
  address: Address = new Address();
  url?: string;
  widgetsConfigurationQuery?: string;
  canDelegateToCreatedByBok?: Array<User> = [];
  canDelegateToCreatedByMe?: Array<User> = [];
  branchId?: Nullable<number>;
  branchAddress?: string;
  branchType?: BranchType;
  businessCardVisible: boolean = false;
  language?: SystemLanguage;
  cvr?: number;
  lastSuccessfulLoginAt?: string;
  applyCvrSortingForStarters?: boolean;
  cvrSortingForStartersEndsAt?: string;
  officeAddressId?: number;
}
