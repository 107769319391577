<template>
  <v-layout  wrap>
    <v-flex v-if="items === null">
      <fp-loader/>
    </v-flex>
    <template v-else-if="items">
      <v-flex xs12 class="mt-6">
        <p class="font-weight-medium body-1 mb-1">{{ I18NGetter().useLossReasonForm.LOSS_REASON }}</p>
      </v-flex>
      <fp-select
        xs12
        :items="items"
        required
        :label="I18NGetter().useLossReasonForm.REASON"
        item-key="id"
        item-name="reason"
        @input="setLossReasonName"
        v-model="value.lossReason"/>
      <v-flex xs12 pt-4>
        <v-textarea
          v-model="value.lossReasonDescription"
          class="fp-input-outline"
          outlined
          :label="I18NGetter().useLossReasonForm.ADDITIONAL_COMMENT"/>
      </v-flex>
    </template>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ProspectLossReasonDictionaryType} from '@/types/Dictionaries';
import {LossReason} from '@/types/ProspectLossReason';
import DictionaryStore from '@/commons/services/store/DictionaryStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';

@Component
export default class LossReasonForm extends Vue {
  @Prop({required: true, default: () => new LossReason(), })
  public readonly value!: LossReason;

  public I18NGetter: () => I18NInterface = I18NGetter;
  public items: Nullable<ProspectLossReasonDictionaryType[]> = null;

  async mounted() {
    this.items = await DictionaryStore.getDictionary('prospectLossReasonDictionary');
  }

  public setLossReasonName() {
    this.value.lossReasonName = this.items?.find(x => x.id === this.value.lossReason)?.reason;
  }
}
</script>

<style scoped lang="scss">

</style>
