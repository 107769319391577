<template>
  <fp-field :keys="$props" ref="radio">
    <p class="radio-group-label" v-if="label">{{label}}
      <span v-if="tooltip" style="margin-left: 4px;">
        <v-icon class="fw-pointer" v-fp-tooltip="tooltip">info</v-icon>
      </span>
    </p>
    <v-radio-group :class="{'radio-width-fix': !fillWidth, 'radio-row': row, 'mt-0 pt-0': solo}"
                   :id="id"
                   v-on="$listeners"
                   :prependIcon="prependIcon"
                   :appendIcon="appendIcon"
                   v-model="model"
                   hide-details="auto"
                   :rules="rules"
                   :required="required"
                   :validate-on-blur="validateOnBlur"
                   :column="!row"
                   :row="row"
                   :disabled="disabled"
                   class="fp-radio-group">
      <template v-for="(item, index) in items">
        <v-row no-gutters :key="index" class="align-center mt-0" :class="{
          'solo-radio': solo,
          'connected-radio': connected,
          'radio-holder': !row && !solo && !connected
        }">
          <v-radio
            @click.stop
            style="width: 100%"
            color="primary"
            :class="{
              'description-label': item[description],
              'clearable': clearable,
              'fill-width': fillWidth,
              'radio-focused': index === focusedItem,
              'fp-input-outline': outline,
              'box': box,
              'dense': dense,
              'fp-radio-group': true,
              'primary--text': !disabled && !item.disabled,
            }"
            :disabled="item.disabled"
            :label="item[description] ? undefined : item[itemName]"
            :value="returnObject ? item : item[itemKey]">
            <template #label>
              <slot name="label"
                    :item="item"
                    :label="item[itemName]"
                    :description="item[description]"
                    :radioIndex="index">
                <p class="d-block mb-0 text--primary">
                  {{item[itemName]}}
                  <v-tooltip max-width="440px" top v-if="item.tooltip">
                    <template v-slot:activator="{on}">
                      <v-icon
                          v-on="on"
                          size="18px"
                          class="tooltip">
                        info
                      </v-icon>
                    </template>
                    {{item.tooltip}}
                  </v-tooltip>
                </p>
                <p v-if="!solo && item[description]" class="mb-0">
                  <span class="d-inline-block">{{ item[description] }}</span>
                </p>
              </slot>
            </template>
          </v-radio>
          <v-btn v-if="clearable && item[itemKey] === model" :key="index" :color="$vuetify.theme.currentTheme.error + '33'"
                 depressed icon class="ml-1">
            <v-icon color="error" @click="emitValue(null)">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-radio-group>
  </fp-field>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import { isObject, isEqual } from 'lodash-es';
import {VBtn, VIcon, VRadio, VRadioGroup} from 'vuetify/lib';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import FpValidationMixin from '@/components/fpComponents/services/validationMixin';

export default {
  name: 'FpRadioGroup',
  mixins: [FpValidationMixin,],
  components: {VBtn, VIcon, VRadio, VRadioGroup,},
  props: {
    id: {
      type: String,
    },
    itemKey: {
      type: String || Boolean,
      default: 'type',
    },
    hideDetails: {
      default: false,
      type: Boolean,
    },
    itemName: {
      type: String,
      default: 'name_pl',
    },
    description: {
      type: String,
      default: 'description',
    },
    autoscroll: {
      type: Boolean,
      default: false,
    },
    connected: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    box: {
      default: () => false,
      type: Boolean,
    },
    outline: {
      default: () => true,
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    fillWidth: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    clear: {
      type: Object || Array,
    },
    defaultObjectAfterClear: {
      type: Object,
      default: () => {},
    },
    items: {
      default: () => ([
        {
          type: true,
          name_pl: I18NGetter().useButtonCommons.YES,
        },
        {
          type: false,
          name_pl: I18NGetter().useButtonCommons.NO,
        },
      ]),
    },
    tooltip: {
      type: Object || String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    focusedItem() {
      return this.items.findIndex(item => {
        if (isObject(this.model)) {
          return isEqual(item.type, this.model);
        } else {
          return item.type === this.model;
        }
      });
    },
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.emitValue(newValue);
      },
    },
  },
  methods: {
    emitValue(value) {
      if (value === true) {
        this.$emit('update:clear', Array.isArray(this.clear) ? [this.defaultObjectAfterClear,] : this.defaultObjectAfterClear);
      } else if (value === false) {
        this.$emit('update:clear', Array.isArray(this.clear) ? [] : (typeof this.clear === 'object' ? {} : null));
      }
      this.$emit('input', value);
      this.$emit('change', value);
      setTimeout(() => {
        const ref = this.$refs.radio;
        if (this.autoscroll && ref && ref.$el && ref.$el.nextElementSibling) {
          VueScrollTo.scrollTo(ref.$el.nextElementSibling, { offset: -80, });
        }
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip {
  cursor: pointer;
  color: fpShadow(.6);
}
::v-deep .v-input__prepend-outer {
  margin-top: 4px;
}
::v-deep .v-input__append-outer {
  margin-top: 4px;
}
  ::v-deep.v-input--selection-controls {
    margin-top: 2px;
    .v-label {
      display: block;
    }
  }
  .description-label {
    ::v-deep label {
      min-height: 100%;
      flex-wrap: wrap;
    }
  }
  .connected-radio {
    position: relative;
    &:not(:last-child) {
      padding-bottom: 12px;
    }
    &:not(:first-child):before {
      content: '';
      position: absolute;
      min-height: calc(50% - 18px);
      border-left: 1px solid #232D3A;
      border-right: 1px solid #232D3A;
      opacity: .2;
      top: 0;
      left: 10.5px;
    }
    &:not(:last-child):after {
      content: '';
      position: absolute;
      min-height: calc(50% - 6px);
      border-left: 1px solid #232D3A;
      border-right: 1px solid #232D3A;
      opacity: .2;
      bottom: 0;
      left: 10.5px;
    }
  }

  .v-radio {
    &.fp-input-outline {
      min-height: 36px;
      padding: 6px 12px 6px 6px;
      border-width: 1px;
      border-color: fpShadow(.38);
      border-style: solid;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      transition: border .3s;
      &:hover {
        border-color: $primary;
      }
    }
  }
  .solo-radio {
      min-height: 66px;
        ::v-deep .v-radio {
          padding: 6px 0;
        }
      ::v-deep label {
        flex-wrap: wrap;
        padding-left: 8px;
        span:first-child {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
        span:last-child {
          font-size: 14px;
          line-height: 20px;
          font-style: italic;
        }
      }
      ::v-deep .v-radio:not(.v-item--active) {
         label {
          opacity: 0.6;
        }
      }
    }
  .fp-radio-group:not(.radio-row) .v-radio:not(.solo-radio) .v-input--radio-group__input {
    > *:not(:first-child):not(:last-child) {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    > *:first-child {
      margin-bottom: 4px;
    }
    > *:last-child {
      margin-top: 4px;
    }
  }

  //Const styles only for Expert Online
  .radio-width-fix {
    ::v-deep .v-input__control > .v-input__slot {
      width: fit-content;
    }
  }

  .clearable {
    width: 100%;
  }

  .fill-width{
    width: 100%;
    ::v-deep .v-input--radio-group__input {
      width: 100%;
    }
    ::v-deep .v-input__control {
      width: 100%;
      .fp-radio-group {
        flex: 1;
      }
      ::v-deep label {
        width: calc(100% - 32px) !important;
        min-height: 100% !important;
      }
    }
  }

  .mz-style {
    margin: 8px 0;
    float: left;
    padding-left: 8px;
    width: 100%;
    min-height: 52px;
    line-height: 52px;
    background: #f5f8fb;
    border: 2px solid #f5f8fb;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 17px;
    transition: border .3s;
    &:hover {
      border: 2px solid #82b1ff;
    }
  }
  ::v-deep label {
    height: 100% !important;
    width: calc(100% - 32px) !important;
  }
  .radio-group-label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px!important;
    text-align: left;
  }
  .radio-holder:not(:last-child) {
    margin-bottom: 12px;
  }
</style>
