<template>
  <v-alert :value="value && isMaximumAvailableLTV" type="info" transition="scroll-y-transition" dense>
    {{$i18n.useMortgageSimulationFormStep1.HIGH_LTV_ALERT}}
  </v-alert>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import EnvironmentService from '@/env/EnvironmentService';

defineProps<{
  value: boolean;
}>();

const store = useSimulationStore();

const isMaximumAvailableLTV = computed<boolean>(() => {
  const percentage = EnvironmentService.Environment.getAppDomainConfig().maximumLTVPercentage;
  const loanAmount: number = store.userInput?.loanAmount || 0;
  const hypothecValue: number = store.userInput?.hypothecValue || 0;
  return Boolean(loanAmount && loanAmount > ((hypothecValue * percentage) / 100));
});

</script>
