import GoToService from '@/router/GoToService';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {Utils} from '@/services/utils/BasicUtils';
import {
  computed,
  ComputedRef,
  reactive,
  Ref,
  ref,
  SetupContext,
  ComponentPropsOptions,
  getCurrentInstance,
  onMounted
} from 'vue';
import Deal from '@/models/Deal';
import DealsApi from '@/modules/deals/services/DealsApi';
import {FormSteps} from '@/components/calculator/CalculatorRoutes';
import {FFHousehold, FFHouseholdDTO} from '@/models/simulation/Simulation';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
export const useHouseholds = () => {
  const store = useSimulationStore();

  const households = computed<FFHouseholdDTO[]>({
    get: () => store.userInput?.households!,
    set: (value: FFHouseholdDTO[]) => { store.userInput!.households = value; },
  });

  onMounted(() => {
    if (households.value.length === 0) {
      households.value = [new FFHousehold(),];
    }
  });
  const householdTabs = computed(() => households.value.map((x, index) => `${I18NGetter().useSimulationForm.HOUSEHOLD_NR} ${index + 1}`));
  const borrowerTabs = (householdIndex: number) => computed(() => households.value[householdIndex].borrowers.map((x, index) => `${I18NGetter().useSimulationForm.BORROWER_NR} ${index + 1}`));
  return {
    households,
    householdTabs,
    borrowerTabs,
  };
};

export const useDealForm = () => {
  const store = useSimulationStore();
  const instance = getCurrentInstance();

  const dealId: ComputedRef<number | undefined> = computed(() =>
    +Utils.getFirstQuery(instance!.proxy.$route.query.dealId) || undefined);
  const fetchDeal: () => Promise<void> = async() => {
    if (dealId.value && store.deal?.id !== dealId.value) {
      await store.setDeal(dealId.value);
    }
  };
  return {
    fetchDeal,
  };
};

export const useFormNavigation = () => {
  const instance = getCurrentInstance();
  const store = useSimulationStore();
  const stepperModel: ComputedRef<number> = computed(() => +instance?.proxy.$route.meta?.stepperValue);

  const goToStep = (step: FormSteps) => {
    GoToService.simulationForm(store.productType!, step, store.simulationId, store.deal?.id);
  };

  const showOffers = async() => {
    try {
      if (!store.simulationId) {
        await store.createOrUpdateSimulation();
      }
      GoToService.simulationSummary(store.productType!, store.simulationId!, store.deal?.id);
    } catch (e) {
      instance?.proxy.errorHandler(e, I18NGetter().useSimulationForm.ERROR_HANDLER_SHOW_OFFERS);
    }
  };

  const previousStep = () => {
    const previousStep = stepperModel.value - 1;
    if (previousStep > 0) {
      goToStep(previousStep);
    } else {
      console.error('invalid step', previousStep);
    }
  };

  const nextStep = () => {
    const nextStep = stepperModel.value + 1;
    const numberOfSteps = Object.values(FormSteps).filter(x => typeof x === 'string').length;
    if (nextStep <= numberOfSteps) {
      goToStep(nextStep);
    } else {
      console.error('invalid step', previousStep);
    }
  };

  const goBackToForm = () => {
    GoToService.simulationForm(
      store.productType!,
      FormSteps.CREDIT_PARAMETERS,
      store.simulationId!,
      store.deal?.id);
  };

  return {
    showOffers,
    nextStep,
    previousStep,
    stepperModel,
    goBackToForm,
  };
};
