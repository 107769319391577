<template>
  <v-row>
    <v-col class="pt-0 pb-4">
      <v-row>
        <v-col>
          <p class="all-caps-header mb-0">{{sectionName}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <slot/>
        </v-col>
      </v-row>
      <v-divider class="mt-7" v-if="hideDivider === false"/>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class MultiformSection extends Vue {
  @Prop({required: true, type: String,})
  public readonly sectionName!: string;

  @Prop({required: false, default: false,})
  public readonly hideDivider!: boolean;
}
</script>
<style scoped lang="scss">
</style>
