import {Person, PersonDto} from '@/models/Person';
import {Address, AddressDTO} from '@/modules/multiForm/shared/MultiformModels';
import {ProspectAgent} from '@/models/Prospect';
import {Utils} from '@/services/utils/BasicUtils';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {OrganisationId} from '@/models/enums/OrganisationId';
import {Searchable} from '@/services/searchEngine/fuzzySearch/Searchable';
import BranchListElement, {BranchDto, BranchListElementDto} from '@/modules/branches/models/Branch';

// TODO: rozszerzć interface w przyszłości
interface UserStats {
  activitiesCreated: {
    statsGeneral: {
      activitiesCount: number;
      activitiesCreatedByAgentCcCount: number;
      activitiesCreatedByMeCount: number;
      activitiesDoneCount: number;
      activitiesForProspectCount: number;
    }
  }
  deals: {
    statsGeneral: {
      dealsCreatedByAgentCcCount: number
      dealsCreatedByLeadsCount: number
    }
  }
}

export interface UserListElementDto extends PersonDto {
  canMeetOutsideOffice: boolean;
  officeAddress: Nullable<AddressDTO>;
  stats?: UserStats
  canManageWidgets?: boolean;
  canManageProspects?: boolean;
  organisationId?: OrganisationId
  branchId?: Nullable<number>;
}

export class UserListElement extends Person implements NullableDeep<UserListElementDto>, Searchable {
  canMeetOutsideOffice!: boolean;
  canManageWidgets?: boolean;
  canManageProspects?: boolean;
  officeAddress: Nullable<Address> = null;
  stats?: UserStats;
  isActive?: boolean;
  isChief?: boolean;
  organisationId?: OrganisationId;
  userName?: OrganisationId;
  branchId?: Nullable<number>;
  branch?: Nullable<BranchListElementDto>;

  username?: string;
  hasUserAccount?: boolean;
  createdAt?: string;
  updatedAt?: string;
  cvr?: number;

  constructor(dto?: UserListElementDto) {
    super(dto);
    Utils.assign(this, dto);
    if (dto?.officeAddress) {
      this.officeAddress = new Address(dto.officeAddress);
    }
  }

  get textToSearch(): string {
    return this.displayName + this.email + this.phone + this.userName;
  }

  get organisationName(): Nullable<string> {
    return this.organisationId ? I18NGetter().OrganisationId[this.organisationId] : null;
  }

  get displayNameWithOrganisation(): string {
    if (this.organisationName) {
      return `${this.displayName} (${this.organisationName}) #${this.id}`;
    } else {
      return `${this.displayName} #${this.id}`;
    }
  }

  get displayNameWithOrganisationAndEmail(): string {
    return `${this.displayNameWithOrganisation} ${this.email}`;
  }

  get displayNameWithIdAndOrganisationAndActiveStatus(): string {
    let baseString = `${this.displayName} #${this.id} ${this.organisationName}`;
    if (!this.isActive) {
      baseString += ` ${I18NGetter().useDivisionPreview.INACTIVE}`;
    }
    return baseString;
  }

  get prospectAgent(): ProspectAgent {
    return new ProspectAgent({
      email: this.email!,
      name: this.displayName,
      crmId: this.id!,
    }, this.photoUrl || undefined);
  }
}
