import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
import BranchesList from '@/modules/branches/views/BranchesList.vue';
import BranchDetails from '@/modules/branches/views/BranchDetails.vue';

const BranchesRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.BRANCHES.BRANCHES_LIST,
    component: BranchesList,
  },
  {
    path: ':id',
    name: Routes.BRANCHES.BRANCH_DETAILS,
    component: BranchDetails,
  },
];

export default BranchesRoutes;
