import {FpAxios} from 'fp-components';
import AuthService from '@/modules/user/AuthService';
import CRMNotification from '@/models/notification/CRMNotification';
import {AxiosInstance} from 'axios';

export interface CRMNotificationObject {
  items: CRMNotification[],
  totalUnread: number,
}

export interface CRMNotificationResponse extends CRMNotificationObject{
  page: number,
}

class NotificationApi {
  private notificationAxios: AxiosInstance = FpAxios.create();
  private baseUrl: string = 'notifications';

  get socket(): WebSocket {
    return new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}${this.baseUrl}`, AuthService.token!);
  }

  public async markAsRead(id: number): Promise<void> {
    await this.notificationAxios.post(`${this.baseUrl}/mark/${id}`);
  }

  public async markAllAsRead(): Promise<void> {
    this.notificationAxios.post(`${this.baseUrl}/mark`);
  }

  public async getNotifications(page: number = 0): Promise<CRMNotificationResponse> {
    const response = await this.notificationAxios.get<CRMNotificationResponse>(`${this.baseUrl}/list`, {
      params: {page, },
    });
    return response.data;
  }
}

export default new NotificationApi();
