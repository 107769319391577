<template>
  <v-row class="no-gutters">
    <v-col>
      <p class="mb-0 font-weight-medium">{{ $i18n.useSmsInput.VERIFICATION_CODE }}</p>
      <v-otp-input
          class="sms-class"
          :value="value"
          :disabled="disabled"
          @input="$emit('input', $event)"
          @finish="$emit('finish', $event)"
          :hide-spin-buttons="true"
          type="number"/>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

export const SMS_CODE_LENGTH: number = 6;

@Component
export default class SmsInput extends Vue {
  @Prop({required: true, type: String,})
  readonly value!: string;

  @Prop({default: false, type: Boolean, })
  readonly disabled!: boolean;
}
</script>
<style scoped lang="scss">
.sms-class{
  ::v-deep input {
    font-weight: 600;
    font-size: 24px;
    max-height: 56px;
  }
}
</style>
