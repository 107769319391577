import {OfferDataTableElementKey, OfferTableElement} from '@/components/calculator/results/offers/OfferTableElement';
import {Offer} from '@/models/simulation/Simulation';
import {numeralFormat} from '@/filters/StringFilters';
import {MortgageSimulationDemand, SimulationDemand} from '@/models/Demand';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {TableElementHeight} from '@/components/calculator/results/tableElements/TableElementsConstants';
import EnvironmentService from '@/env/EnvironmentService';
import {renderDTI} from '@/components/calculator/results/tableElements/helpers/DTI';

const currentCurrency = EnvironmentService.Environment.currentCurrency();
export const RefusedMortgageTableElements: OfferTableElement[] = [
  {
    condition: (): boolean => EnvironmentService.Environment.isAppDomain().NESTO_RO,
    header: () => I18NGetter().useMortgageTableElements.DTI,
    height: TableElementHeight.INPUT,
    printHeight: TableElementHeight.DEFAULT,
    key: OfferDataTableElementKey.DTI,
    runtimeTemplate: true,
    render: (x: Offer) => renderDTI(x, true).header,
    open: true,
    children: [
      {
        header: () => '',
        key: OfferDataTableElementKey.DTI_DETAILS,
        render: (x: Offer) => renderDTI(x, true).details,
        height: 230,
      },
    ],
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_BANK_MARGIN,
    key: OfferDataTableElementKey.MARGIN,
    height: TableElementHeight.DOUBLE_DEFAULT,
    render: (x: Offer) => x.changingMargin ? `<div class="d-flex flex-column">
        <span>${I18NGetter().useRefusedMortgageTableElements.FOR} ${x.changingMargin?.initialPeriod} ${I18NGetter().useRefusedMortgageTableElements.MCY}: ${x.changingMargin.initialValue || 0} %</span>
        <span>${I18NGetter().useRefusedMortgageTableElements.AFTER} ${x.changingMargin?.initialPeriod} ${I18NGetter().useRefusedMortgageTableElements.MCU}: ${x.changingMargin.eventualValue || 0} %</span>
      </div>` : `${x.interestRate.margin || 0} %`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_BANK_COMMISSION,
    key: OfferDataTableElementKey.PROVISION,
    render: (x: Offer) => `${x.initialCosts.provision.percentage} %`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_LOAN_AMOUNT,
    key: OfferDataTableElementKey.LOAN_AMOUNT,
    height: TableElementHeight.DOUBLE_DEFAULT,
    runtimeTemplate: true,
    render: (x: Offer, userInput?: Nullable<SimulationDemand>) => `
      <div class="d-flex flex-column align-center">
      <span class="font-weight-medium" :class="{'error--text': offer.restrictions.loanAmountRestrictions.isLoanAmountViolated}">
      <span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_FEMALE}</span>
       ${numeralFormat(x.customizations?.customLoanAmount || userInput?.loanAmount || 0)} ${currentCurrency}</span>
       <span class="caption">${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_FEMALE}
         <span v-if="offer.restrictions.loanAmountRestrictions.minAmount">
              ${I18NGetter().useRefusedMortgageTableElements.FROM} {{offer.restrictions.loanAmountRestrictions.minAmount | numeralFormat}} ${currentCurrency}
          </span>
              <span v-if="offer.restrictions.loanAmountRestrictions.maxAmount"><br>
              ${I18NGetter().useRefusedMortgageTableElements.TO} {{offer.restrictions.loanAmountRestrictions.maxAmount | numeralFormat}} ${currentCurrency}
          </span>
        </span>
      </div>`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_LOAN_PERIOD,
    key: OfferDataTableElementKey.LOAN_PERIOD,
    height: TableElementHeight.DOUBLE_DEFAULT,
    runtimeTemplate: true,
    class: 'd-block text-wrap overflow-y-auto',
    render: (x: Offer, userInput?: Nullable<SimulationDemand>) => `
      <div class="d-flex flex-column align-center">
      <span class="font-weight-medium" :class="{'error--text': offer.restrictions.loanPeriodRestrictions.isLoanPeriodViolated}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_MALE}</span> ${numeralFormat(x.customizations?.customLoanPeriodInMonths || userInput?.loanPeriodInMonths || 0)} ${I18NGetter().useRefusedMortgageTableElements.MONTHS}</span>
       <div class="caption d-flex flex-column align-center">
       <span>${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_MALE}
            <span v-if="offer.restrictions.loanPeriodRestrictions && offer.restrictions.loanPeriodRestrictions.minLoanPeriod">
                ${I18NGetter().useRefusedMortgageTableElements.FROM} {{offer.restrictions.loanPeriodRestrictions.minLoanPeriod}}
            </span>
                <span v-if="offer.restrictions.loanPeriodRestrictions && offer.restrictions.loanPeriodRestrictions.maxLoanPeriod">
                    ${I18NGetter().useRefusedMortgageTableElements.TO} {{offer.restrictions.loanPeriodRestrictions.maxLoanPeriod}} ${I18NGetter().useRefusedMortgageTableElements.MONTHS}
            </span>
        </span>
        <span v-if="offer.restrictions.loanPeriodRestrictions.isLoanPeriodViolatedDueToBorrowerAge" class="text-center mt-2"> ${I18NGetter().useRefusedMortgageTableElements.VIOLATED_DUE_TO_BORROWER_AGE} <br> ${I18NGetter().useRefusedMortgageTableElements.CREDIT}</span>
        </div>
      </div>`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_BORROWERS_AGE,
    key: OfferDataTableElementKey.CLIENT_AGE,
    height: TableElementHeight.DOUBLE_DEFAULT,
    render: () => `
      <div class="d-flex flex-column align-center">
          <span class="font-weight-medium" :class="{'error--text': offer.restrictions.clientAgeRestrictions.isClientAgeViolated}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_MALE}</span> {{ offer.features.isSafeCredit2Percent ? store.simulation.demandParameters.minBorrowerAge : store.simulation.demandParameters.maxBorrowerAge}} ${I18NGetter().useRefusedMortgageTableElements.YEARS}</span>
          <span class="caption">${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_MALE} ${I18NGetter().useRefusedMortgageTableElements.FROM} {{offer.restrictions.clientAgeRestrictions.minClientAge}} ${I18NGetter().useRefusedMortgageTableElements.TO} {{offer.restrictions.clientAgeRestrictions.maxClientAge}} ${I18NGetter().useRefusedMortgageTableElements.YEARS}</span>
          <span class="caption">({{offer.features.isSafeCredit2Percent ? $i18n.useRefusedMortgageTableElements.YOUNGEST_BORROWER : $i18n.useRefusedMortgageTableElements.OLDEST_BORROWER}})</span>
      </div>
    `,
    runtimeTemplate: true,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_INSTALLMENTS_TYPE,
    key: OfferDataTableElementKey.INSTALLMENTS_TYPE,
    height: TableElementHeight.DOUBLE_DEFAULT,
    render: () => `
      <div class="d-flex flex-column align-center">
          <span class="font-weight-medium" :class="{'error--text': offer.restrictions.onlyFixedInstallmentsViolated}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_NON_BINARY}</span> ${I18NGetter().useRefusedMortgageTableElements.INSTALLMENTS} {{store.simulation.userInput.fixedInstallments ? $i18n.useRefusedMortgageTableElements.FIXED : $i18n.useRefusedMortgageTableElements.DESCENDING}}</span>
          <span class="caption">${I18NGetter().useRefusedMortgageTableElements.INSTALLMENTS_TYPE_ACCEPTABLE} {{offer.restrictions.onlyFixedInstallmentsViolated ? '' : $i18n.useRefusedMortgageTableElements.INSTALLMENTS_TYPE_DESCENDING}}</span>
      </div>
    `,
    runtimeTemplate: true,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_LTV,
    key: OfferDataTableElementKey.LTV,
    height: TableElementHeight.EXTRA_LARGE,
    class: 'd-block text-wrap overflow-y-auto',
    runtimeTemplate: true,
    render: (x: Offer, userInput?: Nullable<SimulationDemand>) => `
      <div class="d-flex flex-column">
      <span class="font-weight-medium text-center" :class="{'error--text': offer.restrictions.ltvRestrictions.isLtvViolated}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_NON_BINARY}</span> LTV ${Math.round(x.totals.capital * 100 / (userInput as MortgageSimulationDemand)?.hypothecValue!)} %</span>
       <span v-if="offer.restrictions.ltvRestrictions" class="caption">
            <div v-if="offer.restrictions.ltvRestrictions.maxLtv" class="text-center">
                ${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM} ${I18NGetter().useRefusedMortgageTableElements.TO} {{offer.restrictions.ltvRestrictions.maxLtv}} %
            </div>
            <template v-else>
                ${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM}:
            <ul>
              <li v-for="prequalification in offer.restrictions.ltvRestrictions.ltvPrequalification.maxLtv">
                  <ul style="list-style-type: none;"  class="mb-2 pl-0">
                      <li>
                          <b>LTV {{prequalification.value}} % ${I18NGetter().useRefusedMortgageTableElements.DEFINED_FOR}:</b>
                      </li>
                      <li v-if="prequalification.restrictions.age">
                          ${I18NGetter().useRefusedMortgageTableElements.AGE_FROM} {{prequalification.restrictions.age.from + 1}} ${I18NGetter().useRefusedMortgageTableElements.YEARS} <span v-if="prequalification.restrictions.age.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.age.until}} ${I18NGetter().useRefusedMortgageTableElements.YEARS}</span>
                      </li>
                      <li v-if="prequalification.restrictions.period">
                          ${I18NGetter().useRefusedMortgageTableElements.PERIOD_FROM} {{prequalification.restrictions.period.from + 1}} ${I18NGetter().useRefusedMortgageTableElements.MCY} <span v-if="prequalification.restrictions.period.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.period.until}} ${I18NGetter().useRefusedMortgageTableElements.MCY}</span>
                      </li>
                      <li v-if="prequalification.restrictions.amount">
                          ${I18NGetter().useRefusedMortgageTableElements.AMOUNT_FROM} {{prequalification.restrictions.amount.from + 1}} ${currentCurrency} <span v-if="prequalification.restrictions.amount.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.amount.until}} ${currentCurrency}</span>
                      </li>
                      <li v-if="prequalification.restrictions.borrowersNumber">
                          ${I18NGetter().useRefusedMortgageTableElements.BORROWERS_NUMBER} {{prequalification.restrictions.borrowersNumber.from + 1}} <span v-if="prequalification.restrictions.borrowersNumber.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.borrowersNumber.until}}</span>
                      </li>
                      <li v-if="prequalification.restrictions.ltv">
                         ${I18NGetter().useRefusedMortgageTableElements.LTV_FROM} {{prequalification.restrictions.ltv.from}} % <span v-if="prequalification.restrictions.ltv.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.ltv.until}} %</span>
                      </li>
                      <li v-if="prequalification.restrictions.personalShare">
                         ${I18NGetter().useRefusedMortgageTableElements.PERSONAL_SHARE} {{prequalification.restrictions.personalShare.from}} % <span v-if="prequalification.restrictions.personalShare.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.personalShare.until}} %</span>
                      </li>
                      <li v-if="prequalification.restrictions.collateralValue">
                         ${I18NGetter().useRefusedMortgageTableElements.COLLATERAL_VALUE} {{prequalification.restrictions.collateralValue.from + 1}} ${currentCurrency} <span v-if="prequalification.restrictions.collateralValue.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.collateralValue.until}} ${currentCurrency}</span>
                      </li>
                      <li v-if="prequalification.restrictions.income">
                         ${I18NGetter().useRefusedMortgageTableElements.INCOME_FROM} {{prequalification.restrictions.income.from + 1}} ${currentCurrency} <span v-if="prequalification.restrictions.income.until">${I18NGetter().useRefusedMortgageTableElements.TO} {{prequalification.restrictions.income.until}} ${currentCurrency}</span>
                      </li>
                      <li v-if="prequalification.restrictions.loanPurpose">
                          ${I18NGetter().useRefusedMortgageTableElements.LOAN_PURPOSE}
                          <ul>${I18NGetter().useRefusedMortgageTableElements.TO}
                              <li v-for="purpose in prequalification.restrictions.loanPurpose">
                                  <span v-if="purpose.Buying">
                                      ${I18NGetter().useRefusedMortgageTableElements.BUYING}: {{getCollateralName(purpose.Buying.what)}}
                                  </span>
                                  <span v-if="purpose.Building">
                                      ${I18NGetter().useRefusedMortgageTableElements.BUILDING}: {{getCollateralName(purpose.Building.what)}}
                                  </span>
                                  <span v-if="purpose.Renovating">
                                      ${I18NGetter().useRefusedMortgageTableElements.RENOVATING}: {{getCollateralName(purpose.Renovating.what)}}
                                  </span>
                                  <span v-if="purpose.Adaptation">
                                      ${I18NGetter().useRefusedMortgageTableElements.ADAPTATION}
                                  </span>
                                  <span v-if="purpose.Loan">
                                       ${I18NGetter().useRefusedMortgageTableElements.LOAN}
                                  </span>
                                  <span v-if="purpose.BuyingAndRenovating">
                                      ${I18NGetter().useRefusedMortgageTableElements.BUYING_AND_RENOVATING}: {{getCollateralName(purpose.BuyingAndRenovating.what)}}
                                  </span>
                                  <span v-if="purpose.BuyingPlotAndBuilding">
                                      ${I18NGetter().useRefusedMortgageTableElements.BUYING_PLOT_AND_BUILDING}
                                  </span>
                                  <span v-if="purpose.RefinancingResidentialRealEstate">
                                      ${I18NGetter().useRefusedMortgageTableElements.REFINANCING_RESIDENTIAL_REAL_ESTATE}
                                  </span>
                                  <span v-if="purpose.RefinancingNonresidentialRealEstate">
                                      ${I18NGetter().useRefusedMortgageTableElements.REFINANCING_NON_RESIDENTIAL_REAL_ESTATE}
                                  </span>
                                  <span v-if="purpose.RefinancingRealEstateLoan">
                                      ${I18NGetter().useRefusedMortgageTableElements.REFINANCING_REAL_ESTATE_LOAN}
                                  </span>
                                  <span v-if="purpose.RefinancingNonRealEstateLoan">
                                      ${I18NGetter().useRefusedMortgageTableElements.REFINANCING_NON_REAL_ESTATE_LOAN}
                                  </span>
                                  <span v-if="purpose.HomeDebtConsolidationLoan">
                                      ${I18NGetter().useRefusedMortgageTableElements.HOME_DEBT_CONSOLIDATION_LOAN}
                                  </span>
                                  <span v-if="purpose.ConsumerDebtConsolidationLoan">
                                      ${I18NGetter().useRefusedMortgageTableElements.CONSUMER_DEBT_CONSOLIDATION_LOAN}
                                  </span>
                              </li>
                          </ul>
                      </li>
                      <li v-if="prequalification.restrictions.incomeType">
                          ${I18NGetter().useRefusedMortgageTableElements.INCOME_TYPE}:
                          <ul>
                              <li v-for="incomeType in prequalification.restrictions.incomeType">
                                  {{getIncomeTypeName(incomeType)}}
                              </li>
                          </ul>
                      </li>
                      <li v-if="prequalification.restrictions.firstOrSubsequentRealEstate">
                      ${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM}:
                      <ul>
                        <li v-for="item in prequalification.restrictions.firstOrSubsequentRealEstate">
                          {{getFirstOrSubsequentRealEstateName(item)}}
                        </li>
                      </ul>
                    </li>
                  </ul>
              </li>
          </ul>
            </template>
        </span>
      </div>`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_LOAN_INCOME_TYPE,
    key: OfferDataTableElementKey.INCOME_TYPE,
    runtimeTemplate: true,
    height: TableElementHeight.EXTRA_LARGE,
    class: 'd-block text-wrap overflow-y-auto',
    render: () => `
      <div class="d-flex flex-column">
      <span class="font-weight-medium text-center" :class="{'error--text': offer.restrictions.incomeRestrictions.isIncomeDurationViolated || offer.restrictions.incomeRestrictions.isIncomeTypeViolated || offer.borrowersInfo.refusedIncomes.length}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_NON_BINARY}</span> {{getIncomeTypeName(store.simulation.demandParameters.maiIncomeId)}}</span>
       <span class="caption"> ${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM}:
        <ul>
            <li v-for="incomeType in offer.restrictions.incomeRestrictions.requiredIncomeTypes.incomeTypes">
                {{getIncomeTypeName(incomeType.incomeTypeId)}} ${I18NGetter().useRefusedMortgageTableElements.FROM} {{incomeType.minPeriod}} ${I18NGetter().useRefusedMortgageTableElements.MCY}
            </li>
        </ul>
        </span>
      </div>`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_LOAN_PROFESSION,
    key: OfferDataTableElementKey.PROFESSION,
    runtimeTemplate: true,
    height: TableElementHeight.EXTRA_LARGE,
    class: 'd-block text-wrap overflow-y-auto',
    render: () => `
      <div class="d-flex flex-column">
      <span class="font-weight-medium text-center" :class="{'error--text': offer.restrictions.incomeRestrictions.isProfessionsViolated}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_NON_BINARY}</span> {{getProfessionName(store.simulation.demandParameters.mainProfessionId)}}</span>
       <span class="caption"> ${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM}:
        <ul>
            <li v-for="professionId in offer.restrictions.incomeRestrictions.requiredProfessionIds">
                {{getProfessionName(professionId)}}
            </li>
        </ul>
        </span>
      </div>`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_LOAN_COLLATERAL,
    key: OfferDataTableElementKey.COLLATERALS,
    runtimeTemplate: true,
    height: TableElementHeight.EXTRA_LARGE,
    class: 'd-block text-wrap overflow-y-auto',
    render: () => `
      <div class="d-flex flex-column">
      <span class="font-weight-medium text-center" :class="{'error--text': offer.restrictions.collateralsRestrictions.isCollateralsViolated}"><span class="font-weight-regular">${I18NGetter().useRefusedMortgageTableElements.CHOSEN_NON_BINARY}</span> {{getCollateralName(store.simulation.userInput.collateral)}}</span>
       <span class="caption"> ${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM}:
        <ul>
            <li v-for="collateral in offer.restrictions.collateralsRestrictions.availableCollaterals">
                {{getCollateralName(collateral)}}
            </li>
        </ul>
        </span>
      </div>`,
  },
  {
    header: () => I18NGetter().useRefusedMortgageTableElements.HEADER_BORROWERS_COUNT,
    key: OfferDataTableElementKey.BORROWERS_COUNT,
    height: TableElementHeight.DOUBLE_DEFAULT,
    runtimeTemplate: true,
    render: () => `
      <span class="font-weight-medium d-block" :class="{'error--text': offer.restrictions.isBorrowersViolated}"><span class="font-weight-regular"> ${I18NGetter().useRefusedMortgageTableElements.ENTERED}</span> {{store.simulation.demandParameters.borrowerNumber}}</span>
      <span class="caption">${I18NGetter().useRefusedMortgageTableElements.ACCEPTABLE_OTHER_FORM} ${I18NGetter().useRefusedMortgageTableElements.MAX} {{offer.restrictions.maxBorrowersNumber}}</span>`,
  },
];
