import {CompanyOwnerData} from '@/modules/accredition/models/CompanyOwnerData';
import {IdentificationDocument} from '@/modules/accredition/models/interfaces/IdentificationDocument';
import {Addressable} from '@/modules/accredition/models/interfaces/Addressable';
import ContactInfoData from '@/modules/accredition/models/ContactInfoData';
import {CompanyData, FullCompanyDataDTO} from '@/models/CompanyData';
import {AccreditationInvitation} from '@/modules/accredition/models/backend/AccreditationInvitation';

export interface AccreditationState {
  CreatedRepresentantId: number | null
  CreatedPersonId: number | null
  PersonModel: any
  attachedCertificate: File | null;
  companyData: CompanyData;
  accreditationInvitation: Nullable<AccreditationInvitation>;
}

const accreditationState: AccreditationState = {
  CreatedPersonId: null,
  CreatedRepresentantId: null,
  PersonModel: null,
  attachedCertificate: null,
  companyData: new CompanyData(),
  accreditationInvitation: null,
};

export const AccreditationStoreModule = {
  namespaced: false,
  state: accreditationState,
  getters: {
    getCreatedPersonId(state: AccreditationState) {
      // session or local storage can be added here
      return state.CreatedPersonId;
    },
    getCreatedCompanyId(state: AccreditationState) {
      return state.companyData?.id || null;
    },
    getCreatedCompany(state: AccreditationState) {
      return state.companyData;
    },
    getCreatedRepresentantId(state: AccreditationState) {
      return state.CreatedRepresentantId;
    },
    getPersonModel(state: AccreditationState) {
      return state.PersonModel;
    },

    getAttachedCertificate(state: AccreditationState) {
      return state.attachedCertificate;
    },

    getAccreditationInvitation(state: AccreditationState): Nullable<AccreditationInvitation> {
      return state.accreditationInvitation;
    },
  },
  mutations: {
    setCreatedCompany(state: AccreditationState, companyData: CompanyData) {
      state.companyData = companyData;
    },
    setCreatedPersonId(state: AccreditationState, id: number) {
      state.CreatedPersonId = id;
    },
    setAccreditationInvitation(state: AccreditationState, invitation: AccreditationInvitation) {
      state.accreditationInvitation = invitation;
    },
    setCompanyId(state: AccreditationState, id: Nullable<number>) {
      if (state.companyData) {
        state.companyData.id = id;
      } else {
        state.companyData = new CompanyData({id,});
      }
    },
    setAttachedCertificate(state: AccreditationState, cert: File) {
      state.attachedCertificate = cert;
    },
  },
};
