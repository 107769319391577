import BaseStoreService from '@/store/BaseStoreService';
import {ClientStoreState} from '@/modules/client/services/store/ClientStoreState';
import {ClientStoreModule} from '@/modules/client/services/store/ClientStoreModule';
import Client from '@/models/Client';
import ClientApi from '@/modules/client/services/ClientApi';

class ClientStore extends BaseStoreService<ClientStoreState> {
  public readonly mutations = ClientStoreModule.mutations;

  get getClients() {
    return this.read<Array<Client>>(ClientStoreModule.getters.clients);
  }

  async fetchClients() {
    const clients = await ClientApi.getClients();
    this.commit(this.mutations.setClients, clients);
  }
}

export default new ClientStore();
