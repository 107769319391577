import {ProductType} from '@/commons/enums/ProductType';
import {SimulationProductType, useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {getZoomStyles} from '@/services/utils/ZoomStyles';
import {findClosestValue} from '@/services/utils/BasicUtils';

export enum ZoomLevel {
  S = 60,
  M = 80,
  L = 100,
  XL = 110,
}
type ZoomLevelLocalStorageKeys = Record<SimulationProductType, `${SimulationProductType}_ZOOM_LEVEL`>

const zoomLevelValue = ref<ZoomLevel>(ZoomLevel.L);
export const useZoom = (productType: SimulationProductType, isMobile = false) => {
  const AVAILABLE_ZOOM_LEVELS: readonly ZoomLevel[] = [ZoomLevel.S, ZoomLevel.M, ZoomLevel.L, ZoomLevel.XL,];

  const LOCAL_STORAGE_KEYS: ZoomLevelLocalStorageKeys = {
    [ProductType.MORTGAGE]: 'productTypeMortgage_ZOOM_LEVEL',
    [ProductType.CASH]: 'productTypeCash_ZOOM_LEVEL',
  };
  const store = useSimulationStore();

  const DEFAULT_ZOOM_LEVEL: ZoomLevel = isMobile ? ZoomLevel.S : ZoomLevel.L;

  const zoomLevel = computed({
    get: () => zoomLevelValue.value,
    set: (value: ZoomLevel) => saveZoomLevel(value),
  });

  const getZoomLevel = (): ZoomLevel => {
    const savedZoomLevel: Nullable<string> = localStorage.getItem(LOCAL_STORAGE_KEYS[productType]);
    return savedZoomLevel ? Number.parseInt(savedZoomLevel) as ZoomLevel : DEFAULT_ZOOM_LEVEL;
  };

  const saveZoomLevel = (zoomLevel: ZoomLevel) => {
    zoomLevelValue.value = zoomLevel;
    const key = LOCAL_STORAGE_KEYS[productType];
    localStorage.setItem(key, `${zoomLevel}`);
  };

  const simulationOfferListingZoom = computed<{ height: string }>(() => {
    const isMobile: boolean = window.matchMedia('only screen and (max-width: 600px)').matches;
    const isTablet: boolean = window.matchMedia('only screen and (max-width: 960px)').matches;
    const APP_BAR_HEIGHT = '56px';
    const NAVIGATION_HEADER_HEIGHT = '64px';
    const SUBMIT_SIMULATION_BUTTON_HEIGHT = isTablet ? '136px' : '76px';
    const BORDER_HEIGHT = '2px';
    const differentValuesElementHeight: number = isMobile ? 113 : 58;
    const simulationForDifferentValuesInfoHeight = `${store.isSimulationForDifferentLoanAmounts || store.isSimulationForDifferentLoanPeriods ? differentValuesElementHeight : 0}px`;
    const vh = window.innerHeight * 0.01;
    return {
      height: `calc(${100 / zoomLevel.value} * (${100 * vh}px - ${APP_BAR_HEIGHT} - ${NAVIGATION_HEADER_HEIGHT} - ${SUBMIT_SIMULATION_BUTTON_HEIGHT} - ${BORDER_HEIGHT} - ${simulationForDifferentValuesInfoHeight}))`,
      ...getZoomStyles(zoomLevel.value),
    };
  });

  const setClosestZoomSetting = (windowHeight: number, setWindowHeightFunc: (height: number) => void) => {
    const currentHeight = window.innerHeight;
    if (currentHeight === windowHeight) {
      return;
    }
    const zoomValues = Object.values(ZoomLevel).filter(x => !isNaN(x as number)) as number[];
    const windowHeightRatio: number = +((window.outerHeight / window.screen.availHeight) * 100).toFixed(0);
    const closestZoomSetting: number = findClosestValue(zoomValues, windowHeightRatio);
    if (closestZoomSetting === zoomLevel.value) {
      const currentZoom: ZoomLevel = zoomLevel.value;
      const currentZoomIndex: number = zoomValues.findIndex(x => x === currentZoom);
      zoomLevelValue.value = +zoomValues[currentZoomIndex + 1] || +zoomValues[currentZoomIndex - 1];
      zoomLevelValue.value = currentZoom;
    } else {
      zoomLevelValue.value = +closestZoomSetting;
    }
    setWindowHeightFunc(currentHeight);
  };
  const debouncedSetClosestZoomSetting = () => {
    let timeouted;
    let windowHeight = window.innerHeight;
    const setWindowHeight = (height: number) => {
      windowHeight = height;
    };
    return function() {
      clearTimeout(timeouted);
      timeouted = setTimeout(() => setClosestZoomSetting(windowHeight, setWindowHeight), 600);
    };
  };

  const resizeZoomHandler = debouncedSetClosestZoomSetting();
  onMounted(() => {
    zoomLevelValue.value = DEFAULT_ZOOM_LEVEL;
    if (!isMobile) {
      zoomLevelValue.value = getZoomLevel();
    }
    window.addEventListener('resize', resizeZoomHandler);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', resizeZoomHandler);
  });

  return {
    AVAILABLE_ZOOM_LEVELS,
    zoomLevel,
    simulationOfferListingZoom,
  };
};
