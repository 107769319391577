import {VueConstructor} from 'vue';
import AnonymousBorrowerBadge from '@/components/badges/AnonymousBorrowerBadge.vue';
import ClientBadge from '@/components/badges/ClientBadge.vue';
import DealBadge from '@/components/badges/DealBadge.vue';
import ApplicationBadge from '@/components/badges/ApplicationBadge.vue';
import ProspectBadge from '@/components/prospects/ProspectBadge.vue';

const BadgesPlugin = {
  install(Vue: VueConstructor) {
    Vue.component('AnonymousBorrowerBadge', AnonymousBorrowerBadge);
    Vue.component('ClientBadge', ClientBadge);
    Vue.component('DealBadge', DealBadge);
    Vue.component('ApplicationBadge', ApplicationBadge);
    Vue.component('ProspectBadge', ProspectBadge);
  },
};
export default BadgesPlugin;
