<template>
  <v-skeleton-loader type="avatar" v-if="person === null"/>
  <v-avatar v-else :size="size" class="p-relative" :color="disabled ? 'grey lighten-2' : (alternative ? 'primary': 'secondary')">
    <img alt="Person avatar" v-if="photoSource" :src="photoSource">
    <span v-if="size >= 36 && !person.photoUrl" class="absolute subtitle-1 font-weight-medium initials">
      {{person.initials}}
    </span>
  </v-avatar>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import {AvatarViewModel} from '@/models/AvatarViewModel';

@Component
export default class PersonAvatar extends Vue {
  @Prop({required: true, })
  public readonly person!: AvatarViewModel | null;

  @Prop({default: 44, type: Number, })
  public readonly size!: number;

  @Prop({default: false, type: Boolean, })
  public readonly alternative!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly disabled!: boolean;

  public get photoSource(): string | null {
    if (this.person!.avatarUrl) {
      return this.person!.avatarUrl;
    } else if (this.person!.photoUrl) {
      return this.convertUrl();
    } else {
      return null;
    }
  }

  public convertUrl(): string {
    if (this.person?.photoUrl) {
      const size = 128;
      const separator = '/upload/';
      const url = this.person?.photoUrl?.split(separator) || '';
      if (url) {
        return `${url[0]}${separator}w_${size},h_${size},q_100,c_thumb/${url[1]}`;
      } else return this.person.photoUrl;
    } else return '';
  }
}
</script>

<style scoped lang="scss">
  .initials {
    color: rgba(255, 255, 255, 0.87);
  }
</style>
