import { normalizeNumberEnum } from '@/services/enumTranslator/normalizeNumberEnum';
import { I18NInterface } from '@/services/enumTranslator/I18NInterface';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import Logger from '../log/Logger';

type EnumDictionaryElement<T> = { type: T; name: string };
export type EnumDictionary<T> = EnumDictionaryElement<T>[];

export function getDictionaryByEnumObject<TEnum extends string>(
  enumObject: Record<keyof TEnum, TEnum>,
  enumName: string = '',
): EnumDictionary<TEnum> {
  // prefix use is essential or workaround for a inconsistency in TranslationsEnumName
  const prefixedEnumName = 'use' + enumName;
  enumObject = normalizeNumberEnum(enumObject) as Record<keyof TEnum, TEnum>;

  try {
    const foundEnum = (I18NGetter()[enumName as keyof I18NInterface] || I18NGetter()[prefixedEnumName as keyof I18NInterface]) as Record<TEnum, string>;
    if (!foundEnum) {
      Logger.error(`Enum not found ${enumName} | ${prefixedEnumName}`);
      throw new Error(`Enum not found ${enumName} | ${prefixedEnumName}`);
    }

    return Object.values(enumObject).map((key: TEnum) => {
      return {
        type: key,
        name: foundEnum[key.toString()],
      };
    });
  } catch (e) {
    console.error(e)
    return [{ type: '', name: '', },] as EnumDictionary<TEnum>;
  }
}
