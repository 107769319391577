import {BranchTypeModel} from '@/modules/branches/models/Branch';
import {BranchType} from '@/modules/branches/enums/BranchType';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const BranchTypes: BranchTypeModel[] = [
  {
    type: BranchType.FRANCHISE,
    name_pl: I18NGetter().useBranchTypes.FRANCHISE,
  },
  {
    type: BranchType.OWN,
    name_pl: I18NGetter().useBranchTypes.OWN,
  },
  {
    type: BranchType.CERTIFIED,
    name_pl: I18NGetter().useBranchTypes.CERTIFIED,
  },
];
export default BranchTypes;
