<template>
  <v-row class="flex-nowrap table no-gutters">
    <v-col>
      <v-row v-if="filteredColsList && filteredColsList.length > 0" class="no-gutters flex-nowrap">
        <v-col class="shrink mt-4">
          <slot v-if="hasSlot(SlotsName.PREPEND)"
            :name="SlotsName.PREPEND"
            :page="page"
            :leftArrowId="leftArrowId"
            :prevPage="prevPage"
            :disbaledPrevious="disabledPrevPage"
          />
          <v-btn v-else icon :id="leftArrowId">
            <v-icon
              :disabled="disabledPrevPage"
              @click="prevPage">
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="pr-2 py-0" :style="styles">
          <v-row>
            <v-col v-for="(col, dayIndex) in filteredColsList" class="text-center pb-0" :key="`header-${dayIndex}`">
              <p class="body-1 font-weight-medium text-capitalize mb-0">{{ $filters.dayFormat(col.day) }}</p>
              <p class="body-2 mb-0">{{ $filters.dayMonthFormat(col.day) }}</p>
            </v-col>
          </v-row>
          <v-row class="hours-table">
            <v-col v-for="(col, dayIndex) in filteredColsList" :key="`col-${dayIndex}`">
              <template v-if="col.hours && col.hours.length > 0">
                <v-row v-for="(hour, hourIndex) in col.hours" :key="`hour-${hourIndex}`">
                  <v-col>
                    <v-btn
                      :text="!isChecked({hourIndex, dayIndex,})"
                      :depressed="isChecked({hourIndex, dayIndex,})"
                      :id="(!loading && hour.isAvailable)  ? availableHourId : undefined"
                      :disabled="loading || !hour.isAvailable"
                      color="primary"
                      block
                      @click="onClick({hourIndex, dayIndex,})"
                    >
                      <span :class="{ busy: !hour.isAvailable }">{{ subString(hour.hour, HOURS_FORMAT_LENGTH) }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row v-for="(_, blankHourIndex) in (new Array(rows)).fill(1)"
                    :key="`blankHour-${blankHourIndex}`">
                  <v-col class="text-center">
                    <v-btn text disabled>-</v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="shrink mt-4">
          <slot v-if="hasSlot(SlotsName.APPEND)"
            :name="SlotsName.APPEND"
            :page="page"
            :leftArrowId="leftArrowId"
            :nextPage="nextPage"
            :disabledNextPage="disabledNextPage"
          />
          <v-btn v-else icon :id="rightArrowId">
            <v-icon
              @click="nextPage"
              :disabled="disabledNextPage"
            >
            mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="font-weight-medium body-1 text-center">
          Brak wolnych terminów spotkania
          <!-- {{ i18n.FP_HOUR_PICKER.NO_DATES }} -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { HoursOfWorkListDto } from '@/models/HoursOfWork';
import { VBtn, VCol, VIcon, VRow } from 'vuetify/lib';
import moment from 'moment';
import { computed, useSlots, ref, watch } from 'vue';

enum SlotsName {
  PREPEND = 'prepend',
  APPEND = 'append',
}

function subString(value: Nullable<string> = '', numberOfChars: number) {
  return value?.substr(0, numberOfChars) || '';
}

type HourPickerModel = { dayIndex: number, hourIndex: number };
const DAY_FORMAT = 'DD.MM.YYYY';
const HOUR_FORMAT = 'HH:mm';
const BUFFER_THRESHOLD = 3;
const MIN_COL_WIDTH = 110;
const HOURS_FORMAT_LENGTH = 5;

const slots = useSlots();
const hasSlot = (name: string) => {
  return !!slots[name];
};

const emits = defineEmits<{
  input: (value: Date) => void;
  bufferEnds: () => void;
  bufferStarts: () => void;
  'update:picked-hour': (value: HoursOfWorkListDto) => void;
}>();

interface FpHourPickerProps {
  value?: Date | null;
  cols?: number;
  colsList?: Nullable<HoursOfWorkListDto[]>;
  loading?: boolean;
  leftArrowId?: string;
  rightArrowId?: string;
  availableHourId?: string;
}

const props = withDefaults(
  defineProps<FpHourPickerProps>(), {
    cols: 4,
    loading: false,
    leftArrowId: '',
    rightArrowId: '',
    availableHourId: '',
  }
);

const page = ref(0);

const rows = computed(() => {
  return props.colsList ? Math.max(...props.colsList.map(x => x.hours?.length || 0)) : 0;
});

const styles = computed(() => ({
  'min-width': `${props.cols * MIN_COL_WIDTH}px`,
}));

const modelRef = computed(() => {
  if (!props.value || !props.colsList) {
    return null;
  }

  const normalizedDayValue = moment(String(props.value)).format(DAY_FORMAT);
  const normalizedHourValue = moment(String(props.value)).format(HOUR_FORMAT);

  const foundDay = props.colsList.find(day => moment(String(day.day)).format(DAY_FORMAT) === normalizedDayValue);
  if (!foundDay || !foundDay.hours) {
    return null;
  }

  const dayIndex = props.colsList.indexOf(foundDay);

  const foundHour = foundDay.hours.find(hour => subString(hour.hour, HOURS_FORMAT_LENGTH) === normalizedHourValue);
  if (!foundHour) {
    return null;
  }

  const hourIndex = foundDay.hours.indexOf(foundHour);
  if (hourIndex === -1) {
    return null;
  }

  return { dayIndex, hourIndex, };
});

const isChecked = (model: HourPickerModel): boolean => {
  const currentModel = modelRef.value;
  return !!currentModel &&
    currentModel.dayIndex === (model.dayIndex + page.value) &&
    currentModel.hourIndex === model.hourIndex;
};

const disabledNextPage = computed(() => {
  return (!!props.colsList && (page.value === props.colsList.length - props.cols)) || props.loading;
});

const nextPage = () => {
  page.value++;

  if (page.value > (props.colsList?.length || 0) - props.cols - BUFFER_THRESHOLD) {
    emits('bufferEnds');
  }
};

const disabledPrevPage = computed(() => {
  return !props.colsList || page.value === 0 || props.loading;
});

const prevPage = () => {
  if (page.value > 0) {
    page.value--;
  } else {
    emits('bufferStarts');
  }
};

const onClick = (model: HourPickerModel) => {
  const col = props.colsList![model.dayIndex + page.value];
  const pickedHour = col.hours![model.hourIndex];
  const [hour, minutes,] = pickedHour.hour.split(':').map(Number);
  const dateObject = new Date(String(col.day));
  dateObject.setHours(hour, minutes);
  emits('input', dateObject);
  emits('update:picked-hour', pickedHour);
};

const filteredColsList = computed(() => {
  return props.colsList?.filter((col, index) => index >= page.value && index < props.cols + page.value) || [];
});

function resetPage(): void {
  page.value = 0;
}

watch(() => props.colsList?.length, (newValue, oldValue) => {
  if (page.value === 0 && oldValue && newValue) {
    page.value += newValue - oldValue - 1;
  }

  // it setting a page to 0 when propsModel changes
  // and choosed date is outside of colsList
  if (oldValue && newValue && oldValue > newValue) {
    resetPage();
  }

  // case when page is totaly of a boundries of a passed list then set it to
  if (page.value >= props?.colsList?.length - 1) {
    resetPage();
  }
});

</script>

<style scoped lang="scss">
.busy{
  text-decoration: line-through;
}
.hours-table{
  overflow-y: scroll;
  height: 280px;
}
</style>
