import {WidgetType} from '@/modules/widgets/enums/WidgetType';
import WidgetConfig from '@/models/widgets/WidgetConfig';
import {FpRouteConfig} from '@/types/typings';
import {MeetingPlannerConfig} from '@/models/widgets/MeetingPlannerConfig';
import EnvironmentService from '@/env/EnvironmentService';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import AuthService from '@/modules/user/AuthService';
const LightComparerWidgetConfigurator = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/LightComparerWidgetConfigurator.vue');
const BaseInstallmentPicker = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/BaseInstallmentPicker.vue');
const MeetingPlannerWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/MeetingPlannerWidget.vue');
const TextUrls = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/TextUrls.vue');
const BasicLendiBanner = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/banners/BasicLendiBanner.vue');
const AffiliateLinks = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/AffiliateLinks.vue');
const CreditWorthinessOffersWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/CreditWorthinessOffersWidget.vue');
const RefinanceWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/RefinanceWidget.vue');
const MbwWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/MbwWidget.vue');
const SafeLoanWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/SafeLoanWidget.vue');
const FlatForStartWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/FlatForStartWidget.vue');
const WidgetViewInfo = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/widget_info/WidgetViewInfo.vue');
const SimulationFormMortgage = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/SimulationFormMortgage.vue');
const SimulationFormSafeLoan = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/SimulationFormSafeLoan.vue');
const SimulationFormFlatForStart = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/SimulationFormFlatForStart.vue');
const SafeLoanFormWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/SafeLoanFormWidget.vue');
const FlatForStartFormWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/FlatForStartFormWidget.vue');
const MortgageComparerFormWidget = () => import(/* webpackChunkName: "Widgets" */'@/modules/widgets/views/configurators/MortgageComparerFormWidget.vue');

type WidgetMetaData = FpRouteConfig<{
  title: string,
  icon: string,
  config?: WidgetConfig
  zoom?: number
  width?: number
  height?: number
  numberOfBanners?: number
  condition?: () => boolean
}>

const WidgetRoutes: Array<WidgetMetaData> = [
  {
    path: WidgetType.WidgetViewInfo,
    name: WidgetType.WidgetViewInfo,
    component: WidgetViewInfo,
    meta: {
      title: I18NGetter().WidgetSection.WidgetViewInfo!,
      icon: 'mdi-information-outline',
    },
  },
  {
    path: WidgetType.AffiliateLinks,
    name: WidgetType.AffiliateLinks,
    component: AffiliateLinks,
    meta: {
      title: I18NGetter().WidgetSection.AffiliateLinks!,
      icon: 'mdi-link-variant',
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.TextUrls,
    name: WidgetType.TextUrls,
    component: TextUrls,
    meta: {
      title: I18NGetter().WidgetSection.TextUrls!,
      icon: 'mdi-link-variant',
      condition: () => AuthService.User!.canAccessModule.WIDGETS && EnvironmentService.Environment.isPreprodOrDev,
    },
  },
  {
    path: WidgetType.LightComparerWidget,
    name: WidgetType.LightComparerWidget,
    component: LightComparerWidgetConfigurator,
    meta: {
      title: I18NGetter().WidgetSection.LightComparerWidget!,
      icon: 'mdi-calculator-variant-outline',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.SimulationFormMortgage,
    name: WidgetType.SimulationFormMortgage,
    component: SimulationFormMortgage,
    meta: {
      title: 'Kredyty hipoteczne - formularz',
      icon: 'mdi-home-city-outline',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.MortgageComparerFormWidget,
    name: WidgetType.MortgageComparerFormWidget,
    component: MortgageComparerFormWidget,
    meta: {
      title: 'Porównywarka kredytów hipotecznych - formularz',
      icon: 'mdi-home-city-outline',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.BaseInstallmentPicker,
    name: WidgetType.BaseInstallmentPicker,
    component: BaseInstallmentPicker,
    meta: {
      title: I18NGetter().WidgetSection.BaseInstallmentPicker!,
      icon: 'mdi-chart-donut',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.MeetingPlannerWidget,
    name: WidgetType.MeetingPlannerWidget,
    component: MeetingPlannerWidget,
    meta: {
      title: I18NGetter().WidgetSection.MeetingPlannerWidget!,
      icon: 'mdi-coffee-outline',
      config: new MeetingPlannerConfig(),
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  // {
  //   path: WidgetType.CreditWorthinessWidget,
  //   name: WidgetType.CreditWorthinessWidget,
  //   component: CreditWorthinessWidget,
  //   meta: {
  //     title: 'Zdolność kredytowa',
  //     icon: 'mdi-cash-multiple',
  //     zoom: 80,
  //   },
  // },
  {
    path: WidgetType.CreditWorthinessOffersWidget,
    name: WidgetType.CreditWorthinessOffersWidget,
    component: CreditWorthinessOffersWidget,
    meta: {
      title: 'Zdolność - oferty',
      icon: 'mdi-speedometer',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.mbwWidget,
    name: WidgetType.mbwWidget,
    component: MbwWidget,
    meta: {
      title: 'Rodzinny kredyt mieszkaniowy',
      icon: 'mdi-human-male-female-child',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.RefinanceWidget,
    name: WidgetType.RefinanceWidget,
    component: RefinanceWidget,
    meta: {
      title: 'Refinans',
      icon: 'mdi-file-sync-outline',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  {
    path: WidgetType.FlatForStartWidget,
    name: WidgetType.FlatForStartWidget,
    component: FlatForStartWidget,
    meta: {
      title: 'Kredyt na start',
      icon: 'mdi-flag-checkered',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
  /* TODO: czekamy na calculator {
    path: WidgetType.FlatForStartFormWidget,
    name: WidgetType.FlatForStartFormWidget,
    component: FlatForStartFormWidget,
    meta: {
      title: 'FlatForStartFormWidget',
      icon: 'mdi-flag-checkered',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  }, */
  {
    path: WidgetType.SimulationFormFlatForStart,
    name: WidgetType.SimulationFormFlatForStart,
    component: SimulationFormFlatForStart,
    meta: {
      title: 'Kredyt na start - formularz',
      icon: 'mdi-flag-checkered',
      zoom: 80,
      condition: () => AuthService.User!.canAccessModule.WIDGETS,
    },
  },
];

export default WidgetRoutes;
