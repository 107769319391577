export default abstract class KnfInfoData {
  public rpkNumber?: string = undefined;
  public rphNumber?: string = undefined;
  public knfApplication?: File | null = null;
  public knfApplicationConfirmation?: File | null = null;
  public paymentConfirmation?: File | null = null;
  public nonSaleDeclaration?: File | null = null;
  public knfInstitution?: string | null = null;
  public hasActiveAgencyContractOrInTerminatedProcess: boolean = false;
  public firstSectionMarked: boolean = false;
  public secondSectionMarked: boolean = true;
  public knfConsumerDocuments?: 'DocumentsSent' | 'ApplicationNotSubmitted' | 'ProductsWillNotBeSell' = 'ApplicationNotSubmitted';
}
