import FinCRM_User, {Organisation} from '@/modules/adminPanel/services/FinCRM_User';
import {Utils} from '@/services/utils/BasicUtils';
import {UserListElement, UserListElementDto} from '@/models/ppl/UserListElement';
import Organisations from '@/models/Organisations';

export class DivisionDTO {
  id!: number;
  chiefs: Array<UserListElementDto> = [];
  kind?: DivisionKind;
  members: UserListElementDto[] = [];
  name!: string;
  organisation?: Organisations;
  organisationId?: number;
  mainChief: Nullable<UserListElementDto> = null;
}

export default class Division extends DivisionDTO {
  chiefs: Array<UserListElement> = [];
  chiefsIds: Array<number> = [];
  mainChief: Nullable<UserListElement> = null;
  members: UserListElement[] = [];
  membersIds: number[] = [];

  constructor(division?: DivisionDTO) {
    super();
    Utils.assign(this, division);
    if (division) {
      if (Array.isArray(division.members)) {
        this.members = division.members.map(x => new UserListElement(x))!;
        this.membersIds = division.members.map(x => x.id!);
      }
      if (Array.isArray(division.chiefs)) {
        this.chiefs = division.chiefs.map(x => new UserListElement(x));
        this.chiefsIds = division.chiefs.map(chief => chief.id!);
      }
      if (division.mainChief) {
        this.mainChief = new UserListElement(division.mainChief);
        this.chiefsIds.push(division.mainChief.id!);
      }
    }
  }
}

export class FinCRM_Division {
  chiefs: Array<DivisionChief> = [];
  id?: number;
  kind!: DivisionKind;
  members: FinCRM_User[] = [];
  name!: string;
  organisationId!: number;
  organisation?: Organisations;

  constructor(division?: Division) {
    if (division) {
      this.chiefs = division.chiefs.map((divisionChief: UserListElement) =>
        new DivisionChief(divisionChief.id, (division.mainChief ? divisionChief.id === division.mainChief.id : false))
      );
      this.id = division.id;
      this.kind = division.kind!;
      this.members = division.members.map((member: UserListElement) => new FinCRM_User(member));
      this.name = division.name;
      this.organisationId = division.organisationId!;
      this.organisation = division.organisation;
    }
  }
}

export class DivisionChief {
  userId!: number;
  isMainChief!: boolean;

  constructor(id: number, isMainChief: boolean) {
    this.userId = id;
    this.isMainChief = isMainChief;
  }
}

export class DivisionKind {
  id!: number;
  name!: string;
}

export interface UserDivisionListElement {
  id: number;
  name: string;
  isMainChief: boolean;
  isChief: boolean;
  isMember: boolean;
}
