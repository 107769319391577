
export abstract class OfferDTO {
  public firstInstallment!: number;
  public id!: string;
}

export default class Offer extends OfferDTO {
  constructor(offerDTO?: OfferDTO) {
    super();
    Object.assign(this, offerDTO);
  }
}
