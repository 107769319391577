import EnvironmentService from '@/env/EnvironmentService';
import {VuetifyThemeItem} from 'vuetify/types/services/theme';
import {Utils} from '@/services/utils/BasicUtils';

class WidgetConfig {
  primaryColor: VuetifyThemeItem = EnvironmentService.Theme.light.primary;
  secondaryColor: VuetifyThemeItem = EnvironmentService.Theme.light.secondary;
  dark: boolean = false;
  agentId?: Nullable<number> = null;

  constructor(obj?: Partial<WidgetConfig>) {
    if (obj) {
      Utils.assign(this, obj);
      this.secondaryColor = obj.secondaryColor;
    }
  }
}

export default WidgetConfig;
