import {LoanApplicationState} from '@/modules/loanApplication/store/LoanApplicationStoreState';
import LoanApplication from '@/models/LoanApplication';
import {DealsStoreState} from '@/modules/deals/services/store/DealsStoreState';
import {HistoryElement} from '@/models/HistoryElement';

const state: LoanApplicationState = {
  LoanApplications: [],
  selectedLoanApplicationId: undefined,
};

export const LoanApplicationStoreModule = {
  namespaced: false,
  state,
  getters: {
    loanApplications(state: LoanApplicationState) {
      return state.LoanApplications;
    },
    loanApplication(state: LoanApplicationState) {
      return new LoanApplication(state.LoanApplications
        .find(loanApp => loanApp.id === state.selectedLoanApplicationId));
    },
  },
  mutations: {
    setLoanApplications(state: LoanApplicationState, loanApplications: Array<LoanApplication>) {
      state.LoanApplications = loanApplications;
    },
    setLoanApplication(state: LoanApplicationState, editedLoanApplication: LoanApplication) {
      const index = state.LoanApplications.findIndex(loanApp => editedLoanApplication.id === loanApp.id);
      if (index > 1) {
        state.LoanApplications.push(editedLoanApplication);
      }
    },
    setLoanApplicationId(state: LoanApplicationState, id: number) {
      state.selectedLoanApplicationId = id;
    },
    setLoanApplicationState(state: LoanApplicationState, payload: { loanAppId: number, stepId: number }) {
      const index = state.LoanApplications.findIndex(loanApp => payload.loanAppId === loanApp.id);
      if (index > -1) {
        state.LoanApplications[index].step.id = payload.stepId;
      }
    },
    removeLoanApplication(state: LoanApplicationState, loanApplicationId: number) {
      const index = state.LoanApplications.findIndex(loanApp => loanApp.id === loanApplicationId);
      state.LoanApplications.splice(index, 1);
    },
    removeLoanApplications(state: LoanApplicationState, loanApplicationIds: Array<number>) {
      loanApplicationIds.forEach(id =>
        state.LoanApplications.splice(state.LoanApplications.findIndex(loanApplication =>
          loanApplication.id === id), 1));
    },
    addLoanApplication(state: LoanApplicationState, loanApplication: LoanApplication) {
      const index = state.LoanApplications.findIndex((loanApp) => loanApp.id === loanApplication.id);
      if (index === -1) {
        state.LoanApplications.push(loanApplication);
      }
    },
    addLoanApplications(state: LoanApplicationState,
      loanApplications: Array<LoanApplication>) {
      loanApplications.forEach(loanApplication => {
        const index = state.LoanApplications.findIndex(loanApp => loanApp.id === loanApplication.id);
        if (index === -1) {
          state.LoanApplications.push(loanApplication);
        }
      });
    },
    // eslint-disable-next-line vue/max-len
    setLoanApplicationHistory(state: LoanApplicationState, payload: {loanApplicationId: number, history: Array<HistoryElement<any>>}) {
      const index = state.LoanApplications
        .findIndex(loanApplication => payload.loanApplicationId === loanApplication.id);
      state.LoanApplications[index].history = payload.history;
    },

    // eslint-disable-next-line vue/max-len
    addLoanApplicationHistory(state: LoanApplicationState, payload: {loanApplicationId: number, newHistoryElement: HistoryElement<any>}) {
      const index = state.LoanApplications
        .findIndex(loanApplication => payload.loanApplicationId === loanApplication.id);
      state.LoanApplications[index].history.unshift(payload.newHistoryElement);
    },
    removeLastAddedLoanApplicationHistory(state: LoanApplicationState, loanApplicationId: number) {
      const index = state.LoanApplications
        .findIndex(loanApplication => loanApplicationId === loanApplication.id);
      state.LoanApplications[index].history.shift();
    },
    // eslint-disable-next-line vue/max-len
    updateLastAddedLoanApplicationHistory(state: LoanApplicationState, payload: {loanApplicationId: number, newHistoryElement: HistoryElement<any>}) {
      const index = state.LoanApplications
        .findIndex(loanApplication => payload.loanApplicationId === loanApplication.id);
      state.LoanApplications[index].history[0] = payload.newHistoryElement;
    },
  },
};
