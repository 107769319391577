<template>
  <div class="d-flex">
    <fp-select
      v-model="store.userInput.sortBy"
      :prepend-inner-icon="store.userInput.isAscending ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"
      :clearable="false"
      :items="sortItems[store.productType]"
      class="sort-items"
      dense
    />
    <fp-btn
      height="40px"
      class="is-ascending"
      @click="store.userInput.isAscending = !store.userInput.isAscending"
    >
      <template v-if="store.userInput.isAscending">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon left>mdi-arrow-top-right</v-icon>
          <span>{{ I18NGetter().useSimulationToolbarSort.ASCENDING }}</span>
        </template>
        <v-icon v-else>mdi-arrow-top-right</v-icon>
      </template>
      <template v-else>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon left>mdi-arrow-bottom-right</v-icon>
          <span>{{ I18NGetter().useSimulationToolbarSort.DESCENDING }}</span>
        </template>
        <v-icon v-else>mdi-arrow-bottom-right</v-icon>
      </template>
    </fp-btn>
  </div>
</template>

<script setup lang="ts">
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import useOfferSorting from '@/components/calculator/results/OfferSorting';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const {sortItems,} = useOfferSorting();
const store = useSimulationStore();

</script>

<style lang="scss" scoped>
.is-ascending {
  border-left: none !important;
  border-radius: 0 4px 4px 0 !important;
  border-color: rgba(#000, .4) !important;
}

.sort-items {
  min-width: 220px;
  @media screen and (max-width: 960px) {
    min-width: 175px;
  }
  ::v-deep.v-input {
    border-radius: 4px 0 0 4px;

    .v-select__selection {
      max-width: 100%;
    }
  }
}
</style>
