import {FpAxios} from 'fp-components';

class ConfigurationApi {
  private configurationAxios = FpAxios.create();
  private baseUrl = 'qa/clock';

  public async getSystemClock() {
    const response = await this.configurationAxios.get(this.baseUrl);
    return response.data;
  }

  public async setSystemClock(time: string) {
    const response = await this.configurationAxios.put(this.baseUrl,time);
    return response.data;
  }

  public async resetSystemClock() {
    const response = await this.configurationAxios.delete(this.baseUrl);
    return response.data;
  }
}

export default new ConfigurationApi();
