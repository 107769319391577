<template>
  <v-card>
    <v-card-title>
      Czy chcesz zapisać zmiany, zanim wyjdziesz?
    </v-card-title>
    <v-divider/>
    <v-card-text class="px-4">
      Istnieją niezapisane zmiany na tym widoku.
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <fp-btn @click="() => closeModal(null)">Pozostań na widoku</fp-btn>
      <v-spacer/>
      <fp-btn @click="() => closeModal(false)">
        Nie zapisuj
      </fp-btn>
      <fp-btn success @click="() => closeModal(true)">
        Zapisz
      </fp-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {VueModalComponent} from 'fp-components';
import {ModalPayload} from '@/types/typings';
import { Component } from 'vue-property-decorator';

@Component({})
export default class SaveChangesModal extends VueModalComponent<ModalPayload<any>> {
}
</script>
