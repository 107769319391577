import Vue from 'vue';
import {TourName} from '@/modules/onboarding/tour/models/TourOptions';

export const useOnboarding = (tourName: TourName) => {
  const start = async() => {
    await Vue.prototype.$onboarding.tours[tourName].start();
  };
  const stop = () => Vue.prototype.$onboarding.tours[tourName].stop();

  const isStarted = () => Vue.prototype.$onboarding.tours[tourName].isStarted;

  return {
    start,
    stop,
    isStarted,
  };
};
