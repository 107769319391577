import RegulationApi from '../RegulationApi';
import BaseStoreService from '@/store/BaseStoreService';
import {RegulationStoreState} from '@/modules/regulation/services/store/RegulationStoreState';
import {RegulationStoreModule} from '@/modules/regulation/services/store/RegulationStoreModule';
import Regulation from '@/models/Regulation';

class RegulationStore extends BaseStoreService<RegulationStoreState> {
  public mutations = RegulationStoreModule.mutations;

  public get regulations() {
    return this.read<Array<Regulation>>(RegulationStoreModule.getters.regulations);
  }

  async setRegulationId(id: number) {
    this.commit<number>(this.mutations.setRegulationId, id);
  }

  async fetchRegulation(regulationId: number) {
    const regulation = await RegulationApi.getRegulation(regulationId);
    this.commit(this.mutations.setRegulation, regulation);
  }

  public setRegulationApiActive(status: boolean) {
    this.commit(this.mutations.setRegulationApiActive, status);
  }
}

export default new RegulationStore();
