import Vue from 'vue';
import { Utils } from '@/services/utils/BasicUtils';
import { forEach, isObject } from 'lodash-es';
import { ThemeOption } from '@/env/services/EnvironmentTheme';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import { StorageKeys } from '@/commons/enums/StorageKeys';

export function setupTheme(theme: ThemeOption) {
  Vue.prototype.$vuetify.theme.dark = getConfigBooleanValue(StorageKeys.DARK_MODE);
  changeTheme(theme);
  createRgbVarsForThemes(Vue.prototype.$vuetify.theme.themes);
}

export function changeTheme(theme: ThemeOption) {
  try {
    forEach(theme, (mode, key) => {
      Vue.prototype.$vuetify.theme.themes[key] = Utils.assign(Vue.prototype.$vuetify.theme.themes[key], mode);
    });
  } catch (e) {
    console.warn(e);
  }
}

export function hexToRgb(hex: string) {
  let r: string = '0';
  let g: string = '0';
  let b: string = '0';

  if (hex.length === 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length === 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }

  return `${+r}, ${+g}, ${+b}`;
};

export function generateRgbVarRule(name: string, color: string = ''): string {
  const colorRgb = hexToRgb(color);
  if (!colorRgb.includes('NaN')) {
    return `--v-${name}-rgb: ${colorRgb};`;
  }

  return '';
};

export function createRgbVarsForThemes(themes: Record<string, VuetifyThemeVariant>) {
  const style = document.createElement('style');
  // WebKit hack
  style.appendChild(document.createTextNode(''));
  document.head.appendChild(style);
  forEach(themes, (theme, key) => {
    let rules = '';
    const themeClass = `theme--${key}`;
    forEach(theme, (colors, key) => {
      if (isObject(colors)) {
        forEach(colors, (color, colorKey) => {
          rules += generateRgbVarRule(`${key}-${colorKey}`, color!);
        });
      } else {
        rules += generateRgbVarRule(key, colors?.toString());
      }
    });
    style.sheet?.insertRule(`.${themeClass} {${rules}}`);
  });
};

export function toggleTheme() {
  Vue.prototype.$vuetify.theme.dark = !Vue.prototype.$vuetify.theme.dark;
  saveConfigValue(StorageKeys.DARK_MODE, Vue.prototype.$vuetify.theme.dark);
}

export function getConfigValue(storageKey: StorageKeys): string | null {
  return localStorage.getItem(storageKey);
}

export function getConfigBooleanValue(storageKey: StorageKeys): boolean {
  return getConfigValue(storageKey) === 'true';
}

export function saveConfigValue(storageKey: StorageKeys, value: any) {
  return localStorage.setItem(storageKey, JSON.stringify(value));
}
