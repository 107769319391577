<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 py-5>
        <v-layout wrap>
          <v-autocomplete
            v-model="income.incomeTypeId"
            :items="availableIncomeSources"
            class="px-4"
            item-text="name_pl"
            item-value="type"
            outlined
            xs12
            @change="resetIncomeValues"
          />
        </v-layout>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep2IncomeSource.INCOME_DURATION_QUESTION }}</p>
    <v-layout>
      <v-flex xs12 py-5 v-if="$vuetify.breakpoint.mdAndUp" class="income-duration-slider">
        <v-slider
          v-model="income.duration"
          :step="1"
          :min="MIN_INCOME_DURATION"
          :max="MAX_INCOME_DURATION"
          :thumb-size="MAX_INCOME_DURATION"
          :tick-labels="incomePeriodTickLabels"
          class="mt-10 px-5"
          hide-details
          thumb-label="always"
          color="primary">
          <template v-slot:thumb-label="{ value }">
            <span class="font-weight-medium body-2">{{ value === MAX_INCOME_DURATION ? `${value}+` : value }}</span>
          </template>
        </v-slider>
      </v-flex>
      <fp-input
        v-else
        v-model="income.duration"
        :suffix="I18NGetter().useSimulationFormStep2IncomeSource.MONTHS_SUFFIX"
        dense
        xs12
        type="number"
        required
      />
    </v-layout>
    <template v-if="income.incomeTypeId === FFIncomeType.TIME_CONTRACT">
      <v-divider class="my-2"></v-divider>
      <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep2IncomeSource.CONTRACT_OF_EMPLOYMENT_DURATION_QUESTION }}</p>
      <v-layout>
        <v-flex xs12 py-5 v-if="$vuetify.breakpoint.mdAndUp" class="income-remaining-duration-slider">
          <v-slider
            v-model="income.remainingDuration"
            :step="1"
            :min="MIN_INCOME_DURATION"
            :max="MAX_INCOME_DURATION"
            :thumb-size="MAX_INCOME_DURATION"
            :tick-labels="incomePeriodTickLabels"
            class="mt-10 px-5"
            hide-details
            thumb-label="always"
            color="primary">
            <template v-slot:thumb-label="{ value }">
              <span class="font-weight-medium body-2">{{ value === MAX_INCOME_DURATION ? `${value}+` : value }}</span>
            </template>
          </v-slider>
        </v-flex>
        <fp-input
          v-else
          dense
          xs12
          type="number"
          int
          v-model="income.remainingDuration"
          required
          :suffix="I18NGetter().useSimulationFormStep2IncomeSource.MONTHS_SUFFIX"/>
      </v-layout>
    </template>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import MultiformDictionaries from '@/modules/multiForm/shared/MultiformDictionaries';
import {FFIncome} from '@/models/ExpertState';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import { IMultiformDictionaryElement } from '@/modules/multiForm/shared/IMultiformDictionary';
import {FFIncomeType} from '@/modules/multiForm/shared/Types';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
const store = useSimulationStore();

type IncomeSourcesListType = Array<IMultiformDictionaryElement<FFIncomeType> | {header: string} | {divider: boolean}>;
const props = defineProps<{
  income: FFIncome
}>()

const MIN_INCOME_DURATION = 1;
const MAX_INCOME_DURATION = computed(() => { return store.isCash ? 36 : 48 });
const fill = (length: number) => Array.from({length,});

const incomeCashPeriodTickLabels = [...fill(2), `3${I18NGetter().useSimulationFormStep2IncomeSource.MC}`,
  ...fill(2), `6${I18NGetter().useSimulationFormStep2IncomeSource.MCY}`,
  ...fill(5), `12${I18NGetter().useSimulationFormStep2IncomeSource.MCY}`,
  ...fill(11), `2 ${I18NGetter().useSimulationFormStep2IncomeSource.YEARS}`,] as const;

const incomeMortgagePeriodTickLabels = [
  ...fill(2), '3m',
  ...fill(2), '6m',
  ...fill(5), '12m',
  ...fill(11), `2 ${I18NGetter().useSimulationFormStep2IncomeSource.YEARS}`,
  ...fill(23), `4+ ${I18NGetter().useSimulationFormStep2IncomeSource.YEARS}`,] as const;

const incomePeriodTickLabels = computed(() => {
  return store.isCash ? incomeCashPeriodTickLabels : incomeMortgagePeriodTickLabels
})

const availableIncomeSources = computed<IncomeSourcesListType>(() => {
  return [
    {header: I18NGetter().useSimulationFormStep2IncomeSource.CONTRACT_OF_EMPLOYMENT_CONTRACT_ORDER,},
    {divider: true,},
    ...MultiformDictionaries.contractIncomeTypes,
    {header: I18NGetter().useSimulationFormStep2IncomeSource.CONTRACT,},
    {divider: true,},
    ...MultiformDictionaries.otherContractIncomeTypes,
    {header: I18NGetter().useSimulationFormStep2IncomeSource.BUSINESS,},
    {divider: true,},
    ...MultiformDictionaries.businessIncomeTypes,
    {header: I18NGetter().useSimulationFormStep2IncomeSource.ABROAD_INCOME,},
    {divider: true,},
    ...MultiformDictionaries.abroadIncomeTypes,
    {header: I18NGetter().useSimulationFormStep2IncomeSource.RETIREMENT_INCOME,},
    {divider: true,},
    ...MultiformDictionaries.retirementIncomeTypes,
    {header: I18NGetter().useSimulationFormStep2IncomeSource.OTHER,},
    {divider: true,},
    ...MultiformDictionaries.otherIncomeTypes,
  ];
});

const resetIncomeValues = () => {
  if (props.income) {
    delete props.income.threeMonthsAverage
    delete props.income.sixMonthsAverage;
    delete props.income.twelveMonthsAverage
    delete props.income.currentYearIncome
    delete props.income.currentYearCost
  }
};

</script>

<style lang="scss">
.income-duration-slider, .income-remaining-duration-slider {
  .v-slider--horizontal .v-slider__tick:last-child .v-slider__tick-label {
    transform: translateX(-50%);
  }
}
</style>
