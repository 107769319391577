import {
  DomainAgreementsWithUrl
} from '@/env/Agreements/GPDR/types';
import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {AppDomain} from '@/env/enums/Domains';

export const GPDR_AGREEMENTS_NESTO: DomainAgreementsWithUrl<AppDomain.NESTO_RO> = {
  [SystemLanguage.RO]: {
    title: 'NOTA DE INFORMARE PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL',
    url: 'https://finance.imobiliare.ro/gdpr.pdf',
  },
  [SystemLanguage.EN]: {
    title: 'Details of the protection of personal data',
    url: 'https://finance.imobiliare.ro/gdpr.pdf',
  },
};
