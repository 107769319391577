import Vue from 'vue';
class VersionCheck {
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private applicationState: {requireReload: boolean} = {
    requireReload: false,
  };

  public get ApplicationObservable() {
    return Vue.observable(this.applicationState);
  }

  public async checkBundlesHash(url: string) {
    const stream = await fetch(`${url}?nocache=${new Date().getTime()}`);
    const json = await stream.json();
    if (!this.currentHash || this.currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return this.currentHash !== json.hash;
  };
}

export default new VersionCheck();
