import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const LoanApplicationsList = () => import(/* webpackChunkName: "LoanApplication" */ '@/modules/loanApplication/views/LoanApplicationsList.vue');
const LoanApplicationDetails = () => import(/* webpackChunkName: "LoanApplication" */ '@/modules/loanApplication/views/loan-application-details/LoanApplicationDetails.vue');

const LoanApplicationsRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.LOAN_APPLICATION.LOAN_APPLICATIONS_LIST,
    component: LoanApplicationsList,
  },
  {
    path: ':id',
    name: Routes.LOAN_APPLICATION.LOAN_APPLICATION_DETAILS,
    component: LoanApplicationDetails,
  },
];

export default LoanApplicationsRoutes;
