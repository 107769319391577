export default class JsonUtils {
  public static prepareFormDataToJson(formData: FormData) {
    const jsonObject: Record<string, any> = {};
    for (const [key, value,] of formData.entries()) {
      jsonObject[key] = typeof value === 'string' && key === 'json'
        ? JSON.parse(value)
        : value instanceof File
          ? {name: value.name, size: value.size, contentType: value.type,}
          : value;
    }
    return jsonObject;
  }
}
