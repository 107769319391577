import {AppDomain} from '@/env/enums/Domains';

export interface ISimulationFiltersConfig {
  realEstateInsurance: boolean
}

export const SimulationFiltersConfig: Record<keyof typeof AppDomain, ISimulationFiltersConfig> = {
  LENDI_PL: {
    realEstateInsurance: true,
  },
  NESTO_RO: {
    realEstateInsurance: false,
  },
};
