import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const ProspectsList = () => import(/* webpackChunkName: "Prospect" */ '@/modules/prospects/views/ProspectsList.vue');
const ProspectDetails = () => import(/* webpackChunkName: "Prospect" */ '@/modules/prospects/views/ProspectDetails.vue');

const ProspectsRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.PROSPECT.LIST,
    component: ProspectsList,
  },
  {
    path: ':id',
    name: Routes.PROSPECT.DETAILS,
    component: ProspectDetails,
  },
];

export default ProspectsRoutes;
