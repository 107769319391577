import {Ref, ref, watch} from 'vue';
import {defineStore} from 'pinia';

export const useObserver = <T>(key: string) => {
  const useObserverStore = defineStore(key, () => {
    const subject: Ref<T | null | undefined> = ref(null)

    const updateSubject = (newSubjectValue?: T) => {
      subject.value = newSubjectValue
    }

    const subscribe = (callback: (newSubjectValue?: T | null) => void) => {
      watch(subject, (newSubjectValue) => {
        callback(newSubjectValue);
      })
    }

    return {updateSubject, subscribe,}
  })
  const {subscribe, updateSubject,} = useObserverStore()
  return {
    subscribe,
    updateSubject,
  }
}
