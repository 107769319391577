<template>
  <v-card>
    <v-card-title>
      Edytuj adres
    </v-card-title>
    <v-card-text>
      <v-form ref="addressForm">
        <v-container grid-list-lg>
          <AddressForm
            v-model="address"
            :required="true"
          />
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-btn color="primary" depressed @click="saveAddress">Zapisz</v-btn>
      <v-spacer/>
      <fp-btn outlined :click="() => closeModal()">Anuluj</fp-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {Component} from 'vue-property-decorator';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import {VueModalComponent} from 'fp-components';
import AddressForm from '@/components/address/AddressForm.vue';

@Component({
  components: {AddressForm, },
})
export default class EditAddressModal extends VueModalComponent<Address> {
  public address: Address = this.modalData.address || new Address();
  public isEdit: Nullable<boolean> = null;

  mounted() {
    this.isEdit = !!this.modalData.address;
  }

  saveAddress() {
    if (this.$validate('addressForm')) {
      this.closeModal(this.address);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
