import {cloneDeep} from 'lodash-es';

// @ts-ignore
export default function resetStore({ store, }) {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => {
    store.$patch(cloneDeep(initialState));

    setTimeout(() => {
    }, 200);
  };
  store.$kill = () => {
    store.$dispose();
  };
}
