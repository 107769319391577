<template>
  <v-container fluid grid-list-lg class="pa-0 fill-height">
    <v-row class="no-gutters fill-height">
      <v-col class="d-inline-flex fill-height">
        <div :class="{'grow': $vuetify.breakpoint.mdAndUp}">
          <simulation-user-data-notice v-if="!simulationStore.deal" class="mx-auto" />
          <div v-if="hasAccessToCurrentSimulation">
            <changes-detection
              ref="changesDetection"
              v-if="simulationStore.userInput"
              :value="simulationStore.userInput"
              :prevent-emit="simulationStore.preventFetch"
              @change="onFormChange"
            >
              <template #default="{isFormValid}">
                <v-card
                  class="mx-auto form-card mt-10"
                  :class="{'rounded-0': $vuetify.breakpoint.mdAndDown}">
                  <v-stepper alt-labels class="elevation-0" :value="formNavigation.stepperModel">
                    <v-stepper-header>
                      <v-stepper-step step="1">{{ I18NGetter().useSimulationFormWrapper.CREDIT_INFO }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="2">{{ I18NGetter().useSimulationFormWrapper.BORROWERS }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="3">{{ I18NGetter().useSimulationFormWrapper.INCOME }}</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="4">{{ I18NGetter().useSimulationFormWrapper.COSTS }}</v-stepper-step>
                    </v-stepper-header>
                  </v-stepper>
                  <v-divider></v-divider>
                  <v-progress-linear
                    indeterminate
                    absolute :active="simulationStore.loading"></v-progress-linear>
                  <v-card-text :class="{'pa-2': $vuetify.breakpoint.mdAndDown}">
                    <slot name="form" :isFormValid="isFormValid"></slot>
                  </v-card-text>
                  <simulation-navigation :isFormValid="isFormValid" />
                </v-card>
              </template>
            </changes-detection>
          </div>
          <div v-else>
            <v-card
                class="mx-auto form-card mt-10"
                :class="{'rounded-0': $vuetify.breakpoint.mdAndDown}"
            >
              <alert-message :alertType="AlertType.INFO">{{ getAccessMessage }}</alert-message>
            </v-card>
          </div>
        </div>
        <div class="bl simulation-info-panel"
             v-if="$vuetify.breakpoint.mdAndUp">
          <simulation-form-info-panel>
            <slot/>
          </simulation-form-info-panel>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts" setup>
import Vue, {computed, getCurrentInstance, onMounted} from 'vue';
import {SimulationProductType, useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {useDealForm, useFormNavigation} from '@/components/calculator/form/useSimulationForm';
import SimulationFormInfoPanel from '@/components/calculator/form/SimulationFormInfoPanel.vue';
import ChangesDetection from '@/components/commons/ChangesDetection.vue';
import SimulationNavigation from '@/components/calculator/form/SimulationNavigation.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import SimulationUserDataNotice from '@/components/calculator/form/SimulationUserDataNotice.vue';
import AlertMessage from '@/components/commons/message/AlertMessage.vue';
import {AlertType} from '@/models/enums/AlertType';
import {ProductType} from '@/commons/enums/ProductType';
import {CrmModules} from '@/types/CrmModules';
import {debouncedAtInput} from '@/services/utils/BasicUtils';

const instance = getCurrentInstance()?.proxy;
const simulationStore = useSimulationStore();
const {fetchDeal,} = useDealForm();
const formNavigation = useFormNavigation();

const updateSimulation = simulationStore.debouncedUpdateSimulation();

const onFormChange = async() => {
  const simulationForm: any = (instance?.$refs.changesDetection as Vue).$refs.FORM_NAME;
  const isValid: boolean = simulationForm?.validate();
  simulationStore.setIsFormValid(isValid);
  if (simulationForm && !isValid) {
    console.error('form invalid');
    return;
  }
  updateSimulation();
};

onMounted(async() => {
  await fetchDeal();
  if (!hasAccessToCurrentSimulation.value) {
    return
  }
  await simulationStore.createOrUpdateSimulation();
});

const accessMessage: Partial<Record<SimulationProductType, string>> = {
  [ProductType.CASH]: I18NGetter().useSimulationFormWrapper.NO_ACCESS_TO_CASH_SIM,
}

const productTypeToModuleMap: Record<SimulationProductType, CrmModules> = {
  [ProductType.CASH]: CrmModules.CASH_COMPARISON_TOOL,
  [ProductType.MORTGAGE]: CrmModules.MORTGAGE_COMPARISON_TOOL,
}

const hasAccessToCurrentSimulation = computed<boolean>(() => {
  return !!instance?.$user.canAccessModule[productTypeToModuleMap[simulationStore.productType!]]
})

const getAccessMessage = computed<string>(() => {
  return accessMessage[simulationStore.productType!] || ''
})
</script>
<style scoped lang="scss">
.simulation-info-panel {
  width: 300px;
  background: var(--v-primaryBg-base);
  :first-child {
    position: sticky;
    top: $main-header-height;
  }
}
.loader {
  position: absolute;
  top: 0;
  z-index: 1;
}
.form-card {
  max-width: 700px;
}
</style>
