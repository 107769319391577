import { render, staticRenderFns } from "./SimulationNavigation.vue?vue&type=template&id=f2e4aba2"
import script from "./SimulationNavigation.vue?vue&type=script&lang=ts"
export * from "./SimulationNavigation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_underscore@1.13.6_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.7.1_webpack@5.89.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCardActions,VIcon})
