import {EnvironmentEnum, EnvironmentMode} from '@/env/enums/EnvironmentMode';
import EnvironmentTheme, {ThemeOption} from '@/env/services/EnvironmentTheme';
import EnvironmentFeatures from '@/env/services/EnvironmentFeatures';
import {appDomainConfig, AppDomainConfig, AppDomainKey} from '@/env/AppDomain';
import {keys} from 'lodash-es';
import {convertArrayToBooleanRecord} from '@/services/utils/BasicUtils';
import {Currency} from '@/modules/multiForm/shared/MultiformDictionaries';
import {ProductType} from '@/commons/enums/ProductType';
import {Route, RouteConfig} from 'vue-router';
import {AppDomain} from '@/env/enums/Domains';
import {I18N} from '@/services/enumTranslator/I18NDictionary';
import {
  Agreements,
  IAgreementItemLabel,
  IAgreementItemWithUrl
} from '@/modules/client/views/client-agreements/Agreements';
import {CrmModules} from '@/types/CrmModules';

function getEnumKey(value: AppDomain): AppDomainKey {
  const result = Object.entries(AppDomain).find(([key, val,]) => val === value);
  return result![0]! as AppDomainKey;
}

export default abstract class EnvironmentService {
  public static get Environment() {
    const appDomain: AppDomain = process.env.VUE_APP_APP_DOMAIN as AppDomain;
    const getAppDomainConfig = (): AppDomainConfig => appDomainConfig[getEnumKey(appDomain)];
    return {
      buildDate: process.env.VUE_APP_BUILD_DATE,
      embedWidgetUrl: process.env.VUE_APP_EMBED_WIDGET_URL,
      env: process.env.VUE_APP_ENV,
      mode: process.env.VUE_APP_MODE,
      isLendi: process.env.VUE_APP_MODE === EnvironmentMode.LENDI,
      isStandard: process.env.VUE_APP_MODE === EnvironmentMode.STANDARD,
      isDev: process.env.VUE_APP_ENV === EnvironmentEnum.DEV,
      isProd: process.env.VUE_APP_ENV === EnvironmentEnum.PROD,
      isPreprod: process.env.VUE_APP_ENV === EnvironmentEnum.PREPROD,
      isPreprodOrDev: [EnvironmentEnum.PREPROD, EnvironmentEnum.DEV,]
        .includes(process.env.VUE_APP_ENV! as EnvironmentEnum),
      ...EnvironmentFeatures[<EnvironmentEnum>process.env.VUE_APP_ENV],
      isUserComServiceOne:
        EnvironmentFeatures[<EnvironmentEnum>process.env.VUE_APP_ENV].isUserComServiceOne &&
        appDomain === AppDomain.LENDI_PL,
      appDomain: appDomain,
      currentCurrency: (): Currency => getAppDomainConfig().currency,
      getAppDomainConfig: getAppDomainConfig,
      appDomainFeatures: {
        isDistrictsOn: getAppDomainConfig().administrativeLevels >= 2,
        hasRealEstatesOn: getAppDomainConfig().availableProducts.includes(ProductType.REAL_ESTATE),
      },
      isAppDomain: (): Record<AppDomainKey, boolean> =>
        convertArrayToBooleanRecord<AppDomainKey>(
          keys(AppDomain) as (AppDomainKey)[],
          (key) => AppDomain[key] === appDomain),
      hasExcludedModule: (route: RouteConfig | Route): boolean => {
        return route.meta?.module && getAppDomainConfig().excludedModules.includes(route.meta.module);
      },
      hasAccessToModule: (module: CrmModules): boolean => {
        return !getAppDomainConfig().excludedModules.includes(module);
      },
      getDomainAgreement: (agreement: keyof Agreements): IAgreementItemLabel => {
        const defaultLangAgreement: IAgreementItemLabel | undefined = getAppDomainConfig()
          .agreements[getAppDomainConfig().defaultSystemLanguage]?.[agreement];

        const currentSystemLangAgreement: IAgreementItemLabel | undefined = getAppDomainConfig()
          .agreements[I18N.systemLanguage]?.[agreement];

        if (!currentSystemLangAgreement?.label?.length && !defaultLangAgreement?.label?.length) {
          console.error(`Agreement ${agreement} not found in config`);
          return {title: 'AGREEMENT_NAME_NOT_FOUND', label: 'AGREEMENT_NAME_NOT_FOUND',};
        } else {
          return currentSystemLangAgreement! || defaultLangAgreement!;
        }
      },
      getGPDRDomainAgreementsWithUrl: (): IAgreementItemWithUrl => {
        const currentSystemLang = I18N.systemLanguage;
        const defualtSystemLang = getAppDomainConfig().defaultSystemLanguage;
        const currentSystemLangGPDRAgreement: IAgreementItemWithUrl | undefined = getAppDomainConfig()
          .gpdrAgreementWithUrl[currentSystemLang];
        const defaultLangGPDRAgreement: IAgreementItemWithUrl = getAppDomainConfig()
          .gpdrAgreementWithUrl[defualtSystemLang]!;
        if (!currentSystemLangGPDRAgreement?.url?.length &&
          !defaultLangGPDRAgreement?.url?.length) {
          console.error('GPDR url not found in config');
          return {title: 'GPDR_URL_NOT_FOUND', url: '',};
        } else if (currentSystemLangGPDRAgreement?.url === defaultLangGPDRAgreement?.url && defualtSystemLang !== currentSystemLang) {
          console.error('There is no GPDR consent for this language, using default one.');
          return currentSystemLangGPDRAgreement;
        } else {
          return currentSystemLangGPDRAgreement || defaultLangGPDRAgreement;
        }
      },
    };
  }

  static get Theme(): ThemeOption {
    return EnvironmentTheme[<EnvironmentMode>process.env.VUE_APP_MODE];
  }
}
