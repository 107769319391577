import _ from 'underscore';
import Chance from 'chance';
import {ClientDoublesClientIds} from '@/env/cypress/ids/ClientDoubles';
import {ClientDetailsSegmentsIds} from '@/env/cypress/ids/ClientDetailsSegmentsIds';

const AccreditationCompanyDataOwnerIds = {
  AccreditationPersonalDataFormNameInput: 'AccreditationPersonalDataFormNameInput',
  AccreditationPersonalDataFormSecondNameInput: 'AccreditationPersonalDataFormSecondNameInput',
  AccreditationPersonalDataFormLastNameInput: 'AccreditationPersonalDataFormLastNameInput',
  AccreditationPersonalDataFormBirthNameInput: 'AccreditationPersonalDataFormBirthNameInput',
  AccreditationPersonalDataFormCompanyPhoneInput: 'AccreditationPersonalDataFormCompanyPhoneInput',
  AccreditationPersonalDataFormPeselInput: 'AccreditationPersonalDataFormPeselInput',
  AccreditationPersonalDataFormMotherNameInput: 'AccreditationPersonalDataFormMotherNameInput',
  AccreditationPersonalDataFormMotherMaidenNameInput: 'AccreditationPersonalDataFormMotherMaidenNameInput',
  AccreditationPersonalDataFormFatherNameInput: 'AccreditationPersonalDataFormFatherNameInput',
  AccreditationPersonalDataFormBirthPlaceInput: 'AccreditationPersonalDataFormBirthPlaceInput',
  AccreditationPersonalDataFormNationality: 'AccreditationPersonalDataFormNationality',
};

const KnfFormIds = {
  KnfFirstSectionCheckbox: 'KnfFirstSectionCheckbox',
  KnfLendiCurrentAgreementRadioGroup: 'KnfLendiCurrentAgreementRadioGroup',
  KnfInstitutionSelect: 'KnfInstitutionSelect',
  KnfInstitutionInput: 'KnfInstitutionInput',
  KnfRphNumberInput: 'KnfRphNumberInput',
  KnfSecondSectionCheckbox: 'KnfSecondSectionCheckbox',
  KnfLendiCareCheckbox: 'KnfLendiCareCheckbox',
  KnfRpkNumberInput: 'KnfRpkNumberInput',
  KNFScanFileInput: 'KnfScanFileInput',
  KnfApplicationConfirmationFileInput: 'KnfApplicationConfirmationFileInput',
  KnfPaymentConfirmationFileInput: 'KnfPaymentConfirmationFileInput',
  KnfNonSaleDeclarationFileInput: 'KnfNonSaleDeclarationFileInput',
};

const AddressFormIds = {
  AddressFormStreet: 'AddressFormStreet',
  AddressFormStreetNum: 'AddressFormStreetNum',
  AddressFormStreetLocal: 'AddressFormStreetLocal',
  AddressFormCity: 'AddressFormCity',
  AddressFormZip: 'AddressFormZip',
  AddressFormPost: 'AddressFormPost',
  AddressFormCityDistrict: 'AddressFormCityDistrict',
  AddressFormCountry: 'AddressFormCountry',
  AddressFormDistrict: 'AddressFormDistrict',
  AddressFormCommunity: 'AddressFormCommunity',
  AddressFormLand: 'AddressFormLand',
};

const CompanyDataFormAutocompleteIds = {
  CompanyDataFormNipInput: 'CompanyDataFormNipInput',
  CompanyDataFormNameInput: 'CompanyDataFormNameInput',
  CompanyDataFormRegonInput: 'CompanyDataFormRegonInput',
};

const AccreditationIds = {
  AccreditationCompanyDataAutocomplete: 'AccreditationCompanyDataAutocomplete',
  AccreditationCompanyKrsInput: 'AccreditationCompanyKrsInput',
  AccreditationStepCompanyAddressForm: 'AccreditationStepCompanyAddressForm',
  AccreditationStepCompanyInfoNextButton: 'AccreditationStepCompanyInfoNextButton',
  ...AccreditationCompanyDataOwnerIds,
};

const CypressIds = {
  crmApp: 'app',
  login: 'login',
  fpDataTable: 'fpDataTable',
  dealBadgeMenu: 'dealBadgeMenu',
  dealDelete: 'dealDelete',
  newDeal: 'newDeal',
  newClient: 'newClient',
  newClientModal: 'newClientModal',
  createClientType: 'createClientType',
  closeCreateClientModal: 'closeCreateClientModal',
  addDeal: 'addDeal',
  addDealProductType: 'addDealProductType',
  dealName: 'dealName',
  password: 'password',
  loginForm: 'loginForm',
  calendarButton: 'calendarButton',
  activityName: 'activityName',
  navigationWrapper: 'navigationWrapper',
  addClientToDeal: 'addClientToDeal',
  clientAutocompleteInDeal: 'clientAutocompleteInDeal',
  pickClientAutocomplete: 'pickClientAutocomplete',
  comboboxClientAutocomplete: 'comboboxClientAutocomplete',
  comboboxOmnisearch: 'comboboxOmnisearch',
  calendarAddActivityButton: 'calendarAddActivityButton',
  calendarFlex: 'calendarFlex',
  addActivityButton: 'addActivityButton',
  deleteActivityButton: 'deleteActivityButton',
  historyTab: 'historyTab',
  addNoteButton: 'addNoteButton',
  addNoteField: 'addNoteField',
  historyElementsList: 'historyElementsList',
  navigationTabs: 'navigationTabs',
  clientListName: 'clientListName',
  addNewDiscussion: 'addNewDiscussion',
  newDiscussionTitle: 'newDiscussionTitle',
  newDiscussionFirstPost: 'newDiscussionFirstPost',
  newDiscussionSaveButton: 'newDiscussionSaveButton',
  discussionList: 'discussionList',
  newPostInput: 'newPostInput',
  addNewPost: 'addNewPost',
  postsList: 'postsList',
  searchDiscussion: 'searchDiscussion',
  discussionListFlex: 'discussionListFlex',
  discussionListItem: 'discussionListItem',
  discussionItemTitle: 'discussionItemTitle',
  backToDiscussionListButton: 'backToDiscussionListButton',
  discussionMoreOptions: 'discussionMoreOptions',
  discussionMoreOptionsMenu: 'discussionMoreOptionsMenu',
  editDiscussionButton: 'editDiscussionButton',
  editPostMenu: 'editPostMenu',
  postText: 'postText',
  saveNewPost: 'saveNewPost',
  removeDiscussionModalButton: 'removeDiscussionModalButton',
  removeDiscussionButton: 'removeDiscussionButton',
  discussionTags: 'discussionTags',
  pickLoanApplication: 'pickLoanApplication',
  calculatorView: 'calculatorView',
  demandLoanAmount: 'demand.loanAmount',
  demandHypothecValue: 'demand.hypothecValue',
  dealNotesTab: 'dealNotesTab',
  userButton: 'userButton',
  activityStartDatePicker: 'activityStartDatePicker',
  activityStartTimePicker: 'activityStartTimePicker',
  dealItem: 'dealItem',
  dealEdit: 'dealEditButton',
  dealEditModal: 'dealEditModal',
  dealIsLookingForMortgage: 'dealIsLookingForMortgage',
  dealEditConfirm: 'dealEditConfirm',
  dealEditLoanAmount: 'dealEditLoanAmount',
  clientPesel: 'clientPesel',
  clientFirstName: 'clientFirstName',
  clientLastName: 'clientLastName',
  clientRegon: 'clientRegon',
  clientNip: 'clientNip',
  clientCompanyName: 'clientCompanyName',
  clientEmail: 'clientEmail',
  clientPhoneNumber: 'clientPhoneNumber',
  createClintSubmitForm: 'createClintSubmitForm',
  newReport: 'newReport',
  newReportModal: 'newReportModal',
  reportModalDealPicker: 'reportModalDealPicker',
  reportModalApplicationPicker: 'reportModalApplicationPicker',
  reportModalPartnerPicker: 'reportModalPartnerPicker',
  reportModalClientPicker: 'reportModalClientPicker',
  reportModalAddClientButton: 'reportModalAddClientButton',
  reportModalProductCategory: 'reportModalProductCategory',
  reportModalFinancialInstitution: 'reportModalFinancialInstitution',
  reportModalProductName: 'reportModalProductName',
  reportModalProductToggleButton: 'reportModalProductToggleButton',
  reportModalCustomProductName: 'reportModalCustomProductName',
  reportModalProductVariant: 'reportModalProductVariant',
  reportModalBankProvision: 'reportModalBankProvision',
  reportModalBankMargin: 'reportModalBankMargin',
  reportModalConsultantCommission: 'reportModalConsultantCommission',
  reportModalAmount: 'reportModalAmount',
  reportModalDuration: 'reportModalDuration',
  reportModalSubmittedAt: 'reportModalSubmittedAt',
  reportModalRealisationAt: 'reportModalRealisationAt',
  reportModalDocumentsInformationForm: 'reportModalDocuments-informationForm',
  reportModalDocumentsDecision: 'reportModalDocuments-decision',
  reportModalDocumentsAgreement: 'reportModalDocuments-agreement',
  reportModalBankBranch: 'reportModalBankBranch',
  reportModalBankConsultant: 'reportModalBankConsultant',
  reportModalBankConsultantEmail: 'reportModalBankConsultantEmail',
  reportModalNote: 'reportModalNote',
  reportModalReportApplication: 'reportModalReportApplication',
  reportModalProductTypeMortgage: 'reportModalProductTypeMortgage',
  reportModalProductTypeCash: 'reportModalProductTypeCash',
  reportModalProductTypeSME: 'reportModalProductTypeSME',
  reportModalinterestTypeRadio: 'reportModalinterestTypeRadio',
  reportModalinterestRateValue: 'reportModalinterestRateValue',
  wonDealDetails: 'wonDealDetails',
  wonDealBankName: 'wonDealBankName',
  wonDealBankMargin: 'wonDealBankMargin',
  wonDealBankCommission: 'wonDealBankCommission',
  wonDealAgentReward: 'wonDealAgentReward',
  wonDealPartner: 'wonDealPartner',
  wonDealRealisationAt: 'wonDealRealisationAt',
  wonDealWonAt: 'wonDealWonAt',
  wonDealInformationForm: 'wonDealInformationForm',
  wonDealDecision: 'wonDealDecision',
  wonDealAgreement: 'wonDealAgreement',
  wonDealConsultantName: 'wonDealConsultantName',
  wonDealConsultantEmail: 'wonDealConsultantEmail',
  wonDealBankBranch: 'wonDealBankBranch',
  wonDealNote: 'wonDealNote',
  saveUserData: 'saveUserData',
  trainingListName: 'trainingListName',
  partnersView: 'partnersView',
  partnersList: 'partnersList',
  createPartnerModal: 'createPartnerModal',
  partnerTypeInput: 'partnerTypeInput',
  partnerNameInput: 'partnerNameInput',
  partnerEmailInput: 'partnerEmailInput',
  partnerNipInput: 'partnerNipInput',
  nipInput: 'nipInput',
  downloadNip: 'downloadNip',
  partnerDownloadNip: 'partnerDownloadNip',
  partnerGlobalCheckbox: 'partnerGlobalCheckbox',
  expertFixCheckbox: 'expertFixCheckbox',
  requireReportingCheckbox: 'requireReportingCheckbox',
  submitPartnerButton: 'submitPartnerButton',
  filterPartnersByNameInput: 'filterPartnersByNameInput',
  deletePartnerButton: 'deletePartnerButton',
  salesmanCreateButton: 'salesmanCreateButton',
  salesmenEditButton: 'salesmenEditButton',
  sourceEditButton: 'sourceEditButton',
  sourceCreateButton: 'sourceCreateButton',
  sourceNameInput: 'sourceNameInput',
  submitSource: 'submitSource',
  salesmanFirstNameInput: 'salesmanFirstNameInput',
  salesmanLastNameInput: 'salesmanLastNameInput',
  submitSalesman: 'submitSalesman',
  partnerEditModal: 'partnerEditModal',
  addDelegationList: 'addDelegationList',
  delegationListName: 'delegationListName',
  delegationListUserCombobox: 'delegationListUserCombobox',
  addDelegationButton: 'addDelegationButton',
  delegationListDataTable: 'delegationListDataTable',
  delegationListDataTableElement: 'delegationListDataTableElement',
  delegationListDataTableElementRemoveButton: 'delegationListDataTableElementRemoveButton',
  delegationListDataTableElementEditButton: 'delegationListDataTableElementEditButton',
  editDelegationButton: 'editDelegationButton',
  bankBranchesView: 'BankBranchesView',
  prospectSettingsView: 'ProspectSettingsView',
  financialInstitiutionsView: 'financialInstitiutionsView',
  bankBranchesOpenModalButton: 'bankBranchesOpenModalButton',
  bankBranchesCreateBranchOpenModalButton: 'bankBranchesCreateBranchOpenModalButton',
  bankBranchesFilterInput: 'bankBranchesFilterInput',
  bankBranchesDeleteBranchButton: 'bankBranchesDeleteBranchButton',
  bankBranchesInstitutionsSelect: 'bankBranchesInstitutionsSelect',
  bankBranchesInstitutionsSelectPanel: 'bankBranchesInstitutionsSelectPanel',
  bankBranchesCityInput: 'bankBranchesCityInput',
  bankBranchesStreetInput: 'bankBranchesStreetInput',
  bankBranchesAddBranch: 'bankBranchesAddBranch',
  bankBranchesEditBranch: 'bankBranchesEditBranch',
  addNewAttachment: 'addNewAttachment',
  phraseSearchInput: 'phraseSearchInput',
  addFiltersButton: 'addFiltersButton',
  dealNameChip: 'dealNameChip',
  prospectsList: 'prospectsList',
  addProspectModalWithActivityButton: 'addProspectModalWithActivityButton',
  landSelect: 'landSelect',
  districtSelect: 'districtSelect',
  rightArrowHourPicker: 'rightArrowHourPicker',
  availableHourPicker: 'availableHourPicker',
  fpHourPicker: 'fpHourPicker',
  pickPlaceSelect: 'pickPlaceSelect',
  teleconferenceMeetingRadioButton: 'teleconferenceMeetingRadioButton',
  prospectModalPhoneInput: 'prospectModalPhoneInput',
  prospectModalPhoneCodeSelect: 'prospectModalPhoneCodeSelect',
  prospectModalFirstNameInput: 'prospectModalFirstNameInput',
  prospectModalLastNameInput: 'prospectModalLastNameInput',
  prospectModalEmailInput: 'prospectModalEmailInput',
  prospectModalPartnerAutocomplete: 'prospectModalPartnerAutocomplete',
  prospectModalFinancialAgreement: 'prospectModalFinancialAgreement',
  prospectModalCommercialInfo: 'prospectModalCommercialInfo',
  allFiltersChip: 'allFiltersChip',
  allFiltersModal: 'allFiltersModal',
  prospectItem: 'prospectItem',
  cleanFilters: 'cleanFilters',
  addProspectButton: 'addProspectButton',
  baseBadgeLink: 'baseBadgeLink',
  canNotAddProspectModal: 'canNotAddProspectModal',
  closeCanNotAddProspectModalBtn: 'closeCanNotAddProspectModalBtn',
  createNewProspectWithActivityModal: 'createNewProspectWithActivityModal',
  myDetailsButton: 'myDetailsButton',
  bigAgreements: 'bigAgreements',
  bigRejectionInput: 'bigRejectionInput',
  bigRejectionSaveBtn: 'bigRejectionSaveBtn',
  npsOpinionsList: 'npsOpinionsList',
  updateDealDevButton: 'updateDealDevButton',
  ...CompanyDataFormAutocompleteIds,
  ...AddressFormIds,
  ...KnfFormIds,
  ...ClientDoublesClientIds,
  ...ClientDetailsSegmentsIds,
};

class CypressUtils {
  public readonly ids = CypressIds;

  public get idGetter() {
    return _.mapObject(CypressIds, (id) => '#' + id) as Record<keyof typeof CypressIds, string>;
  }

  public get randomDealName(): string {
    const chance = new Chance();
    return chance.word() + chance.city();
  }

  public get randomActivityName(): string {
    const chance = new Chance();
    return 'Meeting in ' + chance.city();
  }

  public get randomFirstName(): string {
    const chance = new Chance();
    return chance.name().split(' ')[0];
  }

  public get randomLastName(): string {
    const chance = new Chance();
    return chance.name().split(' ')[1];
  }

  public get randomCompanyName(): string {
    const chance = new Chance();
    return chance.name().split(' ')[1];
  }

  public get randomPhoneNumber(): string {
    return '502' + Math.round(100000 + Math.random() * 900000);
  }

  public get randomEmail(): string {
    const chance = new Chance();
    return chance.email();
  }

  public get randomMessage(): string {
    const chance = new Chance();
    return chance.sentence();
  }

  public get randomPESEL(): string {
    const getMonthOffsetForPesel = function(century: number) {
      switch (century) {
      case 18: return 80;
      case 19: return 0;
      case 20: return 20;
      case 21: return 40;
      case 22: return 60;
      default: return 0;
      }
    };

    const last2Digits = function(number: number) {
      const n = Math.floor(number % 100);
      return ('00' + n.toString(10)).slice(-2);
    };

    const computePeselControlDigit = function(rawPesel: string) {
      const peselDigits = rawPesel.split('').map(d => +d);
      const weigths = [1,3,7,9,1,3,7,9,1,3,];

      let sum1 = 0; let i;
      for (i = 0; i < weigths.length; i += 1) {
        sum1 += weigths[i] * peselDigits[i];
      }

      sum1 = (10 - (sum1 % 10)) % 10;
      return sum1;
    };

    const fullYear = (new Date()).getFullYear();
    const birthYear = this.rand(fullYear - 100, fullYear - 19);

    const century = Math.floor(birthYear / 100);
    const monthOffset = getMonthOffsetForPesel(century);

    const birthMonth = this.rand(1, 13);
    const birthDay = this.rand(1, new Date(birthYear, birthMonth + 1, 0).getDate() + 1);
    const peselMonth = birthMonth + monthOffset;

    const fourRandomDigits = ('0000' + this.rand(0, 10000).toString(10)).slice(-4);

    const rawPesel = last2Digits(birthYear) +
      last2Digits(peselMonth) +
      last2Digits(birthDay) +
      fourRandomDigits;

    const controlDigit = computePeselControlDigit(rawPesel);
    return rawPesel + controlDigit;
  }

  private generateRegon9() {
    const firstHalf = ('00000' + this.rand(0, 100000).toString(10)).slice(-5);
    const secondHalf = ('000' + this.rand(0, 1000).toString(10)).slice(-3);
    const rawRegon = firstHalf + secondHalf;

    const weights = [8, 9, 2, 3, 4, 5, 6, 7,];
    let sum = 0;
    let i;

    for (i = 0; i < weights.length; i += 1) {
      sum += weights[i] * (+rawRegon.charAt(i));
    }

    sum = (sum % 11) % 10;
    return rawRegon + sum.toString(10);
  };

  public get randomREGON(): string {
    const firstHalf = this.generateRegon9();
    const secondHalf = ('0000' + this.rand(0, 10000).toString(10)).slice(-4);
    const rawRegon = firstHalf + secondHalf;

    const weights = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8,];
    let sum = 0;
    let i;

    for (i = 0; i < weights.length; i += 1) {
      sum += weights[i] * (+rawRegon.charAt(i));
    }

    sum = (sum % 11) % 10;
    return rawRegon + sum.toString(10);
  };

  public get randomNIP(): string {
    const taxOfficeId = this.rand(1,10).toString(10) + this.rand(1, 10).toString(10) + this.rand(1, 10).toString(10);
    const rest = ('000000' + this.rand(0, 1000000).toString(10)).slice(-6);
    const rawNip = taxOfficeId + rest;

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7,];
    let sum = 0; let i;

    for (i = 0; i < weights.length; i += 1) {
      sum += weights[i] * (+rawNip.charAt(i));
    }

    sum = sum % 11;

    if (sum === 10) {
      // valid NIP cannot have control sum equal 10
      return this.randomNIP;
    } else {
      return rawNip + sum.toString(10);
    }
  };

  private rand(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  };
}

export default new CypressUtils();
