import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
import AuthService from '@/modules/user/AuthService';
import {OrganisationId} from '@/models/enums/OrganisationId';
import DivisionApi from '@/modules/division/services/DivisionApi';

const DivisionsManagementPanel = () => import(/* webpackChunkName: "AdminDivisionManagement" */ '@/modules/adminPanel/views/DivisionsManagementPanel.vue');
const DivisionDetails = () => import(/* webpackChunkName: "AdminDivisionDetails" */ '@/modules/accredition/views/components/division-details/DivisionDetails.vue');
const AdminDivisionPanelRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.ADMIN.DIVISIONS_MANAGEMENT,
    component: DivisionsManagementPanel,
  },
  {
    path: ':id',
    name: Routes.ADMIN.DIVISIONS_MANAGEMENT_DETAILS,
    component: DivisionDetails,
    beforeEnter: async(to, from, next) => {
      if (AuthService.User && (AuthService.User.isVerifier || AuthService.User.isAdmin)) {
        if (AuthService.User.organisationId === OrganisationId.FINPACK) {
          next();
        } else {
          try {
            const divisionInfo = await DivisionApi.getDivisionInfo(+to.params.id);
            if (divisionInfo.organisationId === AuthService.User.organisationId) {
              next();
            } else {
              next({ path: from.path, });
            }
          } catch (e) {
            next({ path: from.path, });
          }
        }
      } else {
        next({ path: from.path, });
      }
    },
  },
];

export default AdminDivisionPanelRoutes;
