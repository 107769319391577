import {ProductType} from '@/commons/enums/ProductType';

export const DealIcons = {
  [ProductType.CASH]: require('@/assets/productTypesImg/avatarGotwoka.svg'),
  [ProductType.SME]: require('@/assets/productTypesImg/avatarFirmowy.svg'),
  [ProductType.MORTGAGE]: require('@/assets/productTypesImg/avatarHipoteka.svg'),
  [ProductType.REAL_ESTATE]: require('@/assets/productTypesImg/avatarNieruchomosc.svg'),
  [ProductType.INSURANCE]: require('@/assets/productTypesImg/avatarUbezpieczenia.svg'),
  [ProductType.NONBANK]: require('@/assets/bankLogotypes/PLACEHOLDER-circle.webp'),
  [ProductType.FACTORING]: require('@/assets/bankLogotypes/PLACEHOLDER-circle.webp'),
  [ProductType.INVESTMENT]: require('@/assets/bankLogotypes/PLACEHOLDER-circle.webp'),
  [ProductType.LEASING]: require('@/assets/bankLogotypes/PLACEHOLDER-circle.webp'),
};
