<template>
  <tr class="interest-label" :class="{print}">
    <td :colspan="colspan" class="!h-28px">
            <span class="white--text" :class="print ? 'font-weight-medium' : 'body-2'">
              {{ interestRateLabel }}
              <template v-if="row.currentMonth === 1">
                <span  v-if="!offer.changingMargin">{{offer.interestRate.sum}} %</span>
                <template v-else-if="!offer.changingMargin.initialValueStatic">
                  <span>{{ $i18nFormatter($i18n.useScheduleTable.NEW_TRANSLATION_PERIOD, {period: offer.changingMargin.initialPeriod,})}}: {{parseFloat((offer.changingMargin.initialValue + offer.interestRate.referenceRate).toFixed(5))}} %</span>
                </template>
                <span v-else-if="offer.changingMargin.initialValueStatic">{{ $i18nFormatter($i18n.useScheduleTable.NEW_TRANSLATION_PERIOD, {period: offer.changingMargin.initialPeriod,})}}: {{(offer.changingMargin.initialValue)}} %</span>
                <span v-if="offer.features.isSafeCredit2Percent"> - {{ $i18n.useScheduleTable.DESCENDING_INSTALLMENTS }} - {{ $i18n.useScheduleTable.SUBSIDIES_PERIOD }}</span>
                <template v-if="offer.features.isHomeStartProgram">
                  - {{ homeStartProgramLabel}}
                </template>
              </template>
              <template v-else>
                <span v-if="!offer.changingMargin.eventualValueStatic">{{ $i18nFormatter($i18n.useScheduleTable.NEW_TRANSLATION_PERIOD, {period: scheduleStore.loanPeriodInMonths,}) }}: {{parseFloat((offer.changingMargin.eventualValue + offer.interestRate.referenceRate).toFixed(5))}} %</span>
                <span v-if="offer.changingMargin.eventualStatic">{{ $i18nFormatter($i18n.useScheduleTable.NEW_TRANSLATION_PERIOD, {period: scheduleStore.loanPeriodInMonths,}) }}: {{(offer.changingMargin.eventualValue)}} %</span>
                <template v-if="offer.features.isSafeCredit2Percent">
                  <span v-if="scheduleStore.userInput?.fixedInstallments"> - {{ $i18n.useScheduleTable.FIXED_INSTALLMENTS }}</span>
                  <span v-else> - {{ $i18n.useScheduleTable.DESCENDING_INSTALLMENTS }}</span>
                </template>
                <template v-if="offer.features.isHomeStartProgram">
                   - {{ homeStartProgramLabel}}
                </template>
              </template>
            </span>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {Offer} from '@/models/simulation/Simulation';
import {ScheduleRow} from '@/components/calculator/schedule/Schedule';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';

const props = withDefaults(defineProps<{
  offer: Offer,
  row: ScheduleRow,
  colspan?: number,
  print?: boolean,
}>(), {
  colspan: 1,
  print: false,
});

const scheduleStore = useScheduleStore();

const interestRateLabel = computed(() => props.offer.features.variableInterest || props.row.currentMonth > 1
  ? I18NGetter().useScheduleTable.VARIABLE_INTEREST_RATE
  : I18NGetter().useScheduleTable.FIXED_INTEREST_RATE
);

const chosenInstallmentType = computed(() => scheduleStore.userInput?.fixedInstallments
  ? I18NGetter().useScheduleTable.FIXED_INSTALLMENTS
  : I18NGetter().useScheduleTable.DESCENDING_INSTALLMENTS
);

const homeStartProgramLabel = computed(() => {
  if (!scheduleStore.isHomeStartProgram) return ''
  return props.row.currentMonth > 1
    ? chosenInstallmentType.value
    : 'okres dopłat'
});

</script>
<style lang="scss" scoped>
.interest-label {
  background-color: $secondary !important;

  &.print {
    background-color: #fff !important;

    span {
      font-size: 10px;
      color: $primary !important;
    }
  }
}

</style>
