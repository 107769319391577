import Vue from 'vue';
import VueTour from 'vue-tour';
import {OnboardingService} from '@/modules/onboarding/OnboardingService';

export const Onboarding = {
  install(vue: typeof Vue) {
    vue.use(VueTour);
    vue.prototype.$onboarding = new OnboardingService();
  },
};
