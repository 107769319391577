<template>
  <div v-if="address !== null">
    <slot name="prefix" class="mr-1"/>
    <span class="text-no-wrap d-inline-block">
      <template v-if="address.street"><span class="text-capitalize">{{address.street  }}</span></template>
      <template v-if="address.streetNum">{{address.streetNum}}<span v-if="address.streetLocal">/{{address.streetLocal}}</span></template>
      <template v-if="fullAddress || address.zip || address.city">, </template>
    </span>
    <span class="text-no-wrap d-inline-block" v-if="fullAddress">
      <span v-if="$env.appDomainFeatures.isDistrictsOn && address.districtId" class="text-capitalize">{{address.districtId | districtName}}</span>
      <span v-if="address.landId" class="text-capitalize">, {{address.landId | landName}}</span>
      <template v-if="address.zip || address.city">, </template>
    </span>
    <span class="text-no-wrap d-inline-block">
      <template v-if="address.zip">{{zipCodeFormat(address.zip)}}</template>
      <template v-if="address.zip">, </template>
      <span v-if="address.city">{{address.city}}</span>
    </span>
  </div>
  <v-skeleton-loader v-else type="text"/>
</template>

<script setup lang="ts">
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import {zipCodeFormat} from '@/filters/StringFilters';

const props = withDefaults(defineProps<{
  address: Nullable<Address>,
  fullAddress?: boolean,
}>(), {
  fullAddress: false,
});

</script>

<style scoped lang="scss">

</style>
