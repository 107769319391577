<template>
  <v-layout>
    <v-flex>
      <v-autocomplete
          ref="userAutocomplete"
          :value="value"
          @input="onInput"
          :chips="multiple"
          :items="users"
          item-value="id"
          item-text="displayName"
          :searchInput.sync="search"
          :multiple="multiple"
          :clearable="!multiple"
          color="primary"
          height="56px"
          :menu-props="{'closeOnContentClick': !multiple,}"
          hide-details
          :rules="rules"
          :required="required"
          :label="label"
          :disabled="disabled"
          class="fp-input-outline"
          outlined
          hide-selected
          :prepend-icon="hideIcon ? null : 'mdi-account-search'">
        <template v-slot:selection="{item}" v-if="multiple">
          <v-chip v-if="item" close @click:close="removeUser(item.id)">
            <span class="user-chip-text">
              {{item.displayName}}
            </span>
          </v-chip>
        </template>
      </v-autocomplete>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '@/models/user/User';
import UserStore from '@/modules/user/services/store/UserStore';
import {Utils} from '@/services/utils/BasicUtils';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

// TODO: Po zmianach backendowych bedzie to do zaorania
@Component
export default class UserAutocompleteOld extends Vue {
  @Prop({required: true, }) public value!: Array<number> | Nullable<number>;
  @Prop({default: I18NGetter().useUserAutocompleteOld.SEARCH, }) public label!: string;
  @Prop({default: false, type: Boolean, }) public required!: boolean;
  @Prop({default: false, type: Boolean, }) public autoFocus!: boolean;
  @Prop({default: false, type: Boolean, }) public disabled!: boolean;
  @Prop({default: false, type: Boolean, }) public multiple!: boolean;
  @Prop({default: false, type: Boolean, }) public hideIcon!: boolean;

  public users: Array<User> = [];
  public I18NGetter = I18NGetter;

  public search: string = '';
  public rules = [
    (v: any) => this.required === false || !Utils.isEmptyValue(v) ||
      I18NGetter().useUserAutocompleteOld.FIELD_REQUIRED,
  ];

  public async beforeMount() {
    await UserStore.fetchUsersForFilters();
    this.users = this.getUsersList();
    setTimeout(() => {
      if (this.autoFocus) {
        const ref: any = this.$refs.userAutocomplete;
        ref.focus();
      }
    }, 50);
  }

  public getUsersList() {
    return UserStore.getUsersForFilters;
  }

  public removeUser(item: number) {
    if (this.multiple && Array.isArray(this.value)) {
      this.emitValue(this.value.filter(id => id !== item));
    } else {
      this.emitValue(null);
    }
  }

  public emitValue(value: Nullable<number> | Array<number>) {
    this.$emit('input', value);
  }

  public async onInput(event: Array<number> | Nullable<number>) {
    this.search = '';
    this.emitValue(event);
  }
}

</script>
