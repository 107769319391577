import {LoanApplicationStepEnum} from '@/modules/loanApplication/enums/LoanApplicationStepEnum';
import AuthService from '@/modules/user/AuthService';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

interface ILoanApplicationStep {
  id: LoanApplicationStepEnum,
  description: string,
  verifierDescription?: string,
  terminal: boolean,
  realised: boolean,
  visible: boolean,
  reportStatus?: boolean,
  startedAt?: string,
}

export class LoanApplicationStep implements ILoanApplicationStep {
  public id!: LoanApplicationStepEnum;
  public description!: string;
  public verifierDescription?: string;
  public terminal!: boolean;
  public realised!: boolean;
  public visible!: boolean;
  public reportStatus?: boolean;
  public startedAt?: string;

  public get displayName(): string {
    return (AuthService.isCurrentlyVerifier ||
      (AuthService.User && (AuthService.User.isReportDepartment || AuthService.User.isChiefOfDivision))) &&
    this.verifierDescription ? this.verifierDescription : this.description;
  }

  public get disabled(): boolean {
    return !AuthService.User?.isAdminOrVerifier && LoanApplicationStepsOnlyForAdminOrVerifier.includes(this.id);
  }

  public get color(): string {
    return this.realised ? 'success' : this.terminal ? 'error' : '';
  }

  public get isFinalStage(): boolean {
    return this.realised || this.terminal;
  }

  constructor(elem: ILoanApplicationStep) {
    Object.assign(this, elem);
  }
}

export const LoanApplicationStepDeleteWhitelist: Array<LoanApplicationStepEnum> = [
  LoanApplicationStepEnum.Started,
  LoanApplicationStepEnum.ProvisionWasPaid,
  LoanApplicationStepEnum.SalesReportedToFinancialInstitution,
  LoanApplicationStepEnum.FoundsReceivedFromFinancialInstitution,
  LoanApplicationStepEnum.StatementsSentToExpert,
];

export const LoanApplicationStepsOnlyForAdminOrVerifier: Array<LoanApplicationStepEnum> =
    [
      LoanApplicationStepEnum.ReturnedForCorrection,
      LoanApplicationStepEnum.Started,
      LoanApplicationStepEnum.Accepted,
      LoanApplicationStepEnum.ChangeFinalisation,
      LoanApplicationStepEnum.WaitingForDecision,
      LoanApplicationStepEnum.WaitingForVerification,
    ];
export const LoanApplicationStepsDictionary: Array<LoanApplicationStep> = [
  {
    id: LoanApplicationStepEnum.ClientChooseOffer, // 1
    description: I18NGetter().useLoanApplicationStep.CLIENT_CHOOSE_OFFER,
    verifierDescription: I18NGetter().useLoanApplicationStep.CLIENT_CHOOSE_OFFER_VERIFIER,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.ApplicationInTheBank, // 14
    description: I18NGetter().useLoanApplicationStep.APPLICATION_IN_THE_BANK,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.InitialOfferFromBank, // 35
    description: I18NGetter().useLoanApplicationStep.INITIAL_OFFER_FROM_BANK,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.SummaryOffer, // 36
    description: I18NGetter().useLoanApplicationStep.SUMMARY_OFFER,
    verifierDescription: I18NGetter().useLoanApplicationStep.SUMMARY_OFFER_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.ReturnedForCorrection, // 34
    description: I18NGetter().useLoanApplicationStep.RETURNED_FOR_CORRECTION,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.ClientResigned, // 6
    description: I18NGetter().useLoanApplicationStep.CLIENT_RESIGNED,
    terminal: true,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.DocumentationCompleted, // 13
    description: I18NGetter().useLoanApplicationStep.DOCUMENTATION_COMPLETED,
    verifierDescription: I18NGetter().useLoanApplicationStep.DOCUMENTATION_COMPLETED_VERIFIER,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.DecisionNegative, // 16
    description: I18NGetter().useLoanApplicationStep.DECISION_NEGATIVE,
    verifierDescription: I18NGetter().useLoanApplicationStep.DECISION_NEGATIVE_VERIFIER,
    terminal: true,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.DecisionPositive, // 18
    description: I18NGetter().useLoanApplicationStep.DECISION_POSITIVE,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.AgreementConcluded, // 22
    description: I18NGetter().useLoanApplicationStep.AGREEMENT_CONCLUDED,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.Started, // 24
    description: I18NGetter().useLoanApplicationStep.STARTED,
    terminal: true,
    realised: true,
    visible: true,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.AgreementConditionNotMet, // 26
    description: I18NGetter().useLoanApplicationStep.AGREEMENT_CONDITION_NOT_MET,
    terminal: true,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.ProvisionWasPaid, // 27
    description: I18NGetter().useLoanApplicationStep.PROVISION_WAS_PAID,
    verifierDescription: I18NGetter().useLoanApplicationStep.PROVISION_WAS_PAID_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.WaitingForVerification, // 28
    description: I18NGetter().useLoanApplicationStep.WAITING_FOR_VERIFICATION,
    verifierDescription: I18NGetter().useLoanApplicationStep.WAITING_FOR_VERIFICATION_VERIFIER,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.Accepted, // 29
    description: I18NGetter().useLoanApplicationStep.ACCEPTED,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.Rejected, // 30
    description: I18NGetter().useLoanApplicationStep.REJECTED,
    verifierDescription: I18NGetter().useLoanApplicationStep.REJECTED_VERIFIER,
    terminal: true,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.SalesReportedToFinancialInstitution, // 31
    description: I18NGetter().useLoanApplicationStep.SALES_REPORTED_TO_FINANCIAL_INSTITUTION,
    verifierDescription: I18NGetter().useLoanApplicationStep.SALES_REPORTED_TO_FINANCIAL_INSTITUTION_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.FoundsReceivedFromFinancialInstitution, // 32
    description: I18NGetter().useLoanApplicationStep.FOUNDS_RECEIVED_FROM_FINANCIAL_INSTITUTION,
    verifierDescription: I18NGetter().useLoanApplicationStep.FOUNDS_RECEIVED_FROM_FINANCIAL_INSTITUTION_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.StatementsSentToExpert, // 33
    description: I18NGetter().useLoanApplicationStep.STATEMENTS_SENT_TO_EXPERT,
    verifierDescription: I18NGetter().useLoanApplicationStep.STATEMENTS_SENT_TO_EXPERT_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.NegativeDecision, // 37
    description: I18NGetter().useLoanApplicationStep.NEGATIVE_DECISION,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.ExpiredOffer, // 39
    description: I18NGetter().useLoanApplicationStep.EXPIRED_OFFER,
    verifierDescription: I18NGetter().useLoanApplicationStep.EXPIRED_OFFER_VERIFIER,
    terminal: true,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.WaitingForDecision, // 40
    description: I18NGetter().useLoanApplicationStep.WAITING_FOR_DECISION,
    verifierDescription: I18NGetter().useLoanApplicationStep.WAITING_FOR_DECISION_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.ChangeFinalisation, // 41
    description: I18NGetter().useLoanApplicationStep.CHANGE_FINALISATION,
    verifierDescription: I18NGetter().useLoanApplicationStep.CHANGE_FINALISATION_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.EditChecklist, // 42
    description: I18NGetter().useLoanApplicationStep.EDIT_CHECKLIST,
    verifierDescription: I18NGetter().useLoanApplicationStep.EDIT_CHECKLIST_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.BankVerification, // 43
    description: I18NGetter().useLoanApplicationStep.BANK_VERIFICATION,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.AcceptedCorrection, // 44
    description: I18NGetter().useLoanApplicationStep.ACCEPTED_CORRECTION,
    verifierDescription: I18NGetter().useLoanApplicationStep.ACCEPTED_CORRECTION_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.DocumentationRequired, // 45
    description: I18NGetter().useLoanApplicationStep.DOCUMENTATION_REQUIRED,
    verifierDescription: I18NGetter().useLoanApplicationStep.DOCUMENTATION_REQUIRED_VERIFIER,
    terminal: false,
    realised: false,
    visible: true,
  },
  {
    id: LoanApplicationStepEnum.ApplicationSuspended, // 46
    description: I18NGetter().useLoanApplicationStep.APPLICATION_SUSPENDED,
    verifierDescription: I18NGetter().useLoanApplicationStep.APPLICATION_SUSPENDED_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.DocumentsInControl, // 47
    description: I18NGetter().useLoanApplicationStep.DOCUMENTS_IN_CONTROL,
    verifierDescription: I18NGetter().useLoanApplicationStep.DOCUMENTS_IN_CONTROL_VERIFIER,
    terminal: false,
    realised: false,
    visible: false,
  },
  {
    id: LoanApplicationStepEnum.OriginalsWaiting, // 48
    description: I18NGetter().useLoanApplicationStep.ORIGINALS_WAITING,
    verifierDescription: I18NGetter().useLoanApplicationStep.ORIGINALS_WAITING_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.OriginalsControl, // 49
    description: I18NGetter().useLoanApplicationStep.ORIGINALS_CONTROL,
    verifierDescription: I18NGetter().useLoanApplicationStep.ORIGINALS_CONTROL_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.OriginalsComplement, // 50
    description: I18NGetter().useLoanApplicationStep.ORIGINALS_COMPLEMENT,
    verifierDescription: I18NGetter().useLoanApplicationStep.ORIGINALS_COMPLEMENT_VERIFIER,
    terminal: false,
    realised: true,
    visible: false,
    reportStatus: true,
  },
  {
    id: LoanApplicationStepEnum.OriginalsRequiredAfterStart, // 51
    description: I18NGetter().useLoanApplicationStep.ORIGINALS_REQUIRED_AFTER_START,
    verifierDescription: I18NGetter().useLoanApplicationStep.ORIGINALS_REQUIRED_AFTER_START_VERIFIER,
    terminal: false,
    realised: true,
    visible: true,
    reportStatus: true,
  },
].map(el => new LoanApplicationStep(el));
