<template>
  <div class="headers-col br">
    <simulation-main-badge-header />
    <simulation-table
        @dragEnd="onDragEnd"
        :draggable="store.isShownAvailableProducts"
        >
      <template #default="{dataElement, isSubheader}">
          <span class="text-start"
                :class="{
                'font-weight-medium': !isSubheader,
                'pl-8': isSubheader,
              }">
            {{ dataElement.header() }}
          </span>
      </template>
    </simulation-table>
  </div>
</template>
<script lang="ts">

import {defineComponent} from 'vue';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import SimulationTable from '@/components/calculator/results/SimulationTable.vue';
import SimulationMainBadgeHeader from '@/components/calculator/results/SimulationMainBadgeHeader.vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';

export default defineComponent({
  components: {SimulationTable, SimulationMainBadgeHeader,},

  setup() {
    const store = useSimulationStore();
    const {saveTableRows, listElementHeight, renderedOfferTableElements,} = useOffersTable();
    const onDragEnd = () => {
      saveTableRows(store.productType!, renderedOfferTableElements.value.map(x => x.key!));
    };

    return {
      onDragEnd,
      listElementHeight,
      store,
    };
  },
});
</script>
<style scoped lang="scss">
.headers-col {
  width: 210px;
  position: sticky;
  left: 0;
  background-color: var(--v-secondaryBg-base);
  z-index: 9;
}

::v-deep .v-list-item__content > * {
  line-height: 20px !important;
}

::v-deep .v-list-item{
  padding-left: 0;
  padding-right: 0;
}

</style>
