<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <p class="subtitle-1 mb-2">{{doc.documentName}}</p>
        </v-col>
      </v-row>
      <v-layout wrap>
        <v-flex>
          <loading-button
                 class="mt-2"
                 :class="{'white--text': !fileDownloaded}"
                 :primary="!fileDownloaded"
                 :href="doc.documentUrl"
                 @click="fileDownloaded = true"
                 target="_blank">
            {{I18NGetter().useClientScanCard.DOWNLOAD_TEMPLATE }}
          </loading-button>
        </v-flex>
        <fp-file :primary="fileDownloaded === true" v-model="doc.file" class="shrink" />
      </v-layout>
      <template v-if="doc && doc.file">
        <v-row class="align-center pt-2">
          <v-col class="pl-2 shrink">
            <v-icon class="document-icon">mdi-file-document</v-icon>
          </v-col>
          <v-col class="attachment-style">
              <span class="body-1">
                {{doc.file.name}}
              </span>
          </v-col>
          <v-col class="shrink">
            <v-btn icon @click.native.stop.prevent="$emit('remove')" class="pointer ma-0">
              <v-icon color="error">delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ClientScan} from '@/models/clientScans/ClientScanModel';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component
export default class ClientScanCard extends Vue {
  @Prop({required: true, type: Object,})
  public readonly doc!: ClientScan;

  public I18NGetter = I18NGetter;

  public fileDownloaded: boolean = false;
}
</script>
<style scoped lang="scss">
</style>
