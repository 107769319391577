import Logger from '@/services/log/Logger';

export const i18nFormatter = (stringToFormat: string, args: Object): string => {
  let translation = stringToFormat;
  let unusedSlots: RegExpMatchArray | null = null;
  let isArgumentUsed = true;
  const entries = Object.entries(args);
  for (const [key, value,] of entries) {
    isArgumentUsed = !!translation.match(new RegExp('\\{' + key + '\\}', 'gi'))?.length;
    if (!isArgumentUsed) {
      Logger.error(`Argument ${key} nie ma swojego odpowiednika w stringu, ${translation}`);
      return translation;
    }
    translation = translation.replace(new RegExp('\\{' + key + '\\}', 'gi'), value.toString());
  }
  unusedSlots = translation.match(/{[a-zA-Z]+}/g);
  if (unusedSlots?.length) {
    Logger.error(`${unusedSlots.length > 1 ? 'Podane sloty nie mają swoich odpowiedników w argumentach funkcji' : 'Podany slot nie ma swojego odpowiednika w argumentach funkcji'}: ${unusedSlots}, ${translation}`);
    return translation;
  }
  return translation;
};
