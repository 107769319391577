import {Liability, Limit} from '@/modules/multiForm/shared/MultiformModels';
import {IncomeType, LiabilityType, LimitType} from '@/modules/multiForm/shared/Types';

export type AddressCountry = 'PL';
export type AddressRegion =
  'PL_DS'
  | 'PL_KP'
  | 'PL_LU'
  | 'PL_LB'
  | 'PL_LD'
  | 'PL_MA'
  | 'PL_MZ'
  | 'PL_OP'
  | 'PL_PK'
  | 'PL_PD'
  | 'PL_PM'
  | 'PL_SL'
  | 'PL_SK'
  | 'PL_WN'
  | 'PL_WP'
  | 'PL_ZP';
export type AddressStreetPrefix =
  'Street'
  | 'Avenue'
  | 'Square'
  | 'Plaza'
  | 'Boulevard'
  | 'Roundabout'
  | 'Park'
  | 'Market'
  | 'Highway'
  | 'Road'
  | 'HousingEstate'
  | 'Garden'
  | 'Island'
  | 'Coast'
  | 'Other';
export type ApplicantEducation =
  'Unknown'
  | 'PrimarySchool'
  | 'Vocational'
  | 'Secondary'
  | 'BachelorDegree'
  | 'MastersDegree'
  | 'Phd'
  | 'Other';
export type ApplicantGender = 'Male' | 'Female';
export type ApplicantHouseType = 'Appartment' | 'House';
export type ApplicantHousingStatus =
  'Ownership'
  | 'Rented'
  | 'LivingWithFamily'
  | 'HousingCooperativeAppartment'
  | 'HousingCooperativeHouse'
  | 'BusinessAppartment'
  | 'PublicHousing'
  | 'Other';
export type ApplicantMaritalStatus =
  'Single'
  | 'Married'
  | 'MarriedDivorcing'
  | 'Separated'
  | 'Divorced'
  | 'Widow'
  | 'InformalRelationShip'
  | 'Other'
  | 'MarriedCommonTaxSettlement'
  | 'MarriedPropertySeparation'
  | 'MarriedPropertyCommunity';
export type ApplicantType = 'MainDebtor' | 'Codebtor';
export type Currency = 'PLN' | 'EUR' | 'USD' | 'INR';
export type EmploymentBusinessEntityType =
  'SoleProprietorship'
  | 'StateEnterprise'
  | 'PublicLimitedCompany'
  | 'CivilPartnership'
  | 'LimitedPartnershipWithShares'
  | 'GeneralPartnership'
  | 'LimitedPartnership'
  | 'LimitedLiabilityPartnership'
  | 'LimitedWithSharesCompany'
  | 'Cooperative'
  | 'Foundation'
  | 'Other';
export type EmploymentIndustry =
  'PublicAdministration'
  | 'Construction'
  | 'Finance'
  | 'Education'
  | 'Mining'
  | 'Trade'
  | 'Healthcare'
  | 'Service'
  | 'Processing'
  | 'Transportation'
  | 'Other'
  | 'AdministrativeAndSupportServices'
  | 'Research'
  | 'Travel'
  | 'ChemicalIndustry'
  | 'WoodIndustry'
  | 'WaterSupply'
  | 'Electricity'
  | 'PowerWaterAndGasSupply'
  | 'Gastronomy'
  | 'Household'
  | 'WholeSaleTrade'
  | 'DoorToDoorTrade'
  | 'ArtEntertainment'
  | 'Ballifs'
  | 'Consulting'
  | 'Church'
  | 'HairdressingWellness'
  | 'CultureEntertainment'
  | 'AccomodationCateringServices'
  | 'Forestry'
  | 'Aircraft'
  | 'NotApplicable'
  | 'RealEstateServices'
  | 'PropertyProtection'
  | 'RealEstateTrade'
  | 'Industry'
  | 'PoliceFireman'
  | 'ProffesionalAndScientificServices'
  | 'IndustrialProcessing'
  | 'OtherServices'
  | 'AdvertisingMarketingPr'
  | 'RepairCompany'
  | 'AgricurtureForestry'
  | 'HorticultureArboriculture'
  | 'TvPressNewspaper'
  | 'Hancraft'
  | 'CourtLawyers'
  | 'FoodIndustry'
  | 'RailTransportation'
  | 'CarTransportation'
  | 'Services'
  | 'MultiskillCompany'
  | 'TextileClothing'
  | 'ArmyCustoms'
  | 'RealEstateActivities'
  | 'FishingShipping'
  | 'IT'
  | 'NationalDefense';
export type EmploymentPosition =
  'Admiral'
  | 'AdmiralOfTheFleet'
  | 'Analyst'
  | 'Apprentice'
  | 'ApprenticeCustomsOfficer'
  | 'Aspirant'
  | 'AspirantCustomsInspector'
  | 'AspirantStaff'
  | 'Assistant'
  | 'InternalAuditor'
  | 'Bosman'
  | 'Brigadier'
  | 'JuniorEnsign'
  | 'EnsignOfTheStaff'
  | 'ServiceAdvisor'
  | 'Director'
  | 'DirectorOfTheEntity'
  | 'DepartmentDirector'
  | 'GeneralDirector'
  | 'BranchManager'
  | 'DirectorOfTheOffice'
  | 'Dispatcher'
  | 'Expert'
  | 'Worker'
  | 'General'
  | 'BrigadierGeneral'
  | 'WeaposnGeneral'
  | 'GeneralBrigadier'
  | 'GeneralOfDivision'
  | 'GeneralPoliceInspector'
  | 'GeneralInspector'
  | 'GeneralCustomsService'
  | 'Surveyor'
  | 'ChiefInspector'
  | 'ChiefAccountant'
  | 'ChiefSoftwareDeveloperItSpecialist'
  | 'ChiefSpecialist'
  | 'ChiefSeniorSpecialist'
  | 'GeneralWeapons'
  | 'ItSpecialist'
  | 'Other'
  | 'PoliceInspector'
  | 'Inspector'
  | 'CustomsInspector'
  | 'Captain'
  | 'Corporal'
  | 'Cartographer'
  | 'Cashier'
  | 'Manager'
  | 'HeadOfOrganizationalUnit'
  | 'LowerLevelManager'
  | 'HeadOfDepartment'
  | 'HeadOfOffice'
  | 'TeamLeader'
  | 'NavalCaptain'
  | 'CustomsCommissioner'
  | 'Commander'
  | 'PoliceCommissioner'
  | 'Bailiff'
  | 'CommanderSuplex'
  | 'CommanderLieutenant'
  | 'Commissioner'
  | 'Consultant'
  | 'NavyCaptain'
  | 'Accountant'
  | 'RearAdmiral'
  | 'CuratorsApprentice'
  | 'CuratorSpecialist'
  | 'ProfessionalCurator'
  | 'BachelorsDegreeTeacher'
  | 'MasterDegreeTeacher'
  | 'Major'
  | 'Sailor'
  | 'VoivodshipMarshal'
  | 'Checkmate'
  | 'PedagogueWithMasterDegree'
  | 'YoungerAspirant'
  | 'YoungerAspirantCustoms'
  | 'TheYoungerNavy'
  | 'JuniorBrigade'
  | 'JuniorStaffBrigade'
  | 'TheYoungestPoliceInspector'
  | 'JuniorInspector'
  | 'TheYoungerCustomsInspector'
  | 'JuniorCaptain'
  | 'TheYoungerOne'
  | 'TheYoungerCustomsOfficer'
  | 'JuniorCustomsAuditor'
  | 'JuniorSpecialist'
  | 'TheYoungerGuard'
  | 'Head'
  | 'HeadOfDelegation'
  | 'NotApplicable'
  | 'MidForeman'
  | 'SuperintendentOfPolice'
  | 'Superintendent'
  | 'SuperintendentOfCustoms'
  | 'ThePoliceSuperintendent'
  | 'TheCustomsSuperintendent'
  | 'TheChief'
  | 'BranchOfficeLustration'
  | 'FireMaster'
  | 'ViceChancellorOfThePolice'
  | 'DeputyInspector'
  | 'CustomsViceChancellor'
  | 'CustomsSubCommissioner'
  | 'Ensign'
  | 'LieutenantColonel'
  | 'JuniorForeman'
  | 'OfPlutonium'
  | 'Collector'
  | 'DeputyOfThePolice'
  | 'NavyJacket'
  | 'Lieutenant'
  | 'LieutenantOfTheNavy'
  | 'Constable'
  | 'PoliceStation'
  | 'OtherEducation'
  | 'CityPresident'
  | 'ProsecutorsOfficeCriminalInvestigationCommitteeAgainstThePolishNation'
  | 'GeneralProsecutorsOffice'
  | 'AttorneyOfTheLustrationOffice'
  | 'ProsecutorOfTheMainCommissionForTheProsecutionOfCrimesAgainstThePolishNation'
  | 'AttorneyOfTheLustrationBureau'
  | 'ProsecutorOfTheBranchOfTheCommissionForTheProsecutionOfCrimesAgainstThePolishNation'
  | 'AttorneyAppealsProsecutor'
  | 'ProsecutorOfTheNationalProsecutorsOffice'
  | 'AttorneyOfTheDistrictProsecutorOffice'
  | 'AttorneyOfTheRegionProsecutorOffice'
  | 'DistrictProsecutorsOffice'
  | 'RegionProsecutorsOffice'
  | 'WhiteCollarWorker'
  | 'Colonel'
  | 'CustomsOfficer'
  | 'LegalCounsel'
  | 'Referent'
  | 'Auditor'
  | 'CustomsAuditor'
  | 'JudgeOfTheCourtOfAppeal'
  | 'Sectional'
  | 'Secretary'
  | 'Sergeant'
  | 'SergeantOfThePolice'
  | 'StaffSergeant'
  | 'StaffSergeantOfThePolice'
  | 'Treasurer'
  | 'Specialist'
  | 'JudgeOfTheAppellateCourt'
  | 'JudgeOfTheDistrictCourt'
  | 'JudgeOfTheRegionCourt'
  | 'SeniorAnalyst'
  | 'SeniorCustomsOfficer'
  | 'SeniorCustomsAccountingOfficer'
  | 'Foreman'
  | 'SeniorAspirant'
  | 'SeniorAspirantCustoms'
  | 'SeniorAssistant'
  | 'OlderBosman'
  | 'SeniorForeman'
  | 'SeniorNavyEnsign'
  | 'SeniorEnsign'
  | 'SeniorEnsignOfTheStaff'
  | 'SeniorNavyEnsignOfTheStaff'
  | 'SeniorComputerTechnician'
  | 'SeniorInspector'
  | 'SeniorCaptain'
  | 'SeniorCorporal'
  | 'SeniorCommissioner'
  | 'SeniorCurator'
  | 'SeniorSailor'
  | 'OlderMat'
  | 'StandAlonePosition'
  | 'SpecialistPosition'
  | 'MiddleManagementLevel'
  | 'SupportingPosition'
  | 'HigherPosition'
  | 'SeniorArsonist'
  | 'SeniorPlatoon'
  | 'SeniorConstable'
  | 'SeniorPoliceOfficer'
  | 'Security'
  | 'SeniorCustomsAuditor'
  | 'SeniorSection'
  | 'SergeantMajor'
  | 'SeniorSergeantOfThePolice'
  | 'SeniorStaffSergeant'
  | 'ASeniorSpecialist'
  | 'SeniorGuard'
  | 'SeniorFirefighter'
  | 'SeniorPrivate'
  | 'Firefighter'
  | 'Serial'
  | 'Technician'
  | 'ViceAdmiral'
  | 'Mayor'
  | 'Freelance'
  | 'MemberOfTheBoard'
  | 'DeputyPresident'
  | 'DeputyMayor'
  | 'DeputyCommandant';
export type EmploymentProfession =
  'PublicAdministration'
  | 'AssistantSecretary'
  | 'Journalist'
  | 'Economist'
  | 'ItTechnician'
  | 'Other'
  | 'Manager'
  | 'Accountant'
  | 'Auditor'
  | 'Expert'
  | 'PropertyValuer'
  | 'SwornTranslator'
  | 'PublicFunctions'
  | 'Dietician'
  | 'Pharmacist'
  | 'Physiotherapist'
  | 'DoctorsDentists'
  | 'Dentist'
  | 'SpeechTherapist'
  | 'Nurse'
  | 'Midwife'
  | 'Prosthetist'
  | 'Psychologist'
  | 'Veterinarian'
  | 'Scientist'
  | 'NotAvailable'
  | 'Advocate'
  | 'Bailiff'
  | 'CourtCurator'
  | 'Notary'
  | 'TaxAdviser'
  | 'Prosecutor'
  | 'Attorney'
  | 'Judge'
  | 'Farmer'
  | 'CivilService'
  | 'CostomsOfficer'
  | 'BorderGuards'
  | 'ForestService'
  | 'SecurityGuard'
  | 'Policeman'
  | 'Fireman'
  | 'PrisonService'
  | 'Military'
  | 'SalesRep'
  | 'InsuranceAgent'
  | 'MunicipalGuard'
  | 'TeacherLecturer'
  | 'DiplomaTeacher'
  | 'ContractTeacher'
  | 'TeacherAppointed'
  | 'TeacherTrainee'
  | 'ElectricianMechanicHydraulics'
  | 'Architect'
  | 'ArchitectDesignerEngineerUrbanist'
  | 'Surveyor'
  | 'MinerSteelworker'
  | 'OtherTechnical'
  | 'CivilEngineer'
  | 'Urbanist'
  | 'OtherTransportation'
  | 'DriverTaxiDriver'
  | 'OfficerCaptainSailor'
  | 'InsuranceBroker'
  | 'InvestmentAdviser'
  | 'Hairdresser'
  | 'OtherServices'
  | 'Mechanic'
  | 'LegalProffesions';
export type IdentityDocumentType =
  'Unspecified'
  | 'Id'
  | 'Passport'
  | 'DriversLicense'
  | 'SchoolId'
  | 'PensionerId'
  | 'PermanentResidenceCard'
  | 'EuropeanCitizenResidenceCard';
export type IncomeSource =
  'Unknown'
  | 'Paycheck'
  | 'RetirementPension'
  | 'PreRetirementAllowance'
  | 'DisabilityPension'
  | 'SoleProprietorship'
  | 'Military'
  | 'Seafarer'
  | 'Farmer'
  | 'Alderman'
  | 'MemberOfParliament'
  | 'Cleric'
  | 'Freelance'
  | 'TrainLeave'
  | 'MiningLeave'
  | 'LeaseAgreement'
  | 'Contract'
  | 'AlimonyAllowance'
  | 'Manager'
  | 'BoardMemember'
  | 'MaternityBenefit'
  | 'Other';
export type LoanParametersPeriodType = 'Unknown' | 'Day' | 'Month';
export type ObligationType =
  'TotalAmount'
  | 'ConsumerCreditBank'
  | 'ConsumerCreditNonBank'
  | 'CreditCard'
  | 'Mortgage'
  | 'CostOfLiving'
  | 'CostOfRent'
  | 'Alimonies'
  | 'CorporateLoans'
  | 'SpouseObligations'
  | 'MonthlyBills';

export interface LoanApplicationAddress {
  street?: string | null;
  houseNumber: string | null;
  apartmentNumber?: string | null;
  postalCode: string | null;
  city?: string | null;
  country?: AddressCountry;
  streetPrefix?: AddressStreetPrefix;
  region?: AddressRegion;
}

// Borrower
export interface BancovoBorrower {
  type: ApplicantType; // czy głowny kredytobiorca czy dodatkowy
  motherMaidenName?: string | null; // nazwisko panieńskie
  primaryIdentityDocument?: LoanApplicationIdentityDocument;
  additionalIdentityDocument?: LoanApplicationIdentityDocument; // dodatkowy dokument Tożsamości
  mobilePhoneNumber: string | null; // nr telefonu +48 123 123 123
  homePhoneNumber?: string | null; // nr telefonu +48 123 123 123
  costOfLiving?: number; // koszty życia
  costOfRent?: number; // koszty najmu/czynsz
  housingStatus?: ApplicantHousingStatus; //
  housingDateMoved?: string | null; // Data zamieszkania
  houseType?: ApplicantHouseType;
  education?: ApplicantEducation; // wykształcenie
  maritalStatus?: ApplicantMaritalStatus;
  obligations?: LoanApplicationObligation[] | null; // liabilities
  firstName: string | null;
  secondName?: string | null;
  lastName: string | null;
  nationalId?: string | null;
  mainAddress?: LoanApplicationAddress;
  isMainAddressContactAddress?: boolean;
  contactAddress?: LoanApplicationAddress;
  isAddressOfResidence?: boolean;
  addressOfResidence?: LoanApplicationAddress;
  emailAddress: string | null;
  consents?: LoanApplicationConsent[] | null; // zgody
  incomes?: LoanApplicationIncome[] | null;
  motherName?: string | null;
  fatherName?: string | null;
  gender: ApplicantGender;
  citizenship?: string | null;
  birthCountry?: string | null;
  birthCity?: string | null;
  numberOfDependents?: null | number; // int32 //liczba osób na utrzymaniu
  numberOfPeopleInHousehold?: null | number; // int32 //ilość osób w domostwie
  zusKrusBranch?: string | null;
}

export interface LoanApplicationConsent {
  type: string | null;
  isConsent: boolean;
  text?: string | null;
  shortText?: string | null;
  partnerConsentType?: string | null;
  isRequired?: boolean;
}

export interface LoanApplicationEmployment {
  vatId?: string | null;
  name?: string | null;
  mobilePhoneNumber?: string | null;
  landlinePhoneNumber?: string | null;
  address?: LoanApplicationAddress;
  position?: EmploymentPosition;
  industry?: EmploymentIndustry;
  businessEntityType?: EmploymentBusinessEntityType;
  profession?: EmploymentProfession;
}

export interface LoanApplicationIdentityDocument {
  number: string | null;
  type: IdentityDocumentType;
  issueDate: string; // date-time
  validity?: string | null; // date-time
  isValidInfinite?: boolean;
}

export interface LoanApplicationIncome {
  incomeNumber: number; // int32
  isPrimary?: boolean;
  employment?: LoanApplicationEmployment;
  source?: IncomeSource;
  netIncome: number; // double
  grossIncome?: number | null; // double
  startDate: string; // date-time
  endDate?: string | null; // date-time
  isIndefinitePeriod: boolean;
  benefitNumber?: string | null;
  incomeCurrency?: Currency;
  pensionIdNumber?: string | null;
  shortBankNumber?: string | null;
  employmentPeriod?: string | null;
}

export interface BancovoLoanApplication {
  applicationId: string; // uuid
  applicationToken?: string | null;
  agentEmail: string;
  date: string | null; // date-time
  applicants: BancovoBorrower[] | null;
  loanParameters: LoanApplicationLoanParameters;
  technicalInformation: LoanApplicationTechnicalInformation;
}

export interface LoanApplicationLoanParameters {
  amountNet: number; // double
  periodNumber: number; // int32
  periodType: LoanParametersPeriodType;
  installmentNumber: number; // int32
  installmentAmount?: number; // double
  paymentDay?: number; // int32
  firstInstallmentDate?: string | null; // date-time
}

export interface LoanApplicationObligation {
  type: ObligationType;
  amount: number | null; // double
  lenderName: string | null;
  installmentAmount: number | null; // double
}

export interface LoanApplicationTechnicalInformation {
  clientIpAddress?: string | null;
  clientDeviceFingerprint?: string | null;
  userAgent?: string | null;
  screenResolution?: string | null;
  operatingSystem?: string | null;
  currentUrl?: string | null;
  country?: string | null;
  userId?: string; // uuid
  ipAs?: string | null;
  ipCity?: string | null;
  ipCountry?: string | null;
  ipIsp?: string | null;
  ipLat?: string | null;
  ipLon?: string | null;
  ipPostalCode?: string | null;
  uaUserAgentFamily?: string | null;
  uaUserAgentMajor?: string | null;
  uaUserAgentMinor?: string | null;
  uaOsFamily?: string | null;
  uaOsMajor?: string | null;
  uaOsMinor?: string | null;
  uaDevice?: string | null;
  ipUserType?: string | null;
  ipDomain?: string | null;
  ipIsAnonymous?: boolean | null;
  ipIsAnonymousVpn?: boolean | null;
  ipIsHostingProvider?: boolean | null;
  ipIsTorExitNode?: boolean | null;
  utm_source?: string | null;
  utm_id?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_term?: string | null;
  utm_content?: string | null;
  ga_analytics?: string | null;
  gid_analytics?: string | null;
  dsid?: string | null;
  frontendIp?: string | null;
  frontendPort?: string | null;
}

function createNewLimit(obligation: LoanApplicationObligation, type: LimitType): Limit {
  const newLimit = new Limit();
  newLimit.type = type;
  newLimit.loanAmount = obligation.amount;
  return newLimit;
}

function createNewLiability(obligation: LoanApplicationObligation, type: LiabilityType): Liability {
  const newLiability = new Liability();
  newLiability.type = type;
  newLiability.loanAmount = obligation.amount;
  newLiability.installment = obligation.installmentAmount;
  return newLiability;
}

export function mapIncomeTypeId(incomeType: IncomeSource): Nullable<IncomeType> {
  let finpackIncomeType: Nullable<IncomeType> = null;
  switch (incomeType) {
  case 'Paycheck':
    finpackIncomeType = IncomeType.TIME_CONTRACT;
    break;
  case 'RetirementPension':
    finpackIncomeType = IncomeType.RETIRING;
    break;
  case 'PreRetirementAllowance':
    finpackIncomeType = IncomeType.PERMAMENT_PENSION;
    break;
  case 'DisabilityPension':
    finpackIncomeType = IncomeType.TERM_PENSION;
    break;
  case 'SoleProprietorship':
    finpackIncomeType = IncomeType.GENERAL_ACTIVITIES;
    break;
  case 'Military':
    finpackIncomeType = IncomeType.MILITARY_CONTRACT;
    break;
  case 'Seafarer':
    break;
  case 'Farmer':
    break;
  case 'Alderman':
    break;
  case 'MemberOfParliament':
    break;
  case 'Cleric':
    finpackIncomeType = IncomeType.PRIEST;
    break;
  case 'Freelance':
    break;
  case 'TrainLeave':
    break;
  case 'MiningLeave':
    break;
  case 'LeaseAgreement':
    finpackIncomeType = IncomeType.RENT_INCOME;
    break;
  case 'Contract':
    finpackIncomeType = IncomeType.CONTRACT_FOR_WORK;
    break;
  case 'AlimonyAllowance':
    break;
  case 'Manager':
    break;
  case 'BoardMemember':
    break;
  case 'MaternityBenefit':
    finpackIncomeType = IncomeType.MATTERNITY_LEAVE;
    break;
  case 'Other':
    break;
  }
  return finpackIncomeType;
}
export function mapObligations(oblications: LoanApplicationObligation[]): {liabilities: Liability[], limits: Limit[]} {
  const limits: Limit[] = [];
  const liabilities: Liability[] = [];
  oblications.forEach(obligation => {
    switch (obligation.type) {
    case 'TotalAmount':
      break;
    case 'ConsumerCreditBank':
      liabilities.push(createNewLiability(obligation, LiabilityType.cashLoan));
      break;
    case 'ConsumerCreditNonBank':
      liabilities.push(createNewLiability(obligation, LiabilityType.cashLoan));
      break;
    case 'CreditCard':
      limits.push(createNewLimit(obligation, LimitType.creditCard));
      break;
    case 'Mortgage':
      liabilities.push(createNewLiability(obligation, LiabilityType.mortgage));
      break;
    case 'CostOfLiving':
      break;
    case 'CostOfRent':
      break;
    case 'Alimonies':
      liabilities.push(createNewLiability(obligation, LiabilityType.alimony));
      break;
    case 'CorporateLoans':
      break;
    case 'SpouseObligations':
      break;
    case 'MonthlyBills':
      break;
    }
  });
  return {limits, liabilities, };
}
