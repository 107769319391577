
<template>
  <div class="pa-4">
    <p class="headline-5 mb-2">Dane wymagane do kalkulacji Kredytu na start</p>
    <div v-if="isAvailableForHomeStartProgram">
    <v-row>
      <v-col cols="12" md="8">
       <v-row>
          <v-col cols="12" md="6">
            <fp-input
              :suffix="'dorosłych'"
              required type="number"
              :int="true"
              :max-value="2"
              :min-value="1"
              v-model="store.userInput.homeStartProgramInput.numberOfAdult"/>
      </v-col>
         <v-col cols="12" md="6">
                  <fp-input suffix="dzieci" type="number" :int="true"
                            min-value="0" required
                       v-model="store.userInput.homeStartProgramInput.numberOfChildren">
                    <template #append-outer>
                      <fp-tooltip max-width="50%">
                      <v-icon>mdi-information-outline</v-icon>
                    <template #tooltipContent>
                      Liczba osób w gospodarstwie wpływa na wysokość kwoty objętej oproc. preferencyjnym.<br>
                      Liczba dzieci wpływa na wysokość oprocentowania preferencyjnego.<br><br>
                      W przypadku gosp. jednoosobowego maksymalny limit wieku to 35 lat.
                    </template>
                      </fp-tooltip>
                    </template>
                  </fp-input>
         </v-col>
    </v-row >
            <fp-input  suffix="zł"
                       required
                       label="Średniomiesięczny dochód netto z 2023 r"
                       :int="true"
                       :min-value="0"
                       type="number" v-model="store.userInput.homeStartProgramInput.incomeSum" :description="store.homeStartProgramAssumptions?.incomeReductionThreshold !== undefined ? `Maksymalny limit ${store.homeStartProgramAssumptions?.incomeReductionThreshold} zł` : 'Brak maksymalnego limitu dochodu'">
              <template #append-outer>
                <fp-tooltip bottom max-width="50%">
                  <v-icon>mdi-information-outline</v-icon>
                  <template #tooltipContent>
                      Po przekroczeniu maksymalnego limitu dochodu wysokość dopłaty zostanie pomniejszona o:
                      <ul>
                        <li>50% kwoty przekroczenia dla jednoosobowego gosp.,</li>
                        <li>25% kwoty przekroczenia dla pozostałych przypadków.</li>
                      </ul>
                  </template>
                </fp-tooltip>
              </template>
            </fp-input>
            <fp-input required
                      :int="true"
                      :min-value="0"
                      v-if="!shouldHideAreaInput"
                      :persistent-hint="false"
                      suffix="m2" :description="shouldShowLimitInfo ? `Maksymalny limit: ${store.homeStartProgramAssumptions?.propertyAreaReductionThreshold} m2` : ''" label="Metraż nieruchmości" type="number" v-model="store.userInput.homeStartProgramInput.propertyArea">
              <template #append-outer>
           <fp-tooltip max-width="50%">
             <v-icon>mdi-information-outline</v-icon>
              <template #tooltipContent>
                Maksymalny limit metrażu zależy od liczy osób w gospodarstwie. Limit metrażu nie
                obowiązuje w przypadku budowy domu, a jedynie w przypadku zakupu domu/mieszkania.<br>
                Przekroczenie maksymalnego limitu metrażu powoduje obniżenie wysokości dopłat o 50 zł
                za każdy przekroczony m2.
              </template>
           </fp-tooltip>
              </template>
            </fp-input>
        <fp-select label="Lokalizacja nieruchomości"  :clearable="false" v-model="store.userInput.homeStartProgramInput.city"  :items="[{name_pl: 'Gdańsk', type: 'Gdańsk'}, {name_pl: 'Warszawa', type: 'Warszawa'}, {name_pl: 'Poznań', type: 'Poznań'}, {name_pl: 'Wrocław', type: 'Wrocław'}, {name_pl: 'Kraków', type: 'Kraków'}, {name_pl: 'Inna miejscowość', type: null}]">
        <template #append-outer>
          <fp-tooltip max-width="50%">
            <v-icon>mdi-information-outline</v-icon>
            <template #tooltipContent>
            Lokalizacja wpływa na limit dochodu oraz wysokość kwoty kredytu
            objętej oprocentowaniem preferencyjny.
            </template>
          </fp-tooltip>
        </template>
        </fp-select>
      </v-col>
    </v-row>
    <div class="flex-column mt-2">
      <div class="d-inline-flex flex-column">
        <span class="headline-6  d-inline-block mb-1 mt-5">Warunki Kredytu</span>
        <span>
          Oprocentowanie preferencyjne: <b>{{store.homeStartProgramAssumptions?.preferenceInterestRate}}% </b>
                </span>
        <span>
          Kwota kredytu objęta oproc. preferencyjnym: <b>{{store.homeStartProgramAssumptions?.subsidizedLoanAmount}} zł </b>
                </span>
        <span>
                  Pozostała kwota na oproc. normalnym: <b>{{store.homeStartProgramAssumptions?.unsubsidizedLoanAmount}} zł</b>
                </span>
      </div>
      <div class="d-inline-flex flex-column">
        <span class="headline-6 d-inline-block mb-1 mt-5">Rodzaj rat</span>
        <span>
                 Do 120 m-ca: <b>z dopłatami (uwzględnione w symulacji)</b>
                </span>
        <span>
          Po 120 m-cach: <b>równe lub malejące (obecnie wybrane {{store.userInput?.fixedInstallments ? 'równe' : 'malejące'}}) </b>
                </span>
      </div>
      <div class="d-inline-flex flex-column">
        <span class="headline-6 d-inline-block mb-1 mt-5">Obniżka dopłaty</span>
        <span> Ze względu na przekroczenie dochodu: <b>{{store.homeStartProgramAssumptions?.incomeBasedSubsidyReduction}} zł</b></span>
        <span>Ze względu na przekroczenie metrażu: <b>{{store.homeStartProgramAssumptions?.areaBasedSubsidyReduction}} zł</b></span>
        <span>Sumaryczna obniżka dopłaty: <b>{{store.homeStartProgramAssumptions?.subsidyReductionSum}} zł</b></span>
      </div>
      </div>
    </div>
    <alert-message :alert-type="AlertType.INFO" class="mt-4" v-else>
      <template #default>
        Dla tego celu i/lub rodzaju kredytowanej nieruchomości program Kredyt na Start jest niedostępny.
      </template>
    </alert-message>
  </div>
</template>
<script setup lang="ts">
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import FpTooltip from '@/components/fpComponents/FpTooltip.vue';
import {LoanPurposeActivity} from '@/models/simulation/LoanPurposeActivity';
import {LoanPurposeWhat} from '@/models/simulation/LoanPurposeWhat';
import {computed, ref, watchEffect} from 'vue';
import AlertMessage from '@/components/commons/message/AlertMessage.vue';
import {AlertType} from '@/models/enums/AlertType';

const props = defineProps<{
  isFormValid: boolean
}>()
const store = useSimulationStore()

const activitesWithLimits = [LoanPurposeActivity.BUYING, LoanPurposeActivity.BUYING_AND_RENOVATING,];
const whatWithLimits = [LoanPurposeWhat.APARTMENT, LoanPurposeWhat.HOUSE,];
const correctActivities = [LoanPurposeActivity.BUYING,
  LoanPurposeActivity.BUYING_AND_RENOVATING,
  LoanPurposeActivity.BUILDING,
  LoanPurposeActivity.BUYING_PLOT_AND_BUILDING,];
const correctWhats = [LoanPurposeWhat.APARTMENT, LoanPurposeWhat.HOUSE, LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE,];
const correctCollaterals = [LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE,];

const previousArea = ref<Nullable<number>>(null);

const shouldShowLimitInfo = computed(() => {
  return whatWithLimits.includes(store.userInput?.loanPurpose?.what!) && activitesWithLimits.includes(store.userInput?.loanPurpose?.activity!)
})

const isLoanPurposeHouse = computed(() => {
  return store.userInput?.loanPurpose?.what === LoanPurposeWhat.HOUSE
})

const shouldHideAreaInput = computed(() => {
  return (store.userInput?.loanPurpose?.what === LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE &&
    store.userInput?.loanPurpose?.activity === LoanPurposeActivity.BUYING_PLOT_AND_BUILDING) ||
    (store.userInput?.loanPurpose?.what === LoanPurposeWhat.HOUSE &&
      store.userInput?.loanPurpose?.activity === LoanPurposeActivity.BUILDING) ||
    (store.userInput?.loanPurpose.activity === LoanPurposeActivity.BUYING_PLOT_AND_BUILDING &&
      store.userInput?.collateral === LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE)
})
const isLoanPurposeWhatCorrect = computed(() => {
  return store.userInput?.loanPurpose.what !== null && correctWhats.includes(store.userInput?.loanPurpose?.what!)
})
const isLoanPurposeActivityCorrect = computed(() => {
  return correctActivities.includes(store.userInput?.loanPurpose?.activity!)
})

const isCorrectCollateralAndActivity = computed(() => {
  return store.userInput?.collateral === LoanPurposeWhat.BUILDING_PLOT_AND_HOUSE &&
    store.userInput.loanPurpose.activity === LoanPurposeActivity.BUYING_PLOT_AND_BUILDING
})

const isNotAvailableForHomeStartProgram = computed(() => {
  if (store.userInput?.loanPurpose.activity === LoanPurposeActivity.BUYING_PLOT_AND_BUILDING) return !isCorrectCollateralAndActivity.value
  return !isLoanPurposeWhatCorrect.value || !isLoanPurposeActivityCorrect.value
})
const isAvailableForHomeStartProgram = computed(() => {
  return !isNotAvailableForHomeStartProgram.value
})
watchEffect(() => {
  if (isNotAvailableForHomeStartProgram.value) {
    store.setIsHomeStartFormHidden(true)
    return
  }
  store.setIsHomeStartFormHidden(false)
  if (shouldHideAreaInput.value) {
    store.userInput!.homeStartProgramInput!.isBuildingHouse = true;
    if (store.userInput!.homeStartProgramInput!.propertyArea !== null) previousArea.value = store.userInput!.homeStartProgramInput!.propertyArea;
    store.userInput!.homeStartProgramInput!.propertyArea = null;
  } else {
    store.userInput!.homeStartProgramInput!.isBuildingHouse = false;
    if (previousArea.value !== null) {
      store.userInput!.homeStartProgramInput!.propertyArea = previousArea.value;
    }
  }
})

const emit = defineEmits<{
  isFormValidChange: [value: boolean]
}>()

</script>

<style scoped lang="scss">

</style>
