import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {ProductType} from '@/commons/enums/ProductType';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {BankDictionaryElement} from '@/modules/banks/models/BankDictionaryElement';

type BankProductType = ProductType.MORTGAGE | ProductType.CASH
export class BankBranchDTO {
  id!: number;
  name!: string;
  bankId!: number;
}

export class BankBranch extends BankBranchDTO {
  productType: Nullable<BankProductType> = null;
  bank!: Nullable<BankDictionaryElement>;
  type!: Nullable<BankSymbol>;
  displayName!: string;

  constructor(bankBranchDTO: BankBranchDTO, type?: keyof BankDictionaryDTO) {
    super();
    if (type) {
      switch (type) {
      case 'mortgage': this.productType = ProductType.MORTGAGE;
        break;
      case 'cash': this.productType = ProductType.CASH;
        break;
      }
    }

    Object.assign(this, bankBranchDTO);
    this.bank = banksDictionary().find(bank => bank.id === this.bankId) || null;
    this.type = this.bank ? this.bank.type : null;
    this.displayName = this.bank ? `${this.bankId === 0 ? '' : `${this.bank.name_pl}`} ${this.name}` : this.name;
  }
}

export interface BankBranchDictionaryElement {
  id: number,
  code: BankSymbol,
  branches: BankBranchDTO[],
}

export type BankDictionaryDTO = {
  [key in 'cash' | 'mortgage']: BankBranchDictionaryElement[]
}
