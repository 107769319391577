import {CategoryObj, RegulationCategory} from '@/modules/regulation/enums/RegulationCategory';
import {getBankNameById} from '@/commons/dictionaries/BanksDictionary';
import {Utils} from '@/services/utils/BasicUtils';

export abstract class RegulationDTO {
  public id!: RegulationCategory;
  public category: CategoryObj = new CategoryObj({id: RegulationCategory.OTHER, name: 'inne', });
  public bankId: Nullable<number> = null;
  public visible: boolean = true;
  public isHighPriority: boolean = false;
  public title: string = '';
  public details: string = '';
  public isFavorite!: boolean;
  public visited!: boolean;
  public createdAt: string = (new Date()).toISOString(); // To również działa jako data publikacji
}

export default class Regulation extends RegulationDTO {
  constructor(regulationDTO?: RegulationDTO) {
    super();
    if (regulationDTO) {
      if (regulationDTO.category) {
        regulationDTO.category = new CategoryObj(regulationDTO.category);
      }
      Utils.assign(this, regulationDTO);
    }
  }

  public get categoryColor() {
    const chipColors: {[key: number]: string} = {
      [RegulationCategory.MORTGAGE]: '#00bd93',
      [RegulationCategory.CASH]: '#f4b154',
      [RegulationCategory.SME]: '#9e00e4',
      [RegulationCategory.NO_BANK]: '#ef3564',
      [RegulationCategory.CONTEST]: '#5086dc',
      [RegulationCategory.LENDI]: '#35008c',
      [RegulationCategory.PLATFORM]: '#687c10',
      [RegulationCategory.REPORTS]: '#ca5a1a',
      [RegulationCategory.OTHER]: '#6d6d6d',
    };
    return chipColors[this.category.id];
  }

  public get categoryName() {
    return this.category.name;
  }

  get bankName(): string {
    // TODO:ZMIENIć TO NA STARY BGZ
    if (this.bankId === 24) {
      return 'BNP Paribas';
    }
    return this.bankId ? getBankNameById(this.bankId) : '';
  }

  public get textDecoration() {
    return (!this.visited ? 'bold ' : ' ') + (this.visible ? '' : 'color: #a6aab3egu');
  }
}
