<template>
  <v-card>
    <v-card-title class="pb-0">
      <span class="headline-2">{{ $i18n.useSimulationSendOrDownload.SEND_OR_DOWNLOAD_PDF_WITH_OFFERS_TITLE }} </span>
      <v-tabs v-model="tabsModel">
        <v-tab>
          {{ $i18n.useSimulationResult.SELECTED_OFFERS }} - {{modal.modalData.selectedOffers.length}}
        </v-tab>
        <v-tab>
          {{ $i18n.useSimulationResult.PRINTOUT_SETTINGS }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pb-0 pt-6">
      <v-tabs-items v-model="tabsModel">
        <v-tab-item>
          <offers-preview :selected-offers="modal.modalData.selectedOffers" :user-input="modal.modalData.userInput"/>
        </v-tab-item>
        <v-tab-item>
          <printout-settings
            :product-type="modal.modalData.productType"
            @closeModal="closeModal"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        @click="prepareAnnSendOffers"
        :loading="sendLoading"
        :disabledActions="disabledActions"
        color="primary"
        depressed
      >
        <v-icon left>
          mdi-email-outline
        </v-icon>
        {{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS }}
      </v-btn>
      <v-btn
        @click="downloadPDF"
        :loading="downloadLoading"
        :disabledActions="disabledActions"
        depressed
        outlined
      >
        <v-icon left>
          mdi-download
        </v-icon>
        {{ $i18n.useButtonCommons.DOWNLOAD }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import PrintoutSettings from '@/components/calculator/printout/PrintoutSettings.vue';
import OffersPreview from '@/components/calculator/printout/OffersPreview.vue';
import Deal from '@/models/Deal';
import SimulationDealPicker from '../results/SimulationDealPicker.vue';
import SendOffersConfirmModal from '@/components/calculator/results/offers/SendOffersConfirmModal.vue';
import { useSimulationStore } from '../services/SimulationStore';
import { usePrintout } from '@/components/calculator/printout/usePrintout';
import DealsApi from '@/modules/deals/services/DealsApi';
import { useModalInstance } from '@/composables/useModalInstance';
import { useInstance } from '@/composables/useInstance';
import DateUtils from '@/services/utils/DateUtils';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const tabsModel = ref<number>(1);
const sendLoading = ref<boolean>(false);
const downloadLoading = ref<boolean>(false);
const disabledActions = ref<boolean>(false);
const store = useSimulationStore();
const modal = useModalInstance();
const { $modalService, $snackbarService, $user, $errorHandler, } = useInstance();
const printout = usePrintout();

const prepareAnnSendOffers = async() => {
  disabledActions.value = true;
  if (!store.deal) {
    await pickDealToSimulation();
  }

  if (store.deal) {
    sendLoading.value = true;
    sendOffers();
  }

  disabledActions.value = false;
  sendLoading.value = false;
};

const sendOffers = async() => {
  const response = await $modalService.open<Deal>(SendOffersConfirmModal, { clients: store.deal!.clients, });
  if (response) {
    try {
      const url = await printout.getOffersPDFUrl();
      const fileName = printout.preparePrintoutName(store.simulationId || '');
      const sendOffersResponse = await DealsApi.sendSimulationPdf(store.deal!.id, $user.value.id, url, fileName);
      if (sendOffersResponse) {
        $snackbarService.openSuccessSnackbar(I18NGetter().useSimulationSendOrDownload.SENDING_OFFERS_SUCCESS);
      }
    } catch (e) {
      $errorHandler(e, I18NGetter().useSimulationSendOrDownload.SENDING_OFFERS_ERROR);
    } finally {
      modal.closeModal();
    }
  }
};

const pickDealToSimulation = async() => {
  const deal: Deal | null = await $modalService.open<Deal>(SimulationDealPicker, {
    productType: modal.modalData.productType,
    simulationId: store.simulationId,
    additionalInfo: I18NGetter().useSimulationSendOrDownload.LOAD_DEAL_INFO,
  }) ?? null;
  if (deal) {
    await store.init(store.productType!, store.simulationId, deal);
  }
};

const downloadPDF = async() => {
  downloadLoading.value = true;
  disabledActions.value = true;
  await printout.downloadSimulationPrintout();
  downloadLoading.value = false;
  disabledActions.value = false;
  modal.closeModal();
};
</script>

<style scoped lang="scss">
:deep(.v-tab) {
  font-weight: normal;
  position: relative;
  letter-spacing: 0;
  min-width: 0;
  padding: 0 0 8px 0;
  margin-right: 24px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  &:before {
    top: initial;
    height: 3px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  &:hover:before {
    opacity: .6;
  }
}
</style>
