import DictionaryStore from '@/commons/services/store/DictionaryStore';
import {BankDictionaryElement} from '@/modules/banks/models/BankDictionaryElement';

type BanksDictionaryGetterType = (allBanks?: boolean) => BankDictionaryElement[];

export const banksDictionary: BanksDictionaryGetterType = (allBanks: boolean = false) => {
  return DictionaryStore
    .getDictionary('bankDictionary')
    .filter(x => allBanks || x.enabled);
};

export function getBankNameById(id?: number): string {
  const bank = banksDictionary().find(bank => bank.id === id);
  return bank ? bank.name_pl : '';
}

export function getBankById(id?: number): Nullable<BankDictionaryElement> {
  return banksDictionary().find(bank => bank.id === id) ?? null;
}
