import {ProductType} from '@/commons/enums/ProductType';
import {AccreditationStep} from '@/commons/enums/AccreditationStep';
import {FormSteps} from '@/components/calculator/CalculatorRoutes';

export const Routes = {
  BANK_SECRECY: 'BANK_SECRECY',
  ABOUT: 'about',
  DIVISIONS: {
    DIVISIONS_LIST: 'DIVISIONS_LIST',
    DIVISIONS_DETAILS: 'DIVISIONS_DETAILS',
  },
  DEALS: {
    DEALS_LIST: 'DEALS_LIST',
    KANBAN_DEALS_LIST: 'KANBAN_DEALS_LIST',
    DEALS_FOR_REPORTING: 'DEALS_FOR_REPORTING',
    DEAL_DETAILS: 'DEAL_DETAILS',
    DEAL_DETAILS_TAB: {
      PREVIEW: '0',
      PROCESS: '1',
      DOCUMENTS: '2',
      HISTORY: '3',
      REPORT: '4',
    },
    WIZARD: {
      BUILD_TRUST_SECTION: 'buildTrustSection',
      CHOOSE_OFFERS: 'chooseOffers',
      CHECKLIST: 'checklist',
      BORROWERS: 'borrowers',
      CLIENT_DATA: 'clientDataJson',
      CREDIT_DETAILS: 'creditDetails',
      REAL_ESTATES: 'realEstates',
      SUBMIT_APPLICATION: 'submitApplication',
      SIGNING_DOCUMENTS: 'signingDocuments',
      REPORT: 'report',
    },
  },
  ACTIVITIES: {
    CALENDAR: 'CALENDAR',
    USER_CALENDAR: 'USER_CALENDAR',
    ADMIN_CALENDAR: 'ADMIN_CALENDAR',
    ACTIVITY_DETAILS: 'ACTIVITY_DETAILS',
  },
  LOAN_APPLICATION: {
    LOAN_APPLICATIONS_LIST: 'LOAN_APPLICATIONS_LIST',
    LOAN_APPLICATION_DETAILS: 'LOAN_APPLICATION_DETAILS',
  },
  CLIENTS: {
    CLIENT_LIST: 'CLIENT_LIST',
    CLIENT_DETAILS: 'CLIENT_DETAILS',
  },
  PROVISION: {
    LIST: (type: ProductType) => `${type}-list`,
    PRINT: 'PROVISION_PRINT',
  },
  REGULATIONS: {
    REGULATION_LIST: 'REGULATION_LIST',
    REGULATION_DETAILS: 'REGULATION_DETAILS',
    ADD_REGULATION: 'ADD_REGULATION',
  },
  ACCREDITATION_PANEL: {
    PERMISSIONS_BANKS: 'PERMISSIONS_BANKS',
    COMPLIANCE: 'COMPLIANCE',
  },
  REPORT: {
    REPORT_LIST: 'REPORT_LIST',
    REPORT_DETAILS: 'REPORT_DETAILS',
  },
  PROSPECT: {
    LIST: 'PROSPECT_LIST',
    DETAILS: 'PROSPECT_DETAILS',
  },
  FILEMANAGER: {
    FILEMANAGER: 'FILEMANAGER',
  },
  ISSUES: {
    ISSUES: 'ISSUES',
    ISSUES_QUERY: 'ISSUES_QUERY',
  },
  TRAINING_FILES: {
    TRAINING_FILES: 'TRAINING_FILES',
    TRAINING_DETAILS: 'TRAINING_DETAILS',
  },
  LENDI_BEGIN: {
    LENDI_BEGIN: 'LENDI_BEGIN',
    LENDI_PRACTICES: 'LENDI_PRACTICES',
  },
  PROCEDURES: {
    PROCEDURES_LIST: 'PROCEDURES_LIST',
    PROCEDURES_DETAILS: 'PROCEDURES_DETAILS',
  },
  DISCUSSIONS: {
    DISCUSSIONS_LIST: 'DISCUSSIONS_LIST',
    DISCUSSIONS_DETAILS: 'DISCUSSIONS_DETAILS',
  },
  PROMOTIONAL_COUPONS: 'promotional-coupons',
  EARN_WITH_LENDI: 'earn-with-lendi',
  CALCULATOR: ProductType,
  NEW_CALCULATOR: {
    PRINT: 'PRINT',
    SCHEDULE_PRINT: 'SCHEDULE_PRINT',
    REDIRECT: (type: ProductType) => `${type}-redirect`,
    SUMMARY: (type: ProductType) => `${type}-summary`,
    FORM: (type: ProductType, step: FormSteps) => `${type}-form-${step}`,
  },
  REGISTER: 'REGISTER',
  USER_EDIT: 'USER_EDIT',
  PASSWORD_MANAGEMENT: {
    LOGOUT: 'LOGOUT',
    LOGIN: 'LOGIN',
    PASSWORD_CHANGE: 'PASSWORD_CHANGE',
    PASSWORD_RESET: 'PASSWORD_RESET',
  },
  CLIENT_USER: {
    CLIENT_NAVIGATION: 'CLIENT_NAVIGATION',
    CLIENT_CHECKLIST: 'CLIENT_CHECKLIST',
    LOGIN: 'CLIENT_LOGIN',
    MULTIFORM: 'CLIENT_MULTIFORM',
    OFFERS: 'CLIENT_OFFERS',
    DOCUMENTS: 'CLIENT_DOCUMENTS',
  },
  ADMIN: {
    USERS_MANAGMENT: 'USERS_MANAGMENT',
    USERS_MANAGMENT_DETAILS: 'USERS_MANAGMENT_DETAILS',
    DIVISIONS_MANAGEMENT: 'DIVISIONS_MANAGEMENT',
    DIVISIONS_MANAGEMENT_DETAILS: 'DIVISIONS_MANAGEMENT_DETAILS',
    DELEGATION_LISTS: 'DELEGATION_LISTS',
    SMS_LIST: 'SMS_LIST',
    EMAIL_LIST: 'EMAIL_LIST',
    BANK_BRANCHES_LIST: 'BANK_BRANCHES_LIST',
    FINANCIAL_INSTITUTION_LIST: 'FINANCIAL_INSTITUTION_LIST',
    PRODUCTS: 'PRODUCTS',
    HUMAN_RESOURCES: 'HUMAN_RESOURCES',
    BIG_RAPORTS_AGREEMENTS: 'BIG_RAPORTS_AGREEMENTS',
    PROSPECT_SETTINGS: 'PROSPECT_SETTINGS',
    MULTIFORM_TEST_PANEL: 'MULTIFORM_TEST_PANEL',
    MULTIFORM_ENGINE_TEST: 'MULTIFORM_ENGINE_TEST',
    MULTIFORM_ENGINE: 'MULTIFORM_ENGINE',
  },
  MULTIFORM_ENGINE_PRINT: 'MULTIFORM_ENGINE_PRINT',
  MULTIFORM_ENGINE_TEST: 'MULTIFORM_ENGINE_TEST',
  MULTIFORM_ENGINE_PREVIEW: 'MULTIFORM_ENGINE_PREVIEW',
  DASHBOARD: 'dashboard',
  BLOCKED_ACCESS: 'BLOCKED_ACCESS',
  HR_LOCKED: 'HR_LOCKED',
  STATISTIC: 'stats',
  ACCREDITATION: AccreditationStep,
  ACCREDITATION_BASE: 'ACCREDITATION_BASE',
  BRANCHES: {
    BRANCHES_LIST: 'BRANCHES_LIST',
    BRANCH_DETAILS: 'BRANCH_DETAILS',
  },
  PARTNERS: {
    PARTNERS_LIST: 'PARTNERS_LIST',
    PARTNER_DETAILS: 'PARTNER_DETAILS',
  },
  BHP_TEST: {
    START_BHP_TEST: 'start',
  },
  KNF_TEST: {
    START_KNF_TEST: '/knf_test/start',
  },
  CYBER_SECURITY_TEST: {
    START_CYBER_SECURITY_TEST: '/cyberSecurity_test/start',
  },
  STATEMENTS_TEST: {
    START_STATEMENTS_TEST: '/statements_test/start',
  },
  RODO_TEST: {
    START_RODO_TEST: '/rodo_test/start',
  },
  AML_TEST: {
    START_AML_TEST: '/aml_test/start',
  },
  NOT_FOUND: 'NOT_FOUND',
  ERROR: 'ERROR',
  DESIGN: 'DESIGN',
  NPS: {
    NPS_LIST: 'NPS_LIST',
    NPS_DETAILS: 'NPS_DETAILS',
  },
  TELECONFERENCE: 'teleconference',
  MEET: 'meet',
};
