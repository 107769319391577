<template>
  <v-card>
    <v-card-title class="subtitle-1 font-weight-medium">
      <v-layout xs12 align-center ma-0>
        <template v-if="!$slots.title">
          <a v-if="$listeners['click:title']" @click="$emit('click:title')">
            {{ title }}
            <v-icon left>mdi-chevron-right</v-icon>
          </a>
          <span v-else>
            {{ title }}
          </span>
        </template>
        <slot v-else name="title"/>
        <v-spacer></v-spacer>
        <slot name="actions"/>
      </v-layout>
    </v-card-title>
    <v-divider />
    <v-card-text>
        <slot/>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class CardEditDataWrapper extends Vue {
    @Prop()
    title: string | undefined;
}
</script>

<style scoped>
</style>
