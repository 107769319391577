<template>
  <div>
    <template  v-for="(offer, index) in selectedOffers">
      <offer-details-short
        :user-input="userInput"
        :key="offer?.info?.id"
        :offer="offer" />
      <v-divider v-if="index !== selectedOffers.length - 1" :key="`divider${offer?.info?.id}`" class="mb-4" />
    </template>
  </div>
</template>

<script setup lang="ts">
import Offer from '@/models/Offer';
import OfferDetailsShort from '@/components/calculator/printout/OfferDetailsShort.vue';
import { SimulationDemand } from '@/models/Demand';

const props = withDefaults(defineProps<{
  selectedOffers: Offer[],
  userInput: SimulationDemand,
}>(), {
  selectedOffers: () => [],
  userInput: () => new SimulationDemand(),
});
</script>

<style lang="scss" scoped>

</style>
