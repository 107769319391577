<template>
  <v-menu offset-y max-height="250px" close-on-click>
    <template v-slot:activator="{ on }">
      <v-chip
        @click.stop.prevent
        v-if="currentStatus"
        :disabled="disabled || (currentStatus.isFinalStage
        && !(isCurrentlyVerifier || $user.isReportDepartment || $user.isChiefOfDivision))"
        :small="small"
        :color="currentStatus.color.length ?
          $vuetify.theme.currentTheme[currentStatus.color] + '33' :
          ''"
        v-on="clickable ? on : ''"
        :class="[`text--${currentStatus.color}`,
        'text--darken-4', 'pl-2', currentStatus.isFinalStage ? 'pr-2' : '']"
        :text-color="currentStatus.color">
        {{ currentStatus.displayName }}
        <v-icon v-if="clickable">mdi-menu-down</v-icon>
      </v-chip>
    </template>
    <v-list dense v-if="clickable">
      <template v-for="(item, index) in statusList">
        <v-list-item :disabled="item.disabled" v-if="isCurrentlyVerifier || $user.isChiefOfDivision || $user.isReportDepartment || item.visible"
          :key="index"
          @click="!item.disabled && changeLoanApplicationStep(loanApplicationId, item.id)">
          <v-list-item-title :class="[`${item.color}--text`]">
            {{ item.displayName }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  LoanApplicationStepsDictionary,
  LoanApplicationStep,
  LoanApplicationStepsOnlyForAdminOrVerifier
} from '@/models/LoanApplicationStep';
import AuthService from '@/modules/user/AuthService';
import LoanApplicationStore from '@/modules/loanApplication/store/LoanApplicationStore';

@Component
export default class LoanApplicationStatusChip extends Vue {
  @Prop({required: true, })
  public loanApplicationId!: number;

  @Prop({required: true, })
  public value!: number;

  @Prop({default: false, })
  public clickable!: boolean;

  @Prop({default: false, })
  public reportMode?: boolean;

  @Prop({default: false, })
  public small!: boolean;

  @Prop({default: false, })
  public disabled!: boolean;

  public statusObject: Array<LoanApplicationStep> = LoanApplicationStepsDictionary;

  public get statusList() {
    if (this.reportMode && this.clickable) {
      return this.statusObject.filter(status => status.id !== this.value && status.reportStatus);
    } else {
      return this.statusObject.filter(status => status.id !== this.value);
    }
  }

  public get currentStatus(): LoanApplicationStep {
    return this.statusObject.find(el => el.id === this.value)!;
  }

  public get isCurrentlyVerifier() {
    return AuthService.isCurrentlyVerifier;
  }

  public async changeLoanApplicationStep(loanApplicationId: number, stepId: number) {
    try {
      await LoanApplicationStore.setLoanApplicationStep(loanApplicationId, this.value, stepId);
      this.$emit('input', stepId);
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data.message) {
        this.$snackbarService.openErrorSnackbar(e.response.data.message);
      }
    }
  }
}
</script>
