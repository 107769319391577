import {IClientBadgeViewModel} from '@/modules/client/models/ClientBadgeViewModel';
import {PersonalDetails} from '@/modules/multiForm/shared/MultiformModels';
import {ClientBorrowerCardViewModel} from '@/modules/client/models/BorrowerCardViewModel';
import {Utils} from '@/services/utils/BasicUtils';
import {Gender} from '@/models/enums/Gender';

export class AnonymousBorrowerDto {
  public id!: number;
  public dealId!: number;
  public gender!: Gender;
  public birthday?: string;
  public clientDataJson?: PersonalDetails;
}

export class AnonymousBorrower extends AnonymousBorrowerDto
  implements IClientBadgeViewModel, NullableDeep<ClientBorrowerCardViewModel> {
  public clientDataJson!: PersonalDetails;
  readonly initials: string = '?';

  get age(): number | null {
    if (this.birthday) {
      const [birthMonth, birthYear,] = this.birthday.split('-').map(x => Number(x));
      const birthDate = new Date(birthYear, birthMonth);
      const today = new Date();
      const m = today.getMonth() - birthDate.getMonth();
      let age = today.getFullYear() - birthDate.getFullYear();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    } else return null;
  };

  public get avatarUrl(): string {
    return this.gender === Gender.FEMALE
      ? require('@/assets/img/crew/avatarAnonimK.svg')
      : require('@/assets/img/crew/avatarAnonimM.svg');
  }

  get displayName(): string {
    const gender = this.gender === Gender.FEMALE ? 'Kobieta' : 'Mężczyzna';
    return gender + (this.age ? `, ${this.age} lat` : '');
  };

  public get isMale(): boolean {
    return this.gender === Gender.MALE;
  }

  public get isFemale(): boolean {
    return this.gender === Gender.FEMALE;
  }

  constructor(dto: AnonymousBorrowerDto) {
    super();
    Utils.assign(this, dto);
  }
}
