<template>
  <v-flex @click="onLogoClick()" :class="[{'mobile-logo': $vuetify.breakpoint.xsOnly},]" class="pointer logo-wrapper">
    <transition name="fade" v-if="$env.getAppDomainConfig().logotypes.minimized">
      <img v-if="isSmallLogo"
           :src="$env.getAppDomainConfig().logotypes.minimized"
           alt="logotype"
           key="mobile-logo"
           class="logo-img logo-img--small mobile-img">
      <img v-else
           :src="$env.getAppDomainConfig().logotypes.contrast"
           alt="logotype"
           class="logo-img"
           key="desktop-logo"
           :class="$onboarding.tours.DASHBOARD.step.PLATFORM_LOGO">
    </transition>
    <template v-else>
      <v-img :src="$env.getAppDomainConfig().logotypes.contrast"
             alt="logotype"
             class="logo-img animate-size"
             :class="[$onboarding.tours.DASHBOARD.step.PLATFORM_LOGO, {'logo-img--small': isSmallLogo}]"/>
    </template>
    <span v-if="$env.isPreprodOrDev"
          class="env-label headline-3 text-lowercase">
        {{ $env.env }}
    </span>
  </v-flex>
</template>
<script lang="ts" setup>
import GoToService from '@/router/GoToService';
import {computed} from 'vue';

const props = defineProps<{
  isMiniMenu: boolean;
  hover: boolean;
  readonly: boolean;
}>();

const isSmallLogo = computed<boolean>(() => {
  return props.isMiniMenu && !props.hover;
});

const onLogoClick = () => {
  if (!props.readonly) {
    GoToService.dashboard();
  }
};
</script>
<style lang="scss" scoped>
.logo-wrapper {
  --logo-width: 96px;
  --logo-width-mobile: 40px;
  --logo-width-small: 48px;

  .logo-img {
    width: var(--logo-width);

    &--small {
      width: var(--logo-width-small);
    }

    &.mobile-img {
      width: var(--logo-width-mobile);
    }
  }
}
.mobile-logo {
  line-height: 0;

  .logo-img {
    max-height: 32px;
  }
}

.logo-wrapper:not(.mobile-logo) {
  .logo-img:not(.mobile-logo):not(.animate-size) {
    position: absolute;
    top: 12px;
  }
}

.mobile-img {
  --transform-origin: 106px 1px;
  --scale-small: 0.15;
}

.fade-enter-active, .fade-leave-active {
  transition: transform .4s, transform-origin .4s, opacity .8s;
}

.fade-leave-to.mobile-img {
  transform: scale(var(--scale-small));
  transform-origin: var(--transform-origin);
  z-index: 1;
}
.fade-leave-to:not(.mobile-img) {
  opacity: 0;
}
.fade-enter.mobile-img {
  transform: scale(var(--scale-small));
  transform-origin: var(--transform-origin);
}

.env-label {
  font-size: 1rem !important;
  position: absolute;
  left: 115px;
  bottom: 0;
  color: $lendi;
}

.animate-size {
  transition: all .4s;
}
</style>
