import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const CyberSecurityNavigation = () => import(/* webpackChunkName: "CyberSecurity" */ '@/modules/cyberSecurity/views/CyberSecurityNavigation.vue');
const StartCyberSecurityTest = () => import(/* webpackChunkName: "CyberSecurity" */ '@/modules/cyberSecurity/views/StartCyberSecurityTest.vue');

const CyberSecurityRoutes: RouteConfig =
  {
    path: 'cyberSecurity_test',
    redirect: '/cyberSecurity_test/start',
    component: CyberSecurityNavigation,
    children: [
      {
        name: Routes.CYBER_SECURITY_TEST.START_CYBER_SECURITY_TEST,
        path: 'start',
        component: StartCyberSecurityTest,
      },
    ],
  };

export default CyberSecurityRoutes;
