import numeral from 'numeral';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

type Rule = (value: string) => boolean | string;

export const useCalculatorFormRules = () => {
  const store = useSimulationStore();

  const loanAmountRules: Rule[] = [(v: string) => numeral(v).value() <= store.userInput?.hypothecValue!,];
  const hypothecValueRules: Rule[] = [
    (v: string) => (numeral(v).value() >= store.userInput?.loanAmount!) ||
    I18NGetter().useMortgageSimulationFormStep1.DOWN_PAYMENT_TOO_LOW,
  ];
  const debtToIncomeRules: Rule[] = [
    (v: string) => numeral(v).value() <= 100 || I18NGetter().useMortgageSimulationResults.DTI_TOO_HIGH,
  ]
  const savingsPercentageRules: Rule[] = [
    (v: string) => (numeral(v).value() < 100),
  ];

  return {
    loanAmountRules,
    hypothecValueRules,
    debtToIncomeRules,
    savingsPercentageRules,
  };
};
