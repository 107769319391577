import {SimulationDemand} from '@/models/Demand';
import EnvironmentService from '@/env/EnvironmentService';

export const useSimulationCurrency = () => {
  const isForeignCurrency = (userInput: Nullable<SimulationDemand>): boolean =>
    userInput?.currency !== EnvironmentService.Environment.currentCurrency();

  return {
    isForeignCurrency,
  };
};
