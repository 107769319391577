<template>
  <v-layout align-center>
    <v-flex shrink>
      <slot/>
    </v-flex>
    <v-spacer/>
    <template v-if="!isOfflineVersion">
      <v-flex shrink>
        <v-row align="center">
          <v-col class="py-0" v-if="$vuetify.breakpoint.mdAndUp">{{$i18n.useTablePagination.RESULTS_ON_PAGE}}:</v-col>
          <v-col class="py-0 shrink">
            <v-select
                v-model="options.rowsPerPage"
                class="body-2 items-count-select elevation-0"
                :menu-props="{'offset-y': true, 'content-class': 'border-top-fix',}"
                dense
                single-line
                :items="rowsPerPage"
                @change="onRowsPerPageChanged"
            />
          </v-col>
        </v-row>
      </v-flex>
      <v-flex shrink class="mx-4" v-if="$vuetify.breakpoint.mdAndUp">
        {{getListPaginationDisplay()}}
      </v-flex>
      <v-flex shrink>
        <v-btn icon @click="goToPreviousPage" :disabled="options.page === 1">
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-flex>
      <v-flex shrink>
      <span v-if="$vuetify.breakpoint.mdAndUp">
        {{options.totalPagesDescription}}
      </span>
        <span v-else>{{options.page}}</span>
      </v-flex>
      <v-flex shrink>
        <v-btn
            icon
            @click="goToNextPage"
            :disabled="options.page >= options.totalPagesNumber">
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
      </v-flex>
    </template>
  </v-layout>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {BaseFilter} from '@/models/Filter';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component
export default class TablePagination extends Vue {
  @Prop({type: Object,})
  public readonly options!: BaseFilter;

  @Prop({type: Array, default: () => [10, 20, 50, 100,], })
  public readonly rowsPerPage!: Array<number>;

  get isOfflineVersion(): boolean {
    return !this.options.quantity;
  }

  public goToNextPage() {
    this.options.page++;
    this.$emit('nextPage');
  }

  public goToPreviousPage() {
    this.options.page--;
    this.$emit('previousPage');
  }

  public getListPaginationDisplay(): string {
    return `${(this.options.page - 1) * this.options.rowsPerPage + 1}-${this.options.page * this.options.rowsPerPage <= this.options.quantity! ? this.options.page * this.options.rowsPerPage : this.options.quantity} ${I18NGetter().useTablePagination.OF} ${this.options.quantity}`;
  }

  @Watch('options.totalPagesNumber')
  public checkPages() {
    if (this.options.page > this.options.totalPagesNumber) {
      this.options.page = 1;
    }
  }

  public onRowsPerPageChanged() {
    this.$emit('rowsPerPageChanged');
  }
}
</script>
<style lang="scss">
  .items-count-select {
    width: 57px;
    .v-select__selection--comma {
      margin: 5px 4px 9px 0!important;
    }
  }
  .border-top-fix .v-card {
    border-radius: 0 0 4px 4px!important;
  }
</style>
