<template>
  <v-card>
    <v-card-title class="bb">
      <v-window :value="step">
        <v-window-item :value="steps.FORM">
          <anonymous-borrower-badge
            :borrower="borrower"
            :readonly="true"/>
        </v-window-item>
        <v-window-item :value="steps.VERIFICATION">
          <client-badge
            :client="newClient"
            :readonly="true"/>
        </v-window-item>
      </v-window>
    </v-card-title>
    <v-card-text v-if="newClient">
      <create-client-windows-form
        :step="step"
        :ref="CREATE_CLIENTS_WINDOW_REF"
        :client="newClient">
        <template v-slot:customErrorAction="{clientId}">
          <a @click="onExistingClient(clientId)">Połącz z klientem</a>
        </template>
      </create-client-windows-form>
    </v-card-text>
    <v-card-actions class="bt">
      <v-layout>
        <v-flex>
          <v-window v-model="step">
            <v-window-item :value="steps.FORM">
              <loading-button
                @click="submitForm"
                primary>
                {{ isSMS ? 'Wyślij kod SMS' : 'Załącz skany'}}
              </loading-button>
              <span v-if="isSMS && newClient.phone" class="ml-2 body-2">
                Kod SMS zostanie wysłany na numer: {{newClient.phone}}
              </span>
            </v-window-item>
            <v-window-item :value="steps.VERIFICATION">
              <loading-button
                class="mr-4"
                @click="step--">
                <v-icon>mdi-chevron-left</v-icon>
              </loading-button>
              <loading-button
                v-if="isSMS"
                @click="checkValidationCode(newClient.smsCode)"
                primary
              >
                Dodaj Klienta
              </loading-button>
              <loading-button
                v-else
                :disabled="!isDocsAttached"
                @click="sendScans()"
                primary>
                Dodaj Klienta
              </loading-button>
            </v-window-item>
          </v-window>
        </v-flex>
        <v-spacer />
        <v-flex class="text-right">
          <v-btn
            @click="closeModal()"
            outlined>
            Anuluj
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import {Component} from 'vue-property-decorator';
import {VueModalComponent} from 'fp-components';
import Client from '@/models/Client';
import EditClientForm from '@/modules/deals/views/edit-deal/EditClientForm.vue';
import {AnonymousBorrower} from '@/modules/client/models/AnonymousBorrower';
import {AgreementType} from '@/modules/client/views/client-agreements/Agreements';
import AddClientSmsApi from '@/modules/client/services/ClientSmsApi';
import ClientApi from '@/modules/client/services/ClientApi';
import ClientScans from '@/models/clientScans/ClientScans';
import {CreateClientFormStep} from '@/modules/client/enums/CreateClientFormStep';
import CreateClientWindowsForm from '@/modules/client/views/create-client/CreateClientWindowsForm.vue';
import {ICreateClientWindowsValidation} from '@/modules/client/models/CreateClientWindowsValidation';
import {ClientScan} from '@/models/clientScans/ClientScanModel';

@Component({
  components: {CreateClientWindowsForm, EditClientForm, },
})
export default class CreateClientFromAnonymousBorrower extends VueModalComponent<Client> {
  public newClient: Client = new Client();
  public step: CreateClientFormStep = CreateClientFormStep.FORM;
  public steps: typeof CreateClientFormStep = CreateClientFormStep;
  public borrower: AnonymousBorrower = this.modalData.borrower;

  public readonly CREATE_CLIENTS_WINDOW_REF: string = 'CREATE_CLIENTS_WINDOW_REF';

  public get clientDocuments(): ClientScan[] {
    return ClientScans.filter(doc => !doc.condition || doc.condition(this.newClient));
  };

  public async onExistingClient(clientId: string) {
    try {
      const response = await ClientApi.mergeBorrowerWithClient(this.borrower, Number(clientId));
      this.closeModal(response);
    } catch (e) {
      this.errorHandler(e, 'Nie można powiązać klienta.');
    }
  };

  public async checkValidationCode(code: string) {
    if (code.length === 6) {
      try {
        const newClient = await ClientApi.createClientFromAnonymousWithSMS(this.borrower, this.newClient);
        this.closeModal(newClient);
      } catch (e) {
        this.errorHandler(e, 'Wystąpił błąd podczas dodawania klienta.');
      }
    }
  };

  get isSMS(): boolean {
    return this.newClient.verificationType === AgreementType.SMS;
  };

  get isDocsAttached(): boolean {
    return !this.clientDocuments.map(x => x.file).includes(null);
  };

  get createClientWindowsRef(): ICreateClientWindowsValidation {
    return this.$refs[this.CREATE_CLIENTS_WINDOW_REF] as any as ICreateClientWindowsValidation;
  };

  async submitForm() {
    if (this.createClientWindowsRef.validateClientForm()) {
      if (this.isSMS) {
        await this.sendSMSCode();
      }
      this.step = CreateClientFormStep.VERIFICATION;
    }
  }

  private async sendScans() {
    try {
      const formToSend = new FormData();
      this.newClient.name = this.newClient.displayName;
      formToSend.append('formData', JSON.stringify(this.newClient));
      const scans = this.clientDocuments.filter(scan => scan.file);
      scans.forEach(scan => formToSend.append(scan.key, scan.file!));
      const newClient = await ClientApi.createClientFromAnonymousWithScans(this.borrower, formToSend);
      this.closeModal(newClient);
    } catch (e) {
      this.errorHandler(e, 'Wystąpił błąd podczas dodawania klienta.');
    }
  }

  private async sendSMSCode() {
    try {
      await AddClientSmsApi.sendVerificationCode(this.newClient.phone!);
      this.$snackbarService.openSuccessSnackbar('Pomyślnie wysłano wiadomość SMS na podany numer telefonu.');
    } catch (e) {
      this.errorHandler(e, 'Nie można wygenerować kodu SMS.');
    }
  }
}
</script>
