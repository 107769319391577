import {Address, EstateAddress} from '@/modules/multiForm/shared/MultiformModels';
import {Utils} from '@/services/utils/BasicUtils';
import {MarketType} from '@/models/simulation/MarketType';
import {RealEstateType} from '@/modules/multiForm/shared/Types';

export class RealEstateDTO {
  type: Nullable<RealEstateType> = null;
  size: Nullable<number> = null;
  price: Nullable<number> = null;
  address: EstateAddress = new EstateAddress();
  market: Nullable<MarketType> = null;

  isLoanEstate: Nullable<boolean> = null;
  isMortgageEstate: Nullable<boolean> = null;

  right: Nullable<string> = null;
  plotSize?: number;
  ekwPlotNumber?: number;
  numberOfRooms?: number;
  constructionType: Nullable<string> = null;
  garageCase: Nullable<string> = null;
  sewersType: Nullable<string> = null;
  waterIntake: Nullable<string> = null;
  investitionDate?: string;
  willBeUsedAsEconomicRealestate?: boolean;
  isEkwExist?: boolean;
  estateOwnerType: Nullable<string> = null;
  estateOwnerName: Nullable<string> = null;
  estateOwnerPhone?: string;
  buildPermissionRequired?: boolean;
  valuationType: boolean = false;
  isThirdPersonMortgageEstate?: boolean;
  usedAsEconomicRealestatePercentage?: number;
  ekwCode: Nullable<string> = null;
  ekwNumber: Nullable<string> = null;
  isEkwApplied?: boolean;
  valuationCost?: number;
  valuationCompany: Nullable<string> = null;
  valuationCompanyAuthLevel: Nullable<string> = null;
  notes: Nullable<string> = null;
  estateSize: Nullable<number> = null;
  estateValue: Nullable<number> = null;
}

export class RealEstate extends RealEstateDTO {
  constructor(dto?: PartialDeep<RealEstateDTO>) {
    super();
    if (dto) {
      if (dto.address) {
        dto.address = new EstateAddress(dto.address);
      }
      Utils.assign(this, dto);
    }
  }
}
