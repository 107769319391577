import {ComponentInstance, computed, getCurrentScope, Ref, ref, SetupContext} from 'vue';
import {pick} from 'lodash-es';
import {
  OfferDataTableElementKey,
  OfferTableElement,
  OfferTableViewElement
} from '@/components/calculator/results/offers/OfferTableElement';
import {ProductType} from '@/commons/enums/ProductType';
import {SimulationProductType, useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {TableElementHeight} from '@/components/calculator/results/tableElements/TableElementsConstants';
import {CashTableElements} from '@/components/calculator/results/tableElements/CashTableElements';
import {MortgageTableElements} from '@/components/calculator/results/tableElements/MortgageTableElements';

type TableRowsLocalStorageKeys = Record<SimulationProductType, `${ProductType}_TABLE_ROWS`>

const offerTableElements: Ref<OfferTableElement[]> = ref([]);

export const useOffersTable = (props?: any, context?: SetupContext, componentScope: any = {}) => {
  const store = useSimulationStore();

  const getOnlyVisibleTableElements = (elements: OfferTableElement[], isPrintMode: boolean = false) =>
    elements.filter(x => !x.condition || x.condition(store.userInput, isPrintMode));

  const getDataTableByType = (productType: SimulationProductType): OfferTableElement[] => {
    const elements: Record<SimulationProductType, OfferTableElement[]> = {
      [ProductType.MORTGAGE]: MortgageTableElements,
      [ProductType.CASH]: CashTableElements,
    };
    return elements[productType];
  };

  const getPrintoutSettingsElements = (productType: SimulationProductType): OfferTableElement[] => {
    return getOnlyVisibleTableElements(getDataTableByType(productType), true);
  };

  const LOCAL_STORAGE_KEYS: TableRowsLocalStorageKeys = {
    [ProductType.MORTGAGE]: 'productTypeMortgage_TABLE_ROWS',
    [ProductType.CASH]: 'productTypeCash_TABLE_ROWS',
  };

  const listElementHeight = (element: OfferTableViewElement, offset: number = 0) => {
    const height = (element.height || TableElementHeight.DEFAULT) + offset;
    return `height: ${height}px !important; min-height: ${height}px;`;
  };

  const currentScope = getCurrentScope() as any;
  const vm: ComponentInstance = currentScope.effects && currentScope.effects.length ? currentScope.effects[0].vm : {};
  const scope = computed(() => ({
    ...pick(vm, Object.keys(componentScope)),
    ...props,
  }));

  const setDataTableElementByType = (productType: SimulationProductType) => {
    const rows = getDataTableByType(productType);
    const tableRowsKeys = getTableRows(productType);
    const currentRows = rows.map(x => x.key);
    const allRows = [
      ...tableRowsKeys.filter(x => currentRows.includes(x)),
      ...currentRows.filter(x => !tableRowsKeys.includes(x)),
    ];
    offerTableElements.value = allRows
      .map(x => rows.find(row => row.key === x)!)
      .filter(x => x !== undefined);
  };

  const setDataTableElement = (elements: OfferTableElement[]) => {
    offerTableElements.value = elements;
  };

  const saveTableRows = (productType: SimulationProductType, rows: OfferDataTableElementKey[]) => {
    const key = LOCAL_STORAGE_KEYS[productType];
    localStorage.setItem(key, JSON.stringify(rows));
  };

  const getTableRows = (productType: SimulationProductType): OfferDataTableElementKey[] => {
    const savedKeys = localStorage.getItem(LOCAL_STORAGE_KEYS[productType]);
    return savedKeys ? JSON.parse(savedKeys) as OfferDataTableElementKey[] : [];
  };

  const renderedOfferTableElements = computed<OfferTableElement[]>({
    get: () => getOnlyVisibleTableElements(offerTableElements.value, store.isPrintMode),
    set: (value: OfferTableElement[]) => setDataTableElement(value),
  });

  return {
    scope,
    setDataTableElement,
    setDataTableElementByType,
    saveTableRows,
    getTableRows,
    listElementHeight,
    getPrintoutSettingsElements,
    renderedOfferTableElements,
  };
};
