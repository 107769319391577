<template>
  <v-form ref="FORM_NAME" v-model="isFormValid">
    <slot :onAction="onAction" :changed="changed" :actionEnabled="actionEnabled" :isFormValid="isFormValid"/>
  </v-form>
</template>

<script lang="ts">
import {cloneDeep, isEqual} from 'lodash-es';
import { Utils } from '@/services/utils/BasicUtils';
import {defineComponent, getCurrentInstance, onMounted, ref, Ref, watch} from 'vue';

type ModelValue = object | Array<any> | string | number | boolean | null

export interface ChangeDetection {
  $refs: {
    FORM_NAME: HTMLFormElement,
  }
}

export default defineComponent({
  props: {
    value: {
      required: true,
    },
    hasChanges: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const initialValue: Ref<ModelValue> = ref(cloneDeep(props.value) as ModelValue);
    const previousValue: Ref<ModelValue> = ref(cloneDeep(props.value) as ModelValue);
    const changed: Ref<boolean> = ref(false);
    const actionEnabled: Ref<boolean> = ref(false);
    const isFormValid = ref<boolean>(false);
    const FORM_NAME = ref<Nullable<HTMLFormElement>>(null);

    function loadWatchersForInputs() {
      FORM_NAME.value?.inputs.forEach((x: any) => {
        watch(() => x.lazyValue || x.value, (newValue: any, oldValue: any) => {
          if (!isEqual(cloneDeep(newValue), oldValue)) {
            context.emit('change', cloneDeep(newValue));
            FORM_NAME.value?.validate();
          }
        });
      });
    }

    onMounted(() => {
      watch(() => FORM_NAME.value?.inputs.length, (newValue: any, oldValue: any) => {
        loadWatchersForInputs();
      });
      loadWatchersForInputs();

      watch(() => cloneDeep(props.value), (newValue: any, oldValue: any) => {
        if (isEqual(cloneDeep(previousValue.value), cloneDeep(newValue))) {
          return;
        }

        if (Utils.isEmptyValue(previousValue.value)) {
          initialValue.value = cloneDeep(newValue);
        } else {
          actionEnabled.value = !isEqual(cloneDeep(initialValue.value), cloneDeep(newValue));
          changed.value = !isEqual(cloneDeep(newValue), initialValue.value);
          context.emit('update:hasChanges', changed.value);
        }
        previousValue.value = cloneDeep(newValue);
      });
    });

    const onAction = async(callback: () => Promise<void>) => {
      try {
        await callback();
        initialValue.value = cloneDeep(props.value) as ModelValue;
        changed.value = false;
        actionEnabled.value = false;
      } catch (e) {
        instance?.proxy.errorHandler(e);
      }
    };

    return {
      changed,
      actionEnabled,
      onAction,
      FORM_NAME,
      isFormValid,
    };
  },
});
</script>
