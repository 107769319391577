<template>
  <navigation-tabs
    :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.AVAILABLE_OFFERS"
    :tabs-headers="TABS_HEADERS"
    @change="onIsShownAvailableProductsChanged" />
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import NavigationTabs from '@/components/commons/NavigationTabs.vue';
import {OfferTableElement} from '@/components/calculator/results/offers/OfferTableElement';
import {MortgageTableElements} from '@/components/calculator/results/tableElements/MortgageTableElements';
import {RefusedMortgageTableElements} from '@/components/calculator/results/tableElements/RefusedMortgageTableElements';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export default defineComponent({
  components: {
    NavigationTabs,
  },
  setup() {
    const TABS_HEADERS = [I18NGetter().useMortgageSimulationAvailableProductsTabs.AVAILABLE,
      I18NGetter().useMortgageSimulationAvailableProductsTabs.EXCLUDED,] as const;

    const {setDataTableElement,} = useOffersTable();
    const store = useSimulationStore();

    const onIsShownAvailableProductsChanged = (value: 0 | 1) => {
      const isShownAvailableProducts: boolean = value === 0;
      store.isShownAvailableProducts = isShownAvailableProducts;
      const elements: OfferTableElement[] = isShownAvailableProducts
        ? MortgageTableElements : RefusedMortgageTableElements;
      setDataTableElement(elements);
    };

    return {
      TABS_HEADERS,
      onIsShownAvailableProductsChanged,
    };
  },
});
</script>
