import {ModalInstance, ModalInstanceOld} from '@/components/L/Modal/ModalTypes';
import {useObserver} from '@/composables/useObserver';
import {defineStore} from 'pinia';
import {Ref, ref} from 'vue';

const STORE_ID = 'modal-store' as const
const getModalObserverKey = (modalUuid: string): string => 'modal-' + modalUuid;

const isModalInstance = (modalInstance: ModalInstance | ModalInstanceOld): boolean => 'props' in modalInstance
export const useModalStore = defineStore(STORE_ID, () => {
  const modals: Ref<Array<ModalInstance | ModalInstanceOld>> = ref([])

  const addModal = (newModal: ModalInstance | ModalInstanceOld, closeModal?: Function) => {
    modals.value.push(newModal);
    if (isModalInstance(newModal)) {
      if (!closeModal) throw new Error('Modal of class ModalInstance needs closeModalFunction to work properly!');

      const { subscribe, } = useObserver<ModalInstance>(getModalObserverKey(newModal.uuid));

      subscribe((modalPayload) => {
        closeModal(modalPayload);
      })
    }
  }

  const closeModal = <T>(modalInstance: ModalInstance | ModalInstanceOld, payload?: T) => {
    const modalIndex = modals.value.findIndex(modal => modal.uuid === modalInstance.uuid);

    if (modalIndex === -1) {
      throw new Error(`Modal with uuid ${modalInstance.uuid} not found`);
    }

    removeModalInstance(modalIndex);

    if (isModalInstance(modalInstance)) {
      const { updateSubject, } = useObserver<T>(getModalObserverKey(modalInstance.uuid));
      updateSubject(payload);
    }
  };

  const removeModalInstance = (index: number) => {
    modals.value.splice(index, 1);
  };

  return {
    modals,
    addModal,
    closeModal,
  }
})
