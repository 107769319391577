<template>
  <base-badge
    :right="right"
    :small="small"
    :readonly="readonly || removeLink"
    :hideActions="hideActions"
    :label="badgeLabel"
    :loading="client === null"
    :to="$goto.clientLocation(client.id)">
    <template #avatar>
      <person-avatar :person="client" :size="small ? 36 : 44"/>
    </template>
    <template #actions>
      <v-tooltip top v-if="client.phone">
        <template v-slot:activator="{on}">
          <v-btn icon x-small class="mx-1 contact-button"
                 :class="`m${right ? 'r' : 'l'}-2`"
                 @click="onPhoneClick" v-on="on">
            <v-icon small>phone</v-icon>
          </v-btn>
        </template>
        <span class="caption">+{{ client.phone | numeralFormat }}</span>
      </v-tooltip>
      <v-tooltip top v-if="client.email">
        <template v-slot:activator="{on}">
          <v-btn icon x-small class="mx-1 contact-button" @click="onEmailClick" v-on="on">
            <v-icon small>email</v-icon>
          </v-btn>
        </template>
        <span class="caption">{{ client.email }}</span>
      </v-tooltip>
      <v-menu offset-y v-if="!hideMore && !readonly">
        <template v-slot:activator="{ on }">
          <v-btn icon x-small class="mx-1" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list dense v-if="client.id">
          <v-list-item :disabled="!$user.isAdmin" @click="removeClient()">
            <v-list-item-action class="icon-action">
              <v-icon small :color="!$user.isAdmin ? 'grey' : 'error'">delete</v-icon>
            </v-list-item-action>
            <v-list-item-title v-if="!$user.isAdmin">
              {{ I18NGetter().useClientBadge.DELETE_CLIENT_CONTACT_WITH }}
            </v-list-item-title>
            <v-list-item-title v-else>{{ I18NGetter().useClientBadge.DELETE_CLIENT }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:second-line>
      <span v-if="showId && client?.id" :class="{'mt-1 mr-1':!$scopedSlots['second-line'] && client !== null && client.pesel}">
        <CopyId :id="client.id"/>
        <span v-if="$scopedSlots['second-line'] || client.pesel">&#5867;</span>
      </span>
      <span class="mt-1" v-if="!$scopedSlots['second-line'] && client !== null && client.pesel">{{I18NGetter().useClientBadge.PERSONAL_ID}}:{{ client.pesel}}</span>
      <slot v-else name="second-line"/>
    </template>
  </base-badge>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import ClientApi from '@/modules/client/services/ClientApi';
import GoToService from '@/router/GoToService';
import BaseBadge from '@/components/badges/base/BaseBadge.vue';
import PersonAvatar from '@/components/commons/PersonAvatar.vue';
import {IClientBadgeViewModel} from '@/modules/client/models/ClientBadgeViewModel';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import CopyId from '@/components/badges/CopyId.vue';

@Component({
  components: {CopyId, PersonAvatar, BaseBadge,},
})
export default class ClientBadge extends BaseBadge {
  @Prop({required: true,})
  public readonly client!: IClientBadgeViewModel | null;

  @Prop({default: false, type: Boolean,})
  public readonly hideMore?: boolean;

  @Prop({default: false, type: Boolean,})
  public readonly removeLink?: boolean;

  @Prop({default: false, type: Boolean,})
  public readonly showId?: boolean;

  public I18NGetter: () => I18NInterface = I18NGetter;
  public onPhoneClick() {
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$clipboard(this.client!.phone!);
    } else {
      window.location.href = `tel:+${this.client!.phone}`;
    }
  }

  get badgeLabel(): string {
    return this.client?.displayName || '';
  }

  public async removeClient() {
    if (await this.$modalService.openConfirmModal(I18NGetter().useClientBadge.REMOVE_CLIENT_CONFIRM_MODAL)) {
      try {
        await ClientApi.removeClient(this.client!.id!);
        GoToService.clients();
      } catch (e) {
        this.errorHandler(e, I18NGetter().useClientBadge.REMOVE_CLIENT_ERROR_HANDLER);
      }
    }
  }

  public onEmailClick() {
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$clipboard(this.client!.email!);
    } else {
      window.location.href = `mailto:${this.client!.email}`;
    }
  }
}

</script>

<style scoped lang="scss">
.client-name {
  min-width: fit-content;
}
</style>
