import {Utils} from '@/services/utils/BasicUtils';

export enum RaiffaisenApplicationSteps {
  FILL_REQUIRED_DATA = 1,
  SEND_APPLICATION_TO_BANK= 2,
  WAIT_FOR_RESPONSE = 3,
  CHOOSE_OFFER = 4,
  RAIFF_APP_REGISTER = 5,
}

export enum RaiffeisenErrorCodes {
  ACCESS_DENIED = 'access_denied' ,
  ASSOCIATED_AGENT_IS_BLOCKED = 'associated_agent_is_blocked' ,
  VALIDATE_ERROR = 'validate_error',
  MODEL_VALIDATION_ERROR = 'model_validation_error',
  PROPOSAL_ALREADY_APPROVED = 'proposal_already_approved ',
  LEAD_APPLICABLE_NOT_FOUND = 'lead_applicable_not_found',
  TOO_MANY_REQUESTS = 'too_many_requests' ,
  INVALID_FORMAT_PHONE_NUMBER = 'invalid_format_phone_number',
  UNSUPPORTED_COUNTRY_CODE = 'unsupported_country_code' ,
  COMPANY_TAX_CODE_IS_MANDATORY = 'company_tax_code_is_mandatory',
  COMPANY_NAME_IS_MANDATORY = 'company_name_is_mandatory' ,
  PESEL_DOCUMENT_MISSING = 'pesel_document_missing' ,
  CLIENT_DOCUMENTS_MISSING = 'client_documents_missing',
  EDUCATION_NOT_FOUND = 'education_not_found',
  MARITAL_STATUS_NOT_FOUND = 'marital_status_not_found',
  TYPE_OF_LIVING_NOT_FOUND = 'type_of_living_not_found',
  REQUESTED_LOAN_PURPOSE_NOT_FOUND = 'requested_loan_purpose_not_found',
  EMPLOYMENT_STATUS_NOT_FOUND = 'employment_status_not_found',
  CONTRACT_TYPE_NOT_FOUND = 'contract_type_not_found',
  PROFESSION_NOT_FOUND = 'profession_not_found',
  NATIONALITY_CODE_NOT_FOUND = 'nationality_code_not_found',
  COUNTRY_OF_BIRTH_CODE_NOT_FOUND = 'country_of_birth_code_not_found' ,
  DOCUMENT_TYPE_NOT_FOUND = 'document_type_not_found',
}

export const RaiffeisenErrorMessages: Record <RaiffeisenErrorCodes, string> = {
  [RaiffeisenErrorCodes.ACCESS_DENIED]: 'Brak dostępu',
  [RaiffeisenErrorCodes.ASSOCIATED_AGENT_IS_BLOCKED]: 'Ekspert zablokowany',
  [RaiffeisenErrorCodes.VALIDATE_ERROR]: 'Błąd walidacji danych klienta',
  [RaiffeisenErrorCodes.MODEL_VALIDATION_ERROR]: 'Błąd walidacji modelu',
  [RaiffeisenErrorCodes.PROPOSAL_ALREADY_APPROVED]: 'Oferta już zaakceptowana',
  [RaiffeisenErrorCodes.LEAD_APPLICABLE_NOT_FOUND]: 'Nie znaleziono leada',
  [RaiffeisenErrorCodes.TOO_MANY_REQUESTS]: 'Za dużo zapytań z danego numeru telefonu',
  [RaiffeisenErrorCodes.INVALID_FORMAT_PHONE_NUMBER]: 'Zły format numeru komórkowego',
  [RaiffeisenErrorCodes.UNSUPPORTED_COUNTRY_CODE]: 'Niewspierany numer kierunkowy',
  [RaiffeisenErrorCodes.COMPANY_TAX_CODE_IS_MANDATORY]: 'NIP jest wymagany',
  [RaiffeisenErrorCodes.COMPANY_NAME_IS_MANDATORY]: 'Nazwa firmy(pracodawcy) jest wymagana',
  [RaiffeisenErrorCodes.PESEL_DOCUMENT_MISSING]: 'Brak wymaganego dokumentu z numerem PESEL',
  [RaiffeisenErrorCodes.CLIENT_DOCUMENTS_MISSING]: 'Brak wymaganych dokumentów',
  [RaiffeisenErrorCodes.EDUCATION_NOT_FOUND]: 'Nieprawidłowa wartość w polu edukacja',
  [RaiffeisenErrorCodes.MARITAL_STATUS_NOT_FOUND]: 'Nieprawidłowa wartość w polu stanu cywilnego',
  [RaiffeisenErrorCodes.TYPE_OF_LIVING_NOT_FOUND]: 'Nieprawidłowa wartość w polu rodzaju nieruchomosci',
  [RaiffeisenErrorCodes.REQUESTED_LOAN_PURPOSE_NOT_FOUND]: 'Nieprawidłowa wartość w polu celu kredytu',
  [RaiffeisenErrorCodes.EMPLOYMENT_STATUS_NOT_FOUND]: 'Nieprawidłowa wartość w polu zatrudnienia',
  [RaiffeisenErrorCodes.CONTRACT_TYPE_NOT_FOUND]: 'Nieprawidłowa wartość w polu rodzaju zatrudnienia',
  [RaiffeisenErrorCodes.PROFESSION_NOT_FOUND]: 'Nieprawidłowa wartość w polu wykonywanego zawodu',
  [RaiffeisenErrorCodes.NATIONALITY_CODE_NOT_FOUND]: 'Nieprawidłowa wartość w polu obywatelstwa',
  [RaiffeisenErrorCodes.COUNTRY_OF_BIRTH_CODE_NOT_FOUND]: 'Nieprawidłowa wartość w polu kraju urodzenia',
  [RaiffeisenErrorCodes.DOCUMENT_TYPE_NOT_FOUND]: 'Nieprawidłowa wartość w polu dokumentu',
};

export enum SuffixType {
  percentage = '%',
  monthShort = 'mc-e',
  currency = 'zł'
}

export interface IRaiffeisen {
  loanPurpose: Nullable<string>,
  isStudent: Nullable<boolean>,
  isPep: Nullable<boolean>,
  isSoleEarner: Nullable<boolean>,
  householdDependantsQuantity: Nullable<number>,
}

export class Raiffeisen implements IRaiffeisen {
  loanPurpose = null;
  isStudent = null;
  isPep = null;
  isSoleEarner = null;
  householdDependantsQuantity = null;
}

export enum RaiffeisenProposalStatus {
  NO_PROPOSALS = 'NO_PROPOSALS',
  PROPOSALS_SUCCEESS = 'PROPOSALS_SUCCESS',
}

export enum RaiffeisenAcceptStatus {
  PROPOSALS_APPROVED = 'PROPOSALS_APPROVED',
}

export enum RaiffeisenProposalType {
  BASE = 'base',
  UPSELL_B = 'upsell_b',
  DOWNSELL_A = 'downsell_a',
  DOWNSELL_B = 'downsell_b',
  DOWNSELL_C = 'downsell_c',
}

export interface IRaiffeisenProposal {
  type: RaiffeisenProposalType,
  loan_amount: number,
  number_of_installments: number,
  nominal_interest_rate: number,
  total_loan_costs: number,
  monthly_installment: number,
  apr: number,
  fee_amount: number,
  fee_rate: number,
  total_loan_repayable: number,
}

export abstract class RaiffeisenProposalDto {
  public type!: RaiffeisenProposalType;
  public loan_amount!: number;
  public number_of_installments!: number;
  public nominal_interest_rate!: number;
  public total_loan_costs!: number;
  public monthly_installment!: number;
  public apr!: number;
  public fee_amount!: number;
  public fee_rate!: number;
  public total_loan_repayable!: number;
}

export class RaiffeisenProposal extends RaiffeisenProposalDto {
  constructor(dto?: RaiffeisenProposalDto) {
    super();
    if (dto) {
      dto.fee_amount = dto.fee_amount / 100;
      dto.monthly_installment = dto.monthly_installment / 100;
      dto.total_loan_costs = dto.total_loan_costs / 100;
      dto.total_loan_repayable = dto.total_loan_repayable / 100;
      dto.loan_amount = dto.loan_amount / 100;
    }
    Utils.assign(this, dto);
  }
}

export interface IRaiffeisenResponse {
  id: string;
  status: RaiffeisenProposalStatus;
  proposals: Array<IRaiffeisenProposal>;
}
export interface IRaiffeisenAcceptProposal {
  leadId: string,
  proposalType: RaiffeisenProposalType,
}

export interface IRaiffeisenAcceptProposalResponse {
  leadId: string,
  status: RaiffeisenAcceptStatus
  proposals: Array<IRaiffeisenProposal>,
}

export abstract class RaiffeisenAcceptProposalReponseDto {
  public data!: {
    leadId: string;
    status: RaiffeisenAcceptStatus;
    proposals: Array<IRaiffeisenProposal>;
  };
}

export class RaiffeisenAcceptProposalResponse extends RaiffeisenAcceptProposalReponseDto {
  constructor(dto?: RaiffeisenAcceptProposalReponseDto) {
    super();
    if (dto) {
      dto.data.proposals = dto.data.proposals.map(proposal => new RaiffeisenProposal(proposal));
      Utils.assign(this, dto);
    }
  }
}

export abstract class RaiffeisenResponseDto {
  public data!: {
    id: string;
    lead_status: RaiffeisenProposalStatus;
    proposals: Array<RaiffeisenProposalDto>;
  };
}
export abstract class RaiffeisenApplicationDto {
  public applicationId!: number;
  public approvedAt!: string;
  public clientId!: number;
  public response!: RaiffeisenAcceptProposalReponseDto;
  public userId!: number;
}

export class RaiffeisenApplication extends RaiffeisenApplicationDto {
  constructor(dto?: RaiffeisenApplicationDto) {
    super();
    if (dto) {
      dto.response = new RaiffeisenAcceptProposalResponse(dto.response);
      Utils.assign(this, dto);
    }
  }

  public get offer() {
    return this.response.data.proposals;
  }
}

export class RaiffeisenResponse extends RaiffeisenResponseDto {
  constructor(dto?: RaiffeisenResponseDto) {
    super();
    if (dto && dto.data) {
      dto.data.proposals = dto.data?.proposals.map(proposal => new RaiffeisenProposal(proposal)) || [];
      Utils.assign(this, dto);
    }
  };
}

export abstract class RaiffeisenErrorResponseDto {
  message!: string;
  type!: RaiffeisenErrorCodes;
}

export class RaiffeisenErrorResponse extends RaiffeisenErrorResponseDto {
  constructor(dto?: RaiffeisenErrorResponseDto) {
    super();
    if (dto) {
      dto.message = RaiffeisenErrorMessages[dto.type];
      Utils.assign(this, dto);
    }
  }
}
