import {FpAxios} from 'fp-components';
import {Household} from '@/modules/multiForm/shared/MultiformModels';
import {wait} from '@/services/utils/BasicUtils';
import {CompanyData, CompanyDataDto} from '@/models/CompanyData';

class MultiformApi {
  private multiformApiAxios = FpAxios.create();

  async getCompanyDataByNip(nip: string): Promise<CompanyDataDto> {
    const response = await this.multiformApiAxios.get(`company-info/${nip}`);
    return response.data;
  }

  async createHousehold(dealId: number): Promise<Household> {
    const response = await this.multiformApiAxios.post('households', {dealId, });
    return response.data;
  }

  async updateHousehold(households: Array<Household>): Promise<Array<Household>> {
    const response = await this.multiformApiAxios.put('households', households);
    return response.data;
  }

  async addClientToHousehold(householdId: number, clientId: number): Promise<Array<Household>> {
    const response = await this.multiformApiAxios.put(`households/${householdId}/client/${clientId}/assign`);
    return response.data;
  }

  async removeClientFromHousehold(householdId: number, clientId: number): Promise<Array<Household>> {
    const response = await this.multiformApiAxios.put(`households/${householdId}/client/${clientId}/unassign`);
    return response.data;
  }

  async removeHousehold(id: number): Promise<boolean> {
    const response = await this.multiformApiAxios.delete(`households/${id}`);
    return response.data;
  }

  async getMultiformDebugJson(dealId: number, loanAppId: number): Promise<object> {
    const response = await this.multiformApiAxios.get<object>(`deals/${dealId}/applications/${loanAppId}/documents/debug-multiform-json`);
    return response.data;
  }
}

export default new MultiformApi();
