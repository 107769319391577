// eslint-disable-next-line vue/max-len
import {ProductType} from '@/commons/enums/ProductType';
import {RouteConfig} from 'vue-router';
import EnvironmentProducts from '@/env/services/EnvironmentProducts';

// eslint-disable-next-line vue/max-len
export function createRoutesPerProductTypes(productTypes: ProductType[], createRoute: (productType: ProductType) => RouteConfig): RouteConfig[] {
  return productTypes
    .filter(x => EnvironmentProducts().find(y => y.type === x))
    .map(productType => createRoute(productType));
}
