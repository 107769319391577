<template>
  <v-card>
    <v-card-title>
      {{ I18NGetter().useProspectPickLossReason.CLOSE_REASON }}
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <v-form ref="lossReasonForm">
        <v-container class="grid-list-lg">
          <loss-reason-form v-model="lossReason"/>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-row class="align-center flex-nowrap">
        <v-col class="shrink">
          <loader-button primary :click="() => contactDeniedMeeting()">
            {{ I18NGetter().useProspectPickLossReason.CONFIRM_CLOSING }}
          </loader-button>
        </v-col>
        <v-col>
          <v-icon color="error">mdi-alert</v-icon>
          <span class="error--text">{{ I18NGetter().useProspectPickLossReason.CANNOT_REVERT }}</span>
        </v-col>
        <v-col class="shrink">
          <v-btn outlined @click="closeModal()">
            {{ I18NGetter().useProspectPickLossReason.CANCEL }}
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {VueModalComponent} from 'fp-components';
import LoaderButton from '@/components/commons/LoaderButton.vue';
import {ProspectLossReasonDictionaryType} from '@/types/Dictionaries';
import ProspectApi from '@/modules/prospects/services/ProspectApi';
import {LossReason} from '@/types/ProspectLossReason';
import LossReasonForm from '@/components/LossReasonForm.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';

@Component({
  components: {LossReasonForm, LoaderButton, },
})
export default class PickLossReason extends VueModalComponent<LossReason> {
  public lossReason: LossReason = new LossReason();
  public I18NGetter: () => I18NInterface = I18NGetter;
  public contactDeniedMeeting() {
    if (this.$validate('lossReasonForm')) {
      this.closeModal(this.lossReason);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
