<template>
  <v-avatar v-if="logo" :size="size" :height="size">
    <VImg v-if="lazy" :src="logo" alt="bank logo"/>
    <img v-else :src="logo" alt="bank logo"/>
  </v-avatar>
</template>
<script lang="ts" setup>
import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {computed} from 'vue';

const props = withDefaults(defineProps<{
  bankSymbol?: BankSymbol;
  size?: number;
  lazy?: boolean;
}>(), {
  size: 44,
  lazy: true,
});

const defaultBankLogo: string = require('@/assets/productTypesImg/avatarHipoteka.svg');

const logo = computed<string>(() => {
  if (props.bankSymbol) {
    const bank = banksDictionary().find(x => x.type === props.bankSymbol);
    if (bank) {
      return bank.symbolRequire;
    }
  }
  return defaultBankLogo;
});
</script>
