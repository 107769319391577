import User from '@/models/user/User';
import AuthService from '@/modules/user/AuthService';
import {merge} from 'lodash-es';
import {clone} from '@/services/utils/BasicUtils';
import {JwtDTO} from '@/models/user/JwtDTO';
import {Role} from '@/models/user/Role';

export default class JwtData {
  user: Nullable<User> = null;
  currentRole: Nullable<Role> = null;
  timestamp: string = new Date().toISOString();
  exp!: number;

  constructor(jwtDTO?: JwtDTO) {
    if (jwtDTO && jwtDTO.data) {
      this.timestamp = jwtDTO.data.timestamp;
      if (jwtDTO.data.currentRole) {
        this.currentRole = jwtDTO.data.currentRole;
      }
      if (jwtDTO.data.user) {
        if (AuthService.User) {
          const updatedUser = clone(AuthService.User);
          merge(updatedUser, new User(jwtDTO.data.user, jwtDTO.data.privileges));
          this.user = updatedUser;
        } else {
          this.user = new User(jwtDTO.data.user, jwtDTO.data.privileges);
        }
      }
      this.exp = jwtDTO.exp;
    }
  }
}
