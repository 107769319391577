import numeral from 'numeral';
import {SimulationDemand} from '@/models/Demand';
import {getByType} from '@/modules/multiForm/shared/MultiformDictionaries';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {UserDTO} from '@/models/user/UserDTO';
import EnvironmentService from '@/env/EnvironmentService';

numeral.localeData().delimiters.thousands = ' ';

// To napisał chatGPT, mi średnio podoba się ten for, ale działa.
function formatMask(value: string, mask: string): string {
  let formattedValue = '';
  let valueIndex = 0;

  for (let i = 0; i < mask.length; i++) {
    const maskChar = mask[i];

    if (maskChar === '#') {
      if (valueIndex < value.length) {
        formattedValue += value[valueIndex++];
      }
    } else {
      if (valueIndex < value.length && value[valueIndex] === maskChar) {
        valueIndex++;
      }

      if (valueIndex < value.length || maskChar === ' ') {
        formattedValue += maskChar;
      }
    }
  }

  return formattedValue;
}

export function subString(value: Nullable<string> = '', numberOfChars: number) {
  return value?.substr(0, numberOfChars) || '';
}

export function trimString(value: Nullable<string> = '', numberOfChars: number) {
  return (value?.length || -1) > numberOfChars ? `${value!.substr(0, numberOfChars)}...` : value;
}

export function numeralFormat(value: number | string = 0) {
  return numeral(value).format();
}

export function phoneFormat(value: string): string {
  if (value) {
    const phone = parsePhoneNumberFromString(value);
    return phone?.formatInternational()
      .replace(/^0/, '')
      .replace(/[()]/g, '')
      .replace('-', '') || value;
  } else return '';
}

export function decimalFormat(value: string | number = '') {
  return numeral(value).format('0,0.00');
}

export function zipCodeFormat(value: string = '') {
  const zipCodeMask: string = EnvironmentService.Environment.getAppDomainConfig().zipCodeMask;
  return formatMask(value, zipCodeMask);
}

export function peselFormat(value: string) {
  return value ? `${value.substr(0, 2)} ${value.substr(2, 2)} ${value.substr(4, 2)} ${value.substr(6, 5)}` : 'Brak';
}

export function bankAccountFormat(value: string) {
  return value ? `${value.substr(0, 2)} ${value.substr(2, 4)} ${value.substr(6, 4)} ${value.substr(10, 4)} ${value.substr(14, 4)} ${value.substr(18, 4)} ${value.substr(22, 4)}` : 'Brak';
}

export function capitalize(s: any) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const addressFormat = (address: Address): string => {
  return `${address.city}, ${address.street ? address.street : ''} ${address.streetNum}${address.streetLocal ? '/' + address.streetLocal : ''}, ${zipCodeFormat(address.zip || '')}`;
};

export function mortgageSimulationPurposeTitle(v: SimulationDemand) {
  const what = capitalize(I18NGetter().SimulationLoanPurposeWhat[v.loanPurpose?.what!] || '');
  const market = v?.loanPurpose?.market;
  if (what) {
    if (market === 'PrimaryMarket') {
      return `${what} na rynku pierwotnym`;
    } else {
      return `${what} na rynku wtórnym`;
    }
  } else {
    return '';
  }
}

export function displayName(name: {firstName: string, lastName: string}) {
  return `${name.firstName ?? ''}${name.firstName ? ' ' : ''}${name.lastName ?? ''}`;
}
