import {Gender} from '@/models/enums/Gender';

export const BikReportGradientColors = [
  '#FA7268',
  '#FB8459',
  '#FC954A',
  '#FDA63B',
  '#FEB82C',
  '#FFCA1C',
  '#D9CC1E',
  '#ABCC22',
  '#7ECC27',
  '#51CD2B',
];

export const bikLiabilityType = [
  {name_pl: 'Kredyt na zakup towarów, usług i papierów wartościowych', value: '01', },
  {name_pl: 'Kredyt niecelowy oraz studencki', value: '02', },
  {name_pl: 'Kredyt mieszkaniowy', value: '03', },
  {name_pl: 'Kredyt pracowniczy ', value: '04', },
  {name_pl: 'Kredyt odnawialny', value: '21', },
  {name_pl: 'Karta detaliczna', value: '22', },
  {name_pl: 'Karta kredytowa ', value: '23', },
  {name_pl: 'Karta charge', value: '24', },
  {name_pl: 'Karta debetowa', value: '25', },
  {name_pl: 'Limit debetowy w ROR', value: '26', },
  {name_pl: 'Hipoteczna linia kredytowa', value: '27', },
  {name_pl: 'Leasing ', value: '41', },
];

export enum BikRejectionReasons {
  NO_SIGN = 'NO_SIGN',
  UNREADABLE_SCAN = 'UNREADABLE_SCAN',
  WRONG_CLIENT = 'WRONG_CLIENT',
  INCORRECT_DATA = 'INCORRECT_DATA',
  OTHER = 'OTHER',
};

export const BikRejectionReasonsDictionary = [
  { name_pl: 'Brak podpisu', type: BikRejectionReasons.NO_SIGN, },
  { name_pl: 'Nieczytelny skan', type: BikRejectionReasons.UNREADABLE_SCAN, },
  { name_pl: 'Zgoda dotyczy innego klienta', type: BikRejectionReasons.WRONG_CLIENT, },
  { name_pl: 'Błędne dane na zgodzie', type: BikRejectionReasons.INCORRECT_DATA, },
  { name_pl: 'Inny', type: BikRejectionReasons.OTHER, },
];

export const MAX_BIK_SCORE = 720;
export const MIN_BIK_SCORE = 192;

export class GeneralLiabilities {
  numOfPositive: number = 0;
  sumOfPositive: number = 0;
  sumOfDelayedPositive: number = 0;
  sumOfArrears: number = 0;
  numOfArrears: number = 0;
  sumOfLiabilities: number = 0;
  numOfLiabilities: number = 0;
}

export class BikReportLiabilities {
  general: GeneralLiabilities = new GeneralLiabilities();
  sumOfInstallmentCredits: number = 0;
  sumOfUnpaidInstallmentCredits: number = 0;
  installmentCredit: number = 0;
  sumOfLimits: number = 0;
  sumOfUnpaidLimits: number = 0;
  numOfLimitsAccounts: number = 0;
  sumOfOtherLimits: number = 0;
  sumOfUnpaidOtherLimits: number = 0;
  numOfOtherLimitsAccounts: number = 0;
}

export class BikReport {
  summary!: BikReportSummary;
  obligationsForBanks: Array<BikReportObligation> = [];
  obligationsForNonBanks: Array<BikReportObligation> = [];
  paymentVectors: Array<BikPaymentVector> = [];

  get isNoScore(): boolean {
    return this.summary.score === 0;
  }

  get stepColor(): string {
    return this.isNoScore
      ? 'grey'
      : BikReportGradientColors[Math.round(this.scorePercentage / BikReportGradientColors.length)];
  }

  get score(): number {
    return this.summary.score < MIN_BIK_SCORE
      ? MIN_BIK_SCORE
      : this.summary.score > MAX_BIK_SCORE
        ? MAX_BIK_SCORE
        : this.summary.score;
  }

  get scorePercentage(): number {
    return (this.score - MIN_BIK_SCORE) * 100 / (MAX_BIK_SCORE - MIN_BIK_SCORE);
  }

  constructor(dto?: BikReport) {
    if (dto) {
      dto.summary = new BikReportSummary(dto.summary);
      dto.paymentVectors = dto.paymentVectors.map(x => new BikPaymentVector(x));
      Object.assign(this, dto);
    }
  }
}

export class BikReportSummary {
  constructor(dto?: BikReportSummary) {
    Object.assign(this, dto);
  }

  id?: number;
  inquiryId?: number;
  clientId?: number;
  statusMsg?: string;
  reportName: string = '';
  client!: {fullName: string, pesel: string};
  score: number = 0;
  probabilityOfNotPaying: number = 0;
  odds: number = 0;
  queriesInLast30Days?: number;
  queriesInLast90Days?: number;
  queriesInLast365Days?: number;
  liabilities: BikReportLiabilities = new BikReportLiabilities();
  createdDate: string = (new Date()).toISOString();

  public get gender(): Gender | null {
    return this.client.pesel ? (Number(this.client.pesel ? this.client.pesel[9] : '0') % 2 === 0 ? Gender.FEMALE : Gender.MALE) : null;
  }

  public get initials(): string {
    return this.client.fullName
      .split(' ')
      .map(el => el[0])
      .join('')
      .toUpperCase();
  }

  public get avatarUrl(): string {
    return this.gender && this.gender === Gender.MALE
      ? require('@/assets/img/crew/m.svg')
      : require('@/assets/img/crew/k.svg');
  }
}

export class BikReportObligation {
  id: number = 0;
  reportId: number = 0;
  obligationType: number = 0;
  infoMonitorId: string = '';
  creditor: string = '';
  creditType?: string = '';
  creditDate?: Date;
  creditAmount?: number = 0;
  amountToPay: number = 0;
  status?: string = '';
}

export class BikPaymentVector {
  id?: number;
  reportId: number = 0;
  typeCode: string = '';
  statusCode: string = '';

  get typeCodeName(): string {
    const liabilityType = bikLiabilityType.find(code => code.value === this.typeCode);
    return liabilityType ? liabilityType.name_pl : '';
  }

  constructor(dto?: BikPaymentVector) {
    Object.assign(this, dto);
  }
}
