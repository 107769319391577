<template>
  <v-card>
    <v-card-title class="pb-2 text-center">
      <v-row>
        <v-col cols="12" class="py-0">
          <img :src="logo" alt="Lenda">
        </v-col>
        <v-col cols="12" class="py-0">
          <div v-html-safe="modalData.title"></div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="px-6">
          <div v-html-safe="modalData.description"></div>
    </v-card-text>
    <v-divider></v-divider>
    <v-flex tag="v-card-actions" px-6 py-4>
        <fp-btn outlined depressed @click="closeModal(false)">{{ modalData.falseText }}</fp-btn>
        <v-spacer></v-spacer>
        <fp-btn primary outlined depressed @click="closeModal(true)">{{ modalData.trueText }}</fp-btn>
    </v-flex>
  </v-card></template>
<script lang="ts">
import {VueModalComponent} from 'fp-components';
import {ModalPayload} from '@/types/typings';
import {Component} from 'vue-property-decorator';
import {TourModalType} from '@/modules/onboarding/tour/models/TourOptions';

@Component
export default class OnboardingModal extends VueModalComponent<ModalPayload<boolean>> {
  get logo() {
    const logoName: string = this.modalData.modalType === TourModalType.FINISH
      ? 'finish' : 'default';
    return require(`../../assets/onboarding/onboarding_${logoName}_logo.svg`);
  }
}
</script>
<style lang="scss" scoped>
</style>
