<template>
  <v-data-table
    id="fpDataTable"
    :value="value"
    @input="$emit('input', $event);"
    :headers="headers"
    :items="items"
    :page.sync="page"
    :sort-desc.sync="sortDesc"
    :sort-by.sync="sortBy"
    :items-per-page.sync="itemsPerPage"
    :server-items-length="totalItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :height="height"
    class="elevation-0"
    :hide-default-footer="Boolean($slots['top']) || hideFooter"
    fixed-header
    :show-select="showSelect"
    :show-expand="showExpand"
    must-sort
    disable-filtering>
    <template v-slot:header.data-table-select="{props, on}" v-if="$scopedSlots['header.data-table-select']">
      <slot name="header.data-table-select" :props="props" :on="on"/>
    </template>
    <template v-slot:top v-if="$scopedSlots['top']">
      <slot name="top"/>
    </template>
    <template v-slot:footer v-if="$scopedSlots['footer']">
      <slot name="footer"/>
    </template>
    <template v-slot:body.prepend v-if="$scopedSlots['body.prepend']">
      <slot name="body.prepend"/>
    </template>
    <template v-if="$scopedSlots['item']" v-slot:item="{item, isSelected, select, isExpanded, expand,}">
      <slot name="item" :item="item" :isSelected="isSelected" :select="select" :isExpanded="isExpanded" :expand="expand"/>
    </template>
    <template v-if="$scopedSlots['expanded-item']" v-slot:expanded-item="{item,}">
      <slot name="expanded-item" :item="item" :headers="headers"/>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import {DataTableHeader} from '@/services/searchEngine/interfaces/DataTableHeader';
import {BaseFilter} from '@/models/Filter';
import { debounce } from 'lodash-es';

@Component
export default class FpDataTable<T, TFilter extends BaseFilter> extends Vue {
  @Prop({default: () => [], }) value!: T[];
  @Prop({required: true, }) headers!: DataTableHeader<T, TFilter>;
  @Prop({required: true, }) items!: T[];
  @Prop({required: false, default: () => new BaseFilter(),}) options!: TFilter;
  @Prop({default: false, }) showSelect!: boolean;
  @Prop({required: false, }) hideFooter?: boolean;
  @Prop({default: false,}) showExpand?: boolean;
  @Prop({default: false, }) readonly loading!: boolean;
  @Prop({default: false, }) readonly multiple!: boolean;
  @Prop({default: 'calc(100vh - 262px)', }) height?: string;
  @Prop({required: false,})
  public readonly localStorageKey?: string;

  public debounced: (fn: () => void) => void = debounce((fn: () => void) => fn(), 400);

  public get totalItems(): number | undefined {
    return this.options.quantity;
  }

  get isOfflineVersion(): boolean {
    return !this.options.quantity;
  }

  public get page(): number {
    return this.options.page;
  }

  public set page(val: number) {
    this.fetchFilterRecord('page', val);
    this.options.page = val;
  }

  public get itemsPerPage(): number {
    return this.options.rowsPerPage;
  }

  public set itemsPerPage(val: number) {
    this.fetchFilterRecord('rowsPerPage', val);
    this.options.rowsPerPage = val;
  }

  public get sortBy(): string | string[] {
    return this.options.sortBy;
  }

  public set sortBy(val: string | string[]) {
    this.options.sortBy = val;
    this.fetchFilterRecord('sortBy', val);
  }

  public get sortDesc(): boolean {
    return this.options.descending;
  }

  public set sortDesc(val: boolean) {
    this.fetchFilterRecord('descending', val);
  }

  private fetchFilterRecord(k: keyof TFilter, value: boolean | string | string[] | number): void {
    this.debounced(() => {
      const partial = {[k]: value, } as unknown as Partial<TFilter>;
      Object.assign(this.options, partial);
      this.$sync('options', this.options);
    });
  }

  private saveOptions(): void {
    if (this.localStorageKey) {
      const formattedData = JSON.stringify(this.options);
      localStorage.setItem(this.localStorageKey, formattedData);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
