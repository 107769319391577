<template>
  <v-layout wrap>
      <fp-loader v-if="value === null"></fp-loader>
      <v-flex v-else>
        <address-form :value="value" :long-form="false" :required="required" :readonly="readonly"></address-form>
      </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import AddressForm from '@/components/address/AddressForm.vue';
import LandOfWorkForm from '@/modules/user/editUser/LandOfWorkForm.vue';
@Component({
  components: {LandOfWorkForm, AddressForm: AddressForm, },
})
export default class EditUserAddressData extends Vue {
  @Prop()
  public readonly value!: Nullable<Address>;

  @Prop()
  public readonly readonly!: boolean;

  @Prop({default: false,})
  public readonly required!: boolean;
}
</script>

<style scoped lang="scss">
</style>
