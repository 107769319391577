import DOMPurify, {Config, SanitizeElementHookEvent} from 'dompurify';
const config: Config = { ADD_ATTR: ['target','allow', 'allowfullscreen', 'frameborder',], ADD_TAGS: ['iframe',], };
const allowedIframeUrls: string[] = [
  'https://www.youtube.com/',
  'https://player.vimeo.com/',
];
// Custom DOMPurify hook to sanitize iframe elements.
// It ensures that the src attribute of each iframe matches allowed URLs, removing any that don't.
DOMPurify.addHook('uponSanitizeElement', (node: Element, data: SanitizeElementHookEvent) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';
    if (!allowedIframeUrls.some((url) => src.startsWith(url))) {
      return node.parentNode?.removeChild(node);
    }
  }
});

const safeHtml = {
  // Vue 2.x
  inserted(el: HTMLElement, binding: any, vnode: any, prevNode: any) {
    el.innerHTML = DOMPurify.sanitize(binding.value, config) as string;
  },
  // Vue 2.x
  update(el: HTMLElement, binding: any, vnode: any, prevNode: any) {
    if (binding.value !== binding.oldValue) el.innerHTML = DOMPurify.sanitize(binding.value, config) as string;
  },
  // Vue 3.x
  mounted(el: HTMLElement, binding: any) {
    el.innerHTML = DOMPurify.sanitize(binding.value, config) as string;
  },
  // Vue 3.x
  updated(el: HTMLElement, binding: any) {
    if (binding.value !== binding.oldValue) el.innerHTML = DOMPurify.sanitize(binding.value, config) as string;
  },
};

export default {
  install(Vue: any) {
    Vue.directive('html-safe', safeHtml);
  },
};
