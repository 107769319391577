import {Offer} from '@/models/simulation/Simulation';
import {numeralFormat} from '@/filters/StringFilters';

const renderInfoElement = (info: string) => `<div class="pa-2 text-left">${info}</div>`;
const programInfoTemplate = {
  homeStartProgram: `
    Program kredyt na start:
    <ul>
     <li> Kwota kredytu objęta dopłatami - {{offer.homeStartProgram.subsidizedLoanAmount}} zł </li>
     <li> Oprocentowanie preferencyjne - {{offer.homeStartProgram.preferenceInterestRate}} % </li>
    </ul>
    <br>
     Informacje o gospodarstwie:
     <ul>
     <li> {{offer.homeStartProgram.numberOfAdult}} dorosłych i {{offer.homeStartProgram.numberOfChildren}} dzieci </li>
     <li> Miesięczny dochód netto z 2023r:  {{store.userInput?.homeStartProgramInput.incomeSum}} zł </li>
     </ul>
     <br>
      Informacje o nieruchomości:
      <ul>
      <li> Lokalizacja - {{store.userInput?.homeStartProgramInput.city ?? 'Inna miejscowość'}} </li>
      <li> Metraż - {{store.userInput?.homeStartProgramInput.propertyArea}} m2 </li>
      </ul>
      <br>
      Obniżka dopłat:
      <ul>
      <li> Ze względu na dochód - {{offer.homeStartProgram.incomeBasedSubsidyReduction}} zł </li>
      <li> Ze względu na metraż - {{offer.homeStartProgram.areaBasedSubsidyReduction}} zł </li>
      <li> Sumaryczna obniżka - {{offer.homeStartProgram.subsidyReductionSum}} zł </li>
      </ul>
    `,
  rkmProgram: `Program rządowy oparty jest o spłatę rodzinną. Spłata rodzinna jest dokonywana w wysokości:
    <ol>
<li>20 000 zł – w przypadku powiększenia gospodarstwa domowego kredytobiorcy o drugie dziecko,</li>
<li>60 000 zł – w przypadku powiększenia gospodarstwa domowego kredytobiorcy o trzecie albo kolejne dziecko – jednak nie wyższej niż wysokość pozostałej do spłaty części kapitałowej rodzinnego kredytu mieszkaniowego.</li><br><br>
</ol>
W dniu spłaty nie będzie można być właścicielem innego mieszkania niż zakupione z wykorzystaniem rodzinnego kredytu mieszkaniowego.
`,
}
export const getGovProgramInfo = (offer: Offer) => {
  if (offer.features.isHomeStartProgram) {
    return renderInfoElement(programInfoTemplate.homeStartProgram);
  } else if (offer.features.isRkmProgram) {
    return renderInfoElement(programInfoTemplate.rkmProgram);
  } else {
    return 'Oferta nie pochodzi z programu rządowego';
  }
}

export const getTotalSubsidyRow = (offer: Offer) => {
  if (offer.features.isHomeStartProgram) {
    return `${numeralFormat(offer.homeStartProgram?.totalInterestSubsidy)} zł`
  } else if (offer.features.isRkmProgram) {
    return 'Zależna od wysokości spłaty rodzinnej'
  } else {
    return 'brak dopłat'
  }
}
