import {GooglePlacesTypes} from '@/modules/googleAutocomplete/enums/GooglePlacesTypes';
import {
  IMultiformDictionaryElement,
  IMultiformDistrictDictionaryElement
} from '@/modules/multiForm/shared/IMultiformDictionary';
import {AutocompletePlacesTypes} from '@/modules/googleAutocomplete/enums/AutocompletePlacesTypes';
import useGeoAreas from '@/composables/useGeoAreas';
import {textNormalizer} from '@/services/utils/StringUtils';

class GooglePlacesMapper {
  private findComponent(googlePlace: google.maps.GeocoderResult, keyName: GooglePlacesTypes) {
    return googlePlace.address_components.find((component) => component.types.includes(keyName));
  }

  private findLand(googlePlace: google.maps.GeocoderResult): IMultiformDictionaryElement<number> | null {
    const landComponent = this.findComponent(googlePlace, GooglePlacesTypes.ADMINISTRATIVE_AREA_LEVEL_1);
    if (landComponent) {
      const landName = textNormalizer(landComponent.long_name.toLowerCase().replace('województwo ', ''));
      const foundLand = useGeoAreas().landDictionary().find(l =>
        textNormalizer(l.name_pl.toLowerCase()).indexOf(landName.toLowerCase()) > -1 &&
        textNormalizer(l.name_pl.toLowerCase()).indexOf(landName.toLowerCase()) < 3 // 'pomorskie' vs 'zachodniopomorskie' || 'opolskie vs malopolskie'
      );
      return foundLand || null;
    } else return null;
  };

  private findDistrict(googlePlace: google.maps.GeocoderResult, land: IMultiformDictionaryElement<number>):
    IMultiformDictionaryElement<number> | null {
    const allDistricts: IMultiformDistrictDictionaryElement[] = useGeoAreas().districtDictionary();
    // districts assigned to the searched voivodeship to reduce the number of options and speed up the search
    const landDistricts: IMultiformDistrictDictionaryElement[] = allDistricts.filter(district => !land.type || district.landId === land.type)
    const districtComponent = this.findComponent(googlePlace, GooglePlacesTypes.ADMINISTRATIVE_AREA_LEVEL_2);
    // if there is no districtComponent, it means it is not required
    if (!districtComponent) {
      return null;
    }

    // in romanian 'municipiul' is 'miasto' in polish, we need to remove it from district name to normalize the string
    const districtName = textNormalizer(districtComponent.long_name).replace('municipiul ', '');
    const findPredicate = (row: IMultiformDistrictDictionaryElement<number>): boolean => {
      const normalizedRowName = textNormalizer(row.name_pl);
      // districtName is returned from the API as "powiat {name}", and in the dictionaries we have only the district names without the prefix 'powiat'
      // endsWith also helps avoid mistakes with two-part names when we search in citiesDistricts e.g. Jelenia Góra
      return districtName.endsWith(normalizedRowName) || (normalizedRowName.includes(districtName));
    };
    const citiesDistricts = landDistricts
      .filter(district => district.name_pl.toLowerCase().startsWith('m. ') || /^\p{Lu}/u.test(district.name_pl))
      // from BE, cities started to come in different formats, i.e. 'm. Gdańsk' and 'Gdańsk', so check whether it is capitalized
      .map(city => ({
        ...city,
        name_pl: city.name_pl.startsWith('m. ') ? city.name_pl.slice(3) : city.name_pl,
      }));
    return citiesDistricts.find(findPredicate) || landDistricts.find(findPredicate) || null;
  };

  public findPlace(googlePlace: google.maps.GeocoderResult): {landId: number, districtId: number | null, } | null {
    const land = this.findLand(googlePlace);
    if (land) {
      const district = this.findDistrict(googlePlace, land);
      if (district) {
        return {landId: land.type, districtId: district.type, };
      } else {
        console.warn('cannot find district for place', googlePlace);
        return {
          landId: land.type,
          districtId: null,
        };
      }
    } else {
      console.warn('cannot find land for place', googlePlace);
      return null;
    }
  };

  public typesForFilter(type: AutocompletePlacesTypes): string[] | null {
    switch (type) {
    case AutocompletePlacesTypes.CITIES:
      return null;
    case AutocompletePlacesTypes.CITIES_AND_ROUTES:
      return [GooglePlacesTypes.LOCALITY, GooglePlacesTypes.ROUTE,];
    default: return null;
    }
  }

  public findComponentLongName(googlePlace: google.maps.GeocoderResult, type: GooglePlacesTypes) {
    const geocoderAddressComp = this.findComponent(googlePlace, type);
    if (geocoderAddressComp) return geocoderAddressComp.long_name;
    else return '';
  }

  public findComponentShortName(googlePlace: google.maps.GeocoderResult, type: GooglePlacesTypes) {
    const geocoderAddressComp = this.findComponent(googlePlace, type);
    if (geocoderAddressComp) return geocoderAddressComp.short_name;
    else return '';
  }
}

export default new GooglePlacesMapper();
