<template>
  <v-menu offset-y :disabled="!canEditStatus">
    <template v-slot:activator="{ on }">
      <v-chip small class="my-0"
              @click.stop.prevent
              v-on="on"
              :color="$vuetify.theme.currentTheme[deal.stageColor] + '33'"
              :class="[`text--${deal.stageColor}`, 'text--darken-4']"
              :text-color="deal.stageColor">
        <template v-if="showDealStage">
          {{$i18n.DealStage[deal.stage]}}
        </template>
        <template v-else>
          {{$i18n.DealStatus[deal.status]}}
        </template>
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-icon right small :color="deal.stageColor" v-on="on"
                    :class="[`text--${deal.stageColor}`, 'text--darken-4']"
                    class="ml-1 d-flex">mdi-information-outline</v-icon>
          </template>
          <span class="caption">{{$i18n.useDealChip.LAST_MODIFIED}} {{deal.updatedAt | dateTimeFormat}}</span>
        </v-tooltip>
        <v-flex py-0 pr-0 v-if="isDealEditable">
          <v-icon v-if="canEditStatus">
            mdi-menu-down
          </v-icon>
        </v-flex>
      </v-chip>
    </template>
    <v-list v-if="isDealEditable">
      <v-list-item v-for="(status, index) in getStatuses"
                   :disabled="canEditStatus(status.type)"
                   :key="index" @click="updateStatus(status.type)">
        {{ status.name }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import DealsApi from '@/modules/deals/services/DealsApi';
import {DealStatus} from '@/modules/deals/enums/DealStatus';
import Deal from '@/models/Deal';
import {getDictionaryByEnumObject} from '@/services/enumTranslator/GetDictionaryByEnumObject';
import EnvironmentService from '@/env/EnvironmentService';
import {computed} from 'vue';
import {editableStatuses} from '@/modules/deals/enums/editableStatuses';

const props = defineProps<{
  deal: Deal;
  disabled?: boolean;
  showDealStage?: boolean;
}>();

const stages = getDictionaryByEnumObject<DealStatus>(JSON.parse(JSON.stringify(DealStatus)), 'DealStatus')
  ?.filter(item => {
    return !EnvironmentService.Environment.isAppDomain().NESTO_RO || item.type !== DealStatus.RETURNED_TO_AGENT_CC;
  });
const updateStatus = async(statusType: DealStatus) => {
  props.deal.status = statusType;
  await DealsApi.updateDealStatus(props.deal.id, statusType);
};

const isDealEditable = computed<boolean>(() => {
  return !props.disabled && props.deal.userCanEdit.STATUS;
});

const canEditStatus = (stageType: DealStatus): boolean => {
  const newStageIndex = stages.findIndex(item => item.type === stageType);
  const oldStageIndex = stages.findIndex(item => item.type === props.deal.status);
  return newStageIndex <= oldStageIndex;
};

const getStatuses = computed(() => {
  return stages?.filter(x => editableStatuses(props.deal.productType!).includes(x.type));
});
</script>

<style scoped lang="scss">

</style>
