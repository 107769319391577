<template>
  <div class="fab-btn__wrapper"
       :style="{'bottom': `${props.bottomPosition}px`, 'right':`${props.rightPosition}px`}">
    <v-speed-dial v-if="isMultipleActionBtn"
      class="main-fab"
      v-model="fabState"
      direction="top"
      transition="scale-transition">
      <template v-slot:activator>
        <v-btn
          :height="size"
          :width="size"
          v-model="fabState"
          :color="btnColor"
          fab>
          <v-icon :color="iconColor" v-if="fabState">close</v-icon>
          <v-icon :color="iconColor" v-else>add</v-icon>
        </v-btn>
      </template>
      <template>
        <v-tooltip left v-model="fabState" nudge-left="20"
                   v-for="(action, index) in actionOrFabActions" :key="index">
          <template v-slot:activator="{ on }">
            <v-btn @click="action.method"
                   :to="action.routeToNavigate"
                   v-on="on"
                   fab small>
              <v-icon>{{action.icon}}</v-icon>
            </v-btn>
          </template>
          <span>
            {{ action.title }}
          </span>
        </v-tooltip>
      </template>
    </v-speed-dial>
    <v-btn v-else
      class="main-fab"
      :color="btnColor"
      :height="size"
      :width="size"
      v-model="fabState"
      @click="actionOrFabActions"
      fab>
      <v-icon :color="iconColor">add</v-icon>
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {FabAction} from '@/models/interfaces/FabAction';

const fabState = ref<boolean>(false);
const props = withDefaults(defineProps<{
  actionOrFabActions: Function |FabAction[],
  size?: number,
  transparent?: boolean,
  rightPosition?: number,
  bottomPosition?: number,
}>(), {
  size: 60,
  rightPosition: 20,
  bottomPosition: 100,
});

const isMultipleActionBtn = computed<boolean>(() => Array.isArray(props.actionOrFabActions));
const btnColor = computed<string>(() => props.transparent ? '#2b8DFA33' : 'primary');
const iconColor = computed<string>(() => props.transparent ? 'primary' : 'white');
</script>

<style scoped lang="scss">
.fab-btn__wrapper {
  z-index: 7;
  position: fixed !important;
}
</style>
