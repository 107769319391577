import {ProductType} from '@/commons/enums/ProductType';
import {PolishNoun} from '@/types/PolishNoun';
import {ProspectLoanType} from '@/models/ProspectLoanType';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import EnvironmentService from '@/env/EnvironmentService';
import {AppDomainConfig} from '@/env/AppDomain';

export interface Product extends Partial<PolishNoun>{
  pluralName: string,
  type: ProductType,
  icon: string,
  color: string,
  prospectType?: ProspectLoanType,
  simulationUrl?: string,
  isAbleToPerform: {
    deal: boolean,
    report: boolean
    prospect: boolean
  }
}

const Products: Product[] = [
  {
    pluralName: 'Kredyty hipoteczne',
    nominative: {
      female: I18NGetter().PlatformCalculatorSection.mortgage_female,
      male: I18NGetter().PlatformCalculatorSection.mortgage_male,
    },
    type: ProductType.MORTGAGE,
    prospectType: ProspectLoanType.MORTGAGE,
    color: '#0a73eb',
    icon: 'mdi-home-city',
    simulationUrl: `${process.env.VUE_APP_IFRAME_URL}finrate-iframe/#/form/step-1`,
    isAbleToPerform: {
      deal: true,
      report: true,
      prospect: true,
    },
  },
  {
    pluralName: 'Kredyty gotówkowe',
    nominative: {
      female: I18NGetter().PlatformCalculatorSection.cash_female,
      male: I18NGetter().PlatformCalculatorSection.cash_male,
    },
    type: ProductType.CASH,
    prospectType: ProspectLoanType.CASH,
    color: '#23CD30',
    icon: 'mdi-wallet',
    simulationUrl: `${process.env.VUE_APP_IFRAME_URL}finrate-iframe/#/cash/form/step-1`,
    isAbleToPerform: {
      deal: true,
      report: true,
      prospect: true,
    },
  },
  {
    pluralName: 'Kredyty firmowe',
    nominative: {
      female: I18NGetter().PlatformCalculatorSection.sme_female,
      male: I18NGetter().PlatformCalculatorSection.sme_male,
    },
    type: ProductType.SME,
    prospectType: ProspectLoanType.SME,
    icon: 'mdi-rocket',
    color: '#912AFA',
    simulationUrl: `${process.env.VUE_APP_IFRAME_URL}finrate-iframe/#/sme/form/step-1`,
    isAbleToPerform: {
      deal: true,
      report: true,
      prospect: true,
    },
  },
  {
    pluralName: 'Nieruchomości',
    nominative: {
      female: I18NGetter().PlatformCalculatorSection.real_estate_female,
      male: I18NGetter().PlatformCalculatorSection.real_estate_male,
    },
    type: ProductType.REAL_ESTATE,
    prospectType: ProspectLoanType.REAL_ESTATE,
    icon: 'mdi-key-variant',
    color: '#CD6523',
    isAbleToPerform: {
      deal: false,
      report: false,
      prospect: true,
    },
  },
  {
    pluralName: 'Ubezpieczenia',
    nominative: {
      female: I18NGetter().PlatformCalculatorSection.insurance_female,
      male: I18NGetter().PlatformCalculatorSection.insurance_male,
    },
    type: ProductType.INSURANCE,
    prospectType: ProspectLoanType.INSURANCE,
    icon: 'mdi-shield',
    color: '#E21157',
    isAbleToPerform: {
      deal: false,
      report: true,
      prospect: false,
    },
  },
  {
    pluralName: 'Produkty pozabankowe',
    nominative: {
      female: I18NGetter().PlatformCalculatorSection.non_bank_female,
      male: I18NGetter().PlatformCalculatorSection.non_bank_male,
    },
    type: ProductType.NONBANK,
    icon: 'mdi-hand-coin',
    color: '#912AFA',
    isAbleToPerform: {
      deal: false,
      report: false,
      prospect: false,
    },
  },
  {
    pluralName: 'Faktoringi',
    nominative: {
      female: 'Faktoring',
      male: 'Faktoring',
    },
    type: ProductType.FACTORING,
    icon: 'mdi-hand-coin',
    color: '#912AFA',
    isAbleToPerform: {
      deal: false,
      report: false,
      prospect: false,
    },
  },
  {
    pluralName: 'Leasingi',
    nominative: {
      female: 'Leasing',
      male: 'Leasing',
    },
    type: ProductType.LEASING,
    icon: 'mdi-hand-coin',
    color: '#912AFA',
    isAbleToPerform: {
      deal: false,
      report: false,
      prospect: false,
    },
  },
  {
    pluralName: 'Inwestycje',
    nominative: {
      female: 'Investment',
      male: 'Investment',
    },
    type: ProductType.INVESTMENT,
    icon: 'mdi-hand-coin',
    color: '#912AFA',
    isAbleToPerform: {
      deal: false,
      report: false,
      prospect: false,
    },
  },
];

const EnvironmentProducts: () => Product[] = () => {
  const appDomainConfig: AppDomainConfig = EnvironmentService.Environment.getAppDomainConfig();
  return Products.filter(x => appDomainConfig.availableProducts.includes(x.type));
};

export default EnvironmentProducts;
