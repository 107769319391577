<template>
  <v-skeleton-loader v-if="!!promise && text === null" type="chip"/>
  <v-chip
    class="my-0"
    v-else
    :small="small"
    :close="close"
    @click="$emit('click')"
    :disabled="disabled"
    :outlined="outlined"
    @click:close="$emit('click:close')"
    :color="localColor"
    :text-color="String(textColor ?? color)">
    {{text}}
    <slot/>
  </v-chip>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue';
import {VuetifyThemeVariant} from 'vuetify/types/services/theme';
import {useInstance} from '@/composables/useInstance';

const props = defineProps<{
  small?: boolean;
  close: boolean;
  // To generuje vue-warn `Invalid prop type: "null" is not a constructor` ale wszystko działa poprawnie
  // color: keyof VuetifyThemeVariant | 'initial' temporary unitl typescript bump up 5.1.6 fixed this issue
  color: keyof VuetifyThemeVariant;
  noOpacity?: boolean;
  textColor?: keyof VuetifyThemeVariant;
  disabled?: boolean;
  outlined?: boolean;
  promise?: Promise<string>;
}>();

const {$vuetify,} = useInstance();

const localColor = computed<string>(() =>
  props.color === 'initial'
    ? 'initial'
    : $vuetify.theme.currentTheme[props.color] + (props.noOpacity ? '' : '33'));

const text = ref<Nullable<string>>(null);

onMounted(async() => {
  await setText();
});

if (props.promise) {
  watch(() => props.promise, async() => {
    setText();
  });
}

async function setText() {
  if (props.promise) {
    text.value = await props.promise;
  }
}
</script>
