import {Offer} from '@/models/simulation/Simulation';
import {SimulationDemand} from '@/models/Demand';
import {TableElementHeight} from '@/components/calculator/results/tableElements/TableElementsConstants';

export enum OfferDataTableElementKey {
  CREDITABILITY = 'CREDITABILITY',
  PRINCIPAL_AND_INTEREST_INSTALLMENT = 'PRINCIPAL_AND_INTEREST_INSTALLMENT',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE',
  TRANSITIONAL_INSTALLMENT = 'TRANSITIONAL_INSTALLMENT',
  TARGET_INSTALLMENT = 'TARGET_INSTALLMENT',
  FIRST_INSTALLMENT = 'FIRST_INSTALLMENT',
  FIRST_INSTALLMENT_FIRST_INSURANCE = 'FIRST_INSTALLMENT_FIRST_INSURANCE',
  FIRST_INSTALLMENT_SECOND_INSURANCE = 'FIRST_INSTALLMENT_SECOND_INSURANCE',
  FIRST_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT = 'FIRST_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT',
  FIRST_INSTALLMENT_FIRST_INSTALLMENT = 'FIRST_INSTALLMENT_FIRST_INSTALLMENT',
  NON_MONTHLY_COSTS = 'NON_MONTHLY_COSTS',
  NON_MONTHLY_COSTS_CREDIT_CARD = 'NON_MONTHLY_COSTS_CREDIT_CARD',
  NON_MONTHLY_COSTS_REAL_ESTATE_INSURANCE = 'NON_MONTHLY_COSTS_REAL_ESTATE_INSURANCE',
  LAST_INSTALLMENT = 'LAST_INSTALLMENT',
  INITIAL_COST = 'INITIAL_COST',
  COSTS_SUM = 'COSTS_SUM',
  LTV = 'LTV',
  TOTAL_SUM = 'TOTAL_SUM',
  DESCRIPTION = 'DESCRIPTION',
  RRSO = 'RRSO',
  INTEREST_RATE_SUM = 'INTEREST_RATE_SUM',
  MARGIN = 'MARGIN',
  PROVISION = 'PROVISION',
  LOAN_AMOUNT = 'LOAN_AMOUNT',
  LOAN_AMOUNT_GROSS = 'LOAN_AMOUNT_GROSS',
  LOAN_PERIOD = 'LOAN_PERIOD',
  ADDITIONAL_PRODUCTS = 'ADDITIONAL_PRODUCTS',
  ADDITIONAL_PRODUCTS_INFO = 'ADDITIONAL_PRODUCTS_INFO',
  PERSONAL_ACCOUNT = 'PERSONAL_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
  BRIDGING_INSURANCE = 'BRIDGING_INSURANCE',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  REAL_ESTATE_INSURANCE = 'REAL_ESTATE_INSURANCE',
  PAYMENT_INSURANCE = 'PAYMENT_INSURANCE',
  INSURANCE_PACKAGE = 'INSURANCE_PACKAGE',
  LOW_PERSONAL_SHARE_INSURANCE = 'LOW_PERSONAL_SHARE_INSURANCE',
  EARLIER_PAYMENT_DESCRIPTION = 'EARLIER_PAYMENT_DESCRIPTION',
  CLIENT_AGE = 'CLIENT_AGE',
  INCOME_TYPE = 'INCOME_TYPE',
  PROFESSION = 'PROFESSION',
  COLLATERALS = 'COLLATERALS',
  BORROWERS_COUNT = 'BORROWERS_COUNT',
  INSTALLMENTS_TYPE = 'INSTALLMENTS_TYPE',
  PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
  BROKER_COMMISSION = 'BROKER_COMMISSION',
  COSTS_SUM_INTEREST = 'COSTS_SUM_INTEREST',
  COSTS_SUM_PROVISION = 'COSTS_SUM_PROVISION',
  COSTS_SUM_BRIDGING_INSURANCE = 'COSTS_SUM_BRIDGING_INSURANCE',
  COSTS_SUM_LOW_PERSONAL_SHARE_INSURANCE = 'COSTS_SUM_LOW_PERSONAL_SHARE_INSURANCE',
  COSTS_SUM_REAL_ESTATE_INSURANCE = 'COSTS_SUM_REAL_ESTATE_INSURANCE',
  COSTS_SUM_REAL_LIFE_INSURANCE = 'COSTS_SUM_REAL_LIFE_INSURANCE',
  COSTS_SUM_PAYMENT_INSURANCE = 'COSTS_SUM_PAYMENT_INSURANCE',
  COSTS_SUM_INSURANCE_PACKAGE = 'COSTS_SUM_INSURANCE_PACKAGE',
  COSTS_SUM_PERSONAL_ACCOUNT = 'COSTS_SUM_PERSONAL_ACCOUNT',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_COSTS_SUM_PERSONAL_ACCOUNT = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_COSTS_SUM_PERSONAL_ACCOUNT',
  TARGET_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT = 'TARGET_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT',
  TRANSITIONAL_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT = 'TRANSITIONAL_INSTALLMENT_COSTS_SUM_PERSONAL_ACCOUNT',
  COSTS_SUM_CREDIT_CARD = 'COSTS_SUM_CREDIT_CARD',
  COSTS_SUM_REAL_ESTATE_VALUATION = 'COSTS_SUM_REAL_ESTATE_VALUATION',
  INITIAL_COST_PROVISION = 'INITIAL_COST_PROVISION',
  INITIAL_COST_REAL_ESTATE_INSURANCE = 'INITIAL_COST_REAL_ESTATE_INSURANCE',
  INITIAL_COST_LIFE_INSURANCE = 'INITIAL_COST_LIFE_INSURANCE',
  INITIAL_COST_PAYMENT_INSURANCE = 'INITIAL_COST_PAYMENT_INSURANCE',
  INITIAL_COST_INSURANCE_PACKAGE = 'INITIAL_COST_INSURANCE_PACKAGE',
  INITIAL_COST_REAL_ESTATE_VALUATION = 'INITIAL_COST_REAL_ESTATE_VALUATION',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_INSTALLMENT = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_INSTALLMENT',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_BRIDGING_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_BRIDGING_INSURANCE',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_REAL_ESTATE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_REAL_ESTATE_INSURANCE',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_LIFE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_LIFE_INSURANCE',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_PAYMENT_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_PAYMENT_INSURANCE',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_LOW_PERSONAL_SHARE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_LOW_PERSONAL_SHARE_INSURANCE',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_INSURANCE_PACKAGE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE_INSURANCE_PACKAGE',
  TRANSITIONAL_INSTALLMENT_INSTALLMENT = 'TRANSITIONAL_INSTALLMENT_INSTALLMENT',
  TRANSITIONAL_INSTALLMENT_REAL_ESTATE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_REAL_ESTATE_INSURANCE',
  TRANSITIONAL_INSTALLMENT_LIFE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_LIFE_INSURANCE',
  TRANSITIONAL_INSTALLMENT_PAYMENT_INSURANCE = 'TRANSITIONAL_INSTALLMENT_PAYMENT_INSURANCE',
  TRANSITIONAL_INSTALLMENT_LOW_PERSONAL_SHARE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_LOW_PERSONAL_SHARE_INSURANCE',
  TRANSITIONAL_INSTALLMENT_INSURANCE_PACKAGE = 'TRANSITIONAL_INSTALLMENT_INSURANCE_PACKAGE',
  TRANSITIONAL_INSTALLMENT_FIRST_INSURANCE = 'TRANSITIONAL_INSTALLMENT_FIRST_INSURANCE',
  TARGET_INSTALLMENT_INSTALLMENT = 'TARGET_INSTALLMENT_INSTALLMENT',
  TARGET_INSTALLMENT_REAL_ESTATE_INSURANCE = 'TARGET_INSTALLMENT_REAL_ESTATE_INSURANCE',
  TARGET_INSTALLMENT_LIFE_INSURANCE = 'TARGET_INSTALLMENT_LIFE_INSURANCE',
  TARGET_INSTALLMENT_PAYMENT_INSURANCE = 'TARGET_INSTALLMENT_PAYMENT_INSURANCE',
  TARGET_INSTALLMENT_INSURANCE_PACKAGE = 'TARGET_INSTALLMENT_INSURANCE_PACKAGE',
  TARGET_INSTALLMENT_FIRST_INSURANCE = 'TARGET_INSTALLMENT_FIRST_INSURANCE',
  MARGIN_DESCRIPTION = 'MARGIN_DESCRIPTION',
  INTEREST_RATE_SUM_DESCRIPTION = 'INTEREST_RATE_SUM_DESCRIPTION',
  CREDITABILITY_DESCRIPTION = 'CREDITABILITY_DESCRIPTION',
  TRANCHE_ACCOUNTING = 'TRANCHE_ACCOUNTING',
  PERCENTAGE_FOR_WHICHEVER_EXPENSE = 'PERCENTAGE_FOR_WHICHEVER_EXPENSE',
  PERCENTAGE_FOR_WHICHEVER_EXPENSE_DESCRIPTION = 'PERCENTAGE_FOR_WHICHEVER_EXPENSE_DESCRIPTION',
  CONVERSION = 'CONVERSION',
  CURRENCY_SPREAD = 'CURRENCY_SPREAD',
  CURRENCY_SPREAD_DESCRIPTION = 'CURRENCY_SPREAD_DESCRIPTION',
  BUY_RATE = 'BUY_RATE',
  FIRST_INSURANCE = 'FIRST_INSURANCE',
  SECOND_INSURANCE = 'SECOND_INSURANCE',
  TOTAL_COSTS_SUM = 'TOTAL_COSTS_SUM',
  DTI = 'DTI',
  DTI_DETAILS = 'DTI_DETAILS',
  GOV_PROGRAM = 'GOV_PROGRAM',
  TOTAL_SUBSIDY = 'TOTAL_SUBSIDY',
  PROVISION_DESCRIPTION = 'PROVISION_DESCRIPTION',
}

export enum OfferDataTableGroupKey {
  ROOT = 'ROOT',
  BASIC_PARAMETERS_GROUP = 'BASIC_PARAMETERS_GROUP',
  X_SELL_GROUP = 'X_SELL_GROUP',
}

export interface OfferTableViewElement {
  header: () => string,
  headerClass?: string,
  height?: TableElementHeight | number,
  printHeight?: TableElementHeight | number,
  class?: string
  open?: boolean,
  key: OfferDataTableElementKey,
  condition?: (userInput: Nullable<SimulationDemand>, isPrintMode?: boolean) => boolean,
  render?: (offer: Offer, userInput?: Nullable<SimulationDemand>) => string,
  printRender?: (offer: Offer, userInput?: Nullable<SimulationDemand>) => string,
  runtimeTemplate?: boolean,
  groupKey?: OfferDataTableGroupKey,
}

export interface OfferTableElement extends OfferTableViewElement {
  children?: OfferTableViewElement[],
}
