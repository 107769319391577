<template>
  <v-row class="justify-space-between data-card-element d-flex align-center no-gutters">
    <template>
      <v-col class="text--secondary col-auto py-1 body-2">
        <v-skeleton-loader
            v-if="loading"
            type="text" :width="$utils.charWidth * title.length"/>
        <span v-else>
        {{ title }}
      </span>
      </v-col>
      <v-col class="col-auto font-weight-medium py-1 body-2">
        <v-skeleton-loader
            v-if="loading"
            type="text" :width="loaderDescriptionWidth"/>
        <span v-else>
      {{description}}
      </span>
      </v-col>
    </template>
  </v-row>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class DataCardElementRow extends Vue {
  @Prop({type: String, required: true, })
  readonly title!: string;

  @Prop({type: [String, Number,], required: true, })
  readonly description!: string | number;

  @Prop({type: Boolean, required: false, default: false, })
  readonly loading!: boolean;

  get loaderDescriptionWidth(): number {
    return this.$utils.charWidth * String(this.description).length;
  }
}
</script>
<style scoped lang="scss">
.data-card-element{
  height: 26px;
}
</style>
