<template>
  <v-row class="no-gutters" ref="rootElement">
    <simulation-offers-table-headers :data-table-elements="dataTableElements"/>
    <no-offers v-if="isNoOffersInfoVisible"></no-offers>
    <v-col class="offers-wrapper" v-else>
      <template v-if="rootElement">
        <template v-if="store.isShownAvailableProducts">
          <l-lazy
            v-for="offer in store.pinnedOffers"
            :key="offer.info.id"
            class="offer-column br">
            <offer-details
              :offer="offer"
              :dataTableElements="dataTableElements">
            </offer-details>
          </l-lazy>
          <div v-if="store.pinnedOffers.length" class="offers-divider">
            <v-icon>mdi-pin</v-icon>
          </div>
          <l-lazy
            v-for="(offer, index) in store.sortedOffers"
            :key="offer.info.id"
            :class="{bl: index === 0 && store.pinnedOffers.length}"
            class="offer-column br">
            <offer-details
              :offer="offer"
              :dataTableElements="dataTableElements">
            </offer-details>
          </l-lazy>
        </template>
        <template v-else>
          <l-lazy
            v-for="offer in store.refusedOffers"
            :key="offer.info.id"
            class="offer-column br">
            <offer-details
              :offer="offer"
              :dataTableElements="dataTableElements">
            </offer-details>
          </l-lazy>
        </template>
        <div class="offers-end ml-auto" v-if="!isNoOffersInfoVisible">
          <div class="d-flex justify-center">
            {{ I18NGetter().useSimulationOfferListing.REACHED_END_OF_OFFERS }}
            <v-icon>mdi-emoticon-happy-outline</v-icon>
          </div>
        </div>
      </template>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import {OfferTableElement} from '@/components/calculator/results/offers/OfferTableElement';
import {defineComponent, PropType, ref, watch, computed} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import OfferDetails from '@/components/calculator/results/offers/OfferDetails.vue';
import SimulationOffersTableHeaders from '@/components/calculator/results/offers/SimulationOffersTableHeaders.vue';
import LLazy from '@/components/commons/LLazy.vue';
import NoOffers from '@/components/calculator/results/offers/NoOffers.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export default defineComponent({
  components: {LLazy, SimulationOffersTableHeaders, OfferDetails, NoOffers,},
  props: {
    dataTableElements: {
      type: Array as PropType<OfferTableElement[]>,
      required: true,
    },
  },
  setup(props, context) {
    const store = useSimulationStore();
    const rootElement = ref<Nullable<Element>>(null);

    watch([() => store.allOffers, () => store.isShownAvailableProducts,], () => {
      rootElement.value?.scrollTo({top: 0,});
    });

    const isNoOffersInfoVisible = computed<boolean>(() =>
      (store.isShownAvailableProducts ? store.allOffers.length : store.refusedOffers.length) === 0
    );

    return {
      store,
      rootElement,
      I18NGetter,
      isNoOffersInfoVisible,
    };
  },
});

</script>
<style scoped lang="scss">
$offer-column-width: 310px;
$offers-end-info-width: 280px;

.offer-column {
  min-width: $offer-column-width;
  max-width: $offer-column-width;
  display: inline-block;
  --hover-badge-background: #F2F4F9;
  --hover-odd-badge-background: #F8F8F9;

  &::v-deep .institution-badge-wrapper {
    background-color: var(--v-secondaryBg-base);
    border-top: 2px solid var(--v-secondaryBg-base);
  }
  &:nth-child(odd) {
    background: var(--v-primaryBg-base);
    &::v-deep .institution-badge-wrapper {
      background-color: var(--v-primaryBg-base);
      border-top: 2px solid var(--v-primaryBg-base);
    }
  }
  &:deep(.institution-badge-wrapper:hover) {
    background: var(--hover-badge-background);
  }
  &:nth-child(odd) :v-deep(.institution-badge-wrapper:hover) {
    background: var(--hover-odd-badge-background);
  }
}
.theme--dark {
  .offer-column {
    --hover-badge-background: #191919;
    --hover-odd-badge-background: #252525;
  }
}
.offers-wrapper {
  --headers-col-width: 210px;
  white-space: nowrap;
  display: flex;
  position: relative;
  max-width: calc(100% - var(--headers-col-width));
}
.offers-divider, .offers-end {
  position: sticky;
  height: 100%;
}

.offers-divider {
  background: fpShadow(0.07);
  min-width: 24px;
  ::v-deep .v-icon {
    position: sticky;
    top: 50%;
  }
}

.offers-end {
  min-width: $offers-end-info-width;
  max-width: $offers-end-info-width;
  div {
    position: sticky;
    top: 50%;
  }
}

</style>
