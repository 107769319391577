<template>
  <v-data-table
    id="fpDataTable"
    :value="value"
    :headers="headers"
    :items="items"
    hide-default-footer
  >
    <template #item="{item}">
      <tr @click="$goto.division(item.id)" class="pointer">
        <td class="px-4">{{item.id}}</td>
        <td class="px-4">{{item.name}}</td>
        <td class="flag-column px-4 text-center">
          <v-icon v-if="item.isMainChief">mdi-check</v-icon>
        </td>
        <td class="flag-column px-4 text-center">
          <v-icon v-if="item.isChief">mdi-check</v-icon>
        </td>
        <td class="flag-column px-4 text-center">
          <v-icon v-if="item.isMember">mdi-check</v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import Division, { UserDivisionListElement } from '@/models/Division';
import { DataTableHeader } from '@/services/searchEngine/interfaces/DataTableHeader';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component
export default class UserDivisions extends Vue {
  @Prop({default: () => [], }) value!: Division[];
  @Prop({required: true, }) userId!: number;

  private headers: Array<Partial<DataTableHeader<UserDivisionListElement, any>>> = [
    {text: 'ID', value: 'id',},
    {text: I18NGetter().useUserDivisions.DIVISION_NAME, value: 'name',},
    {text: I18NGetter().useUserDivisions.MAIN_CHIEF, value: 'isMainChief',},
    {text: I18NGetter().useUserDivisions.CHIEF, value: 'isChief',},
    {text: I18NGetter().useUserDivisions.EXPERT, value: 'isMember',},
  ];

  private items: Array<UserDivisionListElement> = this.value.map((item: Division) => {
    return {
      id: item.id,
      name: item.name,
      isMainChief: item.mainChief?.id === this.userId,
      isChief: item.chiefs.some(chief => chief.id === this.userId),
      isMember: item.members.some(member => member.id === this.userId),
    };
  });
}
</script>

<style scoped lang="scss">
  :deep(.v-data-table-header) th:nth-child(n+2) {
    text-align: center !important;
  }
  :deep(.v-data-table-header__icon) {
    position: absolute;
  }
  .flag-column {
    min-width: 136px;
  }
</style>
