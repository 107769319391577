import {UserStoreState} from '@/modules/user/services/store/UserStoreState';
import User from '@/models/user/User';
import {BanksApiDictionary} from '@/models/BanksApiDictionary';
import {UserDTO} from '@/models/user/UserDTO';

const state: UserStoreState = {
  ActiveUsersForProspects: [],
  UsersForDelegate: [],
  UsersForFilters: [],
  DivisionsForFilters: [],
  BankForFilters: [],
  BankBranches: [],
};

export const UserStoreModule = {
  namespaced: false,
  state,
  getters: {
    getActiveUsersForProspects(state: UserStoreState) {
      return state.UsersForFilters.map((user: UserDTO) => new User(user));
    },
    getUsersForFilters(state: UserStoreState) {
      return state.UsersForFilters.map((user: UserDTO) => new User(user));
    },
    getBanksForFilters(state: UserStoreState) {
      return state.BankForFilters;
    },
    getBankBranches(state: UserStoreState) {
      return state.BankBranches;
    },
  },
  mutations: {
    setActiveUsersForProspects(state: UserStoreState, users: Array<User>) {
      users.sort((a, b) => ('' + a.displayName).localeCompare(b.displayName));
      state.UsersForFilters = users;
    },
    setUsersForFilters(state: UserStoreState, users: Array<User>) {
      users.sort((a, b) => ('' + a.displayName).localeCompare(b.displayName));
      state.UsersForFilters = users;
    },
    setBanksForFilters(state: UserStoreState, banks: Array<BanksApiDictionary>) {
      state.BankForFilters = banks;
    },
    setBankBranches(state: UserStoreState, banks: Array<BanksApiDictionary>) {
      state.BankBranches = banks;
    },
    clearUsers(state: UserStoreState) {
      state.UsersForFilters = [];
    },
  },
};
