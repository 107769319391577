<template>
  <v-list-item-content class="pb-0 fill-width" @click="$emit('click', $event)">
    <v-layout class="align-center pr-3">
      <v-flex shrink class="px-3">
        <v-icon>{{icon}}</v-icon>
      </v-flex>
      <v-flex>
        <slot name="title"/>
        <div v-if="$slots['note']" class="pt-3 pb-2">
          <slot name="note" />
        </div>
        <span class="date-style">{{date | dateAndTimeFormat}}</span>
        <span class="username ml-2 font-weight-medium" v-if="($user.isAdminOrVerifier)">{{userName}}</span>
      </v-flex>
    </v-layout>
  </v-list-item-content>
</template>

<script lang="ts">
import Vue from 'vue';
import {Prop, Component} from 'vue-property-decorator';

@Component({})
export default class FpMessageWrapper extends Vue {
  @Prop({required: false, })
  public icon!: string;

  @Prop({required: true, })
  public date!: string;

  @Prop({required: false, })
  public userName!: string;
}
</script>

<style scoped lang="scss">
  .date-style{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.6;
  }
  .username {
    color: fpShadow($disabled-opacity);
  }
  p, span {
   & + .date-style {
     display: inline-block;
     margin-top: 8px;
   }
  }
</style>
