export enum EnvironmentMode {
  LENDI = 'LENDI',
  STANDARD = 'STANDARD'
}

export enum EnvironmentEnum {
  DEV = 'DEV',
  PREPROD = 'PREPROD',
  PROD = 'PROD',
}
