<template>
  <v-skeleton-loader type="text" v-if="text === null"/>
  <span v-else v-html-safe="text"/>
</template>

<script lang="ts">
import {Component, Prop, Watch, Vue} from 'vue-property-decorator';

@Component
export default class AsyncSpan extends Vue {
  @Prop({required: true, })
  public readonly promise!: Promise<string>;

  public text: Nullable<string> = null;

  @Watch('promise')
  async onChange() {
    await this.resolvePromise();
  }

  async mounted() {
    await this.resolvePromise();
  }

  private async resolvePromise(): Promise<void> {
    this.text = await this.promise;
  }
}
</script>

<style scoped lang="scss">

</style>
