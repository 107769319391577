import {OrganisationId} from '@/models/enums/OrganisationId';
import {Utils} from '@/services/utils/BasicUtils';

export default class Agent {
  public userId!: number;
  public organisationId?: OrganisationId;
  public organisationUnitId?: number;
  public email: string = '';
  public name?: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public divisions: Array<number> = [];
  public url?: string = '';
  public photoUrl?: string = '';

  get displayName(): string {
    return (this.firstName || this.lastName) ? `${this.firstName} ${this.lastName}` : '-';
  }

  constructor(dto?: Agent) {
    if (dto) {
      Utils.assign(this, dto);
    }
  }
}
