<template>
  <v-container grid-list-lg>
    <v-row wrap no-gutters>
      <v-col xs12>
        <span class="title main-title d-block">
          <slot name="title"/>
        </span>
        <fp-loader v-if="!value" circle size="40px"/>
        <template v-else>
          <v-row v-for="(userBusinessHoursElement, index) in value" :key="index">
            <v-col sm="4" class="py-0">
              <fp-checkbox v-model="userBusinessHoursElement.isEnabled"
                            class="mt-2"
                            :label="$i18n.DayOfWeek[userBusinessHoursElement.day]"/>
            </v-col>
            <v-col sm="4" class="py-0">
              <fp-timepicker v-model="userBusinessHoursElement.openDateObject"
                              :disabled="!userBusinessHoursElement.isEnabled"
                              :min-hour="MIN_ACTIVITY_HOUR"
                              dense
                              @input="validateHour(userBusinessHoursElement.openDateObject, userBusinessHoursElement.closeDateObject, true)"
              />
            </v-col>
            <v-col sm="4" class="py-0">
              <fp-timepicker v-model="userBusinessHoursElement.closeDateObject"
                              :disabled="!userBusinessHoursElement.isEnabled"
                              :min-hour="getMinHour(userBusinessHoursElement.openDateObject)"
                              dense
                              @input="validateHour(userBusinessHoursElement.openDateObject, userBusinessHoursElement.closeDateObject, false)"
              />
            </v-col>
          </v-row>
          <v-divider/>
          <v-row justify="center" class="pa-2">
            {{ I18NGetter().useEditUserBusinessHours.CHANGE_FOR_ALL }}
          </v-row>
          <v-row>
            <v-col sm="4" class="d-flex justify-center align-center pa-0">
              <fp-btn class="fill-width" @click="changeHoursForSelectedDays">
                {{I18NGetter().useEditUserBusinessHours.CHANGE_HOURS }}
              </fp-btn>
            </v-col>
            <v-col sm="4">
              <fp-timepicker
                v-model="businessHoursForSelectedDays.openDateObject"
               :min-hour="MIN_ACTIVITY_HOUR"
               :max-hour="maxHour"
                dense
              />
            </v-col>
            <v-col sm="4">
              <fp-timepicker
                dense
                v-model="businessHoursForSelectedDays.closeDateObject"
                :min-hour="minHour"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {CLOSE_HOUR, OPEN_HOUR, userBusinessHours, UserBusinessHoursElement} from '@/models/UserBusinessHoursElement';
import { MIN_ACTIVITY_HOUR } from '@/models/Activity';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component
export default class EditUserBusinessHours extends Vue {
  @Prop({type: Array, default: userBusinessHours,})
  public readonly value!: Array<UserBusinessHoursElement>;

  public MIN_ACTIVITY_HOUR = MIN_ACTIVITY_HOUR;

  public I18NGetter = I18NGetter;

  private businessHoursForSelectedDays: UserBusinessHoursElement = new UserBusinessHoursElement({
    open: OPEN_HOUR,
    close: CLOSE_HOUR,
  });

  private getMinHour(dateObject: Date): string {
    return dateObject.getHours() + ':' + dateObject.getMinutes();
  }

  private get minHour(): string {
    return this.businessHoursForSelectedDays.openDateObject.getHours() + ':' + this.businessHoursForSelectedDays.openDateObject.getMinutes();
  }

  private get maxHour(): string {
    return this.businessHoursForSelectedDays.closeDateObject.getHours() + ':' + this.businessHoursForSelectedDays.closeDateObject.getMinutes();
  }

  public validateHour(openHourObject: Date, closeHourObject: Date, isOpen: boolean) {
    if (closeHourObject?.getTime() < openHourObject?.getTime()) {
      if (isOpen) {
        openHourObject.setHours(openHourObject.getHours() + 1);
        const foundHour = this.value.find(x => x.closeDateObject.getTime() === closeHourObject.getTime());
        if (foundHour) {
          foundHour.closeDateObject = openHourObject;
        }
      } else {
        closeHourObject.setHours(closeHourObject.getHours() - 1);
        const foundHour = this.value.find(x => x.openDateObject.getTime() === openHourObject.getTime());
        if (foundHour) {
          foundHour.openDateObject = closeHourObject;
        }
      }
    }
  }

  public changeHoursForSelectedDays() {
    this.value.forEach((hoursElement: UserBusinessHoursElement) => {
      if (hoursElement.isEnabled) {
        hoursElement.openDateObject = this.businessHoursForSelectedDays.openDateObject;
        hoursElement.closeDateObject = this.businessHoursForSelectedDays.closeDateObject;
      }
    });
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .v-text-field__details {
    display: none;
  }
</style>
