<template>
  <v-card>
    <v-card-title class="ml-2">
      {{ modalData.isOwnPages ? I18NGetter().useAddPartnerModal.ADD_PAGES_AS_PARTNER : I18NGetter().useAddPartnerModal.NEW_PARTNER }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-2">
      <v-form :ref="CREATE_PARTNER_FORM_KEY">
        <v-container pa-0 grid-list-lg>
          <v-row>
            <v-col>
            <v-layout v-if="isOwnPagesCheckboxShown" class="pb-2">
              <fp-checkbox
                :label="I18NGetter().useAddPartnerModal.ADD_PAGES_AS_PARTNER"
                v-model="isOwnPages"
                class="ml-10"/>
            </v-layout>
            <v-layout>
              <fp-select
                  md5
                  :disabled="isOwnPages"
                  xs12
                  :id="$cy.partnerTypeInput"
                  :label="I18NGetter().useAddPartnerModal.TYPE_OF_PARTNER"
                  prepend-icon="mdi-handshake-outline"
                  :items="multiformDictionary.partnerTypeDictionary"
                  v-model="partner.type"
                  required
                />
                <fp-input
                  md7
                  xs12
                  :disabled="isOwnPages"
                  :id="$cy.partnerNameInput"
                  :label="I18NGetter().useAddPartnerModal.PARTNER_NAME"
                  v-model="partner.name"
                  required
                />
              </v-layout>
              <v-layout>
                <v-col class="pa-0">
                  <fp-input
                    md7
                    xs12
                    :disabled="isOwnPages"
                    :id="$cy.partnerEmailInput"
                    :label="I18NGetter().useAddPartnerModal.EMAIL_ADDRESS"
                    v-model="partner.email"
                    prepend-icon="mdi-email"
                    ref="fp_email"
                    email
                    required
                  />
                  <FpNipForm
                    class="pl-2"
                    :partner-without-nip="partnerWithoutNip"
                    @fetchCompanyDataByNip="getPartnerData"
                    @update:nip="setNip"
                  />
                  <v-col cols="7" class="pa-0 pt-2 pl-10">
                    <fp-checkbox
                      v-if="appDomainConfig.taxIdentificationNumber.hasCompanyDataApiIntegration"
                      v-model="partnerWithoutNip"
                      :label="I18NGetter().useAddPartnerModal.FILL_PARTNER_DATA_MANUALLY"/>
                  </v-col>
                  <v-row  class="px-5">
                  <v-col v-if="$user.isAppDomain.NESTO_RO" cols="12" class="pa-0">
                    <v-card flat class="pa-6 card-background-gray ml-6">
                    <AddressForm
                      v-model="partner.primaryAddress"
                      prepend-icon="mdi-city-variant-outline" class="pt-0 pl-2"
                      :long-form="false"
                      :with-country="false"
                      :requiredFields="{ zip: false }"
                      :required="true"
                      :label="I18NGetter().useAddressCommons.ADDRESS"
                    />
                    </v-card>
                  </v-col>
                  </v-row>
                  <v-col class="pl-4" v-if="!isAppDomain.NESTO_RO && (partnerBussinessName || partnerWithoutNip)">
                    <v-card flat class="pa-6 card-background-gray ml-6">
                      <v-flex>
                        <v-row>
                          <fp-input :label="I18NGetter().useAddPartnerModal.FULL_NAME" v-if="partnerWithoutNip"
                                    v-model="partnerBussinessName"/>
                          <template v-else>
                            <p class="font-weight-medium text--secondary mb-0">
                              {{ I18NGetter().useAddPartnerModal.FULL_NAME }}</p>
                            <v-card-text class="pa-0 pb-4">{{ partnerBussinessName }}</v-card-text>
                          </template>
                        </v-row>
                        <template v-if="partnerWithoutNip">
                          <AddressForm
                            v-model="partner.primaryAddress"
                            :long-form="false"
                            :with-country="false"
                            :with-community="false"
                          />
                        </template>
                        <v-row v-else>
                          <template>
                            <p class="font-weight-medium text--secondary mb-0">
                              {{ I18NGetter().useAddPartnerModal.ADDRESS }}</p>
                            <v-card-text class="pa-0 pb-4">{{ partner.displayAddress }}</v-card-text>
                          </template>
                        </v-row>
                        <v-row v-if="$user.isAppDomain.LENDI_PL">
                          <fp-input
                            v-if="partnerWithoutNip"
                            :label="I18NGetter().useAddPartnerModal.REGON"
                            v-model="partner.regon"/>
                          <template v-else>
                            <p class="font-weight-medium text--secondary mb-0">{{
                                I18NGetter().useAddPartnerModal.REGON
                              }}</p>
                            <v-card-text class="pa-0 pb-4">{{ partner.regon }}</v-card-text>
                          </template>
                        </v-row>
                        <v-row v-if="partner.krs">
                          <p class="font-weight-medium text--secondary mb-0">
                            {{ I18NGetter().useAddPartnerModal.KRS }}</p>
                          <v-card-text class="pa-0 pb-4">{{ partner.krs }}</v-card-text>
                        </v-row>
                        <v-row>
                          <h4 class="font-weight-bold mb-0 pb-2">{{ I18NGetter().useAddPartnerModal.MAILING_ADDRESS }}</h4>
                        </v-row>
                        <v-row class="pa-0 pb-4">
                          <fp-checkbox
                            v-model="businessAddress"
                            :label="I18NGetter().useAddPartnerModal.SAME_AS_BUSINESS_ADDRESS"
                          />
                        </v-row>
                        <template v-if="!businessAddress">
                        <AddressForm
                          v-model="partner.secondaryAddress"
                          :long-form="false"
                          :with-country="false"
                          :with-community="false"
                          :requiredFields="{ zip: false }"
                        />
                        </template>
                      </v-flex>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="pl-2" v-if="authorizedUser">
                    <h3 class="pb-3">{{ I18NGetter().useAddPartnerModal.OPTIONS }}</h3>
                    <fp-checkbox
                      :id="$cy.partnerGlobalCheckbox"
                      :label="I18NGetter().useAddPartnerModal.GLOBAL_PARTNER"
                      v-model="partner.globalPartner"
                      :disabled="$user.isAppDomain.NESTO_RO"
                    />
                    <template v-if="isAppDomain.LENDI_PL">
                      <fp-checkbox
                        :id="$cy.expertFixCheckbox"
                        :label="I18NGetter().useAddPartnerModal.EXPERT_FIT"
                        v-model="partner.expertFit"
                        v-if="partnerSettings.hasExpertFit"
                      />
                      <fp-checkbox
                        v-if="$user.isAppDomain.LENDI_PL"
                        :id="$cy.requireReportingCheckbox"
                        :label="I18NGetter().useAddPartnerModal.REQUIRE_REPORTING"
                        v-model="partner.requireReporting"
                      />
                    </template>
                  </v-col>
                </v-col>
              </v-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider/>
    <v-card-actions class="pl-6">
      <fp-btn
        :id="$cy.submitPartnerButton"
        primary
        :click="()=>createPartner()"
      >{{ I18NGetter().useAddPartnerModal.ADD_PARTNER }}
      </fp-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import {Component, Watch} from 'vue-property-decorator';
import {CreatedPartnerModel, generatePartnerStatusList, OWN_PAGES_PREFIX} from '@/models/Partner';
import MultiformDictionary from '@/modules/multiForm/shared/MultiformDictionaries';
import PartnerApi from '@/modules/partner/services/PartnerApi';
import {VueModalComponent} from 'fp-components';
import FpNipForm from '@/components/inputs/FpNipForm.vue';
import {CompanyDataDto} from '@/models/CompanyData';
import {PartnerType} from '@/modules/partner/enums/PartnerType';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import AddressForm from '@/components/address/AddressForm.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import EnvironmentService from '@/env/EnvironmentService';
import {CrmModules} from '@/types/CrmModules';
import AppRouter from '@/router';

@Component({
  components: {AddressForm, FpNipForm,},
})
export default class AddPartnerModal extends VueModalComponent<any> {
  public partner: CreatedPartnerModel = new CreatedPartnerModel();
  public partnerWithoutNip: boolean = false;
  public partnerBussinessName: Nullable<string> = null;
  public businessAddress: boolean = true;
  public multiformDictionary = MultiformDictionary;
  public isOwnPages: boolean = this.modalData.isOwnPages || false;
  public readonly CREATE_PARTNER_FORM_KEY: string = 'CREATE_PARTNER_FORM_KEY';
  public I18NGetter: () => I18NInterface = I18NGetter;
  public appDomainConfig = EnvironmentService.Environment.getAppDomainConfig();
  public isAppDomain = EnvironmentService.Environment.isAppDomain();
  public partnerSettings = EnvironmentService.Environment.getAppDomainConfig().moduleSettings[CrmModules.PARTNERS];

  mounted() {
    if (this.isOwnPages) {
      this.setOwnPagesPartner();
    }
    if (this.isAppDomain.NESTO_RO) this.partner.globalPartner = true;
  }

  get authorizedUser(): boolean {
    return this.$user.isAdmin || this.$user.isVerifier;
  }

  @Watch('isOwnPages')
  setOwnPagesPartner() {
    this.partner.email = this.$user.email;
    this.partner.name = `${OWN_PAGES_PREFIX} - ${this.$user.displayName}`;
    this.partner.type = PartnerType.OTHER;
  }

  @Watch('partnerWithoutNip')
  setPartnerManually() {
    if (!this.partnerWithoutNip) {
      this.partnerBussinessName = null;
      this.partner.primaryAddress = new Address();
      this.partner.secondaryAddress = new Address();
      this.partner.regon = null;
      this.partner.krs = null;
    }
  }

  async createPartner() {
    if (this.$validate(this.CREATE_PARTNER_FORM_KEY)) {
      if (this.businessAddress) {
        this.partner.secondaryAddress = this.partner.primaryAddress;
      }
      try {
        const response = await PartnerApi.createPartner(this.partner);
        await PartnerApi.assignMeToPartner(response.id);
        this.$snackbarService.openSuccessSnackbar(`${I18NGetter().useAddPartnerModal.SUCCESS_ADD_PARTNER}`);
        this.$snackbarService.openSuccessSnackbar(I18NGetter().useAddPartnerModal.SUCCESS_SNACKBAR_ASSIGN_TO_ME);
        if (this.$user.isChiefOfDivision || this.$user.isChiefOfOrganisation) {
          await PartnerApi.assignMyDivisionsToPartner(response.id);
          this.$snackbarService.openSuccessSnackbar(
            I18NGetter().useAddPartnerModal.SUCCESS_SNACKBAR_ASSIGN_TO_DIVISION);
        }
        this.closeModal(response);
      } catch (e) {
        this.errorHandler(e, I18NGetter().useAddPartnerModal.CREATE_PARTNER_ERROR_HANDLER);
        const errorObject = {...e,};
        const existingPartnerId = errorObject.response.data.additionalInfo.partnerId;
        if (existingPartnerId) {
          if (await this.$modalService.openConfirmModal(I18NGetter()
            .useAddPartnerModal.CREATE_PARTNER_OPEN_CONFIRM_MODAL)) {
            try {
              const response = await PartnerApi.assignMeToPartner(existingPartnerId);
              this.$snackbarService.openSuccessSnackbar(I18NGetter().useAddPartnerModal.SUCCESS_SNACKBAR_ASSIGN_TO_ME);
              if (this.$user.isChiefOfDivision || this.$user.isChiefOfOrganisation) {
                await PartnerApi.assignMyDivisionsToPartner(existingPartnerId);
                this.$snackbarService.openSuccessSnackbar(
                  I18NGetter().useAddPartnerModal.SUCCESS_SNACKBAR_ASSIGN_TO_DIVISION);
              }
              this.closeModal(response);
            } catch (e) {
              this.errorHandler(e);
            }
          }
        }
      }
    }
  }

  public getPartnerData(response: CompanyDataDto, nip: string) {
    this.partner.nip = nip;
    if (response) {
      this.partnerBussinessName = response.name;
      this.partner.regon = response.regon;
      this.partner.primaryAddress = response.address;
      this.partner.krs = response.krs;
    }
  }

  public get isOwnPagesCheckboxShown () {
    return !this.modalData.isOwnPages && this.partnerSettings.hasOwnPages;
  }

  public setNip(nip: string) {
    this.partner.nip = nip;
  }
}
</script>
<style scoped lang="scss">
.card-background-gray {
  background-color: rgba(35, 45, 59, 0.03);
  border-radius: 4px;
}
</style>
