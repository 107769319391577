import {mapValues} from 'lodash-es';
import {getCurrentInstance} from 'vue';

export const useQueryParams = () => {
  const instance = getCurrentInstance();

  if (!instance) {
    throw new Error('useQueryParams must be called within a setup function');
  }

  const getQueryParams = <T>(): Partial<T> => {
    return mapValues(instance.proxy.$route.query, (value: string | string[]) => {
      if (Array.isArray(value)) {
        return value;
      }
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }) as any as Partial<T>;
  };

  const toQueryParams = <T>(query: Partial<T>): Record<string, string> =>
    mapValues(query, (value: typeof query[keyof T]) =>
      typeof value === 'string' ? value : JSON.stringify(value));

  return {
    getQueryParams,
    toQueryParams,
  };
};
