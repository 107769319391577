import {computed, onMounted, onUnmounted, ref} from 'vue';
import {debouncedAtInput} from '@/services/utils/BasicUtils';

interface ScrollEvent extends Event {
  target: Nullable<EventTarget> & {scrollLeft: number, scrollWidth: number};
}

export const useHorizontalOffersScroll = (selector: string) => {
  const SCROLL_DISTANCE: number = 310;
  const MIN_SCROLL_DISTANCE_AMOUNT: number = 4;

  const isScrollToStartVisible = ref<boolean>(false);
  const isScrollToStartClosedManually = ref<boolean>(false);

  const scrollableContainer = ref<Nullable<Element>>(null);
  type IntervalType = number | NodeJS.Timeout;
  const interval = ref<Nullable<IntervalType>>(null);

  const onScroll = debouncedAtInput((event: Event) => {
    const {target,} = event as ScrollEvent;
    if (target.scrollLeft === 0) {
      isScrollToStartClosedManually.value = false;
    }
    isScrollToStartVisible.value = target.scrollLeft > MIN_SCROLL_DISTANCE_AMOUNT * SCROLL_DISTANCE;
  });

  const closeScrollToStart = () => {
    isScrollToStartClosedManually.value = true;
  };

  const scrollToStart = () => {
    scrollableContainer.value?.scrollTo({left: 0,});
  };

  const getAdjustedScrollPosition = (distance: number) => {
    const scrollLeft = scrollableContainer.value?.scrollLeft ?? 0;
    return Math.round(scrollLeft / distance) * distance;
  };

  const scrollLeft = (distance: number) => {
    scrollableContainer.value?.scrollTo({left: getAdjustedScrollPosition(distance) - distance, behavior: 'smooth',});
  };

  const scrollRight = (distance: number) => {
    scrollableContainer.value?.scrollTo({left: getAdjustedScrollPosition(distance) + distance, behavior: 'smooth',});
  };

  const startAutoScroll = (scrollFunc: (distance: number) => void, distance: number) => {
    interval.value = setInterval(() => scrollFunc(distance), 400);
  };

  function stopAutoScroll() {
    if (interval.value) {
      clearInterval(interval.value);
    }
  }

  onMounted(() => {
    if (!selector) {
      console.error('If useHorizontalOffersScroll is to be used, you must provide the scrollable container selector');
      return;
    }
    scrollableContainer.value = document.querySelector(selector);
    scrollableContainer.value?.addEventListener('scroll', debouncedAtInput(onScroll));
  });

  onUnmounted(() => {
    scrollableContainer.value?.removeEventListener('scroll', debouncedAtInput(onScroll));
  });
  return {
    isScrollToStartVisible,
    isScrollToStartClosedManually,
    onScroll,
    closeScrollToStart,
    scrollToStart,
    scrollLeft,
    scrollRight,
    startAutoScroll,
    stopAutoScroll,
    SCROLL_DISTANCE,
  };
};
