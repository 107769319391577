export enum LoanApplicationStepEnum {
  ClientChooseOffer = 1,
  ClientResigned = 6,
  DocumentationCompleted = 13,
  ApplicationInTheBank = 14,
  DecisionNegative = 16,
  DecisionPositive = 18,
  AgreementConcluded = 22,
  Started = 24,
  AgreementConditionNotMet = 26,
  ProvisionWasPaid = 27,
  WaitingForVerification = 28,
  Accepted = 29,
  Rejected = 30,
  SalesReportedToFinancialInstitution = 31,
  FoundsReceivedFromFinancialInstitution = 32,
  StatementsSentToExpert = 33,
  ReturnedForCorrection = 34,
  InitialOfferFromBank = 35,
  SummaryOffer = 36,
  NegativeDecision = 37,
  ExpiredOffer = 39,
  WaitingForDecision = 40,
  ChangeFinalisation = 41,
  EditChecklist = 42,
  BankVerification = 43,
  AcceptedCorrection = 44,
  DocumentationRequired = 45,
  ApplicationSuspended = 46,
  DocumentsInControl = 47,
  OriginalsWaiting = 48,
  OriginalsControl = 49,
  OriginalsComplement = 50,
  OriginalsRequiredAfterStart = 51,
}
