import moment from 'moment';

type DaylightSavingTimeConfig = {
  startMonth: number;
  endMonth: number;
};

type TimeZoneConfig = {
  standardOffset: number;
  dstOffset: number;
  dstConfig: DaylightSavingTimeConfig;
};

// this config represents a timezone for Warsaw, Poland
// DST STAND FOR DAYLIGHT SAVING
const defaultConfig: TimeZoneConfig = {
  // Uwaga: Poniższa konfiguracja jest przykładową konfiguracją dla strefy czasowej Warszawy, Polska.
  // Należy ją dostosować do strefy czasowej, z której rzeczywiście korzystamy.
  // here is a standard offset for a specific timezone e.g. UTC+1
  standardOffset: 60,
  // here is a daylight saving time offset for a specific timezone e.g. UTC+2 in summer represent amount of minutes
  dstOffset: 60,
  // number of months starting from 0
  // this is specific for a polish timezone
  // this is configuration for a daylight saving time
  dstConfig: {
    startMonth: 2, // start of month which daylight saving time starts e.g. 2 is March
    endMonth: 9, // end of month which daylight saving time ends e.g. 9 is October
  },
};

export function useTimezone(timeZoneConfig: TimeZoneConfig = defaultConfig) {
  const config = timeZoneConfig;

  const getLastSunday = (year: number, month: number): moment.Moment => {
    return moment([year, month + 1, 1,]).subtract(1, 'week').day('Sunday');
  };

  const isDSTActive = (date: moment.Moment): boolean => {
    const year = date.year();
    const startDST = getLastSunday(year, config.dstConfig.startMonth);
    const endDST = getLastSunday(year, config.dstConfig.endMonth);
    return date.isBetween(startDST, endDST, 'day', '[]');
  };

  const getLocalTime = (date: Date | moment.Moment, useMoment: boolean = true): Date | moment.Moment => {
    const momentDate = moment(date).utcOffset(config.standardOffset);
    const dstActive = isDSTActive(momentDate);
    const totalOffset = dstActive ? config.dstOffset : config.standardOffset;
    const localDate = momentDate.utcOffset(totalOffset);
    return useMoment ? localDate : localDate.toDate();
  };

  const getUTCTime = (date: Date | moment.Moment, useMoment: boolean = true): Date | moment.Moment => {
    let momentDate = moment(date);
    momentDate = momentDate.minutes(momentDate.utcOffset());
    return useMoment ? momentDate : momentDate.toDate();
  }

  return { getLocalTime, isDSTActive, getLastSunday, getUTCTime, };
}
