/**
 * @deprecated use BankSymbol {@link https://github.com/finpack/universal-model/blob/master/openapi/dictionaries.yml}
 */
export enum BankSymbol { // <- BankCode or BankSymbol or FinancialInstitutionCode
  AION_BANK = 'AION_BANK',
  ALIOR = 'ALIOR',
  ALIOR_LEASING = 'ALIOR_LEASING',
  ATHLON = 'ATHLON',
  BNP = 'BGZ',
  IFL = 'IFL',
  BIBBY = 'BIBBY',
  BIZ = 'BIZ',
  BOS = 'BOS',
  BPH = 'BPH',
  BPS = 'BPS',
  BPS_LEASING = 'BPS_LEASING',
  BREWE_FAKTORING = 'BREWE_FAKTORING',
  BS_WSCHOWA = 'BS_WSCHOWA',
  CITI = 'CITI',
  COFIDIS = 'COFIDIS',
  AGRICOLE = 'AGRICOLE',
  DEUTSCHE = 'DEUTSCHE',
  EFAKTOR = 'EFAKTOR',
  EFL = 'EFL',
  EPEF = 'EPEF',
  EUROBANK = 'EUROBANK',
  FAIR_FINANCE24 = 'FAIR_FINANCE24',
  FAKTORIA = 'FAKTORIA',
  FINIATA = 'FINIATA',
  FUNDUSZ_MIKRO = 'FUNDUSZ_MIKRO',
  KRK_PARTNERS = 'KRK_PARTNERS',
  GETIN = 'GETIN',
  GRENKE = 'GRENKE',
  HAPI = 'HAPI',
  IDEA = 'IDEA',
  IDEA_GETIN_LEASING = 'IDEA_GETIN_LEASING',
  IDEA_LEASING = 'IDEA_LEASING',
  IDEA_MONEY = 'IDEA_MONEY',
  IMPULS = 'IMPULS',
  IMPULS_LEASING = 'IMPULS_LEASING',
  INDOS = 'INDOS',
  ING = 'ING',
  ING_LEASING = 'ING_LEASING',
  IWOCA = 'IWOCA',
  JANVEST = 'JANVEST',
  KFP = 'KFP',
  KREDYT_OK = 'KREDYT_OK',
  LEASE_LINK = 'LEASE_LINK',
  LEASING_POLSKI = 'LEASING_POLSKI',
  MBANK = 'MULTI',
  MIKROKASA = 'MIKROKASA',
  MLEASING = 'M_LEASING',
  MERITUM = 'MERITUM',
  MILLENNIUM = 'MILLENNIUM',
  MILLENNIUM_LEASING = 'MILLENNIUM_LEASING',
  MONUMENT_FUND = 'MONUMENT_FUND',
  NEO = 'NEO',
  NEST = 'NEST',
  NOBLE_FINANCE_LEASING = 'NOBLE_FINANCE_LEASING',
  NORDEA = 'NORDEA',
  PEAC_FINANCE = 'PEAC_FINANCE',
  PEKAO_SA = 'PEKAO_SA',
  PEKAO_BH = 'PEKAO_BH',
  PEKAO_LEASING = 'PEKAO_LEASING',
  PKO_BP = 'PKO_BP',
  PKO_LEASING = 'PKO_LEASING',
  PLUS = 'PLUS',
  PLUSFACTOR = 'PLUSFACTOR',
  POCZTOWY = 'POCZTOWY',
  POZYCZKI_UNIJNE = 'POZYCZKI_UNIJNE',
  PRAGMA = 'PRAGMA',
  PZU = 'PZU',
  RAIFFEISEN = 'RAIFFEISEN',
  RAPIDA = 'RAPIDA',
  SANTANDER = 'SANTANDER',
  SANTANDER_LEASING = 'SANTANDER_LEASING',
  SBR = 'SBR',
  SGEF = 'SGEF',
  SMARTNEY = 'SMARTNEY',
  SMEO = 'SMEO',
  SUNDAY = 'SUNDAY',
  VEHIS = 'VEHIS',
  VIENNA = 'VIENNA',
  PRUDENTIAL = 'PRUDENTIAL',
  ERGO_HESTIA = 'HESTIA',
  NATIONALE_NEDERLANDEN = 'NATIONALE_NEDERLANDEN',
  INTER_RISK = 'INTER_RISK',
  WEALTHON_FUND = 'WEALTHON_FUND',
  LENDI_CARE = 'LENDI_CARE',
  other = 'other',
  NONE = ''
}
