import {IClientScan} from '@/models/clientScans/IClientScan';
import {ClientScanKey} from '@/models/clientScans/ClientScanKey';
import {ScanType} from '@/models/clientScans/ScanType';
import {ClientAgreements} from '@/modules/client/views/client-agreements/Agreements';
import AuthService from '@/modules/user/AuthService';
import {OrganisationId} from '@/models/enums/OrganisationId';
export const ClientScansDtoLendi: IClientScan[] = [
  {
    key: ClientScanKey.AGREEMENT,
    documentName: 'Formularz zgód na przetwarzanie danych osobowych przez Lendi sp. z o.o.',
    type: ScanType.RODO,
    condition: c => !c.isCompany,
    documentUrl: 'https://platforma.finpack.pl/finrate/static-info-files/Zgoda_papierowa_RODO_Lendi.pdf',
    required: (v: ClientAgreements) => v.acceptsProcessing,
  },
  {
    key: ClientScanKey.AGREEMENT,
    documentName: 'Formularz zgód na przetwarzanie danych osobowych przez Lendi sp. z o.o.',
    type: ScanType.RODO,
    condition: c => c.isCompany,
    documentUrl: 'https://platforma.finpack.pl/finrate/static-info-files/01_done_Zgoda_RODO_LENDI_SME.pdf',
    required: (v: ClientAgreements) => v.acceptsProcessing,
  },
  {
    key: ClientScanKey.OFI,
    documentName: 'Ogólny formularz informacyjny o Pośredniku Lendi sp. z o.o.',
    type: ScanType.OFI,
    documentUrl: 'https://platforma.lendi.pl/v2/app/knowledge/filemanager/%2FUODO%20i%20OFI%20-%20Lendi',
    required: (v: ClientAgreements) => v.acceptsReceivingInfo,
  },
  {
    key: ClientScanKey.AGREEMENT_FINAMAX,
    documentName: 'Formularz zgód na przetwarzanie danych osobowych przez FINAMAX sp. z o.o.',
    type: ScanType.RODO,
    documentUrl: 'https://platforma.finpack.pl/finrate/static-info-files/RODO_FINAMAX.pdf',
    condition: c => AuthService.User?.organisationId === OrganisationId.FINAMAX,
    required: (v: ClientAgreements) => v.acceptsProcessingPartner,
  },
  {
    key: ClientScanKey.OFI_FINAMAX,
    documentName: 'Ogólny formularz informacyjny o Pośredniku FINAMAX sp. z o.o.',
    type: ScanType.OFI,
    documentUrl: 'https://platforma.finpack.pl/finrate/static-info-files/Informacja_o_posredniku_kredytowym_Finamax_LENDI.pdf',
    condition: c => AuthService.User?.organisationId === OrganisationId.FINAMAX,
    required: (v: ClientAgreements) => v.acceptsReceivingInfoPartner,
  },
];
