<template>
  <v-list two-line class="bg-transparent">
    <v-list-item>
      <v-list-item-content v-if="date">
        <v-list-item-title style="font-size: 13px">
          <v-icon small>mdi-calendar-outline</v-icon>
          {{date | dateFormat}}
        </v-list-item-title>
        <v-list-item-title style="font-size: 13px" v-if="!onlyDate">
          <v-icon small>mdi-clock-outline</v-icon>
          {{date | timeFormat }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title>
          -
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class FpDateTimeView extends Vue {
  @Prop({required: true, })
  public readonly date!: Date;

  @Prop({default: false, })
  public readonly onlyDate!: boolean;
}
</script>
