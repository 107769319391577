import {Address} from '@/modules/multiForm/shared/MultiformModels';
import KnfInfoData from '@/modules/accredition/models/KnfInfoData';
import {Utils} from '@/services/utils/BasicUtils';
import {AccreditationStep} from '@/commons/enums/AccreditationStep';
import {CompanyType} from '@/modules/multiForm/shared/Types';

export interface CompanyDataDto {
  id?: number;
  nip: string;
  regon: string;
  type: string;
  name: string;
  address: Address;
  krs?: string;
  phone: string | null;
  divisionId?: number;
}

export type FullCompanyDataDTO = KnfInfoData & NullableDeep<CompanyDataDto>;

export class CompanyData extends KnfInfoData implements NullableDeep<CompanyDataDto> {
  id?: Nullable<number> = null;
  krs?: Nullable<string> = null;
  nip: Nullable<string> = null;
  regon: Nullable<string> = null;
  type: Nullable<CompanyType> = null;
  name: Nullable<string> = null;
  address: Address = new Address();
  phone: string | null = null;
  divisionId?: number;
  step?: AccreditationStep;
  isProductInsuranceSeller?: boolean;

  constructor(dto?: PartialDeep<FullCompanyDataDTO>) {
    super();
    if (dto) {
      if (!dto.address) {
        dto.address = new Address();
      }
      Utils.assign(this, dto);
    }
  }
}
