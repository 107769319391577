import LoanApplication, {LoanApplicationDTO} from '@/models/LoanApplication';
import Client, {ClientDTO} from '@/models/Client';
import Deal, {DealDTO} from '@/models/Deal';
import User from '@/models/user/User';
import {assign} from 'lodash-es';
import {UserDTO} from '@/models/user/UserDTO';

export interface ProspectSearchClientsInOrganisationsDTO {
  applications: Array<LoanApplicationDTO>;
  client: ClientDTO;
  owner: UserDTO;
  deals: Array<DealDTO>
}

export class ProspectSearchClientsInOrganisations implements ProspectSearchClientsInOrganisationsDTO {
  applications!: Array<LoanApplication>;
  client!: Client;
  deals!: Array<Deal>;
  owner!: User;

  constructor(dto?: ProspectSearchClientsInOrganisationsDTO) {
    if (dto) {
      if (dto.applications) {
        dto.applications = dto.applications.map(i => new LoanApplication(i));
      }
      if (dto.deals) {
        dto.deals = dto.deals.map(i => new Deal(i));
      }
      if (dto.client) {
        dto.client = new Client(dto.client);
      }
      if (dto.owner) {
        dto.owner = new User(dto.owner);
      }
      assign(this, dto);
    }
  }
}
