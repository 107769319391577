import {
  BackendBankModel,
  BankDictionaryElementDTO
} from '@/commons/dictionaries/financialInstitutions/FinancialInstitutions';
import {Utils} from '@/services/utils/BasicUtils';
import {useBankLogo} from '@/modules/banks/useBankLogo';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {mapObject} from 'underscore';

export function mapToBankDictionaryElementDTO(bank: BackendBankModel): BankDictionaryElementDTO {
  const data = {
    name_pl: bank.name,
    type: bank.symbol,
    ...bank,
  };
  return Utils.pick<BankDictionaryElementDTO>(data, 'id', 'name_pl', 'products', 'type', 'productsConfig', 'meta', 'enabled', 'logoUrl', 'representativeExamples', 'color');
}

export function mapToBackendBankModel(bank: BankDictionaryElementDTO): BackendBankModel {
  return Utils.pick<BackendBankModel>({
    symbol: bank.type,
    name: bank.name_pl,
    ...bank,
  }, 'id', 'name', 'products', 'symbol', 'productsConfig', 'meta', 'enabled', 'logoUrl', 'representativeExamples', 'color');
}

export class BankDictionaryElement extends BankDictionaryElementDTO {
  public get symbolRequire() {
    return useBankLogo(this.type, this.logoUrl);
  }

  public get is(): Record<BankSymbol, boolean> {
    return mapObject(BankSymbol as any, (bank: BankSymbol) => bank === this.type) as Record<BankSymbol, boolean>;
  }

  constructor(element?: BankDictionaryElementDTO) {
    super();
    if (element) {
      Utils.assign(this, element);
    }
  }
}
