import {ProductType} from '@/commons/enums/ProductType';
import {Languages} from '@/commons/enums/Languages';

export class Privileges {
  public isProductAdmin: boolean = false;
  public isBigAdmin: boolean = false;
  public isBokAdmin: boolean = false;
  public isAgentCC: boolean = false;
  public requireBankSecrecy: boolean = false;
  public isActionRequiredLocked: boolean = false;
  public hasAccessToSettlementView: boolean = false;

  public hasAccessToSalaryLevels: boolean = false;
  public hasAccessToProvisions: boolean = false;
  public hasAccessToNest: boolean = false;
  public hasAccessToRaiffeisen: boolean = false;
  public hasFilemanagerAccess: boolean = false;
  public hasExpiredActivities: boolean = false;
  public requireReporting: boolean = false;
  public requireAccreditation: boolean = false;

  public mortgageLoansDisabled?: boolean = true;
  public cashLoansDisabled?: boolean = true;
  public smeLoansDisabled?: boolean = true;

  public canEditProvisions: boolean = false;
  public canManageOrganisation: boolean = false;
  public hasAccessToExperts: boolean = false;
  public isHeadOfAccounting: boolean = false;
  public isRodoAvailable: boolean = false;
  public canSkipVerification: boolean = false;
  public canManageProspects: boolean = false;
  public canMeetOutsideOffice: boolean = false;
  public hasAccessToSmartney: boolean = false;
  public simplePartnerView: boolean = false;
  public hasAccessToCustomLogo: boolean = false;

  public isHrLocked = false;

  public isBetaTester = false;

  public hasAccessToLendiCareLead: boolean = false;
  public hasAccessToLendiCare: boolean = false;

  public canManage = {
    [ProductType.MORTGAGE]: true,
    [ProductType.CASH]: true,
    [ProductType.SME]: true,
    [ProductType.NONBANK]: false,
    [ProductType.REAL_ESTATE]: false,
    [ProductType.INSURANCE]: false,
    [ProductType.FACTORING]: false,
    [ProductType.INVESTMENT]: false,
    [ProductType.LEASING]: false,
    trainings: false,
    widgets: false,
  };

  public canSpeak: Record<Languages, boolean> = {[Languages.ENGLISH]: true, [Languages.GERMAN]: false,};
}
