<template>
  <v-card>
    <v-card-title>{{ modal.modalData.title }}</v-card-title>
    <v-divider/>
    <v-card-text class="pt-4">
      <p class="mb-1">Wpisz <b>{{ validationKey }}</b>, by potwierdzić operacje.</p>
      <fp-input autofocus-on-create v-model="userInputKey"/>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <fp-btn primary error large @click="modal.closeModal(true)"
              :disabled="userInputKey !== validationKey">
        {{ modal.modalData.trueText }}
      </fp-btn>
      <fp-btn large @click="modal.closeModal(false)">
        {{ modal.modalData.falseText }}
      </fp-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts" setup>
import {useModalInstance} from '@/composables/useModalInstance';
import {HardConfirmModalData} from '@/components/fpComponents/services/ModalService/hardConfirmModal/index';
import {ref} from 'vue';
import EnvironmentService from '@/env/EnvironmentService';

const appDomainConfig = EnvironmentService.Environment.getAppDomainConfig();
const validationKey: string = appDomainConfig.name.split('.')[0].toUpperCase();
const userInputKey = ref<string>('');
const modal = useModalInstance<HardConfirmModalData, boolean>();
</script>
<style scoped lang="scss">
</style>
