<template>
  <fp-field :keys="$props">
    <v-autocomplete
      v-if="items?.length > threshold"
      :class="{'fp-box-input-style': box, 'dirty-state': value && box, 'fp-input-dense': dense, 'fp-input-outline': outline, 'no-hint': !description,}"
      :items="items"
      :value="value"
      :menu-props="{offsetY: true}"
      @input="emitValue"
      :label="label"
      :search-input="searchInput"
      @update:search-input="syncSearchInput"
      :id="id"
      v-on="$listeners"
      :dense="dense"
      :outlined="outline"
      :filled="box"
      :filter="filter"
      :no-data-text="noDataText"
      :rules="rules"
      :required="required"
      :disabled="disabled"
      :single-line="!dense ? singleLine : true"
      :hint="description"
      @click:clear="clearCallback"
      :clearable="clearable"
      :loading="loading"
      :item-text="itemName"
      :prepend-icon="prependIcon"
      :item-value="itemKey === false ? null : itemKey"
      :return-object="returnObject || itemKey === false"
      :persistent-hint="!!description"
    >
      <template v-slot:item="{item}" v-if="$scopedSlots['item']">
        <slot name="item" :item="item" />
      </template>
      <template v-slot:selection="{item}" v-if="$scopedSlots['selection']">
        <slot name="selection" :item="item" />
      </template>
      <template v-slot:prepend-item v-if="$scopedSlots['prepend-item']">
        <slot name="prepend-item" />
      </template>
    </v-autocomplete>
    <v-select
      v-else
      v-on="$listeners"
      @click:clear="clearCallback"
      :class="{'fp-box-input-style': box, 'dirty-state': value && box, 'fp-input-dense': dense, 'fp-input-outline': outline,'no-hint': !description,}"
      :items="items"
      :value="value"
      :loading="loading"
      :menu-props="{'nudge-top': dense ? '-34px' : '-54px'}"
      @input="emitValue"
      :label="label"
      :id="id"
      :rules="rules"
      :dense="dense"
      :required="required"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :outlined="outline"
      :filled="box"
      :disabled="disabled"
      :clearable="clearable"
      :single-line="!dense ? singleLine : true"
      :hint="description"
      :item-text="itemName"
      :item-value="itemKey === false ? null : itemKey"
      :autocomplete="items?.length > threshold"
      :return-object="returnObject || itemKey === false"
      :persistent-hint="!!description"
    >
      <template v-slot:item="{item}" v-if="$scopedSlots['item']">
        <slot name="item" :item="item"/>
      </template>
      <template v-slot:selection="{item}" v-if="$scopedSlots['selection']">
        <slot name="selection" :item="item" />
      </template>
      <template v-slot:prepend-item v-if="$scopedSlots['prepend-item']">
        <slot name="prepend-item" />
      </template>
      <template #append-outer>
        <slot name="append-outer" />
      </template>
    </v-select>
  </fp-field>
</template>

<script>
import FpValidationMixin from '@/components/fpComponents/services/validationMixin';
import FpField from '@/components/fpComponents/FpField/FpField.vue';
import {VSelect, VAutocomplete} from 'vuetify/lib';

export default {
  name: 'FpSelect',
  mixins: [FpValidationMixin,],
  components: {VSelect, VAutocomplete,FpField,},
  props: {
    items: {
      required: true,
      default: () => [],
    },
    prependIcon: {
      default: undefined,
      type: String,
    },
    prependInnerIcon: {
      type: String,
      default: undefined,
    },
    noDataText: {
      type: String,
    },
    searchInput: {
      default: '',
      type: String,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    box: {
      default: () => false,
      type: Boolean,
    },
    outline: {
      default: () => true,
      type: Boolean,
    },
    randomDescription: {
      type: Boolean,
      default: true,
    },
    loading: {
      default: false,
      type: [Boolean, String,],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    itemKey: {
      type: String || Boolean,
      default: 'type',
    },
    itemName: {
      type: String || Function,
      default: 'name_pl',
    },
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        return itemText.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());
      },
    },
    threshold: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      searchText: '',
    };
  },
  methods: {
    clearCallback(value) {
      this.$emit('click:clear', value);
    },
    emitValue(value) {
      this.$emit('input', value);
    },
    syncSearchInput(value) {
      this.$emit('update:search-input', value);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep label::after {
  content: '' !important;
}
.no-hint:not(.v-input--has-state){
  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}
</style>
