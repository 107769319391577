import { ProspectLossReasonDictionaryType } from '@/types/Dictionaries';
import Partner from '@/models/Partner';
import { Tag } from '@/models/Discussion';
import DivisionGroup from '@/models/DivisionGroup';
import { BankBranch } from '@/commons/services/models/BankBranchesDictionary';
import { DocumentPeriod } from '../models/DocumentPeriod';
import {MultiDocumentCategory} from '@/components/checklist/models/MultiDocument/MultiDocumentCategory';
import {BankDictionaryElement} from '@/modules/banks/models/BankDictionaryElement';
import {District} from '@/commons/services/District';

export type BasicDictionary = { id: number, name: string, };

export interface DictionaryStoreState {
  prospectLossReasonDictionary: ProspectLossReasonDictionaryType[],
  partnerDictionary: Partner[],
  discussionTagsDictionary: Tag[],
  bankBranchesDictionary: BankBranch[],
  bankDictionary: BankDictionaryElement[],
  divisionsDictionary: DivisionGroup[],
  documentValidityPeriods: Array<BasicDictionary>,
  documentIncomeTypes: Array<BasicDictionary>,
  documentTypes: Array<BasicDictionary>,
  landsDictionary: Array<BasicDictionary>,
  districtsDictionary: Array<District>,
  documentPeriods: Array<DocumentPeriod>,
  multiDocumentCategoryDictionary: MultiDocumentCategory[]
}

export const NonAuthDictionaryKeys: (keyof DictionaryStoreState)[] = [
  'landsDictionary', 'districtsDictionary', 'bankDictionary', 'documentIncomeTypes', 'documentValidityPeriods', 'documentTypes', 'documentPeriods', 'prospectLossReasonDictionary',
];

export const RestrictedDictionaryKeys: (keyof DictionaryStoreState)[] = [
  'divisionsDictionary',
];
