import {Quizzes} from '@/models/user/Quizzes';

export enum QuizUserRequire {
  OSH = 'requireOsh',
  KNF = 'requireKnf',
  RODO = 'requireRodo',
  CYBER = 'requireCyber',
  AML = 'requireAml',
  STMT = 'requireStmt',
}

export enum QuizType {
  KNF = 'KNF',
  RODO = 'RODO',
  CYBER = 'CYBER',
  AML = 'AML',
  OSH = 'BHP',
  STMT = 'STMT',
}

export enum QuizTypeName {
  OSH = 'BHP',
  KNF = 'KNF',
  RODO = 'RODO',
  CYBER = 'Cyberbezpieczeństwo',
  AML = 'AML'
}

export enum QuizCategory {
  KNF = 'knf',
  RODO = 'rodo',
  CYBER = 'cyber',
  AML = 'aml',
  OSH = 'osh',
  STMT = 'stmt',
}

export type QuizzesRequire = {
  [K in QuizCategory as `require${Capitalize<K>}`]: boolean;
}

export type QuizzesRequireHrLockAt = {
  [K in QuizCategory as `require${Capitalize<K>}HrLockAt`]?: Date;

}
export const NotInstantBlockQuizzes: (keyof QuizzesRequire)[] = ['requireStmt',];
