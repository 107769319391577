import EnvironmentService from '@/env/EnvironmentService';
import User from '@/models/user/User';
import AuthService from '@/modules/user/AuthService';
import {IIntegrable} from '@/models/interfaces/IIntegrable';

const IS_TAG_SERVICE_ON = EnvironmentService.Environment.isProd;
const API_KEY = 'GTM-K49P9C3';
const baseUrl = 'https://www.googletagmanager.com/gtm.js?id=' + API_KEY;

class TagService implements IIntegrable {
  private get DataLayer(): any[] | undefined {
    return (<any>window).dataLayer;
  }

  private set DataLayer(newValue: any[] | undefined) {
    (<any>window).dataLayer = newValue;
  }

  public connect() {
    if (IS_TAG_SERVICE_ON) {
      const SCRIPT_TAG_KEY = 'script';
      const foundScriptNode = document.getElementsByTagName(SCRIPT_TAG_KEY)[0];
      const newScriptTag = document.createElement(SCRIPT_TAG_KEY);
      this.DataLayer = this.DataLayer || [];
      this.DataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js', });
      newScriptTag.async = true;
      newScriptTag.src = baseUrl;
      if (foundScriptNode.parentNode !== null) {
        foundScriptNode.parentNode.insertBefore(newScriptTag, foundScriptNode);
      }
    } else {
      console.warn('Tag service is off');
    }
  }

  public setEvent(eventName: string, eventValue?: any) {
    if (this.DataLayer) {
      this.DataLayer.push({event: eventName, ...eventValue, });
    }
  }
}

export default new TagService();
