import {ref} from 'vue';
import EnvironmentService from '@/env/EnvironmentService';
import AuthService from '@/modules/user/AuthService';
import {OrganisationId} from '@/models/enums/OrganisationId';

export enum CalculatorVersion {
  V1 = 0,
  V2 = 1,
}
const version = ref<CalculatorVersion>(CalculatorVersion.V2);

const permittedOrganisations: readonly OrganisationId[] = [
  OrganisationId.LENDI,
  OrganisationId.FINAMAX,
  OrganisationId.DSA,
] as const;

const isNewCalculatorAvailable = (): boolean =>
  Boolean(AuthService.User?.isActive && (EnvironmentService.Environment.isAppDomain().NESTO_RO ||
    permittedOrganisations.includes(AuthService.User?.organisationId!) ||
    EnvironmentService.Environment.isPreprodOrDev));

export const useCalculatorVersion = () => {
  return {
    isNewCalculatorAvailable,
    version,
  };
};
