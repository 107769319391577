import {Route, RouteConfig} from 'vue-router';
import EnvironmentService from '@/env/EnvironmentService';

export default function useModuleGuardService() {
  const extractParentPath = (path: string) => {
    // Remove any leading or trailing slashes
    path = path.replace(/^\/|\/$/g, '');
    const pathElements = path.split('/');
    const excludedPaths = ['app', 'v2', '', '#', ' ', 'v1',];
    return pathElements.find(element => !excludedPaths.includes(element));
  };

  const checkIfExcluded = (route: Route | RouteConfig) => {
    return EnvironmentService.Environment.hasExcludedModule(route);
  };

  const checkIfExcludedModule = (to: Route, appRoutes: Array<RouteConfig>) => {
    if (to.matched.length) {
      const parentPath = extractParentPath(to.matched.slice(-1)[0].path);
      const parentRoute = appRoutes.find(route => route.path === parentPath);
      return (parentRoute ? checkIfExcluded(parentRoute) || checkIfExcluded(to) : checkIfExcluded(to));
    } else return checkIfExcluded(to);
  };

  return {checkIfExcludedModule,};
}
