import DateUtils from '@/services/utils/DateUtils';
import moment from 'moment';
import {Utils} from '@/services/utils/BasicUtils';

export class DocumentPeriod {
  public id!: number;
  public displayedName!: string;
  public ordinal!: number;

  public get timeRange() {
    if (!Number.isNaN(Number(this.displayedName.split(' ')[0]))) {
      return DateUtils.getDatesRange(new Date(), moment(new Date()).subtract(Number(this.displayedName.split(' ')[0]), 'months'));
    } else if (this.id === 4) {
      return DateUtils.getDatesRange(moment().startOf('year'), moment(new Date()));
    } else if (this.id === 5) {
      return DateUtils.getDatesRange(moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year'));
    } else if (this.id === 6) {
      return DateUtils.getDatesRange(moment().subtract(2, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year'));
    } else if (this.id === 7) {
      return DateUtils.getDatesRange(moment().subtract(3, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year'));
    } else if (this.id === 8) {
      return DateUtils.getDatesRange(moment().subtract(1, 'year').startOf('year'), moment(new Date()));
    } else if (this.id === 9) {
      return DateUtils.getDatesRange(moment().subtract(2, 'year').startOf('year'), moment(new Date()));
    } else if (this.id === 10) {
      return DateUtils.getDatesRange(moment().subtract(3, 'year').startOf('year'), moment(new Date()));
    } else if (this.id === 11) {
      return DateUtils.getDatesRange(moment().startOf('year'), moment(new Date()).month(6).endOf('month'));
    } else {
      return null;
    }
  }

  constructor(dto: { id: number, displayedName: string }) {
    Utils.assign(this, dto);
  }
}
