import {Utils} from '@/services/utils/BasicUtils';

export abstract class RelianceStepsDTO {
  id: number = 0;
  leafletPassing: boolean = false;
  companyPresentation: boolean = false;
  selfPresentation: boolean = false;
  clientNeedsAnalysis: boolean = false;
  processPresentation: boolean = false;
  clarificationOfObjections: boolean = false;
  meetingSummary: boolean = false;
  nextStepsPresentation: boolean = false;
  recommendationProcessPresentation: boolean = false;
}

export default class RelianceSteps extends RelianceStepsDTO {
  constructor(relianceStepsDTO?: RelianceStepsDTO) {
    super();
    if (relianceStepsDTO) {
      Utils.assign(this, relianceStepsDTO);
    }
  }
}
