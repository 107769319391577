export enum WidgetType {
  LightComparerWidget = 'LightComparerWidget',
  BaseInstallmentPicker = 'BaseInstallmentPicker',
  MeetingPlannerWidget = 'MeetingPlannerWidget',
  NaviboxBanner = 'NaviboxBanner',
  HalfPageBanner = 'HalfPageBanner',
  WideboardBanner = 'WideboardBanner',
  TextUrls = 'TextUrls',
  AffiliateLinks = 'AffiliateLinks',
  CreditWorthinessWidget = 'CreditWorthinessWidget',
  CreditWorthinessOffersWidget = 'CreditWorthinessOffersWidget',
  mbwWidget = 'mbwWidget',
  RefinanceWidget = 'RefinanceWidget',
  SafeLoanWidget = 'SafeLoanWidget',
  FlatForStartWidget = 'FlatForStartWidget',
  WidgetViewInfo = 'WidgetViewInfo',
  SimulationFormSafeLoan = 'SimulationFormSafeLoan',
  SimulationFormFlatForStart = 'SimulationFormFlatForStart',
  SimulationFormMortgage = 'SimulationFormMortgage',
  SafeLoanFormWidget = 'SafeLoanFormWidget',
  FlatForStartFormWidget = 'FlatForStartFormWidget',
  MortgageComparerFormWidget = 'MortgageComparerFormWidget',
}
