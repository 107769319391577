import {IPerson} from '@/models/interfaces/IPerson';
import {omit} from 'underscore';
import {Utils} from '@/services/utils/BasicUtils';
import EnvironmentService from '@/env/EnvironmentService';
import {AppDomain} from '@/env/enums/Domains';
import {Gender} from '@/models/enums/Gender';

/* TODO: Propozycja zmian, 3 modele Person któe ma tylko firstName, lastName i name, Wersja z peselem oraz wersja z danymi kontakowymi phone i email */
export interface PersonName {
   firstName: string,
   lastName: string
}
export class PersonDto {
  public id?: number;
  public firstName: Nullable<string> = null;
  public lastName: Nullable<string> = null;
  public pesel: Nullable<string> = null;
  public phone: Nullable<string> = null;
  public email: Nullable<string> = null;
  public isCompany?: boolean = false;
  public name?: string | null;
  public gender?: Nullable<Gender>;
  public photoUrl?: Nullable<string> = null;
}

export class Person extends PersonDto implements IPerson {
  public id!: number;

  public get displayName(): string {
    return (this.firstName || this.lastName)
      ? `${this.firstName} ${this.lastName || ''}`
      : this.name || '';
  }

  public get displayNameWithId(): string {
    return `${this.displayName} #${this.id}`;
  }

  // @ts-ignore
  public get gender(): Gender | null {
    return this.pesel ? EnvironmentService.Environment.getAppDomainConfig()
      .personalIdNumber.utils.gender(this.pesel) : null;
  }

  public get isMale(): boolean {
    return this.gender === Gender.MALE;
  }

  public get isFemale(): boolean {
    return this.gender === Gender.FEMALE;
  }

  public get initials(): string {
    return this
      .displayName
      .split(' ')
      .map(el => el[0])
      .join('')
      .toUpperCase();
  }

  public get avatarUrl(): Nullable<string> {
    if (this.photoUrl) {
      const size = 128;
      const separator = '/upload/';
      const url = this.photoUrl.split(separator);
      return `${url[0]}${separator}w_${size},h_${size},q_100,c_thumb/${url[1]}`;
    } else if (this.isCompany) {
      return require('@/assets/img/company.svg');
    } else {
      return this.gender
        ? this.isMale
          ? require('@/assets/img/crew/m.svg')
          : require('@/assets/img/crew/k.svg')
        : null;
    }
  }

  constructor(dto?: PersonDto) {
    super();
    if (dto) {
      const GENDER_KEY: keyof Person = 'gender';
      if (dto.gender) {
        Object.defineProperty(this, GENDER_KEY, {
          get: () => dto.gender,
        });
      }
      Utils.assign(this, omit(dto, GENDER_KEY));
    }
  }
}
