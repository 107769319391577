import {EnvironmentEnum} from '@/env/enums/EnvironmentMode';

interface FeaturesToggles {
  temporaryHide: boolean
  isNotificationOn: boolean
  isBikOn: boolean
  isDiscussionOn: boolean
  isSendAttachmentsToClientsOn: boolean
  isProspectsOn: boolean
  isTagServiceOne: boolean
  isUserComServiceOne: boolean
  isVerificationOn: boolean
  isNewDistrictsModel: boolean
  isSentryOn: boolean
  isInternationalNumber: boolean
  isBlockAccessOn: boolean
  isMultipleBorrowers: boolean
  isXLSXImportActive: boolean
  multiformPercentage: boolean
  activitiesFlow: boolean
  isSendingDocumentsToDeveloper: boolean,
  canEditProcedure: boolean,
  canEditRegulation: boolean,
  isFinpackClientAvailable: boolean,
  isBackendSimV2: boolean,
  isBusinessCardsAvailble: boolean,
  isBusinessCardsAvailbleForUser: boolean,
  isBanksPermissionsAvailble: boolean,
  isUserDataV2Available: boolean,
}

const EnvironmentFeatures: Record<EnvironmentEnum, FeaturesToggles> = {
  DEV: {
    temporaryHide: true,
    isNotificationOn: true,
    isBikOn: true,
    isDiscussionOn: true,
    isSendAttachmentsToClientsOn: true,
    isProspectsOn: true,
    isTagServiceOne: false,
    isUserComServiceOne: false,
    isVerificationOn: true,
    isNewDistrictsModel: true,
    isSentryOn: false,
    isInternationalNumber: true,
    isBlockAccessOn: true,
    isMultipleBorrowers: true,
    isXLSXImportActive: true,
    multiformPercentage: true,
    activitiesFlow: false,
    isSendingDocumentsToDeveloper: true,
    canEditRegulation: true,
    canEditProcedure: true,
    isFinpackClientAvailable: true,
    isBackendSimV2: false,
    isBusinessCardsAvailble: true,
    isBusinessCardsAvailbleForUser: false,
    isBanksPermissionsAvailble: true,
    isUserDataV2Available: true,
  },
  PREPROD: {
    temporaryHide: true,
    isNotificationOn: true,
    isBikOn: true,
    isDiscussionOn: true,
    isSendAttachmentsToClientsOn: true,
    isProspectsOn: true,
    isTagServiceOne: true,
    isUserComServiceOne: false,
    isNewDistrictsModel: true,
    isVerificationOn: true,
    isSentryOn: false,
    isInternationalNumber: true,
    isBlockAccessOn: true,
    isMultipleBorrowers: true,
    isXLSXImportActive: true,
    multiformPercentage: false,
    activitiesFlow: false,
    isSendingDocumentsToDeveloper: true,
    canEditRegulation: true,
    canEditProcedure: true,
    isFinpackClientAvailable: true,
    isBackendSimV2: false,
    isBusinessCardsAvailble: true,
    isBusinessCardsAvailbleForUser: false,
    isBanksPermissionsAvailble: false,
    isUserDataV2Available: true,
  },
  PROD: {
    temporaryHide: false,
    isNotificationOn: true,
    isBikOn: true,
    isDiscussionOn: true,
    isSendAttachmentsToClientsOn: true,
    isProspectsOn: true,
    isTagServiceOne: true,
    isUserComServiceOne: true,
    isVerificationOn: true,
    isNewDistrictsModel: false,
    isSentryOn: true,
    isInternationalNumber: true,
    isBlockAccessOn: true,
    isMultipleBorrowers: true,
    isXLSXImportActive: true,
    multiformPercentage: false,
    activitiesFlow: false,
    isSendingDocumentsToDeveloper: true,
    canEditRegulation: true,
    canEditProcedure: true,
    isFinpackClientAvailable: false,
    isBackendSimV2: false,
    isBusinessCardsAvailble: true,
    isBusinessCardsAvailbleForUser: false,
    isBanksPermissionsAvailble: false,
    isUserDataV2Available: true,
  },
};

export default EnvironmentFeatures;
