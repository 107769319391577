export type TourColour = `#${string}`;
export interface TourTheme {
  TourColour: TourColour,
  TourBorderColour: TourColour,
}
export const TOUR_COLOUR_CONST = '--tour-colour' as const;
export const TOUR_BORDER_COLOUR_CONST = '--tour-border-colour' as const;
export const defaultColourTheme: TourTheme = {
  TourColour: '#0A73EB33',
  TourBorderColour: '#0A73EB',
} as const;
export const navigationMenuTheme: TourTheme = {
  TourColour: '#fa726833',
  TourBorderColour: '#fa7268',
} as const;
export const navigationMenuSecondTheme: TourTheme = {
  TourColour: '#93c5fd4d',
  TourBorderColour: '#93c5fd4d',
} as const;
export enum Themes {
  DEFAULT_COLOUR_THEME = 'DEFAULT_COLOUR_THEME',
  NAVIGATION_MENU_THEME = 'NAVIGATION_MENU_THEME',
  NAVIGATION_MENU_SECOND_THEME = 'NAVIGATION_MENU_SECOND_THEME',
}
export const TourThemes: Record<Themes,TourTheme> = {
  [Themes.DEFAULT_COLOUR_THEME]: defaultColourTheme,
  [Themes.NAVIGATION_MENU_THEME]: navigationMenuTheme,
  [Themes.NAVIGATION_MENU_SECOND_THEME]: navigationMenuSecondTheme,
};
export default function useOnboardingTheme() {
  const root = document.documentElement;
  const addThemeToRoot = (theme: TourTheme = defaultColourTheme) => {
    const {
      TourColour,
      TourBorderColour,
    } = theme;
    root.style.setProperty(TOUR_COLOUR_CONST, TourColour);
    root.style.setProperty(TOUR_BORDER_COLOUR_CONST, TourBorderColour);
  };
  const changeStepHighlightColour = (highlightTheme?: TourTheme) => {
    addThemeToRoot(highlightTheme);
  };

  return {changeStepHighlightColour,};
}
