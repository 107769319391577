import Regulation, {RegulationDTO} from '../../../models/Regulation';
import {FpAxios} from 'fp-components';
import RegulationStore from '@/modules/regulation/services/store/RegulationStore';
import {FiltersResponse, RegulationFilter} from '@/models/Filter';
import {wait} from '@/services/utils/BasicUtils';

class RegulationApi {
  private regulationAxios = FpAxios.create();
  readonly baseUrl = 'regulations';

  constructor() {
    this.regulationAxios.interceptors.response.use(
      (response: any) => {
        RegulationStore.setRegulationApiActive(true);
        return Promise.resolve(response);
      },
      (error: any) => {
        RegulationStore.setRegulationApiActive(false);
        return Promise.reject(error);
      }
    );
  }

  async getRegulation(id: number): Promise<Regulation> {
    const response = await this.regulationAxios.get<Regulation>(`${this.baseUrl}/${id}`);
    return new Regulation(response.data);
  }

  async editRegulation(regulation: Regulation): Promise<Regulation> {
    const response = await this.regulationAxios.put<Regulation>(`${this.baseUrl}/${regulation.id}`, regulation);
    return new Regulation(response.data);
  }

  async deleteRegulation(regulation: Regulation): Promise<void> {
    await this.regulationAxios.delete(`${this.baseUrl}/${regulation.id}`);
  }

  async addRegulation(regulation: Regulation): Promise<Regulation> {
    const response = await this.regulationAxios.post<Regulation>(`${this.baseUrl}`, regulation);
    return new Regulation(response.data);
  }

  async getRegulations(): Promise<Regulation[]> {
    const response = await this.regulationAxios.get<Array<RegulationDTO>>(`${this.baseUrl}/all`);
    return response.data?.map((regulation: RegulationDTO) => new Regulation(regulation)) || [];
  }

  async filterRegulations(filters?: RegulationFilter): Promise<FiltersResponse<Regulation>> {
    const response = await this.regulationAxios.post<FiltersResponse<Regulation>>(`${this.baseUrl}/search`, filters ? filters.apiData : {});
    response.data.items = (Object.values(response.data)[0] as Array<RegulationDTO>)
      .map((regulationDTO: RegulationDTO) => new Regulation(regulationDTO));
    return response.data;
  }

  async findForDashboard(): Promise<Regulation[]> {
    const filters = new RegulationFilter();
    filters.page = 1;
    filters.rowsPerPage = 5;
    filters.sortBy = [ 'createdDate', ];
    const response = await this.regulationAxios.post<FiltersResponse<Regulation>>(`${this.baseUrl}/search`, filters.apiData);
    response.data.items = (Object.values(response.data)[0] as Array<RegulationDTO>)
      .map((regulationDTO: RegulationDTO) => new Regulation(regulationDTO));
    return response.data.items;
  }

  async addToFavorites(regulationId: number): Promise<void> {
    await this.regulationAxios.put<void>(`${this.baseUrl}/favorite/${regulationId}`);
  }

  async deleteFromFavorites(regulationId: number): Promise<void> {
    await this.regulationAxios.delete(`${this.baseUrl}/favorite/${regulationId}`);
  }
}

export default new RegulationApi();
