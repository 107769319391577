<template>
  <v-container grid-list-lg>
    <v-layout wrap v-if="!delegateForm" class="pb-4">
      <v-flex xs12>
        <v-layout wrap>
          <template v-if="value.isCompany">
              <fp-input prepend-icon="business"
                        @input="fieldChanged"
                        v-model.trim="value.name"
                        :clearable="false"
                        required
                        :id="$cy.clientCompanyName"
                        :label="I18NGetter().useEditClientForm.COMPANY_NAME"/>
          </template>
          <template v-else>
            <fp-input
                xs12 md6
                prepend-icon="person"
                :id="$cy.clientFirstName"
                :clearable="false" @input="fieldChanged"
                v-model.trim="value.firstName"
                required
                :label="I18NGetter().useEditClientForm.FIRST_NAME"
                ref="clientInfoRef"
                :class="$onboarding.tours.LEAD.step.CLIENT_INFO"
            />
            <fp-input
                xs12 md6
                :prepend-icon="$vuetify.breakpoint.smAndDown ? 'none' : undefined"
                :clearable="false" @input="fieldChanged"
                :id="$cy.clientLastName"
                :class="[{'padding-mobile': $vuetify.breakpoint.mdAndDown}, $onboarding.tours.LEAD.step.CLIENT_INFO]"
                v-model.trim="value.lastName"
                required
                :label="I18NGetter().useEditClientForm.LAST_NAME" />
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-if="value.isCompany">
        <v-layout wrap>
          <fp-input
              :id="$cy.clientNip"
              nip
              mask="### ### ## ##"
              prepend-icon="none"
              :clearable="false"
              ref="fp_nip"
              v-model.trim="value.nip"
              :errorMessages="fieldsApiValidation.nip"
              required
              xs12 md6
              @input="findRegon(value.nip)"
              @blur="findIfParamExists('nip', value.nip)"
              :label="I18NGetter().useEditClientForm.NIP">
            <template v-if="fieldsApiValidation.nip" v-slot:message>
              <span>
                {{ I18NGetter().useEditClientForm.CLIENT_NIP_ALREADY_EXISTS }}
                <slot name="customErrorAction" :client="existingClient" :clientId="fieldsApiValidation.nip"/>
              </span>
            </template>
          </fp-input>
          <v-flex xs12 v-if="fieldsApiValidation.nip && showClientOwnerInfoCard" class="pl-10">
            <client-owner-info-card
                :client="existingClient"
                @click:goToClientDetails="$emit('closeModal')"
                :message="I18NGetter().useEditClientForm.PERSON_NIP_ALREADY_EXISTS"/>
          </v-flex>
        </v-layout>
        <v-layout>
          <fp-input
              :id="$cy.clientRegon"
              xs12 md6
              regon
              :label="I18NGetter().useEditClientForm.REGON"
              prepend-icon="none"
              v-model.trim="value.regon"
              @input="fieldChanged"/>
        </v-layout>
      </v-flex>
      <fp-input
          v-else
          xs12 md6
          v-model.trim="value.pesel"
          ref="fp_pesel"
          pesel
          :customRules="[() => !fieldsApiValidation?.pesel]"
          :errorMessages="fieldsApiValidation?.pesel ?? ''"
          @input="fieldChanged"
          :required="peselRequired"
          :id="$cy.clientPesel"
          prepend-icon="none"
          @blur="findIfParamExists('pesel', value.pesel)"
          :label="
          $i18nFormatter(I18NGetter().useEditClientForm.NEW_TRANSLATION_PESEL,
           {personalIdNumberType: $env.getAppDomainConfig().personalIdNumber.type,})"
          >
        <template v-if="fieldsApiValidation.pesel" v-slot:message>
          <span>
           {{
              $i18nFormatter(I18NGetter().useEditClientForm.NEW_TRANSLATION_CLIENT_PESEL_ALREADY_EXISTS,
                {personalIdNumberType: $env.getAppDomainConfig().personalIdNumber.type,})
            }}
            <v-menu
              v-if="$scopedSlots.customErrorAction && showLink"
              open-on-hover
              offset-y
              bottom
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <slot name="customErrorAction" :client="existingClient" :clientId="fieldsApiValidation.pesel"/>
                </span>
              </template>
              <v-card>
                <v-card-text>
                  <client-badge :client="existingClient" :readonly="true"></client-badge>
                </v-card-text>
              </v-card>
            </v-menu>
          </span>
        </template>
      </fp-input>
      <v-flex xs12 v-if="fieldsApiValidation.pesel && showClientOwnerInfoCard" class="pl-10">
        <client-owner-info-card
            :client="existingClient"
            @click:goToClientDetails="$emit('closeModal')"
            :message="$i18nFormatter(I18NGetter().useEditClientForm.NEW_TRANSLATION_PERSON_PESEL_ALREADY_IS_CLIENT,
             {personalIdNumberType: $env.getAppDomainConfig().personalIdNumber.type},)"
        />
      </v-flex>
      <v-flex xs12>
          <fp-international-number
          ref="fp_phone"
            prepend-icon="none"
            :input-id="$cy.clientPhoneNumber"
            @input="fieldChanged"
            :errorMessages="phoneErrorMessage || fieldsApiValidation.phone"
            @blur="findIfParamExists('phone', value.phone)"
            @change="validatePhone"
            required
            v-model="value.phone"
            :class="$onboarding.tours.LEAD.step.CLIENT_INFO">
            <template #customErrorAction v-if="fieldsApiValidation.phone">
          <span>
           {{I18NGetter().useEditClientForm.CLIENT_PHONE_NUMBER_ALREADY_EXISTS }}
            <v-menu
              v-if="$scopedSlots.customErrorAction && showLink"
              open-on-hover
              offset-y
              bottom
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <slot name="customErrorAction" :client="existingClient" :clientId="fieldsApiValidation.phone"/>
                </span>
              </template>
              <v-card>
                <v-card-text>
                  <client-badge :client="existingClient" :readonly="true"></client-badge>
                </v-card-text>
              </v-card>
            </v-menu>
          </span>
            </template>
          </fp-international-number>
      </v-flex>
      <v-flex xs12 v-if="fieldsApiValidation.phone && showClientOwnerInfoCard" class="pl-10">
        <client-owner-info-card
            :client="existingClient"
            @click:goToClientDetails="$emit('closeModal')"
            :message="I18NGetter().useEditClientForm.PERSON_PHONE_NUMBER_ALREADY_IS_CLIENT"/>
      </v-flex>
      <fp-input
          xs12 md6
          v-model.trim="value.email"
          prepend-icon="email"
          ref="fp_email"
          required
          :id="$cy.clientEmail"
          email
          :customRules="[() => !fieldsApiValidation.email]"
          @input="fieldChanged"
          :errorMessages="fieldsApiValidation.email"
          @blur="findIfParamExists('email', value.email)"
          label="Email"
          :class="$onboarding.tours.LEAD.step.CLIENT_INFO"
        >
        <template v-slot:message v-if="fieldsApiValidation.email">
          <span>
            {{ I18NGetter().useEditClientForm.CLIENT_WITH_THAT_EMAIL_ALREADY_EXISTS }}
            <v-menu
              v-if="$scopedSlots.customErrorAction && showLink"
              open-on-hover
              offset-y
              bottom
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <slot name="customErrorAction" :client="existingClient" :clientId="fieldsApiValidation.email"/>
                </span>
              </template>
              <v-card>
                <v-card-text>
                  <client-badge :client="existingClient" :readonly="true"></client-badge>
                </v-card-text>
              </v-card>
            </v-menu>
          </span>
        </template>
      </fp-input>
      <v-flex xs12 v-if="fieldsApiValidation.email && showClientOwnerInfoCard" class="pl-10">
        <client-owner-info-card
            :client="existingClient"
            @click:goToClientDetails="$emit('closeModal')"
            :message="I18NGetter().useEditClientForm.PERSON_WITH_THAT_EMAIL_ALREADY_IS_CLIENT"/>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col cols="12">
        <fp-checkbox
          v-model="checkAll"
          @input="onCheckAllChange"
          :label="checkAll ? I18NGetter().useUserInputPanel.UNCHECK_ALL : I18NGetter().useUserInputPanel.CHECK_ALL"
          label-fade
          class="pa-0 ma-0"
          :class="$onboarding.tours.LEAD.step.FILL_AGREEMENTS"
          ref="fillAgreementsRef"
        >
          <template #label="{ label }">
            <span class="body-2 py-2 text-justify" v-html-safe="label"></span>
          </template>
        </fp-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="headline-4">{{ I18NGetter().useEditClientForm.REQUIRED_AGREEMENTS }}</span>
      </v-col>
      <v-col
        v-for="agreement in requiredAgreements"
        cols="12"
        :key="agreement.key"
      >
        <h3 class="all-caps-header pb-2 body-2">{{agreement.title}}</h3>
        <fp-checkbox
            :id="agreement.key"
            label-fade
            class="pa-0 ma-0"
            :disabled="clientAgreements[agreement.key] && !agreement.isEditable && isEdit"
            v-model="clientAgreements[agreement.key]"
            :required="agreement.required(clientAgreements)"
            @input="agreementChanged(agreement.key)"
            :label="agreement.label">
          <template #label="{ label }">
            <span class="body-2 py-2 text-justify" v-html-safe="label"></span>
          </template>
        </fp-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="additionalAgreements.length" class="mt-2">
      <v-col cols="12">
        <span class="headline-4">{{ I18NGetter().useEditClientForm.ADDITIONAL_AGREEMENTS }}</span>
      </v-col>
      <v-col
        v-for="agreement in additionalAgreements"
        cols="12"
        :key="agreement.key">
        <h3 class="all-caps-header pb-2 body-2">{{agreement.title}}</h3>
        <fp-checkbox
            :id="agreement.key"
            label-fade
            class="pa-0 ma-0"
            :disabled="clientAgreements[agreement.key] && !agreement.isEditable && isEdit"
            v-model="clientAgreements[agreement.key]"
            :required="agreement.required(value.agreements)"
            @input="agreementChanged(agreement.key)"
            :label="agreement.label">
          <template #label="{ label }">
            <span class="body-2 py-2 text-justify" v-html-safe="label"></span>
          </template>
        </fp-checkbox>
      </v-col>
      <template v-if="$user.privileges.requireBankSecrecy && !isEdit">
        <v-flex
          xs12>
          <h3 class="all-caps-header pt-6 pb-2 body-2">{{ I18NGetter().useEditClientForm.BANK_SECRECY }}</h3>
          <fp-checkbox
            label-fade
            class="pa-0 ma-0"
            v-model="value.checkedBankSecrecySentEmailApprove"
            >
            <template #label>
              <span class="body-2 py-2 text-justify">{{I18NGetter().useEditClientForm.BANK_SECRECY_EMAIL_AGREEMENT}}</span>
            </template>
          </fp-checkbox>
        </v-flex>
      </template>
      <v-col>
        <a class="d-block primary--text"
           :href="gpdrAgreement?.url"
           target="_blank">{{ gpdrAgreement?.title }}</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import Client from '@/models/Client';
import ClientApi from '@/modules/client/services/ClientApi';
import ClientRodo from '@/components/ClientRodo.vue';
import {
  Agreements,
  AgreementViews,
  ClientAgreements,
  IAgreementItemViewModel
} from '@/modules/client/views/client-agreements/Agreements';
import {clone} from '@/services/utils/BasicUtils';
import MultiformApi from '@/modules/multiForm/services/MultiformApi';
import {ProductType} from '@/commons/enums/ProductType';
import { cloneDeep } from 'lodash-es';
import ClientOwnerInfoCard from '@/modules/client/views/ClientOwnerInfoCard.vue';
import User from '@/models/user/User';
import AuthService from '@/modules/user/AuthService';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {ComponentPublicInstance, computed, onMounted, ref, watch} from 'vue';
import useGeoAreas from '@/composables/useGeoAreas';
import {useTour} from '@/composables/useTour';
import {useInstance} from '@/composables/useInstance';
import EnvironmentService from '@/env/EnvironmentService';
import ValidationApi from '@/commons/services/ValidationApi';

const props = withDefaults(defineProps< {
  value: Client;
  delegateForm?: boolean;
  delegationGoal?: ProductType;
  isEdit?: boolean;
  peselRequired?: boolean;
  dirty?: boolean;
  fieldsApiValidation?: Record<string, string | null | boolean>;
}>(), {
  isEdit: false,
  peselRequired: false,
  dirty: false,
});

const { $refs, $errorHandler, $user, $modalService, $snackbarService, $env, } = useInstance();
const geoAreas = useGeoAreas();
const keysChanged = ref<(keyof ClientAgreements)[]>([]);
const gpdrAgreement = EnvironmentService.Environment.getGPDRDomainAgreementsWithUrl();

const clientAgreements = ref<Agreements>(props.delegationGoal ? new Agreements() : cloneDeep(props.value.agreements));

const clientCopy = ref<Nullable<any>>(null);
const existingClient = ref<Nullable<Client>>(null);
const clientOwner = ref<Nullable<User>>(null);
const checkAll = ref<Nullable<boolean>>(null);

const emit = defineEmits<{
  (e: 'update:fieldsApiValidation', event: any): void,
  (e: 'update:dirty', event: any): void,
  (e: 'update:agreement', event: any): void,
  (e: 'update:agreement'): void,
}>();

const fp_phone = ref(null)
const phoneErrorMessage = ref<String>('');

async function validatePhone(): Promise<void> {
  const isValid = (fp_phone.value as any).validate();

  if (!isValid) {
    return;
  }

  try {
    const isRealNumber = await ValidationApi.phone(props.value.phone as string);
    phoneErrorMessage.value = '';
    if (!isRealNumber) {
      $snackbarService.openErrorSnackbar(I18NGetter().fpComponents.PHONE_CUSTOM_RULES_MSG);
      phoneErrorMessage.value = I18NGetter().fpComponents.PHONE_CUSTOM_RULES_MSG;
    }
  } catch (e) {
    $errorHandler(e, I18NGetter().fpComponents.PHONE_CUSTOM_RULES_MSG);
    phoneErrorMessage.value = I18NGetter().fpComponents.PHONE_CUSTOM_RULES_MSG;
  }
}

const findIfParamExists = async(key: keyof Client, value: Nullable<string>) => {
  if (value && (clientCopy.value === null || clientCopy.value[key] !== value)) {
    const field = ($refs[`fp_${key}`] as any);
    if (props.fieldsApiValidation) {
      props.fieldsApiValidation[key] = null;
    }
    const validField: boolean = field.valid || field?.validate();
    if (value.length && validField) {
      const properties: Record<string, string | boolean> = {};
      properties[key] = value;
      const validationKeys = ['email', 'phone',];
      if (validationKeys.includes(key)) {
        properties.isIndividual = !props.value.isCompany;
      }
      try {
        existingClient.value = await ClientApi.clientsSearch(properties);
        if (existingClient.value?.id && props.fieldsApiValidation) {
          props.fieldsApiValidation[key] = String(existingClient.value?.id);
          emit('update:fieldsApiValidation', props.fieldsApiValidation);
        }
      } catch (e) {
        $errorHandler(e);
        return false;
      }
    }
  }
};

const validatePhoneNumberAndEmail = () => {
  findIfParamExists('email', props.value.email);
  findIfParamExists('phone', props.value.phone);
};
watch(() => props.value.isCompany, validatePhoneNumberAndEmail);

const agreements = computed<IAgreementItemViewModel[]>(() => {
  let agreementsList = AgreementViews.filter(agreement => agreement.key !== 'acceptsBigInquiry');
  if (!props.delegationGoal && !$user.value.isRealEstateUser) {
    agreementsList = agreementsList.filter(x => !x.isRealEstate);
  }
  if ($user.value.isRealEstateUser && !props.delegationGoal) {
    agreementsList = agreementsList.filter(x => x.productTypes.includes(ProductType.REAL_ESTATE));
  }
  if (props.delegationGoal) {
    agreementsList = agreementsList.filter(x => x.productTypes.includes(props.delegationGoal!));
  }

  agreementsList = agreementsList.filter(x => !x.isCompany);
  return agreementsList;
});

const requiredAgreements = computed<IAgreementItemViewModel[]>(() => {
  return agreements.value.filter(agreement => {
    return agreement.required(props.value.agreements) &&
      (!agreement.organisationIds || agreement.organisationIds.includes($user.value.organisationId!));
  });
});

const additionalAgreements = computed<IAgreementItemViewModel[]>(() => {
  return agreements.value.filter(agreement => {
    return !agreement.required(props.value.agreements) &&
      (!agreement.organisationIds || agreement.organisationIds.includes($user.value.organisationId!));
  });
});

const fieldChanged = () => {
  emit('update:dirty', true);
};

const showClientOwnerInfoCard = computed<boolean>(() => {
  return !!existingClient.value &&
        !!existingClient.value.owner &&
        existingClient.value.owner?.id !== AuthService.User?.id
});

const agreementChanged = (key: keyof ClientAgreements) => {
  fieldChanged();
  const existedChangedKeyId = keysChanged.value.indexOf(key);
  if (existedChangedKeyId > -1) {
    keysChanged.value.splice(existedChangedKeyId, 1);
  } else {
    keysChanged.value.push(key);
  }
  emit('update:agreement', {keysChanged: keysChanged.value, agreements: clientAgreements.value,});
};
const {$onboarding,} = useInstance()
const clientInfoRef = ref<ComponentPublicInstance | null>(null)
const fillAgreementsRef = ref<ComponentPublicInstance | null>(null)

onMounted(async() => {
  if (await $onboarding.tours.LEAD.isEnabled()) {
    useTour(clientInfoRef.value!, $onboarding.tours.LEAD.step.CLIENT_INFO);
    useTour(fillAgreementsRef.value!, $onboarding.tours.LEAD.step.FILL_AGREEMENTS)
  }
  props.value.mainAddress.landId = $user.value.landId;
  props.value.mainAddress.districtId = $user.value.districtId;
  if (props.isEdit) {
    clientCopy.value = clone(props.value);
  }
});

const openClientRodo = () => {
  $modalService.open(ClientRodo, {},
    {maxWidth: 650, persistent: true, fullscreen: undefined, });
};

const findRegon = async(nip: Nullable<string>) => {
  if (nip?.length === 10) {
    try {
      const response = await MultiformApi.getCompanyDataByNip(nip);
      if (response) {
        props.value.regon = response.regon;
      }
    } catch (e) {
      $snackbarService.openWarningSnackbar(I18NGetter().useEditClientForm.SNACKBAR_WARNING_REGON_NIP);
    }
  }
  fieldChanged();
};

const onCheckAllChange = () => {
  fieldChanged();
  agreementsChange(requiredAgreements.value);
  agreementsChange(additionalAgreements.value);
  emit('update:agreement', {keysChanged: keysChanged.value, agreements: clientAgreements.value,});
};

const agreementsChange = (agreements: Array<IAgreementItemViewModel>) => {
  agreements.forEach(agreement => {
    clientAgreements.value[agreement.key] = checkAll.value!;
    const existedChangedKeyId = keysChanged.value.indexOf(agreement.key);
    if (existedChangedKeyId > -1) {
      keysChanged.value.splice(existedChangedKeyId, 1);
    } else {
      keysChanged.value.push(agreement.key);
    }
  });
};

const showLink = computed(() => {
  // for domain == LENDI_PL show link
  // for NESTO_RO show only users with admin / verifier / coordinator permissions
  return ($env.isAppDomain().NESTO_RO && ($user.value.isAdminOrVerifier || $user.value.isCoordinator)) || $env.isAppDomain().LENDI_PL;
})
</script>

<style scoped lang="scss">

  .padding-mobile{
    padding-left: 18px;
  }

  .v-card__text{
    padding-bottom: 24px!important;
    padding-top: 24px!important;
  }

  ::v-deep.v-input--checkbox{

    .v-input__slot{
      align-items: start;
    }
  }

</style>
