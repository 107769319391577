<template>
  <multiform-list-manager
      :readonly="true"
      :value="households"
      :itemName="I18NGetter().useSimulationFormStep2And3.HOUSEHOLDS">
    <template #default="{item}">
      <multiform-list-manager
          :readonly="true"
          :value="item.borrowers"
          :itemName="I18NGetter().useSimulationFormStep2And3.BORROWER">
        <template #default="{item}">
          <simulation-form-step3-wrapper :borrower="item" />
        </template>
      </multiform-list-manager>
      <div class="ml-1 mt-1">
        <p v-if="shouldDisplayAverageIncome">{{averageIncomeLabel}}</p>
        <div v-else>
          <p>{{totalIncomeForThreeMonthsLabel}}</p>
          <p>{{totalIncomeForSixMonthsLabel}}</p>
          <p class="mb-1">{{totalIncomeForTwelveMonthsLabel}}</p>
        </div>
      </div>
    </template>
  </multiform-list-manager>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {useHouseholds} from '@/components/calculator/form/useSimulationForm';
import MultiformListManager from '@/modules/multiForm/views/MultiformListManager.vue';
import SimulationFormStep3Wrapper from '@/components/calculator/form/SimulationFormStep3Wrapper.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {numeralFormat} from '@/filters/StringFilters';
import {useInstance} from '@/composables/useInstance';
import {sum} from 'lodash-es';

const {households,} = useHouseholds();
const {$env,} = useInstance();

interface BorrowerIncome {
  mainIncome?: {
    threeMonthsAverage?: number;
    sixMonthsAverage?: number;
    twelveMonthsAverage?: number;
    currentYearIncome?: number;
    currentYearCost?: number;
    currency: string;
  };
  additionalIncome?: {
    threeMonthsAverage?: number;
    sixMonthsAverage?: number;
    twelveMonthsAverage?: number;
    currentYearIncome?: number;
    currentYearCost?: number;
    currency: string;
  };
}

type TimeFrame = 'threeMonths' | 'sixMonths' | 'twelveMonths';

const accumulateBorrowersIncome = (borrowersAmount: number[]): number => {
  return borrowersAmount.reduce((total, amount) => total + amount, 0);
};

const getBorrowersIncomeByTimeFrame = (timeFrame?: TimeFrame): number => {
  const household = households.value[0];
  const mainIncomeKey = `${timeFrame}Average` as keyof BorrowerIncome['mainIncome'];
  const additionalIncomeKey = `${timeFrame}Average` as keyof BorrowerIncome['additionalIncome'];

  const borrowersIncomeMain: number[] = household.borrowers.map(borrower => borrower.mainIncome?.[mainIncomeKey] ?? 0);
  const borrowersIncomeAdditional: number[] = household.borrowers.map(borrower => borrower.additionalIncome?.[additionalIncomeKey] ?? 0);
  const borrowersOtherIncome: number[] = [
    ...household.borrowers.map(x => (x.mainIncome?.income ?? 0)),
    ...household.borrowers.map(x => (x.additionalIncome?.income ?? 0)),
  ];

  const borrowersCompanyIncomeMain: number[] = household.borrowers.map(borrower => (borrower.mainIncome?.currentYearIncome ?? 0) / 12);
  const borrowersCompanyIncomeAdditional: number[] = household.borrowers.map(borrower => (borrower.additionalIncome?.currentYearIncome ?? 0) / 12);
  const borrowersCostMain: number[] = household.borrowers.map(borrower => (borrower.mainIncome?.currentYearCost ?? 0) / 12);
  const borrowersCostAdditional: number[] = household.borrowers.map(borrower => (borrower.additionalIncome?.currentYearCost ?? 0) / 12);

  const avarageIncome: number = sum([
    ...borrowersIncomeMain,
    ...borrowersCompanyIncomeMain,
    ...borrowersCompanyIncomeAdditional,
    ...borrowersIncomeAdditional,
    ...borrowersOtherIncome,
  ]);
  const avarageCost: number = sum([
    ...borrowersCostMain,
    ...borrowersCostAdditional,
  ]);
  return avarageIncome - avarageCost;
};

const computedTotalIncomeByTimeFrame = (timeFrame: TimeFrame, label: string): string => {
  const amount: number = getBorrowersIncomeByTimeFrame(timeFrame);

  return `${I18NGetter().useSimulationFormStep2And3[label]}: ${numeralFormat(amount)} ${
      households.value[0].borrowers[0]?.mainIncome!.currency
  }`;
};

const shouldDisplayAverageIncome = computed<boolean>(() => {
  return $env.isAppDomain().NESTO_RO;
});

const averageIncomeLabel = computed<string>(() => {
  const household = households.value[0];
  const currency = household.borrowers[0]?.mainIncome!.currency;

  const averageIncome = getBorrowersIncomeByTimeFrame('threeMonths');

  return `${I18NGetter().useSimulationFormStep2And3.AVG_INCOME_FOR_THREE_MONTHS}: ${numeralFormat(averageIncome)} ${currency}`;
});

const totalIncomeForThreeMonthsLabel = computed<string>(() => {
  return computedTotalIncomeByTimeFrame('threeMonths', 'TOTAL_INCOME_FOR_THREE_MONTHS');
});

const totalIncomeForSixMonthsLabel = computed<string>(() => {
  return computedTotalIncomeByTimeFrame('sixMonths', 'TOTAL_INCOME_FOR_SIX_MONTHS');
});

const totalIncomeForTwelveMonthsLabel = computed<string>(() => {
  return computedTotalIncomeByTimeFrame('twelveMonths', 'TOTAL_INCOME_FOR_TWELVE_MONTHS');
});

</script>
<style scoped lang="scss">
</style>
