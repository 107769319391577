// Polish Personal Identification Number
import {
  AppDomainSpecificValidator,
  ValidatorObject
} from '@/components/fpComponents/services/countrySpecific/appDomainSpecificValidator';
import EnvironmentService from '@/env/EnvironmentService';
import {AppDomain} from '@/env/enums/Domains';
import {Gender} from '@/models/enums/Gender';

type PersonalIdNumberUtilsObject = {
  gender: (personalIdNumber: string) => Gender,
  birthDay: (personalIdNumber: string) => Date,
};
export type PersonalIdNumberUtils = Record<AppDomain, PersonalIdNumberUtilsObject>;

function isValidPesel(pesel: string): boolean {
  const weightArray = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7,];
  let sum = 0;
  weightArray.forEach((value, index) => {
    const weight = pesel.substring(index, index + 1);
    if (weight) {
      sum += Number(weight) * weightArray[index];
    }
  });
  const exceptionPesels = [
    '00000000000',
    '22222222222',
    '44444444444',
    '66666666666',
    '88888888888',
  ];
  return (exceptionPesels.indexOf(pesel) === -1) &&
    (sum % 10 === Number(pesel.substring(10, 11,)));
}

// Romanian Personal Identification Number -> cnp
// https://ortodoxinfo.ro/2018/02/25/cum-fost-ales-numarul-279146358279-pentru-calcularea-cnp/ source and algorithm
function isValidCNP(cnp: string): boolean {
  if (typeof cnp !== 'string' || !/^\d{13}$/.test(cnp)) {
    return false;
  }

  const monthCode = parseInt(cnp.substring(3, 5), 10);
  if (monthCode < 1 || monthCode > 12) {
    return false;
  }

  const controlNumber = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9,];
  let total = 0;

  for (let i = 0; i < controlNumber.length; i++) {
    total += parseInt(cnp[i], 10) * controlNumber[i];
  }

  const rest = total % 11;
  const lastDigit = parseInt(cnp[12], 10);

  // here is case when we check if the last digit is 10 and last digit of CNP is 1
  if (rest === 10) {
    return lastDigit === 1;
  } else {
    return lastDigit === rest;
  }
}

const birthDayFromPersonalIdNumber = (personalId: string): Date => {
  let year: number;
  let month: number;
  let day: number;
  switch (EnvironmentService.Environment.appDomain) {
  case AppDomain.NESTO_RO:
    year = parseInt(personalId.substring(1, 3), 10);
    month = parseInt(personalId.substring(3, 5), 10) - 1;
    day = parseInt(personalId.substring(5, 7), 10);
    year = year + 1900;
    if (year < new Date().getFullYear() - 100) {
      year += 100;
    }
    break;
  default:
    year = parseInt(personalId.substring(0, 2), 10);
    month = parseInt(personalId.substring(2, 4), 10) - 1;
    day = parseInt(personalId.substring(4, 6), 10);
    if (month > 80) {
      year = year + 1800;
      month = month - 80;
    } else if (month > 60) {
      year = year + 2200;
      month = month - 60;
    } else if (month > 40) {
      year = year + 2100;
      month = month - 40;
    } else if (month > 20) {
      year = year + 2000;
      month = month - 20;
    } else year += 1900;
  }
  return new Date(year, month, day);
};

const polishGenderFromPersonalIdNumber = (personalId: string): Gender => {
  return Number(personalId[9]) % 2 === 0
    ? Gender.FEMALE
    : Gender.MALE;
};

const romanianGenderFromPersonalIdNumber = (personalId: string): Gender => {
  const maleGenederNumber = [1 , 3 , 5 , 7,];
  return maleGenederNumber.includes(Number(personalId![0]))
    ? Gender.MALE
    : Gender.FEMALE;
};

export const personalIdNumberUtils: PersonalIdNumberUtils = {
  [AppDomain.LENDI_PL]: {
    gender: polishGenderFromPersonalIdNumber,
    birthDay: birthDayFromPersonalIdNumber,
  },
  [AppDomain.NESTO_RO]: {
    gender: romanianGenderFromPersonalIdNumber,
    birthDay: birthDayFromPersonalIdNumber,
  },
};

export const isPersonalIdNumberValid: AppDomainSpecificValidator = {
  [AppDomain.LENDI_PL]: {
    length: 11,
    validationFunc: isValidPesel,
  },
  [AppDomain.NESTO_RO]: {
    length: 13,
    validationFunc: isValidCNP,
  },
};
