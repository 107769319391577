import {Person} from '@/models/Person';
import {Utils} from '@/services/utils/BasicUtils';

type Phones = Array<{
  number: string,
  kind: number;
}>

export class BankContactDTO extends Person {
  public name: string = '';
  public phones: Phones = [];
}

export default class BankContact extends BankContactDTO {
  public phone: string = '';
  constructor(dto?: BankContactDTO) {
    super();
    if (dto) {
      Utils.assign(this, dto);
    }
  }

  public get displayName(): string {
    return (this.firstName || this.lastName) ? `${this.firstName} ${this.lastName}` : '';
  }

  public get mainPhone(): string {
    return this.phone ? this.phone : this.phones[0].number;
  }
}
