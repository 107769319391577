<template>
  <base-badge
    v-if="deal"
    :right="right"
    :small="small"
    :label="deal.name"
    :customSize="customSize"
    :loading="deal === null"
    v-on="$listeners"
    :to="$goto.dealLocation(deal.id, deal.dealWizardStep)"
    :readonly="readonly">
    <template #avatar>
      <v-skeleton-loader v-if="deal === null" type="avatar"/>
      <v-img v-else-if="lazy" :src="deal.icon"/>
      <img v-else :src="deal.icon"/>
    </template>
    <template #actions v-if="!small && !hideStatusChip">
      <v-row no-gutters>
        <v-col pl-0>
          <deal-chip :disabled="readonly" v-if="$vuetify.breakpoint.mdAndUp" :deal.sync="deal"/>
          <v-tooltip top v-else>
            <template v-slot:activator="{on}">
              <v-icon v-on="on"
                      class="ml-1"
                      :color="deal.stageColor">mdi-information-outline</v-icon>
            </template>
            <span class="caption">{{$i18n.DealStatus[deal.status]}}</span>
          </v-tooltip>
          <v-menu offset-y v-if="isEditable">
            <template v-slot:activator="{ on }">
              <v-btn icon class="ma-0" v-on="on" :id="$cy.dealBadgeMenu">
                <v-icon >mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <div v-if="isEditableAsAdmin && deal.isLost" class="px-2 pt-2">
              <p class="caption mb-0">{{ $i18n.useDealDetails.DEAL_WAS_CLOSED }}</p>
              <p class="caption mb-0">{{ $i18n.useDealDetails.YOU_CAN_EDIT_AS_ADMIN }}</p>
            </div>
            <v-list dense>
              <v-list-item @click="updateDealName()" :id="$cy.dealEdit">
                <v-list-item-action class="icon-action mr-0">
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-list-item-action>
                <v-list-item-title class="align-center d-flex">{{ I18NGetter().useDealBadge.EDIT_DEAL }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="removeDeal()" :id="$cy.dealDelete">
                <v-list-item-action class="icon-action mr-0">
                  <v-icon small color="error">mdi-archive</v-icon>
                </v-list-item-action>
                <v-list-item-title class="align-center d-flex">{{ I18NGetter().useDealBadge.CLOSE_DEAL }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$user.isAdmin" @click="deleteDeal()">
                <v-list-item-action class="icon-action mr-0">
                  <v-icon small color="error">mdi-delete-alert</v-icon>
                </v-list-item-action>
                <v-list-item-title class="align-center d-flex">{{ I18NGetter().useDealBadge.DELETE_DEAL }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </template>
    <template v-slot:second-line v-if="deal">
      <div v-if="!isPrintOut">
      <CopyId v-if="showId && deal?.id" :id="deal.id"/>
      <span class="mx-1" v-if="simulation || deal.loanAmount">&#5867;</span>
      </div>
      <template v-if="$scopedSlots['second-line']">
        <slot name="second-line"/>
      </template>
      <template v-else-if="deal.simulationId">
        <span  v-if="omnisearchMode">
          {{`ID ${deal.id} • `}} {{deal.updatedAt | dateFormat(true)}}
        </span>
        <span v-else>
          <template v-if="simulation && simulation.userInput && simulation.userInput.loanAmount && deal.isMortgage">
            {{simulation.userInput.loanAmount | numeralFormat}}{{$env.currentCurrency()}}
            &#5867;
            {{simulation.userInput.loanPeriod}} {{ I18NGetter().useDealBadge.YEARS }} &#5867;
            LTV {{simulation.LTV}}% &#5867;
            <span class="first-capital">{{simulation.purposeTitle}} </span>
          </template>
          <template v-if="simulation && simulation.userInput && simulation.userInput.loanAmount && deal.isCash">
            {{simulation.userInput.loanAmount | numeralFormat}}{{$env.currentCurrency()}} &#5867;
            <template v-if="simulation.userInput.loanPeriod">
              <v-icon small>mdi-clock-outline</v-icon> {{simulation.userInput.loanPeriod}} {{ I18NGetter().useDealBadge.MONTHS }}
            </template>
          </template>
        </span>
      </template>
      <template v-else-if="deal.loanAmount">
        {{deal.loanAmount | numeralFormat}}{{$env.currentCurrency()}}
      </template>
    </template>
  </base-badge>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import Deal from '@/models/Deal';
import {Simulation} from '@/models/simulation/Simulation';
import DealsApi from '@/modules/deals/services/DealsApi';
import GoToService from '@/router/GoToService';
import DealChip from '@/modules/deals/views/deal-chip/DealChip.vue';
import BaseBadge from '@/components/badges/base/BaseBadge.vue';
import PickLossReason from '@/components/ProspectPickLossReason.vue';
import {LossReason} from '@/types/ProspectLossReason';
import EditDeal from '@/modules/deals/views/edit-deal/EditDeal.vue';
import {clone, Utils} from '@/services/utils/BasicUtils';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import {i18nFormatter} from '@/services/StringFormatter';
import CopyId from '@/components/badges/CopyId.vue';

@Component({
  components: {CopyId, BaseBadge, DealChip, },
})
export default class DealBadge extends BaseBadge {
  @Prop({required: true, })
  public readonly deal!: Deal;

  @Prop({default: false, })
  public readonly isPrintOut?: boolean;

  @Prop({default: null, })
  public readonly simulation!: Loadable<Simulation>;

  @Prop({default: false, })
  public readonly omnisearchMode!: boolean;

  @Prop({default: false, })
  public readonly hideStatusChip!: boolean;

  @Prop() public readonly customSize!: number;

  @Prop({default: false, type: Boolean,})
  public readonly showId?: boolean;

  public I18NGetter: () => I18NInterface = I18NGetter;
  public async updateDealName() {
    const editedDeal = await this.$modalService.open<Deal>(EditDeal, {
      deal: clone(this.deal),
    },
    {maxWidth: 600, persistent: true, fullscreen: undefined, });
    if (editedDeal && editedDeal.name) {
      Utils.assign(this.deal, editedDeal);
    }
  }

  public goToDeal() {
    this.$goto.deal(this.deal.id, this.deal.dealWizardStep);
  }

  public async removeDeal() {
    const response: LossReason | undefined = await this.$modalService.open<LossReason>(PickLossReason);
    if (response) {
      try {
        await DealsApi.closeDeal(this.deal.id!, response);
        GoToService.deals();
        this.$snackbarService.openSuccessSnackbar(I18NGetter().useDealBadge.REMOVE_DEAL_SUCCESS_SNACKBAR);
      } catch (e) {
        this.errorHandler(e, I18NGetter().useDealBadge.REMOVE_DEAL_ERROR_HANDLER);
      }
    }
  }

  get isEditable(): boolean {
    return !this.readonly && (this.deal.isPending || this.isEditableAsAdmin);
  }

  get isEditableAsAdmin(): boolean {
    return this.$user.isAdminOrVerifier || this.$user.privileges.isBokAdmin;
  }

  public async deleteDeal() {
    if (await this.$modalService.openConfirmModal({
      title: `${I18NGetter().useDealBadge.DELETE_DEAL_TITLE} ${this.deal.name}?`,
      description: I18NGetter().useDealBadge.DELETE_DEAL_DESC,
    })) {
      try {
        await DealsApi.removeDeal(this.deal.id);
        this.$snackbarService.openSuccessSnackbar(i18nFormatter(
          I18NGetter().useDealBadge.NEW_TRANSLATION_DEAL_WAS_DELETED, {dealName: this.deal.name,}));
        GoToService.deals();
      } catch (e) {
        this.errorHandler(e,I18NGetter().useDealBadge.REMOVE_DEAL_ERROR_HANDLER);
      }
    }
  }
}

</script>

<style scoped lang="scss">
  .icon-action{
    min-width: 32px!important;
  }

  div ::v-deep .v-list-item--avatar{
    padding-right: 0 !important;
    padding-left: 0 !important;;
  }
  .reversed-badge{
    .avatar{
      display: flex;
    }

    ::v-deep .v-list-item{
      flex-direction: row-reverse;
      padding-left: 0;
    }
  }

  ::v-deep .v-list-item__title{
    line-height: 24px;
    height: 24px;
  }
  ::v-deep .v-list-item__subtitle {
    line-height: 20px;
    height: 20px;
  }

</style>
