export enum MortgageCalculatorKey {
  AVAILABLE_OFFERS = 'AVAILABLE_OFFERS',
  OFFERS_COUNTER = 'OFFERS_COUNTER',
  ROWS_ORDER = 'ROWS_ORDER',
  OFFERS_MANAGEMENT = 'OFFERS_MANAGEMENT',
  SELECTED_OFFERS = 'SELECTED_OFFERS',
  INSTITUTION_NAMES = 'INSTITUTION_NAMES',
  VIEW_SIZE = 'VIEW_SIZE',
  VERSION = 'VERSION',
  USED_FILTERS_COUNT = 'USED_FILTERS_COUNT',
  BASIC_PARAMETERS = 'BASIC_PARAMETERS',
  REFERENCE_RATE = 'REFERENCE_RATE',
  BRIDGING_INSURANCE = 'BRIDGING_INSURANCE',
  LOAN_PERIOD = 'LOAN_PERIOD',
  BANKS = 'BANKS',
  FILTERS = 'FILTERS',
  PRINTOUT_SETTINGS = 'PRINTOUT_SETTINGS',
  PRINT = 'PRINT',
  ADD_OFFERS_TO_DEAL = 'ADD_OFFERS_TO_DEAL',
  INITIAL_COST = 'INITIAL_COST',
  TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE = 'TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE',
  TRANSITIONAL_INSTALLMENT = 'TRANSITIONAL_INSTALLMENT',
  TARGET_INSTALLMENT = 'TARGET_INSTALLMENT',
  PRINCIPAL_AND_INTEREST_INSTALLMENT = 'PRINCIPAL_AND_INTEREST_INSTALLMENT',
  NON_MONTHLY_COSTS = 'NON_MONTHLY_COSTS',
}
