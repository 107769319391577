<template>
  <div>
    <template v-if="bankSecrecyRejected">
      <v-row>
        <v-col align="center">
          <img src="@/assets/img/zgoda-udzielona.svg" class="py-2" alt="success"/>
        </v-col>
      </v-row>
      <h1 class="main-header">Zgoda na ujawnienie danych objętych tajemnicą bankową dotycząca podmiotu <span class="blue--text">{{clientBankSecrecyInfo.partner.name}}</span> została skutecznie wycofana i anulowana.</h1>
    </template>
    <template v-else>
      <v-row>
        <v-col align="center">
          <img src="@/assets/img/zgoda-udzielona.svg" class="py-2" alt="success"/>
        </v-col>
      </v-row>
      <h1  class="main-header pb-4" :class="{'text-body-1':$vuetify.breakpoint.smAndDown}">Klikając poniższy przycisk, cofniesz zgodę na ujawnienie danych objętych tajemnicą bankową dotyczącą partnera <span class="blue--text">{{clientBankSecrecyInfo.partner.name}}</span> udzieloną na okres do dnia <span class="blue--text">{{clientBankSecrecyInfo.expireAt | dateFormat}}</span></h1>
      <v-row>
        <v-col align="center">
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          ></v-progress-circular>
          <fp-btn v-else class="blue--text py-4"  @click="$emit('rejectBankSecrecy')">Wycofuję zgodę</fp-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ClientBankSecrecyDetails} from '@/models/Client';

@Component
export default class RejectBankSecrecy extends Vue {
  @Prop({required: true,}) public readonly clientBankSecrecyInfo!: ClientBankSecrecyDetails;
  @Prop({required: true,}) public readonly bankSecrecyRejected!: boolean;
  @Prop({required: true,}) public readonly loading!: boolean;
}
</script>
<style scoped lang="scss">
.main-header {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.paragraph {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  align-self: end;
  letter-spacing: normal;
  text-align: center;
}
</style>
