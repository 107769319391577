export enum DealStatus {
  DEAL_CREATED = 'dealStatusDealCreated',
  SIMULATION_PERFORMED = 'dealStatusSimulationPerformed',
  COMMUNICATION_ARRANGED = 'dealStatusCommunicationArranged',
  COMMUNICATION_PERFORMED = 'dealStatusCommunicationPerformed',
  APPOINTMENT_ARRANGED = 'dealStatusAppointmentArranged',
  APPOINTMENT_PERFORMED = 'dealStatusAppointmentPerformed',
  ABLE_TO_START_PROCESS = 'dealStatusAbleToStartProcess',
  APPLICATION_CREATED = 'dealStatusApplicationCreated',
  SENT_TO_VERIFICATION = 'dealStatusSentToVerification',
  STARTED_VERIFICATION = 'dealStatusStartedVerification',
  REJECTED = 'dealStatusRejected',
  COLLECTING_DOCUMENTS = 'dealStatusCollectingDocuments',
  APPLICATION_SUBMITTED = 'dealStatusApplicationsSubmitted',
  POSITIVE_DECISION = 'dealStatusPositiveDecision',
  CONTRACT_SIGNED = 'dealStatusContractSigned',
  WON = 'dealStatusWon',
  LOST = 'dealStatusLost',
  RETURNED_TO_AGENT_CC = 'dealStatusReturnedToTheAgentCC',
  BROKER_AGREEMENT = 'dealStatusBrokerAgreement',
  REAL_ESTATE_PRESENTATION = 'dealStatusRealEstatePresentation',
  COMPLETED_SALE = 'dealStatusCompletedSale',
}
