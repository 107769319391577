import {getCurrentInstance} from 'vue';
import {Vue} from 'vue/types/vue';

interface ModalProxyExtension<D extends Record<string, any>, R> {
    modalData: D;
    showDialog: boolean;
    closeModal(data?: R): void;
}

type ModalProxy<D extends Record<string, any>, R> = Vue & ModalProxyExtension<D, R>;

export const useModalInstance = <TData extends Record<string, any>, TReturn = void>() => {
  const instance = getCurrentInstance();

  const isModalComponent = (proxy: Vue | ModalProxy<TData, TReturn>): proxy is ModalProxy<TData, TReturn> =>
    (proxy as ModalProxy<TData, TReturn>).modalData !== undefined;

  if (!(instance && isModalComponent(instance.proxy))) {
    throw new Error('useModalInstance must be called within a modal component setup function.');
  }

  return instance.proxy as ModalProxy<TData, TReturn>;
};
