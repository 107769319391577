<template>
  <div v-html-safe="html" class="fp-markdown"></div>
</template>

<script lang="ts" setup>
import {Converter} from 'showdown';

const props = withDefaults(defineProps<{
  source: string,
}>(), {
  source: '',
});

const converter: Converter = new Converter();
const html: string = converter.makeHtml(props.source);
</script>
<style lang="scss" scoped>
.fp-markdown ::v-deep {
  p, ul {
    margin-bottom: 8px;
  }
}
</style>
