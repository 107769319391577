import {BikStoreState} from '@/modules/deals/services/store/bikStore/BikStoreState';
import {BikReport} from '@/models/BikReport';

const state: BikStoreState = {
  BikReports: [],
  selectedBikReportId: undefined,
};

export const BikStoreModule = {
  namespaced: false,
  state,
};
