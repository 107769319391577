import {CustomerCommitmentLevelType} from '@/models/enums/CustomerCommitmentLevelType';
import {AttributeTimeRange} from '@/models/enums/AttributeTimeRange';
import {LoanProbabilityType} from '@/models/enums/LoanProbabilityType';
import {LoanBlockerType} from '@/models/enums/LoanBlockerType';
import {CustomerInterest} from '@/models/enums/CustomerInterest';
import {SourceChannel} from '@/modules/prospects/enums/SourceChannel';
import {SourceKind} from '@/modules/prospects/enums/SourceKind';
import {MapToViewModel, Utils} from '@/services/utils/BasicUtils';
import {OrganisationId} from '@/models/enums/OrganisationId';
import {RealEstateSupportKind} from '@/models/enums/RealEstateSupportKind';
import {RealEstate, RealEstateDTO} from '@/models/RealEstate';
import {RealEstateOfferPlacement} from '@/models/enums/RealEstateOfferPlacement';
import {EstateAddress} from '@/modules/multiForm/shared/MultiformModels';
import {MarketType} from '@/models/simulation/MarketType';

export class RealEstateQuestionnaireAnswersDto {
  id?: number;
  supportKind: RealEstateSupportKind[] = [];
  offerPlaces: RealEstateOfferPlacement[] = [];
  urls: string[] = [];
  realEstateBuying: RealEstate = new RealEstate();
  realEstateSelling: RealEstate = new RealEstate({market: MarketType.SECONDARY_MARKET,});
  realEstateLookingTime: Nullable<AttributeTimeRange> = null;
  realEstateBuyingTime: Nullable<AttributeTimeRange> = null;
  realEstateBuyingMarkets: MarketType[] = [];
  advertisers: Nullable<string> = null;
}

export class RealEstateQuestionnaireAnswers extends RealEstateQuestionnaireAnswersDto {
  constructor(dto?: PartialDeep<RealEstateQuestionnaireAnswersDto>) {
    super();
    if (dto) {
      if (dto.realEstateBuying) {
        dto.realEstateBuying = new RealEstate(dto.realEstateBuying);
      }
      if (dto.realEstateSelling) {
        dto.realEstateSelling = new RealEstate(dto.realEstateSelling);
      }
      Utils.assign(this, dto);
    }
  }
}

export interface IQuestionnaireAnswers{
  needHelpInSearching: boolean;
  interestedInSellingRealEstate: boolean;
  interestedInInsurance: boolean;
  needHelpInFinishing: boolean;
  needFinanceAdvice: boolean;
  isLookingForMortgage: boolean;
  nextMeetingPlanned: boolean;
}

export class QuestionnaireAnswers implements NullableDeep<IQuestionnaireAnswers> {
  needHelpInSearching: Nullable<boolean> = null;
  interestedInSellingRealEstate: Nullable<boolean> = null;
  interestedInInsurance: Nullable<boolean> = null;
  needHelpInFinishing: Nullable<boolean> = null;
  isLookingForMortgage: Nullable<boolean> = null;
  needFinanceAdvice: Nullable<boolean> = null;
  nextMeetingPlanned: Nullable<boolean> = null;
}

export interface IDealAttributesViewModel{
  loanProbabilityType: LoanProbabilityType;
  loanProbabilityTypeBlockers?: LoanBlockerType[];
  maxCreditWorthiness: number;
  customerTimeToBuy: AttributeTimeRange;
  plannedDateOfSubmittingApplication: AttributeTimeRange;
  interest: CustomerInterest;
  customerCommitmentLevel: CustomerCommitmentLevelType;
  channel: SourceChannel;
  medium: SourceKind;
  sourceId: number;
  note: string;
  questionnaire: NullableDeep<IQuestionnaireAnswers>
}

export interface IDealAttributesDTO extends IDealAttributesViewModel{
  dealId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export class DealAttributesViewModel implements NullableDeep<IDealAttributesViewModel> {
  loanProbabilityType: Nullable<LoanProbabilityType> = null;
  loanProbabilityTypeBlockers?: LoanBlockerType[] = [];
  maxCreditWorthiness: Nullable<number> = null;
  customerTimeToBuy: Nullable<AttributeTimeRange> = null;
  plannedDateOfSubmittingApplication: Nullable<AttributeTimeRange> = null;
  interest: Nullable<CustomerInterest> = null;
  customerCommitmentLevel: Nullable<CustomerCommitmentLevelType> = null;
  channel: Nullable<SourceChannel> = null;
  medium: Nullable<SourceKind> = null;
  sourceId: Nullable<number> = null;
  note: Nullable<string> = null;
  questionnaire: NullableDeep<IQuestionnaireAnswers> = new QuestionnaireAnswers();
}

export class DealAttributes extends DealAttributesViewModel implements NullableDeep<IDealAttributesDTO> {
  dealId?: number;
  createdAt?: string;
  updatedAt?: string;
  brokerOrganisationId?: OrganisationId;
  usercomLossReasonId?: number;
  usercomLossReasonName?: string;
  usercomLossReasonDescription?: string;

  get cardModel(): DealAttributesViewModel {
    return MapToViewModel(DealAttributesViewModel, this);
  };

  constructor(dto?: IDealAttributesDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    }
  }
}
