<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="isKindMeeting">
        <v-col :class="$onboarding.tours.LEAD.step.FILL_APPOINTMENT_FORM">
          <v-radio-group
            class="mt-0"
            prepend-icon="map"
            hide-details
            :label="I18NGetter().useActivityMeetingForm.CHOOSE_MEETING_PLACE"
            required
            :rules="[(v) => !!v || I18NGetter().useActivityMeetingForm.FIELD_REQUIRED]"
            v-model="value.addressMeetingKind"
          >
            <v-radio :disabled="isDelegation && !isOfficeAddressFilled" :value="addressMeetingKind.OFFICE">
              <template v-slot:label>
                <div class="d-flex flex-col flex-sm-row">
                  <span class="mr-2 text-no-wrap">
                    {{ I18NGetter().useActivityMeetingForm.AT_THE_OFFICE }}
                    <template
                      v-if="isOfficeAddressFilled"
                    >
                    :
                    </template>
                  </span>
                  <AddressView
                    v-if="isOfficeAddressFilled"
                    :address="owner.officeAddress"
                  />
                </div>
              </template>
            </v-radio>
            <v-radio
              :disabled="!owner?.canMeetOutsideOffice || !isClientAddressComplete"
              :value="addressMeetingKind.CLIENT"
            >
              <template v-slot:label>
                <div class="d-flex flex-col flex-sm-row">
                  <span class="mr-1">{{ I18NGetter().useActivityMeetingForm.AT_CLIENTS }}&nbsp;</span>
                <div>
                  <span v-if="!owner?.canMeetOutsideOffice">
                    {{ CANNOT_MEET_OUTSIDE_OFFICE }}
                  </span>
                    <span v-else-if="!isClient">
                    {{ I18NGetter().useActivityMeetingForm.NO_CHOSEN_CLIENT }}
                  </span>
                    <AddressView
                      v-else-if="owner?.canMeetOutsideOffice && isClientAddressComplete"
                      :address="client.mainAddress"
                    />
                    <span v-else-if="owner?.canMeetOutsideOffice && !isClientAddressComplete">
                    {{ I18NGetter().useActivityMeetingForm.NO_ADDRESS_CLIENT }}
                  </span>
                  </div>
                </div>
              </template>
            </v-radio>
            <v-radio
              :value="addressMeetingKind.TELECONFERENCE"
              :id="$cy.teleconferenceMeetingRadioButton"
            >
              <template v-slot:label>
                <div class="d-flex flex-col flex-sm-row">
                  <span>{{ I18NGetter().useActivityMeetingForm.ONLINE }}&nbsp;</span>
                  <div>
                    <span class="ml-sm-1">
                    <!-- move to getter -->
                    {{ value.teleconferenceUrl ? `${value.teleconferenceUrl.substring(0, 27)}...` : I18NGetter().useActivityMeetingForm.LINK_TO_VIDEOCONFERENCE }}
                  </span>
                    <fp-btn v-if="value.teleconferenceUrl" x-small text primary @click="openWindow">
                      {{ I18NGetter().useActivityMeetingForm.OPEN_LINK }}
                    </fp-btn>
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="pl-8" v-if="isAddressMeetingKindClient">
        <v-col>
          <span class="v-label">{{ I18NGetter().useActivityMeetingForm.CLIENT_ADDRESS }}</span>
          <address-form :long-form="false" v-model="client.mainAddress"/>
        </v-col>
      </v-row>
      <v-layout wrap v-if="showNotifyClient">
        <fp-checkbox
          class="pl-10"
          v-if="value.client"
          xs12
          :label="I18NGetter().useActivityMeetingForm.NOTIFY_CLIENTS"
          v-model="value.requireNotification"/>
        <v-flex xs12>
          <v-combobox
            multiple
            prepend-icon="mdi-bell"
            height="56px"
            chips
            class="emails-combobox"
            deletable-chips
            outlined
            :delimiters="[',',';',' ',]"
            :rules="[validateEmails]"
            @change="removeLastEmail(value.additionalEmails)"
            v-model="value.additionalEmails"
            :placeholder="I18NGetter().useActivityMeetingForm.ENTER_EMAIL_ADDRESS"
            :label="`${I18NGetter().useActivityMeetingForm.NOTIFY_ADDITIONAL} (${value.additionalEmails.length || 0}/3)`"/>
        </v-flex>
      </v-layout>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Activity from '@/models/Activity';
import {AddressMeetingKind} from '@/commons/enums/AddressMeetingKind';
import {ActivityKind} from '@/modules/activities/enums/ActivityKind';
import Client from '@/models/Client';
import AddressView from '@/components/address/AddressView.vue';
import {UserListElement} from '@/models/ppl/UserListElement';
import AddressForm from '@/components/address/AddressForm.vue';
import UserManagementApi from '@/modules/user/services/UserManagementApi';
import EditAddressModal from '@/components/address/EditAddressModal.vue';
import {clone} from '@/services/utils/BasicUtils';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';

@Component({
  components: {
    AddressForm,
    AddressView,
  },
})
export default class ActivityMeetingForm extends Vue {
  @Prop({required: true,},)
  readonly value!: Activity;

  @Prop({required: false,},)
  readonly client?: Client;

  @Prop({required: false,},)
  readonly owner!: UserListElement;

  @Prop({required: false,},)
  readonly isDelegation!: boolean;

  @Prop({
    required: false,
    default: true,
  },)
  readonly showNotifyClient!: boolean;

  I18NGetter: () => I18NInterface = I18NGetter;
  readonly CANNOT_MEET_OUTSIDE_OFFICE: string = I18NGetter().useActivityMeetingForm.NO_MEETINGS_OUTSIDE_OFFICE;
  addressMeetingKind: typeof AddressMeetingKind = AddressMeetingKind;

  get isOfficeAddressFilled(): boolean {
    return !!this.owner?.officeAddress?.isFilled;
  }

  get isClientAddressComplete(): boolean {
    return !!this.client &&
      !!this.client.mainAddress.city &&
      !!this.client.mainAddress.street &&
      !!this.client.mainAddress.streetNum;
  }

  get isClient(): boolean | string {
    return !!this.client && !!this.client.firstName && !!this.client.lastName;
  }

  get isKindMeeting(): boolean {
    return this.value?.kind === ActivityKind.MEETING;
  }

  get isAddressMeetingKindClient(): boolean {
    return this.value?.addressMeetingKind === AddressMeetingKind.CLIENT;
  }

  validateEmails(): boolean | string {
    if (this.value.additionalEmails) {
      const x = this.value.additionalEmails.map(x => {
        // eslint-disable-next-line no-useless-escape
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(x);
      });
      return x.includes(false) ? I18NGetter().useActivityMeetingForm.ERROR_EMAIL_ADDRESS as string : true;
    } else {
      return true;
    }
  }

  onInputMeetingKind(): void {
    if (this.value.addressMeetingKind === AddressMeetingKind.OFFICE &&
      !this.isOfficeAddressFilled &&
      !this.isDelegation) {
      this.editAddress();
    }
  }

  openWindow(): void {
    window.open(this.value.teleconferenceUrl!, '_blank');
  }

  removeLastEmail(additionalEmails: Array<string>): void {
    if (additionalEmails.length > 3) {
      additionalEmails.pop();
    }
  }

  async saveOfficeAddress(address: Address): Promise<void> {
    try {
      await UserManagementApi.saveOfficeAddress(this.owner!.id, address);
      this.owner.officeAddress = address;
      this.$snackbarService.openSuccessSnackbar(I18NGetter()
        .useActivityMeetingForm.SAVE_OFFICE_ADDRESS_OPEN_SUCCESS_SNACKBAR);
    } catch (e) {
      this.errorHandler(e, I18NGetter().useActivityMeetingForm.SAVE_OFFICE_ADDRESS_ERROR_HANDLER);
    }
  }

  async editAddress(): Promise<void> {
    const response = await this.$modalService.open<Address>(EditAddressModal, {
      address: this.owner.officeAddress ? clone(this.owner.officeAddress) : null,
    }, {
      persistent: true,
      maxWidth: 600,
    });
    if (response) {
      await this.saveOfficeAddress(response);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 16px !important;
}
</style>
