export enum LoanPurposeWhat {
  HOUSE = 'House',
  APARTMENT = 'Apartment',
  PLOT = 'Plot',
  OFFICE = 'Office',
  BUILDING_PLOT = 'BuildingPlot',
  BUILDING_PLOT_AND_CONSTRUCTION = 'BuildingPlotAndConstruction',
  BUILDING_PLOT_AND_HOUSE = 'BuildingPlotAndHouse',
  RECREATION_PLOT = 'RecreationPlot',
  AGRICULTURAL_PLOT = 'AgriculturalPlot',
  HOLIDAY_HOME = 'HolidayHome',
}
