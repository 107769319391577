<template>
  <v-flex class="text-left ml-10" v-if="headersForFilters.length">
    <v-layout align-center class="fp-filter-wrapper">
        <div class="chips-container">
          <v-chip class="mx-0" right @click="openFilter()">
            <v-icon>mdi-filter-variant</v-icon>
            <span class="px-1">{{ $i18n.useFpFilter.FILTERS }}</span>
          </v-chip>
<!--    value.isValueOnlyInNonClearableProperties(header) it checks if the value is only in the nonClearableProperty, if so it should not be perceived as the active filter     -->
          <l-chip
            v-for="(header, index) in headersForFilters"
            :key="header.valueFilter"
            :close="isShowCloseIcon(header)"
            @click="openFilter(index)"
            :promise="filterLabelContent(header)"
            @click:close="removeFilter(index)"
            :color="isValueExist(header) && !value.isValueOnlyInNonClearableProperties(header) ? 'primary' : 'initial'"
            class="my-0"
            :id="header.id"
          />
        </div>
      <v-flex shrink>
        <v-layout align-center>
          <v-chip :class="{'mr-2': activeHeaders.length}" outlined @click="openFilter()" :id="$cy.allFiltersChip">
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-icon v-on="on">mdi-filter-variant</v-icon>
              </template>
              <span>{{ $i18n.useFpFilter.ALL_FILTERS }}</span>
            </v-tooltip>
          </v-chip>
          <v-chip @click="removeAllFilters()" outlined
                 v-if="activeNotReadonlyHeadersLength > 0"
                  :id="$cy.cleanFilters">
            <span class="error--text">{{ $i18n.useFpFilter.CLEAR }}</span>
            <v-avatar class="v-btn--outlined">
              <span class="font-weight-medium">{{ activeNotReadonlyHeadersLength }}</span>
            </v-avatar>
          </v-chip>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AsyncFilters from '@/components/filters/AsyncFilters/AsycFilters.vue';
import {ModalPayload, VPagination} from '@/types/typings';
import { compact, isNumber } from 'underscore';
import {BaseFilter, LRange} from '@/models/Filter';
import {clone, isDeepEmpty} from '@/services/utils/BasicUtils';
import {FilterGroupDictionary, FilterType} from '@/services/searchEngine/enums/FilterType';
import {FilterGroup, IDataTableHeader} from '@/services/searchEngine/interfaces/DataTableHeader';
import LChip from '@/components/commons/LChip.vue';
import {UserLocalStorage} from '@/services/UserLocalStorage';
import { cloneDeep } from 'lodash-es';

@Component({
  components: {LChip, },
})
export default class FpFilter<T, TEnum extends string> extends Vue {
  @Prop({required: true, }) public headers!: IDataTableHeader<T>[];
  @Prop({required: true, }) public value!: BaseFilter;
  @Prop() public pagination?: VPagination;
  @Prop({required: false,})
  @Prop({ default: () => [], })
  hideFiltersChips!: string[];

  @Prop({ required: true, })
  localStorageKey!: string;

  public get headersForFilters(): IDataTableHeader<T>[] {
    const headers = clone(this.headers).filter(header => {
      if (typeof header.filterCondition === 'function') {
        return header.filterCondition() && header.valueFilter
      }
      if (header.filterCondition !== undefined) {
        return header.filterCondition && header.valueFilter
      }
      return header.valueFilter
    });
    return this.groupHeadersForFilters(headers)
      .sort((a, b) => {
        if (a.filterOrder && b.filterOrder) {
          return a.filterOrder - b.filterOrder;
        } else if (a.filterOrder && !b.filterOrder) {
          return -1;
        } else {
          return 0;
        }
      })
      .sort(a => this.isValueExist(a) ? -1 : 0)
      .filter((filter) => {
        if (filter.valueFilter && this.hideFiltersChips) {
          return this.hideFiltersChips.indexOf(filter!.valueFilter!) === -1
        }

        return true;
      });
  }

  public get activeHeaders(): IDataTableHeader<T>[] {
    return this.headersForFilters.filter(h => this.isValueExist(h));
  }

  public isShowCloseIcon(header: IDataTableHeader<T> | FilterGroup): boolean {
    if (!this.isValueExist(header)) {
      return false;
    }
    if ('headers' in header) {
      return !!header.headers.filter(item => {
        return item.valueFilter && !this.value.isHeaderFilterValueNull(item.valueFilter) && !item.filterReadonly;
      }).length;
    } else if (header.nonClearAbleProperties) {
      return !this.value.isValueOnlyInNonClearableProperties(header)
    } else {
      return !header.filterReadonly;
    }
  }

  public filterLabelContent(header: IDataTableHeader<T>): Promise<string> | string {
    if (this.value.isValueOnlyInNonClearableProperties(header)) {
      return header.text;
    } else return header?.valueText?.(this.value.getValue(header), this.value) || '';
  }

  public get activeNotReadonlyHeadersLength(): number {
    let counter: number = 0;
    this.activeHeaders.forEach((header: IDataTableHeader<T>) => {
      counter += Number(this.isShowCloseIcon(header));
    });

    return counter;
  }

  public isValueExist(header: IDataTableHeader<T>): boolean {
    if (header instanceof FilterGroup) {
      return header.headers.some(h => h.valueFilter && !this.value.isHeaderFilterValueNull(h.valueFilter));
    } else {
      return !!header.valueFilter && !this.value.isHeaderFilterValueNull(header.valueFilter);
    }
  }

  public removeFilter(index: number) {
    const field = this.headersForFilters[index];
    if (field instanceof FilterGroup) {
      const val = this.value.getValue(field) as Record<keyof BaseFilter, BaseFilter>[];
      this.value.clearFilterProp(val);
    } else if (field.valueFilter) {
      this.value.clearFilterProp(field.valueFilter, field.nonClearAbleProperties);
    }

    this.emitInput(this.value);
  }

  public removeAllFilters() {
    this.headersForFilters.forEach(header => {
      if (header instanceof FilterGroup) {
        const val = this.value.getValue(header) as Record<keyof BaseFilter, BaseFilter>[];
        this.value.clearFilterProp(val);
      }
      if (header.valueFilter) {
        this.value.clearFilterProp(header.valueFilter, header.nonClearAbleProperties);
      }
    });
    this.emitInput(this.value);
  }

  public async openFilter(id?: number) {
    if (typeof id === 'undefined' || !this.headersForFilters[id].filterReadonly) {
      const response = await this.$modalService.open<ModalPayload<BaseFilter>>(AsyncFilters,
        {
          headers: isNumber(id) ? [this.headersForFilters[id],] : this.headersForFilters,
          activeFilters: clone(this.value),
        },
        {
          maxWidth: 1070,
          persistent: true,
        });
      if (response && response.payload) {
        this.emitInput(response.payload);
      }
    }
  }

  public emitInput(data: BaseFilter) {
    if (this.localStorageKey) {
      const formattedData = JSON.stringify(this.prepareDataToSave(data));
      UserLocalStorage.setItem(this.localStorageKey, formattedData);
    }
    this.$emit('input', data);
  }

  // TODO: dodrobić obsługę dla pozostałych typów filtrów jeżeli będą inne filtru z defaultValue
  private prepareDataToSave(data: BaseFilter): BaseFilter {
    const dataToSave = cloneDeep(data);
    this.headersForFilters.forEach(header => {
      if (header instanceof FilterGroup) {
        header.headers.forEach(item => {
          if (!item.defaultValue) {
            return;
          }

          if (item.filterType === FilterType.DATE) {
            this.checkDateParam(dataToSave, item);
          }
        });
      } else if (header.defaultValue) {
        if (header.filterType === FilterType.DATE) {
          this.checkDateParam(dataToSave, header);
        }
      }
    });
    return dataToSave;
  }

  private checkDateParam(data: BaseFilter, header: IDataTableHeader<T>) {
    const filterValue: LRange<Date> = data[header.valueFilter!] as any as LRange<Date>;
    const defaultValue = header.defaultValue as LRange<Date>;
    if (defaultValue.from && `${filterValue?.from}` === `${defaultValue.from}`) {
      filterValue!.from = null;
    }
    if (defaultValue.to && `${filterValue?.to}` === `${defaultValue.to}`) {
      filterValue!.to = null;
    }
  }

  public groupHeadersForFilters(headers: IDataTableHeader<T>[]): IDataTableHeader<T>[] {
    FilterGroupDictionary.forEach(el => {
      const headersToGroup = headers.filter(elm => elm.filterType === el.filterType);
      const order = Math.min(...compact(headersToGroup.map(el => el.filterOrder)));
      const group = new FilterGroup(el.filterType, headersToGroup, el.text, order);
      if (group && group.headers.length) {
        const index = headers.findIndex(elm => elm.filterType === el.filterType);
        headers = headers.filter(elm => elm.filterType !== el.filterType);
        headers.splice(index, 0, group);
      }
    });
    return headers;
  }
}

</script>

<style scoped lang="scss">

  .chips-container {
    max-height: 36px;
    overflow: hidden;
  }
  @keyframes animate-accessory{
    100%{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .fp-filter {
    ::v-deep .v-input__slot {
      min-height: 36px !important;
      height: 36px;
      border-radius: 4px;
      .v-input__append-inner,
      .v-input__prepend-inner {
        margin-top: 5px
      }

        .v-label {
        top: 7px
      }

      input {
        margin-top: 0
      }
    }
  }

  .filter-button {
    min-width: 48px;
  }

  .fp-filter-wrapper {
    .chips-container {
      display: block;
      ::v-deep .v-chip {
        margin: 2px 4px;
        &__content {
          cursor: pointer;
        }
      }
    }
    ::v-deep .v-btn {
      height: 32px;
      width: 32px;
    }
  }
  ::v-deep.v-chip .v-avatar {
    margin-left: 4px;
    margin-right: 0;
    width: 20px!important;
    min-width: 20px!important;
    height: 20px!important;
  }
  ::v-deep .v-list-item {
    height: 32px;
  }
</style>
