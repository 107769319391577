import {RoleTypes} from '@/commons/enums/RoleTypes';
import LoanApplication from '@/models/LoanApplication';
import Deal from '@/models/Deal';
import {OrganisationId} from '@/models/enums/OrganisationId';
import {mapValues, merge} from 'lodash-es';
import {DelegationWithStats} from '@/types/DelegationWithStats';
import {UserDetailsSegments} from '@/types/Dictionaries';
import {UserDTO} from '@/models/user/UserDTO';
import {normalizeNumberEnum} from '@/services/enumTranslator/normalizeNumberEnum';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {Privileges} from '@/models/user/Privileges';
import {IUser} from '@/models/user/IUser';
import {CrmModules} from '@/types/CrmModules';
import EnvironmentService from '@/env/EnvironmentService';
import {AgentOpinion} from '@/modules/user/models/AgentOpinion';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import AgentBusinessCard, {AgentBusinessCardDTO} from '@/modules/user/models/BusinessCardStats';
import {Quizzes} from '@/models/user/Quizzes';
import {TourName} from '@/modules/onboarding/tour/models/TourOptions';
import {AppDomainKey} from '@/env/AppDomain';
import {dateAndTimeFormat} from '@/filters/DateFilters';

export interface canManage {
  productTypeMortgage: boolean,
  productTypeCash: boolean,
  productTypeSme: boolean,
  productTypeInsurance: boolean,
  productTypeRealEstate: boolean
}

export type MinimalUser = {id: number; name: string}
export default class User extends UserDTO implements IUser {
  isRecommendedForDelegation?: boolean;
  defaultPartnerId?: number;
  isFirstFromBok?: boolean;
  isFromOutsideRecommendation?: boolean;
  isFromBok?: boolean;
  isFromDelegationList?: boolean;
  isFromMyDelegationList?: boolean;
  isFirstFromMyDelegationList?: boolean;
  isFirstFromFromOutsideRecommendation?: boolean;
  isFirstFromDelegationList?: boolean;
  delegationWithStats: DelegationWithStats = new DelegationWithStats();
  divisions?: Array<number> = [];
  branchId?: Nullable<number> = null;
  opinions?: AgentOpinion[] = [];
  canManageProspects?: boolean;
  quiz?: Quizzes;
  canManage?: canManage;
  hasAccessToOldLendi: boolean = false;
  isContractOfMandate: boolean = false;
  contractOfMandateStartsAt?: Date;
  profile: AgentBusinessCard = new AgentBusinessCard();
  logoUrl: Nullable<string> = '';

  constructor(userDTO?: UserDTO,
    privileges?: Privileges,
    quiz?: Quizzes,
    profile?: AgentBusinessCardDTO,
    opinions?: AgentOpinion[],) {
    super(userDTO);
    if (userDTO) {
      if (userDTO.canDelegateToCreatedByBok) {
        userDTO.canDelegateToCreatedByBok = userDTO.canDelegateToCreatedByBok.map(x => new User(x));
      }
      if (userDTO.canDelegateToCreatedByMe) {
        userDTO.canDelegateToCreatedByMe = userDTO.canDelegateToCreatedByMe.map(x => new User(x));
      }
      if (userDTO.officeAddress) {
        userDTO.officeAddress = new Address(userDTO.officeAddress);
      }
      if (profile) {
        this.profile = new AgentBusinessCard(profile);
      }
      if (opinions) {
        this.opinions = opinions;
      }
      if (userDTO.cvrSortingForStartersEndsAt) {
        userDTO.cvrSortingForStartersEndsAt = dateAndTimeFormat(userDTO.cvrSortingForStartersEndsAt);
      }
      merge(this, userDTO);
    }
    if (privileges) {
      this.privileges = privileges;
    }
    if (quiz) {
      this.quiz = quiz;
    }
  }

  get textToSearch(): string {
    return [
      this.displayName,
      this.email,
    ].join('');
  }

  get displayName(): string {
    return (this.firstName || this.lastName) ? `${this.firstName || ''} ${this.lastName || ''}`.trim() : '';
  }

  get organisationName(): Nullable<string> {
    return this.organisationId ? I18NGetter().OrganisationId[this.organisationId] : null;
  }

  get displayNameWithId(): string {
    return `${this.displayName} #${this.id}`;
  }

  get displayNameWithOrganisation(): string {
    if (this.organisationName) {
      return `${this.displayName} (${this.organisationName})`;
    } else {
      return `${this.displayName}`;
    }
  }

  get isUserOf(): Record<keyof typeof OrganisationId, boolean> {
    return mapValues(normalizeNumberEnum(OrganisationId),
      (v) => v === this.organisationId) as Record<keyof typeof OrganisationId, boolean>;
  }

  get isVerifier(): boolean {
    return Boolean(this.role && this.role.id === RoleTypes.VERIFIER);
  }

  get isAdmin(): boolean {
    return Boolean(this.role && this.role.id === RoleTypes.ADMIN);
  }

  get isCoordinator(): boolean {
    return Boolean(this.role && this.role.id === RoleTypes.COORDINATOR);
  }

  get isAdminOrVerifier(): boolean {
    return Boolean(this.role && [RoleTypes.ADMIN, RoleTypes.VERIFIER,].includes(this.role.id!));
  }

  isVerifierOf(application: LoanApplication): boolean {
    return Boolean(application.verifierId && application.verifierId === this.id);
  }

  isVerifierOfDeal(deal: Deal): boolean {
    return Boolean(deal.verifierId && deal.verifierId === this.id);
  }

  get isChiefOfDivision(): boolean {
    return (this.divisionsChief?.length || 0) > 0;
  }

  get isChiefOfOrganisation(): boolean {
    if (this.organisationsChief) {
      return this.organisationsChief.length > 0;
    } else {
      return false;
    }
  }

  get isReportDepartment(): boolean {
    return this.privileges.isHeadOfAccounting || this.isCypressAccount;
  }

  get isRealEstateUser(): boolean {
    return this.organisationId === OrganisationId.HOME_ESTATE;
  }

  get isCypressAccount(): boolean {
    return this.id === 8035;
  }

  get canProcessExternals(): boolean {
    return !this.isUserOf.RANKOMAT && !this.isUserOf.LEADGURU && !this.isUserOf.HIPORANKER && !this.isUserOf.LOANDO;
  }

  get showOnlyHisOrganisation(): boolean {
    return this.isVerifier || !this.isUserOf.FINPACK;
  }

  get isLendiCareUser(): boolean {
    return this.privileges.hasAccessToLendiCareLead || this.privileges.hasAccessToLendiCare;
  }

  get hasAccessToLoans(): boolean {
    return !this.privileges.mortgageLoansDisabled || !this.privileges.cashLoansDisabled || !this.privileges.smeLoansDisabled;
  }

  get isLendiCareChiefWithoutAccessToLoans(): boolean {
    return this.isLendiCareUser && this.isChiefOfDivision && !this.hasAccessToLoans;
  }

  get isFinpackClient(): boolean {
    const finpackClientOrganisationIds: OrganisationId[] = [
      OrganisationId.FINPACK,
      OrganisationId.M_FINANSE,
    ];
    return EnvironmentService.Environment.isFinpackClientAvailable &&
      !this.isAdmin &&
      (finpackClientOrganisationIds.includes(this.organisationId!) || this.organisationId === null);
  }

  get isAppDomain(): Record<AppDomainKey, boolean> {
    return EnvironmentService.Environment.isAppDomain();
  }

  get canAccessModule(): Record<CrmModules, boolean> {
    const hasAccessToModule = EnvironmentService.Environment.hasAccessToModule;
    return {
      [CrmModules.CALENDAR]: (this.isRealEstateUser && this.privileges.simplePartnerView) ||
      !this.privileges.simplePartnerView,
      [CrmModules.MAIL]: !this.isFinpackClient && !this.privileges.simplePartnerView,
      [CrmModules.KNOWLEDGE]: hasAccessToModule(CrmModules.KNOWLEDGE) && !this.isFinpackClient &&
      !this.privileges.simplePartnerView && this.privileges.hasFilemanagerAccess,
      [CrmModules.DISCUSSIONS]: !this.isFinpackClient && !this.privileges.simplePartnerView &&
      !this.isAppDomain.NESTO_RO,
      [CrmModules.DISCOUNTS_BASE]: !this.isFinpackClient && !this.privileges.simplePartnerView && !this.isAppDomain.NESTO_RO,
      [CrmModules.EARN_WITH_LENDI]: !this.isFinpackClient && !this.privileges.simplePartnerView &&
      !this.isAppDomain.NESTO_RO,
      [CrmModules.PROSPECTS]: !this.isFinpackClient && this.privileges.canManageProspects,
      [CrmModules.WIDGETS]: !this.isFinpackClient && this.privileges.canManage.widgets && !this.isAppDomain.NESTO_RO,
      [CrmModules.LENDI]: !this.isFinpackClient,
      [CrmModules.OMNISEARCH]: !this.isFinpackClient,
      [CrmModules.LENDI_BEGININGS]: !this.isFinpackClient && !this.privileges.simplePartnerView &&
      !this.isAppDomain.NESTO_RO,
      [CrmModules.REPORTS]: !this.isFinpackClient && !this.privileges.simplePartnerView,
      [CrmModules.DISCOUNTS]: !this.isFinpackClient && !this.privileges.simplePartnerView &&
      !this.isAppDomain.NESTO_RO,
      [CrmModules.MY_DIVISIONS]: !this.privileges.simplePartnerView &&
      ([OrganisationId.LENDI, OrganisationId.FINAMAX,].includes(this.organisationId!) || this.isAdminOrVerifier),
      [CrmModules.NOTIFICATION]: !this.isFinpackClient,
      [CrmModules.LENDI_CREW]: !this.isFinpackClient && !this.privileges.simplePartnerView &&
      !this.isAppDomain.NESTO_RO,
      [CrmModules.DEALS]: !this.isUserOf.PORADNIK_KREDYTOWY,
      [CrmModules.CLIENTS]: !this.isUserOf.PORADNIK_KREDYTOWY,
      [CrmModules.DEAL_KANBAN]: !this.isFinpackClient,
      [CrmModules.DEAL_DETAILS]: !this.isFinpackClient,
      [CrmModules.USER_AVATAR]: !this.isFinpackClient,
      [CrmModules.CLIENT]: !this.privileges.simplePartnerView,
      [CrmModules.BIK]: !this.privileges.simplePartnerView && !this.isAppDomain.NESTO_RO,
      [CrmModules.PARTNERS]: !this.privileges.simplePartnerView,
      [CrmModules.ACCREDITATION_PANEL]: !this.isAppDomain.NESTO_RO,
      [CrmModules.LOAN_APPLICATIONS]: !this.privileges.simplePartnerView,
      [CrmModules.LIVESPACE]: !this.privileges.simplePartnerView,
      [CrmModules.USER_MANAGEMENT]: this.isAdminOrVerifier,
      [CrmModules.NPS]: this.isAdminOrVerifier,
      [CrmModules.HUMAN_RESOURCES]: this.isAdminOrVerifier,
      [CrmModules.DELEGATION_LIST]: this.isAdminOrVerifier,
      [CrmModules.BANK_BRANCHES]: this.isAdminOrVerifier,
      [CrmModules.BIG_AGREEMENTS]: this.isAdminOrVerifier,
      [CrmModules.SMS_LOGS]: this.isAppDomain.NESTO_RO ? this.isAdmin : this.isAdminOrVerifier,
      [CrmModules.EMAIL_LOGS]: this.isAppDomain.NESTO_RO ? this.isAdmin : this.isAdminOrVerifier,
      [CrmModules.PROSPECTS_SETTINGS]: this.isAdminOrVerifier,
      [CrmModules.MULTIFORM]: true,
      [CrmModules.PRODUCTS]: this.isAdmin,
      [CrmModules.OLD_COMMISSIONS]: this.privileges.canEditProvisions,
      [CrmModules.CASH_COMPARISON_TOOL]: true,
      [CrmModules.MORTGAGE_COMPARISON_TOOL]: true,
    };
  }

  get canStartOnboarding(): Record<TourName, boolean> {
    return {
      [TourName.DASHBOARD]: !this.isFinpackClient,
      [TourName.MORTGAGE_CALCULATOR]: true,
      [TourName.LEAD]: true,
    };
  }

  get canEditModule(): PartialRecord<CrmModules, boolean> {
    return {
      [CrmModules.LENDI_CREW]: this.isAdmin,
      [CrmModules.MULTIFORM]: EnvironmentService.Environment.hasAccessToModule(CrmModules.MULTIFORM) &&
      (this.isAdminOrVerifier),
    };
  }

  get userCanDisplay(): Record<UserDetailsSegments, boolean> {
    return {
      [UserDetailsSegments.AVAILABILITY]: true,
      [UserDetailsSegments.USER_DATA]: EnvironmentService.Environment.isUserDataV2Available &&
      this.privileges.canManageOrganisation,
      [UserDetailsSegments.HISTORY]: true,
      [UserDetailsSegments.CUSTOM_LOGO]: this.privileges.hasAccessToCustomLogo || this.isAdminOrVerifier,
      [UserDetailsSegments.DELEGATION_LIST]: true, // this.isAdminOrVerifier,
      [UserDetailsSegments.BANKS_PERMISSION]: EnvironmentService.Environment.isBanksPermissionsAvailble, // this.isAdminOrVerifier,
      [UserDetailsSegments.AGENT_BUSINESS_CARD]: EnvironmentService.Environment.isBusinessCardsAvailble, // this.isAdminOrVerifier,
    };
  }
}
