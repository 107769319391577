import {
  OfferDataTableElementKey,
  OfferDataTableGroupKey,
  OfferTableElement
} from '@/components/calculator/results/offers/OfferTableElement';
import {UserLocalStorage} from '@/services/UserLocalStorage';
import {SimulationProductType} from '@/components/calculator/services/SimulationStore';
import {computed, ref} from 'vue';
import {ProductType} from '@/commons/enums/ProductType';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import {difference} from 'lodash-es';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import UiStorage from '@/composables/useUiStorage/UiStorageService';

export type OfferTableElementPrintoutKey = OfferDataTableElementKey | OfferDataTableGroupKey;

export interface OfferTableElementPrintout {
  header: () => string;
  key: OfferTableElementPrintoutKey;
  children?: OfferTableElementPrintout[];
  open?: boolean;
}

export interface PrintoutSettings {
  selectedItems: OfferTableElementPrintoutKey[];
  isAdditionalInfoHidden: boolean;
}

export const usePrintoutSettings = (productType: Nullable<SimulationProductType>) => {
  if (!productType) {
    throw new Error(`productType is invalid ${productType}`);
  }

  const {getPrintoutSettingsElements,} = useOffersTable();

  const EXCLUDED_TABLE_ELEMENT_KEYS: string = `${productType}_EXCLUDED_TABLE_ELEMENT_KEYS`;
  const IS_ADDITIONAL_INFO_HIDDEN: string = `${productType}_IS_ADDITIONAL_INFO_HIDDEN`;

  const IS_ADDITIONAL_INFO_HIDDEN_DEFAULT_VALUE: boolean = true;

  const hiddenElementsKeysByProductType = ref<Record<SimulationProductType, Array<OfferTableElementPrintoutKey>>>({
    [ProductType.CASH]: [],
    [ProductType.MORTGAGE]: [
      OfferDataTableElementKey.ADDITIONAL_PRODUCTS,
      OfferDataTableElementKey.PAYMENT_SCHEDULE,
    ],
  });

  const excludedByDefaultElementsKeysByProductType =
    ref<Record<SimulationProductType, Array<OfferTableElementPrintoutKey>>>({
      [ProductType.CASH]: [
        OfferDataTableElementKey.DESCRIPTION,
        OfferDataTableElementKey.ADDITIONAL_PRODUCTS_INFO,
      ],
      [ProductType.MORTGAGE]: [
        OfferDataTableElementKey.COSTS_SUM,
        OfferDataTableElementKey.COSTS_SUM_BRIDGING_INSURANCE,
        OfferDataTableElementKey.COSTS_SUM_INTEREST,
        OfferDataTableElementKey.COSTS_SUM_CREDIT_CARD,
        OfferDataTableElementKey.COSTS_SUM_PROVISION,
        OfferDataTableElementKey.COSTS_SUM_LOW_PERSONAL_SHARE_INSURANCE,
        OfferDataTableElementKey.COSTS_SUM_REAL_ESTATE_VALUATION,
        OfferDataTableElementKey.COSTS_SUM_PERSONAL_ACCOUNT,
        OfferDataTableElementKey.COSTS_SUM_INSURANCE_PACKAGE,
        OfferDataTableElementKey.COSTS_SUM_REAL_LIFE_INSURANCE,
        OfferDataTableElementKey.COSTS_SUM_REAL_ESTATE_INSURANCE,
        OfferDataTableElementKey.COSTS_SUM_PAYMENT_INSURANCE,
        OfferDataTableElementKey.DESCRIPTION,
        OfferDataTableElementKey.ADDITIONAL_PRODUCTS_INFO,
        OfferDataTableElementKey.TRANCHE_ACCOUNTING,
        OfferDataTableElementKey.PERCENTAGE_FOR_WHICHEVER_EXPENSE,
        OfferDataTableElementKey.PERCENTAGE_FOR_WHICHEVER_EXPENSE_DESCRIPTION,
        OfferDataTableElementKey.CONVERSION,
        OfferDataTableElementKey.CURRENCY_SPREAD,
        OfferDataTableElementKey.BUY_RATE,
        OfferDataTableElementKey.GOV_PROGRAM,
      ],
    });

  const hiddenElementsKeys = computed<OfferTableElementPrintoutKey[]>(
    () => hiddenElementsKeysByProductType.value[productType]
  );

  const createGroup = (key: OfferDataTableGroupKey, header: () => string): OfferTableElementPrintout => ({
    key,
    header,
    children: getPrintoutSettingsElements(productType)
      .filter((element: OfferTableElement) => element.groupKey === key)
      .map((element: OfferTableElement) => ({
        ...element,
        children: element.children?.filter(child => child.header()),
      })),
  });
  const excludedByDefaultElementsKeys = computed<OfferTableElementPrintoutKey[]>(
    () => excludedByDefaultElementsKeysByProductType.value[productType]
  );

  const groupedElements = ref<OfferTableElementPrintout[]>([
    createGroup(OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP, () => I18NGetter().usePrintout.BASIC_PARAMETERS),
    createGroup(OfferDataTableGroupKey.X_SELL_GROUP, () => I18NGetter().usePrintout.CROSS_SELL),
  ],
  );

  const restElements = computed<OfferTableElementPrintout[]>(() => {
    return getPrintoutSettingsElements(productType)
      .map((element: OfferTableElement) => ({
        ...element,
        children: element.children?.filter(child => child.header()),
      }))
      .filter((element: OfferTableElement) => !hiddenElementsKeys.value
        .includes(element.key) && !element.groupKey);
  });

  const getExcludedTableElementKeys = async(): Promise<OfferTableElementPrintoutKey[]> => {
    return await UiStorage.getItem<OfferTableElementPrintoutKey[]>(EXCLUDED_TABLE_ELEMENT_KEYS) ??
      excludedByDefaultElementsKeys.value;
  };

  const saveExcludedTableElementKeys = async(excludedKeys: OfferTableElementPrintoutKey[]) => {
    await UiStorage.setItem(EXCLUDED_TABLE_ELEMENT_KEYS, excludedKeys);
  };

  const getIsAdditionalInfoHiddenValue = async(): Promise<boolean> => {
    const isAdditionalInfoHidden: Nullable<string> = await UiStorage.getItem(IS_ADDITIONAL_INFO_HIDDEN);
    return Boolean(isAdditionalInfoHidden ?? IS_ADDITIONAL_INFO_HIDDEN_DEFAULT_VALUE);
  };

  const saveIsAdditionalInfoHiddenValue = async(isAdditionalInfoHidden: boolean) => {
    await UiStorage.setItem(IS_ADDITIONAL_INFO_HIDDEN, isAdditionalInfoHidden);
  };

  const tableElementKeys = computed<OfferTableElementPrintoutKey[]>(() => {
    return [...groupedElements.value, ...restElements.value,]
      .map((element: OfferTableElementPrintout) => element.children?.length
        ? element.children?.map(child => child.key) : element.key)
      .flat();
  });

  const getExcludedKeys = (selectedItems: OfferTableElementPrintoutKey[]): OfferTableElementPrintoutKey[] => {
    return difference(tableElementKeys.value, selectedItems);
  };

  const savePrintoutSettings = (selectedItems: OfferTableElementPrintoutKey[], isAdditionalInfoHidden: boolean) => {
    saveExcludedTableElementKeys(getExcludedKeys(selectedItems));
    saveIsAdditionalInfoHiddenValue(isAdditionalInfoHidden);
  };

  const elements = computed<OfferTableElementPrintout[]>(() => [
    ...groupedElements.value,
    ...restElements.value,
  ]);

  const getPrintoutSettings = async(): Promise<PrintoutSettings> => {
    return {
      selectedItems: difference(tableElementKeys.value, await getExcludedTableElementKeys()),
      isAdditionalInfoHidden: await getIsAdditionalInfoHiddenValue(),
    };
  };

  return {
    getExcludedTableElementKeys,
    getIsAdditionalInfoHiddenValue,
    savePrintoutSettings,
    getPrintoutSettings,
    hiddenElementsKeys,
    elements,
    tableElementKeys,
    IS_ADDITIONAL_INFO_HIDDEN_DEFAULT_VALUE,
  };
};
