import {mapObject} from 'underscore';
import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {Utils} from '@/services/utils/BasicUtils';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {BankMetaData} from '@/commons/dictionaries/financialInstitutions/FinancialInstitutions';
import {useBankLogo} from '@/modules/banks/useBankLogo';

export abstract class BankDTO {
  id!: number;
  symbol!: BankSymbol;
  name!: string;
  logoUrl?: string;
}
export class Bank extends BankDTO {
  constructor(bankDTO?: BankDTO) {
    super();
    if (bankDTO) {
      Utils.assign(this, bankDTO);
    }
  }

  public get meta(): BankMetaData | null {
    const foundBank = banksDictionary().find(bank => bank.type === this.symbol);
    return foundBank ? foundBank.meta || null : null;
  }

  public get symbolRequire() {
    const foundBank = banksDictionary().find(bank => bank.type === this.symbol);
    return foundBank?.symbolRequire ?? useBankLogo(this.symbol, this.logoUrl);
  }

  public get is(): Record<BankSymbol, boolean> {
    return mapObject(BankSymbol as any, (bank: BankSymbol) => bank === this.symbol) as Record<BankSymbol, boolean>;
  }
}
