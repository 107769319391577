import BaseStoreService from '@/store/BaseStoreService';
import {PartnerStoreState} from '@/modules/partner/services/store/PartnerStoreState';
import {PartnerStoreModule} from '@/modules/partner/services/store/PartnerStoreModule';

class PartnerStore extends BaseStoreService<PartnerStoreState> {
  public mutations = PartnerStoreModule.mutations;

  public setPartnerApiActive(status: boolean) {
    this.commit(this.mutations.setPartnerApiActive, status);
  }

  public get partners() {
    return this.read(PartnerStoreModule.getters.partners);
  }

  public get sources() {
    return this.read(PartnerStoreModule.getters.partners).flatMap(x => x.sources);
  }
}

export default new PartnerStore();
