import BaseStoreService from '@/store/BaseStoreService';
import {UserStoreState} from '@/modules/user/services/store/UserStoreState';
import {UserStoreModule} from '@/modules/user/services/store/UserStoreModule';
import User from '@/models/user/User';
import UserApi from '@/modules/user/services/UserApi';
import { OrganisationId } from '@/models/enums/OrganisationId';
import { Prospect } from '@/models/Prospect';
import ProspectApi from '@/modules/prospects/services/ProspectApi';
import EnvironmentService from '@/env/EnvironmentService';

class UserStore extends BaseStoreService<UserStoreState> {
  public mutations = UserStoreModule.mutations;

  public get getActiveUsersForProspects() {
    return this.read<Array<User>>(UserStoreModule.getters.getActiveUsersForProspects);
  }

  public get getUsersForFilters() {
    return this.read<Array<User>>(UserStoreModule.getters.getUsersForFilters);
  }

  public async fetchUsersForFilters() {
    if (this.getUsersForFilters.length === 0) {
      try {
        const users = await UserApi.usersForFilters({
          from_my_organisation: EnvironmentService.Environment.isAppDomain().NESTO_RO ? true : undefined,
        });
        this.commit(this.mutations.setUsersForFilters, users);
      } catch (e) {
        console.error(e);
      }
    }
  }

  public async fetchActiveUsersForProspects2(prospect: Prospect, usersAdditionalFilters?) {
    try {
      const users = await ProspectApi.getUsersForMeeting(prospect);
      this.commit(this.mutations.setActiveUsersForProspects, users as unknown as User[]);
    } catch (e) {
      console.error(e);
    }
  }

  public async fetchActiveUsersForProspects() {
    try {
      const users = await UserApi.usersForFilters(
        {
          is_active: true,
          can_manage_prospects: true,
          organisation_id: [OrganisationId.LENDI, OrganisationId.HOME_ESTATE, ],
        });
      this.commit(this.mutations.setActiveUsersForProspects, users);
    } catch (e) {
      console.error(e);
    }
  }

  clearUsers() {
    this.commit(this.mutations.clearUsers, []);
  }

  public getUserById(id: number) {
    return this.getUsersForFilters.find(user => user.id === id);
  }
}
export default new UserStore();
