import {ActivityKind} from '@/modules/activities/enums/ActivityKind';
import moment, {Moment} from 'moment';
import Client, {ClientDTO} from '@/models/Client';
import {AddressMeetingKind} from '@/commons/enums/AddressMeetingKind';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import DateUtils from '@/services/utils/DateUtils';
import {dateTextFormat} from '@/filters/DateFilters';
import {ProductType} from '@/commons/enums/ProductType';
import {ActivityPriority} from '@/modules/activities/enums/ActivityPriority';
import {ActivityKindsDictionary} from '@/modules/activities/dict/ActivityDictionary';
import {DealDTO} from '@/models/Deal';
import User from '@/models/user/User';
import { RoleTypeNames } from '@/commons/enums/RoleTypeNames';
import { copyExistingNonEmptyProperties } from '@/services/utils/BasicUtils';

export const DEFAULT_ACTIVITY_DURATION_BY_KIND: Record<ActivityKind, number> = {
  [ActivityKind.PHONE]: 30,
  [ActivityKind.MEETING]: 60,
  [ActivityKind.EMAIL]: 30,
  [ActivityKind.SIGNING_DOCUMENTS]: 60,
  [ActivityKind.VACATION]: 60,
  [ActivityKind.OTHER]: 60,
};
export const MIN_ACTIVITY_HOUR = '5:30';

export abstract class ActivityDTO {
  public dealId?: number = undefined;
  public name: string = '';
  public kind: ActivityKind = ActivityKind.MEETING;
  public isDone: boolean = false;
  public isFullDay: boolean = false;
  public assignedTo?: number;
  public assignedToUser?: User;
  public createdBy?: number;
  public createdByUser?: User;
  public createdDate!: string;
  public updatedDate?: string;
  public startDate: Nullable<string> = null;
  public id?: number;
  public client: Nullable<ClientDTO> = null;
  public deal: Nullable<DealDTO> = null;
  public clientId?: number;
  public bankBranch?: {bankId: number,id: number,name: string};
  public bankBranchId?: number;
  public addressId?: number;
  public address?: Address;
  public addressMeetingKind: Nullable<AddressMeetingKind> = null;
  public description?: string;
  public divisions?: [{id: number,name: string}];
  public endDate: string | null = null;
  public endDateToday?: boolean = false;
  public afterEndDate?: boolean = false;
  public priority: ActivityPriority = ActivityPriority.MEDIUM;
  public additionalEmails: Array<string> = [];
  public teleconferenceUrl?: string;
  public requireReporting?: boolean;
  public teleconference?: {
    clientAccessCode: string,
    agentAccessCode: string
  };

  public requireNotification: boolean = true;
  public isBusy: boolean = true;
}

export interface CalendarCategory {
  userId: number;
  user: User;
}
export default class Activity extends ActivityDTO {
  public productType: Nullable<ProductType> = null;
  public userIds?: number[];
  public attachment: Nullable<File> = null;

  constructor(activityDTO?: Partial<ActivityDTO>) {
    super();
    if (activityDTO) {
      if (activityDTO.client) {
        activityDTO.client = new Client(activityDTO.client);
      }
      if (activityDTO.assignedToUser) {
        activityDTO.assignedToUser = new User(activityDTO.assignedToUser);
      }
      if (activityDTO.bankBranch?.id) {
        activityDTO.bankBranchId = activityDTO.bankBranch.id;
      }
      Object.assign(this, activityDTO);
    }
  }

  public get isMeetingInOffice(): boolean {
    return this.isMeeting && this.addressMeetingKind === AddressMeetingKind.OFFICE;
  }

  // cannot change this prop name, because it's used by v-calendar for chief day view for multiple members
  public get category(): number | undefined {
    return this.assignedToUser?.id;
  }

  public get isMeetingOnline(): boolean {
    return this.addressMeetingKind === AddressMeetingKind.TELECONFERENCE;
  }

  public get isNeedForSummarize(): boolean {
    return !this.isDone &&
      (this.requireReporting! || this.deal?.partner?.requireReporting!) &&
      (!!this.dealId && (this.isMeeting || this.isPhoneMeeting));
  }

  public get shouldBeSummarized(): boolean {
    return !!this.dealId &&
      (this.requireReporting! || this.deal?.partner?.requireReporting!) &&
      (this.isMeeting || this.isPhoneMeeting);
  }

  public get defaultDuration(): number {
    return DEFAULT_ACTIVITY_DURATION_BY_KIND[this.kind];
  }

  public get isMeeting(): boolean {
    return this.kind === ActivityKind.MEETING;
  }

  public get isVacation(): boolean {
    return this.kind === ActivityKind.VACATION;
  }

  public get isEndDateRequired(): boolean {
    return this.kind === ActivityKind.VACATION;
  }

  public get isOther(): boolean {
    return this.kind === ActivityKind.OTHER;
  }

  public get isPhoneMeeting(): boolean {
    return this.kind === ActivityKind.PHONE;
  }

  public get isMeetingInClientHome(): boolean {
    return this.addressMeetingKind === AddressMeetingKind.CLIENT;
  }

  public get endDateObject(): Date | null {
    if (this.endDate) {
      return new Date(this.endDate);
    }
    return null;
  }

  public set endDateObject(newEndDate: Date | null) {
    this.endDate = newEndDate ? newEndDate.toISOString() : null;
    if (newEndDate && this.startDate && moment(this.startDate).isSameOrAfter(newEndDate)) {
      this.startDate = moment(newEndDate).subtract(this.defaultDuration, 'minutes').toISOString();
    }
  }

  public get startDateObject(): Date | null {
    return this.startDate ? new Date(this.startDate) : null;
  }

  public set startDateObject(newDate: Date | null) {
    this.startDate = newDate ? newDate.toISOString() : null;
    if (newDate && this.endDate && moment(newDate).isSameOrAfter(this.endDate)) {
      this.endDate = moment(newDate).add(this.defaultDuration, 'minutes').toISOString();
    }
  }

  public get createdDateObject(): Date {
    return new Date(this.createdDate);
  }

  public get startTime(): string {
    return moment(String(this.startDate)).format('HH:mm');
  }

  public get endTime(): string {
    return moment(String(this.endDate)).format('HH:mm');
  }

  public get startDateTime(): string {
    return this.startDate ? dateTextFormat(this.startDate) : '';
  }

  public get duration(): number {
    if (this.endDateObject && this.startDateObject) {
      return Math.round(((this.endDateObject.getTime() - this.startDateObject.getTime() % 86400000) % 3600000) / 60000);
    } else return 0;
  }

  public get icon(): Nullable<string> {
    return this.isMeeting && this.isMeetingOnline
      ? 'mdi-video-outline' : ActivityKindsDictionary.find(x => x.type === this.kind)?.icon || null;
  }

  public get calendarCreatedAt(): string | null {
    if (this.createdDate) {
      return moment(this.createdDate).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return null;
    }
  }

  public get calendarStartedAt(): string | null {
    if (this.startDate) {
      return moment(this.startDate).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return null;
    }
  }

  public get calendarEndedAt(): string | null {
    if (this.endDate) {
      return moment(this.endDate).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return null;
    }
  }

  public get calendarUpdatedAt(): string | null {
    if (this.updatedDate) {
      return moment(this.updatedDate).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return null;
    }
  }

  public get isTodayActivity(): boolean {
    return this.startDate ? DateUtils.isToday(this.startDate) : false;
  }

  public get isTomorrowActivity(): boolean {
    return this.startDate ? DateUtils.isTomorrow(this.startDate) : false;
  }

  public get isDayAfterTomorrowActivity(): boolean {
    return this.startDate ? DateUtils.isDayAfterTomorrow(this.startDate) : false;
  }
}

// consider add a namespace for all requests
export class ActivityRequest {
  name: string = '';
  startDate: Date | number = 0;
  kind: ActivityKind = ActivityKind.MEETING;
  isDone: boolean = false;
  assignedTo: number = 0;
  createdBy: number = 0;
  description: string = '';
  dealId: number = 0;
  clientId: number = 0;
  endDate?: Date | number;
  isFullDay: boolean = false;
  addressId: number = 0;
  addressMeetingKind: AddressMeetingKind = AddressMeetingKind.OTHER;
  bankBranchId: number = 0;
  priority?: ActivityPriority;
  additionalEmails?: string[];
  requireReporting?: boolean;
  requireNotification: boolean = false;
  requestContextRole?: RoleTypeNames;
  isBusy: boolean = false;
  id: number = 0;

  constructor(activityRequest: Partial<Activity | ActivityRequest>) {
    if (activityRequest) {
      copyExistingNonEmptyProperties(this, activityRequest);
    }
  }
}

export interface IActivityCalendarElement {
  activities: Activity[];
  date: string;
  fullDate: Moment;
  dayName: string;
  isToday: boolean;
}
