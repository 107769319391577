<template>
  <tr :class="{'early-payment-row': !!item.earlyPayment || !!item.interestSubsidy}"
      class="text-right">
    <td>{{ convertMonthToYear(item.currentMonth) }} / {{ item.currentMonth }}</td>
    <td>{{ item.monthlyFee | numeralFormat }} {{ $env.currentCurrency() }}</td>
    <td>{{ item.interestFee | numeralFormat }} {{ $env.currentCurrency() }}</td>
    <td>{{ item.capitalFee | numeralFormat }} {{ $env.currentCurrency() }}</td>
    <template v-if="store.showAdditionalCosts">
      <template v-if="item.additionalCosts">
        <v-tooltip bottom v-if="!print">
          <div v-for="(element, index) in item.additionalCosts" :key="element.name">
            <span :class="(index + 1) !== item.additionalCosts.length ? 'pb-2' : 'pb-0'">{{$i18n.useScheduleModal[getKeyByValue(element.name)] ?? 'No key'}}: {{element.fee}}</span>
          </div>
          <template v-slot:activator="{ on, attrs }">
            <td  v-bind="attrs" v-on="on" class="on-hover">
              {{ additionalCostsSum(item) | numeralFormat }} {{ $env.currentCurrency() }}
            </td>
          </template>
        </v-tooltip>
        <td v-else>{{ additionalCostsSum(item) | numeralFormat }} {{ $env.currentCurrency() }}</td>
      </template>
      <td v-else>-</td>
      <td>{{monthlyCostSum(item) | numeralFormat}} {{ $env.currentCurrency() }}</td>
    </template>
    <template v-if="store.isHomeStartProgram">
      <td v-if="item.interestSubsidy" >{{item.interestSubsidy | numeralFormat}} {{$env.currentCurrency()}}</td>
      <td v-else>-</td>
    </template>
    <td>
      <div class="flex flex-column">
        <span>{{ item.capitalPaid | numeralFormat }} {{ $env.currentCurrency() }}</span>
        <span class="primary--text" v-if="!!item.earlyPayment">+ {{ item.earlyPayment | numeralFormat }} {{ $env.currentCurrency() }}</span>
      </div>
    </td>
    <td>
      <div class="flex flex-column">
        <span>{{ item.actualCapital | numeralFormat }} {{ $env.currentCurrency() }}</span>
        <span class="primary--text" v-if="!!item.earlyPayment">- {{ item.earlyPayment | numeralFormat }} {{ $env.currentCurrency() }}</span>
      </div>
    </td>
  </tr>
</template>
<script lang="ts" setup>

import {ScheduleCostType, ScheduleRow} from '@/components/calculator/schedule/Schedule';
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';
import {sum} from 'lodash-es';

const props = defineProps<{
  item: ScheduleRow;
  print?: boolean;
}>();

const store = useScheduleStore();

const convertMonthToYear = (month: number): number => Math.ceil(month / 12);

const additionalCostsSum = (item: ScheduleRow): number => {
  return sum(item.additionalCosts?.map(cost => cost.fee)) || 0;
};

const monthlyCostSum = (item: ScheduleRow): number => {
  return item.monthlyFee + additionalCostsSum(item);
}

const getKeyByValue = (value: string): keyof typeof ScheduleCostType | undefined => {
  return Object.keys(ScheduleCostType).find((key) => ScheduleCostType[key] === value) as keyof typeof ScheduleCostType | undefined;
}
</script>
<style lang="scss" scoped>
.on-hover {
  text-decoration: underline dotted;
  &:hover {
    color: rgba($primary, .8);
    text-decoration: underline dotted;
  }
}
.v-tooltip__content {
  padding: 8px 16px !important;
}
</style>
