import { TransitionParams } from '@/models/Transition';
import DateUtils from '@/services/utils/DateUtils';
import StringUtils from '@/services/utils/StringUtils';
import {cloneDeep, omit, pick, assign, debounce, pickBy, isEmpty, transform} from 'lodash-es';
import {DivisionDetailsTabs} from '@/modules/accredition/models/DivisionDetails';
type RangeStart = number;
type RangeEnd = number;

export type RangeTuple = [RangeStart, RangeEnd];

const TRANSITION_CLASS = 'element-transition';
const TRANSITION_DURATION_VARIABLE = '--element-transition-duration';
const TRANSITION_PROPERTY_VARIABLE = '--element-transition-property';

export const PESEL_MASK = '###########';

export const wait = (duration: number) =>
  new Promise<void>(resolve => setTimeout(() => {
    resolve();
  }, duration));

export function clone<T extends object = any>(originalObject: T): T {
  return cloneDeep(originalObject);
}

export function generateId(): number {
  return Math.floor(Math.random() * 500000) + 10000;
}
// eslint-disable-next-line vue/max-len
export function convertArrayToBooleanRecord<T extends string>(arr: T[], func: (param: T) => boolean): Record<T, boolean> {
  return arr.reduce((obj, currentValue) => {
    return {...obj, [currentValue]: func(currentValue),};
  }, {}) as Record<T, boolean>;
}

export function pickFile(): Promise<File> {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => {
      if (((e.target instanceof HTMLInputElement && e.target.files))) {
        const file = e.target.files[0];
        resolve(file);
      } else {
        reject(new Error('Not valid target'));
      }
    };
    input.click();
  });
}

export const debouncedAtInput = (fn: (...args: any[]) => void, delay = 400, immediate = false) => {
  return debounce(fn, delay);
};

export const findClosestValue = (array: number[], value: number) => {
  return array.reduce((prev, curr) => {
    return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
  });
};

export const genUUID = (): string => crypto.randomUUID();

export const Utils = {
  charWidth: 8,
  date: DateUtils,
  string: StringUtils,
  isEmpty<T extends object>(originalObject: Nullable<T>, keysToOmit: Array<keyof T> = []): boolean {
    return originalObject === null || Object
      .values(omit<T>(originalObject, keysToOmit))
      .filter(value => value !== null && value !== undefined)
      .length === 0;
  },
  isEmptyValue(value: any) {
    return value === undefined || value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0) ||
      !value;
  },
  getFirstQuery(value: string | (string | null)[]): string {
    return Array.isArray(value) ? String(value[value.length - 1]) : value;
  },
  parseJson<T extends any>(o: any): T | null {
    try {
      return JSON.parse(o) as T;
    } catch (e) {
      if (['false', 'true',].includes(o)) {
        return (o === 'true') as T;
      } else {
        return o as T;
      }
    }
  },
  keys<T>(o: Keys<T>): (keyof T)[] {
    return Object.keys(o) as (keyof T)[];
  },
  changeToNull<T extends any = any>(obj: T): NullableDeep<T> {
    if (!!obj && typeof obj === 'object') {
      for (const key in obj) {
        if (!!obj[key] && typeof obj[key] === 'object') {
          Utils.changeToNull(obj[key]);
        } else {
          if (obj[key] as any === '') {
            (obj[key] as any) = null;
          }
        }
      }
    }
    return obj;
  },
  goThrough<T>(obj: T, func: (x: any, key: string, obj: any) => void): T {
    if (!!obj && typeof obj === 'object') {
      for (const key in obj) {
        if (!!obj[key] && typeof obj[key] === 'object') {
          Utils.goThrough(obj[key], func);
        } else {
          func(obj[key], key, obj);
        }
      }
    }
    return obj;
  },
  getPercentageFilled<T>(data: T): number {
    let nulls = 0;
    let fields = 0;
    Utils.goThrough(data, (value, key, obj) => {
      if (value === null) {
        nulls++;
      }
      fields++;
    });
    return Math.round((fields - nulls) * 10000 / fields) / 100;
  },
  omit<T extends object>(data: T, ...keys: (keyof T)[]) {
    return omit(clone(data), keys);
  },
  pick<T extends object>(data: T, ...keys: (keyof T)[]) {
    return pick<T>(clone(data), keys) as T;
  },
  assign<T extends object, K = T>(data: T, objectToAssign: K): T {
    return assign<T, K>(data, objectToAssign);
  },
  makeTransition(params: TransitionParams = new TransitionParams()) {
    const durationSafeMultiplier = 1.05;
    const rootElement: HTMLElement = document.querySelector(params.rootElementSelector) || document.body;
    rootElement.classList.add(TRANSITION_CLASS);
    rootElement.style.setProperty(TRANSITION_DURATION_VARIABLE, `${params.duration}ms`);
    rootElement.style.setProperty(TRANSITION_PROPERTY_VARIABLE, params.properties.join(','));

    params.callback();
    setTimeout(() => {
      rootElement.classList.remove(TRANSITION_CLASS);
    }, durationSafeMultiplier * params.duration);
  },
};

// @ts-ignore
export function MapToViewModel(ViewModelClass: { new(...args: any[]): any; }, context: this):
  InstanceType<typeof ViewModelClass> {
  const viewModel = new ViewModelClass();
  Object.keys(viewModel).forEach(key => {
    try {
      viewModel[key] = context[key];
    } catch (e) {
      throw new Error('Invalid context');
    }
  });
  return viewModel;
};

export function scrollToElement(selector: string): void {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }

  const topNavbar = document.querySelector('.v-app-bar.top-navigation');
  const topNavbarHeight = topNavbar ? topNavbar.getBoundingClientRect().height : 0;
  const top = window.scrollY + element.getBoundingClientRect().top - topNavbarHeight;

  window.scroll({top, behavior: 'smooth',});
}
export function getKeyByValue(obj: object, value: any) {
  const keyIndex = Object.values(obj).indexOf(value);
  return Object.keys(obj)[keyIndex] as keyof typeof obj;
}

export function copyNonEmptyProperties<T extends object>(target: T, source: Partial<T>): void {
  assign(target, pickBy(source, property => property !== null && property !== undefined && property !== ''));
}

export function copyExistingNonEmptyProperties<T extends object>(target: T, source: Partial<T>): void {
  for (const key in source) {
    if (key as keyof T in target && source[key] !== null && source[key] !== undefined && source[key] !== '') {
      // @ts-ignore
      target[key as keyof typeof target] = source[key];
    }
  }
}

export function isDeepEmpty<T extends object>(inputObject: Nullable<T>) {
  if (isEmpty(inputObject)) {
    return true
  }
  if (typeof inputObject === 'object') {
    for (const item of Object.values(inputObject)) {
      if (item instanceof Date) {
        return false
      }
      if ((item !== undefined && typeof item !== 'object') || !isDeepEmpty(item)) {
        return false
      }
    }
    return true
  }
  return false
}

// Create ranges from array of numbers, ex: [1,2,3,5,6,7,8] => [[1,3],[5,8]]
export const createRangesFromArrayOfNumbers = (numbers: number[]): RangeTuple[] => {
  if (numbers.length === 0) return [];

  numbers.sort((a, b) => a - b);
  const ranges: Array<RangeTuple> = [];
  let start = numbers[0];
  let end = start;

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] === end + 1) {
      end = numbers[i]; // Extend the current range
    } else {
      ranges.push([start, end,]); // Add the current range to the result
      start = end = numbers[i]; // Start a new range
    }
  }

  ranges.push([start, end,]); // Add the last range

  return ranges;
}

export const removePropertyFromObject = <T extends Record<string, unknown>, K extends string[]>(object: T, keys: K & (keyof T)[])
  : Omit<T,OrUnion<K>> => {
  return transform(cloneDeep(object), (result, value, key: string) => {
    if (!keys.includes(key)) {
      result[key] = value;
    };
    return result;
  },);
}
