import Vue from 'vue';
import NotificationApi, { CRMNotificationObject } from '@/modules/notification/NotificationApi';
import Logger from '@/services/log/Logger';
import AuthService from '@/modules/user/AuthService';
import CRMNotification from '@/models/notification/CRMNotification';

class NotificationService {
  private notifications: CRMNotificationObject = {
    items: [],
    totalUnread: 0,
  };

  private socket: Nullable<WebSocket> = null;

  public get Observable() {
    return Vue.observable(this.notifications);
  }

  public async loadMore(page: number) {
    const response = await NotificationApi.getNotifications(page);
    if (response.items && response.items.length > 0) {
      response.items.forEach(x => this.notifications.items.push(x));
      this.notifications.totalUnread = response.totalUnread;
    }
    return response;
  }

  public async loadNotification() {
    const response = await NotificationApi.getNotifications();
    this.notifications.items = response.items;
    this.notifications.totalUnread = response.totalUnread;
    return response;
  }

  private isNotificationInList(notificationItem: CRMNotification): boolean {
    return this.notifications.items.some(existingItem => existingItem.id === notificationItem.id);
  }

  public async loadMissed() {
    const response = await NotificationApi.getNotifications();
    response.items.forEach((item) => {
      if (!item.isRead && !this.isNotificationInList(item)) {
        this.notifications.items.unshift(item);
        this.notifications.totalUnread++;
      }
    });
  }

  public disconnect() {
    if (this.socket) {
      this.socket.close();
    } else {
      console.warn('No socket to close!');
    }
  }

  public connect() {
    this.socket = NotificationApi.socket;
    if (this.socket) {
      this.socket.onmessage = (data) => this.handleNotification(data);
      this.socket.onerror = (error: Event) => {
        Logger.warning('Occurred websocket error', {error: error, });
      };
      this.socket.onclose = () => {
        if (AuthService.isLoggedIn && AuthService.isTokenValid && !AuthService.isTokenExpired()) {
          setTimeout(() => {
            this.connect();
            this.loadMissed();
          }, 5000);
        }
      };
    }
  }

  private handleNotification(data: MessageEvent) {
    const msgData: any = JSON.parse(data.data) as any;
    if (msgData.totalUnread !== undefined) {
      this.notifications.totalUnread = msgData.totalUnread;
    } else if (msgData.data) {
      this.notifications.items.unshift(JSON.parse(data.data));
      this.notifications.totalUnread++;
    }
  }
}

export default new NotificationService();
