export enum RegulationCategory {
    MORTGAGE = 1,
    CASH = 2,
    SME = 3,
    NO_BANK = 4,
    CONTEST = 5,
    LENDI = 6,
    PLATFORM = 7,
    REPORTS = 8,
    OTHER = 9,
}

export class CategoryObj {
  public id!: RegulationCategory;
  public name!: string;

  constructor(categoryObj?: CategoryObj) {
    Object.assign(this, categoryObj);
  }
}
