import {ChecklistDocumentCategories} from '@/components/checklist/models/ChecklistDocumentCategories';
import {Utils} from '@/services/utils/BasicUtils';

export enum MultiDocumentCategoryTypes {
  GENERAL,
  INCOME_DEPENDED,
  PURPOSE_DEPENDED,
  PROTECTION_DEPENDED,
  ORGANIZATION_DEPENDED,
  PERSONAL_DEPENDED,
}

export interface IMultiDocumentCategory {
  idx: MultiDocumentCategoryTypes;
  code: string;
  description: string;
}

export class MultiDocumentCategory implements IMultiDocumentCategory {
  public idx!: MultiDocumentCategoryTypes;
  public code!: string;
  public description!: string;

  constructor(dto: IMultiDocumentCategory) {
    Utils.assign(this, dto);
  }

  public get checklistGroupCategoryKey(): ChecklistDocumentCategories {
    switch (this.idx) {
    case MultiDocumentCategoryTypes.GENERAL:
      return ChecklistDocumentCategories.GENERAL;
    case MultiDocumentCategoryTypes.INCOME_DEPENDED:
      return ChecklistDocumentCategories.INCOME;
    case MultiDocumentCategoryTypes.PERSONAL_DEPENDED:
      return ChecklistDocumentCategories.PERSONAL;
    case MultiDocumentCategoryTypes.PROTECTION_DEPENDED:
      return ChecklistDocumentCategories.PROTECTION;
    case MultiDocumentCategoryTypes.PURPOSE_DEPENDED:
      return ChecklistDocumentCategories.PURPOSE;
    default:
      return ChecklistDocumentCategories.GENERAL;
    }
  }
}
