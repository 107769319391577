import {Utils} from '@/services/utils/BasicUtils';

export class DivisionMemberDTO {
  id!: number;
  name!: string;
  divisionTypeId!: number;

  organisationName!: string;
  divisionType!: string;
  mainChief!: string;
}

export class DivisionMember extends DivisionMemberDTO {
  constructor(divisionMemberDto?: DivisionMemberDTO) {
    super();
    if (divisionMemberDto) {
      Utils.assign(this, divisionMemberDto);
    }
  }

  public get textToSearch(): string {
    return this.displayName;
  }

  public get displayName(): string {
    return this.name;
  }
}
