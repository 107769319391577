import * as Autocompletes from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Autocompletes.json';
import * as BankBranches from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Bank_Branches.json';
import * as Branches from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Branches.json';
import * as CalculatorCashResults from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Calculator_Cash_Results.json';
import * as CalculatorForm from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Calculator_Form.json';
import * as CalculatorResults from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Calculator_Results.json';
import * as CalculatorSchedule from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Calculator_Schedule.json';
import * as CalculatorSchedulePrintout from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Calculator_Schedule_Printout.json';
import * as CalculatorScheduleTable from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Calculator_Schedule_Table.json';
import * as ClientDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Client_Details.json';
import * as ClientDetailsBik from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Client_Details_Bik.json';
import * as ClientDetailsDocuments from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Client_Details_Documents.json';
import * as ClientList from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Client_List.json';
import * as Commons from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Commons.json';
import * as CreateDealModal from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Create_Deal_Modal.json';
import * as Dashboard from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Dashboard.json';
import * as DateFilters from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Date_Filters.json';
import * as DealAttributesDictionary from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Deal_Attributes_Dictionary.json';
import * as DealDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Deal_Details.json';
import * as Deals from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Deals.json';
import * as DealsList from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Deals_List.json';
import * as DelegationLists from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Delegation_Lists.json';
import * as DivisionDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Division_Details.json';
import * as Divisions from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Divisions.json';
import * as Enums from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Enums.json';
import * as ErrorPages from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Error_Pages.json';
import * as FinancialInstitutions from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Financial_Institutions.json';
import * as Forum from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Forum.json';
import * as FpComponents from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Fp_Components.json';
import * as FpMessage from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Fp_Message.json';
import * as Lenda from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Lenda.json';
import * as LoanApplications from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Loan_Applications.json';
import * as Login from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Login.json';
import * as Multiform from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Multiform.json';
import * as MultiformDictionary from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Multiform_Dictionary.json';
import * as Navigation from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Navigation.json';
import * as NewClientModal from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/New_Client_Modal.json';
import * as Notifications from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Notifications.json';
import * as Opinions from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Opinions.json';
import * as Pagination from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Pagination.json';
import * as Partners from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Partners.json';
import * as Prospects from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Prospects.json';
import * as SettlementDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Settlement_Details.json';
import * as Settlements from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Settlements.json';
import * as SmsEmails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Sms_Emails.json';
import * as UseList from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Use_List.json';
import * as UserEditPanel from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/User_Edit_Panel.json';
import * as UsersManagements from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Users_Management.json';
import * as Validation from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Validation.json';
import * as Accreditation from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Accreditation.json';
import * as SnackbarService from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/SnackbarService.json';
import * as Teleconference from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Teleconference.json';

import {I18NInterface} from '@/services/enumTranslator/I18NInterface';

const translationsArray = [Autocompletes,
  BankBranches,
  Branches,
  CalculatorCashResults,
  CalculatorForm,
  CalculatorResults,
  CalculatorSchedule,
  CalculatorSchedulePrintout,
  CalculatorScheduleTable,
  ClientDetails,
  ClientDetailsBik,
  ClientDetailsDocuments,
  ClientList,
  Commons,
  CreateDealModal,
  Dashboard,
  DateFilters,
  DealAttributesDictionary,
  DealDetails,
  Deals,
  DealsList,
  DelegationLists,
  DivisionDetails,
  Divisions,
  Enums,
  ErrorPages,
  FinancialInstitutions,
  Forum,
  FpComponents,
  FpMessage,
  Lenda,
  LoanApplications,
  Login,
  Multiform,
  MultiformDictionary,
  Navigation,
  NewClientModal,
  Notifications,
  Opinions,
  Pagination,
  Partners,
  Prospects,
  SettlementDetails,
  Settlements,
  SmsEmails,
  UseList,
  UserEditPanel,
  UsersManagements,
  Validation,
  SnackbarService,
  Accreditation,
  Teleconference,
];

export const reducedJsonDictPL: I18NInterface = translationsArray.reduce((prev, current) => {
  const parsedCurrent = JSON.parse(JSON.stringify(current));
  return {...prev, ...parsedCurrent,};
}, {}) as typeof Autocompletes & typeof BankBranches
 & typeof Branches
& typeof CalculatorCashResults
& typeof CalculatorForm
& typeof CalculatorResults
& typeof CalculatorSchedule
& typeof CalculatorSchedulePrintout
& typeof CalculatorScheduleTable
& typeof ClientDetails
& typeof ClientDetailsBik
& typeof ClientDetailsDocuments
& typeof ClientList
& typeof Commons
& typeof CreateDealModal
& typeof Dashboard
& typeof DateFilters
& typeof DealAttributesDictionary
& typeof DealDetails
& typeof Deals
& typeof DealsList
& typeof DelegationLists
& typeof DivisionDetails
& typeof Divisions
& typeof Enums
& typeof ErrorPages
& typeof FinancialInstitutions
& typeof Forum
& typeof FpComponents
& typeof FpMessage
& typeof Lenda
& typeof LoanApplications
& typeof Login
& typeof Multiform
& typeof MultiformDictionary
& typeof Navigation
& typeof NewClientModal
& typeof Notifications
& typeof Opinions
& typeof Pagination
& typeof Partners
& typeof Prospects
& typeof SettlementDetails
& typeof Settlements
& typeof SmsEmails
& typeof UseList
& typeof UserEditPanel
& typeof UsersManagements
& typeof Validation
& typeof Accreditation
& typeof SnackbarService
& typeof Teleconference;
