<template>
  <v-card>
      <v-card-title class="px-6 pb-2">
        <v-row>
          <v-col cols="12" class="py-0">
            <span>{{ $i18n.useSimulationResult.ADD_OFFERS_TO_DEAL }} - {{store.deal.name}}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col>
            <v-card class="fp-border-card elevation-0">
            <v-expansion-panels
              v-model="expanded"
              accordion
              multiple>
              <v-expansion-panel>
                <raiffeisen-access-info
                  v-if="isRaiffeisenOfferSelected && !hasAccessToRaiffeisen"
                  :selected-offers-count="selectedOffersCount" />
                <v-expansion-panel-header
                  class="font-weight-medium body-1">
                  <div class="fill-width d-flex align-center justify-space-between">
                    <span>
                      {{ $i18n.useSimulationResult.SELECTED_OFFERS }} - {{ selectedLoanApplications.length }}
                    </span>
                    <fp-btn text
                           @click.stop="store.toggleHideOfferName()"
                           :primary="store.userInput.hideOfferName">
                      <v-icon>mdi-eraser</v-icon>
                    </fp-btn>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="bt">
                  <v-list-loan-application
                    tilePadding="8px"
                    :loanApplications="selectedLoanApplications"
                    class="py-2">
                    <template v-if="store.userInput.hideOfferName" #title="{loanApplication}">
                      <v-list-item-content>
                        <v-list-item-title class="">
                          {{ loanApplication.shortName }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:default="{loanApplication}">
                      <v-list-item-content class="list-item-content">
                        <v-list-item-title class="text-center font-weight-medium">
                          {{loanApplication.amount | numeralFormat}} {{$env.currentCurrency()}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">{{ $i18n.useSimulationResult.LOAN_AMOUNT }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content class="list-item-content">
                        <v-list-item-title class="text-center font-weight-medium">
                          {{loanApplication.period }} {{ store.isMortgage ? $i18n.useSimulationResult.YEARS : $i18n.useSimulationResult.MONTHS }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">{{ $i18n.useSimulationResult.LOAN_PERIOD }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-loan-application>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="existingLoanApplications && existingLoanApplications.length">
                <v-expansion-panel-header
                  class="font-weight-medium body-1">
                  {{ $i18n.useSimulationResult.EXISTING_OFFERS }} - {{ existingLoanApplications.length }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="bt">
                  <v-list-loan-application
                    tilePadding="8px"
                    :loanApplications="existingLoanApplications"
                    class="fill-width py-2">
                    <template v-slot:default="{loanApplication}">
                      <v-list-item-content class="list-item-content">
                        <v-list-item-title class="text-center font-weight-medium">
                          {{loanApplication.amount | numeralFormat}} {{$env.currentCurrency()}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">{{ $i18n.useSimulationResult.LOAN_AMOUNT }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content class="list-item-content">
                        <v-list-item-title class="text-center font-weight-medium">
                          {{loanApplication.demand.loanPeriod}} {{store.isMortgage ? $i18n.useSimulationResult.YEARS : $i18n.useSimulationResult.MONTHS }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">{{ $i18n.useSimulationResult.LOAN_PERIOD }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-loan-application>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-6">
        <v-row>
          <v-col>
            <fp-btn v-if="isRaiffeisenOfferSelected && selectedOffersCount > 1 && !hasAccessToRaiffeisen"
                    depressed
                    @click="addOffersWithoutRaiffeisen"
                    primary
            >
              {{ $i18n.useSimulationResult.ADD_OTHER_OFFERS_TO_DEAL }}
            </fp-btn>
            <fp-btn v-else
                    :disabled="isRaiffeisenOfferSelected && selectedOffersCount === 1 && !hasAccessToRaiffeisen"
                    depressed
                    primary
                    @click="modal.closeModal(true)">
              {{ $i18n.useSimulationResult.ADD }}
            </fp-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="modal.closeModal(false)" depressed outlined>{{ $i18n.useSimulationResult.CANCEL }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
</template>
<script lang="ts" setup>
import {useModalInstance} from '@/composables/useModalInstance';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {Offer} from '@/models/simulation/Simulation';
import LoanApplication from '@/models/LoanApplication';
import {Bank} from '@/modules/banks/models/Bank';
import DealsApi from '@/modules/deals/services/DealsApi';
import {computed, getCurrentInstance, ref} from 'vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import User from '@/models/user/User';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
const RaiffeisenAccessInfo = () => import(/* webpackChunkName: "RaiffeisenAccessInfo" */ '@/components/calculator/results/RaiffeisenAccessInfo.vue');

type ModalData = {
  selectedOffers: Offer[];
  user: User;
}

enum Lists {
  SELECTED,
  EXISTING,
}
const instance = getCurrentInstance();
const modal = useModalInstance<ModalData, boolean | Offer[]>();
const store = useSimulationStore();
const expanded = ref<Lists[]>([Lists.SELECTED,]);
const existingLoanApplications = ref<LoanApplication[]>([]);

const selectedLoanApplications = computed<LoanApplication[]>(() => {
  return modal.modalData.selectedOffers.map(transformOfferToLoanApplication);
});

const offers = computed<Offer[]>(() => {
  return modal.modalData.selectedOffers;
});

const hasAccessToRaiffeisen = computed<boolean>(() => {
  return modal.modalData.user!.privileges.hasAccessToRaiffeisen;
});

const isRaiffeisenOfferSelected = computed<boolean>(() => {
  return modal.modalData.selectedOffers?.some(offer => offer.info.bank === BankSymbol.RAIFFEISEN) || false;
});

const selectedOffersCount = computed<number>(() => {
  return modal.modalData.selectedOffers?.length || 0;
});

const addOffersWithoutRaiffeisen = () => {
  const offers = modal.modalData.selectedOffers.filter(offer => offer.info.bank !== BankSymbol.RAIFFEISEN);
  modal.closeModal(offers);
};

const getLoanPeriod = (offer: Offer): number => {
  const loanPeriodInMonths: number = offer.customizations?.customLoanPeriodInMonths ||
    (store.isMortgage ? store.userInput?.loanPeriodInMonths : store.userInput?.loanPeriod) || 0;
  return store.isMortgage ? Math.round(loanPeriodInMonths / 12) : loanPeriodInMonths;
};

const getLoanAmount = (offer: Offer): number =>
  offer.customizations?.customLoanAmount || store.userInput?.loanAmount || 0;

const transformOfferToLoanApplication = (offer: Offer): LoanApplication => new LoanApplication({
  bank: {name: offer.info.bankName, symbol: offer.info.bank,} as unknown as Bank,
  name: offer.info.title,
  shortName: offer.shortName,
  amount: getLoanAmount(offer),
  period: getLoanPeriod(offer),
} as unknown as LoanApplication);

const fetchLoanApplications = async(): Promise<void> => {
  try {
    existingLoanApplications.value = await DealsApi.getLoanApplicationsByDealId(store.deal!.id);
  } catch (e) {
    instance?.proxy.errorHandler(e, I18NGetter().useDealDetails.FETCH_LOAN_APPLICATIONS_ERROR_HANDLER);
  }
};

fetchLoanApplications();
</script>

<style scoped lang="scss">
.list-item-content {
  flex-basis: 15%;
}
</style>
