import {FpRouteConfig} from '@/types/typings';
import AuthService from '@/modules/user/AuthService';
const LivespaceStatus = () => import('@/modules/livespace/views/LivespaceStatus.vue');
const LivespacePermissions = () => import('@/modules/livespace/views/LivespacePermissions.vue');

type LivespaceMetaData = FpRouteConfig<{
  title: string,
  icon: string,
  condition?: () => boolean
}>

const LivespaceRoutes: LivespaceMetaData[] = [
  {
    path: 'Permissions',
    name: 'Permissions',
    component: LivespacePermissions,
    meta: {
      title: 'Uprawnienia',
      icon: 'mdi-lock-open-outline',
      condition: () => AuthService.User!.isAdmin || AuthService.User!.isVerifier,
    },
  },
  {
    path: 'status',
    name: 'Livespace status',
    component: LivespaceStatus,
    meta: {
      title: 'Statusy',
      icon: 'mdi-list-status',
      condition: () => AuthService.User!.isAdmin || AuthService.User!.isVerifier,
    },
  },
];
export default LivespaceRoutes;
