<template>
  <v-flex v-bind="flexProps" :class="{
    'dirty-state': isDirtyState,
  }">
    <slot></slot>
  </v-flex>
</template>

<script>
import {pick} from 'lodash-es';
import {VFlex} from 'vuetify/lib';
import {SizeProps} from '@/components/fpComponents/FpField/colsSizeProps';

export default {
  props: ['keys', ...SizeProps,],
  components: {VFlex,},
  computed: {
    flexProps() {
      return pick(this.keys, SizeProps);
    },
    isDirtyState() {
      return this.keys.dirty !== undefined ? this.keys.dirty : ![undefined, null, '',].includes(this.keys.value);
    },
  },
};
</script>

<style scoped lang="scss">
.flex:not(.dirty-state) {
  ::v-deep .v-radio.fp-input-outline:not(.fp-checkbox) {
    border-color: fpShadow(.12) !important;
  }

  ::v-deep div.v-input:not(.error--text):not(.fp-checkbox) {
    fieldset{
      border-color: fpShadow(.12) !important;
    }
  }
}
</style>
