import {ProductType} from '@/commons/enums/ProductType';
import {IMultiformDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';

export enum Languages{
  ENGLISH = 'english',
  GERMAN = 'german',
}

export const LanguagesDictionary: IMultiformDictionary<Languages> = [
  {
    type: Languages.ENGLISH,
    name_pl: 'Angielski',
  },
  {
    type: Languages.GERMAN,
    name_pl: 'Niemiecki',
  },
];
