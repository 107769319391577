import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
const RegulationDetails = () => import(/* webpackChunkName: "Calculator" */ '../views/regulation-details/RegulationDetails.vue');
const AddNewRegulation = () => import(/* webpackChunkName: "Calculator" */ '@/modules/regulation/views/AddNewRegulation.vue');
const RegulationList = () => import(/* webpackChunkName: "Calculator" */ '../views/regulation-list/RegulationList.vue');

const RegulationRoutes: Array<RouteConfig> = [
  {
    path: 'regulations',
    name: Routes.REGULATIONS.REGULATION_LIST,
    component: RegulationList,
    meta: {
      title: I18NGetter().KnowledgeBaseSection.updates,
      icon: 'mdi-update',
    },
  },
  {
    path: 'regulations/add',
    name: Routes.REGULATIONS.ADD_REGULATION,
    component: AddNewRegulation,
  },
  {
    path: 'regulation/:id',
    name: Routes.REGULATIONS.REGULATION_DETAILS,
    component: RegulationDetails,

  },
];

export default RegulationRoutes;
