<template>
  <v-layout wrap>
    <v-flex xs12 v-if="divisionCount === 0">
      <span>{{ I18NGetter().useDivisionAutocomplete.NO_DIVISION }}</span>
    </v-flex>
    <template v-for="(division, index) in divisions" v-else>
      <v-flex xs8 mb-4 :key="index" v-if="division.divisions.length > 0">
        <v-autocomplete
          :value="value"
          :items="division.divisions"
          @input="onInput"
          multiple
          chips
          deletable-chips
          item-text="name"
          item-value="id"
          :menu-props="{'closeOnContentClick': true,}"
          color="primary"
          hide-details
          :label="division.name"
          class="fp-input-outline"
          outlined
          hide-selected
          prepend-icon="mdi-account-search">
        </v-autocomplete>
      </v-flex>
    </template>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import DivisionGroup from '@/models/DivisionGroup';
import DictionaryStore from '@/commons/services/store/DictionaryStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component
export default class DivisionAutocomplete extends Vue {
  @Prop({required: true, }) public value!: Array<number>;
  @Prop({default: false, type: Boolean, }) public returnId!: boolean;

  public divisions: Array<DivisionGroup> = [];
  public I18NGetter = I18NGetter;

  public get divisionCount() {
    return this.divisions.flatMap(bank => bank.divisions).length;
  }

  public async mounted() {
    this.divisions = await DictionaryStore.getDictionary('divisionsDictionary');
  }

  public emitValue(value: Array<number>) {
    this.$emit('input', value);
  }

  public async onInput(event: Array<number>) {
    if (event) {
      this.emitValue(event);
    }
  }
}
</script>
