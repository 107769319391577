import Vue from 'vue';
import OnboardingContainer from '@/modules/onboarding/OnboardingContainer.vue';
import OnboardingModal from '@/modules/onboarding/OnboardingModal.vue';
import {
  TourLabel,
  TourModalOptions,
  TourModalType,
  TourName,
  TourOptions
} from '@/modules/onboarding/tour/models/TourOptions';
import {TourStorageService} from '@/modules/onboarding/tour/TourStorageService';
import AuthService from '@/modules/user/AuthService';
import {
  defaultStep,
  TourPlacement,
  TourStep,
  TourStepKey
} from '@/modules/onboarding/tour/models/TourStep';
import VueTour from 'vue-tour';

export class Tour {
  public readonly name: TourName;
  public readonly label: string;
  public readonly steps: TourStep[];
  public readonly tourOptions: TourOptions;
  public readonly tourStorageService: TourStorageService;
  private isModalVisible: boolean = false;
  public isStarted: boolean = false;
  constructor(name: TourName,
    steps: readonly TourStep[],
    tourOptions: TourOptions = new TourOptions(),
    tourStorageService: TourStorageService = new TourStorageService(name),
  ) {
    this.name = name;
    this.label = TourLabel[name];
    this.steps = this.appendTargetClassSelectorAndDefaultParams(steps);
    this.tourOptions = tourOptions;
    this.tourStorageService = tourStorageService;
  }

  private appendTargetClassSelectorAndDefaultParams(steps: readonly TourStep[]): TourStep[] {
    return steps.map((step: TourStep) =>
      ({
        ...step,
        target: `.${this.name}-${step.key}`,
        params: {
          placement: defaultStep.params?.placement,
          highlight: defaultStep.params?.highlight,
          ...step.params,
        },
      }));
  }

  private mount() {
    const ContainerClass = Vue.extend(OnboardingContainer);
    const ContainerInstance = new ContainerClass({
      propsData: {
        tourName: this.name,
        steps: this.steps,
      },
    });
    const mountElement = document.getElementsByClassName('v-application--wrap')[0];
    if (mountElement) {
      const newDiv = document.createElement('div');
      newDiv.id = this.name;
      mountElement.appendChild(newDiv);
      ContainerInstance.$vuetify = Vue.prototype.$vuetify;
      ContainerInstance.$tours = Vue.prototype.$tours;
      ContainerInstance.$mount(`#${this.name}`);
    }
  };

  private async openModal(modalType: TourModalType): Promise<boolean> {
    this.isModalVisible = true;
    const {title, description, trueText, falseText,}: TourModalOptions = this.tourOptions.modals[modalType];
    const value = await Vue.prototype.$modalService.open(
      OnboardingModal, {
        name: 'Lenda',
        title: title(AuthService.User?.firstName ?? ''),
        description,
        falseText,
        trueText,
        modalType,
      }, {
        maxWidth: '500px',
        persistent: true,
        zIndex: 303,
      });
    this.isModalVisible = false;
    return value;
  }

  public get step(): Record<TourStepKey, string> {
    return this.steps.reduce((acc, curr) =>
      ({...acc, [curr.key]: curr.target?.slice(1),}), {} as Record<TourStepKey, `${TourName}-${TourStepKey}`>);
  }

  private get tour(): Nullable<VueTour.Tour> {
    return Vue.prototype.$tours ? Vue.prototype.$tours[this.name] : null;
  }

  public stop() {
    this.tour?.finish();
  }

  public complete() {
    this.stop();
    this.setEnabled(false);
  }

  public async isEnabled(): Promise<boolean> {
    return this.tourStorageService.isEnabled();
  }

  public hasPermission(): boolean {
    return AuthService.User!.canStartOnboarding[this.name];
  }

  public async setEnabled(value: boolean) {
    await this.tourStorageService.setEnabled(value);
  }

  public isStepAvailable(stepIndex: number = 0): boolean {
    const nextStep: TourStep = this.steps[stepIndex];
    const target: Nullable<Element> = document.querySelector(nextStep.target!);
    return Boolean(target);
  }

  public previousStep(step: number = this.tour?.currentStep ?? 0) {
    this.setStep(step - 1, false);
  }

  public nextStep(step: number = this.tour?.currentStep ?? 0) {
    this.setStep(step + 1);
  }

  private setStep(stepIndex: number, nextStep: boolean = true) {
    if (stepIndex < 0 || this.isModalVisible) {
      return;
    }
    if (stepIndex === this.steps.length) {
      this.finish();
      return;
    }
    if (this.isStepAvailable(stepIndex)) {
      this.goToStep(stepIndex);
    } else {
      nextStep ? this.nextStep(stepIndex) : this.previousStep(stepIndex);
    }
  }

  public goToStep(step?: number) {
    this.tour?.start(step?.toString());
  }

  private isMobile(): boolean {
    return Vue.prototype.$vuetify.breakpoint.mdAndDown;
  }

  public async start() {
    // checkPermissions
    if (this.hasPermission() && !this.isMobile() && await this.isEnabled()) {
      const tourStarted = await this.openModal(TourModalType.START);
      if (tourStarted) {
        this.mount();
        this.goToStep();
        this.isStarted = true;
      } else {
        const isTourFinished = await this.cancel();
        if (!isTourFinished) {
          await this.start();
        }
      }
    }
  }

  public async cancel(): Promise<boolean> {
    const isTourFinished = await this.openModal(TourModalType.CANCEL);
    if (isTourFinished) {
      this.complete();
    }
    return isTourFinished;
  }

  public async finish() {
    const isTourFinished = await this.openModal(TourModalType.FINISH);
    if (isTourFinished) {
      this.complete();
      this.isStarted = false;
    }
  }
}
