<template>
  <v-layout wrap>
    <v-flex class="multiform-list-manager" v-if="value !== null">
      <v-expand-transition>
        <v-card>
          <v-card-title v-if="!isSingle" class="pb-0 d-relative">
              <navigation-tabs v-model="itemTabs" :tabsHeaders="headers" hide-arrows class="pr-10">
                  <template v-slot:tabAction="{index}">
                    <v-btn
                      v-if="!readonly && value.length > 1"
                      small
                      icon
                      text
                      @click="removeItem(index)">
                      <v-icon right class="close-icon">mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                </navigation-tabs>
              <v-btn large depressed text color="primary"
                     v-if="!readonly"
                     :icon="$vuetify.breakpoint.smAndDown"
                     class="primaryBg add-button"
                     @click="addItem()">
                <span v-if="$vuetify.breakpoint.mdAndUp">{{ $i18n.useMultiformListManager.ADD }}</span>
                <v-icon class="icon">add</v-icon>
              </v-btn>
            </v-card-title>
          <v-divider v-if="!isSingle"/>
          <v-card-text class="py-0" :class="{'px-2': $vuetify.breakpoint.mdAndDown}">
            <v-tabs-items v-model="itemTabs" class="item-class">
              <v-tab-item v-for="(item, i) in items" :key="i">
                <v-layout wrap my-0>
                  <v-flex xs12>
                    <slot :item="item"/>
                  </v-flex>
                </v-layout>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import NavigationTabs from '@/components/commons/NavigationTabs.vue';
@Component({
  components: {NavigationTabs, },
})
export default class MultiformListManager extends Vue {
  @Prop()
  public readonly value!: Nullable<Object[] | Object>;

  @Prop({required: false, })
  public readonly type!: new(index?: number) => {};

  @Prop()
  public readonly itemName?: string;

  @Prop({default: false, },)
  public readonly isSingle!: boolean;

  @Prop({default: false, },)
  public readonly readonly!: boolean;

  public itemTabs: number = 0;
  mounted() {
    this.addIfNone();
  }

  get items(): Object[] {
    return this.isSingle
      ? (Array.isArray(this.value) ? this.value : [this.value as Object,])
      : (this.value as Array<Object>);
  }

  private addIfNone() {
    if (!this.value) {
      this.$emit('input', [new this.type(0),]);
    }
    if (this.value && this.items.length === 0) {
      this.addItem();
    }
  }

  get headers() {
    return this.items?.map((el, index) => `${this.itemName} ${index + 1}`) || [];
  }

  public addItem() {
    const listLength: number = this.items?.length || 0;
    const itemToAdd = new this.type(listLength);
    this.items!.push(itemToAdd);
    this.$emit('addItem', itemToAdd);
    this.itemTabs = listLength;
  }

  public removeItem(index: number) {
    if (this.items!.length > 1) {
      this.addIfNone();
      this.items!.splice(index, 1);
      this.itemTabs = this.items!.length - 1;
    }
  }
}
</script>

<style scoped lang="scss">
  .item-class{
    overflow: hidden;
  }
  .tabs-header {
    position: relative;
    padding-bottom: 0!important;
  }
  .tab-item{
    ::v-deep .v-tabs__item--active {
      color: $primary;
      .close-icon{
        color: $primary
      }
    }
    text-transform: none;
  }
  .v-divider {
    margin: 0!important;
  }
  .flex-shrink{
    padding-right: 0!important;
    padding-top: 6px!important;
  }
  .close-icon {
    font-size: 16px;
  }
  .d-relative {
    position: relative;
  }
  .add-button{
    bottom: 2px;
    position: absolute;
    right: 16px;
  }
</style>
