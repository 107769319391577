import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import {difference} from 'underscore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export function findEnumByEnumObject<TEnum extends string>(enumObject: Record<keyof TEnum, TEnum>) {
  const foundKey = (<Array<keyof I18NInterface>>Object.keys(I18NGetter()))
    .find((key) => {
      const i18nKeys = Object.keys(I18NGetter()[key]);
      const enumValues = Object.values(enumObject).map(value => String(value));
      return difference(enumValues, i18nKeys).length === 0 && difference(i18nKeys, enumValues).length === 0;
    });
  return I18NGetter()[foundKey as keyof I18NInterface] as Record<TEnum, string>;
}
