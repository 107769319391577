import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';
import AppRouter from '@/router';
import {Routes} from '@/router/Routes';
import AuthService from '@/modules/user/AuthService';
import {usePrintoutSettings} from '@/components/calculator/printout/usePrintoutSettings';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import SimulationApi from '@/modules/calculator/services/SimulationApi';
import {I18N} from '@/services/enumTranslator/I18NDictionary';
import EnvironmentService from '@/env/EnvironmentService';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {useQueryParams} from '@/composables/useQueryParams';
import {CommonPrintoutQueryParams} from '@/components/calculator/printout/query-params/CommonPrintoutQueryParams';
import {SchedulePrintoutQueryParams} from '@/components/calculator/printout/query-params/SchedulePrintoutQueryParams';
import {
  SimulationPrintoutQueryParams
} from '@/components/calculator/printout/query-params/SimulationPrintoutQueryParams';
import moment from 'moment/moment';
import {ScheduleParams} from '@/components/calculator/schedule/Schedule';
import {createRangesFromArrayOfNumbers} from '@/services/utils/BasicUtils';

export const usePrintout = () => {
  const scheduleStore = useScheduleStore();
  const simulationStore = useSimulationStore();

  const {toQueryParams,} = useQueryParams();

  const {
    getExcludedTableElementKeys,
    getIsAdditionalInfoHiddenValue,
  } = usePrintoutSettings(simulationStore.productType);
  const {getTableRows,} = useOffersTable();

  const getCommonQueryParams = (): CommonPrintoutQueryParams => ({
    authToken: AuthService.token,
    lang: I18N.systemLanguage,
  });

  const prepareSchedulePrintoutHref = (): string => {
    const {href,} = AppRouter.resolve({
      name: Routes.NEW_CALCULATOR.SCHEDULE_PRINT,
      params: {
        productType: simulationStore.productType!,
        simulationId: simulationStore.simulationId!,
        offerId: scheduleStore.offer!.info.id,
      },
      query: toQueryParams<SchedulePrintoutQueryParams>({
        ...getCommonQueryParams(),
        params: new ScheduleParams(
          scheduleStore.offer!, scheduleStore.userInput!,scheduleStore.scheduleVariant,
          scheduleStore.earlyPayment, scheduleStore.loanPeriodInMonths, simulationStore.simulationId!,
          scheduleStore.isHomeStartProgram
        ),
        variant: scheduleStore.scheduleVariant,
        shortName: scheduleStore.offer?.shortName,
        isOfferNameHidden: simulationStore.userInput?.hideOfferName,
        rows: createRangesFromArrayOfNumbers(scheduleStore.scheduleRows
          .filter((row) => row.visible)
          .map((row) => row.currentMonth)),
        additionalCosts: scheduleStore.showAdditionalCosts,
      }),
    });
    return href;
  };

  const prepareSimulationPrintoutHref = async(): Promise<string> => {
    const {href,} = AppRouter.resolve({
      name: Routes.NEW_CALCULATOR.PRINT,
      params: {
        productType: simulationStore.productType!,
        simulationId: simulationStore.simulationId!,
      },
      query: toQueryParams<SimulationPrintoutQueryParams>({
        ...getCommonQueryParams(),
        printOffers: simulationStore.userInput!.selectedOffers,
        excludedElements: await getExcludedTableElementKeys(),
        isOfferNameHidden: simulationStore.userInput!.hideOfferName,
        isAdditionalInfoHidden: await getIsAdditionalInfoHiddenValue(),
        tableRows: getTableRows(simulationStore.productType!),
        dealId: simulationStore.deal?.id,
      }),
    });
    return href;
  };

  const convertHrefToUrl = (href: string): string => {
    // Dla środowiska lokalnego przekazujemy do pdf printera url z qa, który ma przedrostek - v2
    return `${process.env.VUE_APP_SIMULATION_URL}${EnvironmentService.Environment.isDev ? 'v2/' : ''}${href.substring(1)}`;
  };

  const preparePrintoutName = (label: string): string => {
    const today: string = moment().format('YYYY-MM-DD');
    let filename: string = `Lendi_${today}_${label}.pdf`;
    if (simulationStore.deal && simulationStore.deal.clients) {
      const normalizeName = (name: string) => name
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .replaceAll(' ', '-');
      const name = normalizeName(simulationStore.deal.clients[0].name!);
      filename = `${name}_${label}.pdf`;
    }
    return filename;
  };

  const downloadPrintout = async(href: string, label: string) => {
    const printoutUrl: string = convertHrefToUrl(href);
    const filename: string = preparePrintoutName(label);
    const {url,} = await SimulationApi.generatePdf(printoutUrl, filename);
    location.href = `${process.env.VUE_APP_SIMULATION_URL}pdf${url}`;
  };

  const downloadSchedulePrintout = async() => {
    const href: string = prepareSchedulePrintoutHref();
    const bankName: string | undefined = simulationStore.userInput?.hideOfferName
      ? scheduleStore.offer!.shortName?.replaceAll(' #', '_') : scheduleStore.offer!.info.bankName;
    await downloadPrintout(href, `${I18NGetter().usePrintout.SCHEDULE}-${simulationStore.simulationId!}-${bankName ?? ''}`);
  };

  const previewSchedulePrintout = () => {
    const href: string = prepareSchedulePrintoutHref();
    window.open(href, '_blank');
  };

  const downloadSimulationPrintout = async() => {
    const href: string = await prepareSimulationPrintoutHref();
    await downloadPrintout(href, simulationStore.simulationId!);
  };

  const previewSimulationPrintout = async() => {
    const href: string = await prepareSimulationPrintoutHref();
    window.open(href, '_blank');
  };

  const getOffersPDFUrl = async() => {
    const href: string = await prepareSimulationPrintoutHref();
    return await convertHrefToUrl(href);
  };

  return {
    downloadSchedulePrintout,
    previewSchedulePrintout,
    downloadSimulationPrintout,
    previewSimulationPrintout,
    getOffersPDFUrl,
    preparePrintoutName,
  };
};
