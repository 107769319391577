<template>
  <v-list v-if="loanApplications" two-line subheader class="pb-0" style="background-color: transparent;">
    <template v-for="(loanApplication, index) in loanApplications">
      <template v-if="loanApplication.isLoanApplicationAvailable">
        <v-list-item :key="index" :style="{'background-color': backgroundColor(loanApplication, index),}">
          <slot name="title" :index="index" :loanApplication="loanApplication">
            <v-list-item-avatar>
              <v-avatar
                  v-if="loanApplication.bank && loanApplication.isLoanApplicationAvailable"
                  size="40"
                  :class="{'disabled-img': !loanApplication.isLoanApplicationAvailable}">
                <img alt="bank-symbol" :src="loanApplication.bank.symbolRequire">
              </v-avatar>
              <v-tooltip v-else top>
                <template v-slot:activator="{on}">
                  <v-avatar v-on="on" v-if="loanApplication.bank" size="44"
                            :class="{'disabled-img': !loanApplication.isLoanApplicationAvailable}">
                    <img alt="bank-symbol" :src="loanApplication.bank.symbolRequire">
                  </v-avatar>
                </template>
                <span>Oferta nieaktualna</span>
              </v-tooltip>
            </v-list-item-avatar>
            <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-list-item-content v-on="on" style="flex-basis: 40%;">
                <v-list-item-title v-if="loanApplication.bank"
                                   :class="{'disabled-text': !loanApplication.isLoanApplicationAvailable}">
                  {{ loanApplication.bank.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-medium"
                                      :class="{'disabled-text': !loanApplication.isLoanApplicationAvailable}">
                      <span>{{ getName(loanApplication) | trimString(55) }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
              <span>
                {{ getName(loanApplication) }}
              </span>
            </v-tooltip>
          </slot>
          <loan-application-status-chip
            :disabled="!loanApplication.isLoanApplicationAvailable"
            :clickable="true"
            v-if="showStatus && loanApplication.canManuallyChangeStatus"
            :loan-application-id="loanApplication.id"
            v-model="loanApplication.step.id"/>
          <slot :index="index" :loanApplication="loanApplication"/>
        </v-list-item>
      </template>
      <template v-else>
        <v-tooltip :key="index" top>
          <template v-slot:activator="{on}">
            <v-list-item v-on="on" :style="{'background-color': backgroundColor(loanApplication, index),}">
              <v-list-item-avatar>
                <v-avatar v-if="loanApplication.bank" size="44"
                          :class="{'disabled-img': !loanApplication.isLoanApplicationAvailable}">
                  <img alt="bank-symbol" :src="loanApplication.bank.symbolRequire">
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content style="flex-basis: 55%;">
                <v-list-item-title v-if="loanApplication.bank"
                                   :class="{'disabled-text': !loanApplication.isLoanApplicationAvailable}">
                  {{ loanApplication.bank.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-medium"
                                      :class="{'disabled-text': !loanApplication.isLoanApplicationAvailable}">
                  {{ getName(loanApplication) | trimString(55) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <loan-application-status-chip
                :disabled="!loanApplication.isLoanApplicationAvailable"
                :clickable="true"
                v-if="showStatus && loanApplication.canManuallyChangeStatus"
                :loan-application-id="loanApplication.id"
                v-model="loanApplication.step.id"/>
              <slot :index="index" :loanApplication="loanApplication"/>
            </v-list-item>
          </template>
          <span>Oferta nieaktualna</span>
        </v-tooltip>
      </template>
      <v-divider :key="`divider-${index}`" v-if="index + 1 < loanApplications.length"/>
    </template>
    <slot name="new"/>
  </v-list>
</template>

<script lang="ts" setup>
import LoanApplication from '@/models/LoanApplication';
import LoanApplicationStatusChip from '@/components/commons/LoanApplicationStatusChip.vue';
import {computed, getCurrentInstance} from 'vue';

const props = withDefaults(defineProps<{
  loanApplications: LoanApplication[];
  showStatus?: boolean;
  reportedApplicationId?: Nullable<number>;
  tilePadding?: string;
  small?: boolean;
}>(), {
  showStatus: false,
  reportedApplicationId: null,
  tilePadding: '0px',
  small: false,
});

const instance = getCurrentInstance();

const reportedApplication = computed<Nullable<number>>(
  () => props.loanApplications && props.reportedApplicationId
    ? props.loanApplications.findIndex(e => e.id === props.reportedApplicationId)
    : null
);

const getName = (loanApplication: LoanApplication): string => loanApplication.productName || loanApplication.name;

const backgroundColor = (loanApplication: LoanApplication, index?: number): string => {
  if (!loanApplication.isLoanApplicationAvailable) {
    return 'rgba(35, 45, 59, 0.02)';
  }
  return index === reportedApplication.value ? instance?.proxy.$vuetify.theme.currentTheme.success + '33' : 'initial';
};
</script>

<style scoped lang="scss">
.disabled-background{
  opacity: 2%;
}

.h30 {
  height: 30px;
}
</style>
