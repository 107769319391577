// Polish Personal Identification Number
import {
  AppDomainSpecificValidator
} from '@/components/fpComponents/services/countrySpecific/appDomainSpecificValidator';
import {AppDomain} from '@/env/enums/Domains';

export function isValidNip(nip: string): boolean {
  const weight: number[] = [6, 5, 7, 2, 3, 4, 5, 6, 7,];
  let sum: number = 0;
  const controlNumber: number = parseInt(nip.substring(9, 10));
  const weightCount: number = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += (parseInt(nip.substr(i, 1)) * weight[i]);
  }
  return sum % 11 === controlNumber;
}
export function isValidTin(tin: string): boolean {
  return /^\d+$/.test(tin);
}
