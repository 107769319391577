<template>
  <div class="schedule-table" :class="{print}">
    <v-data-table
      v-if="store.scheduleRows && store.scheduleRows.length"
      disable-pagination
      disable-filtering
      disable-sort
      :items="tableItems"
      hide-default-footer
      calculate-widths
      :loading="loading"
      mobile-breakpoint="0"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr class="header summary">
            <th v-for="(value, key) in headers" :key="key" role="columnheader" scope="col">
              {{ value }}
                <v-tooltip v-if="key === 'monthlyCost' && !print" bottom>
                    <span>{{I18NGetter().useScheduleTable.MONTHLY_COST_INFO}}</span>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" size="16">mdi-help-circle-outline</v-icon>
                    </template>
                </v-tooltip>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="items">
        <tr class="header summary">
          <th>{{ $i18n.useScheduleTable.TOTAL }}:</th>
          <th>{{ monthlyFeeSum | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <th>{{ store.schedule?.totalInterestFee | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <th>{{ capitalFeeSum | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <template v-if="store.showAdditionalCosts">
            <th>{{additionalCostsSum | numeralFormat}} {{ $env.currentCurrency() }}</th>
            <th>{{ monthlyCostSum | numeralFormat }} {{ $env.currentCurrency() }}</th>
          </template>
          <template v-if="store.isHomeStartProgram">
            <th> {{interestSubsidys | numeralFormat }} {{$env.currentCurrency()}}</th>
          </template>
          <th>0 {{ $env.currentCurrency() }}</th>
          <th>{{ offer.totals.capital | numeralFormat }} {{ $env.currentCurrency() }}</th>
        </tr>
        <template v-for="item in items">
          <interest-rate-row
            v-if="isInterestRateRowVisible(item)"
            :key="`${item.currentMonth}-interest-label`"
            :row="item"
            :offer="offer"
            :print="print"
            :colspan="9"
          />
          <show-more-row
            v-if="isShowMoreRowVisible(item)"
            :key="`${item.currentMonth}-button`"
            :class="`${item.currentMonth}-button`"
            :item="item"
            :print="print"
          />
          <schedule-table-row
            v-if="item.visible"
            :key="item.currentMonth"
            :item="item"
            :print="print"
          />
        </template>
        <tr class="footer summary">
          <th v-for="(value, key) in headers" :key="key">{{ value }}</th>
        </tr>
        <tr class="footer summary">
          <th>{{ $i18n.useScheduleTable.TOTAL }}:</th>
          <th>{{ monthlyFeeSum | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <th>{{ store.schedule?.totalInterestFee | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <th>{{ capitalFeeSum | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <template v-if="store.showAdditionalCosts">
            <th>{{additionalCostsSum | numeralFormat}} {{ $env.currentCurrency() }}</th>
            <th>{{ monthlyCostSum | numeralFormat }} {{ $env.currentCurrency() }}</th>
          </template>
          <template v-if="store.isHomeStartProgram">
            <th> {{interestSubsidys | numeralFormat }}  {{$env.currentCurrency()}}</th>
          </template>
          <th>{{ offer.totals.capital | numeralFormat }} {{ $env.currentCurrency() }}</th>
          <th>0 {{ $env.currentCurrency() }}</th>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-skeleton-loader
      v-else
      type="table-thead, divider, table-tbody"
    ></v-skeleton-loader>
  </div>
</template>
<script lang="ts" setup>
import {computed, watch} from 'vue';
import InterestRateRow from '@/components/calculator/schedule/schedule-table/InterestRateRow.vue';
import {Offer} from '@/models/simulation/Simulation';
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {ScheduleVariant} from '@/components/calculator/schedule/models/ScheduleVariant';
import ScheduleTableRow from '@/components/calculator/schedule/schedule-table/ScheduleTableRow.vue';
import ShowMoreRow from '@/components/calculator/schedule/schedule-table/ShowMoreRow.vue';
import {ScheduleRow, ScheduleRowWithVisible} from '@/components/calculator/schedule/Schedule';
import {isEmpty, sum} from 'lodash-es';

const props = withDefaults(defineProps<{
  offer: Offer;
  loading?: boolean;
  print?: boolean;
}>(), {
  loading: false,
  print: false,
});

const store = useScheduleStore();

const headers = computed<ScheduleTableHeaders>(() => {
  const commonHeaders = {
    yearAndMonth: `${I18NGetter().useScheduleTable.YEAR} / ${I18NGetter().useScheduleTable.MONTH}`,
    monthlyFee: I18NGetter().useScheduleTable.FULL_INSTALLMENT,
    interestFee: I18NGetter().useScheduleTable.INTEREST_INSTALLMENT,
    capitalFee: I18NGetter().useScheduleTable.CAPITAL_INSTALLMENT,
  };

  const homeStartProgramheaders = {
    interestSubsidys: 'Dopłata',
  }

  const showAdditionalCostsHeaders = {
    additionalCosts: I18NGetter().useScheduleTable.ADDITIONAL_COSTS,
    monthlyCost: I18NGetter().useScheduleTable.MONTHLY_COST,
  };

  const capitalPaidAndActualCapitalHeaders = {
    capitalPaid: I18NGetter().useScheduleTable.CAPITAL_REPAID,
    actualCapital: I18NGetter().useScheduleTable.CAPITAL_TO_BE_REPAID,
  };

  if (store.isHomeStartProgram && store.showAdditionalCosts) {
    return {
      ...commonHeaders,
      ...showAdditionalCostsHeaders,
      ...homeStartProgramheaders,
      ...capitalPaidAndActualCapitalHeaders,
    }
  } else if (store.showAdditionalCosts) {
    return {
      ...commonHeaders,
      ...showAdditionalCostsHeaders,
      ...capitalPaidAndActualCapitalHeaders,
    };
  } else if (store.isHomeStartProgram) {
    return {
      ...commonHeaders,
      ...homeStartProgramheaders,
      ...capitalPaidAndActualCapitalHeaders,
    }
  } else {
    return {
      ...commonHeaders,
      ...capitalPaidAndActualCapitalHeaders,
    };
  }
});

const calculateFeeSum = (value: number): number => {
  return value - store.earlyPaymentSum;
};

const monthlyFeeSum = computed<number>(() => {
  const sum: number = (store.schedule?.totalInterestFee || props.offer.totals.costsSum) + props.offer.totals.capital;
  return calculateFeeSum(sum);
});

const additionalCostsSum = computed<number>(() => {
  return sum(store.scheduleRows.map(row =>
    sum(row.additionalCosts?.map(cost => cost.fee)) || 0
  ));
});

const monthlyCostSum = computed<number>(() => {
  return monthlyFeeSum.value + additionalCostsSum.value;
});

const interestSubsidys = computed<number>(() => {
  return sum(store.scheduleRows.map(row => row.interestSubsidy)) || 0;
})

const capitalFeeSum = computed<number>(() => {
  const capitalSum: number = props.offer.totals.capital;
  return calculateFeeSum(capitalSum);
});

const tableItems = computed<ScheduleRowWithVisible[]>(() => {
  return store.scheduleRows.filter((row: Required<ScheduleRowWithVisible>) => !store.showAdditionalCosts ? row.currentMonth : true);
});

const isInterestRateRowVisible = (row: Required<ScheduleRow>): boolean => Boolean(row.currentMonth === 1 ||
  (props.offer.changingMargin && props.offer.changingMargin.initialPeriod! + 1 === row.currentMonth));

const isShowMoreRowVisible = (row: Required<ScheduleRowWithVisible>): boolean => {
  const previousRowIndex: number = Math.max(row.currentMonth - 1, 0);
  const previousRow: Required<ScheduleRowWithVisible> =
    store.scheduleRows[previousRowIndex] as Required<ScheduleRowWithVisible>;
  return previousRow.visible && !row.visible;
};
</script>
<style lang="scss" scoped>
.schedule-table {
  $even-row-bg-color: rgba($primary, .08);
  $border: 1px solid fpShadow(.15) !important;
  $row-height: 40px;
  $early-payment-row-height: 56px;
  $home-start-program-row-height: 56px;

  counter-reset: page;
  ::v-deep {
    thead tr th {
      color: unset !important;
    }

    tbody tr:nth-child(even):not(.show-hidden-rows):not(.footer):not(.interest-label) {
      background-color: $even-row-bg-color !important;
    }

    .v-data-table  .v-data-table__wrapper {
      overflow: unset;

      .summary {
        td, th {
          text-align: right;
        }
      }
    }

    .v-data-table  .v-data-table__wrapper tr {

      &.show-hidden-rows td {
        border-top: $border;
        border-bottom: $border;
      }
      &:first-child:not(.header.summary) th:not([role=columnheader]), &.footer th {
        border-top: $border;
      }
      td, th {
        height: $row-height;
        border-bottom: none !important;
      }
      &.early-payment-row td & .home-start-program.row td {
        $border-primary: 1px solid $primary !important;
        height: $early-payment-row-height;
        border-top: $border-primary;
        border-bottom: $border-primary;

        &:first-child {
          border-left: $border-primary;
        }

        &:last-child {
          border-right: $border-primary;
        }
      }

      &.footer {
        page-break-before: avoid;
      }
    }
  }

  &:not(.print) ::v-deep  .v-data-table  .v-data-table__wrapper thead tr:first-child th {
    background-color: var(--v-primaryBg-base) !important;
    position: sticky;
    top: 44px;
    border-bottom: $border;
    z-index: 1;
  }

  .show-hidden-rows {
    ::v-deep button {
      color: $primary;
    }
  }
}
</style>
