<template>
  <v-card>
    <v-card-title class="headline-2 bb sticky-actions-top">
      {{ $env.getAdditionalAgreementDescription()?.title }}
    </v-card-title>
    <v-card-text class="px-4 py-6">
      <div v-html-safe="$env.getAdditionalAgreementDescription()?.label"></div>
    </v-card-text>
    <v-card-actions class="pa-4 bt sticky-actions-bottom">
      <v-btn color="primary" depressed @click="closeModal()">{{ $i18n.useButtonCommons.CLOSE }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {VueModalComponent} from 'fp-components';
import {Component} from 'vue-property-decorator';
import EnvironmentService from '@/env/EnvironmentService';
import {AppDomain} from '@/env/enums/Domains';

@Component
export default class ClientRodo extends VueModalComponent<void> {
}
</script>

<style scoped lang="scss">
  .sticky-actions {
    &-top {
      position: sticky;
      background-color: var(--v-primaryBg-base);
      top: 0;
    }
    &-bottom {
      position: sticky;
      background-color: var(--v-primaryBg-base);
      bottom: 0;
    }
  }
</style>
