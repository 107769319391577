import {PlaceDto} from '@/models/PlaceDto';
import { UserListElement } from '@/models/ppl/UserListElement';
import { Branch } from '@/modules/branches/models/Branch';

export interface HourOfWork {
  hour: string;
  isAvailable: boolean;
  users?: number[];
}

export interface HoursOfWorkListDto {
  day: string;
  isEnabled?: boolean;
  hours?: HourOfWork[];
  users?: number[];
}

export interface HoursWithUsersDto {
  branches: Branch[];
  days: HoursOfWorkListDto[];
  users: UserListElement[];
  places: PlaceDto[];
}

export type HourEntry = {
  hour: string;
};

export type DayEntry = {
  day: string;
  hours: HourEntry[];
};

export type BaseEntryWithDay = { day: string | Date };

export const useCalendar = function() {
  function diffDate<T extends BaseEntryWithDay>(target: T[], source: T[]): T[] {
    const diff: T[] = [];

    for (const entry of target) {
      const day = new Date(entry.day).toISOString().split('T')[0];
      const sourceEntry = source.find((e) => new Date(e.day).toISOString().split('T')[0] === day);
      if (!sourceEntry) {
        diff.push(entry);
      }
    }

    return diff;
  }

  function uniqueDates(data: DayEntry[] | HoursOfWorkListDto[]): DayEntry[] {
    const mergedData: { [key: string]: DayEntry } = {};

    for (const entry of data) {
      const day = new Date(entry.day).toISOString().split('T')[0];
      if (!mergedData[day]) {
        // TODO idk how to fix it rn ... before cr of after
        // @ts-ignore
        mergedData[day] = { ...entry, };
      } else {
        const existingHours = mergedData[day].hours.map(h => h.hour);
        for (const hourEntry of entry.hours!) {
          if (!existingHours.includes(hourEntry.hour)) {
            mergedData[day].hours.push(hourEntry);
          }
        }
        mergedData[day].hours.sort((a, b) => a.hour.localeCompare(b.hour));
      }
    }

    return Object.values(mergedData);
  }

  return {
    uniqueDates,
    diffDate,
  }
}
