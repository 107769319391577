import Agent from '@/models/Agent';

export enum MeetingRole {
  AGENT = 'agent',
  CLIENT = 'client',
  GUEST = 'guest',
}

export class PendingMeetingDataDto {
  role!: MeetingRole;
  meetingStartDate!: string;
  agent!: Agent;
  surveyUuid?: string;
}

export class MeetingDataDto extends PendingMeetingDataDto {
  roomUuid!: string;
}

export class PendingMeetingData extends PendingMeetingDataDto {
  constructor(dto: PendingMeetingDataDto) {
    super();
    Object.assign(this, dto);
    if (dto?.agent) {
      this.agent = new Agent(dto.agent);
    }
  }
}

export class MeetingData extends MeetingDataDto {
  constructor(dto: MeetingDataDto) {
    super();
    Object.assign(this, dto);
    this.roomUuid = dto.roomUuid;
  }
}
