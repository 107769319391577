import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const ClientDetails = () => import(/* webpackChunkName: "Client" */ '../views/client-details/ClientDetails.vue');
const ClientList = () => import(/* webpackChunkName: "Client" */ '../views/client-list/ClientList.vue');

const ClientRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.CLIENTS.CLIENT_LIST,
    component: ClientList,
  },
  {
    path: ':id',
    name: Routes.CLIENTS.CLIENT_DETAILS,
    component: ClientDetails,
  },
];

export default ClientRoutes;
