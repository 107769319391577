import {FpAxios} from 'fp-components';
import PartnerStore from '@/modules/partner/services/store/PartnerStore';
import Partner, {
  CreatedPartnerModel,
  FinpackSource, PartnerCooperationItem, PartnerCooperationItemDTO,
  PartnerDTO,
  Salesman,
  SalesmanDTO
} from '@/models/Partner';
import {DealsFilter, FiltersResponse, PartnerCooperationFilter, PartnerFilter} from '@/models/Filter';
import Note from '@/models/Note';
class PartnerApi {
  private partnersAxios = FpAxios.create();
  readonly baseUrl = 'partners';

  constructor() {
    this.partnersAxios.interceptors.response.use(
      (response: any) => {
        PartnerStore.setPartnerApiActive(true);
        return Promise.resolve(response);
      },
      (error: any) => {
        PartnerStore.setPartnerApiActive(false);
        return Promise.reject(error);
      }
    );
  }

  async getPartners(): Promise<Array<Partner>> {
    const response = await this.partnersAxios.get<Array<PartnerDTO>>(`${this.baseUrl}`);
    return response.data.map((partner: PartnerDTO) => new Partner(partner));
  }

  async getPartner(id: number): Promise<Partner> {
    const response = await this.partnersAxios.get<PartnerDTO>(`${this.baseUrl}/${id}`);
    return new Partner(response.data);
  }

  async getPartnerSources(id: number): Promise<Array<FinpackSource>> {
    const response = await this.partnersAxios.get(`${this.baseUrl}/${id}/sources`);
    return response.data;
  }

  async getPartnerSalesmen(id: number): Promise<Array<Salesman>> {
    const response = await this.partnersAxios.get(`${this.baseUrl}/${id}/salesmen`);
    return response.data.map((salesman: SalesmanDTO) => new Salesman(salesman));
  }

  // eslint-disable-next-line vue/max-len
  async getPartnerOrganisations(id: number, filters?: PartnerCooperationFilter): Promise<FiltersResponse<PartnerCooperationItem>> {
    const response = await this.partnersAxios.post<FiltersResponse<PartnerCooperationItem>>(`${this.baseUrl}/${id}/organisations`, filters ? filters.apiData : {});
    response.data.items = response.data.items.map((dto: PartnerCooperationItemDTO) => new PartnerCooperationItem(dto));
    return response.data;
  }

  // eslint-disable-next-line vue/max-len
  async getPartnerDivisions(partnerId: number, organisationId: number, filters?: PartnerCooperationFilter): Promise<FiltersResponse<PartnerCooperationItem>> {
    const response = await this.partnersAxios.post<FiltersResponse<PartnerCooperationItem>>(`${this.baseUrl}/${partnerId}/${organisationId}/divisions`, filters ? filters.apiData : {});
    response.data.items = response.data.items.map((dto: PartnerCooperationItemDTO) => new PartnerCooperationItem(dto));
    return response.data;
  }

  // eslint-disable-next-line vue/max-len
  async getPartnerUsers(partnerId: number, filters?: PartnerCooperationFilter): Promise<FiltersResponse<PartnerCooperationItem>> {
    const response = await this.partnersAxios.post<FiltersResponse<PartnerCooperationItem>>(`${this.baseUrl}/${partnerId}/users`, filters ? filters.apiData : {});
    response.data.items = response.data.items.map((dto: PartnerCooperationItemDTO) => new PartnerCooperationItem(dto));
    return response.data;
  }

  async assignMeToPartner(partnerId: number): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/me/assign`);
    return response.data;
  }

  async unAssignMeToPartner(partnerId: number): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/me/unassign`);
    return response.data;
  }

  async assignMyDivisionsToPartner(partnerId: number): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/my-divisions/assign`);
    return response.data;
  }

  async unAssignMyDivisionsToPartner(partnerId: number): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/my-divisions/unassign`);
    return response.data;
  }

  async assignUsersToPartner(partnerId: number, usersIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/users/assign`, usersIds);
    return response.data;
  }

  async unAssignUsersToPartner(partnerId: number, usersIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/users/unassign`, usersIds);
    return response.data;
  }

  async assignOrganisationsToPartner(partnerId: number, organisationsIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/organisations/assign`, organisationsIds);
    return response.data;
  }

  async unAssignOrganisationsToPartner(partnerId: number, organisationsIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/organisations/unassign`, organisationsIds);
    return response.data;
  }

  async assignDivisionsToPartner(partnerId: number, divisionsIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/divisions/assign`, divisionsIds);
    return response.data;
  }

  async unAssignDivisionsToPartner(partnerId: number, divisionsIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/divisions/unassign`, divisionsIds);
    return response.data;
  }

  async filterPartners(filters?: PartnerFilter): Promise<FiltersResponse<Partner>> {
    const response = await this.partnersAxios
      .post<FiltersResponse<Partner>>(`${this.baseUrl}/search`, filters ? filters.apiData : {});
    response.data.items = response.data.items.map((dto: PartnerDTO) => new Partner(dto));
    return response.data;
  }

  async createPartner(partnerToSend: CreatedPartnerModel): Promise<Partner> {
    const response = await this.partnersAxios.post<PartnerDTO>(`${this.baseUrl}/create`, partnerToSend);
    return new Partner(response.data);
  }

  async updatePartner(id: number, partnerToSend: CreatedPartnerModel): Promise<Partner> {
    const response = await this.partnersAxios.put<PartnerDTO>(`${this.baseUrl}/${id}`, partnerToSend);
    return new Partner(response.data);
  }

  async deletePartner(id: number) {
    await this.partnersAxios.delete(`${this.baseUrl}/${id}`);
  }

  async createSource(partnerId: number, source: { name: string | null }): Promise<FinpackSource> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/source`, source);
    return response.data;
  }

  async updateSource(partnerId: number, sourceId: number, source: { name: string }): Promise<FinpackSource> {
    const response = await this.partnersAxios.put(`${this.baseUrl}/${partnerId}/source/${sourceId}`, source);
    return response.data;
  }

  async deleteSource(sourceId: number,): Promise<FinpackSource> {
    const response = await this.partnersAxios.delete(`${this.baseUrl}/source/${sourceId}`);
    return response.data;
  }

  async createSalesman(partnerId: number,
                       salesmanToSend: { firstname: string | null, lastname: string | null }): Promise<Salesman> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/salesman`, salesmanToSend);
    return new Salesman(response.data);
  }

  async updateSalesman(partnerId: number, salesmanId: number,
                       salesmanToSend: { firstname: string, lastname: string }): Promise<Salesman> {
    const response = await this.partnersAxios
      .put(`${this.baseUrl}/${partnerId}/salesman/${salesmanId}`, salesmanToSend);
    return new Salesman(response.data);
  }

  async deleteSalesman(salesmanId: number,): Promise<FinpackSource> {
    const response = await this.partnersAxios.delete(`${this.baseUrl}/salesman/${salesmanId}`);
    return response.data;
  }

  async divisionUsersCount(partnerId: number, divisionsIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/divisions/users-quantity`, divisionsIds);
    return response.data;
  }

  async organisationsUsersCount(partnerId: number, organisationsIds: (number | undefined)[]): Promise<any> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/organisations/users-quantity`, organisationsIds);
    return response.data;
  }

  async withoutOrganisationUsersCount(partnerId: number): Promise<any> {
    const response = await this.partnersAxios.get(`${this.baseUrl}/${partnerId}/users-quantity-without-organisations`);
    return response.data;
  }

  async addNoteToPartner(partnerId: number, note: Note): Promise<Note> {
    const response = await this.partnersAxios.post(`${this.baseUrl}/${partnerId}/notes`, note);
    return response.data;
  }

  async fetchNotes(partnerId: number): Promise<Array<Note>> {
    const response = await this.partnersAxios.get<Array<Note>>(`${this.baseUrl}/${partnerId}/notes`);
    return response.data.map((note: Note) => new Note(note));
  }

  async setJobForExcelReport(filters: PartnerFilter): Promise<string> {
    const response = await this.partnersAxios.post<string>(`${this.baseUrl}/search?xls=true`,
      filters ? filters.apiData : {});
    return response.data;
  }
}

export default new PartnerApi();
