<template>
  <transition name="fade">
    <v-step
      v-if="tour.steps[tour.currentStep]"
      :key="tour.currentStep"
      :step="tour.steps[tour.currentStep]"
      :labels="tour.labels"
      :highlight="isHighlighted"
      :style="{'z-index': tour.steps[tour.currentStep].params.highlight ? 300 : 303}"
    >
      <v-card
        elevation="2"
        shaped
        :dark="$vuetify.theme.dark"
        slot="content"
      >
        <v-card-title class="font-weight-bold title text-break pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="onboarding.cancel()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex align-center">
          <img src="../../assets/onboarding/onboarding_step_logo.svg" :alt="$onboarding.name" class="logo ml-5">
          <div class="ml-6" v-html-safe="tour.steps[tour.currentStep].content"></div>
        </v-card-text>
        <slot name="actions">
          <v-card-actions
            v-if="!(tour.steps[tour.currentStep].isPreviousButtonHidden && tour.steps[tour.currentStep].isNextButtonHidden)
            || tour.isLast"
            class="pt-0">
            <fp-btn v-if="!tour.steps[tour.currentStep].isPreviousButtonHidden" @click="onboarding.previousStep()">{{ $i18n.useButtonCommons.BACK }}</fp-btn>
            <v-spacer/>
            <fp-btn v-if="!tour.steps[tour.currentStep].isNextButtonHidden" @click="onboarding.nextStep()" primary>{{ $i18n.useButtonCommons.NEXT }}</fp-btn>
            <fp-btn v-if="tour.isLast" @click="onboarding.finish()" primary>{{ $i18n.useButtonCommons.NEXT }}</fp-btn>
          </v-card-actions>
        </slot>
      </v-card>
      <div slot="actions"></div>
    </v-step>
  </transition>
</template>
<script lang="ts">
import {computed,defineComponent, getCurrentInstance,onUpdated, PropType} from 'vue';
import {TourName} from '@/modules/onboarding/tour/models/TourOptions';
import {Tour} from '@/modules/onboarding/tour/Tour';
import VueTour from 'vue-tour';

import useOnboardingTheme from '@/modules/onboarding/useOnboardingTheme';
import {TourStep} from '@/modules/onboarding/tour/models/TourStep';

export default defineComponent({
  props: {
    tourName: {
      type: String as PropType<TourName>,
      required: true,
    },
    tour: {
      type: Object as PropType<Tour & VueTour.Tour>,
      required: true,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const onboarding = computed<Tour>(() => instance!.proxy.$onboarding.tours[props.tourName]);

    const {changeStepHighlightColour, } = useOnboardingTheme();
    const isHighlighted = computed<boolean>(() => {
      const highlight: boolean | undefined = props.tour.steps[props.tour.currentStep].params!.highlight;
      return typeof highlight === 'boolean' ? highlight : true;
    });
    onUpdated(() => {
      const currentStep: TourStep | undefined = props.tour.steps[props.tour.currentStep];
      if (currentStep) {
        const highlightTheme = currentStep.params!.highlightTheme;
        changeStepHighlightColour(highlightTheme);
      }
    });

    return {
      onboarding,
      isHighlighted,
    };
  },
});
</script>
<style lang="scss" scoped>
.v-step {
  width: 500px;
}
::v-deep .v-card {
  border: 2px solid $primary;
  border-radius: 12px !important;
}
.name {
  line-height: 25px;
}
::v-deep .v-btn {
  &.v-btn--icon.v-size--default {
    justify-content: flex-end;
    flex: unset;
    height: 24px;
    width: 24px;
  }
  &--icon {
    justify-self: flex-end;
  }
  &__content {
    justify-content: flex-end;
    flex: unset;
  }
}
</style>
