import {IMultiformNationalityDictionaryElement} from '@/modules/multiForm/shared/IMultiformDictionary';
import {CarrierCode, CountryCallingCode, CountryCode} from 'libphonenumber-js/types';
import EnvironmentService from '@/env/EnvironmentService';
import {NationalNumber, parsePhoneNumberFromString} from 'libphonenumber-js/max';
import MultiformDictionary from '@/modules/multiForm/shared/MultiformDictionaries';

export class PhoneNumberObject implements NullableDeep<IMultiformNationalityDictionaryElement<CountryCallingCode | CarrierCode>> {
  callingCode: Nullable<String> = EnvironmentService.Environment.getAppDomainConfig().phonePrefix || null;
  flag: Nullable<string> = null;
  name_pl: Nullable<string> = null;
  nationality: Nullable<string> = null;
  type: CountryCode = EnvironmentService.Environment.getAppDomainConfig().region;
  phoneNumber: Nullable<NationalNumber> = null;

  constructor(value: string, type?: CountryCode) {
    if (type) {
      this.type = type;
      this.callingCode = MultiformDictionary.countriesDictionary
        .find((country) => country.type === type)?.callingCode || EnvironmentService.Environment.getAppDomainConfig().phonePrefix;
    }
    if (value) {
      // TODO: to trzeba ogarnąć przy kolejnych wdrożeniach
      if (value.startsWith('+48')) {
        this.callingCode = '48';
      }
      if (value.startsWith('+40')) {
        this.callingCode = '40';
      }
      const phoneNumberFromLib = parsePhoneNumberFromString(value);
      if (phoneNumberFromLib) {
        this.phoneNumber = phoneNumberFromLib?.nationalNumber || null;
        this.callingCode = phoneNumberFromLib?.countryCallingCode || null;
        // found country code in library if not found automatically
        const countryCode = phoneNumberFromLib?.country ||
          ((phoneNumberFromLib as any).metadata?.country_calling_codes[phoneNumberFromLib?.countryCallingCode as any] ||
            {})[0] || null;
        this.type = countryCode as CountryCode || null;
      } else {
        // add only digit chars
        this.phoneNumber = value.replace(/\D/g,'');
      }
    }
  }
}
