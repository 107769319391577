import {OrganisationId} from '@/models/enums/OrganisationId';
import AuthService from '@/modules/user/AuthService';
import {ProductType} from '@/commons/enums/ProductType';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import EnvironmentService from '@/env/EnvironmentService';

export type UpdateClientAgreements<T extends Partial<ClientAgreements> = Partial<ClientAgreements>> = {
  clientId: number;
  agreements: T;
  smsCode: string;
}
export class LendiCareAgreements {
  acceptsLendiCareCommercialInformation?: boolean;
  acceptsLendiCarePersonalDataProcessing?: boolean;
  acceptsLendiCareMarketing?: boolean;
}
export class ClientAgreements extends LendiCareAgreements {
  acceptsProcessing: boolean = false;
  acceptsReceivingInfo: boolean = false;
  acceptsReceivingOffers: boolean = false;
  acceptsBigInquiry: boolean = false;
  acceptsReceivingOffersPartner: boolean = false;
  acceptsProcessingPartner: boolean = false;
  acceptsReceivingInfoPartner: boolean = false;
  acceptsProcessingLead: boolean = false;
  acceptsRealEstate: boolean = false;
  acceptsBankSecrecy: boolean = false;
}

export enum AgreementType {
  SMS = 'Sms',
  SCAN = 'Scan',
  PHONE = 'Phone',
  UNKNOWN = 'Unknown',
}

export class Agreements extends ClientAgreements {
  acceptsProcessingMethod?: AgreementType;
  acceptsReceivingInfoMethod?: AgreementType;
  acceptsReceivingOffersMethod?: AgreementType;
  acceptsBigInquiryMethod?: AgreementType;
  acceptsReceivingOffersPartnerMethod?: AgreementType;
  acceptsReceivingInfoPartnerMethod?: AgreementType;
  acceptsProcessingPartnerMethod?: AgreementType;
  acceptsProcessingLeadMethod?: AgreementType;
  acceptsRealEstateMethod?: AgreementType;
  acceptsBankSecrecyAt?: string;
  acceptsProcessingLeadAt?: string;
  acceptsProcessingAt?: string;
  acceptsReceivingInfoAt?: string;
  acceptsReceivingOffersAt?: string;
  acceptsBigInquiryAt?: string;
  acceptsReceivingOffersPartnerAt?: string;
  acceptsReceivingInfoPartnerAt?: string;
  acceptsProcessingPartnerAt?: string;
  acceptsRealEstateAt?: string;
  acceptsLendiCareCommercialInformationAt?: string;
  acceptsLendiCareCommercialInformationMethod?: AgreementType;
  acceptsLendiCarePersonalDataProcessingAt?: string;
  acceptsLendiCarePersonalDataProcessingMethod?: AgreementType;
  acceptsLendiCareMarketingAt?: string;
  acceptsLendiCareMarketingMethod?: AgreementType;
}

export interface IAgreementItemLabel {
  title?: string;
  label: string;
}

export interface IAgreementItemWithUrl {
  title: string;
  url: string;
}

export interface IAgreementItemViewModel extends IAgreementItemLabel{
  key: keyof ClientAgreements;
  required: (v?: Agreements) => boolean;
  organisationIds?: OrganisationId[];
  productTypes: Array<ProductType>;
  isEditable: boolean;
  isRealEstate?: boolean;
  isCompany?: boolean;
}

export type AgreementsKeys = keyof Agreements;
export type agreement = Record <AgreementsKeys, IAgreementItemLabel>;

const getAgreementByKey = (key: AgreementsKeys) => EnvironmentService.Environment.getDomainAgreement(key);

export const AgreementViews: IAgreementItemViewModel[] = [
  {
    title: getAgreementByKey('acceptsProcessing')?.title,
    key: 'acceptsProcessing',
    label: getAgreementByKey('acceptsProcessing')!.label,
    isEditable: false,
    required: (v) => !!v && (!v.acceptsProcessingPartner || AuthService.User!.organisationId === OrganisationId.LENDI),
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE,],
  },
  {
    key: 'acceptsProcessingLead',
    label: getAgreementByKey('acceptsProcessingLead')!.label,
    isEditable: false,
    required: (v) => !!v,
    isCompany: true,
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE,],
  },
  {
    title: getAgreementByKey('acceptsProcessingPartner')?.title,
    key: 'acceptsProcessingPartner',
    label: getAgreementByKey('acceptsProcessingPartner')!.label,
    organisationIds: [OrganisationId.FINAMAX,],
    isEditable: false,
    required: (v) => !!v && !v.acceptsProcessing,
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE,],
  },
  {
    title: getAgreementByKey('acceptsReceivingInfo')?.title,
    key: 'acceptsReceivingInfo',
    label: getAgreementByKey('acceptsReceivingInfo')!.label,
    isEditable: false,
    required: (v) => true,
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE,],
  },
  {
    title: getAgreementByKey('acceptsReceivingInfoPartner')?.title,
    key: 'acceptsReceivingInfoPartner',
    label: getAgreementByKey('acceptsReceivingInfoPartner')!.label,
    organisationIds: [OrganisationId.FINAMAX,],
    isEditable: false,
    required: (v) => !!v && v.acceptsProcessingPartner,
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE, ProductType.REAL_ESTATE,],
  },
  {
    title: getAgreementByKey('acceptsReceivingOffers')?.title,
    key: 'acceptsReceivingOffers',
    label: getAgreementByKey('acceptsReceivingOffers')!.label,
    required: (v) => false,
    isEditable: true,
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE, ProductType.REAL_ESTATE,],
  },
  {
    title: getAgreementByKey('acceptsReceivingOffersPartner')?.title,
    key: 'acceptsReceivingOffersPartner',
    label: getAgreementByKey('acceptsReceivingOffersPartner')!.label,
    required: (v) => false,
    isEditable: true,
    organisationIds: [OrganisationId.FINAMAX,],
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE, ProductType.REAL_ESTATE,],
  },
  {
    title: getAgreementByKey('acceptsBigInquiry')?.title,
    key: 'acceptsBigInquiry',
    label: getAgreementByKey('acceptsBigInquiry')!.label,
    isEditable: true,
    required: (v) => false,
    productTypes: [ProductType.SME, ProductType.CASH, ProductType.MORTGAGE, ProductType.REAL_ESTATE,],
  },
  {
    title: getAgreementByKey('acceptsRealEstate')?.title,
    key: 'acceptsRealEstate',
    label: getAgreementByKey('acceptsRealEstate')!.label,
    isEditable: false,
    isRealEstate: true,
    required: (v) => true,
    productTypes: [ProductType.REAL_ESTATE,],
  },
];
