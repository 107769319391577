export enum ReferenceRateType {
   WIRON_1M = 'WIRON 1M',
   WIRON_3M = 'WIRON 3M',
   WIRON_6M = 'WIRON 6M',
   WIBOR_1M = 'WIBOR 1M',
   WIBOR_2M = 'WIBOR 2M',
   WIBOR_3M = 'WIBOR 3M',
   WIBOR_6M = 'WIBOR 6M',
   WIBOR_12M = 'WIBOR 12M',
   EURIBOR_1M = 'EURIBOR 1M',
   EURIBOR_2M = 'EURIBOR 2M',
   EURIBOR_3M = 'EURIBOR 3M',
   EURIBOR_6M = 'EURIBOR 6M',
   EURIBOR_12M = 'EURIBOR 12M',
   WIBID_1M = 'WIBID 1M',
   WIBID_2M = 'WIBID 2M',
   WIBID_3M = 'WIBID 3M',
   WIBID_6M = 'WIBID 6M',
   WIBID_12M = 'WIBID 12M',
   LIBID_1M = 'LIBID 1M',
   LIBID_2M = 'LIBID 2M',
   LIBID_3M = 'LIBID 3M',
   LIBID_6M = 'LIBID 6M',
   LIBID_12M = 'LIBID 12M',
   LIBOR_1M = 'LIBOR 1M',
   LIBOR_2M = 'LIBOR 2M',
   LIBOR_3M = 'LIBOR 3M',
   LIBOR_6M = 'LIBOR 6M',
   LIBOR_12M = 'LIBOR 12M',
   EURIBID_1M = 'EURIBID 1M',
   EURIBID_2M = 'EURIBID 2M',
   EURIBID_3M = 'EURIBID 3M',
   EURIBID_6M = 'EURIBID 6M',
   EURIBID_12M = 'EURIBID 12M',
   CIBOR_3M = 'CIBOR 3M',
   OIBOR_3M = 'OIBOR 3M',
   STIBOR_3M = 'STIBOR 3M',
   IRCC_TRIMESTRIAL = 'IRCC TRIMESTRIAL',
   OTHER = 'OTHER',
}
