<template>
    <div class="flex">
      <bank-logo v-if="!store.userInput.hideOfferName" :bank-symbol="offer.info.bank"/>
      <div class="pl-4">
        <template v-if="!store.userInput.hideOfferName" >
          <p class="headline-5 mb-0">{{offer.info?.bankName}}</p>
          <p class="body-2 opacity-60">{{offer.info?.title}}</p>
        </template>
        <p v-else>
          {{offer.shortName}}
        </p>
        <p>
          <span class="opacity-60">{{ $i18n.useCreditDetailsForm.INSTALLMENT_TYPE }}:</span>
          <span v-if="offer.features.isSafeCredit2Percent">{{ $i18n.useRefusedMortgageTableElements.DESCENDING }} + </span>
          <span>{{ userInput.fixedInstallments ? $i18n.useRefusedMortgageTableElements.FIXED : $i18n.useRefusedMortgageTableElements.DESCENDING }}</span>
        </p>
        <p>
          <span class="opacity-60">{{ $i18n.useSimulationResult.LOAN_AMOUNT }}:</span>
          <span>{{ (offer.customizations.customLoanAmount || userInput.loanAmount) | numeralFormat}}  {{ userInput.currency }}</span>
        </p>
        <p>
          <span class="opacity-60">{{ $i18n.useCreditDetailsForm.LOAN_PERIOD }}:</span>
          <span>{{ (offer.customizations.customLoanPeriodInMonths || userInput.loanPeriodInMonths) }} {{ $i18n.useSchedulePrintout.MONTHS }}</span>
        </p>
      </div>
    </div>
</template>

<script setup lang="ts">
import BankLogo from '@/components/calculator/BankLogo.vue';
import { SimulationDemand } from '@/models/Demand';
import Offer from '@/models/Offer';
import { useSimulationStore } from '../services/SimulationStore';

const props = withDefaults(defineProps<{
  offer: Offer,
  userInput: SimulationDemand,
}>(), {
  offer: () => new Offer(),
});

const store = useSimulationStore();
</script>

<style lang="scss" scoped>

</style>
