<template>
  <base-badge :right="right">
    <template v-slot:avatar>
      <person-avatar :person="prospect.contact" />
    </template>
    <template v-slot:first-line>
      <span class="primary-text-color font-weight-bold main-name">
        {{prospect.contact.firstName}} {{prospect.contact.lastName || ''}}
      </span>
    </template>
    <template v-slot:second-line>
      <span class="secondary-text-color">
        <span v-if="prospect.isProspectFrom.eko">Ekspert Kredytowy Online •</span>
        {{prospect.contact.createdAt | dateFormat}}
      </span>
    </template>
  </base-badge>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseBadge from '@/components/badges/base/BaseBadge.vue';
import PersonAvatar from '@/components/commons/PersonAvatar.vue';
import {Prospect} from '@/models/Prospect';

@Component({
  components: {PersonAvatar, BaseBadge, },
})
export default class ProspectBadge extends BaseBadge {
  @Prop({required: true, }) prospect!: Prospect;
}
</script>

<style scoped lang="scss">
  .initials-style{
    color: var(--v-primaryBg-base);
    text-align: center;
    justify-content: center
  }
</style>
