import {LoanApplicationStepEnum} from '@/modules/loanApplication/enums/LoanApplicationStepEnum';
import User from '@/models/user/User';
import {Person} from '@/models/Person';

export abstract class NoteDTO {
  public id?: number;
  public dealId?: number;
  public applicationId?: number;
  public clientId?: number;
  public user!: {firstName: string, lastName: string};
  public text: string = '';
  public author?: number;
  public to?: string;
  public from?: string;
  public createdAt?: string;
}

export default class Note extends NoteDTO {
  public createdAt!: string;

  constructor(noteDTO?: NoteDTO) {
    super();
    Object.assign(this, noteDTO);
  }

  public get createdAtDateObject() {
    return new Date(this.createdAt);
  }

  public set createdAtDateObject(newDate: Date) {
    this.createdAt = newDate.toISOString();
  }
}
