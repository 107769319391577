<template>
  <v-row no-gutters>
    <v-col v-if="!simple" cols="12">
      <span :class="labelClass">{{ label }}</span>
      <v-tooltip location="top" v-if="tooltip" max-width="350">
        <template v-slot:activator="{ on }">
          <v-icon
              v-on="on"
              class="text-medium-emphasis ml-1"
              :size="18">
              mdiInformationOutline
          </v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="12" class="mt-1">
      <v-tooltip v-if="simple && label" bottom>
        <template v-slot:activator="{on}">
          <span v-on="on">
            <v-rating
                :hover="true"
                :size="size"
                :length="5"
                @update:value="onUpdate"
                :value="value"
                :readonly="readonly"/>
          </span>
        </template>
        <span>{{ label }}</span>
      </v-tooltip>
      <v-rating
        v-else
        :hover="true"
        :size="size"
        :length="5"
        @update:value="onUpdate"
        :value="value"
        :readonly="readonly"/>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
type LRatingProps = {
  value: number
  label?: string
  labelClass?: string
  tooltip?: string
  size?: string | number,
  readonly?: boolean
  simple?: boolean
}
type LRatingEmits = {
  (e: 'update:modelValue', newValue: number): void
};

const props = withDefaults(defineProps<LRatingProps>(), {
  size: 32,
  labelClass: 'body-1',
});
const emits = defineEmits<LRatingEmits>();

const onUpdate = (event: number) => {
  emits('update:modelValue', event);
};
</script>

<style lang="scss" scoped>
:deep(.v-rating__item) {
  .v-btn {
    @apply mr-3;
    justify-content: left !important;
    align-items: center !important;
    height: 56px;
    width: 48px;
    .v-icon{
      height: v-bind(size);
      width: v-bind(size);
    }
  }
}
</style>
