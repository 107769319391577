<template>
  <base-badge :right="right" v-if="user !== null">
    <template v-slot:avatar>
      <person-avatar :person="user" />
    </template>
    <template v-slot:first-line>
      <v-row
             no-gutters
             class="primary-text-color font-weight-bold main-name align-center flex-nowrap"
             :class="{reversed: right, 'justify-end': right}">
        <v-col class="shrink">
          {{user.displayName}}<span v-if="organisationName" class="font-italic font-weight-light ml-2">({{organisationName}})</span>
        </v-col>
        <v-col>
          <slot/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:second-line v-if="user">
      <span class="secondary-text-color" v-if="showLogin && !$scopedSlots['second-line']">
        {{ user.username }}
      </span>
      <slot name="second-line"/>
    </template>
    <template v-slot:suffix v-if="user">
      <slot name="suffix"/>
    </template>
  </base-badge>
  <v-skeleton-loader v-else class="transparent" type="list-item-avatar"/>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import User from '@/models/user/User';
import PersonAvatar from '@/components/commons/PersonAvatar.vue';
import BaseBadge from '@/components/badges/base/BaseBadge.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import CopyId from '@/components/badges/CopyId.vue';
import {I18N} from '@/services/enumTranslator/I18NDictionary';

@Component({
  components: {PersonAvatar, BaseBadge, CopyId, },
})
export default class UserBadge extends BaseBadge {
  @Prop({default: () => null,})
  public user!: Nullable<User>;

  @Prop({ default: false, })
  showId!: boolean;

  @Prop({ default: false, })
  public showLogin!: boolean;

  get organisationName(): Nullable<string> {
    return this.user?.organisationId ? I18NGetter().OrganisationId[this.user!.organisationId] : null;
  }
}
</script>

<style scoped lang="scss">
  .user-badge ::v-deep .v-list-item--avatar{
    padding-right: 0 !important;
    padding-left: 0 !important;;
  }
  .reversed-badge{
    .avatar{
      display: flex;
    }

    ::v-deep .v-list-item{
      flex-direction: row-reverse;
      padding-left: 0;
    }
  }

  .main-client {
    .client-display * {
      font-size: 16px;
    }
  }

  ::v-deep .v-list-item__title{
    line-height: 24px;
    height: 24px;
  }
  ::v-deep .v-list-item__subtitle {
    line-height: 20px;
    height: 20px;
  }

  ::v-deep .v-skeleton-loader__list-item-avatar {
    background-color: transparent !important;
    padding-left: 0px;
  }
</style>
