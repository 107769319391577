<template>
  <fp-checkbox
    v-model="model"
    :disabled="isFilterDisabled(filter)">
    <template #label>
      <div class="d-flex justify-space-between align-baseline label">
        <span>
          {{ filter.name_pl }}
        </span>
        <span class="text--secondary font-weight-medium">
          {{ store.allOffers.filter((x) => filter.filterFunction(x)).length }}
        </span>
      </div>
    </template>
  </fp-checkbox>
</template>
<script lang="ts" setup>
import useOfferSorting, {
  FilterCategory, FilterKey,
  IFilteringMultiformElement,
  OfferFilters
} from '@/components/calculator/results/OfferSorting';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {computed} from 'vue';

const props = defineProps<{
  filter: OfferFilters;
  value?: boolean;
}>();

const emit = defineEmits<{
  (e: 'input', value: boolean): void;
}>();

const store = useSimulationStore();

const isFilterDisabled = (filter: OfferFilters): boolean =>
  store.allOffers.filter((x) => filter.filterFunction(x)).length === 0 &&
!store.userInput?.offerFilters[filter.type];

const model = computed({
  get: () => props.value,
  set: (value) => emit('input', !!value),
});
</script>
