export enum LoanPurposeActivity {
  BUYING = 'Buying',
  BUILDING = 'Building',
  RENOVATING = 'Renovating',
  ADAPTATION = 'Adaptation',
  LOAN = 'Loan',
  BUYING_AND_RENOVATING = 'BuyingAndRenovating',
  BUYING_PLOT_AND_BUILDING = 'BuyingPlotAndBuilding',
  REFINANCING_RESIDENTIAL_REAL_ESTATE = 'RefinancingResidentialRealEstate',
  REFINANCING_NONRESIDENTIAL_REAL_ESTATE = 'RefinancingNonresidentialRealEstate',
  REFINANCING_REAL_ESTATE_LOAN = 'RefinancingRealEstateLoan',
  REFINANCING_NON_REAL_ESTATE_LOAN = 'RefinancingNonRealEstateLoan',
  HOME_DEBT_CONSOLIDATION_LOAN = 'HomeDebtConsolidationLoan',
  CONSUMER_DEBT_CONSOLIDATION_LOAN = 'ConsumerDebtConsolidationLoan',
}
