<template>
  <div class="no-offers">
    <img :src="require('@/assets/calculator/no-offers.svg')" alt="no offers">
    <template v-if="isAnyOfferInvisibleByAppliedFilters">
      <p class="font-weight-medium mb-1">{{ I18NGetter().useNoOffers.NO_OFFERS_PARAMS }}</p>
      <p>{{ I18NGetter().useNoOffers.TRY_TO_CHANGE_PARAMS_FILTERS_BANKS }}</p>
      <div class="buttons my-2">
        <fp-btn class="flex-fill mr-4" @click="store.unselectAllBanks()">{{ I18NGetter().useNoOffers.UNSELECT_ALL_BANKS }}</fp-btn>
        <fp-btn class="flex-fill" @click="store.unselectAllFilters()">{{ I18NGetter().useNoOffers.CLEAR_FILTERS }}</fp-btn>
      </div>
    </template>
    <template v-else-if="isAnyOfferAvailableOrRefused">
      <p class="font-weight-medium mb-1">
        {{ store.isShownAvailableProducts ? I18NGetter().useNoOffers.NO_AVAILABLE_OFFERS_PARAMS : I18NGetter().useNoOffers.NO_REFUSED_OFFERS_PARAMS }}
      </p>
      <p>
        <span v-if="store.isShownAvailableProducts">{{ I18NGetter().useNoOffers.GO_TO_REFUSED_OFFERS }}</span>
        <span v-else>{{ I18NGetter().useNoOffers.GO_TO_AVAILABLE_OFFERS }}</span>
      </p>
    </template>
    <template v-else>
      <p class="font-weight-medium mb-1">
        {{ I18NGetter().useNoOffers.NO_OFFERS }}
      </p>
      <p>
        {{ I18NGetter().useNoOffers.NO_OFFERS_DESCRIPTION }}
      </p>
    </template>
  </div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {Offer} from '@/models/simulation/Simulation';

const store = useSimulationStore();

const isAnyOfferInvisibleByAppliedFilters = computed<boolean>(() => {
  const offers: Offer[] = (store.isShownAvailableProducts
    ? store.simulation?.offers : store.simulation?.refusedOffers) ?? [];
  const isSomeFilterSelected: boolean = store.selectedFiltersCount > 0;
  return isSomeFilterSelected && offers.length > 0;
});

const isAnyOfferAvailableOrRefused = computed<boolean>(() =>
  Boolean(store.simulation?.refusedOffers?.length || store.simulation?.offers?.length));
</script>
<style lang="scss" scoped>
.no-offers {
  text-align: center;
  position: sticky;
  left: 25%;
  top: 25%;
  width: 400px;

  p:first-of-type {
    font-size: 20px;
  }
}
</style>
