import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
import NPSList from '@/modules/nps/views/NPSList.vue';
import NPSDetails from '@/modules/nps/views/NPSDetails.vue';

const NPSRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.NPS.NPS_LIST,
    component: NPSList,
  },
  {
    path: ':id',
    name: Routes.NPS.NPS_DETAILS,
    component: NPSDetails,
  },
];

export default NPSRoutes;
