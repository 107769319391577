<template>
  <v-menu offset-y z-index="200">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :id="$cy.userButton"
        class="user-button white-border fill-width fade-icon-button text-capitalize justify-start px-2"
        outlined>
        <v-row no-gutters>
          <v-col class="shrink">
            <v-avatar v-if="$user.photoUrl" size="24"><v-img :src="$user.photoUrl"/></v-avatar>
            <v-icon v-else>mdi-account-circle</v-icon>
          </v-col>
          <template v-if="!icon">
            <v-col class="grow text-left pl-4 text-truncate">
              <span>{{name}}</span>
            </v-col>
            <v-col class="shrink">
              <v-icon right>keyboard_arrow_down</v-icon>
            </v-col>
          </template>
        </v-row>
      </v-btn>
    </template>
    <slot name="menu" />
  </v-menu>
</template>

<script setup lang="ts">
import { useInstance } from '@/composables/useInstance';

type UserButtonProps = {
  name: string,
  icon: boolean,
}

const props = withDefaults(defineProps<UserButtonProps>(), {
  icon: true,
});

const { $user, } = useInstance();

</script>

<style scoped lang="scss">
.user-button {
  ::v-deep .v-btn__content {
    width: 100%;
  }
}
</style>
