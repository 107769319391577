<template>
   <fp-data-table
      :headers="headers"
      :items="items"
      :options="options"
      :hide-footer="props.simple"
      @update:options="onUpdateOptions"
      :loading="loading"
      height="auto"
    >
      <template #top>
        <v-row v-if="!simple && !short">
          <v-col cols="3">
            <fp-select
                class="py-2 px-0"
                :label="$i18n.useBranchesList.SOURCE_TYPE"
                v-model="options.sourceType"
                :items="Object.values(FeedbackSourceType)"
                @change="fetchData"
            />
          </v-col>
          <v-col cols="3">
            <fp-select
              class="py-2 px-0"
              :label="$i18n.useBranchesList.BRANCH_TYPE"
              v-model="options.branchType"
              :items="BranchTypes"
              itemName="name_pl"
              itemKey="type"
              @change="fetchData"
            />
          </v-col>
          <v-col cols="3">
            <v-switch
              label="Tylko z opinią"
              v-model="options.hasTextOpinion"
              @change="filterTextOpinion"
            />
          </v-col>
        </v-row>
        <table-pagination v-if="!props.simple" :options="options">
          <fp-filter :headers="headers" v-model="options" @input="fetchData"/>
        </table-pagination>
      </template>
      <template #item="{item}">
        <tr class="text-left">
          <td v-if="visibleElements.id">{{ item.survey.id }}</td>
          <td v-if="visibleElements.user">
            <fp-loader v-if="!usersData[item.survey.userId]" size="44px" circular/>
            <v-tooltip v-else top>
              <template v-slot:activator="{on}">
              <span v-on="on" class="pointer">
                <person-avatar
                  @click.native.stop="goToUserDetails(item.survey.userId)"
                  :person="usersData[item.survey.userId]"
                />
              </span>
              </template>
              <p>{{ usersData[item.survey.userId].displayName }}</p>
            </v-tooltip>
          </td>
          <td v-if="visibleElements.branch">
          <span v-if="usersData[item.survey.userId]?.branch"
                @click="goToBranch(usersData[item.survey.userId].branch.id)"
                class="pointer">
            {{ usersData[item.survey.userId].branch.name }}
          </span>
            <span v-else>-</span>
          </td>
          <td v-if="visibleElements.client">
            <v-tooltip top>
              <template v-slot:activator="{on}">
              <span v-on="on" class="pointer">
                <person-avatar
                  @click.native.stop="goToClientDetails(item.survey.clientId)"
                  :person="{initials: clientsInitials[item.survey.clientId]}"
                />
              </span>
              </template>
              <p>{{ item.survey.clientName }}</p>
            </v-tooltip>
          </td>
          <td v-if="visibleElements.createdAt">
            <fp-date-time-view :date="item.feedback.createdAt" class="date-view"/>
          </td>
          <td v-if="visibleElements.ratings" class="rating-column py-2">
            <l-rating v-for="(config, index) in ratingsConfig"
                      :key="index"
                      v-model="item.feedback[config.model]"
                      :label="config.label"
                      :tooltip="config.tooltip"
                      :simple="props.simple"
                      :size="20"
                      class="pb-1"
                      label-class="body-3"
                      readonly/>
          </td>
          <td v-if="visibleElements.opinion">
            <p>{{ item.feedback.opinion || '-' }}</p>
          </td>
          <td v-if="visibleElements.management">
            <fp-checkbox
              v-model="item.feedback.publish"
              @input="() => onPublishChange(item)"
              label="Opublikowana"/>
            <fp-checkbox
              v-model="item.feedback.calculate"
              @input="() => onCalculateChange(item)"
              class="mt-2"
              label="Uwzględniona w średniej"/>
          </td>
          <td v-if="visibleElements.deal" @click.stop="goToDeal(item.survey.dealId)" class="pointer">
            <span v-if="item.survey.dealId">#{{ item.survey.dealId }}</span>
            <span v-else>-</span>
          </td>
          <td v-if="visibleElements.source">{{ item.feedback.sourceType || '-' }}</td>
          <td v-if="visibleElements.lead">
            <v-icon v-if="item.survey.prospectUuid">mdi-leaf</v-icon>
            <span v-else>-</span>
          </td>
          <td v-if="visibleElements.client">
            <span>{{item.survey.clientPhone}}</span>
          </td>
        </tr>
      </template>
    </fp-data-table>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, onMounted, Ref, ref} from 'vue';
import {DataTableHeader} from '@/services/searchEngine/interfaces/DataTableHeader';
import {NPSFilter} from '@/models/Filter';
import FpDataTable from '@/components/datatables/FpDataTable.vue';
import TablePagination from '@/components/commons/TablePagination.vue';
import PersonAvatar from '@/components/commons/PersonAvatar.vue';
import {NPSFeedback, NPSOpinion} from '@/modules/nps/models/NPS';
import GoToService from '@/router/GoToService';
import User from '@/models/user/User';
import UserApi from '@/modules/user/services/UserApi';
import NPSApi from '@/modules/nps/services/NPSApi';
import LRating from '@/components/stats/LRating.vue';
import {cloneDeep, isEqual} from 'lodash-es';
import FpFilter from '@/components/inputs/FpFilter.vue';
import BranchTypes from '@/modules/branches/services/BranchTypes';
import {FilterType} from '@/services/searchEngine/enums/FilterType';
import {debouncedAtInput} from '@/services/utils/BasicUtils';
import {FeedbackSourceType} from '@/modules/nps/enums/FeedbackSourceType';

type RatingConfig = {
  model: keyof NPSFeedback,
  label?: string,
  tooltip?: string,
  simple?: boolean,
}

type NPSTableElement = {
  id: number,
  user: number,
  branch: string,
  client: number,
  createdAt: string,
  ratings: Array<number>,
  opinion: string,
  management: any,
  deal: string,
  source: string,
  load: boolean,
  clientPhone: string
}

const props = defineProps<{
  opinions?: Array<NPSOpinion>;
  params?: NPSFilter,
  short?: boolean;
  simple?: boolean;
}>();

const MAX_INITIALS_LENGTH = 3;

const items: Ref<Array<NPSOpinion>> = ref([]);
const users: Ref<Array<User>> = ref([]);
const options = ref<NPSFilter>(new NPSFilter());
const oldOptions: Ref<NPSFilter> = ref(cloneDeep(options.value));
const loading: Ref<boolean> = ref(false);
const instance = getCurrentInstance();

const headersConfig = computed<Array<DataTableHeader<NPSTableElement, NPSFilter>>>(() => {
  return [
    {text: 'ID', value: 'id', valueSort: 'id', condition: !props.short,},
    {
      text: 'Ekspert',
      value: 'user',
      valueSort: 'userIds',
      valueFilter: 'users',
      filterType: FilterType.AUTOCOMPLETE,
      condition: !props.short,
    },
    {text: 'Placówka', value: 'branch', valueSort: 'branch', condition: !props.short, },
    {text: 'Klient', value: 'client', },
    {text: 'Data wystawienia', value: 'createdAt', valueFilter: 'createdAt', filterType: FilterType.DATE, valueSort: 'createdAt' && (!props.short && !props.simple) , },
    {text: 'Oceny', value: 'ratings', valueFilter: 'rating', filterType: FilterType.RANGE, range: {from: 1, to: 5,},},
    {text: 'Opinia', value: 'opinion', },
    {text: 'Zarządzanie', value: 'management', condition: !props.short, },
    {text: 'Sprawa', value: 'deal', },
    {text: 'Żródło', value: 'source', valueSort: 'source', condition: !props.short, },
    {text: 'Lead', value: 'lead', condition: !props.short, },
    {text: 'Numer telefonu klienta', value: 'clientPhone', valueFilter: 'clientPhone', filterType: FilterType.TEXT,},
  ];
});

const ratingsConfig = computed<Array<RatingConfig>>(() => {
  return [
    {
      model: 'substantivePartOfMeeting',
      label: 'Wiedza',
    },
    {
      model: 'expertInvolvement',
      label: 'Zaangażowanie',
    },
    {
      model: 'expertBehavior',
      label: 'Kultura osobista',
    },
  ];
});

const visibleElements = computed<Partial<Record<keyof NPSTableElement, boolean>>>(() => {
  const visibilityConfig: Partial<Record<keyof NPSTableElement, boolean>> = {};
  headersConfig.value.map(item => {
    const key = item.value as keyof NPSTableElement;
    visibilityConfig[key] = item.condition === undefined || item.condition;
  });

  return visibilityConfig;
});

const headers = computed<Array<DataTableHeader<NPSTableElement, NPSFilter>>>(() => {
  return headersConfig.value
    .filter(item => item.condition === undefined || item.condition)
    .map(x => new DataTableHeader<NPSTableElement, NPSFilter>(x));
});

onMounted(() => {
  if (props.params) {
    options.value = props.params;
    cloneOptions();
  }

  if (props.opinions) {
    items.value = props.opinions;
    fetchUsers();
  } else {
    fetchData();
  }
});

const onUpdateOptions = (newValue: NPSFilter) => {
  if (isEqual(cloneDeep(oldOptions.value), cloneDeep(options.value))) {
    return;
  }

  if (!props.simple && options.value.page) {
    GoToService.setQuery({page: String(options.value.page), }, false);
  }

  cloneOptions();
  fetchData();
};

const filterTextOpinion = async() => {
  if (!options.value.hasTextOpinion) delete options.value.hasTextOpinion;
  await fetchData();
};

const fetchData = async() => {
  loading.value = true;
  try {
    options.value.page = instance?.proxy.$route.query.page ? Number(instance?.proxy.$route.query.page) : 1;
    const response = await NPSApi.getOpinions(options.value);
    items.value = response.items;
    options.value.quantity = response.quantity;
    options.value.page = response.page || options.value.page;
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }

  cloneOptions();
  fetchUsers();
};

const getUsersIdsToFetch = () => {
  const usersIds: Array<number> = [];
  items.value.map(item => {
    if (!usersData.value[item.survey.userId] && !usersIds.includes(item.survey.userId)) {
      usersIds.push(item.survey.userId);
    }
  });

  return usersIds;
};

const fetchUsers = async() => {
  if (props.short) {
    return;
  }

  const usersIds = getUsersIdsToFetch();
  if (!usersIds.length) {
    return;
  }

  const response = await UserApi.usersForFilters({users: usersIds,});
  if (response) {
    users.value.push(...response);
  }
};

const usersData = computed<Record<number, User>>(() => {
  const usersMap: Record<number, User> = {};
  users.value.map(user => {
    usersMap[user.id] = user;
  });

  return usersMap;
});

const clientsInitials = computed<Record<number, string>>(() => {
  const initials: Record<number, string> = {};

  items.value.map(item => {
    initials[item.survey.clientId] = (item.survey.clientName.split(' ').map(x => x.substring(0, 1).toUpperCase()).join('') || '').substring(0,MAX_INITIALS_LENGTH);
  });

  return initials;
});

const goToUserDetails = (userId: number) => {
  if (userId) {
    GoToService.user(userId);
  }
};

const goToClientDetails = (clientId: number) => {
  if (clientId) {
    GoToService.client(clientId);
  }
};

const goToDeal = (dealId: number) => {
  if (dealId) {
    GoToService.deal(dealId);
  }
};

const goToBranch = (branchId: number) => {
  if (branchId) {
    GoToService.branch(branchId);
  }
};

const onPublishChange = (item: NPSOpinion) => {
  item.feedback!.calculate = item.feedback!.publish;
  processOpinion(item, item.feedback!.publish, item.feedback!.calculate);
};

const onCalculateChange = (item: NPSOpinion) => {
  processOpinion(item, item.feedback!.publish, item.feedback!.calculate);
};

const processOpinion = async(item: NPSOpinion, publish: boolean = false, calculate: boolean = false) => {
  try {
    await NPSApi.pocessOpinion(item.feedback!.id, { publish, calculate, });
    instance?.proxy.$snackbarService.openSuccessSnackbar('Zmieniono status opinii');
  } catch (e) {
    instance?.proxy.$snackbarService.openErrorSnackbar('Wystąpił bład podczas przetwarzania opinii');
  }
};

const cloneOptions = () => {
  oldOptions.value = cloneDeep(options.value);
};
</script>

<style scoped lang="scss">
.date-view :deep(.v-list-item ) {
  padding-left: 0;
  padding-right: 0;
}
:deep(.rating-column) {
  .v-rating .v-icon {
    padding: 2px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .col {
    line-height: 1rem;
    &:last-child {
      margin-top: 0 !important;
    }
  }
}
</style>
