<template>
  <v-card :id="$cy.newReportModal">
    <v-card-text>
      <div class="mt-8 text-center">
        <img v-if="$env.isAppDomain().LENDI_PL" class="mt-4" src="@/assets/lenda/settlmentButtonLenda.svg" width="200px"/>
        <img v-else class="mt-4" src="@/assets/lenda/settlmentButtonLendaRO.svg" width="200px"/>
        <p class="headline-5 mb-1">{{ $i18n.useReportForm.MODAL_REMOVED_HEADER }}</p>
        <p class="body-2">{{ $i18n.useReportForm.MODAL_REMOVED_DESCRIPTION }}</p>
        <template v-if="$env.isAppDomain().LENDI_PL">
          <p class="body-2">Jak prawidłowo dodać rozliczenie z poziomu sprawy w materiale</p>
          <p>
            <a href="https://platforma.lendi.pl/v2/app/knowledge/trainings/226"
               target="_blank">
              Przejdź do szkolenia</a>
          </p>
        </template>
      </div>
    </v-card-text>
    <v-card-actions class="px-6">
      <v-layout ma-0>
        <v-spacer />
        <v-flex class="text-right">
          <fp-btn class="mr-2" @click="goToSearchForDeal()">
            {{ $i18n.useReportForm.SEARCH_DEALS }}
          </fp-btn>
          <fp-btn primary @click="modal.closeModal(true)">
            {{ $i18n.useDealPicker.NEW_DEAL }}
          </fp-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import AppRouter from '@/router';
import {Routes} from '@/router/Routes';
import {useModalInstance} from '@/composables/useModalInstance';

const modal = useModalInstance<{}, true>();

const goToSearchForDeal = () => {
  AppRouter.push({
    name: Routes.DEALS.DEALS_LIST,
  });
  modal.closeModal();
}
</script>

<style scoped lang="scss">

</style>
