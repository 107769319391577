import UiStorageApi from '@/composables/useUiStorage/UiStorageApi';
import {UiStorageState} from '@/composables/useUiStorage/UiStorageState';
import {StorageLikeAsync} from '@vueuse/core';
import {UserLocalStorage} from '@/services/UserLocalStorage';
import {cloneDeep, has, isEqual, isNil} from 'lodash-es';

class UiStorageService implements StorageLikeAsync {
  private data: Nullable<UiStorageState> = null;
  private requests: PartialRecord<string, Promise<Nullable<any>>> = {};

  async getItem<T>(key: string): Promise<Nullable<T>> {
    // Check if the value is available in the cache
    if (this.requests[key]) {
      return this.requests[key];
    }

    // Make the API call and store the promise in the cache
    const request = (async() => {
      if (!this.data) {
        this.data = await UiStorageApi.fetchUiStorageData();
      }
      const value = this.data?.[key];
      return value ?? null;
    })();

    this.requests[key] = request;

    try {
      const value = await request;
      // Remove the promise from the cache once the value is received
      delete this.requests[key];
      return value;
    } catch (e) {
      // Remove the promise from the cache if there was an error
      delete this.requests[key];
      console.error(e);
      return null;
    }
  };

  async setItem<T>(key: string, value: T): Promise<void> {
    try {
      const previousState = cloneDeep(this.data);
      this.data = {
        ...this.data,
        [key]: value,
      };
      if (isEqual(previousState, this.data)) {
        return;
      }
      await UiStorageApi.saveUiStorageData(this.data);
    } catch (e) {
      console.error(e);
    }
  };

  async removeItem(key: string): Promise<void> {
    if (!this.data || !has(this.data, key)) {
      return;
    }
    delete this.data[key];
    await UiStorageApi.saveUiStorageData(this.data);
  };

  async getState(): Promise<Nullable<UiStorageState>> {
    if (!this.data) {
      this.data = await UiStorageApi.fetchUiStorageData();
    }
    return this.data;
  }

  async setState(state: Nullable<UiStorageState>): Promise<void> {
    try {
      this.data = state;
      await UiStorageApi.saveUiStorageData(this.data);
    } catch (e) {
      console.error(e);
    }
  }
}

// Można wyrzucić po jakimś czasie
// to powinno przepisac stan z localStorage do uiStorage
class UiStorageServiceProxy extends UiStorageService {
  async getItem<T>(key: string): Promise<Nullable<T>> {
    const value = await super.getItem<Nullable<T>>(key);
    if (isNil(value)) {
      const localStorageValue: Nullable<T> = UserLocalStorage.getItem(key) as Nullable<T>;
      if (localStorageValue) {
        await super.setItem(key, localStorageValue);
        return localStorageValue;
      }
    }
    return value;
  };

  async setItem<T>(key: string, value: Nullable<T>): Promise<void> {
    await super.setItem(key, value);
  };

  async removeItem(key: string): Promise<void> {
    await super.removeItem(key);
  };
}

const UiStorage = new UiStorageServiceProxy();

export default UiStorage;
