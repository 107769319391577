import {RouteConfig} from 'vue-router';
import { Routes } from '@/router/Routes';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
const Filemanager = () => import(/* webpackChunkName: "Filemanager" */ '@/modules/filemanager/views/Filemanager.vue');

const FilemanagerRoutes: Array<RouteConfig> = [
  {
    path: 'filemanager/:path?',
    name: Routes.FILEMANAGER.FILEMANAGER,
    component: Filemanager,
    meta: {
      title: I18NGetter().KnowledgeBaseSection.knowledge_repository,
      icon: 'mdi-file-multiple',
      mode: 'crm',
    },
  },
];

export default FilemanagerRoutes;
