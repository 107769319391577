<template>
  <div>
  <template v-if="bankSecrecyAccepted">
    <v-row>
      <v-col align="center">
        <img src="@/assets/img/zgoda-udzielona.svg" class="py-2" alt="success"/>
      </v-col>
    </v-row>
    <h1 class="main-header">Dziękujemy za udzielenie zgody na ujawnienie danych objętych tajemnicą bankową.</h1>
    <p class="paragraph py-2">Zgodę możesz cofnąć w dowolnym momencie, klikając w link zamieszczony w mailu, który otrzymasz po wyrażeniu zgody.</p>
  </template>
  <template v-else>
    <v-row>
      <v-col align="center">
        <img src="@/assets/img/zgoda-udzielona.svg" class="py-2" alt="success"/>
      </v-col>
    </v-row>
    <h1  class="main-header pb-4" :class="{'text-body-1':$vuetify.breakpoint.smAndDown}">Upoważniam Lendi Sp. z o.o. do przekazania podmiotowi <span v-if="clientBankSecrecyInfo" class="blue--text">{{clientBankSecrecyInfo.partner.name}}</span> informacji o udzieleniu kredytu, w tym kwocie, dacie uruchomienia oraz banku kredytującym.</h1>
    <v-row>
      <v-col align="center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-circular>
        <fp-btn v-else class="blue--text py-4"  @click="$emit('acceptBankSecrecy')">Akceptuję</fp-btn>
      </v-col>
    </v-row>
    <p class="error--text pt-2 text-center">Jeżeli nie wyrażasz zgody, zamknij stronę.</p>
    <p class="text-center">Zgodę możesz cofnąć w dowolnym momencie, klikając w link zamieszczony w mailu, który otrzymasz po wyrażeniu zgody.</p>
  </template>
  </div>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ClientBankSecrecyDetails} from '@/models/Client';

@Component
export default class AcceptBankSecrecy extends Vue {
  @Prop({required: true,}) public readonly clientBankSecrecyInfo!: ClientBankSecrecyDetails;
  @Prop({required: true,}) public readonly bankSecrecyAccepted!: boolean;
  @Prop({required: true,}) public readonly loading!: boolean;
}
</script>
<style scoped lang="scss">
.main-header {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.paragraph {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  align-self: end;
  letter-spacing: normal;
  text-align: center;
}
</style>
