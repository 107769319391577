<template>
  <tr class="show-hidden-rows">
    <td colspan="9">
      <span v-if="print">...</span>
      <fp-btn v-else text class="body-2 font-weight-medium"
              @click="store.showNextHiddenRows(item.currentMonth)">
        {{ $i18n.useScheduleTable.SHOW_ROWS }}
      </fp-btn>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import {useScheduleStore} from '@/components/calculator/services/ScheduleStore';
import {ScheduleRow} from '@/components/calculator/schedule/Schedule';

const props = defineProps<{
  item: ScheduleRow;
  print: boolean;
}>();

const store = useScheduleStore();
</script>
