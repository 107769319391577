export const ActivityTimeRangeDictionary: Array<{ name_pl: string, value: number }> = [
  {
    value: 30,
    name_pl: '30 min',
  },
  {
    value: 60,
    name_pl: '1 h',
  },
  {
    value: 90,
    name_pl: '1 h 30 min',
  },
  {
    value: 120,
    name_pl: '2 h',
  },
];
