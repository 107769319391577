<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <fp-date-picker-month
            :required="true"
            v-model="borrower.birthDate"
            :label="I18NGetter().useSimulationFormStep2.DATE_OF_BIRTH"
            @input="setMaxLoanPeriodBasedOnBirthday"
            :max="dateBefore18Years"
        />
      </v-flex>
      <fp-radio-group
        row
        class="mb-2"
        :label="I18NGetter().useSimulationFormStep2.GENDER"
        v-model="borrower.gender"
        :items="MultiformDictionaries.genderDictionary"
      ></fp-radio-group>
      <fp-select :label="I18NGetter().useSimulationFormStep2.CIVIL_STATUS"
                 xs12
                 v-model="borrower.civilStatus"
                 required
                 :items="MultiformDictionaries.ffCivilStatusDictionary"
                 :clearable="false"
      />
      <fp-select :label="I18NGetter().useSimulationFormStep2.NATIONALITY"
                 xs12
                 v-model="borrower.origin"
                 required
                 :items="MultiformDictionaries.countriesDictionary"
                 item-key="type"
                 item-name="nationality"
                 :clearable="false"
      />
      <fp-radio-group
        row
        :label="I18NGetter().useSimulationFormStep2.RESIDENT_OPTIONS"
        v-model="borrower.resident"
        :items="residentOptions"
      ></fp-radio-group>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep2.PROFESSIONS_HEADER }}</p>
    <v-layout wrap>
      <v-flex xs12 py-5>
        <v-layout wrap>
          <v-autocomplete
            v-model="borrower.professionId"
            :items="availableProfessions"
            class="px-4"
            item-text="name_pl"
            item-value="type"
            outlined
            xs12
          />
        </v-layout>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep2.BORROWER_EMPLOYMENT_FORM }}</p>
    <simulation-form-step2-income-source :income="borrower.mainIncome" />
    <v-divider class="my-2"></v-divider>
    <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep2.ADDITIONAL_FORM_OF_INCOME_BOOLEAN }}</p>
    <v-layout>
      <fp-radio-group
        row
        v-model="borrower.hasAdditionalIncomeSource"
        :items="hasAdditionalIncomeSourceOptions"
      ></fp-radio-group>
    </v-layout>
    <v-expand-transition>
      <div v-show="borrower.hasAdditionalIncomeSource">
        <v-divider class="my-2"></v-divider>
        <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep2.ADDITIONAL_FORM_OF_INCOME }}</p>
        <simulation-form-step2-income-source :income="borrower.additionalIncome" />
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import FpDatePickerMonth from '@/components/inputs/FpDatePickerMonth.vue';
import MultiformDictionaries from '@/modules/multiForm/shared/MultiformDictionaries';
import {FFBorrower} from '@/models/ExpertState';
import SimulationFormStep2IncomeSource from '@/components/calculator/form/SimulationFormStep2IncomeSource.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import EnvironmentService from '@/env/EnvironmentService';
import {Gender} from '@/models/enums/Gender';
import {useInstance} from '@/composables/useInstance';
import {IMultiformDictionaryElement } from '@/modules/multiForm/shared/IMultiformDictionary';
import {Profession} from '@/modules/multiForm/shared/Types';

type ProffesionsListType = Array<IMultiformDictionaryElement<Profession> | {header: string} | {divider: boolean}>;

const store = useSimulationStore();
const props = withDefaults(defineProps<{
  borrower: FFBorrower;
}>(), {});

const { $snackbarService, } = useInstance();

const residentOptions = [
  { type: true, name_pl: I18NGetter().useSimulationFormStep2.RESIDENT, },
  { type: false, name_pl: I18NGetter().useSimulationFormStep2.NON_RESIDENT, },
] as const;

const hasAdditionalIncomeSourceOptions = [
  { type: true, name_pl: I18NGetter().useSimulationFormStep2.HAS_INCOME, },
  { type: false, name_pl: I18NGetter().useSimulationFormStep2.HAS_NOT_INCOME, },
] as const;

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const dateBefore18Years = `${currentYear - 18}-${currentMonth}`;

const availableProfessions = computed<ProffesionsListType>(() => {
  return [
    {header: I18NGetter().useSimulationFormStep2.TRUSTED_PROFESSIONS,},
    {divider: true,},
    ...MultiformDictionaries.trustedProfessions,
    {header: I18NGetter().useSimulationFormStep2.FINANCE_PROFESSIONS,},
    {divider: true,},
    ...MultiformDictionaries.financeProfessions,
    {header: I18NGetter().useSimulationFormStep2.NATIONAL_ADMINISTRATION_PROFESSIONS,},
    {divider: true,},
    ...MultiformDictionaries.administrationProfessions,
    {header: I18NGetter().useSimulationFormStep2.OTHER_PROFESSIONS,},
    {divider: true,},
    ...MultiformDictionaries.otherProfessions,
  ];
});

const setMaxLoanPeriodBasedOnBirthday = () => {
  if (EnvironmentService.Environment.isAppDomain().NESTO_RO) {
    if (props!.borrower!.birthDate.length === 7) {
      const birthYear: number = parseInt(props!.borrower!.birthDate.slice(3));
      const borrowersAge: number = currentYear - birthYear;
      const retirementAge: number =
        EnvironmentService.Environment.getAppDomainConfig().retirementAge[Gender.MALE];
      const maxLoanPeriod: number = (retirementAge - borrowersAge);
      if (borrowersAge > retirementAge) {
        $snackbarService.openWarningSnackbar(I18NGetter().useSimulationFormStep2.IS_RETIRED);
      } else if (store.userInput!.loanPeriod! > maxLoanPeriod) {
        store.userInput!.loanPeriod = maxLoanPeriod;
        store.userInput!.loanPeriodInMonths = maxLoanPeriod * 12;
        $snackbarService.openWarningSnackbar(I18NGetter().useSimulationFormStep2.SET_MAX_LOAN_PERIOD);
      }
    }
  }
};
</script>
