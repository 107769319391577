import { RouteConfig } from 'vue-router';
import { Routes } from '@/router/Routes';
const TestsPanel = () => import(/* webpackChunkName: "TestsPanel" */ '@/modules/adminPanel/views/HumanResourcesPanel.vue');

const HumanResourcesRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.ADMIN.HUMAN_RESOURCES,
    component: TestsPanel,
  },
];

export default HumanResourcesRoutes;
