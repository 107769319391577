import {IMultiformDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';

export enum ProspectStatus {
  NEW = 'new',
  RETURNED_TO_CC = 'returned_to_cc',
  FORWARDED_TO_CC = 'forwarded_to_cc',
  IN_PROGRESS_CC = 'in_progress_cc',
  CALLED = 'called',
  ARRANGED_CALL = 'arranged_call',
  ARRANGED_MEETING = 'arranged_meeting',
  MEETING_HELD = 'meeting_held',
  SUBMITTED_APPLICATION = 'submitted_application',
  POSITIVE_DECISION = 'positive_decision',
  SIGNED_AGREEMENT = 'signed_agreement',
  STARTED = 'started',
  CLOSED = 'closed',
  ABLE_TO_START_PROCESS = 'able_to_start_process',
  COLLECTING_DOCUMENTS = 'collecting_documents',
  APPLICATION_CREATED = 'application_created',
  SIMULATION_PERFORMED = 'simulation_performed',
  BROKER_AGREEMENT = 'broker_agreement',
  REAL_ESTATE_PRESENTATION = 'real_estate_presentation',
  COMPLETED_SALE = 'completed_sale',
}

export const prospectStatusDictionary: IMultiformDictionary<ProspectStatus> = [
  {name_pl: 'Nowy', type: ProspectStatus.NEW, },
  {name_pl: 'Przekazano do CC', type: ProspectStatus.FORWARDED_TO_CC, },
  {name_pl: 'Nawiązano kontakt', type: ProspectStatus.CALLED, },
  {name_pl: 'Zaplanowano spotkanie telefoniczne', type: ProspectStatus.ARRANGED_CALL, },
  {name_pl: 'Zaplanowano spotkanie', type: ProspectStatus.ARRANGED_MEETING, },
  {name_pl: 'Zamknięty', type: ProspectStatus.CLOSED, },
  {name_pl: 'Przeprowadzono spotkanie', type: ProspectStatus.MEETING_HELD, },
  {name_pl: 'Wysłano wniosek', type: ProspectStatus.SUBMITTED_APPLICATION, },
  {name_pl: 'Decyzja pozytywna', type: ProspectStatus.POSITIVE_DECISION, },
  {name_pl: 'Podpisano umowę', type: ProspectStatus.SIGNED_AGREEMENT, },
  {name_pl: 'Przydzielony', type: ProspectStatus.STARTED, },
  {name_pl: 'Kwalifikuje się do procesu', type: ProspectStatus.ABLE_TO_START_PROCESS, },
  {name_pl: 'Zbieranie dokumentów', type: ProspectStatus.COLLECTING_DOCUMENTS, },
  {name_pl: 'Utworzony wniosek', type: ProspectStatus.APPLICATION_CREATED, },
  {name_pl: 'Przeprowadzona symulacja', type: ProspectStatus.SIMULATION_PERFORMED, },
  {name_pl: 'Nieruchomość zaprezentowana', type: ProspectStatus.REAL_ESTATE_PRESENTATION, },
  {name_pl: 'Sprzedaż zakończona', type: ProspectStatus.COMPLETED_SALE, },
  {name_pl: 'Umowa pośrednictwa', type: ProspectStatus.BROKER_AGREEMENT, },
  {name_pl: 'Zwrócono do CC', type: ProspectStatus.RETURNED_TO_CC, },
  {name_pl: 'W trakcie kontaktu CC', type: ProspectStatus.IN_PROGRESS_CC, },
];
