import { RouteConfig } from 'vue-router';
import { Routes } from '@/router/Routes';
const UsersManagementPanel = () => import(/* webpackChunkName: "AdminUserManagement" */ '@/modules/adminPanel/views/UsersManagementPanel.vue');
const UserDetails = () => import(/* webpackChunkName: "AdminUserDetails" */ '@/modules/adminPanel/views/UserDetails.vue');
const AdminUserPanelRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.ADMIN.USERS_MANAGMENT,
    component: UsersManagementPanel,
  },
  {
    path: ':id',
    name: Routes.ADMIN.USERS_MANAGMENT_DETAILS,
    component: UserDetails,
  },
];

export default AdminUserPanelRoutes;
