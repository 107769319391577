<script lang="ts">

import {defineComponent, getCurrentInstance, PropType} from 'vue';
import UserApi from '@/modules/user/services/UserApi';
import {FetchData, useLendiAutocomplete} from '@/components/inputs/autocompletes/UseLendiAutocomplete';
import {UserFilter} from '@/models/Filter';
import {VAutocomplete} from 'vuetify/lib/components';
import {Intersect} from 'vuetify/lib/directives';
import {AutocompleteInputModel} from '@/components/inputs/autocompletes/AutocompleteInputModel';
import {IPerson} from '@/models/interfaces/IPerson';
import {UserListElement} from '@/models/ppl/UserListElement';
import GoToService from '@/router/GoToService';

export default defineComponent({
  // @ts-ignore
  components: {
    VAutocomplete,
  },
  directives: {
    Intersect,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: 'Wyszukaj ekspertów',
      required: false,
    },
    value: {
      type: [Number, Array,] as PropType<AutocompleteInputModel>,
      default: 0,
      required: true,
    },
    existingItems: {
      type: Array as PropType<IPerson[]>,
      default: () => [],
      required: false,
    },
    filter: {
      type: Object as PropType<UserFilter>,
      default: null,
      required: false,
    },
    clearAfterSelect: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      default: 'displayNameWithOrganisation',
      required: false,
    },
    onChipClick: {
      type: Function,
      default: (item: UserListElement) => { GoToService.user(item.id); },
    },
    returnObject: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const fetchItems: FetchData<UserListElement> = async(search: string) => {
      const isSearchAnId:boolean = !isNaN(parseInt(search));
      try {
        const userFilter: UserFilter = new UserFilter({
          ...props.filter,
          id: isSearchAnId ? parseInt(search) : undefined,
          name: isSearchAnId ? undefined : search,
          page: page.value,
        });
        const response = await UserApi.filterUsers(userFilter);
        return response.items;
      } catch (e) {
        instance?.proxy.errorHandler(e, 'Nie udało się pobrać ekspertów');
        return [];
      }
    };
    const {
      render,
      page,
    } = useLendiAutocomplete<IPerson>(props, context, fetchItems);

    return () => render({
      itemText: props.itemText,
      label: props.label,
      prependIcon: props.hideIcon
        ? undefined
        : '',
      returnObject: props.returnObject,
    });
  },
});

</script>

<style lang="scss" scoped>
  ::v-deep .v-input__append-inner {
    cursor: pointer;
  }
  .l-autocomplete {
    .v-chip {
      white-space: normal;
      height: auto;
    }
  }
</style>
