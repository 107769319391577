export enum SettledDocumentsStatus {
  UPLOADED = 'documentUploaded',
  REQUIRED = 'documentRequired',
  NOT_REQUIRED = 'documentNotRequired'
}

export enum MortgageSettledDocumentsLabels {
  AGREEMENT = 'agreement',
  DECISION = 'decision',
  INFORMATION_FORM = 'informationForm',
  SUBSEQUENT_INFORMATION_FORMS = 'subsequentInformationForms',
}
export enum InsuranceSettledDocumentsLabels {
  APK = 'apk',
  IDD = 'idd',
}

export type SettledDocumentsLabels = MortgageSettledDocumentsLabels | InsuranceSettledDocumentsLabels;

export enum ReportDocumentIds {
  INFORMATION_FORM = 6,
  DECISION = 8,
  AGREEMENT = 9,
  APK = 15,
  IDD = 16,
  SUBSEQUENT_INFORMATION_FORMS = 17,
}

const ReportDocumentDictionary: {categoryId: ReportDocumentIds, key: SettledDocumentsLabels}[] = [
  {
    categoryId: ReportDocumentIds.INFORMATION_FORM,
    key: MortgageSettledDocumentsLabels.INFORMATION_FORM,
  },
  {
    categoryId: ReportDocumentIds.DECISION,
    key: MortgageSettledDocumentsLabels.DECISION,
  },
  {
    categoryId: ReportDocumentIds.AGREEMENT,
    key: MortgageSettledDocumentsLabels.AGREEMENT,
  },
  {
    categoryId: ReportDocumentIds.APK,
    key: InsuranceSettledDocumentsLabels.APK,
  },
  {
    categoryId: ReportDocumentIds.IDD,
    key: InsuranceSettledDocumentsLabels.IDD,
  },
  {
    categoryId: ReportDocumentIds.SUBSEQUENT_INFORMATION_FORMS,
    key: MortgageSettledDocumentsLabels.SUBSEQUENT_INFORMATION_FORMS,
  },
];

export default ReportDocumentDictionary;
