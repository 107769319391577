import {getStoreAccessors, GetterHandler, MutationHandlerNoPayload, MutationHandlerWithPayload} from 'vuex-typescript';
import store, {RootState} from '@/store/store';

export default abstract class BaseStoreService<T> {
  protected mutations!: Record<string, (state: T) => void> | Record<string, (state: T, payload: any) => void>;
  protected storeApi = getStoreAccessors<T, RootState>('');

  protected read<TResult>(handler: GetterHandler<T, RootState, TResult>): TResult {
    return this.storeApi.read<TResult>(handler)(store);
  }

  protected mutate(handler: MutationHandlerNoPayload<T>) {
    this.storeApi.commit(handler)(store);
  }

  public commit<TPayload>(handler: (MutationHandlerWithPayload<T, TPayload>), payload: TPayload) {
    this.storeApi.commit(handler)(store, payload);
  }
}
