import {ProductType} from '@/commons/enums/ProductType';
import {CashSimulationDemand, MortgageSimulationDemand, SimulationDemand} from '@/models/Demand';
import {cloneDeep} from 'lodash-es';
import EnvironmentService from '@/env/EnvironmentService';
const useInitialDemands: () => Partial<Record<ProductType, SimulationDemand>> = () => ({
  [ProductType.CASH]: cloneDeep(new CashSimulationDemand({
    loanAmount: 40_000,
  })),
  [ProductType.MORTGAGE]: cloneDeep(new MortgageSimulationDemand({
    loanAmount: 400_000,
    hypothecValue: 500_000,
    loanPeriod: EnvironmentService.Environment.isBackendSimV2 ? 360 : 30,
  })),
});
export default useInitialDemands;
