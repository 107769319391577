import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {DomainAgreementsWithUrl} from '@/env/Agreements/GPDR/types';
import {AppDomain} from '@/env/enums/Domains';

export const GPDR_AGREEMENTS_LENDI: DomainAgreementsWithUrl<AppDomain.LENDI_PL> = {
  [SystemLanguage.PL]: {
    title: 'Szczegóły o ochronie danych osobowych',
    url: 'https://lendi.pl/regulations/lendi-ochrona-danych.pdf',
  },
  [SystemLanguage.EN]: {
    title: 'Details of the protection of personal data',
    url: 'https://lendi.pl/regulations/lendi-ochrona-danych.pdf',
  },
  [SystemLanguage.RO]: {
    title: 'Detalii privind protecția datelor personale',
    url: 'https://lendi.pl/regulations/lendi-ochrona-danych.pdf',
  },
  [SystemLanguage.UK]: {
    title: 'Деталі захисту персональних даних',
    url: 'https://lendi.pl/regulations/lendi-ochrona-danych.pdf',
  },
  [SystemLanguage.DE]: {
    title: 'Einzelheiten zum Schutz personenbezogener Daten',
    url: 'https://lendi.pl/regulations/lendi-ochrona-danych.pdf',
  },
};
