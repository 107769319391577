<template>
  <v-tour :name="tourName" :steps="steps" :options="OPTIONS" >
    <template slot-scope="tour">
        <onboarding-step
          :tour="tour"
          :tour-name="tourName"
          class="mt-2"
          slot="content" />
    </template>
  </v-tour>
</template>
<script lang="ts" setup>
import OnboardingStep from '@/modules/onboarding/OnboardingStep.vue';
import {TourName} from '@/modules/onboarding/tour/models/TourOptions';
import {TourStep} from '@/modules/onboarding/tour/models/TourStep';
import {onKeyStroke} from '@vueuse/core';
import {computed, getCurrentInstance} from 'vue';
import {Tour} from '@/modules/onboarding/tour/Tour';

const props = defineProps<{
  tourName: TourName,
  steps: TourStep[],
}>();

const OPTIONS = {
  // disable the default keyboard navigation
  useKeyboardNavigation: false,
};

const instance = getCurrentInstance();
const onboarding = computed<Tour>(() => instance!.proxy.$onboarding.tours[props.tourName]);

onKeyStroke('ArrowLeft', () => {
  onboarding.value.previousStep();
});

onKeyStroke('ArrowRight', () => {
  onboarding.value.nextStep();
});
</script>
<style lang="scss">
:root {
  --tour-colour: #0A73EB33;
  --tour-border-colour: #0A73EB;
}
.v-tour {
  &__target--highlighted {
    z-index: 200;
    position: relative;

    &:after {
      border-radius: 4px;
      min-height: 100%;
      min-width: 100%;
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      animation-name: blink;
      animation-duration: 1s;
      animation-iteration-count: 3;
      border: solid 2px var(--tour-border-colour);
      background-color: var(--tour-colour);
    }
  }
  .v-step {
    min-width: 200px;
    background: transparent;
    padding: 0;
    box-shadow: none;

    &__arrow {
      background: $primary;
      &:before {
        border-radius: 50%;
      }
    }
  }

  @keyframes blink {
    0% {
      background-color: var(--tour-colour);
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: var(--tour-colour);
    }
  }
}

</style>
