import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const RodoNavigation = () => import(/* webpackChunkName: "RODO" */ '@/modules/rodo/views/RodoNavigation.vue');
const StartRodoTest = () => import(/* webpackChunkName: "RODO" */ '@/modules/rodo/views/StartRodoTest.vue');

const RodoRoutes: RouteConfig =
  {
    path: 'rodo_test',
    redirect: '/rodo_test/start',
    component: RodoNavigation,
    children: [
      {
        name: Routes.RODO_TEST.START_RODO_TEST,
        path: 'start',
        component: StartRodoTest,
      },
    ],
  };

export default RodoRoutes;
