<template>
  <v-layout>
    <v-flex>
      <v-autocomplete
        prepend-inner-icon="search"
        :search-input.sync="searchInput"
        @update:search-input="getList()"
        :label="label"
        :items="searchInput ? stateList : addresses"
        return-object
        item-text="description"
        hide-no-data
        outlined
        filled
        dense
        hide-details
        @input="onInput"
        :disabled="disabled">
        <template v-slot:selection></template>
        <template v-slot:item="{ item }">
          <v-list-item-icon>
            <v-icon v-if="item.city">mdi-briefcase-outline</v-icon>
            <v-icon v-else>mdi-google-maps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.description || formatAddress(item) }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import GooglePlacesApi from '@/modules/googleAutocomplete/services/GooglePlacesApi';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import GoogleAutocompletePrediction from '@/modules/googleAutocomplete/GoogleAutocompletePrediction';
import {AutocompletePlacesTypes} from '@/modules/googleAutocomplete/enums/AutocompletePlacesTypes';
import {addressFormat} from '@/filters/StringFilters';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component
export default class AddressAutocomplete extends Vue {
  @Prop({default: () => [], },)
  public readonly addresses!: Address[];

  @Prop({default: I18NGetter().useAddressAutocomplete.SEARCH_ADDRESS, },)
  public readonly label!: string;

  @Prop({default: AutocompletePlacesTypes.CITIES_AND_ROUTES, type: String, },)
  public readonly type!: AutocompletePlacesTypes;

  @Prop({default: false, },)
  public readonly disabled!: boolean;

  public searchInput: string = '';
  public stateList: GoogleAutocompletePrediction[] = [];

  public async getList(): Promise<void> {
    if (this.searchInput && this.searchInput.length > 1) {
      const response = await GooglePlacesApi.getAddressesByInput(this.searchInput, this.type);
      if (response) {
        this.stateList = response;
      }
    }
  }

  formatAddress(address: Address): string {
    return addressFormat(address);
  }

  public async onInput(addressObject: any) {
    if (addressObject.place_id) {
      const response: Address = await GooglePlacesApi.getAddress(addressObject.place_id);
      this.$emit('input', response);
    } else {
      this.$emit('input', addressObject);
      this.searchInput = '';
    }
  }
}

</script>

<style scoped>

</style>
