<template>
  <v-row class="flex-column">
    <navigation-header :title="I18NGetter().PlatformSection.nps"></navigation-header>
    <v-col>
      <v-card class="mx-6">
        <v-card-text>
          <nps-data-table />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import NpsDataTable from '@/modules/nps/views/NPSDataTable.vue';
import NavigationHeader from '@/components/NavigationHeader/NavigationHeader.vue';

</script>

<style scoped lang="scss">
</style>
