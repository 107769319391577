export enum DashboardKey {
  PLATFORM_LOGO = 'PLATFORM_LOGO',
  DASHBOARD = 'DASHBOARD',
  THEME_COLOR = 'THEME_COLOR',
  MENU_SIZE = 'MENU_SIZE',
  OMNISEARCH = 'OMNISEARCH',
  MAIL = 'MAIL',
  CALENDAR = 'CALENDAR',
  NOTIFICATIONS = 'NOTIFICATIONS',
  DEALS_CLIENTS_SETTLEMENTS = 'DEALS_CLIENTS_SETTLEMENTS',
  CALENDAR_PREVIEW = 'CALENDAR_PREVIEW',
  NEWS = 'NEWS',
  FORUM_PREVIEW = 'FORUM_PREVIEW',
  DEALS_IN_PROGRESS = 'DEALS_IN_PROGRESS',
  CHAT = 'CHAT',
  COMPARISON_TOOL = 'COMPARISON_TOOL',
  USERNAME = 'USERNAME',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  BIK = 'BIK',
  DISCOUNTS_BASE = 'DISCOUNTS_BASE',
  DISCOUNTS = 'DISCOUNTS',
  EARN_WITH_LENDI = 'EARN_WITH_LENDI',
  CLIENTS = 'CLIENTS',
  DEALS = 'DEALS',
  LOAN_APPLICATIONS = 'LOAN_APPLICATIONS',
  SETTLEMENTS = 'SETTLEMENTS',
  PARTNERS = 'PARTNERS',
  WIDGETS = 'WIDGETS',
  LENDI_TEAM = 'LENDI_TEAM',
  ACCREDITATION_PANEL = 'ACCREDITATION_PANEL',
  FORUM = 'FORUM',
}
