export enum ProductType {
  MORTGAGE = 'productTypeMortgage',
  CASH = 'productTypeCash',
  SME = 'productTypeSme',
  NONBANK = 'productTypeNonBank',
  REAL_ESTATE = 'productTypeRealEstate',
  INSURANCE = 'productTypeInsurance',
  FACTORING = 'productTypeSmeFactoring',
  LEASING = 'productTypeSmeLeasing',
  INVESTMENT = 'productTypeInvestment'
}
