<template>
  <multiform-list-manager
      v-if="households && households.length"
      :type="FFBorrower"
      :readonly="false"
      :value="households[0].borrowers"
      :itemName="I18NGetter().useSimulationFormStep2And3.BORROWER">
    <template #default="{item}">
      <simulation-form-step2 :borrower="item" />
    </template>
  </multiform-list-manager>
</template>
<script lang="ts" setup>
import {defineComponent} from 'vue';
import {useHouseholds} from '@/components/calculator/form/useSimulationForm';
import MultiformListManager from '@/modules/multiForm/views/MultiformListManager.vue';
import SimulationFormStep2 from '@/components/calculator/form/SimulationFormStep2.vue';
import {FFBorrower} from '@/models/ExpertState';
import {FFHousehold} from '@/models/simulation/Simulation';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const {households,} = useHouseholds();

</script>
<style scoped lang="scss">
</style>
