import {EnvironmentMode} from '@/env/enums/EnvironmentMode';
import {VuetifyThemeVariant} from 'vuetify/types/services/theme';

export type ThemeOption = {
  dark: Partial<VuetifyThemeVariant>,
  light: Partial<VuetifyThemeVariant>
}

const EnvironmentTheme: Record<EnvironmentMode, ThemeOption> = {
  LENDI: {
    light: {
      primary: '#0a73eb',
      secondary: '#172c57',
      info: '#0a73eb',
      warning: '#ffcc1a',
      warningSecondary: '#8f6f00',
      error: '#fa7268',
      accent: '#0a73eb',
      success: '#23cd30',
      fpShadow: '#232d3a',
      anchor: '#2c3e50',
      primaryBg: '#fffFFF',
      secondaryBg: '#f9fbff',
      surfaceColor: '#22C7FA',
      insurance: '#EA0650',
      realEstate: '#CD6523',
      sme: '#912AFA',
      mortgage: '#0A73EB',
      cash: '#23CD30',
    },
    dark: {
      primary: '#0a73eb',
      info: '#0a73eb',
      warning: '#ffcc1a',
      warningSecondary: '#8f6f00',
      error: '#fa7268',
      accent: '#0a73eb',
      success: '#23cd30',
      fpShadow: '#f9fbff',
      anchor: '#f9fbff',
      primaryBg: '#1E1E1E',
      secondaryBg: '#121212',
      surfaceColor: '#22C7FA',
      insurance: '#EA0650',
      realEstate: '#CD6523',
      sme: '#912AFA',
      mortgage: '#0A73EB',
      cash: '#23CD30',
    },
  },
  STANDARD: {
    light: {
      primary: '#1ab394',
      secondary: '#676a6c',
      info: '#23c6c8',
      warning: '#f8ac59',
      error: '#fa1c1c',
      accent: '#00a2e8',
      success: '#23cd30',
      fpShadow: '#232d3a',
      anchor: '#2c3e50',
      primaryBg: '#fff',
      secondaryBg: '#f9fbff',
      surfaceColor: '#22C7FA',
    },
    dark: {
      primary: '#1ab394',
      secondary: '#676a6c',
      info: '#23c6c8',
      warning: '#f8ac59',
      error: '#fa1c1c',
      accent: '#00a2e8',
      success: '#23cd30',
      fpShadow: '#f9fbff',
      anchor: '#f9fbff',
      primaryBg: '#1E1E1E',
      secondaryBg: '#121212',
      surfaceColor: '#22C7FA',
    },
  },
};

export default EnvironmentTheme;
