import {Cost, Offer} from '@/models/simulation/Simulation';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {numeralFormat} from '@/filters/StringFilters';
import {EMPTY_VALUE} from '@/components/calculator/results/tableElements/TableElementsConstants';
import EnvironmentService from '@/env/EnvironmentService';

export const renderProvision = (offer: Offer): string => {
  const isProvisionCredited: boolean = offer.creditedCosts.includes(Cost.PROVISION);
  if (offer.totals.provision || isProvisionCredited) {
    return isProvisionCredited
      ? `${I18NGetter().useMortgageTableElements.CREDITED_INITIAL_COSTS} / ${numeralFormat(offer.creditedInitialCosts?.provision.cost)} ${EnvironmentService.Environment.currentCurrency()}`
      : `${offer.initialCosts.provision.cost} ${EnvironmentService.Environment.currentCurrency()}`;
  }
  return EMPTY_VALUE;
};
