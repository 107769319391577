import {IClientScan} from '@/models/clientScans/IClientScan';
import {ClientScanKey} from '@/models/clientScans/ClientScanKey';
import {ScanType} from '@/models/clientScans/ScanType';
import {ClientAgreements} from '@/modules/client/views/client-agreements/Agreements';

export const ClientScansDtoNesto: IClientScan[] = [
  {
    key: ClientScanKey.AGREEMENT,
    documentName: 'Formular de consimtamant pentru procesarea datelor cu caracter personal de catre Nesto',
    type: ScanType.RODO,
    condition: c => !c.isCompany,
    documentUrl: 'https://finance.imobiliare.ro/gdpr.pdf',
    required: (v: ClientAgreements) => v.acceptsProcessing,
  },
];
