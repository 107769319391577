<template>
  <v-row no-gutters align="center" v-on="$listeners">
    <v-col class="shrink" :order="right ? 3 : 0">
      <v-hover v-slot="{ hover }">
        <v-avatar

            class="p-relative" :size="customSize || (small ? '36px' : '44px')"
            :class="{'ml-3': right, 'mr-3': !right}">
          <v-skeleton-loader type="avatar" v-if="loading"/>
          <slot v-else name="avatar" :hover="hover"/>
        </v-avatar>
      </v-hover>
    </v-col>
    <v-col :order="1" class="text-truncate">
      <v-row
        no-gutters
        :justify="right ? 'end' : undefined">
        <v-col v-if="loading">
          <v-skeleton-loader class="fill-width mt-2" type="text"/>
        </v-col>
        <v-col v-else class="d-flex align-center shrink font-weight-medium">
          <v-layout no-gutters v-if="label" :class="{reversed: right}">
            <v-flex class="text-truncate shrink align-center d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <a
                    v-if="clickableLink"
                    v-on="hideTooltip !== false ? {} : on"
                    @click="onLinkClick()"
                    class="font-weight-medium text-truncate"
                    :id="$cy.baseBadgeLink">
                    {{label}}
                    <v-icon v-if="!small">mdi-chevron-right</v-icon>
                  </a>
                  <span v-else v-on="hideTooltip !== false ? {} : on" class="font-weight-medium primary-text-color text-truncate">
                    {{label}}
                  </span>
                </template>
                <span class="font-weight-medium">{{label}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex :class="[right ? 'order-0' : 'order-2', 'shrink']" v-if="!hideActions && $scopedSlots.actions">
              <slot name="actions"/>
            </v-flex>
          </v-layout>
          <slot v-else :class="{reversed: right}" name="first-line" />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!small && $slots['second-line']">
        <v-col class="text--secondary body-2" :class="[right ? '' : 'd-flex',]">
          <v-skeleton-loader class="fill-width" type="text" v-if="loading"/>
          <slot v-else name="second-line"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shrink" v-if="$slots['suffix']" :order="2">
      <slot name="suffix"/>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

export class BaseBadgeClass extends Vue {
  @Prop({default: false, }) public readonly right!: boolean;
  @Prop({default: false, }) public readonly small!: boolean;
  @Prop({default: false, }) public readonly readonly!: boolean;
  @Prop({default: false, }) public readonly hideTooltip!: boolean;
  @Prop({default: false, }) public readonly loading!: boolean;
  @Prop({default: false, }) public readonly hideActions!: boolean;
  @Prop({default: true, }) public readonly lazy!: boolean;
  @Prop() public readonly customSize!: number;
  @Prop() public readonly label!: string;
  @Prop() public readonly to!: string;
  @Prop() public readonly value!: any;
}

@Component
export default class BaseBadge extends BaseBadgeClass {
  get clickableLink(): boolean {
    return !this.readonly;
  }

  onLinkClick(): void {
    if (this.$listeners['click:label']) {
      this.$emit('click:label');
    } else if (!this.readonly) {
      this.$router.push(this.to);
    }
  }
}
</script>

<style scoped lang="scss">
  .reversed {
    flex-direction: row-reverse;
  }

  ::v-deep a {
    text-decoration: none!important;
  }
</style>
