import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const BhpNavigation = () => import(/* webpackChunkName: "BHP" */ '@/modules/bhp/views/BhpNavigation.vue');
const StartBhpTest = () => import(/* webpackChunkName: "BHP" */ '@/modules/bhp/views/StartBhpTest.vue');

const BhpRoutes: RouteConfig =
  {
    path: 'bhp_test',
    redirect: '/bhp_test/start',
    component: BhpNavigation,
    children: [
      {
        name: Routes.BHP_TEST.START_BHP_TEST,
        path: 'start',
        component: StartBhpTest,
      },
    ],
  };

export default BhpRoutes;
