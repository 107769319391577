import {RouteConfig} from 'vue-router';
import { Routes } from '@/router/Routes';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
const ProceduresList = () => import(/* webpackChunkName: "Procedures" */'@/modules/procedures/views/procedures-list/ProceduresList.vue');
const ProceduresDetails = () => import(/* webpackChunkName: "Procedures" */ '@/modules/procedures/views/procedures-details/ProceduresDetails.vue');

const ProceduresRoutes: Array<RouteConfig> = [
  {
    path: 'procedures',
    name: Routes.PROCEDURES.PROCEDURES_LIST,
    component: ProceduresList,
    meta: {
      title: I18NGetter().KnowledgeBaseSection.procedures,
      icon: 'mdi-timeline-text',
    },
  },
  {
    path: 'procedures/:id',
    name: Routes.PROCEDURES.PROCEDURES_DETAILS,
    component: ProceduresDetails,
  },
];

export default ProceduresRoutes;
