<template>
  <div>
    <v-chip
      v-if="isScrollToStartVisible && !isScrollToStartClosedManually"
      class="scroll-to-start"
      color="blue-grey darken-4"
      text-color="white"
      close
      @click="scrollToStart()"
      @click:close="closeScrollToStart()"
      close-icon="mdi-close"
      label
    >
      <v-icon>mdi-undo-variant</v-icon>
      <span class="font-weight-medium">{{I18NGetter().useHorizontalNavigation.BACK_TO_BEGINNING}}</span>
    </v-chip>
    <v-chip
      class="navigation-arrows"
      color="blue-grey darken-4"
      text-color="white"
      label
    >
      <v-btn icon
             @click="scrollLeft(SCROLL_DISTANCE)"
             @mousedown="startAutoScroll(scrollLeft, SCROLL_DISTANCE)"
             @mouseup="stopAutoScroll()"
      >
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn icon
             @click="scrollRight(SCROLL_DISTANCE)"
             @mousedown="startAutoScroll(scrollRight, SCROLL_DISTANCE)"
             @mouseup="stopAutoScroll()"
      >
        <v-icon color="white">mdi-arrow-right</v-icon>
      </v-btn>
    </v-chip>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {useHorizontalOffersScroll} from '@/components/calculator/results/useHorizontalOffersScroll';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
export default defineComponent({
  setup() {
    return {
      ...useHorizontalOffersScroll('.simulation-offer-listing'),
      I18NGetter,
    };
  },
});

</script>
<style scoped lang="scss">
.scroll-to-start {
  width: 180px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  bottom: 70px;
  .v-icon {
    font-size: 18px;
  }
  ::v-deep {
    .v-chip__content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    button {
      margin: 0 !important;
    }
  }
}
.navigation-arrows {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  bottom: 30px;

  ::v-deep.v-icon {
    font-size: 24px;
  }
}
</style>
