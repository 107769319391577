import { AxiosError } from 'axios';
import EnvironmentService from '@/env/EnvironmentService';
import { IIntegrable } from '@/models/interfaces/IIntegrable';
import * as Sentry from '@sentry/browser';
import { ILogger, LoggerType } from '@/models/interfaces/ILogger';
import AuthService from '@/modules/user/AuthService';
import JsonUtils from '@/services/utils/JsonUtils';
import {Severity, SeverityLevel} from '@sentry/browser';

const SENTRY_DSN: string = process.env.VUE_APP_SENTRY_DSN!;

const ignoreErrors: (string | RegExp)[] = [
  "Cannot read property 'socket' of null",
  'ResizeObserver loop limit exceeded',
  "TypeError: Cannot read property 'socket' of null",
  'WebSocket.t.websocket.socket.onerror(widget)',
  'Occurred websocket error',
  'Request failed with status code 502',
  'Błędna nazwa użytkownika lub email',
  'Podano błędne dotychczasowe hasło',
  'invalid_sms_code',
  'invalid_nip',
  /got '<'/,
  /auth./,
  /Unexpected token '<'/,
  /Navigation cancelled/,
  /Navigation duplicated/,
  /Request failed with status code 401/,
  /ChunkLoadError/,
  /Loading chunk/,
  /Error: Network Error/,
  /Zajęty termin/,
  /Niepoprawny kod SMS/,
  /trumbowyg.colors.min/,
  /The setup binding property /,
];

class Logger implements ILogger, IIntegrable {
  private isSentryOn: boolean = EnvironmentService.Environment.isSentryOn;

  public connect() {
    console.warn('Initialize sentry ', this.isSentryOn);
    if (this.isSentryOn) {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: process.env.VUE_APP_ENV!,
        integrations: [],
        blacklistUrls: [
          /lendi\.user\.com\/widget\.js/i,
        ],
        ignoreErrors,
        normalizeDepth: 0,
        release: process.env.VUE_APP_COMMIT_HASH,
      });
    }
  }

  private sendEvent(logLevel: Severity, message?: string, data?: object, tags?: Record<string, string>) {
    if (this.isSentryOn) {
      Sentry.withScope(scope => {
        if (data) {
          scope.setExtra('data', data);
        }
        if (AuthService.User) {
          scope.setUser({
            id: String(AuthService.User.id),
            username: AuthService.User.username,
            email: AuthService.User.email!,
          });
        }
        if (tags) {
          scope.setTags(tags);
        }
        if (localStorage.getItem('authToken')) {
          scope.setExtra('jwt', localStorage.getItem('authToken'));
        }
        if (process.env.VUE_APP_COMMIT_HASH) {
          scope.setExtra('app_commit_hash', process.env.VUE_APP_COMMIT_HASH);
        }
        Sentry.captureMessage(message!, logLevel);
      });
    }
  }

  public info(message?: string, data?: object) {
    this.sendEvent(Severity.Info, message, data);
  }

  public warning(message?: string, data?: object) {
    this.sendEvent(Severity.Warning, message, data);
  }

  public error(message?: string, data?: object) {
    this.sendEvent(Severity.Error, message, data);
  }

  public endpointErrorHandler(axiosError: AxiosError) {
    const status = axiosError.response?.status;
    const requestHeaders = axiosError.response?.config.headers;
    const responseBody = axiosError.response?.data;
    const responseHeaders = axiosError.response?.headers;
    const url = axiosError.response?.config.url;
    const params = axiosError.response?.config.params;
    const requestBody = this.prepareBody(axiosError.response?.config.data);

    const data = {
      request: {
        body: requestBody,
        headers: requestHeaders,
      },
      response: {
        body: responseBody,
        headers: responseHeaders,
      },
      url: url,
      parameters: params,
      username: undefined,
    };
    const code = responseBody?.code;
    const key = responseBody?.key;
    const tags = {status: String(status), api_code: code, api_key: key, };
    switch (status) {
    case 400:
      let apiMessage = (responseBody?.message) ? 'api: ' + responseBody?.message : '';
      if (this.shouldLogUsername(responseBody)) {
        data.username = requestBody?.username;
        apiMessage += ' username: ' + requestBody?.username;
      }
      this.sendEvent(Severity.Error, `${url} status: ${status} ${apiMessage}`, data, tags);
      break;
    case 401:
      break;
    case 402:
      break;
    case 403:
      break;
    case 404:
      break;
    case 502:
      break;
    default:
      this.sendEvent(Severity.Error, `${url} status: ${status}`, data, tags);
      break;
    }
  }

  private prepareBody(b: any) {
    try {
      if (b instanceof FormData) {
        return JsonUtils.prepareFormDataToJson(b);
      } else {
        return JSON.parse(b);
      }
    } catch (e) {
      return b;
    }
  }

  private shouldLogUsername(responseBody: any) {
    const code = responseBody?.code;
    const list = [ApiErrors.BAD_PASSWORD, ApiErrors.USER_ACCOUNT_EXPIRED, ApiErrors.USER_NOT_FOUND,
      ApiErrors.USER_NOT_ACTIVATED, ApiErrors.TOO_MANY_ATTEMPTS, ];
    return !!list.find(err => err?.code === code);
  }
}

export default new Logger();

class ApiErrors {
  static BAD_PASSWORD = {code: 1000, key: 'auth.bad_password', };
  static USER_NOT_FOUND = {code: 1001, key: 'auth.user_not_found', };
  static USER_NOT_ACTIVATED = {code: 1002, key: 'auth.user_not_activated', };
  static USER_ACCOUNT_EXPIRED = {code: 1003, key: 'auth.user_account_expired', };
  static TOO_MANY_ATTEMPTS = {code: 1006, key: 'auth.too_many_attempts', };
}
