import AuthService from '@/modules/user/AuthService';

class UserStorage {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly storage: Storage) { }

  get USER_ID() {
    return AuthService.User?.id! || null;
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  getItem<T extends object | string = any>(key: string): T | null {
    const stringValue: string | null = this.storage.getItem(this.createKey(key));
    try {
      return JSON.parse(stringValue!) as T | null;
    } catch (e) {
      return stringValue as T | null;
    }
  }

  setItem<T extends object | string = object>(key: string, value: T): void {
    if (typeof value === 'object') {
      this.storage.setItem(this.createKey(key), JSON.stringify(value));
    } else if (typeof value === 'string') {
      this.storage.setItem(this.createKey(key), value);
    } else {
      console.error('Wrong value:', value);
    }
  }

  private createKey(key: string) {
    return `${key}_${this.USER_ID}`;
  }
}

export const UserLocalStorage = new UserStorage(localStorage);
export const UserSessionStorage = new UserStorage(sessionStorage);
