import {CompanyOwnerData} from '@/modules/accredition/models/CompanyOwnerData';
import {IdentificationDocument} from '@/modules/accredition/models/interfaces/IdentificationDocument';
import {Addressable} from '@/modules/accredition/models/interfaces/Addressable';
import BaseStoreService from '@/store/BaseStoreService';
import ContactInfoData from '@/modules/accredition/models/ContactInfoData';
import {
  AccreditationState,
  AccreditationStoreModule
} from '@/modules/accredition/services/store/accreditation/AccreditationStoreModule';
import {CompanyData} from '@/models/CompanyData';
import {AccreditationInvitation, InvitationType} from '@/modules/accredition/models/backend/AccreditationInvitation';

class AccreditationStore extends BaseStoreService<AccreditationState> {
  public mutations = AccreditationStoreModule.mutations;

  public setCreatedPersonId(id: number) {
    this.commit(this.mutations.setCreatedPersonId, id);
  }

  public setCreatedCompany(company: CompanyData) {
    this.commit(this.mutations.setCreatedCompany, company);
  }

  public setCompanyId(id: Nullable<number>) {
    this.commit(this.mutations.setCompanyId, id);
  }

  public setAccreditationInvitation(invitation: AccreditationInvitation) {
    this.commit(this.mutations.setAccreditationInvitation, invitation);
  }

  public getCreatedPersonId() {
    return this.read(AccreditationStoreModule.getters.getCreatedPersonId);
  }

  public getCreatedCompanyId() {
    return this.read(AccreditationStoreModule.getters.getCreatedCompanyId);
  }

  public getCreatedCompany() {
    return this.read(AccreditationStoreModule.getters.getCreatedCompany);
  }

  public get accreditationInvitation() {
    return this.read(AccreditationStoreModule.getters.getAccreditationInvitation);
  }

  public get isFutureChiefOfDivision() {
    const accessor = AccreditationStoreModule.getters.getAccreditationInvitation;
    return this.read(accessor)?.invitationType === InvitationType.CHIEF;
  }

  public getCreatedRepresentantId() {
    return this.read(AccreditationStoreModule.getters.getCreatedRepresentantId);
  }

  public getAccreditationInvitation() {
    return this.read(AccreditationStoreModule.getters.getAccreditationInvitation);
  }
}

export default new AccreditationStore();
