<template>
  <v-layout column>
    <navigation-header :title="$i18n.useBranchesList.BRANCHES">
      <template v-slot:right>
        <fp-btn  success @click="openNewBranchModal">{{ $i18n.useBranchesList.ADD_BRANCH }}</fp-btn>
      </template>
    </navigation-header>
    <v-flex xs12 mx-6>
      <fp-loader v-if="!items"/>
      <v-card v-else>
        <v-card-text>
          <fp-data-table
            v-model="selectedBranches"
            :headers="headers"
            :items="items"
            :options="options"
            @update:options="fetchData">
            <template v-slot:top>
              <table-pagination :options="options">
                <table>
                  <tr>
                    <td>
                      <v-row>
                        <v-col>
                          <fp-input
                          class="py-2 px-4"
                          :placeholder="$i18n.useInputCommons.SEARCH"
                          v-model="nameFilter"
                          @input="filterByName"
                          prepend-icon="mdi-magnify"
                        ></fp-input>
                        </v-col>
                        <v-col>
                          <fp-select
                            class="py-2 px-0"
                            :label="$i18n.useBranchesList.BRANCH_TYPE"
                            v-model="branchType"
                            :items="branchTypes"
                            itemName="name_pl"
                            itemKey="type"
                            @input="filterByType"
                          />
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </table>
              </table-pagination>
            </template>
            <template v-slot:item="{item}">
              <tr class="text-left" @click="$goto.branch(item.id)">
                <td class="text-left">
                  {{item.id}}
                </td>
                  <td class="text-left">
                <router-link :to="$goto.branchLocation(item.id)" class="remove-underline">
                  {{item.name}}
                </router-link>
                </td>
                <td class="text-left">
                  {{$i18n.BranchType[item.branchType]}}
                </td>
                <td class="text-left">
                  <fp-date-time-view :date="item.updatedAt"/>
                </td>
                <td class="text-left">
                  {{item.active ? $i18n.useButtonCommons.YES : $i18n.useButtonCommons.NO}}
                </td>
                <td class="text-left">
                  <fp-btn error @click.prevent.stop="deleteBranch(item.id)">
                    <span>{{ $i18n.useButtonCommons.DELETE }}</span>
                  </fp-btn>
                </td>
              </tr>
            </template>
          </fp-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {BranchFilter, FiltersResponse} from '@/models/Filter';
import {debounce} from 'lodash-es';
import MultiformDictionary from '@/modules/multiForm/shared/MultiformDictionaries';
import {DataTableHeader, IDataTableHeader} from '@/services/searchEngine/interfaces/DataTableHeader';
import {FilterType} from '@/services/searchEngine/enums/FilterType';
import BranchListElement, {Branch, BranchTypeModel} from '@/modules/branches/models/Branch';
import BranchApi from '@/modules/branches/services/BranchApi';
import FpDataTable from '@/components/datatables/FpDataTable.vue';
import NavigationHeader from '@/components/NavigationHeader/NavigationHeader.vue';
import ListActions from '@/components/commons/ListActions.vue';
import TablePagination from '@/components/commons/TablePagination.vue';
import AddBranchModal from '@/modules/branches/views/AddBranchModal.vue';
import BranchTypes from '@/modules/branches/services/BranchTypes';
import {BranchType} from '@/modules/branches/enums/BranchType';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component({
  components: {
    FpDataTable,
    NavigationHeader,
    ListActions,
    TablePagination,
  },
}
)
export default class BranchesList extends Vue {
  public options: BranchFilter = new BranchFilter();
  public filteredBranches: FiltersResponse<BranchListElement> = new FiltersResponse();
  public selectedBranches: Array<BranchListElement> = [];
  public branchTypes: BranchTypeModel[] = BranchTypes;
  public branchType: BranchType | null = null;
  public nameFilter: Nullable<string> = null;
  public debouncedAtInput: (fn: () => void) => void = debounce((fn: () => void) => fn(), 500);
  public multiformDictionary = MultiformDictionary;

  mounted() {
    this.options = new BranchFilter();
    this.fetchData();
  }

  public get headers(): Array<DataTableHeader<BranchListElement, BranchFilter>> {
    const headers = this.headersObject;
    return headers.filter(header => header.condition === undefined || Boolean(header.condition));
  }

  public get headersObject(): Array<DataTableHeader<BranchListElement, BranchFilter>> {
    const headers: IDataTableHeader<BranchListElement, BranchFilter>[] = [
      {text: I18NGetter().useBranchesList.ID, valueFilter: 'id', filterType: FilterType.RANGE, valueSort: 'id',},
      {text: I18NGetter().useBranchesList.NAME, valueFilter: 'name', filterType: FilterType.TEXT, valueSort: 'name', },
      {text: I18NGetter().useBranchesList.TYPE, valueFilter: 'branchType', filterType: FilterType.TEXT, valueSort: 'branchType', },
      {text: I18NGetter().useBranchesList.UPDATED_AT, valueFilter: 'updatedAt', filterType: FilterType.DATE, valueSort: 'updatedAt', },
      {text: I18NGetter().useBranchesList.ACTIVITY, },
      {text: I18NGetter().useBranchesList.ACTIONS,},
    ];
    return headers.map(x => new DataTableHeader<BranchListElement, BranchFilter>(x));
  }

  public get items(): Array<BranchListElement> {
    return this.filteredBranches.items;
  }

  public async filterByName() {
    this.debouncedAtInput(() => {
      this.options.name = this.nameFilter;
      this.fetchData(this.options);
    });
  }

  public async filterByType() {
    this.options.branchType = this.branchType;
    await this.fetchData(this.options);
  }

  public async fetchData(options: BranchFilter = new BranchFilter()): Promise<void> {
    Object.assign(this.options, options);
    try {
      this.filteredBranches = await BranchApi.filterBranches(this.options);
      this.options.quantity = this.filteredBranches.quantity;
    } catch (e) {
      console.error(e);
    }
  }

  public async openNewBranchModal() {
    const response: Branch | undefined = await this.$modalService
      .open<Branch>(AddBranchModal, {maxWidth: 600, persistent: true, fullscreen: undefined, });
    if (response) {
      this.$goto.branch(response.id);
    }
  }

  public async deleteBranch(id: number) {
    if (await this.$modalService.openConfirmModal(I18NGetter().useBranchesList.DELETE_BRANCH_OPEN_CONFIRM_MODAL)) {
      try {
        await BranchApi.deleteBranch(id);
        await this.fetchData();
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.remove-underline {
  display: block;
  text-decoration: none;
}
</style>
