import {SimulationDemand} from '@/models/Demand';
import {CountryCode} from 'libphonenumber-js';
import moment from 'moment';
import EnvironmentService from '@/env/EnvironmentService';
import {Gender} from '@/models/enums/Gender';
import {FFCivilStatus, FFIncomeType, Profession, TaxType} from '@/modules/multiForm/shared/Types';
import {Currency} from '@/modules/multiForm/shared/MultiformDictionaries';

export enum FlowTypes {
  CREDITABILITY = 'creditability',
  INSTALLMENT = 'installment',
  PROPOSAL = 'proposal',
}

export interface Liabilities {
  cardsLimit: number,
  installmentsPLN: number,
  alimony: number
}

export interface Household {
  landId: number;
  districtId: number;
  formattedAddress: string;
  numberOfAdults: number;
  liabilities: Liabilities;
  numberOfChildren: number;
  realEstateType: string;
  hasLiabilities: boolean;
}

export interface FFIncome {
  contractType: number | string;
  otherIncomeType: string;
  incomeTypeId: number;
  duration: number;
  remainingDuration: number;
  netIncome: number;
  income?: number;
  currency: Currency;
  threeMonthsAverage?: number;
  sixMonthsAverage?: number;
  twelveMonthsAverage?: number;
  incomeDeliveredOn?: 'cash' | 'bankAccount';
  tax?: TaxType;
  currentYearIncome?: number;
  currentYearCost?: number;
}
const defaultIncomeState: FFIncome = {
  duration: 24,
  incomeTypeId: FFIncomeType.FLEX_TIME_CONTRACT,
  contractType: '',
  otherIncomeType: '',
  remainingDuration: 36,
  netIncome: 0,
  currency: EnvironmentService.Environment.currentCurrency(),
  incomeDeliveredOn: 'bankAccount',
  tax: TaxType.LINEAR_TAX,
};

const DEFAULT_BORROWER_AGE = 27;

export class FFBorrower {
  householdIdx: number = 0;
  mainIncome: Nullable<FFIncome> = {...defaultIncomeState,};
  additionalIncome: Nullable<FFIncome> = {...defaultIncomeState,};
  hasAdditionalIncomeSource: boolean = false;
  birthDate: string = moment().subtract(DEFAULT_BORROWER_AGE, 'year').format('MM-YYYY');
  dateOfBirth: string = '';
  gender: Gender = Gender.MALE;
  origin: CountryCode = EnvironmentService.Environment.getAppDomainConfig().region;
  resident: boolean = true;
  professionId: number = Profession.OTHER;
  civilStatus: FFCivilStatus = FFCivilStatus.SINGLE;
  incomeTypeCategory: FFIncomeType = FFIncomeType.FLEX_TIME_CONTRACT;
  incomeDuration: number = 24;
}

export interface CreditHistory {
  delay: string;
  amount: string;
  exist: boolean;
}

export interface Savings {
  amount: number;
  asLittleAsPossible: string;
}

export interface InstallmentFormAnswers {
  loanPurposeActivity: string;
  loanPurposeWhat: string;
  loanSubjectName: string;
  planToRenovate: string;
  planToFinish: string;
}

export interface SimulationItem {
  info: {
    id: string;
  }
}

export interface Simulation {
  offers: SimulationItem[];
}

export interface Creditability {
  borrowerIdx: number;
  progress: string;
}

export interface Paths {
  creditability: Creditability;
}

export interface UserLocation {
  land: number;
  district: number;
  address: string;
}

export interface EKOUser {
  email?: string;
  firstName?: string;
  phone?: string;
}

export interface Agent {
  lp: number;
  name: string;
  email: string;
  phone: string;
  url: string;
  experience: number;
  noOfClients: number;
  raisedFunds: string;
  opinion: string;
  address: string;
  finCrmId: number;
  district: string;
  photoUrl: string;
  ueGroupId: string;
}

export interface CredatibilityResultBudgetValues {
  loanAmount: number;
  savings: number;
  budget: number;
}

export interface StatisticData {
  whenWantToBuy: number;
  howMuchWantToBuy: number;
}

export interface ExpertState {
  statisticData: StatisticData;
  startingFpExpertVersion: number;
  showOffersWithoutCredibility: boolean;
  welcomeToSuperTrooperOpened: boolean;
  pathHistory: string[];
  welcomeToInstallmentPickerOpened: boolean;
  userLocationSameAsFirstHousehold: boolean;
  numberOfBorrowers: number;
  households: Household[];
  borrowers: FFBorrower[];
  creditHistory: CreditHistory;
  creditHistoryExists: boolean;
  savings: Savings;
  loanDemand: SimulationDemand;
  installmentFormAnswers: InstallmentFormAnswers;
  paths: Paths;
  pathType: Nullable<FlowTypes>
  simulationId: string;
  simulationSorting: string;
  selectedOfferId: string;
  selectedOffer: any;
  timeout: string;
  user: EKOUser;
  userLocation: UserLocation;
  dealsId: string;
  applications: string;
  agent: Agent;
  credatibilityResultBudget: CredatibilityResultBudgetValues;
  plannedLoanAmount: number;
  authToken: string;
  clientAuthToken: string;
}
