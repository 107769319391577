import {DayOfWeek} from '@/commons/enums/DayOfWeek';
import moment from 'moment';

export class UserBusinessHoursElementDTO {
  public day!: DayOfWeek;
  public isEnabled!: boolean;
  public open?: Nullable<string>;
  public close?: Nullable<string>;
}

export class UserBusinessHoursElement extends UserBusinessHoursElementDTO {
  constructor(userBusinessHoursElementDTO?: Partial<UserBusinessHoursElementDTO>) {
    super();
    if (userBusinessHoursElementDTO?.open) {
      userBusinessHoursElementDTO.open =
        this.setHour(userBusinessHoursElementDTO.open, this.getDate(userBusinessHoursElementDTO.open));
    }
    if (userBusinessHoursElementDTO?.close) {
      userBusinessHoursElementDTO.close =
        this.setHour(userBusinessHoursElementDTO.close, this.getDate(userBusinessHoursElementDTO.close));
    }
    Object.assign(this, {...this, ...userBusinessHoursElementDTO, });
  }

  public getDate(hour: string) {
    if (hour) {
      const splittedTime = hour.split(':');
      const hours = splittedTime[0];
      const minutes = splittedTime[1];
      return moment().hours(Number(hours)).minutes(Number(minutes)).startOf('minute').toDate();
    } else {
      return moment().hours(9).startOf('hour').toDate();
    }
  }

  public setHour(str: string, newDate: Date): string {
    str = moment(newDate.toISOString()).format('HH:mm');
    return str;
  }

  public get openDateObject(): Date {
    const date: Date = this.getDate(this.open!);
    return date;
  }

  public set openDateObject(newDate: Date) {
    if (this.open) {
      this.open = this.setHour(this.open, newDate);
    }
  }

  public get closeDateObject(): Date {
    const date: Date = this.getDate(this.close!);
    return date;
  }

  public set closeDateObject(newDate: Date) {
    if (this.close) {
      this.close = this.setHour(this.close, newDate);
    }
  }
}

export const CLOSE_HOUR: string = '17:00';
export const OPEN_HOUR: string = '09:00';

export const userBusinessHours: Array<UserBusinessHoursElement> = [
  {day: DayOfWeek.MONDAY, isEnabled: true, close: CLOSE_HOUR, open: OPEN_HOUR, },
  {day: DayOfWeek.TUESDAY, isEnabled: true, close: CLOSE_HOUR, open: OPEN_HOUR, },
  {day: DayOfWeek.WEDNESDAY, isEnabled: true, close: CLOSE_HOUR, open: OPEN_HOUR, },
  {day: DayOfWeek.THURSDAY, isEnabled: true, close: CLOSE_HOUR, open: OPEN_HOUR, },
  {day: DayOfWeek.FRIDAY, isEnabled: true, close: CLOSE_HOUR, open: OPEN_HOUR, },
  {day: DayOfWeek.SATURDAY, isEnabled: false, close: CLOSE_HOUR, open: OPEN_HOUR, },
  {day: DayOfWeek.SUNDAY, isEnabled: false, close: CLOSE_HOUR, open: OPEN_HOUR, },
].map(x => new UserBusinessHoursElement(x));
