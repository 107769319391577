<template>
  <v-app :id="$cy.crmApp">
    <router-view/>
    <LModal/>
  </v-app>
</template>

<script setup lang="ts">
import {onMounted} from 'vue';
import EnvironmentTheme from './env/services/EnvironmentTheme';
import {setupTheme} from './services/theme/ThemeService';
import LModal from '@/components/L/Modal/LModal.vue';

onMounted(() => {
  setupTheme(EnvironmentTheme.LENDI);
})
</script>

<style lang="scss">
@import "./assets/fonts/fonts.css";
@import "./assets/styles/main.scss";
@import './assets/styles/vuetifyOverride.scss';

.row:not([class*="my-"]):not([class*="ma-"]):not([class*="mt-"]):not([class*="mb-"]) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

* {
  scrollbar-width: thin;
}

.no-border {
  border: none!important;
}

.no-underline {
  text-decoration: none;
}

.align-self-stretch{
  align-self: stretch;
}
.bg-transparent {
  background-color: transparent !important;
}
.p-fixed {
  position: fixed !important;
}
.p-relative {
  position: relative !important;
}
.p-absolute {
  position: absolute!important;
}
::-webkit-scrollbar {
  width: 8px;
  max-width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: rgba(255,255,255,0.4);
  -webkit-box-shadow: inset 1px 1px 2px fpShadow(.1);
}
::-webkit-scrollbar-thumb {
  background: #CCC;
  border-radius: 30px;
  -webkit-box-shadow: inset 1px 1px 2px fpShadow(.2);
}
::-webkit-scrollbar-thumb:hover {
  background: #AAA;
}
::-webkit-scrollbar-thumb:active {
  background: #888;
  -webkit-box-shadow: inset 1px 1px 2px fpShadow(.3);
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
  .pointer {
    cursor: pointer !important;
  }
  .cursor-normal {
    cursor: default !important;
  }

.underline {
  text-decoration: underline;
}

.v-input--switch--flat {
  .v-input--switch__thumb {
    border: 1px solid fpShadow(.15) !important;
  }
}

.v-btn:not(.v-btn--icon):not(.v-btn--floating):not(.v-btn--round):not(.v-btn--contained){
  padding: 0 12px;
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0;
  min-width: 44px;
}

div:not(.v-btn-toggle) > .v-btn:not(.v-btn--icon):not(.v-btn--floating):not(.v-btn--round):not(.v-btn--contained){
  border-radius: 4px;
}

.v-list-item .v-list-item__title {
  word-wrap: break-word;
  text-overflow: clip;
  white-space: normal;
}

  /*.v-btn.v-btn--outline {
    border-radius: 4px;
    border: solid 1px fpShadow(.15) !important;
    color: #232D3B !important;
  }*/

  .v-card__title {
    .v-btn {
      margin: 0;
    }
  }

.no-opacity {
  opacity: 1!important;
}

.disabled-text {
  opacity: $disabled-opacity !important;
}

.loading-text {
  opacity: 0.4!important;
}
  .secondary-text-color {
    opacity: $secondary-opacity !important;
    color: var(--v-fpShadow-base)!important;
  }

.secondary-icon {
  opacity: 0.6!important;
}

.disabled-background{
  position: relative;
  &:before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: fpShadow(.03);
  }
}

.disabled-img {
  filter: opacity($disabled-opacity);
}

.primary-text-color {
  color: var(--v-anchor-base) !important;
}

.rounded-xs {
  border-radius: 4px!important;
}

/*Table Styles*/
tbody > a {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-decoration: none!important;
  position: relative;
  & > td {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid fpShadow(.16);
    }
  }
}

table.v-datatable {

  td > .v-list {
    background: transparent;
    padding: 0;
    margin: -10px 0;
    .v-list-item__title {
      font-size: 13px;
    }
  }
  tbody > tr:hover:not(.v-datatable__expand-row) td,
  tbody > a:hover:not(.v-datatable__expand-row) td{
    background: fpShadow($border-opacity);
  }
  ::v-deep td {
    padding: 4px 16px!important;
    font-size: 14px;

  }
  ::v-deep td,
  ::v-deep th {
    background: var(--v-primaryBg-base);
    a {
      color: #222d3a;
      transition: color .15s ease-out;
      &:hover {
        color: $primary
      }
    }
    & {
      position: sticky;
      transition: background-color 0.2s ease-in;
    }
    @media screen and (max-width: 960px) {
      position: static;
    }
  }
  th:first-child {
     border-top-left-radius: 16px!important;
   }
  }
  .ba{
    border: 1px solid fpShadow($border-opacity);
  }

  .bb-hard {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 1px solid fpShadow($border-opacity);
      height: 1px;
      bottom: 0;
      left: 0;
      z-index: 7;
    }
  }
  .by{
    border-top: 1px solid fpShadow($border-opacity);
    border-bottom: 1px solid fpShadow($border-opacity);
  }
  .bt{
    border-top: 1px solid fpShadow($border-opacity);
  }
  .bb{
    border-bottom: 1px solid fpShadow($border-opacity);
  }
  .br{
    border-right: 1px solid fpShadow($border-opacity);
  }
  .bl{
    border-left: 1px solid fpShadow($border-opacity);
  }
  .bx{
    border-right: 1px solid fpShadow($border-opacity);
    border-left: 1px solid fpShadow($border-opacity);
  }
  .bx-dashed{
    border-right: 1px dashed fpShadow($border-opacity);
    border-left: 1px dashed fpShadow($border-opacity);
  }
  .bl-dashed{
    border-left: 1px dashed fpShadow($border-opacity) !important;
  }
  .bb-dashed{
    border-bottom: 1px dashed fpShadow($border-opacity) !important;
  }
  .bt-dashed{
    border-top: 1px dashed fpShadow($border-opacity) !important;
  }
  .bg-white{
    background-color: var(--v-primaryBg-base) !important;
  }
  .bg-error{
    background-color: var(--v-error-base) !important;
  }
  .bg-transparent{
    background-color: transparent !important;
  }
  .no-icon{
    padding-left: 32px !important;
  }
  .border-bold-dashed{
    border-width: 1.5px !important;
    border-style: dashed !important;
  }
  .border-bold-solid{
    border-width: 1.5px !important;
    border-style: solid !important;
    border-color: $primary !important;
  }
  .fp-tabs {

    div .v-tabs-bar {
      background-color: transparent !important;
    }

    .v-tabs__container {
      height: 40px;
    }

    .v-tabs__item {
      text-transform: none;
      padding: 12px 8px 12px 0!important;
    }

    .v-tabs__item--active {
      color: $primary !important;
    }

    div .theme--light.v-sheet:not(.v-card) {
      background-color: transparent !important;
    }
  }
  .fp-border-card {
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border: solid 1px fpShadow($border-opacity) !important;

  }

.fill-width {
  width: 100% !important;
}

.multiline-button.v-btn{
  min-height: 36px;
  height: 100%!important;
  .v-btn__content{
    width: 100%;
    white-space: normal;
  }
}

.display-flex {
  display: flex;
}
div ::v-deep .v-card:not(.user-event) {
  border: 1px solid fpShadow(.87);
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,$border-opacity)!important;
  -moz-box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,$border-opacity)!important;
  box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,$border-opacity)!important;
}

  .shrink.shrink-fix {
    min-width: fit-content;
  }

  .white-border {
    border-left-color: colorShade(white, $disabled-opacity) !important;
    border-right-color: colorShade(white, $disabled-opacity) !important;
    border-top-color: colorShade(white, $disabled-opacity) !important;
    border-bottom-color: colorShade(white, $disabled-opacity) !important;
  }

  .all-caps-header {
    &:not([class*="--text"]) {
      opacity: 0.6!important;
    }
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .v-radio, .v-input--checkbox {
    .v-input--selection-controls__ripple {
      z-index: 7;
    }
    &.v-radio--is-disabled label {
      color: fpShadow($disabled-opacity) !important;
    }
  }
.big-padding-bottom {
  padding-bottom: 25vh !important;
}
  .warning-card {
    background-color: colorShade($warning, 0.03) !important;
    border-color: $warning !important;
    border-radius: 4px !important;
  }
  .info-card {
    background-color: colorShade($primary, 0.03) !important;
    border: none !important;
    border-radius: 4px !important;
  }

  .fit-height{
    height: fit-height !important;
  }
  .fp-checkbox label {
    color: var(--v-anchor-base) !important;
  }
  .element-transition {
    --element-transition-property: background-color, color, opacity;
    --element-transition-duration: 500ms;

    *, *::before, *::after {
      transition-duration: var(--element-transition-duration, 500ms) !important;
      transition-property: var(--element-transition-property, all) !important;
    }
  }
  .sticky-actions {
    position: sticky;
    bottom: 0;
    z-index: 3;
    background-color: var(--v-primaryBg-base);
  }
  .anchor--text {
    color: var(--v-anchor-base)
  }

  @media print {
    #usercom-widget {
      display: none;
    }
  }

  :has(.calendar){
    #app{
      overflow: hidden;
      height: 100dvh;
    }
    #usercom-widget {
      display: none;
    }
    .ui-placeholder{
     display: none;
    }
  }

  :has(.activity-details-menu-wrapper.menuable__content__active ){
    overflow: hidden;
  }

  .border-radius-0-important{
    border-radius: 0 !important;
  }

  html {
    scroll-behavior: smooth;
  }
</style>
