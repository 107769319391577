import Deal from '../../../../models/Deal';
import {DealsStoreState} from '@/modules/deals/services/store/DealsStoreState';
import Vue from 'vue';
import Client from '@/models/Client';
import {DealStatus} from '@/modules/deals/enums/DealStatus';
import {HistoryElement} from '@/models/HistoryElement';
import {DealStage} from '@/modules/deals/enums/DealStage';
import {IStoreModule} from '@/store/store';

const state: DealsStoreState = {
  Deals: [],
  selectedDealId: undefined,
};

export const DealsStoreModule: IStoreModule<DealsStoreState> = {
  namespaced: false,
  state,
  getters: {
    deals(state: DealsStoreState) {
      return state.Deals;
    },
    selectedDeal(state: DealsStoreState) {
      const foundDeal = state.Deals.find(deal => deal.id === state.selectedDealId);
      return foundDeal ? new Deal(state.Deals.find(deal => deal.id === state.selectedDealId)) : null;
    },
  },
  mutations: {
    setDeals(state: DealsStoreState, deals: Array<Deal>) {
      state.Deals = deals.map(newDeal => {
        const oldDealIndex = state.Deals.findIndex(oldDeal => oldDeal.id === newDeal.id);
        const oldDeal = state.Deals[oldDealIndex];
        if (oldDeal && oldDeal.history && oldDeal.history.length > 0) {
          newDeal.history = state.Deals[oldDealIndex].history || [];
        }
        return newDeal;
      });
    },
    setDealHistory(state: DealsStoreState, payload: {dealId: number, history: Array<HistoryElement<any>>}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      state.Deals[index].history = payload.history;
    },
    setDealStep(state: DealsStoreState, payload: {dealId: number, newStep: string}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      state.Deals[index].wizardStep = payload.newStep;
    },
    addDealHistory(state: DealsStoreState, payload: {dealId: number, newHistoryElement: HistoryElement<any>}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      if (!state.Deals[index].history) {
        state.Deals[index].history = [];
      }
      state.Deals[index].history!.unshift(payload.newHistoryElement);
    },
    removeLastAddedDealHistory(state: DealsStoreState, dealId: number) {
      const index = state.Deals.findIndex(deal => dealId === deal.id);
      if (!state.Deals[index].history) {
        state.Deals[index].history = [];
      }
      state.Deals[index].history!.shift();
    },
    updateLastAddedDealHistory(state: DealsStoreState,
      payload: {dealId: number, newHistoryElement: HistoryElement<any>}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      if (!state.Deals[index].history) {
        state.Deals[index].history = [];
      }
      state.Deals[index].history![0] = payload.newHistoryElement;
    },
    removeLastClients(state: DealsStoreState, payload: {dealId: number, numberOfClientsToRemove: number}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      state.Deals[index].clients.splice(-payload.numberOfClientsToRemove, payload.numberOfClientsToRemove);
    },
    addClientToHousehold(state: DealsStoreState, payload: {dealId: number, hhId: number, client: Client}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      const hhIndex = state.Deals[index].households!.findIndex(hh => payload.hhId === hh.id);
      state.Deals[index].clients.push(payload.client);
      state.Deals[index].households![hhIndex].clientsIds.push(payload.client.id!);
    },
    removeClientFromHousehold(state: DealsStoreState, payload: {dealId: number, hhId: number, clientId: number}) {
      const index = state.Deals.findIndex(deal => payload.dealId === deal.id);
      const hhIndex = state.Deals[index].households!.findIndex(hh => payload.hhId === hh.id);
      const clientIndex = state.Deals[index].clients.findIndex(client => client.id === payload.clientId);
      const clientHhIndex = state
        .Deals[index]
        .households![hhIndex]
        .clientsIds!
        .findIndex(cId => cId === payload.clientId);

      state.Deals[index].clients.splice(clientIndex, 1);
      state.Deals[index].households![hhIndex].clientsIds.splice(clientHhIndex, 1);
    },
    setDeal(state: DealsStoreState, editedDeal: Deal) {
      const index = state.Deals.findIndex(deal => editedDeal.id === deal.id);
      if (index > -1) {
        if (editedDeal.history.length === 0 && state.Deals[index].history) {
          editedDeal.history = state.Deals[index].history || [];
        }
        Vue.set(state.Deals, index, editedDeal);
      } else {
        state.Deals.push(editedDeal);
      }
    },
    setDealStatus(state: DealsStoreState, payload: {dealId: number, newStatus: DealStatus}) {
      const index = state.Deals.findIndex(activity => payload.dealId === activity.id);
      state.Deals[index].status = payload.newStatus;
    },
    setDealStage(state: DealsStoreState, payload: {dealId: number, newStage: DealStage}) {
      const index = state.Deals.findIndex(activity => payload.dealId === activity.id);
      state.Deals[index].stage = payload.newStage;
    },
    addDeal(state: DealsStoreState, deal: Deal) {
      state.Deals.push(deal);
    },
    setDealId(state: DealsStoreState, id: number) {
      state.selectedDealId = id;
    },
  },
};
