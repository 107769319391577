import {Tour} from '@/modules/onboarding/tour/Tour';
import {TourModalType, TourName, TourOptions} from '@/modules/onboarding/tour/models/TourOptions';
import {TourPlacement, TourStep} from '@/modules/onboarding/tour/models/TourStep';
import {LeadKey} from '@/modules/onboarding/tours/enums/LeadKey';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {navigationMenuTheme} from '@/modules/onboarding/useOnboardingTheme';

const STEPS: readonly TourStep[] = [
  {
    key: LeadKey.PRESS_NEW_ACTIVITY,
    content: I18NGetter().useLeadTour.PRESS_NEW_ACTIVITY,
    params: {
      enableScrolling: false,
      highlightTheme: navigationMenuTheme,
      placement: TourPlacement.LEFT,
    },
    isPreviousButtonHidden: true,
  },
  {
    key: LeadKey.INITIAL_FORM,
    content: I18NGetter().useLeadTour.INITIAL_FORM,
    params: {
      placement: TourPlacement.TOP,
      highlightTheme: navigationMenuTheme,
    },
  },
  {
    key: LeadKey.ADDING_CLIENT,
    content: I18NGetter().useLeadTour.ADDING_CLIENT,
    params: {
      placement: TourPlacement.TOP,
    },
  },
  {
    key: LeadKey.CLIENT_INFO,
    content: I18NGetter().useLeadTour.CLIENT_INFO,
    params: {
      placement: TourPlacement.LEFT,
    },
  },
  {
    key: LeadKey.FILL_AGREEMENTS,
    content: I18NGetter().useLeadTour.FILL_AGREEMENTS,
    params: {
      highlightTheme: navigationMenuTheme,
    },
  },
  {
    key: LeadKey.SMS,
    content: I18NGetter().useLeadTour.SMS,
    params: {
      highlightTheme: navigationMenuTheme,
    },
  },
  {
    key: LeadKey.PASS_LEAD,
    content: I18NGetter().useLeadTour.PASS_LEAD,
    params: {},
  },

  {
    key: LeadKey.RECOMMENDED_EXPERT,
    content: I18NGetter().useLeadTour.RECOMMENDED_EXPERT,
    params: {placement: TourPlacement.TOP,},
  },

  {
    key: LeadKey.SYSTEM_FORCE_APPOINTMENT_2,
    content: I18NGetter().useLeadTour.SYSTEM_FORCE_APPOINTMENT_2,
    params: {
      placement: TourPlacement.LEFT,
    },
  },
  {
    key: LeadKey.FILL_APPOINTMENT_FORM,
    content: I18NGetter().useLeadTour.FILL_APPOINTMENT_FORM,
    params: {
      highlightTheme: navigationMenuTheme,
      placement: TourPlacement.LEFT,

    },
  },
  {
    key: LeadKey.PASS_LEAD_INFO_1,
    content: I18NGetter().useLeadTour.PASS_LEAD_INFO_1,
    params: {
      highlightTheme: navigationMenuTheme,
    },
  },
  {
    key: LeadKey.GO_TO_DEALS,
    content: I18NGetter().useLeadTour.GO_TO_DEALS,
    params: {
      highlightTheme: navigationMenuTheme,
    },
  },
  {
    key: LeadKey.BANKING_SECRECY,
    content: I18NGetter().useLeadTour.BANKING_SECRECY,
    params: {},
  },
  {
    key: LeadKey.SEND_AGREEMENT_BANKING_SECRECY,
    content: I18NGetter().useLeadTour.SEND_AGREEMENT_BANKING_SECRECY,
    params: {},
    isNextButtonHidden: true,
  },
] as const;

const OPTIONS: TourOptions = new TourOptions({
  modals: {
    [TourModalType.START]: {
      description: I18NGetter().useLeadTour.START,
    },
    [TourModalType.FINISH]: {},
  },
});

const LeadTour: Tour = new Tour(TourName.LEAD, STEPS, OPTIONS,);

export default LeadTour;
