export const getZoomStyles = (zoomLevel: number) => ({
  width: `${100 * 100 / zoomLevel}%`,
  '-ms-zoom': zoomLevel / 10,
  '-moz-transform': `scale(${zoomLevel / 100})`,
  '-moz-transform-origin': '0 0',
  '-o-transform': `scale(${zoomLevel / 100})`,
  '-o-transform-origin': '0 0',
  '-webkit-transform': `scale(${zoomLevel / 100})`,
  '-webkit-transform-origin': '0 0',
});
