<template>
  <v-tabs :value="model" :center-active="centerActive" @change="$emit('change', $event)" class="navigation-tabs" :show-arrows="!hideArrows">
    <v-tab
      :disabled="disabled"
      @click="model = index"
      active-class="primary--text"
      v-for="(tabHead, index) in tabsHeaders"
      :key="index">
      {{isObject ? tabHead[itemName] : tabHead}}
      <slot name="tabAction" :index="index"></slot>
    </v-tab>
    <slot name="tabsAction"/>
  </v-tabs>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class NavigationTabs extends Vue {
  @Prop({default: () => [], })
  public readonly tabsHeaders!: string[] | Record<string, any>[];

  @Prop({default: 'type', type: String,})
  public readonly itemKey!: string;

  @Prop({default: 'name_pl', type: String,})
  public readonly itemName!: string;

  @Prop()
  public readonly value!: any;

  @Prop({type: Boolean, })
  public readonly hideArrows!: boolean;

  @Prop({type: Boolean, default: false, })
  public readonly disabled!: boolean;

  @Prop({type: Boolean, default: false, })
  public readonly centerActive?: boolean;

  get isObject(): boolean {
    return typeof this.tabsHeaders[0] === 'object';
  }

  get model(): number {
    return this.isObject
      ? this.tabsHeaders.findIndex((x: string | Record<string, any>) => typeof x === 'object' && x[this.itemKey] === this.value)
      : this.value;
  }

  set model(newValue: number) {
    const value = this.isObject && typeof this.tabsHeaders[newValue] === 'object'
      ? (this.tabsHeaders[newValue] as Record<string, any>)[this.itemKey]
      : newValue;
    this.$emit('input', value);
  }
}
</script>

<style scoped lang="scss">
  .v-tabs.navigation-tabs {
    background-color: var(--v-primaryBg-base) !important;

    ::v-deep .v-slide-group__prev--disabled {
      display: none!important;
    }
    ::v-deep .v-tabs-bar {
      height: 32px;
    }
    ::v-deep .v-tab {
      font-weight: normal;
      position: relative;
      letter-spacing: 0;
      min-width: 0;
      padding: 0 0 8px 0;
      margin-right: 24px;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 24px;
      &:before {
        top: initial;
        height: 3px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      &:hover:before {
        opacity: .6;
      }
    }

    ::v-deep.v-tabs__item--active {
      color: $primary!important;
    }

    ::v-deep .v-tabs-slider-wrapper {
      border-top-left-radius: 15px;
      border: 1px solid $primary;
      border-top-right-radius: 15px;
      height: 3px!important;
      background-color: $primary;
    }
  }
</style>
