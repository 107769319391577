import Vue from 'vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import NotificationService from '@/modules/notification/NotificationService';

enum SnackbarTemplatesType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  ZIP = 'zip',
  EXCEL = 'excel',
}

interface IDownloadWindowOptions {
  fileId: string,
  errorType?: string,
  msgOnSuccess?: string,
  msgOnError?: string,
  onDownload?(): void,
  offsetTop?: string,
}

const createVSnackbarTemplate = (icon: string, color: string,) =>
  `<v-snackbar :id="id" v-model="showSnackbar" :timeout="duration" style="height: initial; z-index: 999;">
        <span>
          <v-icon color="${color}">${icon}</v-icon>
          {{msg}}
        </span>
        <template #action>
          <v-btn small @click="closeSnackbar()" text>
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
`;

const createVSnackbarDownloadTemplate = (icon: string,) =>
  `<v-snackbar :id="id" light v-model="showSnackbar" top right rounded :timeout="duration" style="height: initial; z-index: 999;" :style="{'padding-top': infoWindowOptions.offsetTop}">
    <v-row no-gutters>
      <v-col class="shrink">
        <v-badge
          bottom
          avatar
          overlap>
          <template #badge>
            <v-avatar :color="isDownloadReady ? 'success' : isError ? 'error' : 'primary'" :class="{'cog-wrapper': !isDownloadReady && !isError}" width="20px">
              <v-icon dark color="white" v-if="!isDownloadReady && !isError" size="16">mdi-cog</v-icon>
              <v-icon dark color="white" v-else-if="!isDownloadReady && isError" size="16">mdi-download-off</v-icon>
              <v-icon dark color="white" v-else size="16">mdi-download</v-icon>
            </v-avatar>
          </template>

          <v-avatar size="44" color="rgba(35, 45, 59, 0.13)">
            <v-icon size="32">
              ${icon}
            </v-icon>
          </v-avatar>
        </v-badge>
      </v-col>
      <v-col class="pl-6">
        <template v-if="isDownloadReady && infoWindowOptions.msgOnSuccess">
          <span class="body-1" v-if="infoWindowOptions.msgOnSuccess" v-html="infoWindowOptions.msgOnSuccess" />
        </template>
        <template v-else-if="isError && infoWindowOptions.msgOnError">
          <span class="body-1" v-if="infoWindowOptions.msgOnError" v-html="infoWindowOptions.msgOnError" />
        </template>
        <span class="body-1" v-else v-html="msg" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-spacer />
      <v-col class="shrink">
        <v-btn text @click="closeSnackbar()" depressed>${I18NGetter().useButtonCommons.CLOSE}</v-btn>
      </v-col>
      <v-col class="shrink" v-if="infoWindowOptions.onDownload && isDownloadReady">
        <v-btn text color="primary" @click="infoWindowOptions.onDownload" depressed>${I18NGetter().useButtonCommons.DOWNLOAD}</v-btn>
      </v-col>
    </v-row>
  </v-snackbar>`;

const templatesType: Record<SnackbarTemplatesType, string> = {
  [SnackbarTemplatesType.ERROR]: createVSnackbarTemplate('error', 'error'),
  [SnackbarTemplatesType.SUCCESS]: createVSnackbarTemplate('done', 'success'),
  [SnackbarTemplatesType.WARNING]: createVSnackbarTemplate('warning', 'warning'),
  [SnackbarTemplatesType.ZIP]: createVSnackbarDownloadTemplate('mdi-package-down'),
  [SnackbarTemplatesType.EXCEL]: createVSnackbarDownloadTemplate('mdi-file-excel'),
};

class SnackbarService {
  public NotificationObservable: { items: any[], totalUnread: number, } | null = NotificationService.Observable;

  public openSuccessSnackbar(msg: string, duration?: number): void {
    this.openSnackBar(SnackbarTemplatesType.SUCCESS, msg, duration);
  }

  public openErrorSnackbar(msg: string, duration?: number): void {
    this.openSnackBar(SnackbarTemplatesType.ERROR, msg, duration);
  }

  public openWarningSnackbar(msg: string, duration?: number): void {
    this.openSnackBar(SnackbarTemplatesType.WARNING, msg, duration);
  }

  public openDownloadWindowZip(msg: string, infoWindowOptions: IDownloadWindowOptions): void {
    this.openSnackBar(SnackbarTemplatesType.ZIP, msg, -1, infoWindowOptions);
  }

  public openDownloadWindowExcel(msg: string, infoWindowOptions: IDownloadWindowOptions): void {
    this.openSnackBar(SnackbarTemplatesType.EXCEL, msg, -1, infoWindowOptions);
  }

  // eslint-disable-next-line vue/max-len
  private CreateSnackBarComponent(type: SnackbarTemplatesType, msg: string, duration: number, infoWindowOptions?: IDownloadWindowOptions) {
    const notificationObservable = this.NotificationObservable;
    return (Vue.component('fp-snackbar', {
      data() {
        return {
          msg,
          showSnackbar: false,
          infoWindowOptions,
          duration,
          id: Math.random().toString(36).substr(2, 5),
          createdAt: new Date().getTime(),
        };
      },
      computed: {
        isDownloadReady() {
          if (infoWindowOptions?.fileId && notificationObservable) {
            const downloadNotification = notificationObservable.items.find(notification => {
              return !notification.isRead &&
                notification.data &&
                notification.data.fileId === infoWindowOptions.fileId;
            });
            return downloadNotification || null;
          } else {
            return null;
          }
        },
        isError() {
          if (infoWindowOptions && notificationObservable) {
            const downloadNotification = notificationObservable.items.find(notification => {
              const date = new Date(notification?.createdDate);
              return !notification.isRead && notification.data?.error &&
                notification.type === infoWindowOptions?.errorType &&
                date.getTime() > (this as any).createdAt;
            });
            return downloadNotification || null;
          } else {
            return null;
          }
        },
      },

      methods: {
        closeSnackbar() {
          const element = document.getElementById(this.id)!;
          const index = Array.from(element.parentNode!.children).indexOf(element);
          document.querySelectorAll('.v-snack').forEach((node, i, nodes) => {
            if (i < index && (node as HTMLElement).style.bottom !== null) {
              const bottomPixels = Number((node as HTMLElement).style.bottom!.replace('px', ''));
              (node as HTMLElement).style.bottom = `${bottomPixels - 56}px`;
            }
          });
          this.showSnackbar = false;
        },
      },
      mounted() {
        setTimeout(() => {
          this.showSnackbar = true;
        }, 20);
        if (!duration || duration > 0) {
          setTimeout(() => {
            this.showSnackbar = false;
          }, this.duration || 8000);
        }
      },
      template: templatesType[type],
    }));
  }

  // eslint-disable-next-line vue/max-len
  private openSnackBar = (type: SnackbarTemplatesType, msg: string, duration: number = 8000, infoWindowOptions?: IDownloadWindowOptions) => {
    const VueSnackBarComponent = this.CreateSnackBarComponent(type, msg, duration, infoWindowOptions);
    const SnackBarInstance = new VueSnackBarComponent();
    SnackBarInstance.$vuetify = Vue.prototype.$vuetify;
    const mountElement = document.getElementsByClassName('v-application--wrap')[0];
    if (mountElement) {
      const newDiv = document.createElement('div');
      const id = 'newSnackBar';
      newDiv.id = id;
      mountElement.querySelectorAll('.v-snack').forEach((node, i, nodes) => {
        (nodes[nodes.length - (i + 1)] as HTMLElement).style.bottom = `${(56 + 4) * (i + 1)}px`;
      });
      mountElement.appendChild(newDiv);
      SnackBarInstance.$mount(`#${id}`);
    } else {
      console.error('Can\'t find v-app');
    }
  };
}
export default new SnackbarService();
