<template>
  <v-btn :outlined="!primary"
         @click="doTheClick"
         :depressed="primary"
         :small="small"
         :large="large"
         :color="primary ? 'primary' : ''"
         :loading="loader"
         :block="block"
         :class="buttonClass + `${block ? ' mx-0': ''}`"
         :disabled="loader || disabled">
    <slot></slot>
    <template v-slot:loader>
      <template v-if="loaderProgress === 0">
        <span class="mr-2">{{loaderStepText[loaderStep]}}... </span>
        <v-progress-circular :size="20" indeterminate></v-progress-circular>
      </template>
      <span class="mr-2" v-else>{{loaderText}} | {{loaderProgress}}MB</span>
    </template>
  </v-btn>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class LoaderButton extends Vue {
  @Prop() public click!: (onProgressFunction: (progress: ProgressEvent) => void) => void;
  @Prop({default: 'Pobieranie', type: String, }) public loaderText!: boolean;
  @Prop({default: false, type: Boolean, }) public primary!: boolean;
  @Prop({default: false, type: Boolean, }) public small!: boolean;
  @Prop({default: false, type: Boolean, }) public large!: boolean;
  @Prop({default: false, type: Boolean, }) public block!: boolean;
  @Prop({default: false, type: Boolean, }) public disabled!: boolean;
  @Prop({default: '', type: String, }) public buttonClass!: string;

  public readonly INTERVAL_LENGTH: number = 2500;
  public loaderStep: number = 0;
  public loader: boolean = false;
  public loaderProgress: number = 0;

  @Prop({
    type: Array,
    default: () => [
      'Przygotowuje dokumenty',
      'Kompresuje pliki',
      'Nawiązuje połączenie',
      'Rozpoczynam pobieranie',
    ],
  })
  public loaderStepText!: string[];

  public async doTheClick() {
    this.loader = true;

    const intervalID = setInterval(() => {
      this.loaderStep++;
      if (this.loaderStep === this.loaderStepText.length - 1) {
        window.clearInterval(intervalID);
      }
    }, this.INTERVAL_LENGTH);

    try {
      await this.click((progress: ProgressEvent) => {
        this.loaderProgress = Math.round(progress.loaded / 1024 / 102.4) / 10;
      });
    } catch (e) {
      console.error(e);
      this.loader = false;
    }
    this.loader = false;
    this.loaderProgress = 0;
    this.loaderStep = 0;
  }
}
</script>

<style scoped>

</style>
