import {Person} from '@/models/Person';
import {OrganisationId} from '@/models/enums/OrganisationId';
import {Utils} from '@/services/utils/BasicUtils';

export class Owner extends Person {
  public username!: string;
  public email!: string;
  public organisationId!: OrganisationId;
  public organisationUnitId!: number;

  constructor(dto: Owner) {
    super(dto);
    Utils.assign(this, dto);
  }

  public get displayName(): string {
    return (this.firstName || this.lastName) ? `${this.firstName} ${this.lastName}` : '-';
  }
}
