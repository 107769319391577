import {RoleTypes} from '@/commons/enums/RoleTypes';
import {RoleTypeNames} from '@/commons/enums/RoleTypeNames';

export class Role {
  id: Nullable<RoleTypes> = RoleTypes.EXPERT;
  name: Nullable<string> = RoleTypeNames.EXPERT;

  constructor(id?: Nullable<number>, name?: Nullable<string>) {
    if (id !== undefined) {
      this.id = id;
    }
    if (name !== undefined) {
      this.name = name;
    }
  }
}
