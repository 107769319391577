import AppRouter from '@/router';
import { Routes } from '@/router/Routes';
import Client from '@/models/Client';
import {SmsApiData} from '@/models/LoginData';
import ClientUserApi from '@/modules/client/services/ClientUserApi';
import {TOKEN_GETTER_KEY, TokenType, TOKEN_TYPE_KEY, CLIENT_COOKIE_TOKEN_GETTER} from '@/commons/enums/TokenType';
import Cookies from 'js-cookie';

class ClientAuthService {
  private readonly CLIENT_TOKEN_KEY: string = TOKEN_GETTER_KEY;

  private clientData: Nullable<Client> = null;

  public get token(): string | null {
    const cookieToken = Cookies.get(CLIENT_COOKIE_TOKEN_GETTER);
    if (cookieToken) {
      Cookies.remove(CLIENT_COOKIE_TOKEN_GETTER);
      this.token = cookieToken;
      return cookieToken;
    } else {
      const localStorageToken = localStorage.getItem(this.CLIENT_TOKEN_KEY);
      return localStorageToken || null;
    }
  }

  public set token(newToken: string | null) {
    if (newToken) {
      localStorage.setItem(TOKEN_TYPE_KEY, TokenType.CLIENT);
      localStorage.setItem(this.CLIENT_TOKEN_KEY, newToken);
    } else {
      console.error('newToken is null');
    }
  }

  public logout() {
    localStorage.removeItem(this.CLIENT_TOKEN_KEY);
    AppRouter.push({
      name: Routes.CLIENT_USER.LOGIN,
    });
  }

  public get User(): Nullable<Client> {
    return this.clientData;
  }

  public set User(user: Nullable<Client>) {
    this.clientData = user;
  }

  public get isTokenValid(): boolean {
    return !!this.token;
  }

  async login(clientLoginData: SmsApiData): Promise<void> {
    this.token = await ClientUserApi.checkSms(clientLoginData);
    await this.fetchUser();
  }

  public async fetchUser(): Promise<Client> {
    const client = await ClientUserApi.getUser();
    this.User = client;
    return client;
  }

  public goToEKO(lastPath: string = ''): void {
    if (lastPath.startsWith('/')) {
      lastPath = lastPath.slice(1, lastPath.length);
    }
    if (this.token) {
      Cookies.set(CLIENT_COOKIE_TOKEN_GETTER, this.token, {
        domain: process.env.VUE_APP_COOKIE_URL,
        expires: 1 / 288, // 5 minutes
      });
      const ekoPanel = process.env.VUE_APP_EKO_URL + lastPath;
      if (ekoPanel) {
        window.location.href = ekoPanel;
      } else {
        console.error('Cannot find client panel URL', process.env.VUE_APP_EKO_URL);
      }
    } else {
      throw new Error('Cannot find valid auth token');
    }
  }
}

export default new ClientAuthService();
