import {onMounted, onUnmounted} from 'vue';

export const useVhHack = () => {
  /***
   * Hack for mobile browsers to fix vh units
   */
  const calculateVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  onMounted(() => {
    calculateVh();
    window.addEventListener('resize', calculateVh);
    window.addEventListener('orientationchange', calculateVh);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', calculateVh);
    window.removeEventListener('orientationchange', calculateVh);
  });
};
