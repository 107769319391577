import {RouteConfig} from 'vue-router';
import { Routes } from '@/router/Routes';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
const TrainingsList = () => import(/* webpackChunkName: "Training" */ '@/modules/trainings/TrainingsList.vue');
const TrainingDetails = () => import(/* webpackChunkName: "Training" */ '@/modules/trainings/TrainingDetails.vue');

const TrainingsRoutes: Array<RouteConfig> = [
  {
    path: 'trainings',
    name: Routes.TRAINING_FILES.TRAINING_FILES,
    component: TrainingsList,
    meta: {
      title: I18NGetter().KnowledgeBaseSection.training_courses,
      icon: 'mdi-school',
    },
  },
  {
    path: 'trainings/:id',
    name: Routes.TRAINING_FILES.TRAINING_DETAILS,
    component: TrainingDetails,
  },
];

export default TrainingsRoutes;
