import BaseStoreService from '@/store/BaseStoreService';
import {LoanApplicationState} from '@/modules/loanApplication/store/LoanApplicationStoreState';
import {LoanApplicationStoreModule} from '@/modules/loanApplication/store/LoanApplicationStoreModule';
import LoanApplication from '@/models/LoanApplication';
import LoanApplicationsApi from '@/modules/loanApplication/services/LoanApplicationsApi';
import Note from '@/models/Note';
import {HistoryElement} from '@/models/HistoryElement';
import {FpMessageType} from '@/modules/deals/enums/FpMessageType';
import {LoanApplicationStepEnum} from '@/modules/loanApplication/enums/LoanApplicationStepEnum';

class LoanApplicationStore extends BaseStoreService<LoanApplicationState> {
  public mutations = LoanApplicationStoreModule.mutations;

  get getLoanApplication() {
    return this.read<LoanApplication>(LoanApplicationStoreModule.getters.loanApplication);
  }

  async setLoanApplicationId(id: number) {
    this.commit<number>(this.mutations.setLoanApplicationId, id);
  }

  async fetchLoanApplications() {
    const loanApplicationList = await LoanApplicationsApi.getLoanApplicationList();
    this.commit(this.mutations.setLoanApplications, loanApplicationList);
  }

  async fetchLoanApplication(loanApplicationId: number) {
    const loanApplication = await LoanApplicationsApi.getLoanApplication(loanApplicationId);
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async setVerifier(loanApplication: LoanApplication, userId: number) {
    await LoanApplicationsApi.setVerifier(loanApplication);
    loanApplication.verifierId = userId;
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async unsetVerifier(loanApplication: LoanApplication) {
    await LoanApplicationsApi.unsetVerifier(loanApplication);
    loanApplication.verifierId = null;
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async setLoanApplicationStep(loanApplicationId: number, oldLoanApplicationStepId: number, stepId: number) {
    this.commit(this.mutations.setLoanApplicationState, {loanAppId: loanApplicationId, stepId: stepId, });
    try {
      await LoanApplicationsApi.setLoanApplicationStep(loanApplicationId, stepId);
    } catch (e) {
      this.commit(this.mutations.setLoanApplicationState,
        {loanAppId: loanApplicationId, stepId: oldLoanApplicationStepId, });
      throw e;
    }
  }

  async fetchHistory(loanApplication: LoanApplication) {
    this.commit(this.mutations.addLoanApplication, loanApplication);
    const loanApplicationId: number = loanApplication.id;
    const history = await LoanApplicationsApi.getApplicationHistory(loanApplicationId);
    this.commit(this.mutations.setLoanApplicationHistory, {loanApplicationId, history, });
  }

  async addNote(loanApplicationId: number, newNote: Note) {
    const newHistoryElement = new HistoryElement({
      data: newNote,
      type: FpMessageType.NOTE_ADDED,
      createdDate: (new Date()).toISOString(),
    });
    this.commit(this.mutations.addLoanApplicationHistory, {loanApplicationId, newHistoryElement, });
    try {
      newHistoryElement.data =
        await LoanApplicationsApi.addNote(loanApplicationId, {text: String(newHistoryElement.data.text), },);
      newHistoryElement.createdDate = newHistoryElement.data.createdAt;
      this.commit(this.mutations.updateLastAddedLoanApplicationHistory, {loanApplicationId, newHistoryElement, });
    } catch (e) {
      this.commit(this.mutations.removeLastAddedLoanApplicationHistory, loanApplicationId);
    }
  }

  async sendToVerification(loanApplication: LoanApplication): Promise<void> {
    const newApplication = await LoanApplicationsApi.sendToVerification(loanApplication);
    Object.assign(newApplication, loanApplication);
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async accept(loanApplication: LoanApplication, emailContent: string): Promise<void> {
    await LoanApplicationsApi.accept(loanApplication, emailContent);
    loanApplication.step.id = LoanApplicationStepEnum.Accepted;
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async sendDirectlyToBank(loanApplication: LoanApplication, emailContent: string): Promise<void> {
    await LoanApplicationsApi.sendDirectlyToBank(loanApplication, emailContent);
    loanApplication.step.id = LoanApplicationStepEnum.Accepted;
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async reject(loanApplication: LoanApplication): Promise<void> {
    await LoanApplicationsApi.reject(loanApplication);
    loanApplication.step.id = LoanApplicationStepEnum.Rejected;
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }

  async returnForCorrection(loanApplication: LoanApplication): Promise<void> {
    await LoanApplicationsApi.returnForCorrection(loanApplication);
    loanApplication.step.id = LoanApplicationStepEnum.ReturnedForCorrection;
    this.commit(this.mutations.setLoanApplication, loanApplication);
  }
}

export default new LoanApplicationStore();
