import {GooglePlacesTypes} from '@/modules/googleAutocomplete/enums/GooglePlacesTypes';

export default class GoogleAutocompletePrediction implements google.maps.places.AutocompletePrediction {
  public description!: string;
  public matched_substrings!: google.maps.places.PredictionSubstring[];
  public place_id!: string;
  public reference!: string;
  public structured_formatting!: google.maps.places.AutocompleteStructuredFormatting;
  public terms!: google.maps.places.PredictionTerm[];
  types!: GooglePlacesTypes[];
  id!: string;

  get icon(): string {
    return this.types.includes(GooglePlacesTypes.LOCALITY)
      ? 'mdi-city'
      : 'mdi-road-variant';
  };

  constructor(autocompletePrediction: google.maps.places.AutocompletePrediction) {
    Object.assign(this, autocompletePrediction);
  }
}
