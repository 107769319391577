<template>
  <v-menu
    ref="menu"
    v-model="isOpen"
    close-on-content-click
    :return-value="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        hide-details
        placeholder="MM-RRRR"
        outlined
        :rules="[v => required && !!v || 'Pole wymagane']"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="fpDate"
      @change="onFpDateChange"
      type="month"
      no-title
      scrollable
      :max="max"
      :required="required"
    >
    </v-date-picker>
  </v-menu>
</template>
<script lang="ts">
import {computed, defineComponent, ref} from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: 'Data',
    },
    value: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
      default: '',
    },
  },
  setup(props, {emit,}) {
    const fpDate = computed<string>(() =>
      `${props.value.substr(3, 4)}-${props.value.substr(0, 2)}`);

    const date = computed<string>({
      get: () => props.value,
      set: (value: string) => emit('input', value),
    });

    const onFpDateChange = (event?: string) => {
      if (event && event.length === 7) {
        date.value = `${event.substr(5, 2)}-${event.substr(0, 4)}`;
      }
    };

    const isOpen = ref<boolean>(false);
    return {
      isOpen,
      date,
      fpDate,
      onFpDateChange,
    };
  },
});

</script>
<style scoped lang="scss">

</style>
