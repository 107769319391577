export enum FpMessageType {
  STATUS_CHANGED = 'STATUS_CHANGED',
  NOTE_ADDED = 'NOTE_ADDED',
  NOTE_REMOVED = 'NOTE_REMOVED',
  ACCEPTS_BIG_CHANGED = 'ACCEPTS_BIG_CHANGED',
  ACTIVITY_DONE = 'ACTIVITY_DONE',
  ACTIVITY_UNDONE = 'ACTIVITY_UNDONE',
  ACTIVITY_CREATED = 'ACTIVITY_CREATED',
  ACTIVITY_REMOVED = 'ACTIVITY_REMOVED',
  APPLICATION_ADDED = 'APPLICATION_ADDED',
  APPLICATION_REMOVED = 'APPLICATION_REMOVED',
  APPLICATION_STATUS_CHANGED = 'APPLICATION_STATUS_CHANGED',
  CLIENT_ADDED = 'CLIENT_ADDED',
  CLIENT_REMOVED = 'CLIENT_REMOVED',
  BACK_TO_AGENT_CC = 'BACK_TO_AGENT_CC',
  STAGE_CHANGED = 'STAGE_CHANGED',
  ARCHIVED = 'ARCHIVED',
  HOUSEHOLD_UPDATED = 'HOUSEHOLD_UPDATED',
  HOUSEHOLD_CREATED = 'HOUSEHOLD_CREATED',
  HOUSEHOLD_REMOVED = 'HOUSEHOLD_REMOVED',
  PARTNER_ADDED = 'PARTNER_ADDED',
  PARTNER_CHANGED = 'PARTNER_CHANGED',
  PARTNER_EXPIRED = 'PARTNER_EXPIRED',
  PARTNER_REMOVED = 'PARTNER_REMOVED',
  CLIENT_DATA_JSON_CHANGED = 'CLIENT_DATA_JSON_CHANGED',
  CLIENT_CREATED = 'CLIENT_CREATED',
  CLIENT_CREATION_ALLOWED = 'CLIENT_CREATION_ALLOWED',
  CLIENT_CREATION_WITH_SCANS_ALLOWED = 'CLIENT_CREATION_WITH_SCANS_ALLOWED',
  NAME_CHANGED = 'NAME_CHANGED',
  PESEL_CHANGED = 'PESEL_CHANGED',
  NIP_CHANGED = 'NIP_CHANGED',
  FIRST_NAME_CHANGED = 'FIRST_NAME_CHANGED',
  LAST_NAME_CHANGED = 'LAST_NAME_CHANGED',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  PHONE_CHANGED = 'PHONE_CHANGED',
  OWNER_ID_CHANGED = 'OWNER_ID_CHANGED',
  ACCEPTS_PROCESSING_CHANGED = 'ACCEPTS_PROCESSING_CHANGED',
  ACCEPTS_PROCESSING_INFO = 'ACCEPTS_PROCESSING_INFO',
  ACCEPTS_RECEIVING_INFO_CHANGED = 'ACCEPTS_RECEIVING_INFO_CHANGED',
  ACCEPTS_RECEIVING_OFFERS_CHANGED = 'ACCEPTS_RECEIVING_OFFERS_CHANGED',
  APPLICATION_SETTLED = 'APPLICATION_SETTLED',
  SETTLED = 'SETTLED',
  WON = 'WON',
  ATTACHMENTS_SENT = 'ATTACHMENTS_SENT',
  BANK_SECRECY_ACCEPTED = 'BANK_SECRECY_ACCEPTED',
  BANK_SECRECY_REJECTED = 'BANK_SECRECY_REJECTED',
  BANK_SECRECY_EXPIRED = 'BANK_SECRECY_EXPIRED',
  BANK_SECRECY_EMAIL_SENT = 'BANK_SECRECY_EMAIL_SENT',
  ATTACHMENTS_SENT_FOR_CLIENT = 'ATTACHMENTS_SENT_FOR_CLIENT',
  OFI_EMAIL_SENT = 'OFI_EMAIL_SENT',
  PARTNER_OFI_EMAIL_SENT = 'PARTNER_OFI_EMAIL_SENT',
  RODO_EMAIL_SENT = 'RODO_EMAIL_SENT',
  PARTNER_RODO_EMAIL_SENT = 'PARTNER_RODO_EMAIL_SENT',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  STATUS_UPDATED = 'STATUS_UPDATED',
  USERCOM_DEAL_ATTACHED = 'USERCOM_DEAL_ATTACHED',
  NOTE_CREATED = 'NOTE_CREATED',
  PARTNER_WELCOME_EMAIL_SENT = 'PARTNER_WELCOME_EMAIL_SENT',
  CONTACTED_AGAIN = 'CONTACTED_AGAIN',
  ATTRIBUTES_UPDATED = 'ATTRIBUTES_UPDATED',
  FOCUS_EVENT = 'FOCUS_EVENT',
  PRODUCT_REMOVED = 'PRODUCT_REMOVED',
  RETURNED_TO_CC = 'RETURNED_TO_CC',
  DEAL_TRANSFER = 'DEAL_TRANSFER',
  DEAL_DELEGATED = 'DEAL_DELEGATED',
  CLOSED = 'CLOSED',
  CLIENT_TRANSFER = 'CLIENT_TRANSFER',
  OFFICE_HOURS_UPDATED = 'OFFICE_HOURS_UPDATED',
  OFFICE_ADDRESS_ADDED = 'OFFICE_ADDRESS_ADDED',
  OFFICE_ADDRESS_UPDATED = 'OFFICE_ADDRESS_UPDATED',
  BRANCH_ASSIGNED = 'BRANCH_ASSIGNED',
  BRANCH_UNASSIGNED = 'BRANCH_UNASSIGNED',
  USER_BUSINESS_CARD_URL_CHANGED = 'USER_BUSINESS_CARD_URL_CHANGED',
  CAN_MANAGE_PROSPECTS_CHANGED = 'CAN_MANAGE_PROSPECTS_CHANGED',
  CAN_MANAGE_WIDGETS_CHANGED = 'CAN_MANAGE_WIDGETS_CHANGED',
  CAN_MANAGE_MORTGAGE_CHANGED = 'CAN_MANAGE_MORTGAGE_CHANGED',
  CAN_MANAGE_CASH_CHANGED = 'CAN_MANAGE_CASH_CHANGED',
  CAN_MANAGE_SME_CHANGED = 'CAN_MANAGE_SME_CHANGED',
  CAN_MANAGE_INSURANCE_CHANGED = 'CAN_MANAGE_INSURANCE_CHANGED',
  CAN_MEET_OUTSIDE_OFFICE_CHANGED = 'CAN_MEET_OUTSIDE_OFFICE_CHANGED',
  PROSPECT_DELEGATION_UPDATED = 'PROSPECT_DELEGATION_UPDATED',
  REQUIRE_KNF_QUIZ_CHANGED = 'REQUIRE_KNF_QUIZ_CHANGED',
  REQUIRE_AML_QUIZ_CHANGED = 'REQUIRE_AML_QUIZ_CHANGED',
  REQUIRE_RODO_QUIZ_CHANGED = 'REQUIRE_RODO_QUIZ_CHANGED',
  REQUIRE_CYBER_QUIZ_CHANGED = 'REQUIRE_CYBER_QUIZ_CHANGED',
  REQUIRE_OSH_QUIZ_CHANGED = 'REQUIRE_OSH_QUIZ_CHANGED',
  REQUIRE_ACCREDITATION = 'REQUIRE_ACCREDITATION',
  IS_HEAD_OF_ACCOUNTING_CHANGED = 'IS_HEAD_OF_ACCOUNTING_CHANGED',
  USER_CREATED = 'USER_CREATED',
  USER_ACCREDITED = 'USER_ACCREDITED',
  IS_CHIEF = 'IS_CHIEF',
  IS_HR_LOCKED = 'IS_HR_LOCKED',
  USER_ACTIVATED = 'USER_ACTIVATED',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  USER_REGISTERED = 'USER_REGISTERED',
  LANGUAGE_CHANGED = 'LANGUAGE_CHANGED',
  HAS_ACCESS_TO_SETTLEMENT_VIEW = 'HAS_ACCESS_TO_SETTLEMENT_VIEW',
  AGREEMENT_CHANGED = 'AGREEMENT_CHANGED',
  CLIENT_DELEGATED_SUCCESSFULLY = 'CLIENT_DELEGATED_SUCCESSFULLY',
  CLIENT_DELEGATED_FAILED = 'CLIENT_DELEGATED_FAILED',
  ONET_PREMIUM = 'ONET_PREMIUM',
  REALISATION_AT_UPDATED = 'REALISATION_AT_UPDATED',
  LOAN_AMOUNT_UPDATED = 'LOAN_AMOUNT_UPDATED',
  HAS_ACCESS_TO_LENDI_CARE = 'HAS_ACCESS_TO_LENDI_CARE',
  HAS_ACCESS_TO_LENDI_CARE_LEAD = 'HAS_ACCESS_TO_LENDI_CARE_LEAD',
  REQUIRE_STMT_QUIZ_CHANGED = 'REQUIRE_STMT_QUIZ_CHANGED',
  IS_HR_LOCKED_CHANGED = 'IS_HR_LOCKED_CHANGED',
  IS_ACTION_REQUIRED_LOCKED = 'IS_ACTION_REQUIRED_LOCKED',
  ATTACHMENT_EXPIRED = 'ATTACHMENT_EXPIRED',
  HAS_FILEMANAGER_ACCESS_CHANGED = 'HAS_FILEMANAGER_ACCESS_CHANGED',
}

export enum CRMNotificationType {
  DEAL_STATUS_CHANGED = 'DEAL_STATUS_CHANGED',
  APPLICATION_STATUS_CHANGED = 'APPLICATION_STATUS_CHANGED',
  DEAL_SETTLED = 'DEAL_SETTLED',
  APPLICATION_SEND_TO_VERIFICATION = 'APPLICATION_SEND_TO_VERIFICATION',
  DEAL_DELEGATED = 'DEAL_DELEGATED',
  DEAL_CREATED = 'DEAL_CREATED',
  POST_CREATED = 'POST_CREATED',
  ACTIVITY_CREATED = 'ACTIVITY_CREATED',
  LIVESPACE_PERMISSION_CHANGED = 'LIVESPACE_PERMISSION_CHANGED',
  DEAL_ALL_DOCUMENTS_COLLECTED = 'DEAL_ALL_DOCUMENTS_COLLECTED',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORT_CREATED_FAILED = 'REPORT_CREATED_FAILED',
  DOCUMENTS_CREATED = 'DOCUMENTS_CREATED',
  DOCUMENT_CREATION_FAILED = 'DOCUMENT_CREATION_FAILED',
  DOCUMENTS_SENT_TO_DEVELOPER = 'DOCUMENTS_SENT_TO_DEVELOPER',
  DOCUMENTS_SENDING_TO_DEVELOPER_FAILED = 'DOCUMENTS_SENDING_TO_DEVELOPER_FAILED',
  PRODUCT_REMOVED = 'PRODUCT_REMOVED',
  QUIZ_REPORT_CREATED = 'QUIZ_REPORT_CREATED',
  CLIENT_BIG_APPROVAL_CHANGED = 'CLIENT_BIG_APPROVAL_CHANGED',
  CLIENT_OWNER_CHANGED = 'CLIENT_OWNER_CHANGED',
}
