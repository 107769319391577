import {RouteConfig} from 'vue-router';
import {ProductType} from '@/commons/enums/ProductType';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import EnvironmentProducts, {Product} from '@/env/services/EnvironmentProducts';
import {Routes} from '@/router/Routes';
import {FormSteps} from '@/components/calculator/CalculatorRoutes';
import {createRoutesPerProductTypes} from '@/services/CreateRoutesPerProductTypes';
import AuthService from '@/modules/user/AuthService';
import EnvironmentService from '@/env/EnvironmentService';
const ProvisionList = () => import(/* webpackChunkName: "Provisions" */ '@/modules/provisions/views/ProvisionList.vue');
const ProvisionTools = () => import(/* webpackChunkName: "Provisions" */ '@/modules/provisions/views/ProvisionTools.vue');

function createProvisionRoute(productType: ProductType): RouteConfig {
  const product: Product = EnvironmentProducts().find(x => x.type === productType)!;
  return {
    path: `${productType}`,
    name: `${Routes.PROVISION.LIST(productType)}`,
    component: ProvisionList,
    meta: {
      icon: product.icon,
      title: product.nominative?.male,
      productType,
      minNavigation: true,
    },
  };
}
export const ProvisionsRoutes: RouteConfig[] = [
  {
    path: '/provisions',
    name: 'GENERATE_SETTLEMENTS',
    beforeEnter() {
      window.location.href = `https://crm.qa.lendi.pl/app/settlements/generate-settlements?authToken=${AuthService.token}`;
    },
    meta: {
      icon: 'mdi-arrow-collapse',
      title: 'Generator Zestawień',
      condition: () => EnvironmentService.Environment.isPreprodOrDev,
    },
  },
  {
    path: '/provision-tools',
    name: 'TOOLS',
    component: ProvisionTools,
    meta: {
      icon: 'mdi-wrench',
      title: 'Narzędzia',
    },
  },
  ...createRoutesPerProductTypes(
    [ProductType.MORTGAGE,], // remove cash for production
    createProvisionRoute,),
];
