<template>
  <v-btn
    :outlined="outlined"
    @click="onClick"
    :depressed="depressed"
    :small="small"
    @mouseover="$emit('mouseover', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    :large="large"
    :x-large="xLarge"
    :x-small="xSmall"
    :color="buttonColor"
    :fab="fab"
    :height="height"
    :text="text"
    :href="href"
    :target="target"
    :loading="localLoading"
    :block="block"
    :id="id"
    :type="type"
    :class="{...buttonMergedClasses, 'white--text': primary}"
    :disabled="localLoading || disabled"
  >
    <slot></slot>
  </v-btn>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {VBtn} from 'vuetify/lib';

@Component({
  components: {VBtn,},
})
export default class FpBtn extends Vue {
  @Prop() public click!: () => void;

  @Prop({required: false, }) public readonly id!: string;
  @Prop({required: false, }) public readonly type!: string;

  @Prop({default: false, type: Boolean, }) public readonly text!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly fab!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly disabled!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly error!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly success!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly primary!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly small!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly large!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly xLarge!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly xSmall!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly block!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly loading!: boolean;
  @Prop({default: false, type: Boolean, }) public readonly dashed!: boolean;
  @Prop({type: [String, Number,],}) public readonly height!: boolean;
  @Prop({type: String, }) public readonly color!: string;
  @Prop({type: String, }) public readonly target!: string;
  @Prop({type: String, }) public readonly href!: string;

  @Prop({default: () => {}, })
  public readonly buttonClass!: string;

  public internalLoader: boolean = false;

  get buttonMergedClasses() {
    const classes: Record<string, boolean> = {
      dashed: this.dashed,
    };
    Object.assign(classes, this.buttonClass);
    return classes;
  }

  mounted() {
    this.internalLoader = this.loading;
  }

  async onClick(event: MouseEvent) {
    this.$emit('click', event);
    if (this.click) {
      this.localLoading = true;
      try {
        await this.click();
      } finally {
        this.localLoading = false;
      }
    }
  }

  public get depressed(): boolean {
    return [this.primary, this.success,].includes(true);
  }

  public get outlined(): boolean {
    return ![this.primary, this.success, this.text,].includes(true);
  }

  public get buttonColor(): string | undefined {
    if (this.color) return this.color;
    else if (this.error) return 'error';
    else if (this.success) return 'success';
    else if (this.primary) return 'primary';
    else return undefined;
  }

  private set localLoading(newValue: boolean) {
    this.internalLoader = newValue;
    this.$sync('loading', newValue);
  }

  private get localLoading(): boolean {
    return this.loading || this.internalLoader;
  }
}
</script>

<style scoped lang="scss">
.dashed {
  border-style: dashed!important;
  border-width: 2px !important;
}
</style>
