// @ts-ignore
import * as fuzzy from 'fuzzy.js';

function compareElements(aElement: string, bElement: string) {
  return aElement < bElement
    ? -1 : aElement > bElement
      ? 1 : 0;
}

export function textNormalizer(text: string): string {
  return text
    ? text.trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\u0142/g, 'l')
    : '';
}

export default class StringUtils {
  public static compareString(text: string, query: string = '') {
    return textNormalizer(text).includes(textNormalizer(query));
  }

  public static sortByKey<T extends object>(array: T[], key: keyof T): T[] {
    return array.sort((a, b) => {
      const aElement = textNormalizer(a[key] as any as string);
      const bElement = textNormalizer(b[key] as any as string);
      return compareElements(aElement, bElement);
    });
  }

  public static sortBy<T extends object>(array: T[], func: (k: T) => string): T[] {
    return array.sort((a, b) => {
      const aElement = textNormalizer(func(a));
      const bElement = textNormalizer(func(b));
      return compareElements(aElement, bElement);
    });
  }

  public static comparePaths(path1: string, path2: string) {
    const splitted1 = path1.split('/').length;
    const splitted2 = path2.split('/').length;
    if (splitted1 > splitted2) {
      return 1;
    } else if (splitted1 < splitted2) {
      return -1;
    }
    return path1 < path2 ? -1 : 1;
  }

  public static contains(string: string, array: string[]) {
    return array.map(x => string.includes(x)).includes(true);
  }
}
