<template>
  <v-row class="no-gutters">
    <v-col>
      <template v-if="simulationStore.simulation">
        <v-row v-if="simulationStore.offersCount">
          <v-col class="pa-6">
            <p class="body-1 font-weight-medium mb-0">{{ $i18n.useSimulationFormInfoPanel.SUMMARY }}</p>
            <calculator-summary
                :loading="simulationStore.loading"
                :simulation="simulationStore.simulation"/>
          </v-col>
        </v-row>
        <v-row v-else-if="!simulationStore.offersCount && simulationStore.refusedOffersCount">
          <v-col class="pa-6">
            <p class="body-1 font-weight-medium mb-0 error--text">{{ $i18n.useSimulationFormInfoPanel.NO_AVAILABLE_OFFERS_HEADER }}</p>
            <p class="body-2">{{ $i18n.useSimulationFormInfoPanel.NO_AVAILABLE_OFFERS_SUBTITLE }}</p>
            <p class="body-2 mb-0">{{ $i18n.useSimulationFormInfoPanel.TRY }}</p>
            <ul class="body-2">
              <li>{{ $i18n.useSimulationFormInfoPanel.REDUCE_LOAN_AMOUNT }}</li>
              <li>{{ $i18n.useSimulationFormInfoPanel.REDUCE_LOAN_PERIOD }}</li>
            </ul>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="pa-6">
            <p class="body-1 font-weight-medium mb-0 error--text">{{ $i18n.useSimulationFormInfoPanel.NO_OFFERS_HEADER }}</p>
            <p class="body-2">{{ $i18n.useSimulationFormInfoPanel.NO_OFFERS_SUBTITLE }}</p>
          </v-col>
        </v-row>
      </template>
      <v-row v-else class="pa-6">
        <v-col class="body-1 font-weight-medium">
          {{ $i18n.useSimulationFormInfoPanel.CREATE_SIM_FIRST_WARNING }}
        </v-col>
        <v-col>
          <v-img :src="require('@/assets/calculator/undraw_calculator.svg')"></v-img>
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-if="simulationStore.simulation && topOffers && topOffers.length">
        <v-col class="pa-6">
          <p class="body-1 font-weight-medium">{{ $i18n.useSimulationFormInfoPanel.BEST_OFFERS }}</p>
          <v-row v-for="(offer, index) in topOffers" :key="index">
            <v-col class="align-center d-flex">
              <span>{{ index + 1 }}.</span>
              <v-avatar :size="24" class="mx-2">
                <v-img :src="bank(offer?.info.bank).symbolRequire"></v-img>
              </v-avatar>
                 {{offer.info.bankName}} - {{offer.overview.firstInstallment}} {{ $env.currentCurrency()}}/{{ $i18n.useSimulationFormInfoPanel.MONTHLY_MSC }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { Offer } from '@/models/simulation/Simulation';
import { BankDictionaryElement } from '@/modules/banks/models/BankDictionaryElement';
import { banksDictionary } from '@/commons/dictionaries/BanksDictionary';
import { BankSymbol } from '@/commons/dictionaries/BankSymbol';
import { computed } from 'vue';
import CalculatorSummary from '@/components/calculator/results/CalculatorSummary.vue';
import { useSimulationStore } from '@/components/calculator/services/SimulationStore';
import { useInstance } from '@/composables/useInstance';

const simulationStore = useSimulationStore();
const { $env, } = useInstance();

const topOffers = computed<Nullable<Offer[]>>(() => {
  const TOP_OFFERS_COUNT = 3;
  return simulationStore.simulation?.offers.slice(0, TOP_OFFERS_COUNT) || null;
});

const bank = (bankSymbol: BankSymbol): Nullable<BankDictionaryElement> => {
  const bankDictionaryElement = banksDictionary().find(x => x.type === bankSymbol);
  if (bankDictionaryElement) {
    return bankDictionaryElement;
  } else {
    throw new Error('No bank found for offer ' + bankSymbol);
  }
}
</script>
