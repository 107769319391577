import {FpAxios} from 'fp-components';
import {UiStorageState} from '@/composables/useUiStorage/UiStorageState';

class UiStorageApi {
  private uiStorageAxios = FpAxios.create();
  readonly url = 'users/ui-storage';

  async fetchUiStorageData(): Promise<Nullable<UiStorageState>> {
    const response = await this.uiStorageAxios.get(this.url);
    return response.data;
  }

  async saveUiStorageData(state: Nullable<UiStorageState>): Promise<void> {
    this.uiStorageAxios.put<Nullable<UiStorageState>>(this.url, state);
  }
}

export default new UiStorageApi();
