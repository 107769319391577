import {RouteConfig} from 'vue-router';
import {ProductType} from '@/commons/enums/ProductType';
import {Routes} from '@/router/Routes';
import CashSimulationForm from '@/components/calculator/cash/CashSimulationForm.vue';
import {EmptyTemplateWithRouterView} from '@/router/templates';
import CashSimulationResults from '@/components/calculator/cash/CashSimulationResults.vue';
import EnvironmentProducts from '@/env/services/EnvironmentProducts';
import MortgageSimulationForm from '@/components/calculator/mortgage/MortgageSimulationForm.vue';
import MortgageSimulationResults from '@/components/calculator/mortgage/MortgageSimulationResults.vue';
import {VueConstructor} from 'vue';
import CashSimulationFormStep1 from '@/components/calculator/cash/CashSimulationFormStep1.vue';
import CashSimulationFormStep2 from '@/components/calculator/cash/CashSimulationFormStep2.vue';
import MortgageSimulationFormStep1 from '@/components/calculator/mortgage/MortgageSimulationFormStep1.vue';
import SimulationFormStep4 from '@/components/calculator/form/SimulationFormStep4.vue';
import MortgageSimulationFormStep2 from '@/components/calculator/mortgage/MortgageSimulationFormStep2.vue';
import CashSimulationFormStep3 from '@/components/calculator/cash/CashSimulationFormStep3.vue';
import MortgageSimulationFormStep3 from '@/components/calculator/mortgage/MortgageSimulationFormStep3.vue';
import AuthService from '@/modules/user/AuthService';
import CalculatorNavigation from '@/components/calculator/CalculatorNavigation.vue';
import {useCalculatorVersion} from '@/components/calculator/useCalculatorVersion';
import {NavigationGuard} from 'vue-router/types/router';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {DashboardKey} from '@/modules/onboarding/tours/enums/DashboardKey';
import {createRoutesPerProductTypes} from '@/services/CreateRoutesPerProductTypes';

export enum FormSteps {
  CREDIT_PARAMETERS = 1,
  BORROWERS = 2,
  INCOME = 3,
  LIABILITIES = 4
}
// @ts-ignore
const components: PartialDeep<Record<ProductType, Record<number, VueConstructor>>> = {
  [ProductType.CASH]: {
    [FormSteps.CREDIT_PARAMETERS]: CashSimulationFormStep1,
    [FormSteps.BORROWERS]: CashSimulationFormStep2,
    [FormSteps.INCOME]: CashSimulationFormStep3,
    [FormSteps.LIABILITIES]: SimulationFormStep4,
  },
  [ProductType.MORTGAGE]: {
    [FormSteps.CREDIT_PARAMETERS]: MortgageSimulationFormStep1,
    [FormSteps.BORROWERS]: MortgageSimulationFormStep2,
    [FormSteps.INCOME]: MortgageSimulationFormStep3,
    [FormSteps.LIABILITIES]: SimulationFormStep4,
  },
};

function createRoute(productType: ProductType): RouteConfig {
  const product = EnvironmentProducts().find(x => x.type === productType)!;
  return {
    path: productType,
    name: `${Routes.NEW_CALCULATOR.REDIRECT(productType)}`,
    redirect: {name: Routes.NEW_CALCULATOR.FORM(productType, FormSteps.CREDIT_PARAMETERS),},
    meta: {
      icon: product.icon,
      title: product.nominative?.female,
      minNavigation: true,
      condition: () => AuthService?.permitSimulation(productType) && useCalculatorVersion().isNewCalculatorAvailable(),
    },
  };
}

function createFormRoute(type: ProductType, step: FormSteps): RouteConfig {
  return {
    props: true,
    path: String(step),
    name: Routes.NEW_CALCULATOR.FORM(type, step),
    component: components[type]![step]! as VueConstructor,
    meta: {
      stepperValue: step,
    },
  };
};

const calculatorGuardForType = (type: ProductType): NavigationGuard => (to, from, next) => {
  if (!AuthService.isLoggedIn) {
    next({name: Routes.PASSWORD_MANAGEMENT.LOGIN,});
  } else {
    const conditionsByType: Partial<Record<ProductType, boolean>> = {
      [ProductType.MORTGAGE]: Boolean(AuthService.User!.privileges.mortgageLoansDisabled),
      [ProductType.CASH]: Boolean(AuthService.User!.privileges.cashLoansDisabled),
    };
    if (conditionsByType[type] && useCalculatorVersion().isNewCalculatorAvailable()) {
      next({name: Routes.DASHBOARD,});
    } else {
      next();
    }
  }
};

const CalculatorRoutes: RouteConfig[] = [
  {
    path: 'calculator-2',
    component: {
      template: EmptyTemplateWithRouterView(),
    },
    children: createRoutesPerProductTypes([ProductType.MORTGAGE, ProductType.CASH,], createRoute),
    meta: {
      icon: 'mdi-calculator-variant-outline',
      title: () => I18NGetter().PlatformSection.calculator_v2,
      key: DashboardKey.COMPARISON_TOOL,
      mode: 'lendi',
      condition: () => {
        const privileges = AuthService.User!.privileges;
        return (!privileges.mortgageLoansDisabled || !privileges.cashLoansDisabled) &&
          useCalculatorVersion().isNewCalculatorAvailable();
      },
      badge: {
        is: true,
        text: 'beta',
      },
    },
  },
  {
    path: ProductType.MORTGAGE,
    components: {
      default: {
        template: EmptyTemplateWithRouterView(),
      },
      navigationBar: CalculatorNavigation,
    },
    children: [
      {
        path: '',
        component: MortgageSimulationForm,
        props: true,
        children: [
          createFormRoute(ProductType.MORTGAGE, FormSteps.CREDIT_PARAMETERS),
          createFormRoute(ProductType.MORTGAGE, FormSteps.BORROWERS),
          createFormRoute(ProductType.MORTGAGE, FormSteps.INCOME),
          createFormRoute(ProductType.MORTGAGE, FormSteps.LIABILITIES),
        ],
        beforeEnter: calculatorGuardForType(ProductType.MORTGAGE),
      },
      {
        path: 'calculator-summary/:simulationId',
        name: Routes.NEW_CALCULATOR.SUMMARY(ProductType.MORTGAGE),
        component: MortgageSimulationResults,
        beforeEnter: calculatorGuardForType(ProductType.MORTGAGE),
      },
    ],
  },
  {
    path: ProductType.CASH,
    components: {
      default: {
        template: EmptyTemplateWithRouterView(),
      },
      navigationBar: CalculatorNavigation,
    },
    children: [
      {
        path: '',
        component: CashSimulationForm,
        props: true,
        children: [
          createFormRoute(ProductType.CASH, FormSteps.CREDIT_PARAMETERS),
          createFormRoute(ProductType.CASH, FormSteps.BORROWERS),
          createFormRoute(ProductType.CASH, FormSteps.INCOME),
          createFormRoute(ProductType.CASH, FormSteps.LIABILITIES),
        ],
        beforeEnter: calculatorGuardForType(ProductType.CASH),
      },
      {
        path: 'calculator-summary/:simulationId',
        name: Routes.NEW_CALCULATOR.SUMMARY(ProductType.CASH),
        component: CashSimulationResults,
        beforeEnter: calculatorGuardForType(ProductType.CASH),
      },
    ],
  },
];

export default CalculatorRoutes;
