<template>
    <v-layout>
        <fp-select
                outlined
                :prepend-icon="prependIcon"
                return-object
                :clearable="false"
                :disabled="disabled"
                class="fp-input-outline country-picker shrink pr-0"
                :class="{'filled-phone': !!phoneNumberObject.phoneNumber}"
                :items="countries"
                :value="countryCode"
                :search-input="searchInput"
                @update:search-input="onSearchInput($event)"
                @input="setMaskAndCountry"
                item-name="name_pl"
                item-key="type"
                :id="selectId"
                v-omit-input-element>
            <template v-slot:item="{item}">
                {{getFlagEmoji(item.type)}}
                <span>+{{item.callingCode}} <span class="text--secondary">{{item.name_pl}}</span></span>
            </template>
            <template v-slot:selection="{item}">
                <v-row no-gutters class="flex-nowrap">
                    <v-col v-if="$vuetify.breakpoint.smAndUp">
                        {{countryFlag}}
                    </v-col>
                    <v-col>
                        +{{searchInputNumberDisplay.length ? searchInputNumberDisplay : item.callingCode}}
                    </v-col>
                </v-row>
            </template>
        </fp-select>
        <fp-input
                required
                :disabled="disabled"
                class="phone-input pl-0"
                @input="emitValue"
                @change="$emit('change', $event)"
                @blur="$emit('blur', $event)"
                @paste="onPaste"
                :custom-rules="customPhoneRules"
                :placeholder="phoneNumberMask"
                :label="label"
                :id="inputId"
                :clearable="false"
                v-model="phoneNumberObject.phoneNumber"
                :errorMessages="errorMessages"
                :mask="phoneNumberMask">
            <template #message v-if="$scopedSlots.customErrorAction">
                <slot name="customErrorAction"/>
            </template>
        </fp-input>
    </v-layout>
</template>

<script setup lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getExampleNumber, isValidPhoneNumber} from 'libphonenumber-js/max';
import {debounce} from 'lodash-es';
import {CountryCode} from 'libphonenumber-js/types';
import {VAvatar, VCol, VLayout, VRow} from 'vuetify/lib';
import {
  IMultiformNationalityDictionary,
  IMultiformNationalityDictionaryElement
} from '@/modules/multiForm/shared/IMultiformDictionary';
import MultiformDictionary from '@/modules/multiForm/shared/MultiformDictionaries';
import EnvironmentService from '@/env/EnvironmentService';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {PhoneNumberObject} from '@/models/PhoneNumberObject';
import { computed, onMounted, ref, watch } from 'vue';

const exampleNumbers = require('libphonenumber-js/examples.mobile.json');

type ValidationRule = ((v: string) => string | boolean)

const props = withDefaults(defineProps<{
  prependIcon?: string;
  label?: string;
  inputId?: string;
  selectId?: string;
  value?: string;
  errorMessages?: string;
  disabled?: boolean;
}>(), {
  prependIcon: '',
  label: () => I18NGetter().fpComponents.PHONE_INPUT_LABEL,
  value: '',
  errorMessages: '',
  disabled: false,
});
const emit = defineEmits(['input', 'change', 'blur',]);

const phoneNumberObject = ref(new PhoneNumberObject(props.value));
const phoneNumberMask = ref<Nullable<string>>(null);
const searchInput = ref('');
const isNewQuery = ref(false);

watch(() => props.value, () => {
  if (props.value) {
    phoneNumberObject.value.phoneNumber = props.value.slice(phoneNumberObject.value.callingCode!.length + 1);
  } else {
    phoneNumberObject.value.phoneNumber = null;
  }
});

const countryFlag = computed(() => {
  return getFlagEmoji(countryCode.value || EnvironmentService.Environment.getAppDomainConfig().region);
});

const countryCode = computed(() => {
  return phoneNumberObject.value?.type || null;
});

function getFlagEmoji(countryCode: CountryCode): string {
  return String.fromCodePoint(...[...countryCode.toUpperCase(),].map(x => 0x1f1a5 + x.charCodeAt(0)));
}

const isValidNumber = computed(() => {
  if (finalValue.value) {
    return !phoneNumberMask.value || isValidPhoneNumber(finalValue.value);
  } else return false;
});

const countries = computed(() => {
  if (EnvironmentService.Environment.getAppDomainConfig().availablePhonePrefixesCountryCodes.length) {
    return MultiformDictionary.countriesDictionary
      .filter(country => EnvironmentService.Environment.getAppDomainConfig().availablePhonePrefixesCountryCodes.includes(country.type))
  }
  return MultiformDictionary.countriesDictionary;
});

const getDebounce = debounce(() => {
  isNewQuery.value = true;
}, 2000);

function onSearchInput(event: string) {
  if (event && event.length >= searchInput.value.length) {
    if (isNewQuery.value) {
      searchInput.value = event.slice(-1);
      isNewQuery.value = false;
    } else {
      searchInput.value = event;
    }
  } else {
    searchInput.value = '';
  }
  return getDebounce();
}

const searchInputNumberDisplay = computed(() => {
  return searchInput.value.replace(/[^\d]/g, '');
});

const valid = computed(() => {
  return phoneNumberObject.value.phoneNumber?.length === maskLength.value;
});

const finalValue = computed(() => {
  return phoneNumberObject.value.callingCode && phoneNumberObject.value.phoneNumber
    ? `+${phoneNumberObject.value.callingCode}${phoneNumberObject.value.phoneNumber}`
    : null;
});

onMounted(() => {
  setDefaultMask();
});

function setDefaultMask(): void {
  const defaultCountry = countries.value[0];
  setMask(defaultCountry);
}

function setMaskAndCountry(event: IMultiformNationalityDictionaryElement<CountryCode>): void {
  if (event) {
    setMask(event);
    phoneNumberObject.value.callingCode = event.callingCode;
    phoneNumberObject.value.type = event.type;
    phoneNumberObject.value.flag = event.flag;
    phoneNumberObject.value.phoneNumber = null;
    emitValue();
  } else {
    console.warn(I18NGetter().fpComponents.PHONE_SET_MASK_WARN);
  }
}

function setMask(event: IMultiformNationalityDictionaryElement<CountryCode>) {
  const number = getExampleNumber(event?.type, exampleNumbers);
  if (number) {
    // Array of Countries with mask. TODO: W przyszłości to trzeba wyciągnąć do appDomainConfig
    const maskedCountries: CountryCode[] = ['PL', 'RO',];
    if (maskedCountries.includes(event.type)) {
      // Create mask format from example national phone number
      phoneNumberMask.value = number.formatNational()
        .replace(/^0/, '')
        .replace(/[()]/g, '')
        .replace('-', '')
        .replace(/[\d]/g, '#');
    } else {
      phoneNumberMask.value = null;
    }
  }
}
const customPhoneRules = computed((): ValidationRule[] => {
  return [
    (v: string) => {
      const ERROR_TEXT = I18NGetter().fpComponents.PHONE_CUSTOM_RULES_MSG;
      return isValidNumber.value || props.errorMessages || ERROR_TEXT;
    },
  ];
});

const maskLength = computed(() => {
  return phoneNumberMask.value?.replace(/ /g, '').length || 0;
});

function emitValue() {
  emit('input', finalValue.value);
}

function onPaste(event: ClipboardEvent) {
  try {
    event.stopPropagation();
    event.preventDefault();
    const paste = (event.clipboardData || (window as any).clipboardData).getData('text');
    phoneNumberObject.value = new PhoneNumberObject(paste, countryCode.value);
    const countryElementFound = countries.value.find(x => x.type === countryCode.value);
    setMask(countryElementFound!);
    emitValue();
  } catch (e) {
    console.error(e)
    console.warn('Occurred error when parsing pasted phone number');
  }
}

// Directive
const vOmitInputElement = {
  mounted: (el: HTMLElement) => {
    el.querySelector('input')!.setAttribute('tabindex', '-1');
  },
};

defineExpose({
  validate: () => {
    return isValidNumber.value;
  },
});
</script>

<style lang="scss">
.country-picker:not(.filled-phone) {
  fieldset {
    border-color: fpShadow(.12);
  }
}
.country-picker {
  fieldset {
    border-radius: 4px 0 0 4px !important;
  }
  ::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
  input {
    font-size: 0;
  }
}
.phone-input{
  fieldset {
    border-left: none;
    border-radius: 0 4px 4px 0 !important;
  }
}
</style>
