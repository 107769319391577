import {RouteConfig} from 'vue-router';
import { Routes } from '@/router/Routes';

const StatementRoutes: RouteConfig = {
  path: 'statements_test',
  component: () => import('../views/StatementsNavigation.vue'),
  redirect: '/statements_test/start',
  children: [
    {
      path: 'start',
      name: Routes.STATEMENTS_TEST.START_STATEMENTS_TEST,
      component: () => import('../views/StatementsQuiz.vue'),
    },
  ],
}

export default StatementRoutes;
