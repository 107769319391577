import {IPerson} from '@/models/interfaces/IPerson';
import {PersonalId} from '@/models/Client';
import {AccreditationRepresentantDto} from '@/modules/accredition/models/backend/AccreditationRepresentantDto';
import MultiformDictionaries from '@/modules/multiForm/shared/MultiformDictionaries';
import {IMultiformDictionary, IMultiformIconDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';
import {AccreditationStep} from '@/commons/enums/AccreditationStep';

export interface AccreditationExpertDto extends
  IPerson {
  isChiefOfDivision: boolean;
  isSelfEmployed: boolean;
}

export enum InvitationType {
  CONTRACT = 'contract',
  SOLE_PROPRIETORSHIP ='sole_proprietorship',
  CHIEF = 'chief',
}
export enum InvitationStatus {
  CREATED = 'created',
  SENT ='sent',
  JOINED ='joined',
}

export const InvitationTypeDictionary: IMultiformDictionary<InvitationType> = [
  {
    type: InvitationType.CONTRACT,
    name_pl: 'Wypłata poprzez umowę o pracę/zlecenie',
  },
  {
    type: InvitationType.SOLE_PROPRIETORSHIP,
    name_pl: 'Wypłata poprzez działalność gospodarczą Eksperta',
  },
];

export const InvitationStatusDictionary: IMultiformIconDictionary<InvitationStatus> = [
  {
    type: InvitationStatus.SENT,
    name_pl: 'Zaproszenie wyslane',
    color: 'warning',
    icon: 'mdi-send-check',
  },
  {
    type: InvitationStatus.JOINED,
    name_pl: 'Dołączył do zespołu',
    color: 'success',
    icon: 'mdi-account-check-outline',
  },
];

export class AccreditationInvitation implements NullableDeep<AccreditationExpertDto> {
  id?: number;
  phone: Nullable<string> = null;
  email: Nullable<string> = null;
  firstName: Nullable<string> = null;
  lastName: Nullable<string> = null;
  pesel: Nullable<string> = null;
  isChiefOfDivision: boolean = false;
  invitationType: InvitationType = InvitationType.SOLE_PROPRIETORSHIP;
  isSelfEmployed: boolean = false;
  status?: InvitationStatus;
  divisionName?: string;
  companyId?: number;
  userId?: number;
  step?: AccreditationStep;

  constructor(dto?: Partial<AccreditationInvitation>) {
    if (dto) {
      Object.assign(this, dto);
    }
  }
}
