import { Privileges } from '@/models/user/Privileges';
import User from '@/models/user/User';
import { ProductType } from '@/commons/enums/ProductType';
import AuthService from '@/modules/user/AuthService';
import { Quizzes } from '@/models/user/Quizzes';
import { keys as LodashKeys } from 'underscore';
import { isEqual } from 'lodash-es';

const EMPTY_VALUE_INDEX = -1 as const;

export function prepareUserFormToSend(userBeforeChanges: User, userToSend: User): FormData {
  userToSend.pesel = userToSend.pesel || '';
  userToSend.url = userToSend.url || '';

  const formToSend: FormData = new FormData();

  const userKeys: (keyof User)[] = [
    'firstName',
    'lastName',
    'phone',
    'email',
    'pesel',
    'url',
    'branchId',
    'businessCardVisible',
    'isContractOfMandate',
    'contractOfMandateStartsAt',
    'divisions',
    'isChief',
    'applyCvrSortingForStarters',
  ];

  const productKeys = [
    ProductType.MORTGAGE,
    ProductType.CASH,
    ProductType.SME,
    ProductType.REAL_ESTATE,
    ProductType.INSURANCE,
  ];
  const PRODUCT_TYPE_PREFIX = 'productType';
  const CAN_MANAGE_PREFIX = 'canManage';

  const privilegesKeys: (keyof Privileges)[] = [
    'isActionRequiredLocked',
    'canMeetOutsideOffice',
    'requireAccreditation',
    'isHrLocked',
    'hasAccessToRaiffeisen',
    'hasAccessToSettlementView',
    'hasAccessToCustomLogo',
  ];

  const quizKeys = LodashKeys(new Quizzes()) as Array<keyof Quizzes>;

  if (AuthService.User && (AuthService.User.isAdmin || AuthService.User.isVerifier)) {
    privilegesKeys.push('canManageProspects');
    privilegesKeys.push('isBetaTester');
    if (userBeforeChanges.privileges.canManage.widgets !== userToSend.privileges.canManage.widgets) {
      formToSend.append('canManageWidgets', String(userToSend.privileges.canManage.widgets));
    }
    if (userBeforeChanges.privileges.canManage.trainings !== userToSend.privileges.canManage.trainings) {
      formToSend.append('canManageTrainings', String(userToSend.privileges.canManage.trainings));
    }
  }

  if (AuthService.User && AuthService.User.isAdmin) {
    userKeys.push('hasAccessToOldLendi');
    privilegesKeys.push('isHeadOfAccounting');
    if (userBeforeChanges.role.id !== userToSend.role.id) {
      formToSend.append('role', String(userToSend.role.id));
    }
  }

  userKeys.map((key) => {
    if (!isEqual(userBeforeChanges[key], userToSend[key])) {
      const changedProp = userToSend[key];
      if (changedProp && Array.isArray(changedProp)) {
        if (changedProp.length) {
          changedProp.map((item: number | string | boolean | object) => {
            formToSend.append(key, String(item));
          });
        } else {
          formToSend.append(key, String(EMPTY_VALUE_INDEX));
        }
      } else if (changedProp && changedProp instanceof Date) {
        formToSend.append(key, changedProp.toISOString());
      } else if (userToSend[key] !== '') {
        formToSend.append(key, String(userToSend[key]));
      }
    }
  });

  productKeys.map((key) => {
    if (userBeforeChanges.privileges.canManage[key] !== userToSend.privileges.canManage[key]) {
      const formValue = String(userToSend.privileges.canManage[key]);
      const formKey = key.replace(PRODUCT_TYPE_PREFIX, CAN_MANAGE_PREFIX);
      formToSend.append(formKey, formValue);
    }
  });

  privilegesKeys.map((key) => {
    if (userBeforeChanges.privileges[key] !== userToSend.privileges[key]) {
      formToSend.append(key, String(!!userToSend.privileges[key]));
    }
  });

  quizKeys.map((key) => {
    if (userBeforeChanges.quiz![key] !== userToSend.quiz![key]) {
      const keyToSend = `${key}Quiz`;
      formToSend.append(keyToSend, String(userToSend.quiz![key]));
    }
  });

  return formToSend;
}
