import {LendiAutocompletePropsType} from '@/components/inputs/autocompletes/LendiAutocompletePropsType';
import {computed, ref, Ref, SetupContext, h, onMounted, watch, getCurrentInstance} from 'vue';
import {isUndefined, uniqBy, isNull, cloneDeep} from 'lodash-es';
import {BaseFilter, DEFAULT_ROWS_PER_PAGE} from '@/models/Filter';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {VAvatar, VChip, VImg} from 'vuetify/lib/components';

const REF_ID = 'autocomplete';
const DEFAULT_PAGE = 1;
const DEFAULT_CHIP_TEXT_PROP = 'text';
export type FetchData<T> = (search: string, page?: number) => Promise<T[]>
export type DataTableElement = {
  id?: number;
}

function useLendiAutocomplete<T extends DataTableElement>(
  props: LendiAutocompletePropsType<T>,
  context: SetupContext,
  fetchData: FetchData<T>) {
  const instance = getCurrentInstance();
  const loading: Ref<boolean> = ref(true);
  const isBufferEnd: Ref<boolean> = ref(false);
  const search: Ref<string> = ref('');
  const items: Ref<T[]> = ref([]);
  const page: Ref<number> = ref(DEFAULT_PAGE);

  const loadItems = async(page: number = DEFAULT_PAGE) => {
    try {
      const response = await fetchData(search.value, page);
      const isNotFirstPage: boolean = page > DEFAULT_PAGE;
      if (isNotFirstPage) {
        if (response.length < DEFAULT_ROWS_PER_PAGE) {
          isBufferEnd.value = true;
        }
        items.value.push(...response);
      } else {
        items.value = response;
      }
    } finally {
      loading.value = false;
    }
  };

  const uniqItems = computed(() => {
    return uniqBy(props.existingItems.concat(items.value), 'id');
  });

  onMounted(() => {
    setTimeout(() => {
      if (props.autoFocus) {
        const ref: any = instance?.proxy.$refs[REF_ID];
        ref.focus();
      }
    }, 50);
  });

  function resetPagination() {
    page.value = DEFAULT_PAGE;
    isBufferEnd.value = false;
  }

  const emitValue = (value: Nullable<number> | Array<number>) => {
    context.emit('input', value);
    if (props.multiple && Array.isArray(value)) {
      const existingItems = value.map(x => uniqItems.value.find(item => item.id === x));
      context.emit('update:existingItems', existingItems);
    }
    if (props.clearAfterSelect) {
      search.value = '';
    }
  };

  const rules = computed(() => {
    return [
      (v: any) => {
        return props.required
          // Upewnić się jak poniższy temat działa na String
          ? (!isNaN(v) && !isUndefined(v) && !isNull(v)) || I18NGetter().VALIDATION.NEW_TRANSLATION_REQUIRED
          : true;
      },
    ];
  });

  const removeUser = (item: number) => {
    if (props.multiple && Array.isArray(props.value)) {
      emitValue(props.value.filter(id => id !== item));
    } else {
      emitValue(null);
    }
  };
  const render = (optionsExtend: Record<string, any>) => h('v-autocomplete', {
    class: 'l-autocomplete',
    on: {
      copy: () => {
        // TODO: wyciągnąć te eventy do useClipboard
        const clipboardData = {
          value: props.value,
          existingItems: props.existingItems,
        };
        instance?.proxy.$clipboard(JSON.stringify(clipboardData));
      },
      paste: (e: ClipboardEvent) => {
        // TODO: wyciągnąć te eventy do useClipboard
        const value = e.clipboardData?.getData('text');
        try {
          const parsedValue = value ? JSON.parse(value) : undefined;
          if (parsedValue && Array.isArray(parsedValue.existingItems) && Array.isArray(parsedValue.value)) {
            e.preventDefault();
            parsedValue.value.forEach((x: number | undefined) => {
              if (typeof x === 'number' && !(props.value as any).includes(x)) {
                (props.value as any).push(x);
              }
            });
            instance?.proxy.$snackbarService.openSuccessSnackbar(I18NGetter().useLendiAutocomplete.PASTED_EXPERTS_DATA);
          }
        } catch (e) {
          console.warn(e);
        }
      },
      input: emitValue,
      'update:search-input': async(event: string) => {
        resetPagination();
        search.value = event;
        loadItems();
      },
      'click:clear': async(event: string) => {
        context.emit('click:clear', event);
      },
    },
    props: {
      // props
      value: props.value,
      disabled: props.disabled,
      chips: props.multiple,
      multiple: props.multiple,
      clearable: true,
      menuProps: {closeOnContentClick: !props.multiple,},

      // computed
      loading: loading.value,
      items: uniqItems.value,
      rules: rules.value,
      searchInput: search.value,

      // static
      ref: REF_ID,
      itemValue: 'id',
      color: 'primary',
      deletableChips: true,
      outlined: true,
      hideDetails: 'auto',

      // variable per autocomplete
      ...optionsExtend,
    },
    scopedSlots: {
      // @ts-ignore
      selection: (element) => h(VChip, {
        on: {
          click: (event: PointerEvent) => {
            if (props.onChipClick) {
              props.onChipClick(element.item);
              event?.stopPropagation();
            }
          },
          'click:close': () => {
            context.emit('click:close', element.item);
            removeUser(element.item.id);
          },
        },
        props: {
          close: true,
          large: true,
        },
      },
      [
        element.item.photoUrl && h(VAvatar, {props: {left: true,},}, [
          h(VImg, {
            attrs: {
              width: 32,
              height: 32,
              src: element.item.photoUrl,
            },
          }),
        ]),
        element.item[(props.itemText || optionsExtend.itemText || DEFAULT_CHIP_TEXT_PROP)] || '',
      ]),
    },
  }, [
    h('div' , {
      slot: 'append-item',
      directives: [{
        name: 'intersect',
        value: (el: IntersectionObserverEntry[], observer: IntersectionObserver, value: boolean) => {
          if (value && !isBufferEnd.value) {
            page.value++;
            loadItems(page.value);
          }
        },
      },],
    }),
  ]);
  watch(() => cloneDeep(props.filter), (newValue: BaseFilter) => {
    search.value = '';
    loadItems();
  });

  return {
    loading,
    search,
    items,
    loadItems,
    rules,
    removeUser,
    render,
    page,
  };
}

export {useLendiAutocomplete};
