import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import moment from 'moment';
import Vue from 'vue';
import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {reducedJsonDictEN} from '@/services/enumTranslator/langDictionaries/getDictEN';
import {reducedJsonDictRO} from '@/services/enumTranslator/langDictionaries/getDictRO';
import {reducedJsonDictPL} from '@/services/enumTranslator/langDictionaries/getDictPL';
import {reducedJsonDictUK} from '@/services/enumTranslator/langDictionaries/getDictUK';
import {reducedJsonDictDE} from '@/services/enumTranslator/langDictionaries/getDictDE';
import EnvironmentService from '@/env/EnvironmentService';

const STORAGE_SYSTEM_LANG_KEY = 'STORAGE_SYSTEM_LANG_KEY';
const getStorage = () => typeof localStorage !== 'undefined' ? localStorage : undefined;

class I18nClass {
  dictionaries: Record<SystemLanguage, I18NInterface> = {
    PL: reducedJsonDictPL,
    EN: reducedJsonDictEN,
    RO: reducedJsonDictRO,
    UK: reducedJsonDictUK,
    DE: reducedJsonDictDE,
  };

  systemLanguage: SystemLanguage = getStorage()?.getItem(STORAGE_SYSTEM_LANG_KEY) as SystemLanguage ||
    this.defaultLanguage;

  get defaultLanguage(): SystemLanguage {
    return this.systemLanguageFromExplorer || SystemLanguage.EN;
  }

  get systemLanguageFromExplorer(): SystemLanguage | undefined {
    const navigatorLanguage: string = navigator.language;
    if (navigatorLanguage.includes('pl')) return SystemLanguage.PL;
    if (navigatorLanguage.includes('en')) return SystemLanguage.EN;
    if (navigatorLanguage.includes('ro')) return SystemLanguage.RO;
    if (navigatorLanguage.includes('uk')) return SystemLanguage.UK;
    if (navigatorLanguage.includes('de')) return SystemLanguage.DE;
    return undefined;
  }

  get dict(): I18NInterface {
    return this.dictionaries[this.systemLanguage] ?? this.dictionaries[this.defaultLanguage];
  }

  setSystemLanguage(newSystemLang: SystemLanguage): void {
    if (!Object.keys(this.dictionaries).includes(newSystemLang)) {
      this.setSystemLanguage(EnvironmentService.Environment.getAppDomainConfig().defaultSystemLanguage);
      return;
    }
    this.systemLanguage = newSystemLang;
    moment.locale(newSystemLang.toLocaleLowerCase());
    if (Vue.prototype.$vuetify) {
      Vue.prototype.$vuetify.lang.current = newSystemLang.toLocaleLowerCase();
    }
    localStorage.setItem(STORAGE_SYSTEM_LANG_KEY, newSystemLang);
  };
}

export const I18N = new I18nClass();
