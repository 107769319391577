<template>
  <v-list-item
    v-if="!isChildrenExist"
    :to="to"
    active-class="text-bearly-white primary"
    @click.prevent="action ? action() : () => {}"
  >
    <slot name="title" v-if="$scopedSlots.title" />
    <template v-else>
      <v-list-item-action>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-list-item>
  <v-list-group
    v-else
    active-class="text-bearly-white"
    class="list-line-decorator"
  >
    <template v-slot:activator>
      <v-list-item-action>
        <v-badge
          :value="badgeText"
          :content="badgeText"
          class="discount-badge"
          color="error"
          overlap
        >
          <v-icon>{{ icon }}</v-icon>
        </v-badge>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-for="(child, index) in children">
      <v-list-item
        v-if="childCondition(child)"
        :class="{'active': isSameRouteAsChildCondition(child)}"
        @click="$emit('child-click', child)" :key="index"
      >
        <slot name="child" :child="child" />
      </v-list-item>
    </template>
  </v-list-group>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import {RouteConfig} from 'vue-router/types/router';
type Tile = RouteConfig;

@Component
export default class LendiNavigationTile extends Vue {
  @Prop({required: true, type: String,})
  readonly title!: string;

  @Prop({required: true, type: String,})
  readonly icon!: string;

  @Prop({required: false, type: String,})
  readonly badgeText!: string;

  @Prop({required: false, default: undefined,})
  readonly to!: any;

  @Prop({required: false, default: undefined,})
  readonly action!: any;

  @Prop({required: false, type: Array,})
  readonly children?: Tile[];

  @Prop({required: false, type: Function, default: () => () => true,})
  readonly childCondition!: (child: Tile) => boolean;

  @Prop({required: false, type: Function, default: () => () => true,})
  readonly isSameRouteAsChildCondition!: (child: RouteConfig) => boolean;

  get isChildrenExist() {
    return this.children && this.children.length > 0;
  }
}
</script>
<style scoped lang="scss">
.active {
  background: $primary !important;
}
:deep(.v-list-item__title) {
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
