import { StandardLonghandPropertiesHyphen, StandardShorthandPropertiesHyphen } from 'csstype';

export class TransitionParams {
  callback: Function = () => {};
  rootElementSelector: string = 'body';
  duration: number = 500;
  properties: Array<keyof StandardLonghandPropertiesHyphen | keyof StandardShorthandPropertiesHyphen> = ['all',];

  constructor(transition?: Partial<TransitionParams>) {
    if (transition) {
      Object.assign(this, transition);
    }
  }
};
