<template>
  <v-layout wrap>
    <fp-loader v-if="value === null"></fp-loader>
    <v-flex v-else>
      <land-of-work-form :user-lands-data.sync="value"></land-of-work-form>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import LandOfWorkForm from '@/modules/user/editUser/LandOfWorkForm.vue';
import {LandOfWork} from '@/models/LandOfWork';

@Component({
  components: {LandOfWorkForm, },
})
export default class EditUserWorkData extends Vue {
  @Prop()
  public readonly value!: Nullable<LandOfWork[]>;
}
</script>

<style scoped lang="scss">

</style>
