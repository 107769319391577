import {IMultiformIconDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';
import {ActivityKind} from '@/modules/activities/enums/ActivityKind';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
export const ActivityKindsDictionary: IMultiformIconDictionary<ActivityKind> = [
  {
    icon: 'mdi-coffee-outline',
    name_pl: I18NGetter().useActivityDictionary.MEETING,
    type: ActivityKind.MEETING,
  },
  {
    icon: 'mdi-email-outline',
    name_pl: I18NGetter().useActivityDictionary.EMAIL,
    type: ActivityKind.EMAIL,
  },
  {
    icon: 'mdi-phone-outline',
    name_pl: I18NGetter().useActivityDictionary.PHONE,
    type: ActivityKind.PHONE,
  },
  {
    icon: 'mdi-ferry',
    name_pl: I18NGetter().useActivityDictionary.VACATION,
    type: ActivityKind.VACATION,
  },
  {
    icon: 'mdi-file-document-edit-outline',
    name_pl: I18NGetter().useActivityDictionary.SIGNING_DOCUMENTS,
    type: ActivityKind.SIGNING_DOCUMENTS,
  },
  {
    icon: 'mdi-dots-horizontal',
    name_pl: I18NGetter().useActivityDictionary.OTHER,
    type: ActivityKind.OTHER,
  },
];
