import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const AmlNavigation = () => import(/* webpackChunkName: "AML" */ '@/modules/aml/views/AmlNavigation.vue');
const StartAmlTest = () => import(/* webpackChunkName: "AML" */ '@/modules/aml/views/StartAmlTest.vue');

const AmlRoutes: RouteConfig =
  {
    path: 'aml_test',
    redirect: '/aml_test/start',
    component: AmlNavigation,
    children: [
      {
        name: Routes.AML_TEST.START_AML_TEST,
        path: 'start',
        component: StartAmlTest,
      },
    ],
  };

export default AmlRoutes;
