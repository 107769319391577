import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
const IssuesList = () => import(/* webpackChunkName: "Issues" */ '@/modules/issues/views/issues-list/IssuesList.vue');

const IssuesRoutes: Array<RouteConfig> = [
  {
    path: 'issues',
    name: Routes.ISSUES.ISSUES,
    component: IssuesList,
    meta: {
      title: I18NGetter().KnowledgeBaseSection.product_knowledge,
      icon: 'mdi-bank',
    },
  },
  {
    path: 'issues/:category/:query',
    name: Routes.ISSUES.ISSUES_QUERY,
    component: IssuesList,
  },
];

export default IssuesRoutes;
