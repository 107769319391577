<template>
  <v-layout wrap>
    <v-flex class="px-0">
      <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep3.INCOME_NUMBER }} {{incomeNumber}}: {{getIncomeNameByType(income.incomeTypeId)}}</p>
      <template v-if="shouldDisplayFormTypeOf(IncomeFormType.DEFAULT_INCOME_TYPE_FORM)">
        <fp-input
          :label="I18NGetter().useSimulationFormStep3.LABEL_3_MONTHS"
          xs12
          class="px-0"
          type="number"
          int
          v-model="income.threeMonthsAverage"
          @blur="onThreeMonthsAverageInput"
          required
          :suffix="$env.currentCurrency()"/>
        <fp-input
          :label="I18NGetter().useSimulationFormStep3.LABEL_6_MONTHS"
          xs12
          ref="sixMonthsAverageInput"
          class="px-0"
          type="number"
          int
          v-model="income.sixMonthsAverage"
          required
          :suffix="$env.currentCurrency()"/>
        <fp-input
          :label="I18NGetter().useSimulationFormStep3.LABEL_12_MONTHS"
          xs12
          ref="twelveMonthsAverageInput"
          class="px-0"
          type="number"
          int
          v-model="income.twelveMonthsAverage"
          required
          :suffix="$env.currentCurrency()"/>
        <fp-select
          :label="I18NGetter().useSimulationFormStep3.LABEL_CURRENCY"
          xs12
          class="px-0"
          type="number"
          :items="MultiformDictionaries.currencyDictionary"
          v-model="income.currency"
          :clearable="false"
        />
      </template>
      <template v-else-if="shouldDisplayRentIncome">
        <v-layout row wrap px-2>
          <fp-input
            :label="I18NGetter().useSimulationFormStep3.LABEL_INCOME_AVG"
            xs12
            class="pr-2 pl-0"
            type="number"
            int
            v-model="income.currentYearIncome"
            :suffix="$env.currentCurrency()"/>
        </v-layout>
      </template>
      <template v-else-if="shouldDisplayGeneralActivitiesIncome">
        <v-layout row wrap px-2>
          <fp-input
            :label="I18NGetter().useSimulationFormStep3.LABEL_INCOME_AVG"
            xs6
            class="pr-2 pl-0"
            type="number"
            int
            v-model="income.currentYearIncome"
            :suffix="$env.currentCurrency()"/>
          <fp-input
            :label="I18NGetter().useSimulationFormStep3.LABEL_COSTS_AVG"
            xs6
            class="pl-2 pr-0"
            type="number"
            int
            v-model="income.currentYearCost"
            required
            :suffix="$env.currentCurrency()"/>
        </v-layout>
      </template>
      <template v-else-if="shouldDisplayFormTypeOf(IncomeFormType.PERMANENT_INCOME_TYPE_FORM)">
        <fp-input
          :label="I18NGetter().useSimulationFormStep3.LABEL_INCOME_VALUE"
          xs12
          class="px-0"
          type="number"
          int
          v-model="income.income"
          :suffix="$env.currentCurrency()"/>
        <fp-select
          :label="I18NGetter().useSimulationFormStep3.LABEL_CURRENCY"
          xs12
          class="px-0"
          type="number"
          :items="MultiformDictionaries.currencyDictionary"
          v-model="income.currency"
          :clearable="false"
        />
      </template>
      <template v-else-if="shouldDisplayFormTypeOf(IncomeFormType.BUSINESS_INCOME_TYPE_FORM)">
        <v-layout row wrap px-2>
            <fp-input
              :label="`${I18NGetter().useSimulationFormStep3.LABEL_INCOME} ${CURRENT_YEAR}`"
              xs6
              class="pr-2 pl-0"
              type="number"
              int
              v-model="income.currentYearIncome"
              required
              :suffix="$env.currentCurrency()"/>
            <fp-input
              :label="`${I18NGetter().useSimulationFormStep3.LABEL_COSTS} ${CURRENT_YEAR}`"
              xs6
              class="pl-2 pr-0"
              type="number"
              int
              v-model="income.currentYearCost"
              required
              :suffix="$env.currentCurrency()"/>
            <fp-input
              :label="`${I18NGetter().useSimulationFormStep3.LABEL_INCOME} ${CURRENT_YEAR - 1}`"
              xs6
              class="pr-2 pl-0"
              type="number"
              int
              v-model="income.lastYearIncome"
              :suffix="$env.currentCurrency()"/>
            <fp-input
              :label="`${I18NGetter().useSimulationFormStep3.LABEL_COSTS} ${CURRENT_YEAR - 1}`"
              xs6
              class="pl-2 pr-0"
              type="number"
              int
              v-model="income.lastYearCost"
              :suffix="$env.currentCurrency()"/>
            <fp-input
              :label="`${I18NGetter().useSimulationFormStep3.LABEL_INCOME} ${CURRENT_YEAR - 2}`"
              xs6
              class="pr-2 pl-0"
              type="number"
              int
              v-model="income.twoYearsBeforeIncome"
              :suffix="$env.currentCurrency()"/>
            <fp-input
              :label="`${I18NGetter().useSimulationFormStep3.LABEL_COSTS} ${CURRENT_YEAR - 2}`"
              xs6
              class="pl-2 pr-0"
              type="number"
              int
              v-model="income.twoYearsBeforeCost"
              :suffix="$env.currentCurrency()"/>
        </v-layout>
        <p class="all-caps-header mb-0">{{I18NGetter().useSimulationFormStep3.TAX}}</p>
        <v-layout>
          <fp-radio-group
            row
            v-model="income.tax"
            :items="MultiformDictionaries.taxTypesDictionary"
          ></fp-radio-group>
        </v-layout>
      </template>
      <template v-else-if="shouldDisplayFormTypeOf(IncomeFormType.BUSINESS_WITHOUT_COSTS_INCOME_TYPE_FORM)">
        <v-layout row wrap px-2>
          <fp-input
            :label="`${I18NGetter().useSimulationFormStep3.LABEL_INCOME} ${CURRENT_YEAR}`"
            xs12
            class="pr-2 pl-0"
            type="number"
            v-model="income.currentYearIncome"
            required
            int
            :suffix="$env.currentCurrency()"/>
          <fp-input
            :label="`${I18NGetter().useSimulationFormStep3.LABEL_INCOME} ${CURRENT_YEAR - 1}`"
            xs12
            class="pr-2 pl-0"
            type="number"
            v-model="income.lastYearIncome"
            int
            :suffix="$env.currentCurrency()"/>
          <fp-input
            :label="`${I18NGetter().useSimulationFormStep3.LABEL_INCOME} ${CURRENT_YEAR - 2}`"
            xs12
            class="pr-2 pl-0"
            type="number"
            v-model="income.twoYearsBeforeIncome"
            int
            :suffix="$env.currentCurrency()"/>
        </v-layout>
      </template>
      <template v-else-if="shouldDisplayFormTypeOf(IncomeFormType.BON_CARD_INCOME_TYPE_FORM)">
        <v-layout row wrap px-2>
          <fp-input
            :label="I18NGetter().useSimulationFormStep3.LABEL_TAX_AMOUNT"
            xs12
            class="pr-2 pl-0"
            type="number"
            int
            v-model="income.taxAmount"
            :suffix="$env.currentCurrency()"/>
        </v-layout>
      </template>
    </v-flex>
  </v-layout>
</template>
<script  setup lang="ts">
import {computed, ref} from 'vue';
import {useFormNavigation} from '@/components/calculator/form/useSimulationForm';
import MultiformDictionaries from '@/modules/multiForm/shared/MultiformDictionaries';
import {FFIncome} from '@/models/ExpertState';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {FFIncomeType, IncomeFormType} from '@/modules/multiForm/shared/Types';
import EnvironmentService from '@/env/EnvironmentService';
interface FpInput {
  value: number;
  inputCallback: (value: number) => void,
}
interface ComponentRef<T> {
  value?: T,
}

const props = withDefaults(defineProps<{
  income: FFIncome,
  incomeNumber: number,
}>(), {
  income: null,
  incomeNumber: 1,
});
const formNavigation = useFormNavigation();
const twelveMonthsAverageInput = ref<Nullable<ComponentRef<FpInput>>>(null);
const sixMonthsAverageInput = ref<Nullable<ComponentRef<FpInput>>>(null);
const incomeDeliveredOnOptions = [{type: 'bankAccount', name_pl: I18NGetter().useSimulationFormStep3.ACCOUNT,}, {type: 'cash', name_pl: I18NGetter().useSimulationFormStep3.CASH,},] as const;
const shouldDisplayFormTypeOf = (formType: IncomeFormType) => {
  return props.income && MultiformDictionaries.incomeTypesByFormType[formType].includes(props.income.incomeTypeId);
}
const getIncomeNameByType = (incomeTypeId: FFIncomeType): string =>
  MultiformDictionaries.allIncomeTypesDictionary.find(({type,}) => type === incomeTypeId)?.name_pl ?? '';
const CURRENT_YEAR: number = new Date().getFullYear();

const shouldDisplayRentIncome =
  computed<boolean>(() => EnvironmentService.Environment.isAppDomain().NESTO_RO && shouldDisplayFormTypeOf(IncomeFormType.RENT_INCOME_TYPE_FORM))
const shouldDisplayGeneralActivitiesIncome =
  computed<boolean>(() => EnvironmentService.Environment.isAppDomain().NESTO_RO && shouldDisplayFormTypeOf(IncomeFormType.GENERAL_ACTIVITIES_INCOME_TYPE_FORM))
const onThreeMonthsAverageInput = (value: number) => {
  if (!sixMonthsAverageInput.value?.value) {
    (sixMonthsAverageInput.value as unknown as FpInput).inputCallback(value);
  }
  if (!twelveMonthsAverageInput.value?.value) {
    (twelveMonthsAverageInput.value as unknown as FpInput).inputCallback(value);
  }
}

</script>
<style scoped lang="scss">

</style>
