import {FpAxios} from 'fp-components';
import {AxiosInstance} from 'axios';
import {LandOfWork, LandOfWorkDto} from '@/models/LandOfWork';
import {Address} from '@/modules/multiForm/shared/MultiformModels';
import {IApi} from '@/models/interfaces/IApi';
import {HoursOfWorkListDto, HoursWithUsersDto} from '@/models/HoursOfWork';
import {UserListElement} from '@/models/ppl/UserListElement';
import {ProductType} from '@/commons/enums/ProductType';
import {Place} from '@/models/PlaceDto';

interface GetLandsOfWorkRequest{
  lands: LandOfWorkDto[]
  userId: number
}

export class HoursOfWorkParams {
  duration?: number;
  from?: Date;
  userId?: number;
  districtId?: number;
  landId?: number;
  productType?: ProductType[] = [];
  language?: ProductType[] = [];
  numberOfDays?: number;
  users?: number[];
  widgetOwnerId?: Nullable<number>;
  bookTimeBefore?: boolean;
  extendBusinessHours?: boolean;
};

class UserManagementApi implements IApi {
  public readonly baseUrl: string = 'users';
  public readonly fpAxios: AxiosInstance = FpAxios.create();

  async saveOfficeAddress(userId: number, address: Address): Promise<void> {
    await this.fpAxios.put<Address>(`${this.baseUrl}/${userId}/office/address`, address);
  }

  async getOfficeAddress(userId: number): Promise<Address | null> {
    const response = await this.fpAxios.get<Address>(`${this.baseUrl}/${userId}/office/address`);
    return response.data;
  }

  async getOfficeAddressFromAddressId(addressId: number): Promise<Address | null> {
    const response = await this.fpAxios.get<Address>(`${process.env.VUE_APP_BASE_URL}address/${addressId}`);
    return response.data;
  }

  async getLandsOfWork(userId: number): Promise<LandOfWork[]> {
    try {
      const response = await this.fpAxios.get<GetLandsOfWorkRequest[]>(`${this.baseUrl}/location/range`, {
        params: {
          user_id: userId,
        },
      });
      const find = response.data.find(x => x.userId === userId);
      return find ? find.lands.map(dto => new LandOfWork(dto)) : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getHoursOfWorkByLocalization(config: HoursOfWorkParams): Promise<HoursWithUsersDto> {
    const response = await this.fpAxios.post<HoursWithUsersDto>('activities/availability', {
      useDefaultBusinessHours: true,
      ...config,
    });
    return {
      branches: response.data.branches,
      places: response.data.places.map(place => new Place(place)),
      users: response.data.users
        .map(x => new UserListElement(x)),
      days: response.data.days,
    };
  }

  async getHoursOfWork(userId: number, config?: HoursOfWorkParams): Promise<HoursOfWorkListDto[]> {
    try {
      const response = await this.fpAxios.post<HoursOfWorkListDto[]>('activities/availability', {
        userId,
        useDefaultBusinessHours: true,
        from: config?.from?.toISOString(),
        numberOfDays: config?.numberOfDays,
        duration: config?.duration,
        bookTimeBefore: config?.bookTimeBefore,
        extendBusinessHours: config?.extendBusinessHours,
        widgetOwnerId: config?.widgetOwnerId || null,
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
export default new UserManagementApi();
