import {Cost, Offer} from '@/models/simulation/Simulation';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {numeralFormat} from '@/filters/StringFilters';
import EnvironmentService from '@/env/EnvironmentService';

export const renderDTI = (offer: Offer, excluded: boolean = false): { header: string, details: string, } => {
  const householdDtiPercentage: number = offer?.debtToIncome!.householdDtiPercentage!;
  const acceptableDtiPercentage: number = offer?.debtToIncome!.acceptableDtiPercentage!;
  const positiveDebtToIncomePercentage: boolean = householdDtiPercentage <= acceptableDtiPercentage;
  const header = (): string => {
    if (householdDtiPercentage && !excluded) {
      return `<div class="${positiveDebtToIncomePercentage ? 'success--text' : 'error--text'}">${householdDtiPercentage.toFixed(1)}% - ${positiveDebtToIncomePercentage ? I18NGetter().useMortgageTableElements.DTI_ACCEPTABLE : I18NGetter().useMortgageTableElements.DTI_EXCEEDED}</div>`
    }
    return `<div class="${excluded && 'error--text'}">${excluded ? I18NGetter().useMortgageTableElements.DTI_CANNOT_BE_ESTIMATED : I18NGetter().useMortgageTableElements.DTI_NO_INFO}</div>`;
  };
  const details = (): string => {
    const expensesDetails: string = `<p>${I18NGetter().useMortgageTableElements.TOTAL_NET_INCOME} = ${numeralFormat(offer.debtToIncome?.incomeSum)}  ${EnvironmentService.Environment.currentCurrency()}</p>
                <p>${I18NGetter().useMortgageTableElements.TOTAL_EXPENSES} = ${numeralFormat(offer.debtToIncome?.totalCost)}  ${EnvironmentService.Environment.currentCurrency()}</p>
                <p>${I18NGetter().useMortgageTableElements.HIGHEST_INSTALLMENT} = ${numeralFormat(offer.debtToIncome?.installment)}  ${EnvironmentService.Environment.currentCurrency()}</p>`;
    const renderDetailsTemplate = (slot: string): string => (`<div>
    ${slot}
    <p>${I18NGetter().useMortgageTableElements.DTI_ASSUMPTION_INFO} 0 - ${acceptableDtiPercentage}%</p>
    </div>`)

    const acceptableInfo: string = `
                <p>${I18NGetter().useMortgageTableElements.DTI_ACCEPTABLE_INFO}</p>
                <br>
                ${expensesDetails}
                <br>
                <p><b>DTI</b> = ${I18NGetter().useMortgageTableElements.DTI_FORMULA_FIXED_INSTALLMENTS} = ${householdDtiPercentage}%</p>`

    const exceededInfo: string =
                `<p>${I18NGetter().useMortgageTableElements.DTI_EXCEEDED_INFO}</p>
                <br>
                ${expensesDetails}
                <br>
                <p><b>DTI</b> = ${I18NGetter().useMortgageTableElements.DTI_FORMULA_FIXED_INSTALLMENTS} = ${householdDtiPercentage}%</p>
                `

    const noInfo: string =
                `<p>${I18NGetter().useMortgageTableElements.DTI_NO_INFO_INFO}</p>
                <br>`

    const cannotBeEstimated: string =
                `<p>${I18NGetter().useMortgageTableElements.DTI_CANNOT_BE_ESTIMATED_INFO}</p>
                <br>`

    return renderDetailsTemplate(excluded
      ? cannotBeEstimated : householdDtiPercentage
        ? positiveDebtToIncomePercentage ? acceptableInfo : exceededInfo : noInfo);
  };

  return {
    header: header(),
    details: details(),
  }
};
