<template>
  <v-container grid-list-lg class="pa-0">
    <changes-detection :value="userInput" @change="onChange()" ref="changesDetection">
      <v-layout wrap>
        <slot></slot>
      </v-layout>
    </changes-detection>
  </v-container>
</template>
<script lang="ts" setup>
import { SimulationDemand } from '@/models/Demand';
import ChangesDetection from '@/components/commons/ChangesDetection.vue';
import Vue, {getCurrentInstance} from 'vue';

defineProps<{
    userInput: SimulationDemand;
  }>();

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
    (event: 'change'): void;
}>();

const instance = getCurrentInstance();

const onChange = () => {
  const simulationForm: any = (instance?.proxy.$refs.changesDetection as Vue).$refs.FORM_NAME;
  if (simulationForm && !simulationForm.validate()) {
    console.error('form invalid');
    return;
  }
  emit('change');
};
</script>
<style scoped lang="scss">
</style>
