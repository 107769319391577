import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const KnfNavigation = () => import(/* webpackChunkName: "KNF" */ '@/modules/knf/views/KnfNavigation.vue');
const StartKnfTest = () => import(/* webpackChunkName: "KNF" */ '@/modules/knf/views/StartKnfTest.vue');

const KnfRoutes: RouteConfig =
  {
    path: 'knf_test',
    redirect: '/knf_test/start',
    component: KnfNavigation,
    children: [
      {
        name: Routes.KNF_TEST.START_KNF_TEST,
        path: 'start',
        component: StartKnfTest,
      },
    ],
  };

export default KnfRoutes;
