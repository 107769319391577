import {BankSymbol} from '@/commons/dictionaries/BankSymbol';

export function useBankLogo(symbol: BankSymbol, logoUrl?: string | null) {
  // Enkapsulacja base url powoduje crash webpacka
  if (logoUrl) {
    return logoUrl;
  } else {
    try {
      return require(`@/assets/bankLogotypes/${symbol}-circle.png`);
    } catch (e) {
      try {
        // Nowy lżejszy format zdjęć
        return require(`@/assets/bankLogotypes/${symbol}-circle.webp`);
      } catch (e) {
        return require('@/assets/bankLogotypes/PLACEHOLDER-circle.webp');
      }
    }
  }
}
