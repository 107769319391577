import {merge} from 'lodash-es';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {i18nFormatter} from '@/services/StringFormatter';

export enum TourName {
  MORTGAGE_CALCULATOR = 'MORTGAGE_CALCULATOR',
  DASHBOARD = 'DASHBOARD',
  LEAD = 'LEAD',
}

export const TourLabel: Record<TourName,string> = {
  [TourName.MORTGAGE_CALCULATOR]: I18NGetter().useTourOptions.CALCULATOR,
  [TourName.DASHBOARD]: I18NGetter().useTourOptions.DASHBOARD,
  [TourName.LEAD]: I18NGetter().useTourOptions.LEAD,
};

export interface TourModalOptions {
  title: (name: string) => string;
  description: string;
  trueText: string;
  falseText: string;
}

export enum TourModalType {
  START = 'start',
  CANCEL = 'cancel',
  FINISH = 'finish',
}
const DEFAULT_TOUR_OPTIONS: TourOptions = {
  modals: {
    [TourModalType.START]: {
      title: () => I18NGetter().useTourOptions.START_TITLE,
      description: I18NGetter().useTourOptions.START_DESC,
      trueText: I18NGetter().useTourOptions.START_TRUE_TEXT,
      falseText: I18NGetter().useTourOptions.START_FALSE_TEXT,
    },
    [TourModalType.CANCEL]: {
      title: () => I18NGetter().useTourOptions.CANCEL_TITLE,
      description: I18NGetter().useTourOptions.CANCEL_DESC,
      trueText: I18NGetter().useTourOptions.CANCEL_TRUE_TEXT,
      falseText: I18NGetter().useTourOptions.CANCEL_FALSE_TEXT,
    },
    [TourModalType.FINISH]: {
      title: (name: string) => i18nFormatter(
        I18NGetter().useTourOptions.NEW_TRANSLATION_FINISH_TITLE,
        {name: name,}),
      description: I18NGetter().useTourOptions.FINISH_DESC,
      trueText: I18NGetter().useTourOptions.FINISH_TRUE_TEXT,
      falseText: I18NGetter().useTourOptions.FINISH_FALSE_TEXT,
    },
  },
} as const;

export class TourOptions {
  modals!: Record<TourModalType, TourModalOptions>;
  constructor(options?: PartialDeep<TourOptions>) {
    merge(this, DEFAULT_TOUR_OPTIONS, options);
  };
}
