<template>
  <div class="notice d-flex flex-column align-center">
    <div class="mx-auto d-flex align-start mt-4 body-2">
      <v-icon class="mr-2">mdi-information</v-icon>
      <div class="text" :class="{expanded: isExpanded}">
        <p class="mb-3">
          {{ I18NGetter().useSimulationUserDataNotice.FIRST_PART_NOTICE }}
        </p>
        <p class="mb-3">
          {{ I18NGetter().useSimulationUserDataNotice.SECOND_PART_NOTICE }}
        </p>
        <p class="mb-3" v-html-safe="I18NGetter().useSimulationUserDataNotice.THIRD_PART_NOTICE">
        </p>
      </div>
    </div>
    <fp-btn primary text @click="isExpanded = !isExpanded">
      {{ isExpanded ? I18NGetter().useSimulationUserDataNotice.IS_EXPANDED_LESS : I18NGetter().useSimulationUserDataNotice.IS_EXPANDED_MORE}}
      <v-icon>{{ `mdi-chevron-${isExpanded ? 'up' : 'down'}` }}</v-icon>
    </fp-btn>
  </div>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export default defineComponent({
  setup() {
    const isExpanded = ref<boolean>(false);

    return {
      isExpanded,
      I18NGetter,
    };
  },
});
</script>
<style lang="scss" scoped>
.notice {
  max-width: 700px;
}
.text {
  position: relative;
  transition: all .2s cubic-bezier(0.390, 0.575, 0.565, 1.000);
  will-change: max-height;
  height: auto;
  max-height: 500px;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
  }

  &:not(.expanded) {
    max-height: 100px;

    &:after {
      background: linear-gradient(0, var(--v-secondaryBg-base), transparent 30%, transparent);
    }
  }
}
</style>
