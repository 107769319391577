import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';

export abstract class FilemanagerEntryDTO {
  path!: string;
  name!: BankSymbol | string;
  createdAt?: string;
  url!: string;
}
export class FilemanagerEntry extends FilemanagerEntryDTO {
  constructor(filemanagerEntryDTO?: FilemanagerEntryDTO) {
    super();
    Object.assign(this, filemanagerEntryDTO);
  }

  get displayName(): string {
    if ((<string[]>Object.values(BankSymbol)).includes(this.name)) {
      const bank = banksDictionary().find(
        el => el.type === this.name);
      return bank ? bank.name_pl : this.name;
    } else { return this.name; }
  }

  get ext(): string {
    if (this.name === '..') { return '..'; }
    const extension = (/(?:\.([^.]+))?$/).exec(this.name);
    if (extension) {
      const ext = extension[1];
      if (ext === undefined || this.url?.length === 0) {
        return 'folder';
      } else {
        return ext;
      }
    }
    return 'folder';
  }

  get isFolder() {
    return (this.ext === 'folder');
  }

  get icon() {
    const extNorm = this.ext.trim().toLowerCase();
    return this.iconDict[extNorm] ? this.iconDict[extNorm] : this.iconDict.file;
  }

  iconDict: Record<string, [string, string]> = {
    '..': ['mdi-arrow-up-circle', 'blue-grey lighten-2', ],
    folder: ['mdi-folder', 'blue-grey lighten-2', ],
    file: ['mdi-file-document', 'light-blue darken-4', ],

    xls: ['mdi-file-excel', 'green darken-3', ],
    xlsm: ['mdi-file-excel', 'green darken-3', ],
    xlsx: ['mdi-file-excel', 'green darken-3', ],

    ppt: ['mdi-file-powerpoint', 'deep-orange darken-4', ],
    pptx: ['mdi-file-powerpoint', 'deep-orange darken-4', ],

    doc: ['mdi-file-word', 'light-blue darken-2', ],
    docx: ['mdi-file-word', 'light-blue darken-2', ],

    pdf: ['mdi-file-pdf-box', 'deep-orange darken-3', ],

    ini: ['mdi-settings', 'grey darken-1', ],
    exe: ['mdi-settings', 'grey darken-1', ],

  };

  get pathNoName() {
    return FilemanagerEntry.withoutName(this.path);
  }

  public static withoutName(path: string) {
    const pathSplitted = /(.*)\/[^/]+/.exec(path);
    if (pathSplitted) {
      return pathSplitted[1] ? pathSplitted[1] : '/';
    }
    return '/';
  }

  public nameFromPath(path: string) {
    const pathSplitted = /(?:.*)\/([^/]+)/.exec(path);
    if (pathSplitted) {
      return pathSplitted[1] ? pathSplitted[1] : 'no name';
    }
    return 'no name';
  }
}
