<template>
  <v-card class="filter-modal">
    <v-card-title class="px-6 sticky-header bb">
      <span class="headline-2 font-weight-medium">
        {{ $i18n.useAsycFilters.FILTERS }}
      </span>
      <v-spacer />
      <v-btn v-if="hasClearableFilters" outlined class="mr-6" @click="clearFilters()">{{ $i18n.useAsycFilters.CLEAR }}</v-btn>
      <v-btn icon @click="closeModal()">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-0">
      <v-form @submit.prevent="chooseFilters()" :id="$cy.allFiltersModal">
        <v-expansion-panels accordion class="elevation-0" v-model="expansionModel">
        <template v-for="(header, index) in headers">
          <v-expansion-panel :key="index" v-if="!header.filterReadonly">

            <v-expansion-panel-header class="elevation-0">
              <span class="font-weight-medium shrink">{{header.text}}</span>
              <!--    value.isValueOnlyInNonClearableProperties(header) it checks if the value is only in the nonClearableProperty, if so it should not be perceived as the active filter     -->
              <v-avatar size="16" v-if="isValueExist(header) && !activeFilters.isValueOnlyInNonClearableProperties(header)"
                        class="ml-2 shrink"
                        color="primary" />
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="header.filterType === filterTypes.TEXT">
              <v-layout class="filter-wrapper px-6">
                <v-flex xs12 md4>
                  <fp-input v-model="activeFilters[header.valueFilter]"
                            @onEnter="chooseFilters()"
                            autofocusOnCreate
                            :label="searchLabel"
                            :suffix="header.suffix"
                            :id="$cy.phraseSearchInput"
                            outlined />
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="header.filterType === filterTypes.NUMBER">
              <v-layout class="filter-wrapper px-6">
                <v-flex xs12 md4>
                  <fp-input v-model="activeFilters[header.valueFilter]"
                            @onEnter="chooseFilters()"
                            autofocusOnCreate
                            :label="searchLabel"
                            :suffix="header.suffix"
                            int
                            type="number"
                            :id="$cy.phraseSearchInput"
                            outlined />
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="header.filterType === filterTypes.RANGE">
              <v-layout class="filter-wrapper px-6">
                <v-flex xs12 md8>
                  <v-layout>
                    <v-flex xs12 md6 mr-2>
                      <fp-input v-model="activeFilters[header.valueFilter].from"
                                :label="fromLabel"
                                outlined
                                :min-value="header.range && header.range.from ? header.range.from  : null"
                                :max-value="header.range && header.range.to ? header.range.to : null"
                                autofocusOnCreate
                                :int="Boolean(header.range && header.range.from)"
                                :suffix="header.suffix"
                                type="number" />
                    </v-flex>
                    <v-flex xs12 md6 mr-2>
                      <fp-input v-model="activeFilters[header.valueFilter].to"
                                type="number"
                                :min-value="header.range && header.range.from ? header.range.from : null"
                                :max-value="header.range && header.range.to ? header.range.to : null"
                                :label="toLabel"
                                :int="Boolean(header.range && header.range.from)"
                                :suffix="header.suffix"
                                outlined />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="header.filterType === filterTypes.DATE">
              <dev-info :value="header"></dev-info>
              <v-layout class="filter-wrapper px-6" wrap>
                <v-flex xs12 md8 v-for="(groupedHeader, index) in header.headers" :key="index">
                  <span class="subtitle-1 pb-2 pt-4 d-block">
                    {{groupedHeader.text}}
                  </span>
                  <v-layout>
                    <v-flex xs12 md6 mr-2>
                      <fp-datepicker hide-details
                                     v-model="activeFilters[groupedHeader.valueFilter].from"
                                     :label="fromLabel"
                                     outlined
                                     :clearable="isClearable(groupedHeader)"
                                     :max-date="activeFilters[groupedHeader.valueFilter].to" />
                    </v-flex>
                    <v-flex xs12 md6 ml-2>
                      <fp-datepicker hide-details
                                     v-model="activeFilters[groupedHeader.valueFilter].to"
                                     :label="toLabel"
                                     outlined
                                     :end-of-day="isEndOfDayFormat(groupedHeader)"
                                     :clearable="isClearable(groupedHeader)"
                                     :min-date="activeFilters[groupedHeader.valueFilter].from" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="header.filterType === filterTypes.BOOLEAN">
              <v-layout class="filter-wrapper px-6">
                <v-flex xs12 md8>
                  <v-layout>
                    <v-flex xs12 md6 mr-2>
                      <fp-checkbox
                      label-fade
                      borderless
                      v-model="activeFilters[header.valueFilter]"
                      :label="header.labelPrefix ? `${header.labelPrefix} ${header.text}` : header.text"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="header.filterType === filterTypes.AUTOCOMPLETE">
              <v-layout class="filter-wrapper px-6 elevation-0">
                <v-flex xs12 md8>
                  <client-autocomplete
                    v-if="header.valueFilter === autocompleteTypes.CLIENTS"
                    v-model="activeFilters[header.valueFilter]"
                    multiple
                    :label="enterClientNameLabel"
                    auto-focus
                    return-id/>
                  <UserAutocompleteOld
                    v-if="[autocompleteTypes.USERS, autocompleteTypes.OWNER_ID].includes(header.valueFilter)"
                    v-model="activeFilters[header.valueFilter]"
                    :existing-items.sync="activeFilters.existingUsers"
                    :multiple="header.valueFilter === autocompleteTypes.USERS"
                    auto-focus/>
                  <deal-from-partner-autocomplete
                    v-if="header.valueFilter === autocompleteTypes.PARTNERS"
                    multiple
                    auto-focus
                    v-model="activeFilters[header.valueFilter]"/>
                  <division-autocomplete
                    v-if="header.valueFilter === autocompleteTypes.DIVISIONS"
                    v-model="activeFilters[header.valueFilter]"
                    multiple
                    return-id/>
                  <v-layout wrap>
                    <v-flex xs8>
                      <bank-autocomplete
                          v-if="header.valueFilter === autocompleteTypes.BANKS"
                          v-model="activeFilters[header.valueFilter]"
                          multiple
                          auto-focus
                          return-id />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="isRadioFilterType(header)">
              <v-layout class="filter-wrapper px-6">
                <v-flex xs12 md8 v-for="(groupedHeader, index) in header.headers" :key="index">
                  <fp-radio-group v-model="activeFilters[groupedHeader.valueFilter]" :label="groupedHeader.text" />
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="header.filterType === filterTypes.STORE_DICT">
              <v-layout class="filter-wrapper px-6" wrap>
                <v-flex xs12 md4 v-for="(item, i) in storeDicts[header.dictName] " :key="`enumWrap_${i}`">
                  <v-checkbox
                    hide-details
                    class="mb-2 enum-checkbox pa-0"
                    v-model="activeFilters[header.valueFilter]"
                    :value="item.id"
                    :label="item.reason"/>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="typeof header.filterType === 'object'">
              <v-layout class="filter-wrapper px-6">
                <template v-if="Array.isArray(activeFilters[header.valueFilter])">
                  <template v-if="isLoanApplicationStatuses(header)">
                    <v-flex
                      xs12
                      md4
                      v-for="(section, i) in groupBy(LoanApplicationStepsDictionary)"
                      :key="`dictWrap_${i}`">
                      <template v-for="(item, key) in section">
                        <v-checkbox
                          :key="key"
                          :color="item.color"
                          hide-details
                          class="pa-0 enum-checkbox"
                          v-if="item"
                          v-model="activeFilters[header.valueFilter]"
                          :value="item.id">
                          <template v-slot:label>
                            <span :class="[`${item.color}--text`]">
                              {{item.displayName}}
                            </span>
                          </template>
                        </v-checkbox>
                      </template>
                    </v-flex>
                  </template>
                  <template v-else>
                    <v-flex xs12 md4 v-for="(section, i) in enumGroupBy(header)"
                            :key="`enumWrap_${i}`">
                      <template v-for="(item, key) in section">
                        <v-checkbox
                          :key="key"
                          hide-details
                          class="pa-0 enum-checkbox"
                          v-if="item"
                          v-model="activeFilters[header.valueFilter]"
                          :value="item.type"
                          :label="item.name"/>
                      </template>
                    </v-flex>
                  </template>
                </template>
                <fp-select
                  v-else
                  v-model="activeFilters[header.valueFilter]"
                  :label="chooseLabel"
                  :items="getDictionaryByEnumObject(header.filterType, header.i18nEnumName)"
                  itemKey="type"
                  itemName="name"
                />

              </v-layout>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="header.filterType === filterTypes.ADDRESS">
              <address-form :long-form="false" v-model="activeFilters[header.valueFilter]" :required="false" :disabled-fields="['country']"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-6 py-4 bt sticky-action">
      <v-btn depressed color="primary"
             type="submit"
             @click="chooseFilters()"
             :id="$cy.addFiltersButton">{{ $i18n.useAsycFilters.ADD_FILTERS }}</v-btn>
      <v-spacer />
      <v-btn depressed outlined @click="closeModal()">{{ $i18n.useAsycFilters.CANCEL }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {Component} from 'vue-property-decorator';
import {ModalPayload} from '@/types/typings';
import {VueModalComponent} from 'fp-components';
import {AutocompleteFilterTypes, FilterType} from '@/services/searchEngine/enums/FilterType';
import {ModalAction} from '@/types/typings/enums';
import {FilterGroup, IDataTableHeader} from '@/services/searchEngine/interfaces/DataTableHeader';
import ClientAutocomplete from '@/components/inputs/ClientAutocomplete.vue';
import UserAutocomplete from '@/components/inputs/autocompletes/UserAutocomplete.vue';
import DealFromPartnerAutocomplete from '@/components/inputs/DealFromPartnerAutocomplete.vue';
import DivisionAutocomplete from '@/components/inputs/DivisionAutocomplete.vue';
import BankAutocomplete from '@/components/inputs/bankAutocomplete/BankAutocomplete.vue';
import {BaseFilter, ReportFilter} from '@/models/Filter';
import {LoanApplicationStepsDictionary} from '@/models/LoanApplicationStep';
import ApplicationReport from '@/models/ApplicationReport';
import {getDictionaryByEnumObject} from '@/services/enumTranslator/GetDictionaryByEnumObject';
import UserAutocompleteOld from '@/components/inputs/autocompletes/UserAutocompleteOld.vue';
import {chunk, find} from 'lodash-es';
import DictionaryStore from '@/commons/services/store/DictionaryStore';
import EnvironmentService from '@/env/EnvironmentService';
import {ProductType} from '@/commons/enums/ProductType';
import AddressForm from '@/components/address/AddressForm.vue';

@Component({
  components: {
    AddressForm,
    UserAutocompleteOld,
    BankAutocomplete,
    DivisionAutocomplete,
    DealFromPartnerAutocomplete,
    UserAutocomplete,
    ClientAutocomplete,
  },
})
export default class AsyncFilters extends VueModalComponent<ModalPayload<BaseFilter>> {
  public headers: Array<IDataTableHeader<any> | FilterGroup> = this.modalData.headers;
  public activeFilters: BaseFilter = this.modalData.activeFilters;
  public expansionModel: number = 0;

  public storeDicts: any = {};

  public getDictionaryByEnumObject = getDictionaryByEnumObject;

  public radioFilterTypes: Array<FilterType> = [FilterType.RADIO,
    FilterType.RADIO_EXPERT_ATTRIBUTES, ];

  mounted() {
    this.headers.forEach(async(header: IDataTableHeader<any, any>) => {
      if (header.filterType === FilterType.STORE_DICT) {
        const dictionary = await DictionaryStore.getDictionary(header.dictName!);
        this.$set(this.storeDicts, header.dictName!, dictionary);
      }
    });
  }

  public enumGroupBy<TEnum extends string>(header: IDataTableHeader<TEnum>) {
    const enumObject = header.filterType as Record<keyof TEnum, TEnum>;
    let dictionary = getDictionaryByEnumObject(enumObject, header.i18nEnumName!).sort(
      (a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    );
    // TODO: to w przyszłości pewnie warto zrobić lepiej
    // @ts-ignore
    if (header.valueFilter === 'productTypes') {
      const availableProductTypes = EnvironmentService.Environment.getAppDomainConfig().availableProducts;
      dictionary = dictionary.filter(x => availableProductTypes.includes(x.type as ProductType));
    }

    if (EnvironmentService.Environment.getAppDomainConfig().excludedFilterItems) {
      dictionary = dictionary.filter(item => {
        return !EnvironmentService.Environment.getAppDomainConfig().excludedFilterItems!.includes(item.type);
      });
    }

    return this.groupBy(dictionary);
  }

  private groupBy(arr: any[]) {
    if (arr.length > 4) {
      return chunk(arr, Math.ceil(arr.length / 3));
    } else {
      return [arr,];
    }
  }

  public isValueExist(header: IDataTableHeader<any>): boolean {
    if (header instanceof FilterGroup) {
      return header.headers.some(h => h.valueFilter && !this.activeFilters.isHeaderFilterValueNull(h.valueFilter));
    } else {
      return !!header.valueFilter && !this.activeFilters.isHeaderFilterValueNull(header.valueFilter);
    }
  }

  public isLoanApplicationStatuses(header: IDataTableHeader<ApplicationReport, ReportFilter>): boolean {
    const statusHeaderName: (keyof ReportFilter) = 'settledApplicationSteps';
    return header.valueFilter === statusHeaderName;
  }

  public isLossReason(header: IDataTableHeader<ApplicationReport, ReportFilter>): boolean {
    return header.valueFilter === 'lossReasons';
  }

  public get filterTypes() {
    return FilterType;
  }

  public get autocompleteTypes() {
    return AutocompleteFilterTypes;
  }

  public get LoanApplicationStepsDictionary() {
    return LoanApplicationStepsDictionary.filter(el => el.isFinalStage);
  }

  public labelEnum = this.$i18n.useAsycFilters;
  public get searchLabel() {
    return this.labelEnum.SEARCH_INPUT;
  }

  public get fromLabel() {
    return this.labelEnum.FROM;
  }

  public get toLabel() {
    return this.labelEnum.TO;
  }

  public get enterClientNameLabel() {
    return this.labelEnum.ENTER_CLIENT_NAME;
  }

  public get chooseLabel() {
    return this.labelEnum.CHOOSE;
  }

  public get hasClearableFilters() {
    return this.headers.some(header => {
      return this.isClearable(header);
    });
  }

  public isClearable(header: IDataTableHeader<any> | FilterGroup) {
    if (!this.isValueExist(header)) {
      return false;
    }

    if ('headers' in header) {
      return header.headers.some(item => {
        return item.valueFilter && !this.activeFilters.isHeaderFilterValueNull(item.valueFilter) && !item.filterReadonly;
      });
    } else {
      return !header.filterReadonly && !this.activeFilters.isValueOnlyInNonClearableProperties(header);
    };
  }

  public isEndOfDayFormat(header: IDataTableHeader<any> | FilterGroup) {
    if ('headers' in header) {
      const headerObject: IDataTableHeader<any> | undefined = find(header.headers,
        (groupHeader: IDataTableHeader<any>) => !!groupHeader.endOfDayFormat);
      return headerObject && (this.isValueExist(header) && headerObject.endOfDayFormat);
    } else {
      return this.isValueExist(header) && header.endOfDayFormat;
    }
  }

  public clearFilters() {
    this.headers.forEach(header => {
      if (header instanceof FilterGroup) {
        const val = this.activeFilters.getValue(header) as Record<keyof BaseFilter, BaseFilter>[];
        this.activeFilters.clearFilterProp(val);
      }
      if ('valueFilter' in header && header.valueFilter) {
        this.activeFilters.clearFilterProp(header.valueFilter, header.nonClearAbleProperties);
      }
    });
    this.chooseFilters();
  }

  public chooseFilters() {
    this.closeModal({
      payload: this.activeFilters,
      action: ModalAction.ADD,
    });
  }

  public isRadioFilterType(header: IDataTableHeader<any>): boolean {
    return this.radioFilterTypes.includes(header.filterType as FilterType);
  }
}
</script>

<style scoped lang="scss">
.filter-modal {
  ::v-deep .v-expansion-panel__header > *:not(.v-expansion-panel__header__icon) {
    flex: 0 1 auto;
  }
  .existing-filter {
    min-width: 32px;
    justify-content: center;
    flex: 0 1 auto;
  }
  .filter-wrapper {
    padding-bottom: 40px;
    ::v-deep .enum-checkbox {
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  ::v-deep .v-card__text {
    padding: 0!important;
  }
}
</style>
