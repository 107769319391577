import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const ReportList = () => import(/* webpackChunkName: "Report" */ '@/modules/report/views/report-list/ReportList.vue');
const ReportDetails = () => import(/* webpackChunkName: "Report" */ '@/modules/report/views/report-details/ReportDetails.vue');

const ReportRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.REPORT.REPORT_LIST,
    component: ReportList,
  },
  {
    path: ':id',
    name: Routes.REPORT.REPORT_DETAILS,
    component: ReportDetails,
  },
];

export default ReportRoutes;
