import {IMultiformDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';

export enum SortTypeEnum{
  DATE = 'updatedAt',
  AUTHOR = 'author',
  TITLE = 'title',
}

export const sortTypesDictionary: IMultiformDictionary<SortTypeEnum> = [
  {name_pl: 'Ostatnia Aktywność', type: SortTypeEnum.DATE, },
  {name_pl: 'Autor', type: SortTypeEnum.AUTHOR, },
  {name_pl: 'Tytuł', type: SortTypeEnum.TITLE, },
];
