import Regulation, {RegulationDTO} from '@/models/Regulation';
import {RegulationStoreState} from '@/modules/regulation/services/store/RegulationStoreState';
import Vue from 'vue';

const state: RegulationStoreState = {
  Regulations: [],
  regulationsApiActive: true,
  selectedRegulationId: undefined,
};

export const RegulationStoreModule = {
  namespaced: false,
  state,
  getters: {
    regulations(state: RegulationStoreState) {
      return state.Regulations.map((c: RegulationDTO) => new Regulation(c));
    },
    regulationsApiActive(state: RegulationStoreState) {
      return state.regulationsApiActive;
    },
    selectedRegulation(state: RegulationStoreState) {
      return new Regulation(state.Regulations.find(regulation => regulation.id === state.selectedRegulationId));
    },
  },
  mutations: {
    setRegulations(state: RegulationStoreState, regulations: Array<Regulation>) {
      state.Regulations = regulations;
    },

    setRegulation(state: RegulationStoreState, editedRegulation: Regulation) {
      const index = state.Regulations.findIndex(regulation => editedRegulation.id === regulation.id);
      if (index > -1) {
        editedRegulation = Object.assign(state.Regulations[index], editedRegulation);
        Vue.set(state.Regulations, index, editedRegulation);
      } else {
        state.Regulations.push(editedRegulation);
      }
    },

    setRegulationId(state: RegulationStoreState, id: number) {
      state.selectedRegulationId = id;
    },

    setRegulationApiActive(state: RegulationStoreState, active: boolean) {
      state.regulationsApiActive = active;
    },
  },
};
