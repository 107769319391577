<template>
  <v-col :cols="cols" class="primaryBg pb-0 pt-0" :class="[noMargin ? 'mb-0' : 'mb-2']">
    <v-row
      class="navigation-header wrap no-gutters px-2 px-sm-6"
      :class="tabsHeaders ? 'pt-2' : 'py-2'"
      :align="tabsHeaders ? 'center' : undefined">
      <v-col>
        <v-row>
          <v-col :class="{'shrink': $slots.middle}" class="align-self-center grow">
            <v-row class="wrap">
              <v-col class="text-left">
                <span v-if="title" :class="[ customClass ? customClass : 'headline-2 font-weight-medium d-inline-flex']">
                  {{title | trimString(70)}}
                </span>
                <slot v-else name="left" />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="grow align-self-center" v-if="$slots.middle">
            <slot name="middle">
              <v-spacer/>
            </slot>
          </v-col>
          <v-col
            v-if="$slots.right"
            class="align-self-center text-right pr-0"
            :style="slotRightMaxWidth ? maxWidthClass : ''"
            :class="{'mb-3': tabsHeaders,}">
            <slot name="right" />
          </v-col>
          <v-col v-if="tabsHeaders" cols="12" class="pb-0" :id="$cy.navigationTabs">
            <navigation-tabs
              :hide-arrows="hideArrows"
              :value="tabsModel"
              :disabled="disabled"
              :tabs-headers="tabsHeaders"
              @input="inputCallback"
              @change="changeCallback" />
          </v-col>
        </v-row>
        <v-row v-if="$slots.bottom">
          <v-col class="pa-0 bottom-switch">
            <slot name="bottom">
              <v-spacer/>
            </slot>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import NavigationTabs from '@/components/commons/NavigationTabs.vue';
import GoToService from '@/router/GoToService';
import {isNumber} from 'lodash-es';

const PATH_KEY: string = 'path';
@Component({
  components: {NavigationTabs, },
})
export default class NavigationHeader extends Vue {
  @Prop({type: String,})
  readonly customClass?: string;

  @Prop({type: Array,})
  readonly tabsHeaders!: Array<string>;

  @Prop({type: Number,})
  readonly tabsModel!: number;

  @Prop({type: String,})
  readonly title!: string;

  @Prop({type: String,})
  readonly slotRightMaxWidth!: string;

  @Prop({default: 12, type: [String, Number, Boolean,], })
  readonly cols!: string;

  @Prop({type: Boolean, default: false, })
  readonly hideArrows!: boolean;

  @Prop({type: Boolean, default: false, })
  readonly noMargin!: boolean;

  @Prop({type: Boolean, default: false, })
  readonly syncUrl!: boolean;

  @Prop({type: Boolean, default: false, })
  public readonly disabled!: boolean;

  maxWidthClass: string = '';

  public changeCallback(val: number) {
    this.$emit('change', val);
  }

  public inputCallback(newValue: number) {
    this.$emit('update:tabsModel', newValue);
    if (this.syncUrl) {
      GoToService.setQuery({[PATH_KEY]: String(newValue),});
    }
  }

  mounted() {
    this.maxWidthClass = `max-width: ${this.slotRightMaxWidth}`;
    if (this.syncUrl) {
      const newTabsModel = Number(this.$route.query[PATH_KEY]);
      if (isNumber(newTabsModel)) {
        this.$emit('update:tabsModel', newTabsModel);
      }
    }
  }
}

</script>

<style scoped lang="scss">
.bottom-switch {
  position: absolute;
  bottom: -12.5%;
  left: calc(50% - 100px);
}
  .navigation-header {
    position: sticky;
    width: 100%;
    top: 57px;
    z-index: 7;
    &:after {
      width: 100%;
      border-bottom: 1px fpShadow($border-opacity) solid;
      content: '';
      z-index: 20;
      bottom: -1px;
      left: 0;
      position: absolute;
    }
  }
</style>
