import {ProductType} from '@/commons/enums/ProductType';
import {DealStatus} from '@/modules/deals/enums/DealStatus';

export const editableStatuses: (productType: ProductType) => Array<DealStatus> = (productType: ProductType) => {
  if (productType === ProductType.REAL_ESTATE) {
    return [
      DealStatus.DEAL_CREATED,
      DealStatus.COMMUNICATION_ARRANGED,
      DealStatus.COMMUNICATION_PERFORMED,
      DealStatus.APPOINTMENT_ARRANGED,
      DealStatus.APPOINTMENT_PERFORMED,
      DealStatus.CONTRACT_SIGNED,
      DealStatus.BROKER_AGREEMENT,
      DealStatus.REAL_ESTATE_PRESENTATION,
      DealStatus.COMPLETED_SALE,
    ];
  } else if (productType === ProductType.INSURANCE) {
    return [
      DealStatus.DEAL_CREATED,
      DealStatus.COMMUNICATION_ARRANGED,
      DealStatus.APPOINTMENT_ARRANGED,
      DealStatus.APPOINTMENT_PERFORMED,
      DealStatus.SIMULATION_PERFORMED,
      DealStatus.APPLICATION_CREATED,
      DealStatus.CONTRACT_SIGNED,
    ];
  } else {
    return [
      DealStatus.DEAL_CREATED,
      DealStatus.SIMULATION_PERFORMED,
      DealStatus.COMMUNICATION_ARRANGED,
      DealStatus.COMMUNICATION_PERFORMED,
      DealStatus.APPOINTMENT_ARRANGED,
      DealStatus.APPOINTMENT_PERFORMED,
      DealStatus.ABLE_TO_START_PROCESS,
      DealStatus.COLLECTING_DOCUMENTS,
      DealStatus.RETURNED_TO_AGENT_CC,
      DealStatus.APPLICATION_CREATED,
      DealStatus.APPLICATION_SUBMITTED,
      DealStatus.POSITIVE_DECISION,
      DealStatus.CONTRACT_SIGNED,
    ];
  }
};
