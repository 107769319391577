<template>
  <v-card-actions class="bt sticky-actions">
    <fp-btn
      :disabled="!isFormValid || store.loading"
      :loading="store.loading"
      primary
      :click="() => formNavigation.showOffers()">
      <v-icon>mdi-view-list</v-icon>
      {{ $vuetify.breakpoint.mdAndUp ? I18NGetter().useSimulationNavigation.SHOW_OFFERS : I18NGetter().useSimulationNavigation.OFFERS }}
    </fp-btn>
    <fp-btn v-if="step > FormSteps.CREDIT_PARAMETERS" :click="() => formNavigation.previousStep()">
      <v-icon>mdi-chevron-left</v-icon>
      <span v-if="$vuetify.breakpoint.mdAndUp">
        {{ I18NGetter().useSimulationNavigation.PREVIOUS_STEP }}
      </span>
    </fp-btn>
    <fp-btn v-if="step < FormSteps.LIABILITIES" :click="() => formNavigation.nextStep()" :disabled="!isFormValid">
      <span v-if="$vuetify.breakpoint.mdAndUp">
        {{I18NGetter().useSimulationNavigation.NEXT_STEP }}
      </span>
      <span v-else>{{ I18NGetter().useSimulationNavigation.NEXT }}</span>
      <v-icon>mdi-chevron-right</v-icon>
    </fp-btn>
  </v-card-actions>
</template>
<script lang="ts">
import {useFormNavigation} from '@/components/calculator/form/useSimulationForm';
import {computed, defineComponent} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {FormSteps} from '@/components/calculator/CalculatorRoutes';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
export default defineComponent({
  props: {
    isFormValid: Boolean,
  },
  setup() {
    const formNavigation = useFormNavigation();
    const store = useSimulationStore();
    const step = computed<number>(() => formNavigation.stepperModel.value);

    return {
      formNavigation,
      step,
      store,
      FormSteps,
      I18NGetter,
    };
  },
});
</script>
<style lang="scss"></style>
