import VueScrollTo from 'vue-scrollto';
import {wait} from '@/services/utils/BasicUtils';

const ScrollTo = {
  top(): void {
    setTimeout(() => VueScrollTo.scrollTo('body'), 100);
  },
  async firstError(wrapper: HTMLElement = document.querySelector('form') as HTMLElement, container: HTMLElement = document.documentElement): Promise<void> {
    await wait(100);
    const element = wrapper.querySelector('.error--text');
    if (element) {
      VueScrollTo.scrollTo(element as Element, {
        container,
        offset: -200,
      });
    } else {
      console.error('cannot find error element.');
    }
  },
  async element(element: HTMLElement, container?: HTMLElement,): Promise<void> {
    await wait(100);
    const dialogNodes = document.querySelectorAll('[role="dialog"]');
    const dialog = dialogNodes[dialogNodes.length - 1]?.getElementsByClassName('v-card__text')[0];
    VueScrollTo.scrollTo(element, {
      container: dialog || container,
      offset: -200,
    });
  },
};

export default ScrollTo;
