export enum LeadKey {
  ADDING_CLIENT = 'ADDING_CLIENT',
  BANKING_SECRECY = 'BANKING_SECRECY',
  CLIENT_INFO = 'CLIENT_INFO',
  FILL_AGREEMENTS = 'FILL_AGREEMENTS',
  FILL_APPOINTMENT_FORM = 'FILL_APPOINTMENT_FORM',
  GO_TO_DEALS = 'GO_TO_DEALS',
  INITIAL_FORM = 'INITIAL_FORM',
  PASS_LEAD = 'PASS_LEAD',
  PASS_LEAD_INFO_1 = 'PASS_LEAD_INFO_1',
  PRESS_NEW_ACTIVITY= 'PRESS_NEW_ACTIVITY',
  RECOMMENDED_EXPERT = 'RECOMMENDED_EXPERT',
  SEND_AGREEMENT_BANKING_SECRECY = 'SEND_AGREEMENT_BANKING_SECRECY',
  SMS = 'SMS',
  START = 'START',
  SYSTEM_FORCE_APPOINTMENT_2 = 'SYSTEM_FORCE_APPOINTMENT_2',
}
