<template>
  <div>
    <simulation-form-wrapper
        :loading="store.loading"
        :simulation="store.simulation"
        :simulation-demand="store.userInput">
      <template #form="{isFormValid}">
        <router-view :simulationDemand="store.userInput" :isFormValid="isFormValid"/>
      </template>
    </simulation-form-wrapper>
  </div>
</template>
<script lang="ts">

import SimulationFormWrapper from '@/components/calculator/form/SimulationFormWrapper.vue';
import {cloneDeep} from 'lodash-es';
import {defineComponent, getCurrentInstance} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {Utils} from '@/services/utils/BasicUtils';
import {ProductType} from '@/commons/enums/ProductType';
import {useDealForm, useFormNavigation} from '@/components/calculator/form/useSimulationForm';
import useInitialDemands from '@/components/UseInitialDemands';

const initialDemand = cloneDeep(useInitialDemands().productTypeMortgage!);

export default defineComponent({
  components: {SimulationFormWrapper,},
  setup(props, context) {
    const instance = getCurrentInstance();
    const store = useSimulationStore();
    const formNavigation = useFormNavigation();
    const simulationId = Utils.getFirstQuery(instance!.proxy.$route.query.simulationId) || null;
    if (!simulationId) {
      store.$reset();
      store.setUserInput(initialDemand);
    }
    store.init(ProductType.MORTGAGE, simulationId);
    return {
      store,
      formNavigation,
    };
  },
});

</script>
<style scoped lang="scss">

</style>
