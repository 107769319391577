<template>
  <v-hover v-slot="{ hover }">
    <v-slider
        :step="sliderConfig.STEP_SIZE"
        hide-details
        v-model="model"
        :min="sliderConfig.MIN_LOAN_PERIOD_VALUE"
        :max="sliderConfig.MAX_LOAN_PERIOD_VALUE"
        :thumb-size="sliderConfig.THUMB_SIZE"
        append-icon="mdi-plus"
        prepend-icon="mdi-minus"
        @click:append="onAppend"
        @click:prepend="onPrepend"
        :thumb-label="hover ? 'always' : true"
        color="primary">
      <template v-slot:thumb-label="{ value }">
        <span class="font-weight-medium body-2">{{ value }} {{ I18NGetter().useLoanPeriodSlider.YEARS }}</span>
      </template>
    </v-slider>
  </v-hover>
</template>
<script lang="ts">

import {computed, defineComponent} from 'vue';
import EnvironmentService from '@/env/EnvironmentService';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const sliderConfig = {
  STEP_SIZE: 1,
  MIN_LOAN_PERIOD_VALUE: 1,
  MAX_LOAN_PERIOD_VALUE: 35,
  THUMB_SIZE: 48,
};

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props, {emit,}) {
    const emitInput = (value: number | null) => {
      emit('input', value);
    };

    const onAppend = () => model.value!++;
    const onPrepend = () => model.value!--;

    const model = computed<number | null>({
      get: () => EnvironmentService.Environment.isBackendSimV2
        ? props.value ? Math.round(props.value / 12) : null
        : props.value,
      set: (newValue: number | null) => {
        const value = EnvironmentService.Environment.isBackendSimV2 ? newValue ? newValue * 12 : null : newValue;
        return emitInput(value);
      },
    });
    return {
      onPrepend,
      onAppend,
      model,
      sliderConfig,
      I18NGetter,
    };
  },
});
</script>
<style scoped lang="scss">
</style>
