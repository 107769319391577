import {AxiosResponse} from 'axios';
import jwt_decode from 'jwt-decode';
import UserApi from '@/modules/user/services/UserApi';
import EnvironmentService from '@/env/EnvironmentService';
import {RoleTypes} from '@/commons/enums/RoleTypes';
import AppRouter from '@/router';
import {Routes} from '@/router/Routes';
import UserCom from '@/services/log/UserCom';
import NotificationService from '@/modules/notification/NotificationService';
import store from '@/store/store';
import Logger from '@/services/log/Logger';
import {TOKEN_GETTER_KEY, TOKEN_TYPE_KEY, TokenType} from '@/commons/enums/TokenType';
import {Route} from 'vue-router';
import GoToService from '@/router/GoToService';
import {LoginData} from '@/models/LoginData';
import JwtData from '@/models/user/JwtData';
import {keys, merge} from 'lodash-es';
import Vue from 'vue';
import {ProductType} from '@/commons/enums/ProductType';
import {Role} from '@/models/user/Role';
import {IUser} from '@/models/user/IUser';
import LogRocket from 'logrocket';
import {I18N} from '@/services/enumTranslator/I18NDictionary';
import {LoginDataResponseDTO} from '@/models/user/LoginDataResponseDTO';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const LAST_PATH_KEY = 'LAST_PATH';

export const TABLE_FILTER_KEYS = {
  LAST_REPORT: 'LAST_REPORT_TABLE_FILTER',
  REGULATIONS_LIST: 'REGULATIONS_LIST_TABLE_FILTER',
  LAST_LOAN: 'LAST_LOAN_TABLE_FILTER',
  LAST_CLIENT: 'LAST_CLIENT_TABLE_FILTER',
  LAST_DEALS: 'LAST_DEALS_TABLE_FILTER',
  LAST_KANBAN_DEALS: 'LAST_KANBAN_DEALS',
  LAST_PROSPECT: 'LAST_PROSPECT_TABLE_FILTER',
  LAST_TRAININGS: 'LAST_TRAININGS_TABLE_FILTER',
};

class AuthService {
  private UserData: Nullable<IUser> = null;

  public get token(): string | null {
    return localStorage.getItem(TOKEN_GETTER_KEY) || null;
  }

  public set token(newToken: string | null) {
    if (newToken) {
      localStorage.setItem(TOKEN_TYPE_KEY, TokenType.EXPERT);
      localStorage.setItem(TOKEN_GETTER_KEY, newToken);
    } else {
      console.error('newToken is null');
    }
  }

  public setTokens(response: AxiosResponse) {
    if (response.headers.hasOwnProperty('authorization')) {
      this.token = response.headers.authorization.replace('Bearer ', '') || null;
    }
  }

  public async logout(lastRoute?: Route) {
    if (lastRoute) {
      localStorage.setItem(LAST_PATH_KEY, lastRoute.fullPath);
    }
    try {
      await UserApi.logout();
    } catch (e) {
      console.error(e);
    }
    await AppRouter.push({
      name: Routes.PASSWORD_MANAGEMENT.LOGIN,
    });
    this.resetTablesFilters();
    console.warn('Logout');
    localStorage.removeItem(TOKEN_GETTER_KEY);
    NotificationService.disconnect();
    this.User = null;
    store.commit('reset');
  }

  private resetTablesFilters() {
    keys(TABLE_FILTER_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });
  }

  async logIn(loginData: LoginData) {
    window.localStorage.removeItem(TOKEN_GETTER_KEY);
    const loginDataResponse: LoginDataResponseDTO = await UserApi.login(loginData);
    const hasLanguageChanged = loginDataResponse.user.language &&
      loginDataResponse.user.language !== I18N.systemLanguage;
    if (loginDataResponse.user.language && hasLanguageChanged) {
      I18N.setSystemLanguage(loginDataResponse.user.language);
    }
    if (loginDataResponse.activities?.hasExpired) {
      AppRouter.push({name: Routes.BLOCKED_ACCESS,},);
    } else {
      this.enterUserPanel();
      const logrocketid = process.env.VUE_APP_LOGROCKET_ID;
      if (logrocketid && this.User && this.User.privileges.isAgentCC) {
        LogRocket.init(`${logrocketid}/fincrm-frontend`);
        LogRocket.identify('User ID:' + String(this.User.id));
      }
    }
    if (hasLanguageChanged && !loginDataResponse.user.requirePasswordChange) {
      setTimeout(() => window.location.reload(), 3000);
    }
  }

  public enterUserPanel() {
    const lastPath = localStorage.getItem(LAST_PATH_KEY);
    if (lastPath) {
      const pathBeforeChange = AppRouter.currentRoute;
      AppRouter.push({path: lastPath,});
      window.localStorage.removeItem(LAST_PATH_KEY);
      if (pathBeforeChange.fullPath === lastPath) {
        GoToService.dashboard();
      }
    } else {
      GoToService.dashboard();
    }
  }

  public get isLoggedIn() {
    return this.token;
  }

  public async changeRole(id: RoleTypes) {
    try {
      const response = await UserApi.changeRole(id);
      this.revokeToken(response);
    } catch (e) {
      console.error(e);
    }
  }

  public async dropRole() {
    try {
      const response = await UserApi.dropRole();
      this.revokeToken(response);
    } catch (e) {
      console.error(e);
    }
  }

  public get User(): Nullable<IUser> {
    return this.UserData;
  }

  public set User(user: Nullable<IUser>) {
    this.UserData = user;
    UserCom.setUserData(user);
  }

  private CurrentRole: Role = Vue.observable(new Role());

  public get isCurrentlyVerifier(): boolean {
    return this.CurrentRole.id === RoleTypes.VERIFIER;
  }

  public get isSystemBlocked(): boolean {
    const hasExpiredActivities = Boolean(this.User?.privileges.hasExpiredActivities);
    const simplePartnerView = this.User?.privileges.simplePartnerView;

    return !simplePartnerView && (hasExpiredActivities);
  }

  public fetchJwtData(): Nullable<JwtData> {
    return this.token ? this.prepareJwtData(this.token) : null;
  }

  public get isTokenValid(): boolean {
    if (this.token) {
      return this.canDecodeToken(this.token);
    } else {
      return false;
    }
  }

  private setUserData(jwtData: JwtData) {
    if (this.User) {
      merge(this.User, jwtData.user);
    }
    const newRole = jwtData.currentRole || new Role();
    this.CurrentRole.id = newRole.id;
    this.CurrentRole.name = newRole.name;
    if (jwtData.user!.forceLogout) {
      this.logout();
      Vue.prototype.$snackbarService.openErrorSnackbar(I18NGetter().useAuthService.SET_USER_DATA_ERROR_SNACKBAR);
    }
  }

  public isTokenExpired = () => {
    try {
      if (this.token) {
        const now = new Date().valueOf();
        const date = new Date(0);
        const decoded = this.fetchJwtData();
        date.setUTCSeconds(decoded!.exp);
        return date.valueOf() < now;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  public revokeToken(response: AxiosResponse): void {
    if (response.headers.hasOwnProperty('authorization')) {
      const token = response.headers.authorization.replace('Bearer ', '');
      if (this.canDecodeToken(token)) {
        this.refreshUserData(token);
      } else {
        const data = {
          token: token,
          response: {
            body: response?.data,
            headers: response?.headers,
            url: response?.config.url,
            status: response?.status,
          },
        };
        Logger.error('Cannot decode token', data);
      }
    }
  }

  public permitSimulation(productType: Nullable<ProductType>): boolean {
    switch (productType) {
    case ProductType.MORTGAGE:
      return !this.User!.privileges.mortgageLoansDisabled;
    case ProductType.CASH:
      return !this.User!.privileges.cashLoansDisabled;
    case ProductType.SME:
      return !this.User!.privileges.smeLoansDisabled;
    default:
      return false;
    }
  }

  private canDecodeToken(token: string): boolean {
    if (token && token.length > 0) {
      try {
        jwt_decode(token);
        return true;
      } catch (e) {
        console.error('Occurred error when decoding token', e);
        return false;
      }
    } else {
      return false;
    }
  }

  private prepareJwtData(token: string): Nullable<JwtData> {
    try {
      return new JwtData(jwt_decode(token));
    } catch (e) {
      Logger.error('Occurred error when decoding token and converting to JwtData', {token: token,});
      console.error('Occurred error when decoding token and converting to JwtData', e);
      return null;
    }
  }

  private refreshUserData(token: string) {
    const jwtData = this.prepareJwtData(token);
    if (jwtData) {
      this.token = token;
      this.setUserData(jwtData);
    }
  }

  get canEditProcedure(): boolean {
    return !!this.User?.isAdminOrVerifier && EnvironmentService.Environment.canEditProcedure;
  }

  get canEditRegulation(): boolean {
    return !!this.User?.isAdminOrVerifier && EnvironmentService.Environment.canEditRegulation;
  }
}

export default new AuthService();
