<template>
  <multiform-list-manager
    :type="FFHousehold"
    :readonly="false"
    :value="households"
    :itemName="I18NGetter().useSimulationFormStep2And3.HOUSEHOLDS">
    <template #default="{item}">
      <multiform-list-manager
        :type="FFBorrower"
        :readonly="false"
        :value="item.borrowers"
        :itemName="I18NGetter().useSimulationFormStep2And3.BORROWER">
        <template #default="{item}">
          <simulation-form-step2 :borrower="item" />
        </template>
      </multiform-list-manager>
    </template>
  </multiform-list-manager>
</template>
<script lang="ts" setup>
import {defineComponent} from 'vue';
import {useHouseholds} from '@/components/calculator/form/useSimulationForm';
import MultiformListManager from '@/modules/multiForm/views/MultiformListManager.vue';
import SimulationFormStep2 from '@/components/calculator/form/SimulationFormStep2.vue';
import {FFHousehold} from '@/models/simulation/Simulation';
import {FFBorrower} from '@/models/ExpertState';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
const {households,} = useHouseholds();

</script>
<style scoped lang="scss">

</style>
