import {PartnerStoreState} from '@/modules/partner/services/store/PartnerStoreState';
import Partner from '@/models/Partner';

const state: PartnerStoreState = {
  Partners: [],
  partnerApiActive: true,
};

export const PartnerStoreModule = {
  namespaced: false,
  state,
  getters: {
    partners(state: PartnerStoreState) {
      return state.Partners.map((partner) => new Partner(partner));
    },
    partnerApiActive(state: PartnerStoreState) {
      return state.partnerApiActive;
    },
  },
  mutations: {
    setPartners(state: PartnerStoreState, partners: Array<Partner>) {
      state.Partners = partners;
    },
    setPartnerApiActive(state: PartnerStoreState, status: boolean) {
      state.partnerApiActive = status;
    },
    addPartner(state: PartnerStoreState, status: boolean) {
      state.partnerApiActive = status;
    },
  },
};
