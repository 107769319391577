<template>
  <v-row>
    <v-col>
      <template v-for="(land, index) in userLands">
        <v-row wrap :key="index">
          <v-col xs11>
            <v-row wrap>
              <fp-select
                xs12
                :prepend-icon="`mdi-numeric-${index + 1}-box`"
                :label="I18NGetter().useLandOfWorkForm.PROVINCE"
                v-model="land.id"
                @input="land.districts = []"
                :items="lands(land.id)"
              ></fp-select>
              <v-col cols="12" v-if="$env.appDomainFeatures.isDistrictsOn">
                <v-combobox
                  prepend-icon="none"
                  :label="I18NGetter().useLandOfWorkForm.COUNTY"
                  multiple
                  chips
                  item-text="name_pl"
                  item-value="type"
                  outlined
                  deletable-chips
                  @keypress.ctrl="() => selectAllDistricts(land, $event)"
                  :disabled="!land.id"
                  v-model="land.districts"
                  :items="useGeoAreas.districtDictionary(land.id)"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="shrink align-center d-flex">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on"
                       icon color="error"
                       @click="removeLandOfWork(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{I18NGetter().useLandOfWorkForm.DELETE_LAND }}
            </v-tooltip>
          </v-col>
        </v-row>
        <v-divider :key="`d-${index}`" class="my-2"></v-divider>
      </template>
      <v-row class="align-center" v-if="userLands.length < MAX_LANDS">
        <v-col class="pl-0">
          <v-btn color="primary"
                 icon
                 @click="addNewLandOfWork">
            <v-icon>mdi-briefcase-plus-outline</v-icon>
          </v-btn>
          <a class="primary--text" @click="addNewLandOfWork">
            {{I18NGetter().useLandOfWorkForm.ADD_LAND }}
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {LandOfWork} from '@/models/LandOfWork';
import {IMultiformDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import useGeoAreas from '@/composables/useGeoAreas';

const KEY_A_CODE = 'KeyA';

@Component
export default class LandOfWorkForm extends Vue {
  @Prop() public userLandsData!: LandOfWork[];

  @Watch('userLandsData')
  onLandsChange() {
    this.initLands();
  }

  public get userLands(): LandOfWork[] {
    return this.userLandsData;
  }

  public I18NGetter = I18NGetter;

  public set userLands(newVal: LandOfWork[]) {
    this.userLandsData = newVal;
    this.$emit('update:userLandsData', this.userLandsData);
  }

  public readonly MAX_LANDS: number = 6;
  public useGeoAreas = useGeoAreas();

  lands(landId?: number): IMultiformDictionary<number> {
    return this.useGeoAreas.landDictionary()
      .filter(land => landId === land.type || !this.userLands.some(l => l.id === land.type));
  }

  mounted() {
    this.initLands();
  }

  private initLands() {
    if (this.userLands!.length === 0) {
      this.addNewLandOfWork();
    }
  }

  private addNewLandOfWork() {
    this.userLands!.push(new LandOfWork());
  }

  private removeLandOfWork(index: number) {
    this.userLands!.splice(index, 1);
  }

  private selectAllDistricts(land: LandOfWork, event: KeyboardEvent) {
    if (event.code === KEY_A_CODE && land.id) {
      const allLands = this.useGeoAreas.districtDictionary(land.id);
      land.districts = allLands.length === land.districts.length ? [] : allLands;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
