<template>
  <v-card>
      <v-card-text class="pa-0 text-center body-1">
        <img src="../../assets/img/lendi-update.svg" class="d-block mx-auto mt-10 mb-4" alt="asd">
        {{ $i18n.useRefreshApplicationModal.CRM_UPDATED }}

      <div class="diclaimer py-2 mt-6">
        {{ $i18n.useRefreshApplicationModal.ATTENTION_NOT_SAVED_DATA }}
      </div>
      </v-card-text>
      <v-card-actions>
          <v-btn grow @click="closeModal" large outlined>{{ $i18n.useButtonCommons.CANCEL }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn grow @click="reload()" depressed large color="primary">{{ $i18n.useRefreshApplicationModal.REFRESH_APPLICATION }}</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { VueModalComponent } from 'fp-components';
import { Component } from 'vue-property-decorator';
import UserStore from '@/modules/user/services/store/UserStore';

@Component
export default class RefreshApplicationModal extends VueModalComponent<void> {
  public reload() {
    window.location.reload();
  }
}
</script>

<style>
  .diclaimer {
    background-color: rgba(255, 201, 26, 0.4);
  }
</style>
