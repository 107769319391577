<template>
  <v-row>
    <!-- this functionallity to rewrite -->
    <v-col>
      <v-layout wrap pb-3>
        <v-flex xs12>
          <PlaceholderButton
            v-show="!activity.isVacation"
            :block="true"
            :x-large="true"
            :clicked="signDocumentActivityKind"
            :value="isPassedClient"
            :label="$vuetify.breakpoint.mdAndUp ?
              I18NGetter().useEditActivityForm.LINK_TASK_TO_CLIENT : I18NGetter().useEditActivityForm.LINK_TO_CLIENT"
            prepend-icon="mdi-account-search"
          >
            <ClientAutocomplete
              class="fp-input-outline"
              :disabled="isPassedClient"
              :label="I18NGetter().useEditActivityForm.SEARCH_CLIENT"
              :required="signDocumentActivityKind"
              :is-company="activity.productType === productType.SME"
              v-model="activity.client"
              @input="onClientPicked"
              singular
            />
            <v-layout v-if="!hideDealSelection">
              <fp-select
                v-model="activity.dealId"
                @change="onDealPicked"
                :items="clientDeals"
                :label="I18NGetter().useEditActivityForm.CHOOSE_CLIENTS_DEAL"
                :required="signDocumentActivityKind"
                item-name="name"
                item-key="id"
                prepend-icon="none"
              />
            </v-layout>
            <v-layout v-if="signDocumentActivityKind">
              <fp-select
                v-model="activity.bankBranchId"
                :no-data-text="I18NGetter().useEditActivityForm.NO_BRANCH_ADDRESS"
                :label="I18NGetter().useEditActivityForm.BANK_BRANCH"
                :items="dictionary"
                :required="true"
                prepend-icon="mdi-map-marker"
                item-key="id"
                item-name="displayName"
                :filter="bankCustomFilter"
                :threshold="5"
                :loading="dictionaryStore.getApiState.bankBranchesDictionary"
              >
                <template #selection="{ item }">
                  <v-avatar
                    v-if="item.bank && item.bank.symbolRequire"
                    size="32px"
                    class="mr-4 my-1 align-self-center">
                    <img alt="bank avatar" :src="item.bank.symbolRequire">
                  </v-avatar>
                  <div>
                    <template v-if="item.bankId > 0">
                      <p class="d-inline-block mb-1 font-weight-bold text-uppercase overline"
                        :style="{color: item.bank.color ? item.bank.color : ''}">
                        {{ item.bank.name_pl }}
                      </p> <br>
                    </template>
                    <p class="d-inline-block mb-1 word-brake-normal d-inline">
                      {{ item.name }}
                    </p>
                  </div>
                </template>
                <template #item="{ item }">
                  <v-row no-gutters align="center" class="my-2">
                    <v-col class="shrink">
                      <v-avatar
                        v-if="item.bank && item.bank.symbolRequire"
                        size="32px"
                        class="mr-4 my-1 align-self-center">
                        <img alt="bank avatar" :src="item.bank.symbolRequire">
                      </v-avatar>
                    </v-col>
                    <v-col class="align-center">
                      <p v-if="item.bankId > 0"
                        class="mb-0 font-weight-bold text-uppercase overline"
                        :style="{color: item.bank.color ? item.bank.color : ''}">
                        {{ item.bankId === 0 ? '' : item.bank.name_pl }}
                      </p>
                      <p class="mb-0 word-brake-normal">
                        {{ item.name }}
                      </p>
                    </v-col>
                  </v-row>
                </template>
              </fp-select>
            </v-layout>
          </PlaceholderButton>
        </v-flex>
        <fp-input
          v-model="activity.name"
          :id="$cy.activityName"
          :label="I18NGetter().useEditActivityForm.TASK_NAME"
          required
          xs12
          prepend-icon="edit"
          @click:clear="activity.name = ''"
        />
        <fp-checkbox
          class="pl-10"
          v-if="activity.client && activity.kind === activityKind.PHONE"
          xs12
          :label="I18NGetter().useEditActivityForm.REQUIRE_NOTIFICATION"
          v-model="activity.requireNotification"
        />
      </v-layout>
      <ActivityMeetingForm
        v-if="activity.kind === activityKind.MEETING"
        :value="activity"
        :client="activity.client"
        :owner="user"
        class="py-3"
      />
      <v-layout>
        <fp-checkbox
          class="mt-0 ml-10"
          v-model="activity.isBusy"
          :disabled="alwaysBusyActivityKinds.includes(activity.kind)"
          :label="I18NGetter().useEditActivityForm.BLOCK_AVAILABILITY_IN_CALENDAR"
        />
        <v-tooltip top max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="align-self-center ml-2"
            >
              mdi-information
            </v-icon>
          </template>
              <span>
                {{ $i18n.useEditActivityForm.BLOCK_AVAILABILITY_IN_CALENDAR_INFO }}
              </span>
        </v-tooltip>
      </v-layout>
      <EditActivityFormDateInputs
        :activity="activity"
        :isBusy="isBusy"
        :is-lead="isLead"
        @isBusyChange="onIsBusyChange"
      />
      <AlertMessage v-if="isBusy" :alert-type="AlertType.ERROR">
        <span v-if="isEdit">{{ I18NGetter().useEditActivityForm.BUSY_DATE_EDIT_ACTIVITY }}</span>
        <span v-else>{{ I18NGetter().useEditActivityForm.BUSY_DATE_NEW_ACTIVITY }}</span>
      </AlertMessage>
      <v-row v-if="isDealOrClient" class="py-3">
        <v-col xs12>
          <v-textarea v-model="newNote.text"
                      class="fp-input-outline"
                      outlined
                      hide-details
                      :label="getNoteLabel()"
          />
        </v-col>
      </v-row>
      <v-row class="py-3" v-else>
        <v-col xs12>
          <v-textarea v-model="activity.description"
                      class="fp-input-outline"
                      :label="I18NGetter().useEditActivityForm.TASK_DESC"
                      hide-details
                      outlined
            />
        </v-col>
      </v-row>
      <v-row v-if="activity.client || activity.dealId">
        <v-col xs12>
          <p class="headline-5 mb-6">
            {{ activity.dealId ? I18NGetter().useEditActivity.RECENT_NOTES_ON_DEAL : I18NGetter().useEditActivity.RECENT_NOTES_ON_CLIENT }}
          </p>
          <HistoryElements
            :notes-only="true"
            :show-new-note-option="false"
            :historyElements="historyElements"
            :last="MAX_HISTORY_LENGTH"
          />
          <AlertMessage v-if="historyElements.length > MAX_HISTORY_LENGTH" :alert-type="AlertType.INFO" class="mt-6">
            {{ activity.dealId ? I18NGetter().useEditActivity.MORE_NOTES_INFO_DEAL : I18NGetter().useEditActivity.MORE_NOTES_INFO_CLIENT }}
          </AlertMessage>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { ProductType } from '@/commons/enums/ProductType';
import { BankBranch } from '@/commons/services/models/BankBranchesDictionary';
import DictionaryStore from '@/commons/services/store/DictionaryStore';
import PlaceholderButton from '@/components/PlaceholderButton.vue';
import AlertMessage from '@/components/commons/message/AlertMessage.vue';
import HistoryElements from '@/components/history/HistoryElements.vue';
import ClientAutocomplete from '@/components/inputs/ClientAutocomplete.vue';
import Activity from '@/models/Activity';
import Deal from '@/models/Deal';
import { HistoryElement } from '@/models/HistoryElement';
import Note from '@/models/Note';
import { AlertType } from '@/models/enums/AlertType';
import { UserListElement } from '@/models/ppl/UserListElement';
import { ActivityKind } from '@/modules/activities/enums/ActivityKind';
import ActivityMeetingForm from '@/modules/activities/views/activity-meeting-details/ActivityMeetingForm.vue';
import EditActivityFormDateInputs from '@/modules/activities/views/edit-activity/EditActivityFormDateInputs.vue';
import ClientApi from '@/modules/client/services/ClientApi';
import { FpMessageType } from '@/modules/deals/enums/FpMessageType';
import DealsApi from '@/modules/deals/services/DealsApi';
import UserApi from '@/modules/user/services/UserApi';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import { I18NInterface } from '@/services/enumTranslator/I18NInterface';
import { uniqWith } from 'lodash-es';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    EditActivityFormDateInputs,
    ClientAutocomplete,
    PlaceholderButton,
    ActivityMeetingForm,
    HistoryElements,
    AlertMessage,
  },
})
export default class EditActivityForm extends Vue {
  @Prop({required: true,})
  readonly activity!: Activity;

  @Prop({required: false,})
  readonly hideDealSelection!: boolean;

  @Prop({required: false,})
  readonly currentUser!: UserListElement;

  @Prop({required: false, default: false,})
  readonly isLead!: boolean;

  @Prop({required: false, default: '',})
  readonly newNote!: Note;

  @Prop({required: false, default: false,})
  isBusy!: boolean;

  @Prop({required: false, default: false,})
  readonly isEdit!: boolean;

  productType = ProductType;
  clientDeals: Deal[] = [];
  dictionary: BankBranch[] = [];

  isPassedClient: Boolean = false;
  isPassedDealId: Boolean = false;
  user: UserListElement = new UserListElement();
  I18NGetter: () => I18NInterface = I18NGetter;
  readonly activityKind = ActivityKind;
  alwaysBusyActivityKinds: readonly ActivityKind[] = [
    ActivityKind.SIGNING_DOCUMENTS,
    ActivityKind.VACATION,
    ActivityKind.MEETING,
  ];

  historyElements: HistoryElement<any>[] = [];
  MAX_HISTORY_LENGTH = 3;
  AlertType = AlertType;

  @Watch('activity.kind')
  onActivityKindChange(newValue: ActivityKind, oldValue: ActivityKind) {
    this.activity.requireNotification = newValue !== this.activityKind.PHONE;
    this.setIsBusy(newValue, oldValue);
    if (this.activity.client) {
      this.isPassedClient = true;
    }
    if (this.activity.isVacation) {
      this.resetClientAndDeal();
    }
  }

  resetClientAndDeal(): void {
    this.activity.client = null;
    this.activity.deal = null;
    this.activity.dealId = undefined;
    this.activity.clientId = undefined;
    this.isPassedClient = false;
  }

  setIsBusy(newValue: ActivityKind, oldValue: ActivityKind): void {
    const isBusyActivitySelected: boolean = this.alwaysBusyActivityKinds.includes(newValue);
    const wasBusyActivitySelected: boolean = this.alwaysBusyActivityKinds.includes(oldValue);
    if (isBusyActivitySelected) {
      this.activity.isBusy = true;
    } else if (wasBusyActivitySelected) {
      this.activity.isBusy = false;
    }
  }

  get signDocumentActivityKind(): boolean {
    return this.activity.kind === ActivityKind.SIGNING_DOCUMENTS;
  }

  get dictionaryStore(): typeof DictionaryStore {
    return DictionaryStore;
  }

  async mounted(): Promise<void> {
    if (this.activity.client) {
      this.isPassedClient = true;
      await this.getClientDeals();
    }
    if (this.activity.bankBranch?.id) {
      this.activity.bankBranchId = this.activity.bankBranch.id;
    }
    if (this.activity.dealId) {
      this.isPassedDealId = true;
    }
    await this.fetchBankBranchesDictionary();
    if (this.currentUser !== undefined) {
      this.user = this.currentUser;
    } else {
      this.user = new UserListElement({
        id: this.$user.id,
        officeAddress: null,
        email: this.$user.email,
        lastName: this.$user.lastName,
        phone: this.$user.phone,
        pesel: this.$user.pesel,
        firstName: this.$user.firstName,
        canMeetOutsideOffice: this.$user.privileges?.canMeetOutsideOffice,
      });
      this.user.officeAddress = await UserApi.getOfficeAddress();
    }

    this.setHistoryElements();
  }

  bankCustomFilter(item: any, queryText: string, itemText: string): boolean {
    const searchValues = queryText.split(' ');

    return searchValues.every((value) => {
      return !value || itemText.toLocaleLowerCase().includes(value.toLocaleLowerCase());
    });
  }

  async fetchBankBranchesDictionary(): Promise<void> {
    try {
      const response = await DictionaryStore.getAsyncDictionary('bankBranchesDictionary');
      // TODO check is this filterting really needed
      this.dictionary = response.filter(el => el.bank && el.id && el.bankId);
    } catch (e) {
      this.errorHandler(e, this.$i18n.useBankBranchesFetch.FAILED_TO_LOAD_BANK_BRANCHES_LIST);
    }
  }

  async onClientPicked() {
    if (!this.activity.client) {
      this.activity.deal = null;
      this.activity.dealId = undefined;
    }
    this.activity.name = this.activity.name.split('z ')[0];
    if ((this.activity.kind === ActivityKind.MEETING ||
            this.activity.kind === ActivityKind.PHONE ||
            this.activity.kind === ActivityKind.EMAIL ||
            this.activity.kind === ActivityKind.SIGNING_DOCUMENTS) &&
        this.activity.client?.displayName) {
      this.activity.name += `${this.activity.kind === ActivityKind.MEETING || ActivityKind.SIGNING_DOCUMENTS ? I18NGetter().useEditActivityForm.WITH : I18NGetter().useEditActivityForm.TO} ${this.activity.client.displayName}`;
    }
    await this.getClientDeals();
    this.setHistoryElements();
  }

  async onDealPicked() {
    this.setHistoryElements();
  }

  async getClientDeals() {
    if (!this.hideDealSelection && this.activity.client?.id) {
      try {
        this.clientDeals = await DealsApi.getDealsByClientId(this.activity.client!.id);
      } catch (e) {
        this.errorHandler(e, I18NGetter().useEditActivityForm.GET_CLIENT_DEALS_ERROR_HANDLER);
      }
    }
  }

  get isOnlyClient() {
    return this.activity.client && !this.activity.dealId;
  }

  get isDealOrClient() {
    return this.activity.client || this.activity.dealId;
  }

  getNoteLabel() {
    if (this.isOnlyClient) {
      return I18NGetter().useDealAttributesCard.NOTE_FOR_CLIENT;
    } else if (this.isDealOrClient) {
      return I18NGetter().useDealAttributesCard.NOTE_FOR_DEAL;
    }
  }

  async fetchClientHistory(clientId: number) {
    try {
      return await ClientApi.getClientHistory(clientId);
    } catch (e) {
      this.errorHandler(e, I18NGetter().useClientDetails.FETCH_HISTORY);
    }
  }

  async fetchDealHistory(dealId: number) {
    try {
      return await DealsApi.getDealHistory(dealId);
    } catch (e) {
      this.errorHandler(e, I18NGetter().useClientDetails.FETCH_HISTORY);
    }
  }

  async setHistoryElements(): Promise<void> {
    const elements: Array<HistoryElement<any>> = this.activity.dealId
      ? await this.fetchDealHistory(this.activity.dealId) || []
      : this.activity.client ? await this.fetchClientHistory(this.activity.client.id) || [] : [];
    this.historyElements = uniqWith(elements.filter(element => element.type === FpMessageType.NOTE_ADDED) || [],
      (first, second) => {
        return first.data?.text === second.data?.text && first.data?.createdAt === second.data?.createdAt;
      }
    );
  }

  onIsBusyChange = (value: boolean) => {
    this.isBusy = value;
  }
}
</script>

<style scoped lang="scss">
  .file-display {
    white-space: nowrap;
    border-bottom: 1px solid colorShade(black, 0.24);
    border-left: 1px solid colorShade(black, 0.24);
    border-right: 1px solid colorShade(black, 0.24);
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .overline {
    line-height: 1;
  }

</style>
