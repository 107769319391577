<template>
  <component :is="tag" v-intersect="intersect">
    <slot v-if="componentExist" />
  </component>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';

export default defineComponent({
  props: {
    tag: {
      default: 'div',
      type: String,
    },
    options: {
      required: false,
      type: Object as PropType<IntersectionObserverInit>,
    },
    debug: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const componentExist = ref<boolean>(false);
    const intersect = {
      handler: (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        if (props.debug) {
          console.log(entries[0].target, entries[0]);
        }
        componentExist.value = !entries[0]?.rootBounds || entries[0]?.isIntersecting;
      },
      options: props.options || {},
    };
    return {
      intersect,
      componentExist,
    };
  },
});
</script>
