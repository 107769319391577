import {FpAxios} from 'fp-components';
import getDevelopmentVerificationCode from '@/commons/services/getDevelopmentVerificationCode';
import EnvironmentService from '@/env/EnvironmentService';

class ClientSmsApi {
  private clientSmsApiAxios = FpAxios.create();
  readonly baseUrl = 'sms/code';

  async sendVerificationCode(phone: string, isBik?: true, organisationId?: number) {
    const response = await this.clientSmsApiAxios.post(`${this.baseUrl}/${phone}`, undefined, {
      params: {
        organisation_id: organisationId,
        is_bik: isBik,
      },
    });
    return response.data.message;
  }

  async checkVerificationCode(phone: string, code: string) {
    const response = await this.clientSmsApiAxios.get(`${this.baseUrl}/${phone}/${code}`);
    return response.data.message;
  }

  // For developer use only
  async getVerificationCodeIfDev(phone: string): Promise<string> {
    if (EnvironmentService.Environment.isPreprodOrDev) {
      return getDevelopmentVerificationCode(phone);
    } else return '';
  }
}

export default new ClientSmsApi();
