import {Person} from '@/models/Person';
import {IClientable} from '@/models/interfaces/IClientable';
import Client, {PersonalId} from '@/models/Client';
import {ProspectStatus} from '@/modules/prospects/enums/ProspectStatus';
import Activity from '@/models/Activity';
import Deal from '@/models/Deal';
import {ProductType} from '@/commons/enums/ProductType';
import MultiformDictionary, {
  civilStatusDictionary
} from '@/modules/multiForm/shared/MultiformDictionaries';
import {IMeeting} from '@/models/interfaces/IMeeting';
import {IPerson} from '@/models/interfaces/IPerson';
import {LossReason} from '@/types/ProspectLossReason';
import {TransferSourceType} from '@/modules/prospects/enums/TransferSourceType';
import {SimulationDemand} from '@/models/Demand';
import {ScenarioStep} from '@/modules/prospects/enums/ScenarioStatus';
import {SourceKind} from '@/modules/prospects/enums/SourceKind';
import {SourceChannel} from '@/modules/prospects/enums/SourceChannel';
import {CustomerInterest} from '@/models/enums/CustomerInterest';
import {CustomerCommitmentLevelType} from '@/models/enums/CustomerCommitmentLevelType';
import {AttributeTimeRange} from '@/models/enums/AttributeTimeRange';
import {
  BancovoBorrower,
  BancovoLoanApplication,
  mapIncomeTypeId,
  mapObligations
} from '@/models/BancovoLoanApplication';
import {CreditDetails, Income, PersonalDetails} from '@/modules/multiForm/shared/MultiformModels';
import {PersonalIdEnum} from '@/modules/client/enums/PersonalIdEnum';
import LoanApplication from '@/models/LoanApplication';
import {compact} from 'underscore';
import {IDealAttributesViewModel, RealEstateQuestionnaireAnswers} from '@/models/DealAttributes';
import {LoanProbabilityType} from '@/models/enums/LoanProbabilityType';
import {Agreements} from '@/modules/client/views/client-agreements/Agreements';
import {RealEstateSupportKind} from '@/models/enums/RealEstateSupportKind';
import {AvatarViewModel} from '@/models/AvatarViewModel';
import {Utils} from '@/services/utils/BasicUtils';
import {ProspectLoanType} from '@/models/ProspectLoanType';
import useGeoAreas from '@/composables/useGeoAreas';
import {AddressMeetingKind} from '@/commons/enums/AddressMeetingKind';
import {ActivityKind} from '@/modules/activities/enums/ActivityKind';
import {CivilStatus} from '@/modules/multiForm/shared/Types';

/* TODO: Ten plik trzeba rozbić na pare plików, ale chciałym to zrobić po standaryzacji modelu universal-model */

const getLandId = (land: string): number | null => {
  const foundLand = useGeoAreas().landDictionary()
    .find(l => l.name_pl.toLocaleLowerCase() === land.toLocaleLowerCase());
  return foundLand ? foundLand.type : null;
};

const getDistrictId = (district: string): number | null => {
  const foundDistrict = useGeoAreas().districtDictionary()
    .find(l => l.name_pl.toLocaleLowerCase() === district.toLocaleLowerCase());
  return foundDistrict ? foundDistrict.type : null;
};

export function prospectLoanTypeToProductType(prospectLoanType: ProspectLoanType): ProductType {
  return ('productType' + prospectLoanType) as ProductType;
}

export class ProspectContactDto implements Partial<LossReason> {
  firstname: Nullable<string> = null;
  lastname: Nullable<string> = null;
  name: Nullable<string> = null;
  phone: Nullable<string> = null;
  email: Nullable<string> = null;
  nip: Nullable<string> = null;
  regon: Nullable<string> = null;
  status: Nullable<ProspectStatus> = null;
  createdAt!: string;
  updatedAt!: string;
  activityAt?: string;
  lossReason: Nullable<number> = null;
  lossReasonDescription?: string;

  constructor(client?: Client) {
    if (client) {
      this.firstname = client.firstName;
      this.lastname = client.lastName;
      this.email = client.email;
      if (client.isCompany) {
        this.nip = client.nip;
        this.name = client.name;
      }
    }
  }
}

export class ProspectContact extends Person implements IPerson, IClientable, Partial<LossReason> {
  public phone!: string;
  public status!: ProspectStatus;
  public createdAt!: string;
  public updatedAt?: string;
  public nip?: string;
  public activityAt?: string;
  public agreements!: Agreements;
  public lossReason: Nullable<number> = null;
  public lossReasonDescription?: string;

  constructor(dto: ProspectContactDto, agreements: Agreements) {
    super();
    this.lastName = dto.lastname;
    this.firstName = dto.firstname;
    if (dto.nip) {
      this.nip = dto.nip!;
      this.isCompany = !!this.nip;
    }
    if (dto.name) {
      this.name = dto.name!;
    }
    this.phone = dto.phone!;
    this.email = dto.email;
    this.status = dto.status!;
    this.updatedAt = dto.updatedAt;
    this.createdAt = dto.createdAt;
    this.agreements = agreements;
    this.lossReason = dto.lossReason;
  }

  get avatarUrl(): string {
    return require('@/assets/prospect/prospekt.svg');
  }
}

export enum ProspectSourceName {
  EKO = 'ekspert-online',
  BANCOVO = 'bancovo.pl',
}

export enum DataGrabber {
  FACEBOOK = 'facebook',
  WIDGET = 'widget',
  EKO = 'eko',
  BUSINESS_CARD_EXPERT = 'business_card_expert',
  BUSINESS_CARD_OFFICE = 'business_card_office',
  CHECKBOX = 'checkbox',
  LANDING_PAGE = 'landing_page',
  CHATBOT = 'chatbot',
  OTHER = 'other',
  INSTALLMENT_COMPARER = 'installment_comparer',
  FORM_BEFORE_SME = 'form_before_sme',
}

export enum SourceForm {
  SEARCH = 'search',
  TEXT_LINK = 'text_link',
  BANNER = 'banner',
  MENU_NAV = 'menu_nav',
  COMPARER_WIDGET = 'comparer_widget',
  INSTALLMENT_WIDGET = 'installment_widget',
  ABILITY_WIDGET = 'ability_widget',
  MEETING_WIDGET = 'meeting_widget',
  FACEBOOK_POST = 'facebook_post',
  APPROVAL_APPLICATION = 'approval_application',
  CHATBOT = 'chatbot',
  SURVEY = 'survey',
  LEAD_MAGNET = 'lead_magnet',
  OTHER = 'other',
}

class ProspectSource {
  id!: number;
  name!: ProspectSourceName | string;
  domain: Nullable<string> = null;
  dataGrabber: Nullable<DataGrabber> = null;
  form: Nullable<SourceForm> = null;
  kind!: SourceKind;
  channel!: SourceChannel;
  uri!: string;
  campaignId!: string;
  adId!: string;
  key: string = '';
  keyword!: string;
  entryPage!: string;
  interest: Nullable<CustomerInterest> = null;
  transfer!: TransferSourceType;
  finpackSourceId: Nullable<number> = null;
  finpackPartnerId?: number;
  finpackPartnerName?: string;
  widgetOwnerId: Nullable<number> = null;
}

export class ProspectLoan {
  category: Nullable<ProspectLoanType> = ProspectLoanType.MORTGAGE;
  approval?: boolean;
  amount?: number;
}

export class ProspectMeetingDto implements IMeeting {
  voivodship: Nullable<string> = null;
  district: Nullable<string> = null;
  landId: number | null = null;
  districtId: number | null = null;
}

type ProspectActivity = {
  createdAt: string;
  updatedAt: string;
  startedAt: string;
  addressMeetingKind: AddressMeetingKind;
  kind: ActivityKind;
  isDone: boolean;
  id: number;
};

export class ProspectDto {
  id?: number;
  uuid?: string;
  key?: string;
  deal: Deal = new Deal();
  contact: ProspectContactDto = new ProspectContactDto();
  source: Nullable<ProspectSource> = new ProspectSource();
  ekoInput: Nullable<ProspectEkoInput> = null;
  scenarioStep!: ScenarioStep;
  agent: Nullable<ProspectAgentDto> = null;
  agentCC: Nullable<Person> = null;
  meeting: ProspectMeetingDto = new ProspectMeetingDto();
  createdAt!: string;
  loan: Nullable<ProspectLoan> = new ProspectLoan();
  agreement: Agreements = new Agreements();
  finpackPartnerId!: number;
  finpackPartnerName!: string;
  finpackSourceId!: number;
  finpackDealId: Nullable<number> = null;
  usercomDealId: Nullable<number> = null;
  activityAt?: string;
  client?: Nullable<Client> = null;
  /* To jest jakiś absurd i to trzeba zmienić, spalić, i zrównać do Activity */
  activity?: ProspectActivity;
  userId?: Nullable<number> = null;

  constructor(dto?: Partial<ProspectDto>) {
    if (dto) {
      Utils.assign(this, dto);
      if (!this.meeting.landId) {
        this.meeting.landId = null;
      }
      if (!this.meeting.districtId) {
        this.meeting.districtId = null;
      }
    }
  }

  get isCash(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.CASH;
  }

  get isMortgage(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.MORTGAGE;
  }

  get isRealEstate(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.REAL_ESTATE;
  }

  get isSme(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.SME;
  }

  get prospectProductType(): Nullable<ProductType> {
    if (this.isCash) {
      return ProductType.CASH;
    }
    if (this.isMortgage) {
      return ProductType.MORTGAGE;
    }
    if (this.isRealEstate) {
      return ProductType.REAL_ESTATE;
    }
    if (this.isSme) {
      return ProductType.SME;
    }
    return null;
  }
}

export class ProspectMeeting extends ProspectMeetingDto implements IMeeting {
  landId: number | null = null;
  districtId: number | null = null;

  constructor(dto?: ProspectMeetingDto) {
    super();
    if (dto) {
      Object.assign(this, dto);
      this.landId = getLandId(dto.voivodship!);
      this.districtId = getDistrictId(dto.district!);
    }
  }
}

export interface ProspectAgentDto {
  name: string;
  email: string;
  crmId: number;
  groupId?: number;
}

export class ProspectAgent implements ProspectAgentDto, AvatarViewModel {
  name!: string;
  email!: string;
  crmId!: number;
  groupId?: number;
  photoUrl: Nullable<string> = null;

  get initials(): string {
    return this.name?.split(' ').map(x => x.substring(0, 1).toUpperCase()).join('') || '';
  }

  constructor(dto: ProspectAgentDto, photoUrl?: string) {
    Utils.assign(this, dto);
    if (photoUrl) {
      this.photoUrl = photoUrl;
    }
  }
}

export class Prospect {
  id!: number;
  uuid?: string;
  userId: number | null = null;
  client: Client | null = null;
  activity: Activity = new Activity();
  deal: Deal = new Deal();
  finpackDealId: Nullable<number> = null;
  usercomDealId: Nullable<number> = null;
  activityAt?: string;
  contact!: ProspectContact;
  source: Nullable<ProspectSource> = null;
  oldAgent?: ProspectAgent;
  agent?: ProspectAgent;
  agentCC: Nullable<Person> = null;
  meeting!: ProspectMeeting;
  createdAt!: string;
  updatedAt!: string;
  ekoInput!: ProspectEkoInput;
  scenarioStep!: ScenarioStep;
  simulationInput: SimulationDemand = new SimulationDemand();
  realEstateQuestionnaire: RealEstateQuestionnaireAnswers = new RealEstateQuestionnaireAnswers();
  agreement: Agreements = new Agreements();
  loan: Nullable<ProspectLoan> = null;
  universalClientModel: Nullable<Client> = null;
  universalLoanApplicationModel: Nullable<CreditDetails> = null;

  constructor(dto?: ProspectDto) {
    if (dto) {
      Object.assign(this, dto);
      this.contact = new ProspectContact(dto.contact, dto.agreement);
      if (dto.agentCC) {
        this.agentCC = new Person(dto.agentCC);
      }
      if (dto.agent) {
        this.agent = new ProspectAgent(dto.agent);
      }
    }
    if (!this.scenarioStep) {
      this.scenarioStep = ScenarioStep.SIMULATION_INPUT;
    }
    if (dto?.activity) {
      this.activity = new Activity(dto?.activity);
    }
    if (!this.meeting) {
      this.meeting = new ProspectMeeting();
    }
    if (!this.ekoInput) {
      this.ekoInput = new ProspectEkoInput();
    }
    if (dto?.agent?.crmId) {
      this.userId = dto?.agent?.crmId;
    } else if (dto?.userId) {
      this.userId = dto.userId;
    }
  }

  get isProspectFrom() {
    return {
      eko: this.source?.name === ProspectSourceName.EKO,
      realEstateService: this.source?.kind === SourceKind.OFFER_WEBSITE,
      agentCard: this.source?.kind === SourceKind.BUSINESS_CARD,
      bancovo: this.source?.name === ProspectSourceName.BANCOVO,
    };
  }

  get dealAttributesFromEkoInput(): NullableDeep<IDealAttributesViewModel> {
    return {
      plannedDateOfSubmittingApplication: this.ekoInput.plannedDateOfSubmittingApplication,
      customerCommitmentLevel: this.ekoInput.customerCommitmentLevel,
      customerTimeToBuy: this.ekoInput.customerTimeToBuy,
      interest: this.source?.interest || null,
      loanProbabilityType: this.ekoInput.loanProbabilityType,
      loanProbabilityTypeBlockers: [],
      channel: this.source?.channel || null,
      maxCreditWorthiness: null,
      note: null,
      sourceId: null,
      medium: null,
      questionnaire: null,
    };
  }

  get doesClientExist(): boolean {
    return !!this.client && !!this.client.id;
  }

  get isCash(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.CASH;
  }

  get isMortgage(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.MORTGAGE;
  }

  get isRealEstate(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.REAL_ESTATE;
  }

  get isSme(): boolean {
    return !!this.loan && this.loan?.category === ProspectLoanType.SME;
  }

  get dealType(): Nullable<ProductType> {
    return prospectLoanTypeToProductType(this.loan?.category!);
  }

  get dealNamePrefix(): string {
    return this.isRealEstate ? 'Nieruchomość dla' : 'Kredyt dla';
  }

  get isRealEstateSelling(): boolean {
    return this.realEstateQuestionnaire.supportKind.includes(RealEstateSupportKind.SELL_REAL_ESTATE);
  }

  get isRealEstateBuying(): boolean {
    return this.realEstateQuestionnaire.supportKind.includes(RealEstateSupportKind.BUY_REAL_ESTATE);
  }

  get isOpen(): boolean {
    return !this.isMeetingSet && ![
      ProspectStatus.CLOSED,
      ProspectStatus.POSITIVE_DECISION,
    ].includes(this.contact.status);
  }

  get isMeetingSet(): boolean {
    const meetingSetStatuses: ProspectStatus[] = [
      ProspectStatus.ARRANGED_MEETING,
      ProspectStatus.ARRANGED_CALL,
      ProspectStatus.STARTED,
    ];
    return meetingSetStatuses.includes(this.contact.status);
  }

  mapBancovo(bancovoJson: BancovoLoanApplication): void {
    // CreditDetails
    const application = new CreditDetails(new LoanApplication());
    application.creditedAmountSum = bancovoJson?.loanParameters.amountNet!;
    application.loanPeriod = bancovoJson?.loanParameters.periodNumber!;
    this.universalLoanApplicationModel = bancovoJson?.loanParameters ? application : null;

    // Client
    this.universalClientModel = bancovoJson?.applicants?.map((el: BancovoBorrower) => {
      const newClient: Client = new Client();
      newClient.firstName = el.firstName!;
      newClient.lastName = el.lastName!;
      newClient.pesel = el.nationalId!;
      if (newClient.clientDataJson === null) {
        newClient.clientDataJson = new PersonalDetails();
      }
      newClient.clientDataJson.origin = 'PL';
      if (el.incomes) {
        newClient.clientDataJson.incomes = el.incomes.map((elm) => {
          const income = new Income();
          income.incomeTypeId = mapIncomeTypeId(elm.source!);
          income.netIncome = elm.netIncome;
          return income;
        });
      }
      if (el.obligations) {
        const mappedObligations = mapObligations(el.obligations);
        newClient.clientDataJson.liabilities = mappedObligations.liabilities;
        newClient.clientDataJson.limits = mappedObligations.limits;
      }
      if (el.maritalStatus) {
        const status = civilStatusDictionary.find(elm => {
          return el.maritalStatus!.toLowerCase().includes(elm.type);
        });
        newClient.clientDataJson.civilStatus = status ? status.type : CivilStatus.SINGLE;
      }
      if (el.mainAddress) {
        newClient.mainAddress.zip = el.mainAddress.postalCode?.replace('-', '')!;
        newClient.mainAddress.city = el.mainAddress.city!;
        newClient.mainAddress.street = el.mainAddress.street!;
        newClient.mainAddress.streetNum = el.mainAddress.houseNumber!;
        newClient.mainAddress.streetLocal = el.mainAddress.apartmentNumber!;
      }
      newClient.personalIds =
        compact([el.primaryIdentityDocument, el.additionalIdentityDocument,]).map((id, index) => {
          const doc = new PersonalId(index);
          /* TODO: co z obcokrajowcami? */
          doc.number = id.number?.slice(3)!;
          doc.series = id.number?.slice(0, 3)!;
          doc.dateIssued = id.issueDate;
          doc.expiryDate = id.validity!;
          doc.idType = id.type === 'Id' ? PersonalIdEnum.PERSONAL_ID : id.type === 'Passport' ? PersonalIdEnum.PASSPORT : PersonalIdEnum.OTHER;
          return doc;
        });
      return newClient;
    })[0] || null;
  }

  createClientFromProspectContact() {
    if (!this.client) {
      this.client = new Client();
      if (!this.client.firstName) {
        this.client.firstName = this.contact.firstName;
      }
      if (!this.client.lastName) {
        this.client.lastName = this.contact.lastName;
      }
      if (!this.client.email) {
        this.client.email = this.contact.email;
      }
      if (!this.client.phone) {
        this.client.phone = this.contact.phone;
      }
      if (!this.client.nip && this.contact.nip) {
        this.client.isCompany = true;
        this.client.nip = this.contact.nip;
      }
      if (!this.client.name && this.contact.name) {
        let name = '';
        if (this.client.firstName && this.client.lastName) {
          name = `${this.client.firstName} ${this.client.lastName}`;
        }
        this.client.name = name || this.contact.name;
      }
      this.client!.agreements = this.agreement;
      if (!this.client.partnerId) {
        this.client.partnerId = this.source?.finpackPartnerId;
      }
      if (!this.client.partnerSourceId) {
        this.client.partnerSourceId = this.source?.finpackSourceId;
      }
    }
  }
}

export class ProspectEkoInput {
  loanProbabilityType: Nullable<LoanProbabilityType> = null;
  plannedDateOfSubmittingApplication: Nullable<AttributeTimeRange> = null;
  customerTimeToBuy: Nullable<AttributeTimeRange> = null;
  customerInterest: Nullable<CustomerInterest> = null;
  customerCommitmentLevel: Nullable<CustomerCommitmentLevelType> = null;
}

export interface ProspectNoteDto {
  text: string;
  createdAt: string;
}
