<template>
  <v-toolbar
    :color="color ? undefined: 'primaryBg'"
    dense
    min-height="56px"
    :class="color ? 'py-4' : ''"
    height="auto"
    class="elevation-0 bt bb-hard shrink">
    <v-toolbar-items class="d-flex">
      <div style="min-height: 56px" class="d-flex align-center">
        <v-icon class="mr-2" :color="color || undefined">mdi-information-outline</v-icon>
        <span
          class="subtitle-1 font-weight-medium"
          :class="[color ? `${color}--text` : 'secondary-text']">
          <slot></slot>
        </span>
      </div>
    </v-toolbar-items>
    <v-spacer />
    <slot name="info"></slot>
  </v-toolbar>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ProcessInfoToolbar extends Vue {
  @Prop({required: false,})
  readonly color?: string;
}
</script>
