import User from '@/models/user/User';
import {UserDTO} from '@/models/user/UserDTO';
import {Utils} from '@/services/utils/BasicUtils';

export enum DelegationListsFlags {
  IS_FROM_MY_DELEGATION_LIST = 'isFromMyDelegationList',
  IS_FROM_DELEGATION_LIST = 'isFromDelegationList',
  IS_FROM_BOK = 'isFromBok',
}

export class DelegationStatsDTO {
  public count!: number;
  public user!: User;
}

export class DelegationStats extends DelegationStatsDTO {
  constructor(dto?: DelegationStatsDTO) {
    super();
    if (dto) {
      dto.user = new User(dto.user);
      Utils.assign(this, dto);
    }
  }
}

export class DelegationListDto {
  public users!: User[];
  public delegationListId!: number;
  public delegationListName!: string;
}

export class DelegationList extends DelegationListDto {
  public isClosed?: boolean;
  constructor(dto?: DelegationListDto) {
    super();
    if (dto) {
      dto.users = dto.users.map((x) => new User(x));
      Utils.assign(this, dto);
    }
  }
}

export class DelegationWithStatsDTO {
  public my!: Array<User>;
  public bok!: Array<User>;
  public stats!: Array<DelegationStats>;
  public bokLists!: Array<DelegationList>;
}

export class DelegationWithStats extends DelegationWithStatsDTO {
  constructor(dto?: DelegationWithStatsDTO) {
    super();
    if (dto) {
      dto.my = dto.my.map(x => new User(x));
      dto.bok = dto.bok.map(x => new User(x));
      dto.stats = dto.stats.map(x => new DelegationStats(x));
      dto.bokLists = dto.bokLists.map(x => new DelegationList(x));
      Utils.assign(this, dto);
    }
  }
}
