import Client, {ClientDTO} from '@/models/Client';
import {ClientStoreState} from '@/modules/client/services/store/ClientStoreState';

const state: ClientStoreState = {
  Clients: [],
  selectedClientId: undefined,
};

export const ClientStoreModule = {
  namespaced: false,
  state,
  getters: {
    clients(state: ClientStoreState) {
      return state.Clients.map((c: ClientDTO) => new Client(c));
    },
    selectedClient(state: ClientStoreState) {
      return new Client(state.Clients.find(client => client.id === state.selectedClientId));
    },
  },
  mutations: {
    setClients(state: ClientStoreState, clients: Array<Client>) {
      state.Clients = clients;
    },
    setClient(state: ClientStoreState, editedClient: Client) {
      const index = state.Clients.findIndex(client => client.id === editedClient.id);
      if (index > -1) {
        state.Clients[index] = editedClient;
      } else {
        state.Clients.push(editedClient);
      }
    },
    addClient(state: ClientStoreState, client: Client) {
      state.Clients.push(client);
    },
    setClientId(state: ClientStoreState, id: number) {
      state.selectedClientId = id;
    },
  },
};
