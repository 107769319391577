<template>
  <v-row>
    <v-col class="shrink align-center d-flex" v-if="!showContent && prependIcon">
      <v-icon>{{prependIcon}}</v-icon>
    </v-col>
    <v-col class="pl-0" v-if="!showContent">
      <loading-button
        :block="block"
        :xLarge="xLarge"
        :primary="primary"
        @click="onClick()">
        {{label}}
      </loading-button>
    </v-col>
    <v-col v-if="showContent" :ref="SLOT_NAME">
      <slot/>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {wait} from '@/services/utils/BasicUtils';

@Component
export default class PlaceholderButton extends Vue {
  @Prop({required: true, type: String, }) public readonly label!: string;
  @Prop({required: false, type: String, }) public readonly prependIcon!: string;
  @Prop({default: false, }) public readonly value!: boolean;
  @Prop({default: false, }) public readonly xLarge!: boolean;
  @Prop({default: false, }) public readonly large!: boolean;
  @Prop({default: false, }) public readonly block!: boolean;
  @Prop({default: false, }) public readonly primary!: boolean;
  @Prop({default: false,}) public readonly clicked!: boolean;

  public buttonClicked: boolean = false;
  public SLOT_NAME: string = 'slot';

  @Watch('clicked')
  check() {
    if (this.clicked) {
      this.onClick();
    } else {
      this.buttonClicked = false;
    }
  }

  get showContent(): boolean {
    return this.value || this.buttonClicked;
  }

  async onClick(): Promise<void> {
    this.buttonClicked = true;
    await wait(50);
    const elements = (this.$refs[this.SLOT_NAME] as HTMLElement).getElementsByTagName('input');
    if (elements && elements[0] && elements[0].focus!) {
      elements[0].focus();
    }
  }
}
</script>

<style scoped>

</style>
