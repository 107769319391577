import ScrollTo from '@/services/utils/ScrollTo';

export function isRefValid(ref: any, key: string, scrollContainer?: HTMLElement, validateFormValue?: boolean): boolean {
  if (ref) {
    const form: any = Array.isArray(ref) ? ref[0] : ref;
    const isValid = validateFormValue ? form.validate() && Object.values(form.errorBag).every((error) => error === false) : form.validate();
    if (!isValid) {
      console.warn(`${key} form is invalid`, form);
      ScrollTo.firstError(form.$el, scrollContainer);
    }
    return isValid;
  } else {
    throw new Error(`Can't find form: ${key}`);
  }
}
