import {OrganisationId} from '@/models/enums/OrganisationId';
import Client from '@/models/Client';
import {ClientAgreements} from '@/modules/client/views/client-agreements/Agreements';
import {ClientScanKey} from '@/models/clientScans/ClientScanKey';
import {ScanType} from '@/models/clientScans/ScanType';
import {IClientScan} from '@/models/clientScans/IClientScan';

export class ClientScan implements IClientScan {
  public file: Nullable<File> = null;
  public type!: ScanType;
  public documentName!: string;
  public documentUrl!: string;
  public key!: ClientScanKey;
  public organisationIds?: OrganisationId[];
  public condition?: (c: Client) => boolean;

  public required(v: ClientAgreements): boolean {
    return false;
  }

  constructor(dto: IClientScan) {
    Object.assign(this, dto);
  }
}
