import {TourName} from '@/modules/onboarding/tour/models/TourOptions';
import UiStorage from '@/composables/useUiStorage/UiStorageService';
import AuthService from '@/modules/user/AuthService';

export class TourStorageService {
  private readonly STORAGE_KEY: string = 'onboarding';
  private readonly tourName: TourName;
  private storage = UiStorage;

  constructor(tourName: TourName) {
    this.tourName = tourName;
  }

  private async getStorageState(): Promise<Nullable<Record<TourName, boolean>>> {
    return this.storage.getItem(this.STORAGE_KEY);
  }

  public async isEnabled(): Promise<boolean> {
    const state = await this.getStorageState();
    return state?.[this.tourName] ?? true;
  }

  public async setEnabled(value: boolean) {
    const state = await this.getStorageState() ?? {};
    await this.storage.setItem(this.STORAGE_KEY, {...state, [this.tourName]: value,});
  }
}
