import Activity from '@/models/Activity';
import { HourOfWork, HoursOfWorkListDto } from '@/models/HoursOfWork';
import DateUtils from '@/services/utils/DateUtils';
import moment from 'moment';

const DATE_FORMAT = 'DD-MM-YYYY';
const TIME_FORMAT = 'HH:mm:ss';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const useActivityHoursOfWork = () => {
  const getActivityHoursOfWorkWithoutBuffer = (
    activity: Activity,
    availableHours: Nullable<HoursOfWorkListDto[]>,
    startDate: Nullable<Date>,
    endDate: Nullable<Date>
  ): Nullable<HoursOfWorkListDto[]> => {
    if (!startDate || !activity.isBusy) {
      if (!activity.isBusy) {
        return availableHours?.map(item => {
          return {
            ...item,
            hours: makeAllHoursAvasilable(item.hours),
          }
        }) || [];
      }

      return availableHours;
    }

    return availableHours?.map(item => {
      return !moment(item.day).isSame(moment(startDate!), 'day') ? item : {...item, hours: getHoursWithoutBuffer(startDate, endDate, activity, item.day, item.hours),};
    }) || [];
  }

  const getHoursWithoutBuffer = (startDate: Nullable<Date>, endDate: Nullable<Date>, activity: Activity, day: string, hours?: HourOfWork[]) => {
    if (!activity.isBusy) {
      return makeAllHoursAvasilable(hours);
    }

    const dayFormated = moment(day).format(DATE_FORMAT);
    const startTime = moment(startDate).format(TIME_FORMAT);
    const activityStartDate = moment(`${dayFormated} ${startTime}`, DATE_TIME_FORMAT);
    const activityStartBuffer = moment(`${dayFormated} ${startTime}`, DATE_TIME_FORMAT).subtract(activity.defaultDuration, 'minutes');
    const activityEndDate = moment(`${dayFormated} ${moment(endDate).format(TIME_FORMAT)}`, DATE_TIME_FORMAT);
    return hours?.map(hour => {
      const tesedHour = moment(`${dayFormated} ${hour.hour}`, DATE_TIME_FORMAT);
      return hour.isAvailable || !DateUtils.isDateInRange(tesedHour, activityStartBuffer, activityEndDate) || tesedHour.isSame(activityStartDate)
        ? hour : {...hour, isAvailable: true,} as HourOfWork;
    }) || [];
  }

  const makeAllHoursAvasilable = (hours?: HourOfWork[]) => {
    return hours?.map(hour => {
      return {...hour, isAvailable: true,} as HourOfWork
    }) || [];
  }

  return {
    getActivityHoursOfWorkWithoutBuffer,
  }
}
