import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';

const MyDivisions = () => import(/* webpackChunkName: "Discussions" */ '@/modules/division/views/MyDivisions.vue');
const DivisionDetails = () => import(/* webpackChunkName: "Discussions" */ '@/modules/accredition/views/components/division-details/DivisionDetails.vue');

const DiscussionsRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.DIVISIONS.DIVISIONS_LIST,
    component: MyDivisions,
  },
  {
    path: ':id',
    name: Routes.DIVISIONS.DIVISIONS_DETAILS,
    component: DivisionDetails,
  },
];

export default DiscussionsRoutes;
