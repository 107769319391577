<template>
  <div>
  <div v-for="(modal, index) in modals" :key="index">
    <component v-if="modal.modalData" :is="modal.formComponent" v-bind:modal-data="modal.modalData"/>
    <form v-else method="dialog" :id="`dialog-${modal.uuid}`" class="rounded-lg dialog">
      <div class="sm:max-h-[85vh]">
        <component :is="modal.formComponent" v-bind="modal.props" @closeModal="submitModal(modal, $event)"/>
      </div>
    </form>
  </div>
  </div>
</template>
<script lang="ts" setup>
import {useModalStore} from '@/components/L/Modal/useModalStore';
import {ModalInstance, ModalInstanceOld} from '@/components/L/Modal/ModalTypes';

const {modals, closeModal: closeModalInStore,} = useModalStore()
const submitModal = (modal: ModalInstance, payload?: unknown) => {
  closeDialog(modal);
  closeModalInStore(modal, payload);
}

const closeDialog = (modal: ModalInstance) => {
  const dialog = document.querySelector(`#dialog-${modal.uuid}`) as HTMLElement
  dialog.remove()
}
</script>
<style scoped lang="scss">
$default-old-modal-z-index: 202;

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $default-old-modal-z-index;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 1rem;
  box-sizing: border-box;
}
</style>
