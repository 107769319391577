import {Searchable} from '@/services/searchEngine/fuzzySearch/Searchable';
import {clone} from 'lodash-es';

const fuzzy = require('fuzzy.js');

export interface FuzzyObject<T>{
  item: T,
  score: number
}

export default class FuzzySearch {
  public static createFuzzyObject<T extends Searchable>(el: T, query: string): FuzzyObject<T> {
    return {
      item: el,
      score: fuzzy(el.textToSearch, query).score,
    };
  }

  public static sortAndFilter<T extends Searchable>(array: Array<T> | null, query: string): Array<T> {
    array = clone(array || []);
    return !query ? array : array
      .map(c => FuzzySearch.createFuzzyObject<T>(c, query))
      .sort((a, b) => b.score - a.score)
      .slice(0, 50)
      .filter(fuzzyItem => fuzzyItem.score > (query.length * 0.5))
      .map(fuzzyItem => fuzzyItem.item);
  }
}
