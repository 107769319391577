import Vue from 'vue';
import Vuex from 'vuex';
import {ClientStoreModule} from '@/modules/client/services/store/ClientStoreModule';
import {RegulationStoreModule} from '@/modules/regulation/services/store/RegulationStoreModule';
import {PartnerStoreModule} from '@/modules/partner/services/store/PartnerStoreModule';
import {DealsStoreModule} from '@/modules/deals/services/store/DealsStoreModule';
import {LoanApplicationStoreModule} from '@/modules/loanApplication/store/LoanApplicationStoreModule';
import {UserStoreModule} from '@/modules/user/services/store/UserStoreModule';
import {BikStoreModule} from '@/modules/deals/services/store/bikStore/BikStoreModule';
import {DictionaryStoreModule} from '@/commons/services/store/DictionaryStoreModule';
import { AccreditationStoreModule } from '@/modules/accredition/services/store/accreditation/AccreditationStoreModule';

Vue.use(Vuex);

export interface IStoreModule<TState> {
  namespaced: boolean,
  state: TState,
  mutations: {
    [key: string]: (state: TState, payload: any) => any
  },
  getters: {
    [key: string]: (state: TState) => any
  },
}

export interface RootState {
  DealsStoreModule: typeof DealsStoreModule.state;
  ClientStoreModule: typeof ClientStoreModule.state;
  LoanApplicationStoreModule: typeof LoanApplicationStoreModule.state;
  RegulationStoreModule: typeof RegulationStoreModule.state;
  PartnerStoreModule: typeof PartnerStoreModule.state;
  UserStoreModule: typeof UserStoreModule.state;
  BikStoreModule: typeof BikStoreModule.state;
  DictionaryStoreModule: typeof DictionaryStoreModule.state;
  AccreditationStoreModule: typeof AccreditationStoreModule.state
}

const vuexModules = {
  DealsStoreModule,
  ClientStoreModule,
  LoanApplicationStoreModule,
  RegulationStoreModule,
  PartnerStoreModule,
  UserStoreModule,
  BikStoreModule,
  DictionaryStoreModule,
  AccreditationStoreModule,
};

export default new Vuex.Store<RootState>({
  modules: vuexModules,
  mutations: {
    reset(state: RootState) {
      const modulesKeys = Object.keys(vuexModules) as (keyof typeof vuexModules)[];
      modulesKeys.forEach(moduleKey => {
        if (state[moduleKey] && vuexModules[moduleKey]) {
          const initialModuleState = vuexModules[moduleKey].state as any;
          const moduleState = state[moduleKey] as any;
          Object.keys(initialModuleState).forEach(key => {
            moduleState[key] = Array.isArray(initialModuleState[key]) ? [] : null as any;
          });
        }
      });
    },
  },
});
