<template>
  <v-layout class="sidebar-history">
    <v-flex class="text-left">
      <v-layout wrap>
        <v-flex
          v-if="showNewNoteOption"
          :class="{'note-header-is-sidebar': isSidebar}"
          :pa-6="isSidebar"
          :bb="isSidebar"
          xs12
        >
          <v-textarea
            v-model="newNote"
            :id="$cy.addNoteField"
            hide-details
            outlined
            class="fp-input-outline primaryBg"
            :placeholder="I18NGetter().useHistoryElements.TAKE_NOTE"
            :rows="1"
            @keydown.enter="addNote"
          >
            <template slot="append">
              <v-flex class="pr-0 pt-0 pb-4">
                <v-btn
                  :id="$cy.addNoteButton"
                  class="mx-0"
                  depressed
                  color="primary"
                  :disabled="!newNote"
                  @click="addNote">
                  {{ I18NGetter().useHistoryElements.SEND }}
                </v-btn>
              </v-flex>
            </template>
          </v-textarea>
        </v-flex>
        <v-layout wrap>
          <v-flex xs12 :pa-6="isSidebar" :id="$cy.historyElementsList">
            <v-layout wrap v-if="historyList && historyList.length > 0">
              <v-flex v-for="(historyElement, index) in historyList" :key="'historyElement' + index" xs12>
                <!-- TODO create config for that elements ... -->
                <template v-if="isToday(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.TODAY
                    }}</span>
                </template>
                <template v-else-if="isYesterday(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.YESTERDAY
                    }}</span>
                </template>
                <template v-else-if="isDayBeforeYesterday(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.DAY_BEFORE_YESTERDAY
                    }}</span>
                </template>
                <template v-else-if="isThisWeek(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.LAST_WEEK
                    }}</span>
                </template>
                <template v-else-if="isThisMonth(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.LAST_MONTH
                    }}</span>
                </template>
                <template v-else-if="isThisYear(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.LAST_YEAR
                    }}</span>
                </template>
                <template v-else-if="isOlderThanYear(historyElement)">
                  <span class="timeline-span py-6 pl-2 body-2 font-weight-medium">{{
                      I18NGetter().useHistoryElements.OVER_A_YEAR
                    }}</span>
                </template>
                <fp-message class="pl-1" :message="historyElement" />
              </v-flex>
              <v-flex>
                <v-pagination
                  v-if="!!filterOptions"
                  v-model="filterOptions.page"
                  :length="filterOptions.totalPagesNumber"
                  @input="$emit('fetchHistoryElements')"
                />
              </v-flex>
            </v-layout>
            <p v-else-if="notesOnly" class="subtitle-1 font-weight-bold text-center">
              {{ I18NGetter().useHistoryElements.NO_NOTES }}</p>
            <p v-else class="subtitle-1 font-weight-bold text-center">{{
                I18NGetter().useHistoryElements.NO_HISTORY
              }}</p>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import {HistoryElement} from '@/models/HistoryElement';
import {FpMessageType} from '@/modules/deals/enums/FpMessageType';
import {isEqual, chain} from 'lodash-es';
import FpMessage from '@/components/messages/FpMessage.vue';
import DateUtils from '@/services/utils/DateUtils';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {BaseFilter, ProspectHistoryFilter} from '@/models/Filter';
import { useInstance } from '@/composables/useInstance';
const {$snackbarService,} = useInstance();

interface Props {
  showNewNoteOption?: boolean;
  chat?: boolean;
  notesOnly?: boolean;
  last?: number;
  historyElements: HistoryElement<any>[];
  dealId?: number;
  loanApplicationId?: number;
  isSidebar?: boolean;
  filterOptions?: BaseFilter;
}

const props = withDefaults(defineProps<Props>(), {
  showNewNoteOption: true,
  chat: false,
  notesOnly: false,
  last: 0,
  historyElements: () => [],
  isSidebar: false,
});

const emit = defineEmits<{
  (e: 'click:new-note', newNote: string, dealId: number | undefined): void,
  (e: 'fetchHistoryElements'): void,
}>();

const newNote = ref<string>('');

function findFirst(elements: HistoryElement<any>[], f: (n: string) => boolean): HistoryElement<any> | undefined {
  return elements.find(date => f(date.createdDate));
}

function isToday(historyElement: HistoryElement<any>): boolean {
  return isEqual(historyElement, findFirst(props.historyElements, DateUtils.isToday));
}

function isYesterday(historyElement: HistoryElement<any>): boolean {
  return isEqual(historyElement, findFirst(props.historyElements, DateUtils.isYesterday));
}

function isDayBeforeYesterday(historyElement: HistoryElement<any>): boolean {
  return isEqual(historyElement, findFirst(props.historyElements, DateUtils.isDayBeforeYesterday));
}

function isThisWeek(historyElement: HistoryElement<any>): boolean {
  const elements = props.historyElements
    .filter(el => !DateUtils.isToday(el.createdDate))
    .filter(el => !DateUtils.isYesterday(el.createdDate))
    .filter(el => !DateUtils.isDayBeforeYesterday(el.createdDate));
  return isEqual(historyElement, findFirst(elements, DateUtils.isThisWeek));
}

function isThisMonth(historyElement: HistoryElement<any>): boolean {
  const elements = props.historyElements
    .filter(el => !DateUtils.isThisWeek(el.createdDate));
  return isEqual(historyElement, findFirst(elements, DateUtils.isThisMonth));
}

function isThisYear(historyElement: HistoryElement<any>): boolean {
  const elements = props.historyElements
    .filter(el => !DateUtils.isThisMonth(el.createdDate));
  return isEqual(historyElement, findFirst(elements, DateUtils.isThisYear));
}

function isOlderThanYear(historyElement: HistoryElement<any>): boolean {
  const elements = props.historyElements
    .filter(el => !DateUtils.isThisYear(el.createdDate));
  return isEqual(historyElement, findFirst(elements, DateUtils.isOlderThanThisYear));
}

const historyList = computed<HistoryElement<any>[]>(() => {
  let list: HistoryElement<any>[] = props.historyElements;
  if (props.last) {
    list = list.slice(0, props.last);
  }

  if (!props.chat) {
    return list;
  }

  return chain<HistoryElement<any>>(list).filter(historyElement => historyElement.type === FpMessageType.NOTE_ADDED)
    .uniq()
    .value();
});

function addNote(): void {
  if (newNote.value.length) {
    emit('click:new-note', newNote.value, props.dealId ? props.dealId : props.loanApplicationId);
    newNote.value = '';
  } else {
    $snackbarService.openWarningSnackbar(I18NGetter().useHistoryElements.WARNING_SNACKBAR_ADD_NOTE);
  }
}
</script>

<style scoped lang="scss">

.note-header-is-sidebar {
  position: relative;
  z-index: 2;
  width: 100%;
  top: 0;
  background: var(--v-primaryBg-base);
}

p {
  line-height: 24px;
  margin-bottom: 8px;
  font-size: 16px;
  margin-top: 8px;
}

.timeline-span {
  line-height: 24px;
  color: var(--v-fpShadow-base);
}
</style>
