<template>
  <client-badge
    :readonly="true"
    :hideActions="hideActions"
    :client="borrower">
    <template v-slot:second-line v-if="canCreateClient">
      <a @click="createClientFromAnonymous()" class="primary--text">Zweryfikuj tożsamość</a>
    </template>
  </client-badge>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Client from '@/models/Client';
import ClientApi from '@/modules/client/services/ClientApi';
import GoToService from '@/router/GoToService';
import BaseBadge from '@/components/badges/base/BaseBadge.vue';
import PersonAvatar from '@/components/commons/PersonAvatar.vue';
import {IClientBadgeViewModel} from '@/modules/client/models/ClientBadgeViewModel';
import ClientBadge from '@/components/badges/ClientBadge.vue';
import {AnonymousBorrower} from '@/modules/client/models/AnonymousBorrower';
import CreateClientFromAnonymousBorrower
  from '@/modules/client/views/create-client/CreateClientFromAnonymousBorrower.vue';

@Component({
  components: {ClientBadge, },
})
export default class AnonymousBorrowerBadge extends BaseBadge {
  @Prop({required: true, })
  public readonly borrower!: AnonymousBorrower | null;

  get canCreateClient(): boolean {
    return !this.readonly && !!this.$listeners.clientCreated;
  }

  public async createClientFromAnonymous() {
    const response = await this.$modalService.open<Client>(CreateClientFromAnonymousBorrower, {
      borrower: this.borrower,
    });
    if (response) {
      this.$emit('clientCreated', response);
    }
  }
}

</script>

<style scoped lang="scss">
  .client-name {
    min-width: fit-content;
  }
</style>
