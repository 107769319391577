import { render, staticRenderFns } from "./ProspectPickLossReason.vue?vue&type=template&id=82894ed2&scoped=true"
import script from "./ProspectPickLossReason.vue?vue&type=script&lang=ts"
export * from "./ProspectPickLossReason.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_underscore@1.13.6_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82894ed2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.7.1_webpack@5.89.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VDivider,VForm,VIcon,VRow,VSpacer})
