<template>
  <v-btn-toggle
    :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.VIEW_SIZE"
    v-model="zoomLevel"
    mandatory
    dense>
    <v-btn
      height="40px"
      v-for="item in AVAILABLE_ZOOM_LEVELS"
      :value="item"
      :key="ZoomLevel[item]">
      <v-icon>mdi-size-{{ ZoomLevel[item].toLowerCase() }}</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>
<script lang="ts">
import {useZoom, ZoomLevel} from '@/components/calculator/results/useZoom';
import {defineComponent, getCurrentInstance} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';

export default defineComponent({
  setup(props, context) {
    const instance = getCurrentInstance();
    const store = useSimulationStore();
    const {zoomLevel, AVAILABLE_ZOOM_LEVELS,} = useZoom(
      store.productType!, instance?.proxy.$vuetify.breakpoint.mdAndDown
    );

    return {
      zoomLevel,
      AVAILABLE_ZOOM_LEVELS,
      ZoomLevel,
    };
  },
});
</script>
