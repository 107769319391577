import {Tour} from '@/modules/onboarding/tour/Tour';
import {TourModalType, TourName, TourOptions} from '@/modules/onboarding/tour/models/TourOptions';
import {TourStep} from '@/modules/onboarding/tour/models/TourStep';
import {DashboardKey} from '@/modules/onboarding/tours/enums/DashboardKey';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

const STEPS: readonly TourStep[] = [
  {
    key: DashboardKey.PLATFORM_LOGO,
    content: I18NGetter().useDashboardTour.PLATFORM_LOGO,
    params: {
      enableScrolling: false,
    },
    isPreviousButtonHidden: true,
  },
  {
    key: DashboardKey.DASHBOARD,
    content: I18NGetter().useDashboardTour.DASHBOARD,
    params: {
    },
  },
  {
    key: DashboardKey.THEME_COLOR,
    content: I18NGetter().useDashboardTour.THEME_COLOR,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.MENU_SIZE,
    content: I18NGetter().useDashboardTour.MENU_SIZE,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.USERNAME,
    content: I18NGetter().useDashboardTour.USERNAME,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.OMNISEARCH,
    content: I18NGetter().useDashboardTour.OMNISEARCH,
    params: {
    },
  },
  {
    key: DashboardKey.CALENDAR,
    content: I18NGetter().useDashboardTour.CALENDAR,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.MAIL,
    content: I18NGetter().useDashboardTour.MAIL,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.NOTIFICATIONS,
    content: I18NGetter().useDashboardTour.NOTIFICATIONS,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.DEALS_CLIENTS_SETTLEMENTS,
    content: I18NGetter().useDashboardTour.DEALS_CLIENTS_SETTLEMENTS,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.CALENDAR_PREVIEW,
    content: I18NGetter().useDashboardTour.CALENDAR_PREVIEW,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.NEWS,
    content: I18NGetter().useDashboardTour.NEWS,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: DashboardKey.FORUM_PREVIEW,
    content: I18NGetter().useDashboardTour.FORUM_PREVIEW,
    params: {
    },
    offset: -300,
  },
  {
    key: DashboardKey.DEALS_IN_PROGRESS,
    content: I18NGetter().useDashboardTour.DEALS_IN_PROGRESS,
    params: {
    },
    offset: 100,
  },
  {
    key: DashboardKey.COMPARISON_TOOL,
    content: I18NGetter().useDashboardTour.COMPARISON_TOOL,
    params: {
    },
  },
  {
    key: DashboardKey.KNOWLEDGE_BASE,
    content: I18NGetter().useDashboardTour.KNOWLEDGE_BASE,
    params: {
    },
  },
  {
    key: DashboardKey.BIK,
    content: I18NGetter().useDashboardTour.BIK,
    params: {
    },
  },
  {
    key: DashboardKey.DISCOUNTS,
    content: I18NGetter().useDashboardTour.DISCOUNTS,
    params: {
    },
  },
  {
    key: DashboardKey.CLIENTS,
    content: I18NGetter().useDashboardTour.CLIENTS,
    params: {
    },
  },
  {
    key: DashboardKey.DEALS,
    content: I18NGetter().useDashboardTour.DEALS,
    params: {
    },
  },
  {
    key: DashboardKey.LOAN_APPLICATIONS,
    content: I18NGetter().useDashboardTour.LOAN_APPLICATIONS,
    params: {
    },
  },
  {
    key: DashboardKey.SETTLEMENTS,
    content: I18NGetter().useDashboardTour.SETTLEMENTS,
    params: {
    },
  },
  {
    key: DashboardKey.PARTNERS,
    content: I18NGetter().useDashboardTour.PARTNERS,
    params: {
    },
  },
  {
    key: DashboardKey.LENDI_TEAM,
    content: I18NGetter().useDashboardTour.LENDI_TEAM,
    params: {
    },
  },
  {
    key: DashboardKey.ACCREDITATION_PANEL,
    content: I18NGetter().useDashboardTour.ACCREDITATION_PANEL,
    params: {
    },
  },
  {
    key: DashboardKey.FORUM,
    content: I18NGetter().useDashboardTour.FORUM,
    params: {
    },
    isNextButtonHidden: true,
  },
] as const;

const OPTIONS: TourOptions = new TourOptions({
  modals: {
    [TourModalType.START]: {
      description: I18NGetter().useDashboardTour.START,

    },
    [TourModalType.FINISH]: {
      description: I18NGetter().useDashboardTour.FINISH,
    },
  },
});

const DashboardTour = new Tour(TourName.DASHBOARD, STEPS, OPTIONS,);

export default DashboardTour;
