import {Currency} from '@/modules/multiForm/shared/MultiformDictionaries';
import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {CarrierCode, CountryCode} from 'libphonenumber-js';
import {ValidatorObject} from '@/components/fpComponents/services/countrySpecific/appDomainSpecificValidator';
import {isValidNip, isValidTin} from '@/components/fpComponents/services/countrySpecific/taxIdNumber';
import {ProductType} from '@/commons/enums/ProductType';
import {
  isPersonalIdNumberValid,
  personalIdNumberUtils
} from '@/components/fpComponents/services/countrySpecific/personalIdNumber';
import {PersonalIdEnum} from '@/modules/client/enums/PersonalIdEnum';
import {CrmModules} from '@/types/CrmModules';
import {AppDomain} from './enums/Domains';
import {Gender} from '../models/enums/Gender';
import {agreementsLendi} from '@/env/Agreements/LENDI_PL';
import {agreementsNesto} from '@/env/Agreements/NESTO_RO';
import {DomainAgreements} from '@/env/Agreements/types';
import {
  DomainAgreementsWithUrl
} from '@/env/Agreements/GPDR/types';
import {
  CompanyType,
  FFIncomeType,
  HomeStatus,
  IncomeType,
  LiabilityType,
  RealEstateRights,
  RealEstateType
} from '@/modules/multiForm/shared/Types';
import {UserPanelModule} from '@/models/enums/UserPanelModule';
import {DealStatus} from '@/modules/deals/enums/DealStatus';
import {ClientScansDtoLendi} from '@/env/ClientScans/LENDI_PL';
import {IClientScan} from '@/models/clientScans/IClientScan';
import {ClientScansDtoNesto} from '@/env/ClientScans/NESTO_RO';
import {calculatorConfig, CalculatorConfig} from '@/env/AppDomainConfigs/Calculator';
import {LoanPurposeWhat} from '@/models/simulation/LoanPurposeWhat';
import {DivisionDetailsTabs} from '@/modules/accredition/models/DivisionDetails';
import {OrganisationId} from '@/models/enums/OrganisationId';
import {PartnerFilter} from '@/models/Filter';
import {GPDR_AGREEMENTS_LENDI} from '@/env/Agreements/GPDR/LENDI_PL';
import {GPDR_AGREEMENTS_NESTO} from '@/env/Agreements/GPDR/NESTO_RO';
import { PartnerType } from '@/modules/partner/enums/PartnerType';
import { FilterKey } from '@/components/calculator/results/OfferSorting'
import { SimulationFiltersConfig, ISimulationFiltersConfig } from './AppDomainConfigs/simulationFilters';
import { QuizCategory } from '@/models/Quiz';

export type AppDomainKey = keyof typeof AppDomain;
export type taxIdentificationNumberLength = {
  min: number;
  max: number
}

export type ModuleSettings = {
  [CrmModules.PARTNERS]: {
    hasExpertFit: boolean;
    hasStatus: boolean;
    availableFilters: Array<keyof PartnerFilter>
    hasOwnPages: boolean;
    hasNotes: boolean;
  },
  [CrmModules.USER_MANAGEMENT]: {
    hasCvrSorting: boolean,
  },
  [CrmModules.DEAL_DETAILS]: {
    hasEKOTab: boolean
  }
  [CrmModules.MY_DIVISIONS]: {
    excludedTabs: DivisionDetailsTabs[],
  },
  [CrmModules.DELEGATION_LIST]: {
    organisationIds: OrganisationId[],
    hasProspectDelegationList: boolean,
  },
  [CrmModules.REPORTS]: {
    hasObligatoryBankApplicationNumber: boolean,
    hasAlwaysVisibleBankApplicationNumber: boolean,
  }
}
interface excludedMultiformItems {
  businessIncomeTypes?: FFIncomeType[]
  loanPurposeWhatTypes?: RealEstateType[]
  homeStatusDictionary?: HomeStatus[]
  incomeTypesDictionary?: IncomeType[]
  companyTypesDictionary?: CompanyType[]
  liabilityTypesDictionary?: LiabilityType[]
  loanPurposeRightsDictionary?: RealEstateRights[]
  otherIncomeTypes?: FFIncomeType[]
  militaryStatusHidden?: boolean
  isEKWHidden?: boolean
  incomeBankAccountNumberHidden?: boolean
  tranchesAndLoanPurposeHidden?: boolean
  fFCollaterals?: LoanPurposeWhat[]
}

type PersonalIdNumberType = 'PESEL' | 'CNP';
type Quizzes = {
  [K in QuizCategory as `has${Capitalize<K>}`]?: boolean;
}

export type AppDomainConfig = {
  clientScans: IClientScan[];
  excludedModules: CrmModules[];
  moduleSettings: ModuleSettings;
  quizzes: Quizzes
  organisations: {
    hasAccess: boolean
  }
  excludedMultiformItems: excludedMultiformItems;
  excludedFilterItems?: Array<string>;
  agreements: DomainAgreements;
  gpdrAgreementWithUrl: DomainAgreementsWithUrl<AppDomain.NESTO_RO | AppDomain.LENDI_PL>;
  availableProducts: ProductType[];
  name: string;
  companyFullName: string,
  currency: Currency;
  region: CountryCode;
  countryName: string;
  inCountryName: string;
  zipCodeMask: string;
  phonePrefix: CarrierCode;
  availablePhonePrefixesCountryCodes: CountryCode[];
  simpleDelegation: {
    canDelegate: PartialRecord<ProductType, boolean>;
  }
  defaultSystemLanguage: SystemLanguage;
  availableLanguages: SystemLanguage[]
  administrativeLevels: number;
  supportedCurrencies: Currency[];
  accreditation: {
    availablePersonalIds: PersonalIdEnum[]
  }
  calculator: CalculatorConfig;
  hasHrLock: boolean
  mailDomainUrl: string
  jitsiToken?: string
  taxIdentificationNumber: {
    label: string,
    regonLabel: string | false,
    validator?: ValidatorObject
    hasCompanyDataApiIntegration: boolean,
    hasNationalCourtRegister: boolean,
  },
  personalIdNumber: {
    type: PersonalIdNumberType,
    utils: {
      gender: (personalId: string) => Gender,
      birthDay: (personalId: string) => Date
    },
    validators: ValidatorObject
  },
  retirementAge: {
    [Gender.MALE]: number,
    [Gender.FEMALE]: number,
  },
  userPanelModules: Array<UserPanelModule>,
  logotypes: {
    default: string,
    contrast: string,
    minimized?: string,
  },
  nationalMinimumGrossMonthlySalary?: string,
  maximumLTVPercentage: number;
  excludedPartners: PartnerType[],
  cashComparisonHiddenFilters: FilterKey[],
  simulationFilters: ISimulationFiltersConfig,
}

export const appDomainConfig: Record<keyof typeof AppDomain, AppDomainConfig> = {
  LENDI_PL: {
    clientScans: ClientScansDtoLendi,
    organisations: {
      hasAccess: true,
    },
    quizzes: {
      hasStmt: true,
    },
    excludedModules: [],
    moduleSettings: {
      [CrmModules.PARTNERS]: {
        hasExpertFit: true,
        hasStatus: false,
        hasOwnPages: true,
        availableFilters: [],
        hasNotes: false,
      },
      [CrmModules.USER_MANAGEMENT]: {
        hasCvrSorting: true,
      },
      [CrmModules.DEAL_DETAILS]: {
        hasEKOTab: true,
      },
      [CrmModules.MY_DIVISIONS]: {
        excludedTabs: [],
      },
      [CrmModules.DELEGATION_LIST]: {
        organisationIds: [OrganisationId.LENDI,
          OrganisationId.HOME_ESTATE,
          OrganisationId.FINAMAX,
          OrganisationId.BANCOVO,
          OrganisationId.MILLENNIUM,
        ],
        hasProspectDelegationList: true,
      },
      [CrmModules.REPORTS]: {
        hasObligatoryBankApplicationNumber: true,
        hasAlwaysVisibleBankApplicationNumber: false,
      },
    },
    excludedMultiformItems: {
    },
    agreements: agreementsLendi,
    gpdrAgreementWithUrl: GPDR_AGREEMENTS_LENDI,
    name: 'Lendi.pl',
    companyFullName: 'Lendi sp. z o.o.',
    currency: 'PLN',
    region: 'PL',
    countryName: 'Polska',
    inCountryName: 'Polsce',
    phonePrefix: '48',
    zipCodeMask: '##-###',
    availablePhonePrefixesCountryCodes: [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'GR',
      'ES',
      'IE',
      'LT',
      'LU',
      'LV',
      'MT',
      'NL',
      'DE',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'SE',
      'HU',
      'IT',
      'UA',
      'FI',
      'NO',
      'GB',
      'US',
      'CA',
      'AU',
      'MD',
      'BY',
      'CH',
    ],
    availableProducts: [
      ProductType.MORTGAGE,
      ProductType.CASH,
      ProductType.SME,
      ProductType.NONBANK,
      ProductType.REAL_ESTATE,
      ProductType.INSURANCE,
      ProductType.FACTORING,
      ProductType.LEASING,
      ProductType.INVESTMENT,
    ],
    administrativeLevels: 2,
    defaultSystemLanguage: SystemLanguage.PL,
    availableLanguages: [
      SystemLanguage.PL,
      SystemLanguage.EN,
      SystemLanguage.DE,
      SystemLanguage.RO,
      SystemLanguage.UK,
    ],
    supportedCurrencies: ['PLN', 'EUR', 'CHF', 'GBP', 'NOK', 'SEK', 'USD',],
    accreditation: {
      availablePersonalIds: [PersonalIdEnum.PERSONAL_ID, PersonalIdEnum.PASSPORT,],
    },
    calculator: calculatorConfig.LENDI_PL,
    hasHrLock: true,
    mailDomainUrl: 'https://mail.lendi.pl/',
    jitsiToken: 'vpaas-magic-cookie-e463ffda06614a71b5bde01d69af7dae',
    taxIdentificationNumber: {
      label: 'NIP',
      regonLabel: 'REGON',
      validator: {
        length: 10,
        validationFunc: isValidNip,
      },
      hasNationalCourtRegister: true,
      hasCompanyDataApiIntegration: true,
    },
    simpleDelegation: {
      canDelegate: {
        [ProductType.INSURANCE]: true,
      },
    },
    personalIdNumber: {
      type: 'PESEL',
      utils: {
        gender: personalIdNumberUtils[AppDomain.LENDI_PL].gender,
        birthDay: personalIdNumberUtils[AppDomain.LENDI_PL].birthDay,
      },
      validators: {
        length: isPersonalIdNumberValid[AppDomain.LENDI_PL].length,
        validationFunc: isPersonalIdNumberValid[AppDomain.LENDI_PL].validationFunc,
      },
    },
    retirementAge: {
      [Gender.MALE]: 65,
      [Gender.FEMALE]: 60,
    },
    userPanelModules: [UserPanelModule.SETTINGS, UserPanelModule.OPINIONS, UserPanelModule.CUSTOM_LOGO,],
    logotypes: {
      default: require('@/assets/platformLogotypes/lendi.png'),
      contrast: require('@/assets/platformLogotypes/lendi-contrast.svg'),
      minimized: require('@/assets/platformLogotypes/logoLendi.png'),
    },
    nationalMinimumGrossMonthlySalary: '3600,00',
    maximumLTVPercentage: 90,
    excludedPartners: [PartnerType.DEVELOPER_ORGANISATION, PartnerType.OWN_PARTNER_CONTRACT, PartnerType.REAL_ESTATE_OFFICE_ORGANISATION,],
    cashComparisonHiddenFilters: [],
    simulationFilters: SimulationFiltersConfig.LENDI_PL,
  },
  NESTO_RO: {
    clientScans: ClientScansDtoNesto,
    organisations: {
      hasAccess: false,
    },
    quizzes: {
      hasStmt: false,
    },
    agreements: agreementsNesto,
    gpdrAgreementWithUrl: GPDR_AGREEMENTS_NESTO,
    excludedMultiformItems: {
      fFCollaterals: [LoanPurposeWhat.RECREATION_PLOT, LoanPurposeWhat.AGRICULTURAL_PLOT,],
      militaryStatusHidden: true,
      isEKWHidden: true,
      incomeBankAccountNumberHidden: true,
      tranchesAndLoanPurposeHidden: true,
      loanPurposeRightsDictionary: [
        RealEstateRights.CONDOMINIUM_RIGHT,
        RealEstateRights.TENEMENT_RIGHT,
        RealEstateRights.ASSET,
        RealEstateRights.PERPETUAL_USUFRUCT,
      ],
      homeStatusDictionary: [
        HomeStatus.CONDOMINIUM_WITHOUT_MORTGAGE,
        HomeStatus.TBS,
        HomeStatus.CONDOMINIUM_WITH_MORTGAGE,
        HomeStatus.ACCOMODATION,
        HomeStatus.TENEMENT,
      ],
      loanPurposeWhatTypes: [
        RealEstateType.AGRICULTURAL_PLOT,
        RealEstateType.CAR_PARKING,
        RealEstateType.GARAGE,
        RealEstateType.HABITATION,
        RealEstateType.MULTI_HOUSE,
        RealEstateType.RECREATION_PLOT,
        RealEstateType.RECREATION_PLOT_WITH_BUILDINGS,
        RealEstateType.RECREATION_PLOT_WITHOUT_BUILDINGS,
        RealEstateType.SEGMENT,
        RealEstateType.STORAGE_ROOM,
        RealEstateType.TENEMENT_HOUSE,
      ],
      businessIncomeTypes:
        [
          FFIncomeType.ACTIVITIES_FARMER,
          FFIncomeType.ACTIVITIES_3,
          FFIncomeType.ACTIVITIES_5,
          FFIncomeType.ACTIVITIES_8,
          FFIncomeType.ACTIVITIES_17_20,
          FFIncomeType.ACTIVITIES_BON_CARD,
        ],
      incomeTypesDictionary: [
        IncomeType.ACTIVITIES_FARMER,
        IncomeType.PRIEST,
        IncomeType.ACTIVITIES_3,
        IncomeType.ACTIVITIES_5,
        IncomeType.ACTIVITIES_8,
        IncomeType.ACTIVITIES_17_20,
        IncomeType.ACTIVITIES_BON_CARD,
        IncomeType.STRUCTURAL_PENSION,
        IncomeType.SOCIAL_500,
        IncomeType.PRE_PENSION,
        IncomeType.INCOME_HOUSE_SUPLEMENT,
        IncomeType.APPOINTMENT,

      ],
      otherIncomeTypes:
        [
          FFIncomeType.PRIEST,
          FFIncomeType.INCOME_HOUSE_SUPLEMENT,
          FFIncomeType.APPOINTMENT,

        ],
      companyTypesDictionary: [
        CompanyType.WORK_IN_CIVIL_PARTNERSHIP,
        CompanyType.WORK_IN_GENERAL_PARTNERSHIP,
        CompanyType.WORK_IN_BUDGET_ZONE,
        CompanyType.WORK_IN_COOPERATIVE,
        CompanyType.WORK_IN_PUBLIC_COMPANY,
        CompanyType.WORK_IN_PERSONAL_COMPANY,
      ],
      liabilityTypesDictionary: [
        LiabilityType.alimony,
        LiabilityType.billOfExchange,
        LiabilityType.businessGuarantee,
        LiabilityType.guarantee,
        LiabilityType.financiaLeasing,
        LiabilityType.insurance,
        LiabilityType.investLoan,
        LiabilityType.rent,
        LiabilityType.shopLoan,
        LiabilityType.carLoan,
        LiabilityType.businessLoan,
      ],
    },
    moduleSettings: {
      [CrmModules.PARTNERS]: {
        hasExpertFit: false,
        hasStatus: true,
        hasOwnPages: false,
        availableFilters: ['status', 'primaryAddress', 'ownerId',],
        hasNotes: true,
      },
      [CrmModules.USER_MANAGEMENT]: {
        hasCvrSorting: false,
      },
      [CrmModules.DEAL_DETAILS]: {
        hasEKOTab: false,
      },
      [CrmModules.MY_DIVISIONS]: {
        excludedTabs: [DivisionDetailsTabs.INVITATIONS, DivisionDetailsTabs.COMPANY_DATA,],
      },
      [CrmModules.DELEGATION_LIST]: {
        organisationIds: [OrganisationId.DSA,],
        hasProspectDelegationList: false,
      },
      [CrmModules.REPORTS]: {
        hasObligatoryBankApplicationNumber: false,
        hasAlwaysVisibleBankApplicationNumber: true,
      },
    },
    excludedModules: [
      CrmModules.PROSPECTS,
      CrmModules.LIVESPACE,
      CrmModules.NPS,
      CrmModules.BIG_AGREEMENTS,
      CrmModules.KNOWLEDGE,
      CrmModules.PROSPECTS_SETTINGS,
      CrmModules.HUMAN_RESOURCES,
      CrmModules.MULTIFORM,
      CrmModules.OLD_COMMISSIONS,
      CrmModules.PRODUCTS,
      CrmModules.WIDGETS,
    ],
    name: 'Nesto.ro',
    companyFullName: 'Nesto',
    currency: 'RON',
    region: 'RO',
    countryName: 'România',
    inCountryName: 'România',
    phonePrefix: '40',
    zipCodeMask: '######',
    availablePhonePrefixesCountryCodes: [
      // wazne biznesowo dla PL, Rumunia jeszcze nie potwierdziła
      // 'US',
      // 'GR',
      // 'NL',
      // 'BE',
      // 'FR',
      // 'ES',
      // 'IT',
      // 'AT',
      // 'GB',
      // 'DE',
      // 'SE',
      // 'NO',
      // 'DE',
      // 'PT',
      // 'LU',
      // 'IE',
      // 'CY',
      // 'CZ',
      // 'RO',
      // 'DK',
      // 'PL'
    ],
    availableProducts: [
      ProductType.MORTGAGE,
      ProductType.CASH,
    ],
    administrativeLevels: 1,
    accreditation: {
      availablePersonalIds: [],
    },
    calculator: calculatorConfig.NESTO_RO,
    defaultSystemLanguage: SystemLanguage.RO,
    availableLanguages: [SystemLanguage.RO, SystemLanguage.EN, SystemLanguage.PL,],
    supportedCurrencies: ['RON', 'EUR',],
    hasHrLock: false,
    mailDomainUrl: 'https://mail.google.com/mail/u/0/#inbox',
    taxIdentificationNumber: {
      label: 'CUI',
      regonLabel: false,
      hasCompanyDataApiIntegration: false,
      hasNationalCourtRegister: false,
      validator: {
        validationFunc: isValidTin,
      },
    },
    simpleDelegation: {
      canDelegate: {},
    },
    personalIdNumber: {
      type: 'CNP',
      utils: {
        gender: personalIdNumberUtils[AppDomain.NESTO_RO].gender,
        birthDay: personalIdNumberUtils[AppDomain.NESTO_RO].birthDay,
      },
      validators: {
        length: isPersonalIdNumberValid[AppDomain.NESTO_RO].length,
        validationFunc: isPersonalIdNumberValid[AppDomain.NESTO_RO].validationFunc,
      },
    },
    retirementAge: {
      [Gender.MALE]: 65,
      [Gender.FEMALE]: 63,
    },
    userPanelModules: [UserPanelModule.SETTINGS, UserPanelModule.CUSTOM_LOGO,],
    logotypes: {
      default: require('@/assets/platformLogotypes/imobiliare.webp'),
      contrast: require('@/assets/platformLogotypes/imobiliare-contrast.webp'),
    },
    excludedFilterItems: [
      DealStatus.RETURNED_TO_AGENT_CC,
    ],
    maximumLTVPercentage: 85,
    excludedPartners: [],
    cashComparisonHiddenFilters: ['forForeignersTrue', 'creditHolidaysTrue', 'overnightSeparationTrue', 'negotiableTrue', 'jobBreakAcceptedTrue', 'simplifiedProcedureTrue', 'negotiableTrue',],
    simulationFilters: SimulationFiltersConfig.NESTO_RO,
  },
};
