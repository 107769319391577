<template>
  <fragment>
    <v-row class="no-gutters main-badge-header-wrapper bb">
      <v-col
        :class="[{
          'short-name': store.userInput.hideOfferName,
          'short-name-extended': store.isShownAvailableProducts
          }, $onboarding.tours.MORTGAGE_CALCULATOR.step.OFFERS_COUNTER]"
        class="pl-2 pt-2 main-badge-header">
        <div class="align-start d-flex body-1 justify-space-between">
          <span class="font-weight-medium">{{ I18NGetter().useSimulationMainBadgeHeader.OFFERS }}</span>
          <span class="mr-2 text--secondary" v-if="store.simulation">{{ store.visibleOffersCountLabel }}</span>
        </div>
        <div v-if="store.isShownAvailableProducts" class="mt-4 text--secondary d-flex align-center justify-space-between">
          <div>
            <v-icon>mdi-checkbox-multiple-outline</v-icon>
            <span class="ml-1">{{ I18NGetter().useSimulationMainBadgeHeader.CHECKED }}</span>
          </div>
          <span class="mr-2">{{ store.userInput.selectedOffers.length }}</span>
        </div>
      </v-col>
    </v-row>
  </fragment>
</template>
<script lang="ts">
// @ts-ignore
import { Fragment } from 'vue-fragment';
import {computed, defineComponent} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export default defineComponent({
  components: {Fragment,},
  setup() {
    const store = useSimulationStore();

    return {
      store,
      I18NGetter,
    };
  },
});
</script>
<style lang="scss">
$main-badge-header-height: 138px;
$main-badge-header-content-height: 60px;
$main-badge-header-content-short-name-height: 42px;
$main-badge-header-content-short-name-extended-height: 80px;
$main-badge-header-border-width: 1px;

.main-badge-header-wrapper {
  position: sticky;
  z-index: 10;
  top: 0;
  margin: 0;
  background: var(--v-secondaryBg-base);
}
.main-badge-header-border {
  position: sticky;
  top: $main-badge-header-content-height;

  &.short-name {
    top: $main-badge-header-content-short-name-height;

    &.short-name-extended {
      top: $main-badge-header-content-short-name-extended-height;
    }
  }
}
.main-badge-header {
  height: $main-badge-header-height;

  &.short-name {
    height: $main-badge-header-content-short-name-height;

    &.short-name-extended {
      height: $main-badge-header-content-short-name-extended-height;
    }
  }
}

</style>
