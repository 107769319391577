import {RouteConfig} from 'vue-router';
import { Routes } from '@/router/Routes';
const DiscussionsList = () => import(/* webpackChunkName: "Discussions" */ '@/modules/discussions/views/discussions-list/DiscussionsList.vue');
const DiscussionDetails = () => import(/* webpackChunkName: "Discussions" */ '@/modules/discussions/views/discussions-details/DiscussionDetails.vue');

const DiscussionsRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.DISCUSSIONS.DISCUSSIONS_LIST,
    component: DiscussionsList,
  },
  {
    path: ':id',
    name: Routes.DISCUSSIONS.DISCUSSIONS_DETAILS,
    component: DiscussionDetails,
  },
];

export default DiscussionsRoutes;
