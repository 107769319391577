import * as Autocompletes from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Autocompletes.json';
import * as BankBranches from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Bank_Branches.json';
import * as Branches from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Branches.json';
import * as CalculatorCashResults from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Calculator_Cash_Results.json';
import * as CalculatorForm from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Calculator_Form.json';
import * as CalculatorResults from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Calculator_Results.json';
import * as CalculatorSchedule from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Calculator_Schedule.json';
import * as CalculatorSchedulePrintout from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Calculator_Schedule_Printout.json';
import * as CalculatorScheduleTable from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Calculator_Schedule_Table.json';
import * as ClientDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Client_Details.json';
import * as ClientDetailsBik from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Client_Details_Bik.json';
import * as ClientDetailsDocuments from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Client_Details_Documents.json';
import * as ClientList from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Client_List.json';
import * as Commons from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Commons.json';
import * as CreateDealModal from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Create_Deal_Modal.json';
import * as Dashboard from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Dashboard.json';
import * as DateFilters from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Date_Filters.json';
import * as DealAttributesDictionary from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Deal_Attributes_Dictionary.json';
import * as DealDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Deal_Details.json';
import * as Deals from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Deals.json';
import * as DealsList from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Deals_List.json';
import * as DelegationLists from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Delegation_Lists.json';
import * as DivisionDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Division_Details.json';
import * as Divisions from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Divisions.json';
import * as Enums from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Enums.json';
import * as ErrorPages from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Error_Pages.json';
import * as FinancialInstitutions from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Financial_Institutions.json';
import * as Forum from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Forum.json';
import * as FpComponents from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Fp_Components.json';
import * as FpMessage from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Fp_Message.json';
import * as Lenda from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Lenda.json';
import * as LoanApplications from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Loan_Applications.json';
import * as Login from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Login.json';
import * as Multiform from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Multiform.json';
import * as MultiformDictionary from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Multiform_Dictionary.json';
import * as Navigation from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Navigation.json';
import * as NewClientModal from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/New_Client_Modal.json';
import * as Notifications from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Notifications.json';
import * as Opinions from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Opinions.json';
import * as Pagination from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Pagination.json';
import * as Partners from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Partners.json';
import * as Prospects from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Prospects.json';
import * as SettlementDetails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Settlement_Details.json';
import * as Settlements from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Settlements.json';
import * as SmsEmails from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Sms_Emails.json';
import * as UseList from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Use_List.json';
import * as UserEditPanel from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/User_Edit_Panel.json';
import * as UsersManagements from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Users_Management.json';
import * as Validation from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Validation.json';
import * as SnackbarService from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/SnackbarService.json';
import * as Teleconference from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/UK/Teleconference.json';

import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import * as Accreditation from '@/services/enumTranslator/langDictionaries/jsonDicts/Namespaces/PL/Accreditation.json';

const translationsArray = [Autocompletes,
  BankBranches,
  Branches,
  CalculatorCashResults,
  CalculatorForm,
  CalculatorResults,
  CalculatorSchedule,
  CalculatorSchedulePrintout,
  CalculatorScheduleTable,
  ClientDetails,
  ClientDetailsBik,
  ClientDetailsDocuments,
  ClientList,
  Commons,
  CreateDealModal,
  Dashboard,
  DateFilters,
  DealAttributesDictionary,
  DealDetails,
  Deals,
  DealsList,
  DelegationLists,
  DivisionDetails,
  Divisions,
  Enums,
  ErrorPages,
  FinancialInstitutions,
  Forum,
  FpComponents,
  FpMessage,
  Lenda,
  LoanApplications,
  Login,
  Multiform,
  MultiformDictionary,
  Navigation,
  NewClientModal,
  Notifications,
  Opinions,
  Pagination,
  Partners,
  Prospects,
  SettlementDetails,
  Settlements,
  SmsEmails,
  UseList,
  UserEditPanel,
  UsersManagements,
  Validation,
  Accreditation,
  SnackbarService,
  Teleconference,
];

export const reducedJsonDictUK: I18NInterface = translationsArray.reduce((prev, current) => {
  const parsedCurrent = JSON.parse(JSON.stringify(current));
  return {...prev, ...parsedCurrent,};
}, {}) as typeof Autocompletes & typeof BankBranches
  & typeof Branches
  & typeof CalculatorCashResults
  & typeof CalculatorForm
  & typeof CalculatorResults
  & typeof CalculatorSchedule
  & typeof CalculatorSchedulePrintout
  & typeof CalculatorScheduleTable
  & typeof ClientDetails
  & typeof ClientDetailsBik
  & typeof ClientDetailsDocuments
  & typeof ClientList
  & typeof Commons
  & typeof CreateDealModal
  & typeof Dashboard
  & typeof DateFilters
  & typeof DealAttributesDictionary
  & typeof DealDetails
  & typeof Deals
  & typeof DealsList
  & typeof DelegationLists
  & typeof DivisionDetails
  & typeof Divisions
  & typeof Enums
  & typeof ErrorPages
  & typeof FinancialInstitutions
  & typeof Forum
  & typeof FpComponents
  & typeof FpMessage
  & typeof Lenda
  & typeof LoanApplications
  & typeof Login
  & typeof Multiform
  & typeof MultiformDictionary
  & typeof Navigation
  & typeof NewClientModal
  & typeof Notifications
  & typeof Opinions
  & typeof Pagination
  & typeof Partners
  & typeof Prospects
  & typeof SettlementDetails
  & typeof Settlements
  & typeof SmsEmails
  & typeof UseList
  & typeof UserEditPanel
  & typeof UsersManagements
  & typeof Validation
  & typeof Accreditation
  & typeof SnackbarService
  & typeof Teleconference;
