<template>
  <v-row>
    <v-col cols="12">
      <v-row
        v-for="(doc, index) in clientDocuments"
        :key="index">
        <v-col cols="12">
          <client-scan-card
            @remove="removeFile(index)"
            :doc="doc"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import ClientScanCard from '@/modules/client/views/create-client/ClientScanCard.vue';
import {ClientScan} from '@/models/clientScans/ClientScanModel';
@Component({
  components: {ClientScanCard,},
})
export default class AddClientScans extends Vue {
  @Prop({required: true, default: [], type: Array,})
  public readonly clientDocuments!: ClientScan[];

  public removeFile(index: number): void {
    this.clientDocuments[index].file = null;
  }

  public destroyed() {
    this.clientDocuments.forEach((scan: ClientScan) => {
      scan.file = null;
    });
  }
}
</script>
<style scoped lang="scss">
  .attachment-style {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
  }
</style>
