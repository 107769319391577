import {defineStore} from 'pinia';
import {
  CashSimulationDemand, InferredReplacement,
  LoanVariant,
  MortgageSimulationDemand,
  ReplacementKind, Replacements,
  SimulationDemand
} from '@/models/Demand';
import {Offer, Simulation} from '@/models/simulation/Simulation';
import {debouncedAtInput, removePropertyFromObject} from '@/services/utils/BasicUtils';
import SimulationApi from '@/modules/calculator/services/SimulationApi';
import {ProductType} from '@/commons/enums/ProductType';
import Vue from 'vue';
import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import useInitialDemands from '@/components/UseInitialDemands';
import GoToService from '@/router/GoToService';
import {FormSteps} from '@/components/calculator/CalculatorRoutes';
import {cloneDeep, transform} from 'lodash-es';
import useOfferSorting from '@/components/calculator/results/OfferSorting';
import Deal from '@/models/Deal';
import DealsApi from '@/modules/deals/services/DealsApi';
import {LoanPurposeActivity} from '@/models/simulation/LoanPurposeActivity';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {i18nFormatter} from '@/services/StringFormatter';
import {useCalculatorSettings} from '@/components/calculator/useCalculatorSettings';
import {HomeStartProgram, HomeStartProgramInput} from '@/components/calculator/schedule/Schedule';
import Axios from 'axios';

const STORE_ID = 'simulation-store';

export type SimulationProductType = ProductType.CASH | ProductType.MORTGAGE

export type SimulationDemandBasicParameters = Pick<MortgageSimulationDemand & CashSimulationDemand, 'loanAmount' | 'loanPeriodInMonths' | 'loanPeriod' | 'fixedInstallments' | 'additionalRequests' | 'explicitBridgingPeriod' | 'minProvision' | 'loanTypes' | 'brokerCommission' | 'hypothecValue'>

function getUserInputBasicParameters(userInput: SimulationDemandBasicParameters): SimulationDemandBasicParameters {
  return {
    loanAmount: userInput?.loanAmount,
    hypothecValue: userInput?.hypothecValue,
    loanPeriodInMonths: userInput?.loanPeriodInMonths,
    loanPeriod: userInput?.loanPeriod,
    fixedInstallments: userInput?.fixedInstallments,
    explicitBridgingPeriod: userInput?.explicitBridgingPeriod,
    additionalRequests: {...userInput?.additionalRequests,},
    loanTypes: {...userInput?.loanTypes,},
    minProvision: userInput?.minProvision,
    brokerCommission: userInput?.brokerCommission,
  };
}
export const useSimulationStore = defineStore(STORE_ID, {
  state: () => ({
    simulation: null as Nullable<Simulation>,
    userInput: null as Nullable<SimulationDemand>,
    initialUserInput: null as Nullable<SimulationDemandBasicParameters>,
    loading: false,
    simulationId: null as Nullable<string>,
    productType: null as Nullable<SimulationProductType>,
    isShownAvailableProducts: true,
    deal: null as Nullable<Deal>,
    isPrintMode: false,
    preventFetch: false as boolean,
    homeStartProgramAssumptions: null as Nullable<HomeStartProgram>,
    isFormValid: false,
    isHomeStartHidden: false,
  }),
  getters: {
    isCash: (state) => state.productType === ProductType.CASH,
    isMortgage: (state) => state.productType === ProductType.MORTGAGE,
    filteredOffers(state): Offer[] {
      const offers = state.simulation?.offers ?? [];
      const {defaultSort, sortItems, offerFilters,} = useOfferSorting();
      const filters = Object.values(offerFilters[state.productType!]).flat();
      return offers
        .filter(x => filters.map(filter => !state.userInput?.offerFilters[filter.type] ||
          filter.filterFunction(x)).every((x) => x === true)).filter(x => !state.userInput?.showOnlySelected ||
          state.userInput.selectedOffers.includes(x.info.id))
        .map((x, index) => ({...x, shortName: `${I18NGetter().useSimulationStore.OFFER} #${index + 1}`,}))
        .sort(sortItems[state.productType!].find(x => x.type === state.userInput?.sortBy)?.sortFunction ?? defaultSort);
    },
    allOffers(state): Offer[] {
      const selectedBanks = state.userInput?.selectedBanks ?? [];
      const banks = banksDictionary().map(x => x.type);
      return this.filteredOffers
        .filter(x => banks.includes(x.info.bank))
        .filter(x => selectedBanks.length === 0 || selectedBanks.includes(x.info.bank));
    },
    sortedOffers(state): Offer[] {
      return (this.allOffers as any as Offer[])
        .filter(x => !state.userInput?.pinnedOffers.includes(x.info.id));
    },
    pinnedOffers(state): Offer[] {
      return (this.allOffers as any as Offer[])
        .filter(x => state.userInput?.pinnedOffers.includes(x.info.id));
    },
    refusedOffers(state): Offer[] {
      const offers = state.simulation?.refusedOffers ?? [];
      const selectedBanks = state.userInput?.refusedOffers.selectedBanks ?? [];
      return offers
        .map((x: Offer, index: number) => ({...x, shortName: `${I18NGetter().useSimulationStore.OFFER} #${index + 1}`,}))
        .filter(x => selectedBanks.length === 0 || selectedBanks.includes(x.info.bank));
    },
    offersCount(state): number {
      return state.simulation?.offers.length ?? 0;
    },
    refusedOffersCount(state): number {
      return state.simulation?.refusedOffers?.length ?? 0;
    },
    visibleOffersCountLabel(state): string {
      const visibleOffersCount: number = this.isShownAvailableProducts
        ? this.sortedOffers.length + this.pinnedOffers.length
        : this.refusedOffersCount;
      const offersCount: number = this.isShownAvailableProducts ? this.offersCount : this.refusedOffersCount;
      return visibleOffersCount === offersCount ? offersCount.toString()
        : `${visibleOffersCount} ${I18NGetter().useSimulationStore.OF} ${offersCount}`;
    },
    isSimulationForDifferentLoanAmounts(state): boolean {
      const loanAmountReplacements = state.userInput?.additionalRequests?.replacements?.loanAmountReplacements;
      return !!(loanAmountReplacements && loanAmountReplacements
        .filter(x => x.newValue !== this.userInput?.loanAmount).length
      );
    },
    isSimulationForDifferentLoanPeriods(state): boolean {
      const loanPeriodReplacements = state.userInput?.additionalRequests?.replacements?.loanPeriodReplacements;
      return !!(loanPeriodReplacements && loanPeriodReplacements
        .filter(x => x.newLoanPeriodInMonths !== this.userInput?.loanPeriodInMonths).length
      );
    },
    selectedOffers(state): Offer[] {
      return this.filteredOffers.filter(offer => {
        return state.userInput?.selectedOffers.includes(offer.info?.id);
      }) ?? [];
    },
    selectedOffersCount(state): number {
      return state.userInput?.selectedOffers.length ?? 0;
    },
    selectedFiltersCount(state): number {
      if (!this.isShownAvailableProducts) {
        return state.userInput?.refusedOffers.selectedBanks.length ?? 0;
      }
      const selectedFiltersCount: number = Object.values(state.userInput?.offerFilters ?? {}).filter(x => x).length;
      const selectedBanksCount: number = state.userInput?.selectedBanks.length ?? 0;
      return selectedFiltersCount + selectedBanksCount;
    },
    isLoanPurposeActivityBuilding(state): boolean {
      return state.userInput?.loanPurpose.activity === LoanPurposeActivity.BUILDING;
    },
    isLoanPurposeActivityBuying(state): boolean {
      return state.userInput?.loanPurpose.activity === LoanPurposeActivity.BUYING;
    },
    isLoanPurposeActivityBuyingAndRenovating(state): boolean {
      return state.userInput?.loanPurpose.activity === LoanPurposeActivity.BUYING_AND_RENOVATING;
    },
  },
  actions: {
    async clear() {
      this.userInput = cloneDeep(useInitialDemands()[this.productType!])!;
      GoToService.simulationForm(this.productType!, FormSteps.CREDIT_PARAMETERS);
    },
    async init(
      productType: SimulationProductType,
      simulationId: Nullable<string> = null,
      deal: Nullable<Deal> = null,
      isPrintMode: boolean = false,
    ) {
      if (productType !== this.productType || simulationId !== this.simulationId) {
        this.simulation = null;
      }
      this.productType = productType;
      this.simulationId = simulationId;
      this.isPrintMode = isPrintMode;
      if (!this.simulation) {
        this.simulation = await this.fetchSimulation();
        const DemandClasses: Partial<Record<ProductType, typeof SimulationDemand>> = {
          [ProductType.CASH]: CashSimulationDemand,
          [ProductType.MORTGAGE]: MortgageSimulationDemand,
        };
        const Ctor: typeof SimulationDemand = DemandClasses[productType]!;
        const userInput = this.simulation ? new Ctor(this.simulation.userInput)
          : cloneDeep(useInitialDemands()[this.productType!])!;
        this.setUserInput(userInput);
      }
      if (deal) {
        this.deal = deal;
      }
      this.setSelectedBanksByDefault();
      if (this.userInput?.homeStartProgramInput) {
        await this.getHomeStartProgramAssumptions();
      }
    },
    setSelectedBanksByDefault() {
      const excludedBanksByProductType: Partial<Record<ProductType, BankSymbol[]>> = {
        [ProductType.CASH]: [],
        [ProductType.MORTGAGE]: [BankSymbol.SBR,],
      };
      const excludedBanks: BankSymbol[] = excludedBanksByProductType[this.productType!] ?? [];
      const banks = banksDictionary().filter(x => x.products![this.productType!]).map(x => x.type);
      this.userInput!.selectedBanks = excludedBanks.length ? banks
        .filter(type => !excludedBanks.includes(type)) : [];
    },
    setUserInput(newUserInput: SimulationDemand): void {
      this.userInput = newUserInput;
    },
    async fetchSimulation(): Promise<Simulation | null> {
      if (this.simulationId) {
        try {
          this.loading = true;
          const response = await SimulationApi.getSimulation(this.simulationId, this.productType!);
          this.simulation = response;
          return response;
        } catch (e) {
          Vue.prototype.errorHandler(e, I18NGetter().useSimulationStore.FETCH_SIMULATION_ERROR_HANDLER);
          const isNotFoundError: boolean = e.response.status === 404;
          if (isNotFoundError) {
            await this.clear();
            Vue.prototype.$snackbarService
              .openWarningSnackbar(I18NGetter().useSimulationStore.NEW_SIMULATION_CREATED);
          }
          return null;
        } finally {
          this.loading = false;
        }
      } else {
        console.error('There is no valid simulationId', this.simulationId);
        return null;
      }
    },
    async createSimulation(loanId: string): Promise<void> {
      try {
        if (!this.userInput?.pinnedOffers.includes(loanId)) {
          await this.pinOffer(loanId);
        }
        await this.createOrUpdateSimulation();
      } catch (e) {
        Vue.prototype.$snackbarService.openErrorSnackbar(I18NGetter().useSimulationStore
          .CREATE_SIMULATION_IF_AVAILABLE_CATCH_ERROR_SNACKBAR);
        console.error('offer not available');
      }
    },
    async createSimulationIfAvailable(loanId: string): Promise<void> {
      const isAvailable = await SimulationApi.isAvailable(loanId, this.productType!, this.userInput!);
      if (isAvailable) {
        await this.createSimulation(loanId);
      } else {
        Vue.prototype.$snackbarService.openErrorSnackbar(I18NGetter().useSimulationStore
          .CREATE_SIMULATION_IF_AVAILABLE_TRY_ERROR_SNACKBAR);
      }
    },
    cleanSelectedOffersMovedToRefused() {
      if (!this.selectedOffersCount) {
        return;
      }
      const allOffersIds: string[] = (this.simulation?.offers ?? []).map(x => x.info.id);
      const refusedOffersIds: string[] = (this.simulation?.refusedOffers ?? []).map(x => x.info.id);
      const selectedOffersMovedToRefusedCount: number = this.userInput?.selectedOffers
        .filter(x => refusedOffersIds.includes(x)).length ?? 0;
      if (selectedOffersMovedToRefusedCount) {
          this.userInput!.selectedOffers = this.userInput!.selectedOffers.filter(x => allOffersIds.includes(x));
          if (this.userInput!.selectedOffers.length > 0) {
            Vue.prototype.$snackbarService.openWarningSnackbar(
              `${selectedOffersMovedToRefusedCount > 1 ? i18nFormatter(I18NGetter().useSimulationStore.NEW_TRANSLATION_FEW_OFFERS_DESELECTED, {offersCount: selectedOffersMovedToRefusedCount,}) : I18NGetter().useSimulationStore.NEW_TRANSLATION_ONE_OFFER_DESELECTED} `
            );
          } else {
            this.userInput!.showOnlySelected = false;
            Vue.prototype.$snackbarService.openWarningSnackbar(
              I18NGetter().useSimulationStore.ALL_OFFERS_MOVED_TO_REFUSED_INFO
            );
          }
      }
    },
    async createOrUpdateSimulation(): Promise<void | false> {
      const userInputWithHomeStartProgramInput = this.userInput
      const userInputWithoutHomeStartProgramInput = removePropertyFromObject(this.userInput!, ['homeStartProgramInput',]);
      const userInput = this.isHomeStartHidden ? userInputWithoutHomeStartProgramInput : userInputWithHomeStartProgramInput;
      const simulationId = this.simulationId;
      const productType = this.productType;
      const ableToPerformSimulation: boolean =
          !!((userInput?.loanAmount || userInput?.hypothecValue));

      if (productType && userInput && ableToPerformSimulation) {
        if (productType === ProductType.MORTGAGE && !userInput.loanPeriodInMonths) {
          userInput.loanPeriodInMonths = userInput.loanPeriod! * 12;
        }
        try {
          if (simulationId) {
            // TODO: unify simulation update logic
            if (productType === ProductType.MORTGAGE) {
              this.loading = true;
              const response = await SimulationApi.updatePersistentSimulationV2(simulationId, productType, userInput);
              this.simulation = response;
            } else {
              this.loading = true;
              await SimulationApi.updateSimulationV2(simulationId, productType, userInput);
              setTimeout(async() => {
                try {
                  this.loading = true
                  await this.fetchSimulation();
                } catch (e) {
                  Vue.prototype.errorHandler(e, I18NGetter().useSimulationStore.CREATE_UPDATE_SIMULATION_CATCH_ERROR);
                } finally {
                  this.loading = false;
                }
              }, 1000);
            }
          } else {
            this.simulationId = await SimulationApi.createNewSimulationV2(productType, userInput);
            await this.fetchSimulation();
          }
          this.cleanSelectedOffersMovedToRefused();
          if (this.deal && !this.deal.simulationId) {
            this.assignSimulationToDeal();
          }
          if (this.isFormValid && !this.isHomeStartHidden) this.getHomeStartProgramAssumptions();
          this.loading = false;
        } catch (e) {
          if (!Axios.isCancel(e)) {
            console.error('error', e)
            this.loading = false;
            Vue.prototype.errorHandler(e, I18NGetter().useSimulationStore.CREATE_UPDATE_SIMULATION_CATCH_ERROR);
          } else {
            console.error(e, 'Zapytanie anulowane, czekaj na kolejną odpowiedź');
          }
        }
      } else {
        console.error('There is no valid productType or userInput');
      }
    },
    saveInitialUserInput() {
      this.initialUserInput = getUserInputBasicParameters(
        this.userInput as unknown as SimulationDemandBasicParameters
      );
    },
    restoreInitialUserInput() {
      this.userInput = {
        ...this.userInput,
        ...getUserInputBasicParameters(this.initialUserInput!),
      } as SimulationDemand;
    },
    async saveSimulation() {
      if (this.simulationId && this.productType && this.userInput) {
        await SimulationApi.postSimulation(this.simulationId, this.productType, this.userInput);
      } else {
        throw new Error(I18NGetter().useSimulationStore.SAVE_SIMULATION_ERROR);
      }
    },
    debouncedUpdateSimulation() {
      const {INPUT_DEBOUNCE_TIME_IN_MILLISECONDS,} = useCalculatorSettings();
      return debouncedAtInput(async() => {
        await this.createOrUpdateSimulation();
      }, INPUT_DEBOUNCE_TIME_IN_MILLISECONDS);
    },
    unselectAllBanks() {
      if (this.isShownAvailableProducts) {
        this.userInput!.selectedBanks = [];
      } else {
        this.userInput!.refusedOffers.selectedBanks = [];
      }
    },
    unselectAllFilters() {
      const filterKeys = Object.keys(this.userInput!.offerFilters);
      filterKeys.forEach((key: string) => {
        this.userInput!.offerFilters[key] = false;
      });
    },
    async removeCustomLoanAmounts() {
      if (this.userInput?.additionalRequests.replacements?.loanAmountReplacements) {
        this.userInput.additionalRequests.replacements.loanAmountReplacements = [];
      }
      await this.createOrUpdateSimulation();
    },
    async removeCustomLoanPeriods() {
      if (this.userInput?.additionalRequests.replacements?.loanPeriodReplacements) {
        this.userInput.additionalRequests.replacements.loanPeriodReplacements = [];
      }
      await this.createOrUpdateSimulation();
    },
    async removeCustomValues() {
      if (this.userInput?.additionalRequests.replacements?.loanPeriodReplacements) {
        this.userInput.additionalRequests.replacements.loanPeriodReplacements = [];
      }
      if (this.userInput?.additionalRequests.replacements?.loanAmountReplacements) {
        this.userInput.additionalRequests.replacements.loanAmountReplacements = [];
      }
      await this.createOrUpdateSimulation();
    },
    async pinOffer(id: string) {
      this.userInput!.pinnedOffers.push(id);
      await SimulationApi.pinOffer(this.productType!, this.simulationId!, id);
    },
    async unpinOffer(id: string) {
      this.userInput!.pinnedOffers = this.userInput!.pinnedOffers.filter(item => item !== id);
      await SimulationApi.unpinOffer(this.productType!, this.simulationId!, id);
    },
    async selectOffer(id: string) {
      this.userInput!.selectedOffers.push(id);
      await SimulationApi.selectOffer(this.productType!, this.simulationId!, id);
    },
    async unselectOffer(id: string) {
      this.userInput!.selectedOffers = this.userInput!.selectedOffers.filter(item => item !== id);
      if (this.userInput!.showOnlySelected && !this.userInput!.selectedOffers.length) {
        this.userInput!.showOnlySelected = false;
      }
      await SimulationApi.unselectOffer(this.productType!, this.simulationId!, id);
    },
    async unselectedAllOffers() {
      const ids = this.userInput!.selectedOffers;

      try {
        await Promise.all(
          ids.map((id) => {
            return SimulationApi.unselectOffer(this.productType!, this.simulationId!, id);
          })
        );

        this.userInput!.selectedOffers = [];
        this.userInput!.showOnlySelected = false;
      } catch (error) {
        throw error;
      }
    },
    async duplicateOffer(id: string) {
      this.userInput!.additionalRequests = await SimulationApi.duplicateLoan(
        id,
        this.productType!,
        this.userInput!.additionalRequests!);
      const variants: LoanVariant[] = this.userInput!.additionalRequests
        .loanVariants!
        .filter(x => x.originLoanId === id);
      const duplicateOfferId: string = variants[variants.length - 1]?.variantLoanId;
      if (duplicateOfferId) {
        this.userInput!.pinnedOffers.push(duplicateOfferId);
        const isOfferSelected: boolean = this.userInput!.selectedOffers.includes(id);
        if (isOfferSelected) {
          this.userInput!.selectedOffers.push(duplicateOfferId);
        }
        await this.createOrUpdateSimulation();
      }
    },
    async removeDuplication(id: string) {
      this.userInput!.additionalRequests = await SimulationApi.removeDuplication(
        id,
        this.productType!,
        this.userInput!.additionalRequests!);
      this.simulation!.offers = this.simulation!.offers.filter(x => x.info.id !== id);
      this.userInput!.selectedOffers = this.userInput!.selectedOffers.filter(x => x !== id);
      if (this.userInput!.showOnlySelected && !this.userInput!.selectedOffers.length) {
        this.userInput!.showOnlySelected = false;
      }
      await this.createOrUpdateSimulation();
    },
    async deleteSimulationFromDeal() {
      this.simulationId = null;
      this.deal = null;
      await this.createOrUpdateSimulation();
    },
    async setDeal(dealId: number) {
      try {
        this.deal = await DealsApi.getDeal(dealId);
      } catch (e) {
        Vue.prototype.errorHandler(e, I18NGetter().useSimulationStore.FETCH_DEAL_ERROR);
      }
    },
    async assignSimulationToDeal() {
      try {
        this.deal = await DealsApi.updateDeal(
          {
            ...this.deal as Deal,
            simulationId: this.simulationId,
            loanAmount: this.userInput?.loanAmount || this.deal?.loanAmount,
          });
      } catch (e) {
        Vue.prototype.errorHandler(e, I18NGetter().useSimulationStore.UPDATE_DEAL_ERROR);
      }
    },
    toggleHideOfferName() {
      this.userInput!.hideOfferName = !this.userInput!.hideOfferName;
    },
    async getHomeStartProgramAssumptions() {
      const loanAmount = this.userInput?.loanAmount;
      const data: HomeStartProgramInput & {loanAmount: number} = {
        ...this.userInput?.homeStartProgramInput,
        loanAmount: loanAmount!,
      } as HomeStartProgramInput & {loanAmount: number};
      try {
        this.homeStartProgramAssumptions = await SimulationApi.getHomeStartAssumptions(data!);
      } catch (e) {
        Vue.prototype.errorHandler(e, 'Błąd pobierania danych programu Mieszkanie na start');
      }
    },
    setIsFormValid(isValid: boolean) {
      this.isFormValid = isValid;
    },
    setIsHomeStartFormHidden(isHidden: boolean) {
      this.isHomeStartHidden = isHidden;
    },
    setReplacement<T extends ReplacementKind>(type: T, index: number, replacement: InferredReplacement<T>): void {
      this.userInput!.additionalRequests!.replacements![type] = this.userInput!
        .additionalRequests.replacements![type].map(
          (replacementOffer, i: number) => i === index ? {...replacementOffer,...replacement,} : replacementOffer) as (InferredReplacement<typeof type>)[]
    },
  },
});
