<template>
  <v-row class="no-gutters">
    <v-col v-if="xSellProduct && xSellProduct.covers" cols="12">
      <template v-for="(value, key) in xSellProduct.covers">
        <v-chip :key="key" v-if="value" class="ml-0 mr-2">
          {{ getCoverName(key) }}</v-chip>
      </template>
    </v-col>
    <v-col cols="12" v-if="xSellProduct">
      <v-switch
        v-if="!print"
        @click.stop
        @change="onRequiredChange($event, xSellProduct.insuranceDefinitionId)"
        :error="!xSellProduct.required"
        hide-details
        flat
        :disabled="isMandatoryFromBank"
        class="xsell-switch"
        v-model="xSellProduct.required">
        <template #label>
          <span>{{ isCredited ? I18NGetter().useXSellVariantsRadio.CREDITED :
            xSellProduct.required ? I18NGetter().useXSellVariantsRadio.INCLUDED : I18NGetter().useXSellVariantsRadio.NOT_INCLUDED }}</span>
        </template>
      </v-switch>
      <template v-else>
        <div :class="{print}">
          <span v-if="isCredited" class="primary--text font-weight-medium d-flex align-center"><v-icon class="pr-1 primary--text">mdi-information-outline</v-icon>{{ I18NGetter().useXSellVariantsRadio.CREDITED.toUpperCase() }}</span>
          <span v-else-if="xSellProduct.required" class="success--text font-weight-medium d-flex align-center"><v-icon class="pr-1 success--text">mdi-check-circle-outline</v-icon>{{ I18NGetter().useXSellVariantsRadio.INCLUDED.toUpperCase() }}</span>
          <span v-else class="error--text font-weight-medium d-flex align-center"><v-icon
            class="pr-1 error--text">mdi-close-circle-outline</v-icon>{{ I18NGetter().useXSellVariantsRadio.NOT_INCLUDED.toUpperCase() }}</span>
        </div>
      </template>
      <p class="mb-1" :class="print ? 'print-font-size-11' : 'body-2'">{{ hintLabel }}</p>
      <p v-if="print && isAnyRequired && isNotIncluded">{{ I18NGetter().useXSellVariantsRadio.NOT_INCLUDED_INFO }}</p>
      <template v-else>
        <fp-radio-group
          v-if="items.length > 1 && !print"
          @click.stop
          :disabled="!xSellProduct.required"
          v-model="xSellProduct.currentVariantId"
          :outline="false"
          @input="onInsuranceChange($event)"
          :connected="true"
          :items="items"
        />
        <template v-else>
          <p class="font-weight-medium mb-1 print-font-size-11">
            {{ pickedVariant.name }}
            <v-tooltip top v-if="!print && pickedVariant.description" max-width="300px">
              <template v-slot:activator="{on}">
                <v-icon size="18" v-on="on">mdi-information</v-icon>
              </template>
              {{ pickedVariant.description }}
            </v-tooltip>
          </p>
          <p class="mb-1"
             :class="print ? 'font-weight-regular' : 'caption font-weight-medium text--secondary'"
             :key="index"
             v-for="(payment, index) in pickedVariant.payments">
            {{ payment.paymentDescription }} {{ payment.durationDescription }}
          </p>
          <p class="font-weight-regular" v-if="print && !isAdditionalInfoHidden && pickedVariant.description">
            {{ pickedVariant.description }}
          </p>
        </template>

        <p class="font-weight-regular mt-1"
           v-if="print && !isAdditionalInfoHidden && xSellProduct.description">
          {{ xSellProduct.description }}
        </p>
        <p class="caption font-weight-medium text--secondary mb-0 mt-1"
           v-else-if="!print && xSellProduct.description">
          {{ xSellProduct.description }}
        </p>
      </template>
    </v-col>
    <v-col v-else class="font-weight-medium d-flex align-center justify-left" :class="print ? 'print-font-size-11' : 'body-1'">
      {{ I18NGetter().useXSellVariantsRadio.NO_PRODUCT }}
    </v-col>
  </v-row>
</template>
<script lang="ts">

import {computed, defineComponent, PropType} from 'vue';
import {
  Cost,
  InsuranceCovers,
  InsuranceDescription,
  Offer,
  Variant,
  XSellKind,
  XSellType
} from '@/models/simulation/Simulation';
import {debouncedAtInput} from '@/services/utils/BasicUtils';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export default defineComponent({
  name: 'XSellVariantsRadio',
  props: {
    kind: {
      required: true,
      type: String as PropType<XSellKind>,
    },
    offer: {
      required: true,
      type: Object as PropType<Offer>,
    },
    print: {
      required: false,
      default: false,
      type: Boolean,
    },
    isAdditionalInfoHidden: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const store = useSimulationStore();
    const isCredited: boolean = props.offer.creditedCosts.includes(props.kind);
    const insurancePackage = props.offer.insurances.insurancePackage;
    const isInPackage: boolean = !!insurancePackage?.required && insurancePackage?.replaces.includes(props.kind);
    const type: XSellType = (props.kind[0].toLowerCase() + props.kind.substr(1)) as unknown as XSellType;
    // Czasem są specjalne przypadki w których switch musi być disabled i jedyne co to informować o aktualnym stanie, bez pokazania wariantów
    const specialCaseLabel: string | boolean = isCredited ? I18NGetter().useXSellVariantsRadio.CREDITED
      : isInPackage ? I18NGetter().useXSellVariantsRadio.INCLUDED_IN_PACKAGE : false;
    const xSellProduct: InsuranceDescription | undefined = props.offer.insurances[type];

    const getTranslatedMessage = (key: string): string => {
      const formattedKey = key.replace(/\./g, '_');
      return I18NGetter().useInsurenceBackendMessages?.[formattedKey] || key;
    };

    const translateText = (text: string): string => {
      const percentRegex = /\d{1,2},\d{1,2}% (kwoty kredytu|kwoty i okresu kredytu)/g;
      const durationRegex = /od (\d{1,2})\. miesiąc(?:a|e|y) do końca kredytu/g;
      const customDurationRegex = /od uruchomienia kredytu do uruchomienia kredytu/g;

      const matches = text.match(percentRegex) || [];
      const translatedMatches = matches.map(match => {
        const [numberPart, ...textParts] = match.split(/ (?=\w+)/);
        const textPart = textParts.join(' ');
        const translatedText = getTranslatedMessage(textPart.trim());
        return `${numberPart} ${translatedText}`;
      });

      let translatedText = text;
      matches.forEach((match, index) => {
        translatedText = translatedText.replace(match, translatedMatches[index]);
      });

      translatedText = translatedText.replace(durationRegex, (match, month) => {
        const isSingular = parseInt(month) === 1;
        const templateKey = isSingular
          ? 'od {{count}}_ miesiąca do końca kredytu'
          : 'od {{count}}_ miesięcy do końca kredytu';
        const template = getTranslatedMessage(templateKey);
        return template ? template.replace('{{count}}', month) : match;
      });

      translatedText = translatedText.replace(customDurationRegex, match => {
        const template = getTranslatedMessage('od uruchomienia kredytu do uruchomienia kredytu');
        return template || match;
      });

      return translatedText;
    }

    const items = xSellProduct?.variants.map(variant => ({
      name_pl: variant.name,
      type: variant.id,
      tooltip: variant.description,
      description: variant.payments
      // here add translations preferably on be
        .map(payment => `${translateText(payment.paymentDescription) || ''} ${translateText(payment.durationDescription || '')}`)
        .join(', '),
    })) ?? [];
    const isVoluntary: boolean = Boolean(!xSellProduct?.originallyRequired ||
        props.offer.info.voluntaryInsurances?.includes(props.kind));

    // Ta flaga mówi o tym, że produkt jest wymagany z banku, nie mozna kupić go poza bankiem
    const mandatoryFromBankProductKinds: XSellKind[] = [Cost.BRIDGING_INSURANCE, Cost.LOW_PERSONAL_SHARE_INSURANCE,];

    const isAnyRequired = computed<boolean>(() =>
      !isVoluntary &&
      !(props.offer.info.xsellInsurances?.includes(props.kind) || mandatoryFromBankProductKinds.includes(props.kind))
    );

    const isNotIncluded = computed<boolean>(() => !(isCredited || xSellProduct?.required));

    const getHintLabel = (): string | undefined => {
      // W przypadku ubezpieczenia niskiego wkładu nie ma sensu pokazywać etykiety.
      // Ubezpieczenie niskiego wkładu jest zawsze wymagane kiedy jest wliczone i go nie ma kiedy nie jest wliczone
      if (props.kind === Cost.LOW_PERSONAL_SHARE_INSURANCE) {
        return undefined;
      }
      const REQUIRED_BY_BANK_KINDS: XSellKind[] | undefined = [
        ...(props.offer.info.xsellInsurances ?? []), ...mandatoryFromBankProductKinds,
      ];
      const REQUIRED_BY_BANK_FOR_X_SELL: XSellKind[] | undefined = props.offer.info.insurancesRequiredFromBankForXsell;

      if (REQUIRED_BY_BANK_KINDS?.includes(props.kind)) {
        return I18NGetter().useXSellVariantsRadio.REQUIRED_BY_BANK;
      } else if (REQUIRED_BY_BANK_FOR_X_SELL?.includes(props.kind)) {
        return I18NGetter().useXSellVariantsRadio.REQUIRED_BY_BANK_FOR_X_SELL;
      } else if (isVoluntary) {
        return I18NGetter().useXSellVariantsRadio.VOLUNTARY;
      } else {
        return I18NGetter().useXSellVariantsRadio.REQUIRED_ANY;
      }
    };
    const hintLabel: string | undefined = getHintLabel();

    const isMandatoryFromBank: boolean =
        props.kind === Cost.LOW_PERSONAL_SHARE_INSURANCE ||
((props.offer.info.xsellInsurances?.includes(props.kind) || mandatoryFromBankProductKinds.includes(props.kind)) &&
        !!xSellProduct?.originallyRequired);

    const pickedVariant = computed<Nullable<Variant>>(() =>
      xSellProduct?.variants.find(x => x.id === xSellProduct.currentVariantId) ?? null);

    const onRequiredChange = debouncedAtInput(async(required: boolean, insuranceId: string) => {
      const loanId = props.offer.info.id;
      if (!store.userInput!.additionalRequests.insuranceDemands) {
        store.userInput!.additionalRequests.insuranceDemands = [];
      }
      if (store.userInput!.additionalRequests.insuranceDemands) {
        const foundDemand = store.userInput!
          .additionalRequests
          .insuranceDemands
          .find(x => x.insuranceId === insuranceId && x.loanId === loanId);
        if (!foundDemand) {
          store.userInput!.additionalRequests.insuranceDemands.push({
            loanId,
            required,
            insuranceId,
            kind: props.kind,
          });
        } else {
          foundDemand.required = required;
        }
        await store.createSimulation(loanId);
      }
    }, 800);
    const onInsuranceChange = debouncedAtInput(async(insuranceVariantId: string) => {
      const loanId = props.offer.info.id;
      if (store.userInput!.additionalRequests.replacements) {
        const foundReplacement = store.userInput!.additionalRequests
          .replacements
          .insuranceVariantReplacements
          .find(x => x.loanId === loanId && x.insuranceKind === props.kind);
        if (!foundReplacement) {
          store.userInput!.additionalRequests.replacements.insuranceVariantReplacements.push({
            loanId,
            insuranceVariantId,
            insuranceKind: props.kind,
            insuranceId: xSellProduct!.insuranceDefinitionId,
          });
        } else {
          foundReplacement.insuranceVariantId = insuranceVariantId;
        }
        await store.createSimulation(loanId);
      }
    }, 1000);

    const getCoverName = (key: keyof InsuranceCovers): string => {
      const uppercaseKey = key.toUpperCase() as Uppercase<keyof InsuranceCovers>;
      return I18NGetter().useXSellVariantsRadio[uppercaseKey];
    };

    return {
      items,
      xSellProduct,
      isMandatoryFromBank,
      onInsuranceChange,
      onRequiredChange,
      specialCaseLabel,
      hintLabel,
      pickedVariant,
      isInPackage,
      isVoluntary,
      isCredited,
      I18NGetter,
      getCoverName,
      isAnyRequired,
      isNotIncluded,
    };
  },
});
</script>
<style scoped lang="scss">

/*Jest to nadpisywane na szare kiedy jest disabled*/
::v-deep .error--text {
  label {
    color: var(--v-error-base) !important;
  }
}
::v-deep .v-input--is-disabled {
  .v-input--selection-controls__input {
    display: none;
  }
}
::v-deep .v-label {
  p.text--primary {
    font-size: 0.875rem;
  }

  span.d-inline-block {
    font-size: 0.75rem;
  }
}

.print {
  font-size: 11px !important;

  .v-icon {
    font-size: 14px;
  }
}
</style>
