<template>
  <fp-loader v-if="!items"/>
  <v-card v-else class="mx-6">
    <v-card-text>
      <fp-data-table
          v-model="selectedUsers"
          :headers="allHeaders"
          :items="filteredItems"
          :options="options"
          :show-select="showMultipleUsersOperationMenu"
          :show-expand="expandableRows"
          :height="height"
          @update:options="onUpdateOptions"
        >
        <template #top>
          <table-pagination :options="options">
            <v-row>
              <v-col>
                <fp-input
                    xs12
                    ref="comboBox"
                    append-icon="mdi-magnify"
                    :placeholder="$i18n.useInputCommons.SEARCH"
                    return-object
                    dense
                    item-text="displayName"
                    v-model="nameFilter"
                    @input="filterByName">
                </fp-input>
              </v-col>
              <v-col
                  align-self="center"
                  v-if="!readonly && !isOfflineVersion">
                <list-actions v-model="showMultipleUsersOperationMenu">
                  <span v-if="showMultipleUsersOperationMenu">{{ $i18n.useExpertsList.CHOSEN }} {{selectedUsers.length}}</span>
                </list-actions>
              </v-col>
            </v-row>
          </table-pagination>
        </template>
        <template #item="{item, select, isSelected, isExpanded, expand}">
          <tr @click="goToUserDetails(item.id)" class="text-left"
              :class="{
                'text--disabled': !item.isActive,
                'pointer': $user.privileges.canManageOrganisation
          }">
            <td v-if="expandableRows"
              @click.stop="onExpandClick(item, expand, isExpanded)"
              :class="{'expanded primary--text': isExpanded}"
              class="px-2">
              <v-icon>mdi-chevron-right</v-icon>
            </td>
            <td v-if="showMultipleUsersOperationMenu">
              <fp-checkbox class="py-0 my-0"
                           default-styles
                           @click.stop.native.prevent="select(isSelected)"
                           @input="isSelected = $event"
                           :value="isSelected"
              />
            </td>
            <td class="text-left"><span @click.stop="() => {}">{{ item.id }}</span></td>
            <td class="text-left"><span @click.stop="() => {}">{{ item.username }}</span></td>
            <td class="text-left">
              <v-row no-gutters class="display-flex align-center pl-0 py-4" align="center">
                <person-avatar :person="item" class="mr-6" :disabled="!item.isActive"/>
              </v-row>
            </td>
            <td class="text-left">{{ item.firstName }}</td>
            <td class="text-left">{{ item.lastName }}</td>
            <td class="text-left">
              <v-row v-if="item.phone">{{ item.phone }}</v-row>
              <v-row v-if="item.email">{{ item.email }}</v-row>
            </td>
            <td v-if="$user.isVerifier || $user.isAdmin" class="text-left">
              <span v-if="!item.canManageWidgets && !item.canManageProspects">{{ $i18n.useExpertsList.NOT_LEAD }}</span>
              <v-row>
                <v-tooltip v-if="item.canManageWidgets" top>
                  <template #activator="{ on }">
                    <v-col cols="6">
                      <v-icon v-on="on">mdi-widgets</v-icon>
                    </v-col>
                  </template>
                  <span>{{ $i18n.useExpertsList.WIDGETS }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.canManageProspects" top>
                  <template #activator="{ on }">
                    <v-col cols="6">
                      <v-icon v-on="on">mdi-leaf</v-icon>
                    </v-col>
                  </template>
                  <span>{{ $i18n.useExpertsList.PROSPECTS }}</span>
                </v-tooltip>
              </v-row>
            </td>
            <td v-if="$user.isAdminOrVerifier && hasAccessToOrganisations"
                class="text-left">{{ item.organisationName || $i18n.useTableCommons.NONE }}
            </td>
            <slot :item="item"/>
          </tr>
        </template>
        <template #expanded-item="{ item }">
          <td></td>
          <td :colspan="headers.length" class="py-4">
            <v-card v-if="expandedItems[item.id].length" class="d-inline-block">
              <v-card-text>
                <user-divisions v-model="expandedItems[item.id]" :user-id="item.id"></user-divisions>
              </v-card-text>
            </v-card>
            <div v-else class="d-flex align-center">
              <v-icon class="primary--text">mdi-information</v-icon>
              {{ $i18n.useExpertsList.NO_DIVISION_EXPERT }}
            </div>
          </td>
        </template>
      </fp-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {Emit, Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import FpDataTable from '@/components/datatables/FpDataTable.vue';
import TablePagination from '@/components/commons/TablePagination.vue';
import ListActions from '@/components/commons/ListActions.vue';
import PersonAvatar from '@/components/commons/PersonAvatar.vue';
import User from '@/models/user/User';
import {DataTableHeader, IDataTableHeader} from '@/services/searchEngine/interfaces/DataTableHeader';
import {UserFilter} from '@/models/Filter';
import {debounce} from 'lodash-es';
import FuzzySearch from '@/services/searchEngine/FuzzySearch';
import DivisionApi from '@/modules/division/services/DivisionApi';
import Division from '@/models/Division';
import UserDivisions from '@/components/datatables/UserDivisions.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import EnvironmentService from '@/env/EnvironmentService';

const ALL_ROWS_PER_PAGE = -1;
@Component({
  components: {FpDataTable, TablePagination, ListActions, PersonAvatar, UserDivisions,},
})
export default class ExpertsList extends Vue {
  @Prop({ required: false,})
  public readonly headers!: Array<DataTableHeader<User, UserFilter>>;

  @Prop({ required: false, default: false,})
  public readonly readonly!: boolean;

  @Prop({ required: false, default: () => new UserFilter({rowsPerPage: ALL_ROWS_PER_PAGE,}), })
  public readonly options!: UserFilter;

  @Prop({required: true,})
  public readonly items!: Nullable<Array<User>>;

  @Prop({default: false, })
  public readonly expandableRows?: boolean;

  @Prop()
  public readonly height?: string;

  get isOfflineVersion(): boolean {
    return !this.options.quantity;
  }

  get hasAccessToOrganisations(): boolean {
    return EnvironmentService.Environment.getAppDomainConfig().organisations.hasAccess;
  }

  public get defaultHeaders(): Array<DataTableHeader<User, UserFilter>> {
    const headers: IDataTableHeader<User, UserFilter>[] = [
      {text: I18NGetter().useExpertsList.ID, valueSort: 'id', },
      {text: I18NGetter().useExpertsList.LOGIN, },
      {text: I18NGetter().useExpertsList.AVATAR, },
      {text: I18NGetter().useUserCommons.FIRST_NAME, },
      {text: I18NGetter().useUserCommons.LAST_NAME, },
      {text: I18NGetter().useExpertsList.CONTACT_DATA, },
      {text: I18NGetter().useExpertsList.IS_LEADING, condition: this.$user.isVerifier || this.$user.isAdmin,},
      {text: I18NGetter().useExpertsList.ORGANISATION, condition: this.$user.isVerifier || this.$user.isAdmin,},
      {text: I18NGetter().useExpertsList.CHIEF, condition: this.$user.isVerifier || this.$user.isAdmin,},
    ];
    return headers.map(x => new DataTableHeader<User, UserFilter>(x));
  }

  get allHeaders(): DataTableHeader<User, UserFilter>[] {
    const tableHeaders = (this.headers || this.defaultHeaders);

    return tableHeaders.filter(
      header => header.condition === undefined || Boolean(header.condition)
    );
  }

  get filteredItems(): Array<User> {
    const array = (this.items || []).filter(
      (user: User) => user.isActive || this.$user.isVerifier || this.$user.isAdmin
    );
    return this.readonly
      ? FuzzySearch.sortAndFilter(array, this.nameFilter || '')
      : array;
  }

  public showMultipleUsersOperationMenu: boolean = false;
  public selectedUsers: Array<User> = [];
  public nameFilter: Nullable<string> = null;
  public debouncedAtInput: (fn: () => void) => void = debounce((fn: () => void) => fn(), 500);
  public expandedItems: Record<number, Array<Division>> = {};

  @Emit('onUpdateOptions')
  onUpdateOptions() {
    if (this.options) {
      this.$goto.setQuery({page: String(this.options.page), }, false);
    }
  }

  public async filterByName() {
    if (this.readonly) {
      this.options.name = this.nameFilter;
    } else {
      this.debouncedAtInput(() => {
        this.options.name = this.nameFilter;
        this.onUpdateOptions();
      });
    }
  }

  public goToUserDetails(id: string) {
    if (this.$user.privileges.canManageOrganisation) {
      this.$goto.user(Number(id));
    }
  }

  public async onExpandClick(user: User, expand: Function, isExpanded: boolean) {
    if (!this.expandedItems[user.id]) {
      await this.fetchUserDivisions(user.id);
    }

    expand(!isExpanded);
  }

  public async fetchUserDivisions(userId: number) {
    try {
      this.expandedItems[userId] = await DivisionApi.getDivisionsForUser(userId);
    } catch (e) {
      this.errorHandler(e, I18NGetter().useExpertsList.FETCH_USER_DIVISIONS_ERROR_HANDLER);
    }
  }
}
</script>

<style scoped>
.expanded {
  transform: rotate(90deg);
}
</style>
