import {Tour} from '@/modules/onboarding/tour/Tour';
import {TourLabel, TourName} from '@/modules/onboarding/tour/models/TourOptions';
import MortgageCalculatorTour from '@/modules/onboarding/tours/MortgageCalculatorTour';
import DashboardTour from '@/modules/onboarding/tours/DashboardTour';
import LeadTour from '@/modules/onboarding/tours/LeadTour';

export class OnboardingService {
  public tours: Record<TourName, Tour> = {
    [TourName.MORTGAGE_CALCULATOR]: MortgageCalculatorTour,
    [TourName.DASHBOARD]: DashboardTour,
    [TourName.LEAD]: LeadTour,
  };
}
