<template>
  <simulation-form-wrapper
    :loading="store.loading"
    :simulation="store.simulation"
    :simulation-demand="store.userInput"
  >
    <template #form>
      <router-view :simulationDemand="store.userInput"/>
    </template>
  </simulation-form-wrapper>
</template>
<script lang="ts">
import SimulationFormWrapper from '@/components/calculator/form/SimulationFormWrapper.vue';
import {defineComponent, getCurrentInstance} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {Utils} from '@/services/utils/BasicUtils';
import {ProductType} from '@/commons/enums/ProductType';
import useInitialDemands from '@/components/UseInitialDemands';
import {cloneDeep} from 'lodash-es';
import {useDealForm} from '@/components/calculator/form/useSimulationForm';

const initialDemand = cloneDeep(useInitialDemands().productTypeCash!);

export default defineComponent({
  components: {SimulationFormWrapper,},
  setup() {
    const instance = getCurrentInstance();
    const store = useSimulationStore();
    const simulationId = Utils.getFirstQuery(instance!.proxy.$route.query.simulationId) || null;
    if (!simulationId) {
      store.$reset();
      store.setUserInput(initialDemand);
    }
    store.init(ProductType.CASH, simulationId);
    return {
      store,
    };
  },
});
</script>
<style scoped lang="scss">

</style>
