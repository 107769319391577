<template>
  <v-list color="transparent" dense class="pb-0 pt-0">
    <l-lazy>
      <draggable
          :disabled="!draggable"
          handle=".handle"
          animation="0"
          ghostClass="ghost"
          @start="isDragged = true"
          @end="onDragEnd"
          v-model="renderedOfferTableElements">
        <template v-for="(dataElement, index) in renderedOfferTableElements">
            <div
              class="bb"
              :key="dataElement.key + index"
            >
              <v-list-item
                @click="dataElement.open = !dataElement.open"
                :link="draggable"
                :ripple="!!dataElement.children"
                :style="listElementHeight(dataElement)"
                class="px-0"
                :class="dataElement.headerClass"
                @mouseover="dataElement.hover = true"
                @mouseleave="dataElement.hover = false">
                <v-list-item-icon v-if="draggable"
                                  class="align-self-center drag handle"
                                  :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.ROWS_ORDER"
                >
                  <v-icon>mdi-drag</v-icon>
                </v-list-item-icon>
                <v-list-item-content :class="{'ml-2': !draggable,}">
                  <slot :dataElement="dataElement" :isSubheader="false"/>
                </v-list-item-content>
                <template v-if="dataElement.children">
                  <v-icon>{{dataElement.open ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                </template>
              </v-list-item>
              <template v-if="dataElement.open">
                <v-list-item
                  v-for="(child, i) in dataElement.children"
                  :link="draggable"
                  :disabled="!draggable"
                  class="bt body-2"
                  :style="listElementHeight(child)"
                  :key="i">
                  <slot :dataElement="child" :isSubheader="true"/>
                </v-list-item>
              </template>
            </div>
          </template>
      </draggable>
    </l-lazy>
    <div class="border-bottom"></div>
  </v-list>
</template>
<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import {OfferTableElement} from '@/components/calculator/results/offers/OfferTableElement';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import draggable from 'vuedraggable';
import LLazy from '@/components/commons/LLazy.vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';

export default defineComponent({
  components: {LLazy, draggable,},
  props: {
    draggable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props,{emit,}) {
    const store = useSimulationStore();
    const {listElementHeight, renderedOfferTableElements,} = useOffersTable();
    const isDragged = ref<boolean>(false);

    const onDragEnd = () => {
      emit('dragEnd');
      isDragged.value = false;
    };

    return {
      listElementHeight,
      renderedOfferTableElements,
      onDragEnd,
      isDragged,
      store,
    };
  },
});
</script>
<style scoped lang="scss">
::v-deep .v-list-group--disabled {
 .v-list-item--link {
   cursor: default;
 }
}
.handle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.flip-list-move {
  transition: transform 0.3s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
}
</style>
