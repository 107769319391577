<template>
  <v-card class="warning-card" :id="$cy.clientOwnerInfoCard">
    <v-card-title class="font-weight-medium">
      {{ message }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <p class="all-caps-header mb-2">{{ $i18n.useClientOwnerInfoCard.CLIENT }}</p>
          <client-badge
              :hide-actions="true"
              :client="client"
              :readonly="true"/>
        </v-col>
        <template v-if="client.owner && client.owner.id !== $user.id">
          <v-divider class="warning mr-2" vertical v-if="$vuetify.breakpoint.smAndUp"></v-divider>
          <v-col>
            <p class="all-caps-header mb-2">{{ $i18n.useClientOwnerInfoCard.OWNER }}</p>
            <user-badge
                :user="client.owner"
                :readonly="true"/>
          </v-col>
        </template>
      </v-row>
      <template v-if="client.owner && client.owner.id !== $user.id">
        <v-row>
          <v-col class="shrink">
            <v-icon>
              mdi-account-arrow-right-outline
            </v-icon>
          </v-col>
          <v-col class="pl-0">
            {{ $i18n.useClientOwnerInfoCard.TO_CREATE_DEAL }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="shrink">
            <v-icon>
              mdi-checkbox-multiple-marked-outline
            </v-icon>
          </v-col>
          <v-col class="pl-0">
            {{$i18n.useClientOwnerInfoCard.AGREEMENTS_ALREADY_EXIST }}
          </v-col>
        </v-row>
      </template>
      <v-row v-if="isAppDomain.LENDI_PL">
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-col class="shrink">
          <fp-btn
              primary
              :click="() => goToClient()"
              :id="$cy.goToClientOnOwnerInfoCard">
            {{ $i18n.useClientOwnerInfoCard.SEE_CLIENT_PROFILE }}
          </fp-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import Client from '@/models/Client';
import UserBadge from '@/components/badges/UserBadge.vue';
import GoToService from '@/router/GoToService';
import EnvironmentService from '@/env/EnvironmentService';
import Activity from '@/models/Activity';

const props = defineProps<{
  message: string,
  client: Client,
}>();
const emit = defineEmits<{
  (event: 'click:goToClientDetails'): void,
}>();

const isAppDomain = EnvironmentService.Environment.isAppDomain();
const goToClient = () => {
  GoToService.client(props.client.id);
  emit('click:goToClientDetails');
}
</script>
<style scoped lang="scss">

</style>
