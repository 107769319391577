<template>
  <v-layout wrap>
    <v-flex xs12 md6>
      <v-layout wrap>
        <fp-input
            xs12
            prepend-icon="mdi-cash-fast"
            :label="I18NGetter().useCashSimulationResultsAndFormStep1.LOAN_AMOUNT"
            type="number"
            int
            required
            v-model="store.userInput.loanAmount"/>
        <fp-input
            xs12
            :label="I18NGetter().useCashSimulationResultsAndFormStep1.LOAN_PERIOD"
            type="number"
            prepend-icon="mdi-clock-outline"
            :suffix="I18NGetter().useCashSimulationResultsAndFormStep1.MONTHS"
            int
            required
            v-model="store.userInput.loanPeriod"/>
      </v-layout>
      <v-layout wrap class="ml-6">
        <span class="font-weight-medium ml-2">{{ I18NGetter().useCashSimulationResultsAndFormStep1.LOAN_TYPES }}</span>
        <v-flex xs12 class="pb-0">
          <fp-checkbox
            v-model="store.userInput.loanTypes.cash"
            :label="I18NGetter().useCashSimulationResultsAndFormStep1.CASH"/>
        </v-flex>
        <v-flex xs12 wrap>
          <fp-checkbox
            v-model="store.userInput.loanTypes.consolidation"
            :label="I18NGetter().useCashSimulationResultsAndFormStep1.CONSOLIDATION"/>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script lang="ts" setup>
import {defineComponent} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
const store = useSimulationStore();

</script>
<style scoped lang="scss">

</style>
