import {RouteConfig} from 'vue-router';
import {Routes} from '@/router/Routes';
const PartnerList = () => import(/* webpackChunkName: "Partner" */ '@/modules/partner/PartnerList.vue');
const PartnerDetails = () => import(/* webpackChunkName: "Partner" */ '@/modules/partner/PartnerDetails.vue');

const PartnerRoutes: Array<RouteConfig> = [
  {
    path: '',
    name: Routes.PARTNERS.PARTNERS_LIST,
    component: PartnerList,
  },
  {
    path: ':id',
    name: Routes.PARTNERS.PARTNER_DETAILS,
    component: PartnerDetails,
  },
];

export default PartnerRoutes;
