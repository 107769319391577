<template>
  <v-tooltip bottom>
    <template v-slot:activator="{on}">
      <span class="badge" v-on="on" @click="$clipboard(id.toString())">ID:{{id}}</span>
    </template>
    <span class="caption">{{ I18NGetter().useDealBadge.COPY }}</span>
  </v-tooltip>
</template>
<script lang="ts" setup>
import {I18NGetter} from '../../services/enumTranslator/I18NGetter';
defineProps({
  id: {
    type: Number,
    required: true,
  },
});
</script>
<style scoped lang="scss">
.badge:hover {
  cursor: pointer;
  color: $primary;
}
</style>
