import {DivisionMember, DivisionMemberDTO} from '@/models/DivisionMember';
import {Utils} from '@/services/utils/BasicUtils';

export class DivisionGroupDTO {
  id!: number;
  name!: DivisionTypes;
  divisions: Array<DivisionMember> = [];
}

export default class DivisionGroup extends DivisionGroupDTO {
  divisionValue: Array<number> = [];
  constructor(divisionDTO?: DivisionGroupDTO) {
    super();
    if (divisionDTO) {
      if (divisionDTO.divisions) {
        this.divisions = divisionDTO.divisions.map((el: DivisionMemberDTO) => new DivisionMember(el));
      }
      Utils.assign(this, divisionDTO);
    }
  }

  public get textToSearch(): string {
    return this.displayName;
  }

  public get displayName(): string {
    return this.name;
  }
}

enum DivisionTypes {
  REGION = 'Region',
  CITY = 'Miasto',
  BRANCH = 'Oddział',
  TEAM = 'Zespół',
  SITE = 'Sieć',
  LAND = 'Województwo',
}
