import { render, staticRenderFns } from "./MortgageSimulationForm.vue?vue&type=template&id=5c2540ac&scoped=true"
import script from "./MortgageSimulationForm.vue?vue&type=script&lang=ts"
export * from "./MortgageSimulationForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_underscore@1.13.6_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2540ac",
  null
  
)

export default component.exports