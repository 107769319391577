<template>
  <base-badge v-on="$listeners" :loading="loading" v-if="institution" :customSize="customSize">
    <template #avatar="{hover}">
      <v-img :src="institution.symbolRequire"
             :class="{'on-hover': hover && $listeners.click}"/>
        <v-icon
            class="p-absolute pointer"
            color="primary"
            v-if="hover && $listeners.click">
          mdi-upload
        </v-icon>
    </template>
    <template #first-line>
      {{ institution.name_pl }}
    </template>
    <template v-if="$slots.default" #second-line>
      <slot></slot>
    </template>
  </base-badge>
</template>
<script lang="ts" setup>
import {Vue, Component, Prop} from 'vue-property-decorator';
import BaseBadge, {BaseBadgeClass} from '@/components/badges/base/BaseBadge.vue';
import {BankDictionaryElement} from '@/modules/banks/models/BankDictionaryElement';

const props = defineProps<{
  institution: BankDictionaryElement | undefined,
  loading?: boolean,
  customSize?: number,
}>();
</script>
<style scoped lang="scss">
.v-image.on-hover {
  opacity: 0.1;
  cursor: pointer;
  transition: opacity .2s ease-in-out;

}
</style>
