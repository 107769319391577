import Vue from 'vue';
import {VueModalComponent} from '../ModalService/VueModalComponent';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {HardConfirmModalData} from '@/components/fpComponents/services/ModalService/hardConfirmModal';
import HardConfirmModal from '@/components/fpComponents/services/ModalService/hardConfirmModal/HardConfirmModal.vue';
import {useModalStore} from '@/components/L/Modal/useModalStore';
import {ModalInstanceOld} from '@/components/L/Modal/ModalTypes';

interface ModalOptions {
  maxWidth: number,
  persistent: boolean,
  fullscreen?: boolean,
}

const createModalTemplate = () => {
  return '' +
    '<v-dialog ref="dialog" @keydown.esc="closeModal()" :persistent="true" scrollable v-model="showDialog" ' +
    'v-if="showDialog" :max-width="typeof modalOptions.maxWidth == \'number\' ? (modalOptions.maxWidth + \'px\') : modalOptions.maxWidth"' +
    ' :fullscreen="modalOptions.fullscreen" :content-class="modalOptions.fullscreen ?\'border-radius-0-important\':\'\'">' +
    '<v-btn icon v-if="!modalOptions.persistent" @click="closeModal()" style="position: absolute; top: 4px; right: 5px; z-index: 1000;">' +
    '<v-icon>close</v-icon>' +
    '</v-btn>' +
    '<ModalComponent ref="modal" :modal-data="modalData" :show-dialog.sync="showDialog"/>' +
    '</v-dialog>';
};

// @deprecated please use useModal
class ModalService {
  // eslint-disable-next-line vue/max-len
  public async openHardConfirm(title: string, falseText: string = I18NGetter().useButtonCommons.CANCEL, trueText: string = I18NGetter().useButtonCommons.YES_I_CONFIRM, maxWidth: number = 450): Promise<boolean | undefined> {
    return this.open<boolean, HardConfirmModalData>(HardConfirmModal, {
      title, trueText, falseText,
    }, {
      maxWidth: 500,
      persistent: true,
    });
  }

  // LEAD-PASS_LEAD_INFO_1 -> klasa generowana przez Lende potrzebna do złapania elementu.
  // eslint-disable-next-line vue/max-len
  public openConfirmModal(msg: string | {title?: string, description?: string}, falseText: string = I18NGetter().useButtonCommons.NO, trueText: string = I18NGetter().useButtonCommons.YES, maxWidth: number = 450): Promise<boolean | undefined> {
    return this.open<boolean>({
      template:
        '<v-card class="pa-1">' +
        '<v-card-title style="word-break: break-word" class="headline" v-html="msg.title || msg"></v-card-title>' +
        '<v-card-text v-if="msg.description" v-html="msg.description" style="word-break: break-word"></v-card-text>' +
        '<v-card-actions style="width: 100%" class="pt-0">' +
        '<v-spacer></v-spacer>' +
        '<v-btn outlined class="text-none my-2" @click="closeModal(false)">{{falseText}}</v-btn>' +
        '<v-btn depressed color="primary" class="text-none my-2 LEAD-PASS_LEAD_INFO_1" @click="closeModal(true)">{{trueText}}</v-btn>' +
        '</v-card-actions>' +
        '</v-card>',
      data() {
        return {
          msg,
          falseText,
          trueText,
        };
      },
    }, undefined, {
      persistent: true,
      maxWidth: maxWidth,
      fullscreen: false,
    })!;
  }

  // eslint-disable-next-line vue/max-len
  public open<T, TData extends any = any>(modalComponent: any, modalData: Partial<TData> = {}, modalOptions: Partial<ModalOptions> = {}): Promise<T> | undefined {
    const modalStore = useModalStore()
    const defaultModalOptions: ModalOptions = {
      maxWidth: 650,
      persistent: false,
      fullscreen: undefined,
    };
    modalOptions = Object.assign(defaultModalOptions, modalOptions);
    return new Promise<T>((resolve) => {
      const extendedModalComponent = Vue.extend(VueModalComponent).extend(modalComponent).extend({
        methods: {
          closeModal(data?: any) {
            this.$emit('update:showDialog', false);
            document.querySelectorAll('.v-dialog__content--active').forEach((dialog, i, dialogs) => {
              // eslint-disable-next-line vue/max-len
              if (i === dialogs.length - 2) (dialog as HTMLElement).style.cssText = (dialog as HTMLElement).style.cssText.substring(0, 13);
            });
            resolve(data);
            modalStore.closeModal(modalInstance);
          },
        },
      });
      const TempComponent = Vue.component('mzn-modal', {
        data() {
          return {
            showDialog: true,
            modalData,
            modalOptions,
          };
        },
        components: {
          ModalComponent: extendedModalComponent,
        },
        methods: {
          closeModal(data?: any) {
            this.showDialog = false;
            document.querySelectorAll('.v-dialog__content--active').forEach((dialog, i, dialogs) => {
              // eslint-disable-next-line vue/max-len
              if (i === dialogs.length - 2) (dialog as HTMLElement).style.cssText = (dialog as HTMLElement).style.cssText.substring(0, 13);
            });
            resolve(data);
            modalStore.closeModal(modalInstance);
          },
        },
        template: createModalTemplate(),
      });
      const modalInstance: ModalInstanceOld = {
        formComponent: TempComponent,
        uuid: crypto.randomUUID(),
        modalData: modalData,
      }
      modalStore.addModal(modalInstance)
    });
  }
}

export default new ModalService();
