export enum SourceKind {
  LANDING_PAGE = 'landing_page',
  OFFER_WEBSITE = 'offer_website',
  BUSINESS_CARD = 'business_card',
  EKO = 'eko',
  ABILITY_WIDGET = 'ability_widget',
  INSTALLMENT_WIDGET = 'installment_widget',
  COMPARER_WIDGET = 'comparer_widget',
  EXPERT_WIDGET = 'expert_widget',
  AUTORESPONDER = 'autoresponder',
  CHATBOT = 'chatbot',
  UNKNOWN = 'unknown',
  GOOGLE_BUSINESS_CARD = 'google_business_card',
  MAIN_PAGE = 'main_page',
  LISTING = 'listing',
  BLOG_POST = 'blog_post',
  SEARCH = 'search',
  FANPAGE = 'fanpage',
}

export enum LoanCategory {
  MORTGAGE = 'Mortgage'
}
