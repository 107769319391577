import {PersonalInfo} from '@/modules/accredition/models/interfaces/PersonalInfo';
import {NationalityDependant} from '@/modules/accredition/models/interfaces/NationalityDependant';
import {BirthInfo} from '@/modules/accredition/models/interfaces/BirthInfo';
import {ContactInfo} from '@/modules/accredition/models/interfaces/ContactInfo';
import {Addressable} from '@/modules/accredition/models/interfaces/Addressable';
import {PersonalInfoConfirmationQuestions} from '@/modules/accredition/models/interfaces/PersonalInfoConfirmationQuestions';
import {Address, AddressDTO} from '@/modules/multiForm/shared/MultiformModels';
import {PersonalId, PersonalIdDto} from '@/models/Client';
import {isEqualWith, omit} from 'lodash-es';

export interface AccreditationRepresentantDto extends
  PersonalInfo,
  PersonalInfoConfirmationQuestions,
  NationalityDependant,
  ContactInfo,
  BirthInfo,
  Addressable {
  id?: number,
  personalId: PersonalIdDto;
  nip: string | undefined | null
}

export class AccreditationRepresentant implements NullableDeep<AccreditationRepresentantDto> {
  id?: number;
  address: AddressDTO = new Address();
  birthName: string | null | undefined = null;
  birthPlace: string | null = null;
  contactPhone: Nullable<string> = null;
  privatePhone: Nullable<string> = null;
  dateIssued: Nullable<string> = null;
  email: Nullable<string> = null;
  expiryDate: Nullable<string> = null;
  fatherName: string | null = null;
  firstName: string | null = null;
  secondName: string | null = null;
  institution: Nullable<string> = null;
  lastName: string | null = null;
  motherName: string | null = null;
  mothersMaidenName: string | null = null;
  nationality: string = 'Polskie';
  nip: string | undefined | null = null;
  pesel: string | null = null;
  personalId: PersonalId = new PersonalId(0);
  attachedCertificate: Nullable<File> = null;
  activeCorrespondenceAddress: Nullable<boolean> = false;
  activeOfficialAddress: Nullable<boolean> = false;
  disableNipField: boolean = true;
  registeredAddress?: AddressDTO = new Address();
  correspondenceAddress?: AddressDTO = new Address();

  constructor(dto?: Partial<AccreditationRepresentantDto>) {
    if (dto) {
      if (dto.personalId) {
        dto.personalId = new PersonalId(0, dto.personalId);
      }
      if (!isEqualWith(omit(dto.address, 'id'), omit(dto.registeredAddress, 'id'))) {
        this.activeOfficialAddress = true;
      }
      if (!isEqualWith(omit(dto.address, 'id'), omit(dto.correspondenceAddress, 'id'))) {
        this.activeCorrespondenceAddress = true;
      }
      Object.assign(this, dto);
    }
  }
}
