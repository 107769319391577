import {IMultiformDictionaryElement} from '@/modules/multiForm/shared/IMultiformDictionary';

export interface LandOfWorkDto {
  id: number;
  districts: {id: number, name: string, }[];
}

export class LandOfWork {
  id: number | null = null;
  districts: IMultiformDictionaryElement<number>[] = [];

  constructor(dto?: LandOfWorkDto) {
    if (dto) {
      this.id = dto.id;
      this.districts = dto.districts.map(district => {
        return {
          type: district.id,
          name_pl: district.name,
        };
      });
    }
  }
}
