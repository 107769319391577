<template>
  <div class="view-wrapper">
    <div class="content-box px-8" v-if="clientBankSecrecyInfo">
      <img src="@/assets/platformLogotypes/lendi.png" alt="logo-lendi" class="logo"/>
      <RejectBankSecrecy
        v-if="activeBankSecrecy"
        @rejectBankSecrecy="rejectBankSecrecy"
        :loading="loading"
        :clientBankSecrecyInfo="clientBankSecrecyInfo"
        :bankSecrecyRejected="bankSecrecyRejected"/>
      <AcceptBankSecrecy
        v-else
        @acceptBankSecrecy="acceptBankSecrecy"
        :loading="loading"
        :clientBankSecrecyInfo="clientBankSecrecyInfo"
        :bankSecrecyAccepted="bankSecrecyAccepted"/>
    </div>
    <div v-else>
      <v-row>
        <v-col align="center">
          <img src="@/assets/img/brakDokumentow.svg" width="300px" class="py-2" alt="error"/>
        </v-col>
      </v-row>
      <h1 v-if="validToken" class="px-2 text-center error--text">Ups! Wystąpił błąd. Skontaktuj się z dws@lendi.pl</h1>
      <h1 v-else class="px-2 text-center error--text">
       Ups! Wygląda na to, że ten link wygasł. Skontaktuj się ze swoim Ekspertem i poproś o wysłanie maila z nowym linkiem.
      </h1>
    </div>
  </div>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import ClientApi from '@/modules/client/services/ClientApi';
import {ClientBankSecrecyDetails} from '@/models/Client';
import AcceptBankSecrecy from '@/modules/bankSecrecy/AcceptBankSecrecy.vue';
import RejectBankSecrecy from '@/modules/bankSecrecy/RejectBankSecrecy.vue';
@Component({
  components: {RejectBankSecrecy, AcceptBankSecrecy,},
})
export default class BankSecrecy extends Vue {
  public bankSecrecyRejected: boolean = false;
  public bankSecrecyAccepted: boolean = false;
  public token: Nullable<string> = null;
  public loading: boolean = false;
  public clientBankSecrecyInfo: Nullable<ClientBankSecrecyDetails> = null;

  get activeBankSecrecy(): boolean {
    return this.clientBankSecrecyInfo!.isAccepted && this.clientBankSecrecyInfo!.isEnabled;
  }

  get validToken(): boolean | null {
    if (this.clientBankSecrecyInfo) {
      return this.clientBankSecrecyInfo!.isEnabled;
    } return null;
  }

  public getToken() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params.token;
  }

  async mounted() {
    this.token = this.getToken();
    if (this.token) {
      try {
        this.clientBankSecrecyInfo = await ClientApi.getClientBankSecrecyByToken(this.token!);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async acceptBankSecrecy() {
    try {
      this.loading = true;
      const response = await ClientApi.acceptBankSecrecy(this.token!);
      if (response.status === 200) {
        this.bankSecrecyAccepted = true;
        this.loading = false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async rejectBankSecrecy() {
    try {
      this.loading = true;
      const response = await ClientApi.rejectBankSecrecy(this.token!);
      if (response.status === 200) {
        this.bankSecrecyRejected = true;
        this.loading = false;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
</script>
<style scoped lang="scss">
.logo {
  position: fixed;
  top: 16px;
  width: 68px;
  height: 24px;
  left: 16px;
}
.view-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
