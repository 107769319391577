import {TourTheme} from '@/modules/onboarding/useOnboardingTheme';
import {DashboardKey} from '@/modules/onboarding/tours/enums/DashboardKey';
import {LeadKey} from '@/modules/onboarding/tours/enums/LeadKey';
import {MortgageCalculatorKey} from '@/modules/onboarding/tours/enums/MortgageCalculatorKey';

export enum TourPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  AUTO = 'auto'
}

export const defaultStep: Partial<TourStep> = {
  params: {
    placement: TourPlacement.AUTO,
    highlight: true,
  },
};

export type TourStepKey = MortgageCalculatorKey | DashboardKey | LeadKey

export interface TourStep {
  key: TourStepKey;
  target?: string;
  content: string;
  offset?: number;
  before?: () => Promise<void>;
  params?: {
    highlight?: boolean;
    placement?: TourPlacement;
    enableScrolling?: boolean;
    highlightTheme?: TourTheme;
  };
  isNextButtonHidden?: boolean;
  isPreviousButtonHidden?: boolean;
}
