import {FpAxios} from 'fp-components';
import {AxiosError} from 'axios';

class ValidationApi {
  private clientAxios = FpAxios.create();
  private baseUrl = 'validate';

  async phone(phone: string): Promise<boolean> {
    try {
      await this.clientAxios.get<boolean>(`${this.baseUrl}/phone/${phone}`);
      return true;
    } catch (e: unknown) {
      // When the phone is not valid, the server returns 400.
      // When backend is not available, function should return true
      return (<AxiosError>e).response?.status !== 400;
    }
  }
}

export default new ValidationApi();
