<template>
  <multiform-list-manager
      :readonly="true"
      :value="households"
      :itemName="I18NGetter().useSimulationFormStep4.HOUSEHOLD">
    <template #default="{item}">
      <template v-if="$env.isAppDomain().LENDI_PL">
        <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep4.HOUSEHOLD_COSTS_AUTOMATICALLY }}</p>
        <v-layout wrap>
          <fp-input
              xs12
              md5
              type="number"
              v-model="item.averageCosts.life"
              int
              :suffix="$env.currentCurrency()"
              :label="I18NGetter().useSimulationFormStep4.LIFE_COSTS"/>
          <fp-input
              xs12
              md5
              type="number"
              v-model="item.averageCosts.house"
              int
              :suffix="$env.currentCurrency()"
              :label="I18NGetter().useSimulationFormStep4.HOUSE_COSTS"/>
          <fp-input
              xs12
              md5
              type="number"
              v-model="item.averageCosts.car"
              int
              :suffix="$env.currentCurrency()"
              :label="I18NGetter().useSimulationFormStep4.CAR_COSTS"/>
          <fp-input
              xs12
              md5
              type="number"
              v-model="item.averageCosts.other"
              int
              :suffix="$env.currentCurrency()"
              :label="I18NGetter().useSimulationFormStep4.OTHER_COSTS"/>
        </v-layout>
      <v-divider class="my-2"></v-divider>
      </template>
      <p class="all-caps-header mb-0">{{ I18NGetter().useSimulationFormStep4.HAS_LIABILITIES_OPTIONS }}</p>
      <v-layout>
        <fp-radio-group
            :row="$vuetify.breakpoint.mdAndUp"
            class="mb-2"
            v-model="item.hasLiabilities"
            :items="hasLiabilitiesOptions"
        ></fp-radio-group>
      </v-layout>

      <fp-expansion :value="item.hasLiabilities">
        <v-layout wrap>
          <fp-input
              xs12
              md5
              type="number"
              v-model="item.liabilities.installmentsPLN"
              :suffix="$env.currentCurrency()"
              int
              :label="I18NGetter().useSimulationFormStep4.SUM_OF_INSTALLMENTS"/>
          <fp-input
              xs12
              md5
              v-if="!hasMerged.liabilities"
              type="number"
              v-model="item.liabilities.installmentsFX"
              :suffix="$env.currentCurrency()"
              int
              :label="I18NGetter().useSimulationFormStep4.SUM_OF_INSTALLMENTS_FOREIGN"/>
          <fp-input
              xs12
              md5
              type="number"
              v-model="item.liabilities.accountLimit"
              :suffix="$env.currentCurrency()"
              int
              :label="hasMerged.limits
              ? I18NGetter().useSimulationFormStep4.SUM_OF_LIMITS_MERGED
              : I18NGetter().useSimulationFormStep4.SUM_OF_LIMITS_ACCOUNT"/>
          <fp-input
              xs12
              md5
              v-if="!hasMerged.limits"
              type="number"
              v-model="item.liabilities.cardsLimit"
              :suffix="$env.currentCurrency()"
              int
              :label="I18NGetter().useSimulationFormStep4.SUM_OF_LIMITS_CARD"/>
          <fp-input
              xs12
              md5
              v-if="!hasMerged.liabilities"
              type="number"
              v-model="item.liabilities.alimony"
              :suffix="$env.currentCurrency()"
              int
              :label="I18NGetter().useSimulationFormStep4.SUM_OF_ALIMONY"/>
          <fp-input
              xs12
              md5
              v-if="!hasMerged.liabilities"
              type="number"
              v-model="item.liabilities.guarantees"
              :suffix="$env.currentCurrency()"
              int
              :label="I18NGetter().useSimulationFormStep4.SUM_OF_GUARANTEES"/>
          <template v-if="$env.isAppDomain().LENDI_PL">
            <fp-input
                xs12
                md10
                type="number"
                v-model="item.liabilities.loansLiabilitiesSum"
                :suffix="$env.currentCurrency()"
                int
                :label="I18NGetter().useSimulationFormStep4.SUM_OF_LOAN_LIABILITIES"/>
            <fp-input
                xs12
                md10
                type="number"
                v-model="item.liabilities.mortgageLiabilitiesSum"
                :suffix="$env.currentCurrency()"
                int
                :label="I18NGetter().useSimulationFormStep4.SUM_OF_MORTGAGE_LIABILITIES"/>
          </template>
        </v-layout>

      </fp-expansion>
    </template>
  </multiform-list-manager>
</template>
<script lang="ts" setup>
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {useHouseholds} from '@/components/calculator/form/useSimulationForm';
import MultiformListManager from '@/modules/multiForm/views/MultiformListManager.vue';
import {IMultiformDictionary} from '@/modules/multiForm/shared/IMultiformDictionary';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {useInstance} from '@/composables/useInstance';
const store = useSimulationStore();
const {households,} = useHouseholds();
const {$env,} = useInstance();

const hasMerged = {
  liabilities: $env?.getAppDomainConfig().calculator.hasMergedLiabilities,
  limits: $env?.getAppDomainConfig().calculator.hasMergedLimits,
};
const hasLiabilitiesOptions: IMultiformDictionary<boolean> = [{
  type: true,
  name_pl: I18NGetter().useSimulationFormStep4.MULTI_FORM_LIABILITIES,
},
{type: false, name_pl: I18NGetter().useSimulationFormStep4.MULTI_FORM_NO_LIABILITIES,},];

</script>
<style scoped lang="scss">

</style>
