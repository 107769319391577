<template>
  <v-expand-transition>
    <v-row v-show="VShow" class="no-gutters">
      <v-col>
        <slot v-if="VIf"/>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>
<script lang="ts">
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {wait} from '@/services/utils/BasicUtils';

@Component
export default class FpExpansion extends Vue {
  @Prop({required: true, default: false,})
  public value!: boolean;

  public VShow: boolean = this.value;
  public VIf: boolean = this.value;

  mounted() {
    this.setValue(this.value);
  }

  @Watch('value', {immediate: false,})
  async setValue(newValue: boolean) {
    if (newValue) {
      this.VIf = true;
      await wait(40);
      this.VShow = true;
    } else {
      this.VShow = false;
      await wait(50);
      this.VIf = false;
    }
  }
}
</script>
<style scoped lang="scss">
</style>
