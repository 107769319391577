<template>
  <v-card>
    <v-card-title>
      <span class="headline-2">{{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS_CONFIRM_TITLE }}</span>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pb-2 pt-6">
      <p class="body-1 opacity-90">
        {{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS_INFO_1 }}
      </p>
      <p class="body-1 mb-6">
        <span class="opacity-90">{{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS_INFO_2 }}</span>
        <span class="headline-5">{{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS_PATH }}</span><span class="opacity-90">, {{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS_INFO_3 }}</span>
      </p>
      <client-badge v-for="client in modal.modalData.clients" :key="client.id"
        :client="client"
        :hide-more="true"
        :hide-actions="true"
        :readonly="true"
        :show-id="false"
        class="client-badge mb-6"
      >
        <template #second-line>
          {{ client.email }}
        </template>
      </client-badge>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        @click="modal.closeModal(true)"
        color="primary"
        depressed
      >
        {{ $i18n.useSimulationSendOrDownload.SEND_TO_CLIENTS }}
      </v-btn>
      <v-spacer />
      <v-btn depressed outlined @click="modal.closeModal()">
        {{ $i18n.useButtonCommons.CANCEL }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useModalInstance } from '@/composables/useModalInstance';
import ClientBadge from '@/components/badges/ClientBadge.vue';

const modal = useModalInstance();
</script>

<style scoped lang="scss">
.client-badge {
  :deep(.mdi-chevron-right) {
    display: none;
  }
}
</style>
