import { VueConstructor } from 'vue';
import {
  dateFormat,
  dateTextFormat,
  dateTimeFormat, dateAndTimeFormat, dayFormat, dayMonthFormat, dayTime,
  fileNameFormat,
  monthFormat, monthYearFormat, noDataDefault, noDataHandler,
  timeFormat,
  yearFormat
} from '@/filters/DateFilters';
import {
  addressFormat,
  bankAccountFormat, capitalize,
  decimalFormat, displayName, mortgageSimulationPurposeTitle,
  numeralFormat,
  peselFormat, phoneFormat,
  subString,
  trimString,
  zipCodeFormat
} from '@/filters/StringFilters';
import {bankName, districtName, landName, productType} from '@/filters/DictionaryFilters';

export default {
  install(Vue: VueConstructor) {
    // Date filters
    Vue.filter('capitalize', capitalize);
    Vue.filter('dateTimeFormat', dateTimeFormat);
    Vue.filter('dateAndTimeFormat', dateAndTimeFormat);
    Vue.filter('dayTime', dayTime);
    Vue.filter('fullDateTime', dateTextFormat);
    Vue.filter('dateTextFormat', dateTextFormat);
    Vue.filter('dayMonthFormat', dayMonthFormat);
    Vue.filter('dayFormat', dayFormat);
    Vue.filter('dateFormat', dateFormat);
    Vue.filter('monthYearFormat', monthYearFormat);
    Vue.filter('timeFormat', timeFormat);

    // string filters
    Vue.filter('trimString', trimString);
    Vue.filter('subString', subString);
    Vue.filter('yearFormat', yearFormat);
    Vue.filter('monthFormat', monthFormat);
    Vue.filter('fileNameFormat', fileNameFormat);
    Vue.filter('decimalFormat', decimalFormat);
    Vue.filter('numeralFormat', numeralFormat);
    Vue.filter('addressFormat', addressFormat);
    Vue.filter('zipCodeFormat', zipCodeFormat);
    Vue.filter('bankAccountFormat', bankAccountFormat);
    Vue.filter('peselFormat', peselFormat);
    Vue.filter('mortgageSimulationPurposeTitle', mortgageSimulationPurposeTitle);
    Vue.filter('noDataDefault', noDataDefault);
    Vue.filter('phoneFormat', phoneFormat);

    // dictionary filters
    Vue.filter('landName', landName);
    Vue.filter('districtName', districtName);
    Vue.filter('bankName', bankName);
    Vue.filter('productType', productType);
    Vue.filter('displayName', displayName);
  },
};
