import {TourStep} from '@/modules/onboarding/tour/models/TourStep';
import {Tour} from '@/modules/onboarding/tour/Tour';
import {TourModalType, TourName, TourOptions} from '@/modules/onboarding/tour/models/TourOptions';
import {MortgageCalculatorKey} from '@/modules/onboarding/tours/enums/MortgageCalculatorKey';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

export const STEPS: readonly TourStep[] = [
  {
    key: MortgageCalculatorKey.AVAILABLE_OFFERS,
    content: I18NGetter().useMortgageCalculatorTour.AVAILABLE_OFFERS,
    params: {
    },
    isPreviousButtonHidden: true,
  },
  {
    key: MortgageCalculatorKey.OFFERS_COUNTER,
    content: I18NGetter().useMortgageCalculatorTour.OFFERS_COUNTER,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.ROWS_ORDER,
    content: I18NGetter().useMortgageCalculatorTour.ROWS_ORDER,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: MortgageCalculatorKey.OFFERS_MANAGEMENT,
    content: I18NGetter().useMortgageCalculatorTour.OFFERS_MANAGEMENT,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.SELECTED_OFFERS,
    content: I18NGetter().useMortgageCalculatorTour.SELECTED_OFFERS,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.INSTITUTION_NAMES,
    content: I18NGetter().useMortgageCalculatorTour.INSTITUTION_NAMES,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.VIEW_SIZE,
    content: I18NGetter().useMortgageCalculatorTour.VIEW_SIZE,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.VERSION,
    content: I18NGetter().useMortgageCalculatorTour.VERSION,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.USED_FILTERS_COUNT,
    content: I18NGetter().useMortgageCalculatorTour.USED_FILTERS_COUNT,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.BASIC_PARAMETERS,
    content: I18NGetter().useMortgageCalculatorTour.BASIC_PARAMETERS,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.REFERENCE_RATE,
    content: I18NGetter().useMortgageCalculatorTour.REFERENCE_RATE,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.BRIDGING_INSURANCE,
    content: I18NGetter().useMortgageCalculatorTour.BRIDGING_INSURANCE,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.LOAN_PERIOD,
    content: I18NGetter().useMortgageCalculatorTour.LOAN_PERIOD,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.BANKS,
    content: I18NGetter().useMortgageCalculatorTour.BANKS,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.FILTERS,
    content: I18NGetter().useMortgageCalculatorTour.FILTERS,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.PRINTOUT_SETTINGS,
    content: I18NGetter().useMortgageCalculatorTour.PRINTOUT_SETTINGS,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.PRINT,
    content: I18NGetter().useMortgageCalculatorTour.PRINT,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.ADD_OFFERS_TO_DEAL,
    content: I18NGetter().useMortgageCalculatorTour.ADD_OFFERS_TO_DEAL,
    params: {
    },
  },
  {
    key: MortgageCalculatorKey.INITIAL_COST,
    content: I18NGetter().useMortgageCalculatorTour.INITIAL_COST,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: MortgageCalculatorKey.TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE,
    content: I18NGetter().useMortgageCalculatorTour.TRANSITIONAL_INSTALLMENT_WITH_BRIDGE_INSURANCE,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: MortgageCalculatorKey.TRANSITIONAL_INSTALLMENT,
    content: I18NGetter().useMortgageCalculatorTour.TRANSITIONAL_INSTALLMENT,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: MortgageCalculatorKey.TARGET_INSTALLMENT,
    content: I18NGetter().useMortgageCalculatorTour.TARGET_INSTALLMENT,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: MortgageCalculatorKey.PRINCIPAL_AND_INTEREST_INSTALLMENT,
    content: I18NGetter().useMortgageCalculatorTour.PRINCIPAL_AND_INTEREST_INSTALLMENT,
    params: {
      enableScrolling: false,
    },
  },
  {
    key: MortgageCalculatorKey.NON_MONTHLY_COSTS,
    content: I18NGetter().useMortgageCalculatorTour.NON_MONTHLY_COSTS,
    params: {
      enableScrolling: false,
    },
    isNextButtonHidden: true,
  },
] as const;

const OPTIONS: TourOptions = new TourOptions({
  modals: {
    [TourModalType.START]: {
      description: I18NGetter().useMortgageCalculatorTour.START,

    },
    [TourModalType.FINISH]: {
      description: I18NGetter().useMortgageCalculatorTour.FINISH,
    },
  },
});

const MortgageCalculatorTour = new Tour(TourName.MORTGAGE_CALCULATOR, STEPS, OPTIONS);

export default MortgageCalculatorTour;
