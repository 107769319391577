import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {DomainAgreements} from '@/env/Agreements/types';

export const agreementsNesto: DomainAgreements = {
  [SystemLanguage.RO]: {
    acceptsProcessing: {
      title: 'INTERMEDIERE FINANCIARA',
      label: 'Sunt de acord cu procesarea datelor mele personale de catre Realmedia Credit SRL pentru a efectua serviciul de intermediere financiara si serviciile conexe.',
    },
    acceptsReceivingInfo: {
      title: 'INFORMATII COMERCIALE',
      label: 'Sunt de acord sa primesc comunicari comerciale privind derularea solicitarii mele de credit, adresate mie de Realmedia Credit SRL in concordanta cu prevederile Regulamentului 2016 / 679.',
    },
    acceptsReceivingOffers: {
      title: 'MARKETING',
      label: 'Sunt de acord sa primesc comunicari de marketing adresate mie de Realmedia Credit SRL prin e-mail si/ sau SMS, in concordanta cu prevederile Regulamentului 2016 / 679.',
    },
    acceptsRealEstate: {
      title: 'INFORMATII IMOBILIARE',
      label: 'Sunt de acord sa primesc comunicari privind ofertele imobiliare adresate mie de Realmedia Credit SRL prin e-mail si/ sau SMS, in concordanta cu prevederile Regulamentului 2016 / 679.',
    },
  },
};
