<template>
  <v-layout no-wrap align-center px-4>
    <span class="subtitle-1 font-weight-medium">{{$i18n.useListActions.ACTIONS_ON_LIST}}</span>
    <v-switch v-model="showMultiSelect" hide-details class="py-0 my-0 ml-4 shrink"></v-switch>
    <v-divider vertical class="mx-4"></v-divider>
    <v-flex shrink pa-0>
      <v-slide-x-transition>
        <div v-show="showMultiSelect" class="text-no-wrap">
          <slot></slot>
        </div>
      </v-slide-x-transition>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Vue, Prop, Component} from 'vue-property-decorator';
@Component
export default class ListActions extends Vue {
  @Prop({default: false, }) value!: boolean;
  public temporaryValue: boolean = this.value;

  public get showMultiSelect(): boolean {
    return this.temporaryValue;
  }

  public set showMultiSelect(newValue: boolean) {
    this.temporaryValue = newValue;
    this.$emit('input', this.temporaryValue);
  }
}
</script>
